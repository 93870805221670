import AddRoleNameModalForm from 'components/contexts/modal/AddRoleNameModal/AddRoleNameModalForm';
import ModalDeleteConfirmation from 'components/contexts/modal/ModalDeleteConfirmation/ModalDeleteConfirmation';
import ModalStatusCreate from 'components/contexts/modal/ModalEditorialStatus/ModalEditorialStatus';
import ModalStatus from 'components/contexts/modal/ModalStatus/ModalStatus';
import Modal from 'components/structure/Modal/Modal';
import PageContent from 'components/structure/PageContent/PageContent';
import PageHeader from 'components/structure/PageHeader/PageHeader';
import PopOver from 'components/structure/PopOver/PopOver';
import TableDefault from 'components/structure/Table/Table';
import urls from 'constants/urls';
import { getUserData } from 'lib/contexts/auth';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import addIcon from 'assets/icons/add.svg';

import { getPermission } from 'utils/permissions';
import { Divider, PopItem, PopOverContent } from '../Users/Users.styles';
import {
  getListRoles,
  getRoleDetail,
  getRoleEditDetail,
  openRoleModalDelete,
  setRoleId,
  statusRoleModal,
  statusRoleModalCreate,
  removeRole as deleteRole,
} from 'store/roles/roles-slice';

function Roles() {
  const { items, totalPage } = useSelector((state) => state.roles.roles);
  const [currentPage, setCurrentPage] = useState(1);
  const [showAddModal, setShowAddModal] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');

  const {
    role,
    msg,
    status,
    openStatusModal,
    openModalDelete,
    openStatusModalCreate,
  } = useSelector((state) => state.roles);

  const { permissions } = useSelector((state) => state.auth);
  const [selectedId, setSelectedId] = useState(null);
  const [ordenation, setOrdenation] = useState('');
  const [totalPerPage, setTotalPerPage] = useState({ value: 10 });
  const perPage = totalPerPage.value;

  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  useEffect(() => {
    dispatch(getListRoles({ currentPage, searchTerm, perPage, ordenation }));
  }, [dispatch, currentPage, searchTerm, perPage, ordenation]);

  const addRoleModal = () => {
    history.push(urls.ROUTES.ROLES_CREATE.path)
  };

  const editRole = useCallback(
    (id) => {
      setSelectedId(id);
      dispatch(setRoleId(id));
      history.push(urls.ROUTES.ROLES_EDIT.path);
    },
    [dispatch, history],
  );

  const viewRole = useCallback(
    (id) => {
      dispatch(getRoleEditDetail(id));
      setSelectedId(id);
      dispatch(setRoleId(id));
      history.push(urls.ROUTES.ROLES_VIEW.path);
    },
    [dispatch, history],
  );

  const viewActivities = useCallback(
    (id) => {
      history.push(`${urls.ROUTES.ACCESS.items.logsByRecord.path}/${id}/roles`);
    },
    [history],
  );

  const removeRole = useCallback(
    (id) => {
      setSelectedId(id);
      dispatch(getRoleDetail({ id, deleteSearch: true }));
    },
    [dispatch],
  );

  const user = getUserData();

  const COLUMNS = useMemo(
    () => [
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Slug',
        accessor: 'slug',
      },
      {
        Header: ' ',
        accessor: ({ id, name }) =>
          id === user.role ||
            name === 'Admin' ||
            getPermission('roles', permissions, 'update') === false ? (
            <></>
          ) : (
            <PopOver
              content={
                <PopOverContent>
                  <PopItem onClick={() => editRole(id)}>
                    {intl.messages['buttons.edit']}
                  </PopItem>
                  {getPermission('roles', permissions, 'delete') && (
                    <>
                      <Divider />
                      <PopItem onClick={() => removeRole(id)}>
                        {intl.messages['buttons.exclusion']}
                      </PopItem>
                    </>
                  )}
                  {getPermission('roles', permissions, 'access') && (
                    <>
                      <Divider />
                      <PopItem onClick={() => viewRole(id)}>
                        {intl.messages['buttons.view']}
                      </PopItem>
                    </>
                  )}
                  <>
                    <Divider />
                    <PopItem onClick={() => viewActivities(id)}>
                      Ver atividades
                    </PopItem>
                  </>
                </PopOverContent>
              }
            />
          ),
      },
    ],
    [editRole, intl.messages, permissions, removeRole, user.role, viewRole],
  );

  const data = useMemo(() => items, [items]);

  const addRoleNameModalForm = (
    <AddRoleNameModalForm
      cancelActionModal={() => setShowAddModal(false)}
      createActionModal={() => setShowAddModal(false)}
    />
  );

  const optionsOrdenation = [
    { value: 'name', label: 'Nome' },
    { value: 'slug', label: 'Slug' },
  ];

  return (
    <>
      <Modal
        title={intl.messages['textModal.createRoles']}
        setIsOpen={setShowAddModal}
        isOpen={showAddModal}
        content={addRoleNameModalForm}
        width="30%"
      />
      <ModalDeleteConfirmation
        title={intl.messages['textModal.areYouSure']}
        text={intl.messages['textModal.roleDeleteConfirm']}
        placeholder={intl.messages['labelsInputs.enterName']}
        buttonName={intl.messages['buttons.delete']}
        setIsOpen={(value) => dispatch(openRoleModalDelete(value))}
        isOpen={openModalDelete}
        fieldValue={role.name}
        fieldLabel={role.name}
        confirmOnClick={() =>
          dispatch(
            deleteRole({
              id: selectedId,
              page: currentPage,
              searchTerm,
            }),
          )
        }
        textFieldLabel={intl.messages['labelsInputs.enterName']}
      />

      <ModalStatusCreate
        title={msg.title}
        text={msg.text}
        setIsOpen={(payload, id) => {
          dispatch(statusRoleModalCreate(payload));
          if (status !== 'error') {
            setSelectedId(id);
            history.push(urls.ROUTES.ROLES_CREATE.path);
          }
        }}
        isOpen={openStatusModalCreate}
        status={status}
      />

      <ModalStatus
        title={msg.title}
        text={msg.text}
        setIsOpen={(payload) => {
          dispatch(statusRoleModal(payload));
        }}
        isOpen={openStatusModal}
        status={status}
      />
      <PageHeader
        title="Perfis"
        onClickFilter={() => { }}
        actions={!getPermission('roles', permissions, 'create') ? [] : [
          {
            name: 'Novo Perfil',
            onClick: addRoleModal,
            btnType: "primary",
            icon: addIcon,
          },
        ]
        }
        search
        searchFunc={(searchData) => setSearchTerm(searchData)}
        placeholder="Busque por Nome"
        optionsOrdenation={optionsOrdenation}
        ordenation={ordenation}
        setOrdenation={setOrdenation}
        trash={getPermission('roles', permissions, 'list')}
        trashRedirect={() => history.push(urls.ROUTES.ROLES_DELETED.path)}
      />
      <PageContent>
        <TableDefault
          columns={COLUMNS}
          data={data}
          pageCount={totalPage}
          setPageCurrent={setCurrentPage}
          defaultCurrentPage={currentPage}
          totalPerPage={totalPerPage}
          setTotalPerPage={setTotalPerPage}
        />
      </PageContent>
    </>
  );
}

export default Roles;
