import ModalDeleteConfirmation from 'components/contexts/modal/ModalDeleteConfirmation/ModalDeleteConfirmation';
import ModalMultiConfirmation from 'components/contexts/modal/ModalMultiConfirmation/ModalMultiConfirmation';
import EmptySearch from 'components/structure/EmptySearch/EmptySearch';
import PageContent from 'components/structure/PageContent/PageContent';
import PageHeader from 'components/structure/PageHeader/PageHeader';
import PopOver from 'components/structure/PopOver/PopOver';
import {
  Divider,
  PopItem,
  PopOverContent,
} from 'components/structure/PopOver/PopOver.styles';
import TableDefault from 'components/structure/Table/Table';
import urls from 'constants/urls';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getPermission } from 'utils/permissions';
import { successToast } from 'utils/toasts';
import { types } from './dataProperties';
import AddPropertyModalForm from './modals/AddPropertyModalForm';
import EditPropertyModalForm from './modals/EditPropertyModalForm';
import ModalPropertyConfirmation from './modals/ModalPropertyConfirmation';
import ViewPropertyModalForm from './modals/ViewPropertyModalForm';
import {
  dismissModalDelete,
  getPropertiesList,
  getPropertyDetail,
  removeProperties,
  setPropertyId,
  showModalDelete,
  statusPropertiesModal,
  addProperties as createProperty,
  editProperty as updateProperty,
} from 'store/properties/properties-slice';

function Properties() {
  const { permissions } = useSelector((state) => state.auth);
  const { items, totalPage } = useSelector(
    (state) => state.properties.properties,
  );
  const { openStatusModal, status, msg, openDeleteModal, property, selectId } =
    useSelector((state) => state.properties);

  const [name, setName] = useState('');
  const [typeProperty, setTypeProperty] = useState('');
  const [nameError, setNameError] = useState({
    type: '',
    message: '',
  });
  const [typePropertyError, setTypePropertyError] = useState({
    type: '',
    message: '',
  });
  const [selectedId, setSelectedId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [showModalCancel, setShowModalCancel] = useState(false);
  const [showModalCancelEdit, setShowModalCancelEdit] = useState(false);
  const [showModalConfirmationEdit, setShowModalConfirmationEdit] =
    useState(false);
  const [showViewModal, setShowViewModal] = useState(false);

  const [selectedSlug, setSelectedSlug] = useState(null);
  const [ordenation, setOrdenation] = useState('');
  const [totalPerPage, setTotalPerPage] = useState({ value: 10 });
  const perPage = totalPerPage.value;

  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      getPropertiesList({ currentPage, searchTerm, perPage, ordenation }),
    );
  }, [dispatch, currentPage, searchTerm, perPage, ordenation]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  const resetStates = () => {
    setName('');
    setTypeProperty('');
  };

  const addPropertyModal = () => {
    setShowAddModal(true);
    resetStates();
  };

  const editPropertyModal = useCallback(
    (id) => {
      setSelectedId(id);
      dispatch(setPropertyId(id));
      setShowEditModal(true);
    },
    [dispatch],
  );

  const viewPropertyModal = useCallback(
    (id) => {
      dispatch(getPropertyDetail(parseInt(id, 10)));
      setSelectedId(id);
      dispatch(setPropertyId(id));
      setShowViewModal(true);
    },
    [dispatch],
  );

  const removePropertyModal = useCallback(
    (id, slug) => {
      setSelectedId(id);
      setSelectedSlug(slug);
      dispatch(showModalDelete());
    },
    [dispatch],
  );

  const cancelProperty = () => {
    setName('');
    setTypeProperty('');
    setShowModalCancel(false);
  };

  const viewActivities = useCallback(
    (id) => {
      history.push(
        `${urls.ROUTES.ACCESS.items.logsByRecord.path}/${id}/properties`,
      );
    },
    [history],
  );

  const cancelPropertyEdit = () => {
    setShowModalCancelEdit(false);
  };

  function validateName() {
    if (name === '') {
      setNameError({
        type: 'error',
        message: 'Nome da propriedade não preenchido',
      });
      return false;
    }
    return true;
  }

  function validateType() {
    if (typeProperty === '') {
      setTypePropertyError({
        type: 'error',
        message: 'Tipo da propriedade não selecionado',
      });
      return false;
    }
    return true;
  }

  const addProperty = () => {
    const propertyData = {
      name,
      type: typeProperty,
    };
    dispatch(createProperty(propertyData));
    if (status !== 'error') {
      successToast(`A propriedade ${name} foi criada com sucesso!`);
    }
    setShowModalConfirmation(false);
    resetStates();
  };

  const editProperty = () => {
    const propertyData = {
      name,
      type: typeProperty,
    };
    dispatch(updateProperty({ info: propertyData, id: selectId }));
    if (status !== 'error') {
      successToast(`A propriedade ${name} foi editada com sucesso!`);
    }
    setShowModalConfirmationEdit(false);
  };

  const findTranslate = (type) => {
    const found = types.find((data) => data.value === type);
    return found.label;
  };

  const renderAddProperty = () => (
    <>
      <AddPropertyModalForm
        name={name}
        setName={setName}
        type={typeProperty}
        setType={setTypeProperty}
        isOpen={showAddModal}
        setIsOpen={() => setShowAddModal(false)}
        cancelActionModal={() => setShowModalCancel(true)}
        confirmActionModal={() => {
          // eslint-disable-next-line no-bitwise
          if ((validateType() === false) | (validateName() === false)) return;
          setShowModalConfirmation(true);
        }}
        nameError={nameError}
        typeError={typePropertyError}
      />
      <ModalPropertyConfirmation
        title={intl.messages['textModal.createPropertyConfirm']}
        isOpen={showModalConfirmation}
        setIsOpen={setShowModalConfirmation}
        buttonNameBack={intl.messages['buttons.toBack']}
        buttonName={intl.messages['buttons.yes']}
        status="confirm"
        backOnClick={() => setShowModalConfirmation(false)}
        confirmOnClick={addProperty}
      />
      <ModalPropertyConfirmation
        title={intl.messages['textModal.createPropertyCancel']}
        text={intl.messages['textModal.allWillBeLost']}
        isOpen={showModalCancel}
        setIsOpen={setShowModalCancel}
        backOnClick={() => {
          setShowAddModal(true);
          setShowModalCancel(false);
        }}
        confirmOnClick={cancelProperty}
        status="info"
      />
    </>
  );

  useEffect(() => {
    setName(property.name);
    setTypeProperty(property.type);
  }, [property]);

  const renderEditProperty = () => (
    <>
      <EditPropertyModalForm
        name={name}
        setName={setName}
        type={typeProperty}
        setType={setTypeProperty}
        isOpen={showEditModal}
        setIsOpen={() => setShowEditModal(false)}
        cancelActionModal={() => setShowModalCancelEdit(true)}
        confirmActionModal={() => {
          if (validateName() === false) return;
          setShowModalConfirmationEdit(true);
        }}
        nameError={nameError}
      />

      <ModalPropertyConfirmation
        title={intl.messages['textModal.editPropertyConfirm']}
        isOpen={showModalConfirmationEdit}
        setIsOpen={setShowModalConfirmationEdit}
        buttonNameBack={intl.messages['buttons.toBack']}
        buttonName={intl.messages['buttons.yes']}
        status="confirm"
        backOnClick={() => setShowModalConfirmationEdit(false)}
        confirmOnClick={editProperty}
      />
      <ModalPropertyConfirmation
        title={intl.messages['textModal.editPropertyCancel']}
        text={intl.messages['textModal.allWillBeLost']}
        isOpen={showModalCancelEdit}
        setIsOpen={setShowModalCancelEdit}
        backOnClick={() => {
          setShowEditModal(true);
          setShowModalCancelEdit(false);
        }}
        confirmOnClick={cancelPropertyEdit}
        status="info"
      />
    </>
  );

  const renderViewProperty = () => (
    <ViewPropertyModalForm
      isOpen={showViewModal}
      setIsOpen={setShowViewModal}
      cancelActionModal={() => {
        setShowViewModal(false);
      }}
      confirmActionModal={() => {
        setShowEditModal(true);
        setShowViewModal(false);
      }}
    />
  );

  const COLUMNS = useMemo(
    () => [
      { Header: 'ID', accessor: 'id' },
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Tipo',
        accessor: ({ type }) => findTranslate(type),
      },
      {
        Header: ' ',
        accessor: ({ id, slug }) =>
          getPermission('properties', permissions, 'update') === false ? (
            <></>
          ) : (
            <PopOver
              content={
                <PopOverContent>
                  <PopItem onClick={() => editPropertyModal(id)}>
                    {intl.messages['buttons.edit']}
                  </PopItem>
                  {getPermission('properties', permissions, 'delete') && (
                    <>
                      <Divider />
                      <PopItem onClick={() => removePropertyModal(id, slug)}>
                        {intl.messages['buttons.exclusion']}
                      </PopItem>
                    </>
                  )}
                  {getPermission('properties', permissions, 'access') && (
                    <>
                      <Divider />
                      <PopItem onClick={() => viewPropertyModal(id, slug)}>
                        {intl.messages['buttons.view']}
                      </PopItem>
                    </>
                  )}
                  <>
                    <Divider />
                    <PopItem onClick={() => viewActivities(id)}>
                      Ver atividades
                    </PopItem>
                  </>
                </PopOverContent>
              }
            />
          ),
      },
    ],
    [editPropertyModal, intl.messages, permissions, removePropertyModal],
  );

  const data = useMemo(() => items, [items]);

  const optionsOrdenation = [
    { value: 'id', label: 'ID' },
    { value: 'name', label: 'Nome' },
  ];

  useEffect(() => {
    if (selectId !== 0) {
      dispatch(getPropertyDetail(parseInt(selectId, 10)));
    }
  }, [selectId, dispatch]);

  return (
    <>
      {renderAddProperty()}
      {renderEditProperty()}
      {renderViewProperty()}
      <ModalDeleteConfirmation
        title={intl.messages['textModal.areYouSure']}
        text={intl.messages['textModal.propertiesConfirm']}
        placeholder={intl.messages['labelsInputs.enterSlug']}
        buttonName={intl.messages['buttons.delete']}
        setIsOpen={() => dispatch(dismissModalDelete())}
        isOpen={openDeleteModal}
        fieldValue={selectedSlug}
        fieldLabel={selectedSlug}
        confirmOnClick={() =>
          dispatch(removeProperties({ id: selectedId, page: currentPage }))
        }
        textFieldLabel={intl.messages['labelsInputs.enterSlug']}
      />

      <ModalMultiConfirmation
        title={msg.title}
        text={msg.text}
        setIsOpen={(payload) => {
          dispatch(statusPropertiesModal(payload));
          if (status !== 'error') {
            dispatch(getPropertiesList({ currentPage, searchTerm }));
          }
        }}
        isOpen={openStatusModal}
        status={status}
      />
      <PageHeader
        title="Propriedades"
        addButtonName="Nova propriedade"
        onClickAdd={addPropertyModal}
        onClickFilter={() => {}}
        addButton={getPermission('properties', permissions, 'create')}
        search
        searchFunc={(searchData) => setSearchTerm(searchData)}
        placeholder="Busque por Nome"
        optionsOrdenation={optionsOrdenation}
        ordenation={ordenation}
        setOrdenation={setOrdenation}
        trash={getPermission('properties', permissions, 'list')}
        trashRedirect={() => history.push(urls.ROUTES.PROPERTIES_DELETED.path)}
      />
      <PageContent>
        <EmptySearch items={data} term={searchTerm}>
          <TableDefault
            columns={COLUMNS}
            data={data}
            pageCount={totalPage}
            defaultCurrentPage={currentPage}
            setPageCurrent={setCurrentPage}
            totalPerPage={totalPerPage}
            setTotalPerPage={setTotalPerPage}
          />
        </EmptySearch>
      </PageContent>
    </>
  );
}

export default Properties;
