import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';
import api from 'services/api';
import { SKUS } from 'constants/endpoints';
import Button from 'components/structure/ActionButton/ActionButton';
import Modal from 'components/structure/Modal/Modal';
import InputText from 'components/form/Text/TextInput/InputText';
import { errorToast, successToast } from 'utils/toasts';

import * as S from './EanSkusExclusionModal.styles';

const ModalSteps = {
  SEARCH_SKUS: {
    title: 'Excluir EAN em massa',
    description: 'Informe o número do EAN para excluir todos SKUs do EAN. ',
    className: '',
  },
  CONFIRM_EXCLUSION: {
    title: 'Deseja excluir todos SKU?',
    description:
      'Essa ação irá excluir permanentemente todos SKUs relacionados ao EAN abaixo e não poderá ser desfeita, deseja continuar?',
    className: 'step--exclusion',
  },
};

const EanSkusExclusionModal = ({ isOpen, setIsOpen, onSuccessCallback }) => {
  const product = useSelector((state) => state.sku.product);
  const { items } = useSelector((state) => state.sku.skus);
  const [step, setStep] = useState(ModalSteps.SEARCH_SKUS);
  const [eanToSearch, setEanToSearch] = useState('');
  const [notFoundError, setNotFoundError] = useState(false);
  const [loadingResponse, setLoadingResponse] = useState(false);
  const filteredItems = items?.filter((item) => item.ean === eanToSearch) ?? [];

  const killModal = () => {
    setEanToSearch('');
    setStep(ModalSteps.SEARCH_SKUS);
    setIsOpen(false);
  };

  const handleChangeEanInput = (e) => {
    setEanToSearch(e.target.value);
    if (notFoundError) setNotFoundError(false);
  };

  const handleSubmitSearch = () => {
    if (!filteredItems.length) {
      setNotFoundError(true);
      return;
    }

    setStep(ModalSteps.CONFIRM_EXCLUSION);
  };

  const handleSubmitExclusion = async () => {
    try {
      setLoadingResponse(true);
      await api.delete(`${SKUS}/batch/${eanToSearch}`);
      successToast('EAN excluido com sucesso');
      killModal();
      onSuccessCallback();
    } catch (error) {
      if (error.response !== undefined) {
        const { status, data } = error.response;
        switch (error.response.status) {
          case 400:
            errorToast(
              'Não foi possível remover os SKUS deste EAN, verifique as informações.',
            );
            break;
          default:
            errorToast(`Erro: ${status}: ${data.error}`);
            break;
        }
      } else {
        errorToast(
          `Falha ao se comunicar com o servidor, verifique a conexão!`,
        );
      }
    } finally {
      setLoadingResponse(false);
    }
  };

  return (
    <Modal
      zIndex="1400"
      isOpen={isOpen}
      setIsOpen={killModal}
      title={step.title}
      paddingTop="56px"
    >
      <S.EanSkusExclusionModalContent className={step.className}>
        <p className={step.description.className}>{step.description}</p>

        {step === ModalSteps.SEARCH_SKUS && (
          <S.Flex pt="32px" gap="16px">
            <InputText
              placeholder="Insira o número do EAN"
              value={eanToSearch}
              onChange={handleChangeEanInput}
              className="input--search"
              padding="0"
              hideLabel
              error="Nenhum SKU encontrado com esse EAN"
              isError={notFoundError}
            />
            <Button
              uppercase
              btnType="primary"
              width="141px"
              height="48px"
              onClick={handleSubmitSearch}
              className="btn--submit-search"
            >
              Pesquisar
            </Button>
          </S.Flex>
        )}

        {step === ModalSteps.CONFIRM_EXCLUSION && (
          <>
            <S.ResumeAction>
              <S.ResumeActionBlock>
                <span className="action-block--title">EAN</span>
                <span>{eanToSearch}</span>
              </S.ResumeActionBlock>
              <S.ResumeActionBlock>
                <span className="action-block--title">Produto</span>
                <span>{product.name}</span>
              </S.ResumeActionBlock>
            </S.ResumeAction>

            <S.Flex pt="52px" justify="space-between">
              <Button
                uppercase
                btnType="outlined"
                width="141px"
                height="48px"
                onClick={() => setStep(ModalSteps.SEARCH_SKUS)}
              >
                Voltar
              </Button>
              <Button
                uppercase
                className="btn--exclude"
                btnType="danger"
                width="141px"
                height="48px"
                onClick={handleSubmitExclusion}
                disabled={loadingResponse}
              >
                Excluir
              </Button>
            </S.Flex>
          </>
        )}
      </S.EanSkusExclusionModalContent>
    </Modal>
  );
};

EanSkusExclusionModal.propTypes = {
  setIsOpen: propTypes.func.isRequired,
  isOpen: propTypes.bool.isRequired,
  onSuccessCallback: propTypes.func.isRequired,
};

EanSkusExclusionModal.defaultProps = {};

export default EanSkusExclusionModal;
