import styled, { css } from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 68px 0 0 0;
`;

export const PermissionsTitle = styled.h3`
  ${({ theme }) => css`
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: ${theme.colors.secondary.default};
    padding-top: 24px;
    padding-bottom: 24px;
  `}
`;

export const ButtonPermissionsContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 31px;
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: ${theme.colors.secondary.default};
  `}
`;

export const Table = styled.table`
  ${({ theme }) => css`
    width: 75%;
    font-family: ${theme.typography.family.primary};
  `}
`;

export const TableBody = styled.tbody``;
export const TableHead = styled.thead``;

export const Td = styled.td`
  ${({ theme }) => css`
    font-family: ${theme.typography.family.primary};
    font-size: 0.85rem;
    line-height: 16px;
    text-align: left;
    padding: 12px 0 10px 0;
    border-bottom: 1px solid ${theme.colors.black.lightest2};
    color: ${theme.colors.secondary.default};

    &:first-child {
      padding: 12px 70px 12px 10px;
    }
  `}
`;

export const Th = styled.th`
  ${({ theme }) => css`
    font-family: ${theme.typography.family.primary};
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
    padding: 10px;
    border: none;
    color: ${theme.colors.tertiary.default};
    white-space: pre;
  `}
`;

export const Tr = styled.tr`
  ${({ theme }) => css`
    font-size: 1rem;
    line-height: 19px;
    width: 100%;

    &:hover {
      background: ${theme.colors.gray.lighter};
      &:first-child {
        background: none;
      }
    }
  `}
`;

export const Line = styled.hr`
  border: 0.1px solid #e8e8e8;
  margin-top: 15px;
`;
