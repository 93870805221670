import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/structure/Modal/Modal';
import Cropper from 'react-easy-crop';
import miniImage from 'assets/icons/mini_img.svg';
import largeImage from 'assets/icons/large_image.svg';
import rotateImage from 'assets/icons/arrows.svg';

import Button from 'components/structure/Button/Button';
import Slider from 'components/structure/Slider/Slider';
import { getCroppedImg } from 'utils/canvasUtils';
import {
  ButtonsContainer,
  BtnContainer,
  ButtonTitle,
  Container,
  RotateButton,
  CropperContainer,
} from './ImageCropModal.styles';

const ImageCropModal = ({
  imgSrc,
  isOpen,
  aspect,
  setIsOpen,
  setCancelButton,
  setImage,
  updateFileName,
  fileName,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [scale, setScale] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const filename = fileName.split('.', 1);

  // eslint-disable-next-line no-shadow
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const cropImageUrl = await getCroppedImg(
        imgSrc,
        croppedAreaPixels,
        rotation,
      );

      fetch(cropImageUrl)
        .then((res) => res.blob())
        .then((data) => {
          setImage(
            new File([data], `cropped_${filename[0]}.jpeg`, {
              type: `image/jpeg`,
              lastModified: Date.now(),
            }),
          );
        });
      updateFileName(`cropped_${filename[0]}.jpeg`);
    } catch (e) {
      console.error(e);
    }
  }, [imgSrc, croppedAreaPixels, rotation]);

  const handleRotation = () => {
    if (rotation === 360) {
      setRotation(30);
    } else {
      setRotation(rotation + 30);
    }
  };

  const modalContent = (
    <Container>
      <CropperContainer>
        <Cropper
          image={imgSrc}
          crop={crop}
          rotation={rotation}
          zoom={scale}
          aspect={aspect}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setScale}
        />
      </CropperContainer>
      <ButtonsContainer>
        <RotateButton onClick={handleRotation}>
          <img src={rotateImage} alt="Girar imagem" />
          <ButtonTitle>GIRAR IMAGEM</ButtonTitle>
        </RotateButton>
        <RotateButton>
          <img src={miniImage} alt="Imagem pequena" />
          <Slider
            minValue={1}
            maxValue={10}
            onChange={(e) => setScale(e.target.value)}
            value={scale}
          />
          <img src={largeImage} alt="Imagem grande" />
        </RotateButton>
      </ButtonsContainer>
      <BtnContainer>
        <Button
          btnType="secondary"
          width="140px"
          borderRadius="4px"
          onClick={() => {
            setIsOpen(false);
            setCancelButton(true);
            setCrop(null);
            setRotation(0);
            setScale(1);
          }}
          fontWeight="600"
        >
          CANCELAR
        </Button>
        <Button
          btnType="primary"
          width="140px"
          borderRadius="4px"
          onClick={() => {
            showCroppedImage();
            setIsOpen(false);
            setRotation(0);
            setScale(1);
          }}
          fontWeight="600"
        >
          SALVAR CORTE
        </Button>
      </BtnContainer>
    </Container>
  );

  return (
    <Container>
      <Modal
        title="Recortar a imagem"
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        content={modalContent}
        containerWidth="100%"
        containerHeight="100%"
      />
    </Container>
  );
};

ImageCropModal.propTypes = {
  imgSrc: PropTypes.string,
  isOpen: PropTypes.bool,
  aspect: PropTypes.number,
  setIsOpen: PropTypes.func,
  setCancelButton: PropTypes.func,
  setImage: PropTypes.func,
  updateFileName: PropTypes.func,
  fileName: PropTypes.string,
};

ImageCropModal.defaultProps = {
  imgSrc: '',
  isOpen: false,
  aspect: 0,
  setIsOpen: () => {},
  setCancelButton: () => {},
  setImage: () => {},
  updateFileName: () => {},
  fileName: '',
};

export default ImageCropModal;
