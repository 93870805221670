/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/structure/Modal/Modal';
import Button from 'components/structure/Button/Button';
import InputSlugConfirm from 'components/form/Text/InputSlugConfirm/InputSlugConfirm';
import {
  Container,
  InputContent,
  ButtonsContainer,
} from './ModalDeleteConfirmationSku.styles';

const ModalDeleteConfirmationSku = ({
  buttonName,
  isOpen,
  setIsOpen,
  confirmOnClick,
  loading,
  fieldValue,
  title,
  text,
  placeholder,
  label,
  fieldLabel,
}) => {
  const [isSameValue, setIsSameValue] = useState(null);

  const convertStringToNumber = parseInt(isSameValue, 10);
  const confirmationContent = (
    <Container>
      <p>
        {text} Digite o ID "<strong>{fieldLabel}</strong>" do SKU para
        confirmar!
      </p>
      <InputContent>
        <InputSlugConfirm
          type="number"
          label={label}
          placeholder={placeholder}
          value={convertStringToNumber}
          handleChange={(e) => setIsSameValue(e.target.value)}
        />

        <ButtonsContainer>
          <Button
            btnType="secondary"
            width="100%"
            borderRadius="4px"
            onClick={() => {
              setIsOpen();
              setIsSameValue('');
            }}
            fontWeight="600"
          >
            CANCELAR
          </Button>

          <Button
            btnType="danger"
            width="100%"
            borderRadius="4px"
            onClick={() => {
              confirmOnClick();
              setIsSameValue('');
            }}
            disabled={convertStringToNumber !== fieldValue}
            fontWeight="600"
          >
            {buttonName}
          </Button>
        </ButtonsContainer>
      </InputContent>
    </Container>
  );

  return (
    <Container>
      <Modal
        title={title}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        content={confirmationContent}
        loading={loading}
      />
    </Container>
  );
};

ModalDeleteConfirmationSku.propTypes = {
  buttonName: PropTypes.node,
  setIsOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  confirmOnClick: PropTypes.func,
  loading: PropTypes.number,
  fieldValue: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  fieldLabel: PropTypes.string,
};

ModalDeleteConfirmationSku.defaultProps = {
  buttonName: null,
  setIsOpen: () => {},
  isOpen: false,
  confirmOnClick: () => {},
  loading: 0,
  fieldValue: '',
  title: '',
  text: '',
  placeholder: '',
  label: '',
  fieldLabel: '',
};

export default ModalDeleteConfirmationSku;
