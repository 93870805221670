import React from 'react';
import styled, { css } from 'styled-components';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { colors, typography } from 'styles/tokens';

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: ${theme.colors.white.default};
    background-color: ${theme.colors.primary.default};
    border-radius: ${({ borderRadius }) =>
      borderRadius ? `${borderRadius}` : '4px'};
  `}
`;

export const Button = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: ${theme.colors.white.default};
    padding: 8px;
    font-size: 0.85rem;
    line-height: 16px;
    outline: 0;
    border: none;
    white-space: nowrap;
    text-decoration: none;
    width: ${({ width }) => `${width}`};
    height: ${({ height }) => (height ? `${height}` : '48px')};
    font-family: ${theme.typography.family.primary};
    font-weight: ${({ fontWeight }) =>
      fontWeight ? `${fontWeight}` : 'normal'};
    text-transform: uppercase;
    border-radius: ${({ borderRadius }) =>
      borderRadius ? `${borderRadius}` : '4px'};
    background: ${theme.colors.gray.light};
    transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
      box-shadow 0.1s ease, background 0.1s ease;

    &:hover {
      background: ${theme.colors.black.lightest2};
    }

    ${({ btnType }) =>
      btnType === 'primary' &&
      css`
        background-color: ${theme.colors.primary.default};

        &:hover {
          background-color: ${theme.colors.background.dark.button};
        }
      `};

    ${({ btnType }) =>
      btnType === 'danger' &&
      css`
        background-color: ${theme.colors.contextual.error.dark};

        &:hover {
          background-color: ${theme.colors.contextual.error.hover};
        }
      `};

    ${({ btnType }) =>
      btnType === 'secondary' &&
      css`
        background-color: ${theme.colors.gray.light};
        color: ${theme.colors.tertiary.default};

        &:hover {
          background-color: ${theme.colors.gray.default};
        }
      `};

    ${({ btnType }) =>
      btnType === 'light' &&
      css`
        background-color: ${theme.colors.gray.lighter};
        color: ${theme.colors.tertiary.default};

        &:hover {
          background-color: ${theme.colors.gray.default};
        }
      `};

    ${({ btnType }) =>
      btnType === 'blue' &&
      css`
        background-color: #04456b;
        color: ${theme.colors.gray.lighter};

        &:hover {
          background-color: #465368;
        }
      `};

    ${({ disabled }) =>
      disabled &&
      css`
        cursor: not-allowed;
        background-color: #bbb;

        &:hover {
          background-color: #bbb;
        }
      `};

    > img {
      margin-right: 12px;
    }
  `}
`;

export const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export const StyledMenuItem = withStyles(() => ({
  root: {
    fontFamily: typography.family.primary,
    fontSize: '0.85rem',
    fontWeight: 'bold',
    color: colors.secondary.default,
    '&:hover': {
      color: colors.primary.default,
    },
  },
}))(MenuItem);
