import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const ButtonsContent = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin-top: 5%;
  justify-content: space-around;
`;
