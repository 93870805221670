/* eslint-disable no-unused-vars */
import Button from 'components/structure/ActionButton/ActionButton';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    padding: 0px 30px;
    height: 18vh;
    border-bottom: 1px solid ${theme.colors.background.light.sidebar};
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}
`;

export const ContainerTitle = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const Title = styled.h3`
  font-family: 'Suvinil Sans';
  font-weight: bold;
  font-size: 24px;
  margin: 0;
`;

export const FilterButton = styled(Button)`
  ${({ theme }) => css`
    margin: 0 20px 4px 0;
    background: transparent;
    color: ${theme.colors.primary.default};
    font-family: ${theme.typography.family.primary};
    font-size: 16px;

    :hover {
      background: none;
    }
  `}
`;

export const AddButton = styled(Button)`
  ${({ theme }) => css`
    font-family: ${theme.typography.family.primary};
    font-weight: 600;
    font-size: 16px;
    padding: 24px 16px;
    text-transform: uppercase;
    margin-left: 16px;
  `}
`;

export const ContainerOptions = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-top: 10px;
    gap: 24px;
  `}
`;

export const ContainerSearch = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 55%;
  `}
`;

export const SearchButton = styled(Button)`
  ${({ theme }) => css`
    color: ${theme.colors.primary.default};
    font-family: ${theme.typography.family.primary};
    font-size: 16px;
    width: 48px;
    height: 48px;
    margin-right: 20px;
    margin-left: 20px;
    img {
      padding: 10px;
    }
  `}
`;

export const SearchInput = styled.input`
  ${({ theme }) => css`
    font-size: ${theme.typography.sizes.sm};
    height: 48px;
    border: none;
    outline: none;
    padding: 10px;
    width: 90%;
    background: ${theme.colors.gray.lighter};
    color: rgba(0, 0, 0, 0.87);
    -webkit-appearance: none;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      box-shadow: 0 0 0 30px ${theme.colors.gray.lighter} inset;
      font-size: ${theme.typography.sizes.sm};
      font-family: ${theme.typography.family.primary};
    }
  `}
`;

export const InputContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    border: none;
    outline: none;

    border-radius: 4px;
    width: 90%;
    background: ${theme.colors.gray.lighter};
    color: rgba(0, 0, 0, 0.87);
    -webkit-appearance: none;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      box-shadow: 0 0 0 30px ${theme.colors.gray.lighter} inset;
      font-size: ${theme.typography.sizes.sm};
      font-family: ${theme.typography.family.primary};
    }
  `}
`;

export const ResetButton = styled(Button)`
  ${({ theme }) => css`
    color: transparent;
    background-color: transparent;
    width: 9%;
    height: 5vh;

    img {
      padding: 10px;
    }
  `}
`;
