/* eslint-disable prefer-const */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import Grid from '@material-ui/core/Grid';
import ActionButtons from 'components/form/ActionButtons';
import FileInput from 'components/form/FileInput/FileInput';
import SelectSearch from 'components/form/Select/ReactSelect';
import InputText from 'components/form/Text/TextInput/InputText';
import TextAreaDescription from 'components/form/TextArea/TextAreaDescription/TextAreaDescription';
import FormContainer from 'components/structure/FormContainer/FormContainer';
import urls from 'constants/urls';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { createSlug } from 'utils/createSlug';
import { getPermission } from 'utils/permissions';
import { platforms, typesForm } from './dataOptionsCategories';
import { getDepartmentsOptions } from 'store/products/products-slice';

const CategoriesView = (props) => {
  const { permissions } = useSelector((state) => state.auth);
  const departmentItems = useSelector((state) => state.categories.items);
  const { selectId, category } = useSelector((state) => state.categories);

  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [motherDepartment, setMotherDepartment] = useState('');
  const [motherCategory, setMotherCategory] = useState('');
  const [platform, setPlatform] = useState([]);
  const [description, setDescription] = useState('');
  const [siteTitle, setSiteTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [fileName, setFileName] = useState('');
  const [altImage, setAltImage] = useState('');
  const [categoryItems, setCategoryItems] = useState([]);
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    if (getPermission('categories', permissions, 'access') === false) {
      history.push('/');
    }
  }, [history, permissions]);

  const cancelEditCategorie = () => {
    history.goBack();
  };

  useEffect(() => {
    setName(category?.name);
    setType(category?.type);
    setMotherDepartment(category?.parent?.category);
    setMotherCategory(category?.category);
    setPlatform(category?.platforms?.map((value) => value));
    setDescription(category?.description);
    setSiteTitle(category?.siteTitle);
    setMetaDescription(category?.siteDescription);
    setAltImage(category?.images?.[1]?.alternativeText);
    setFileName(category?.images?.[0]?.fullUrl);
  }, [category]);

  useEffect(() => {
    dispatch(getDepartmentsOptions());
  }, [dispatch]);

  useEffect(() => {
    if (motherDepartment !== '') {
      let data = departmentItems.find(
        (department) => department.value === motherDepartment,
      );
      let aux = data?.items?.map((option) => ({
        value: option?.id,
        label: option?.name,
      }));
      setCategoryItems(aux);
    }
  }, [departmentItems, motherDepartment]);

  return (
    <>
      <FormContainer title={`Categoria - ${name}`}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item md={12}>
                <InputText
                  placeholder={intl.messages['labelsInputs.typeYourName']}
                  label={intl.messages['labelsInputs.typeYourName']}
                  value={name}
                  handleChange={setName}
                  disabled
                />
              </Grid>
              <Grid item md={12}>
                <InputText
                  placeholder={intl.messages['labelsInputs.slug']}
                  label={intl.messages['labelsInputs.slug']}
                  disabled
                  value={createSlug(name)}
                />
              </Grid>

              <Grid item md={12}>
                <SelectSearch
                  label={intl.messages['labelsInputs.type']}
                  placeholder={intl.messages['labelsInputs.select']}
                  options={typesForm}
                  defaultOption={type}
                  handleChange={setType}
                  tooltip
                  title={intl.messages['labelsInputs.tooltipTypeCategory']}
                  disabled
                />
              </Grid>
              <Grid item md={12}>
                <SelectSearch
                  label={intl.messages['labelsInputs.motherDepartment']}
                  placeholder={intl.messages['labelsInputs.select']}
                  options={departmentItems}
                  defaultOption={motherDepartment}
                  handleChange={setMotherDepartment}
                  disabled
                  tooltip
                  title={intl.messages['labelsInputs.tooltipMotherDepartment']}
                />
              </Grid>
              <Grid item md={12}>
                <SelectSearch
                  label={intl.messages['labelsInputs.motherCategory']}
                  placeholder={intl.messages['labelsInputs.select']}
                  options={categoryItems}
                  defaultOption={motherCategory}
                  handleChange={setMotherCategory}
                  disabled
                  tooltip
                  title={intl.messages['labelsInputs.tooltipMotherCategory']}
                />
              </Grid>
              <Grid item md={12}>
                <SelectSearch
                  label={intl.messages['labelsInputs.platforms']}
                  placeholder={intl.messages['labelsInputs.select']}
                  options={platforms}
                  defaultMultiOptions={platform}
                  handleChange={setPlatform}
                  multi
                  disabled
                />
              </Grid>
              <Grid item md={12}>
                <TextAreaDescription
                  label={intl.messages['labelsInputs.description']}
                  placeholder={intl.messages['labelsInputs.description']}
                  value={description}
                  handleChange={setDescription}
                  heigth="110px"
                  tooltip={false}
                  isCount={false}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <TextAreaDescription
                  label={intl.messages['labelsInputs.metaDescription']}
                  placeholder={intl.messages['labelsInputs.metaDescription']}
                  value={metaDescription}
                  handleChange={setMetaDescription}
                  heigth="110px"
                  tooltip={false}
                  isCount={false}
                  disabled
                />
              </Grid>
              <Grid item md={12}>
                <TextAreaDescription
                  label={intl.messages['labelsInputs.siteTitle']}
                  placeholder={intl.messages['labelsInputs.siteTitle']}
                  value={siteTitle}
                  handleChange={setSiteTitle}
                  heigth="110px"
                  isCount={false}
                  tooltip={false}
                  disabled
                />
              </Grid>

              <Grid item md={12}>
                <FileInput
                  label={intl.messages['labelsInputs.uplodadImageLabel']}
                  text={intl.messages['labelsInputs.toDoUploadImage']}
                  title={intl.messages['labelsInputs.tooltipUploadImage']}
                  accept="image/*"
                  fileName={fileName}
                  disabled
                />
              </Grid>

              <Grid item md={12}>
                <TextAreaDescription
                  label={intl.messages['labelsInputs.altTextImage']}
                  placeholder={intl.messages['labelsInputs.imageDescription']}
                  value={altImage}
                  handleChange={setAltImage}
                  heigth="134px"
                  isCount={false}
                  tooltip={false}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ActionButtons
          type={props.location.state === 'deleted' ? 'none' : 'view'}
          cancelPath={() => {
            cancelEditCategorie();
          }}
          submitFunc={() => {
            history.push(urls.ROUTES.CATEGORIES_EDIT.path);
          }}
        />
      </FormContainer>
    </>
  );
};

export default CategoriesView;
