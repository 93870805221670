/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import axios from 'axios';
import * as Fd from 'form-data';
import Button from 'components/structure/Button/Button';
import { ARTICLES } from 'constants/endpoints';
import { getAccessToken } from 'lib/contexts/auth';
import RGBColorInput from 'components/contexts/colors/RGBColorInput/RGBColorInput';
import LABColorInput from 'components/contexts/colors/LABColorInput/LABColorInput';
import arrayToString from 'utils/arrayToString';

const apiKey = process.env.REACT_APP_API_KEY;
const token = getAccessToken();

/// TODO
function buildForm(params) {
  const form = new Fd();

  Object.keys(params).forEach((key) => {
    if (key === 'files') {
      form.append(key, params[key].buffer, params[key].config);
    } else {
      form.append(key, params[key]);
    }
  });

  return form;
}
async function send() {
  const formArticleParams = {
    name: 'ARTICLE_ADMIN_TEST_99',
    siteTitle: 'ARTICLE_ADMIN_TEST_99',
    description: 'ARTICLE_ADMIN_TEST_8',
    shortDescription: 'ARTICLE_ADMIN_TEST_8',
    published: 'false',
    platforms: JSON.stringify(['app']),
    defaultEditorial: 2,
  };

  const form = new Fd();

  Object.keys(formArticleParams).forEach((key) => {
    if (key === 'files') {
      form.append(
        key,
        formArticleParams[key].buffer,
        formArticleParams[key].config,
      );
    } else {
      form.append(key, formArticleParams[key]);
    }
  });
  /// TODO
  console.log(form);

  const data = new FormData();

  data.append('name', 'ARTICLE_ADMIN_TEST_99');
  data.append('siteTitle', 'ARTICLE_ADMIN_TEST_99');
  data.append('description', 'ARTICLE_ADMIN_TEST_8');
  data.append('shortDescription', 'ARTICLE_ADMIN_TEST_8');
  data.append('published', 'false');
  data.append('platforms', JSON.stringify(['app']));
  data.append('defaultEditorial', 2);

  try {
    const response = await axios.post(ARTICLES, form, {
      method: 'POST',
      headers: {
        token,
        'content-type': 'multipart/form-data',
        'x-api-key': apiKey,
      },
    });
    /// TODO
    console.log({ response: response.data });
  } catch (error) {
    /// TODO
    console.log(error);
  }
}
const BlogArticles = () => {
  const [color1, setColor1] = useState('');
  const [color2, setColor2] = useState('');
  const [color3, setColor3] = useState('');
  const [labColor1, setLabColor1] = useState('');
  const [labColor2, setLabColor2] = useState('');
  const [labColor3, setLabColor3] = useState('');

  const tempColorRgb = [color1, color2, color3];
  const colorRgb = arrayToString(tempColorRgb);

  const tempColorLab = [labColor1, labColor2, labColor3];
  const colorLab = arrayToString(tempColorLab);

  return (
    <>
      <h3>Blog Articles Page</h3>
      <Button onClick={send}>Enviar</Button>
      <RGBColorInput
        color1={color1}
        color2={color2}
        color3={color3}
        setColor1={(e) => setColor1(e)}
        setColor2={(e) => setColor2(e)}
        setColor3={(e) => setColor3(e)}
        colorRgbString={colorRgb}
      />
      <LABColorInput
        labColor1={labColor1}
        labColor2={labColor2}
        labColor3={labColor3}
        setLabColor1={setLabColor1}
        setLabColor2={setLabColor2}
        setLabColor3={setLabColor3}
        colorLabString={colorLab}
      />
    </>
  );
};

export default BlogArticles;
