import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 28vw;

    text-align: center;
    padding: 0 49px;
    z-index: 10;
    > img {
      width: 20%;
      height: 20%;
      padding-bottom: 24px;
    }

    > h3 {
      font-family: ${theme.typography.family.secondary};
      font-size: 1.5rem;
      line-height: 38px;
      font-weight: 700;
      color: ${theme.colors.tertiary.default};
      padding-bottom: 14px;
    }

    > p {
      padding-bottom: 24px;
      font-family: ${theme.typography.family.primary};
      color: ${theme.colors.tertiary.default};
      font-size: 1rem;
      line-height: 24px;
      font-weight: 500;
    }
  `}
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Separator = styled.div`
  padding: 0 20px;
`;
