/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  loading: 0,
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState: INITIAL_STATE,
  reducers: {
    startLoading: (state) => {
      state.loading = 1;
    },
    stopLoading: (state) => {
      state.loading = 0;
    },
  },
});
export const loading = loadingSlice.reducer;
export const { startLoading, stopLoading } = loadingSlice.actions;
