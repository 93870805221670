import EmptySearch from 'components/structure/EmptySearch/EmptySearch';
import PageContent from 'components/structure/PageContent/PageContent';
import PageHeader from 'components/structure/PageHeader/PageHeader';
import TableDefault from 'components/structure/Table/Table';
import Toggle from 'components/structure/ToggleTable/TogleTable';
import urls from 'constants/urls';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import tinycolor from 'tinycolor2';
import { getPermission } from 'utils/permissions';
import { Color } from '../../components/contexts/colors/RGBColorInput/RGBColorInput.styles';
import ModalDeleteConfirmation from '../../components/contexts/modal/ModalDeleteConfirmation/ModalDeleteConfirmation';
import Popover from '../../components/structure/PopOver/PopOver';
import {
  Divider,
  PopItem,
  PopOverContent,
} from '../../components/structure/PopOver/PopOver.styles';
import {
  editColorCombination,
  removeColorCombination,
  setCombinationId,
  getColorCombinationsList,
  getCombinationDetail,
} from 'store/colorCombinations/colorCombinations-slice';
import rgbToHex from '../../utils/rgbToHex';
import * as S from './ColorCombinations.styles';

const ColorCombinations = () => {
  const { permissions } = useSelector((state) => state.auth);
  const [page, setPage] = useState(1);
  const { items, totalPage } = useSelector(
    (state) => state.colorCombinations.colorCombinations,
  );
  const [selectedId, setSelectedId] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [ordenation, setOrdenation] = useState('');
  const [totalPerPage, setTotalPerPage] = useState({ value: 10 });
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
  const [selectedCombination, setSelectedCombination] = useState({});
  const perPage = totalPerPage.value;
  const [depublishingModalVisibility, setDepublishingModalVisibility] =
    useState();

  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  useEffect(() => {
    dispatch(
      getColorCombinationsList({
        currentPage,
        searchTerm,
        perPage,
        ordenation,
      }),
    );
  }, [dispatch, setCurrentPage, currentPage, searchTerm, perPage, ordenation]);

  const addColorCombination = () => {
    history.push('/admin/catalogue/color-combinations/create');
  };

  const viewCombination = useCallback(
    (id) => {
      dispatch(setCombinationId(id));
      setSelectedId(id);
      dispatch(getCombinationDetail(id));
      history.push(urls.ROUTES.COLOR_COMBINATIONS_VIEW.path);
    },
    [dispatch, history],
  );

  const editCombination = useCallback(
    (id) => {
      dispatch(setCombinationId(id));
      setSelectedId(id);
      history.push(urls.ROUTES.CATALOGUE.items.combinations_edit.path);
    },
    [dispatch, history],
  );

  const COLUMNS = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Autor',
        accessor: 'author',
      },
      {
        Header: 'Cores',
        accessor: ({ colorToColorCombinations }) => {
          return (
            <S.Colors>
              {colorToColorCombinations.map((color) => {
                const colorRgb = rgbToHex(color?.rgb || '');
                const txtColor = tinycolor(colorRgb);
                return (
                  <Color
                    key={color.id}
                    rgbCode={colorRgb || ''}
                    colorText={txtColor.isLight() ? '#666666' : '#F7F7F7'}
                  />
                );
              })}
            </S.Colors>
          );
        },
      },
      {
        Header: 'Publicado',
        accessor: ({ published, id, name }) => (
          <Toggle
            key={id}
            checked={published}
            id={id}
            page={page}
            readonly
            setData={() => setSelectedId(id)}
            onChangeFunction={() => {
              if (published) {
                setDepublishingModalVisibility(true);
                setSelectedCombination({ id, name });
              } else {
                dispatch(
                  editColorCombination({ id, info: { published: true } }),
                );
              }
            }}
          />
        ),
      },
      {
        Header: ' ',
        accessor: ({ id, name }) => (
          <Popover
            content={
              <PopOverContent>
                <PopItem onClick={() => editCombination(id)}>
                  {intl.messages['buttons.edit']}
                </PopItem>
                {
                  /* getPermission('colors', permissions, 'delete') */ true && (
                    <>
                      <Divider />
                      <PopItem
                        onClick={() => {
                          setDeleteModalVisibility(true);
                          setSelectedCombination({ id, name });
                        }}
                      >
                        {intl.messages['buttons.exclusion']}
                      </PopItem>
                    </>
                  )
                }
                {
                  /* getPermission('colors', permissions, 'access') */ true && (
                    <>
                      <Divider />
                      <PopItem onClick={() => viewCombination(id)}>
                        {intl.messages['buttons.view']}
                      </PopItem>
                    </>
                  )
                }
              </PopOverContent>
            }
          />
        ),
      },
    ],
    [],
  );

  const data = useMemo(
    () =>
      items?.map((item) => {
        return { ...item, author: 'Suvinil' };
      }),
    [items],
  );

  const optionsOrdenation = [
    { value: 'id', label: 'ID' },
    { value: 'name', label: 'Nome' },
    { value: 'colors', label: 'Cores' },
    { value: 'published', label: 'Publicado' },
  ];

  return (
    <>
      <ModalDeleteConfirmation
        title={intl.messages['textModal.areYouSure']}
        text={intl.messages['textModal.editorialDeleteConfirm']}
        placeholder="Nome"
        buttonName={intl.messages['buttons.delete']}
        setIsOpen={setDeleteModalVisibility}
        isOpen={deleteModalVisibility}
        fieldValue={selectedCombination.name}
        fieldLabel={selectedCombination.name}
        confirmOnClick={() => {
          dispatch(removeColorCombination({ id: selectedCombination.id }));
          setDeleteModalVisibility(false);
        }}
        textFieldLabel="Digite o nome da combinação para continuar"
      />
      <ModalDeleteConfirmation
        title={intl.messages['textModal.areYouSure']}
        text="Ao pressionar restaurar essa editoria será despublicada"
        placeholder="Nome"
        buttonName="Despublicar"
        setIsOpen={setDepublishingModalVisibility}
        isOpen={depublishingModalVisibility}
        fieldValue={selectedCombination.name}
        fieldLabel={selectedCombination.name}
        confirmOnClick={() => {
          dispatch(
            editColorCombination({
              id: selectedCombination.id,
              info: {
                published: false,
              },
            }),
          );
          setDepublishingModalVisibility(false);
        }}
        textFieldLabel="Digite o nome da combinação para continuar"
      />
      <PageHeader
        title="Combinações"
        onClickFilter={() => {}}
        addButtonName={intl.messages['textHeader.colorCombination']}
        onClickAdd={addColorCombination}
        addButton={getPermission('products', permissions, 'create')}
        search
        searchFunc={(searchData) => setSearchTerm(searchData)}
        placeholder="Busque por ID ou nome"
        optionsOrdenation={optionsOrdenation}
        ordenation={ordenation}
        setOrdenation={setOrdenation}
        trashRedirect={() =>
          history.push(urls.ROUTES.CATALOGUE.items.combinations_deleted.path)
        }
        trash={getPermission('color-combinations', permissions, 'list')}
      />
      <PageContent>
        <EmptySearch items={data} term={searchTerm}>
          <TableDefault
            columns={COLUMNS}
            data={data}
            pageCount={totalPage}
            defaultCurrentPage={currentPage}
            setPageCurrent={setCurrentPage}
            totalPerPage={totalPerPage}
            setTotalPerPage={setTotalPerPage}
          />
        </EmptySearch>
      </PageContent>
    </>
  );
};

export default ColorCombinations;
