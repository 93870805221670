/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { PRICES } from 'constants/endpoints';
import api from 'services/api';
import { startLoading, stopLoading } from 'store/loading/loading-slice';

import { errorToast, errorSessionToast } from 'utils/toasts';

const INITIAL_STATE = {
  sku: {
    id: 0,
  },
  prices: {
    totalPage: 1,
    totalItems: 0,
    items: [],
  },
  price: {
    id: 0,
    stockKeepingUnitId: undefined,
    state: '',
    value: 0,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
  },
  modalCreate: false,
  modalUpdate: false,
  modalDelete: false,
  openStatusModal: false,
  status: '',
  msg: {
    title: '',
    text: '',
  },
};

const pricesSlice = createSlice({
  name: 'prices',
  initialState: INITIAL_STATE,
  reducers: {
    setSkuPrice: (state, { payload }) => {
      state.sku = payload;
    },

    getPricesSuccess: (state, { payload }) => {
      state.prices = payload;
    },

    getPriceSuccess: (state, { payload }) => {
      state.price = payload;
    },

    /**
     ** Action Modals
     */
    showModalCreatePrice: (state, { payload }) => {
      state.modalCreate = payload;
    },
    showModalUpdatePrice: (state, { payload }) => {
      state.modalUpdate = payload;
    },
    showModalDeletePrice: (state, { payload }) => {
      state.modalDelete = payload;
    },

    /**
     ** Return Status Modal
     */
    statusPricesModal: (state, { payload }) => {
      state.openStatusModal = payload;
    },
    setPricesStatus: (state, { payload }) => {
      state.status = payload;
    },
    setMsg: (state, { payload }) => {
      state.msg = payload;
    },
  },
});

export const prices = pricesSlice.reducer;
export const {
  getPriceSuccess,
  getPricesSuccess,
  setMsg,
  setPricesStatus,
  setSkuPrice,
  showModalCreatePrice,
  showModalDeletePrice,
  showModalUpdatePrice,
  statusPricesModal,
} = pricesSlice.actions;

export const getListPrices = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { currentPage, searchTerm, skuId } = payload;
  try {
    let url = '';
    if (searchTerm === '') {
      url = `${PRICES}?perPage=10&page=${currentPage}&stockKeepingUnitId=${skuId}&sort.field=createdAt&sort.order=ASC`;
    } else {
      url = `${PRICES}?perPage=10&page=${currentPage}&state=${searchTerm}&stockKeepingUnitId=${skuId}&sort.field=createdAt&sort.order=ASC`;
    }
    const { data } = await api.get(url);

    dispatch(getPricesSuccess(data));
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const savePrice = (payload) => async (dispatch) => {
  const { priceData, page, searchTerm } = payload;
  dispatch(startLoading());
  try {
    await api.post(PRICES, priceData);
    const msg = {
      title: 'Preço registrado com sucesso!',
      text: 'O preço foi registrado e já pode ser visto na listagem!',
    };

    dispatch(showModalCreatePrice(false));
    dispatch(setMsg(msg));
    dispatch(setPricesStatus('success'));
    dispatch(statusPricesModal(true));
    dispatch(stopLoading());
    dispatch(
      getListPrices({
        currentPage: page,
        searchTerm,
        skuId: priceData.stockKeepingUnitId,
      }),
    );
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Erros de Validação',
            text: 'Verifique os campos e tente novamente!',
          };
          dispatch(setMsg(msg));
          dispatch(setPricesStatus('error'));
          dispatch(statusPricesModal(true));
          break;
        case 409:
          msg = {
            title: 'Dados já existentes',
            text: 'Os dados relacionados à esse Estado já se encontram na base, para modificá-los utilize Editar!',
          };
          dispatch(setMsg(msg));
          dispatch(setPricesStatus('error'));
          dispatch(statusPricesModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const getPriceDetail = (payload) => async (dispatch) => {
  const { id } = payload;
  dispatch(startLoading());
  try {
    const response = await api.get(`${PRICES}/${id}`);

    dispatch(getPriceSuccess(response.data));
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const editPrice = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { priceData, priceId, page, searchTerm } = payload;
  try {
    await api.patch(`${PRICES}/${priceId}`, priceData);
    const msg = {
      title: 'Preço alterado com sucesso!',
      text: 'As alterações já podem ser vista na listagem de preços!',
    };

    dispatch(showModalUpdatePrice(false));
    dispatch(setMsg(msg));
    dispatch(setPricesStatus('success'));
    dispatch(statusPricesModal(true));
    dispatch(stopLoading());
    dispatch(
      getListPrices({
        currentPage: page,
        searchTerm,
        skuId: priceData.stockKeepingUnitId,
      }),
    );
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Falha no Processamento!',
            text: 'Não foi possível alterar o preço, tente novamente!',
          };
          dispatch(setMsg(msg));
          dispatch(setPricesStatus('error'));
          dispatch(statusPricesModal(true));
          break;
        case 409:
          msg = {
            title: 'Dados já existentes',
            text: 'Os dados relacionados à esse Estado já se encontram na base, para modificá-los utilize Editar!',
          };
          dispatch(setMsg(msg));
          dispatch(setPricesStatus('error'));
          dispatch(statusPricesModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const removePrice = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { id, page, searchTerm, skuId } = payload;
  try {
    await api.delete(`${PRICES}/${id}`);
    const msg = {
      title: 'Preço removido com sucesso!',
      text: '',
    };
    dispatch(setMsg(msg));
    dispatch(showModalDeletePrice(false));

    dispatch(stopLoading());
    dispatch(setPricesStatus('success'));
    dispatch(statusPricesModal(true));

    dispatch(
      getListPrices({
        currentPage: page,
        searchTerm,
        skuId,
      }),
    );
  } catch (error) {
    dispatch(stopLoading());

    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          dispatch(setPricesStatus('error'));
          dispatch(statusPricesModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};
