import React from 'react';
import { getUserData } from 'lib/contexts/auth';
import { Container, WelcomeText } from './PageHome.styles';

const PageHome = () => {
  const user = getUserData();
  return (
    <Container>
      <WelcomeText>{`Bem-vindo(a), ${
        user ? user.name : 'Usuário'
      }!`}</WelcomeText>
    </Container>
  );
};
export default PageHome;
