/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { ATTRIBUTES, ROLES } from 'constants/endpoints';
import api from 'services/api';
import { startLoading, stopLoading } from 'store/loading/loading-slice';

import { errorToast, errorSessionToast, successToast } from 'utils/toasts';

const INITIAL_STATE = {
  articlesModal: false,
  articleResourceId: '',
  publishButtonResources: {
    id: '4bbf610a-e633-4f4f-8a2f-b89adeac7e11',
    name: 'Botão Publicar',
    slug: 'botao-publicar',
    type: 'boolean',
    value: false,
  },
  attributtes: [],
  attributtesList: [],
};

const morePermissionsSlice = createSlice({
  name: 'morePermissions',
  initialState: INITIAL_STATE,
  reducers: {
    setArticlesModal: (state, { payload }) => {
      state.articlesModal = payload;
    },
    setArticlesResourceId: (state, { payload }) => {
      state.articleResourceId = payload;
    },
    getAttributesSuccess: (state, { payload }) => {
      state.attributes = payload;
    },
    getAttributesListSuccess: (state, { payload }) => {
      state.attributtesList = payload;
    },
  },
});

export const morePermissions = morePermissionsSlice.reducer;
export const {
  getAttributesListSuccess,
  getAttributesSuccess,
  setArticlesModal,
  setArticlesResourceId,
} = morePermissionsSlice.actions;

export const getAttributesListOptions = () => async (dispatch) => {
  dispatch(startLoading());

  try {
    const url = `${ATTRIBUTES}?sort.order=ASC`;

    const { data } = await api.get(url);
    dispatch(getAttributesListSuccess(data?.items));

    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getAttributesListPerRole = (payload) => async (dispatch) => {
  dispatch(startLoading());

  try {
    const url = `${ROLES}/${payload}${ATTRIBUTES}`;

    const { data } = await api.get(url);
    let dataArray = [];
    if (data?.items !== undefined) {
      dataArray = data?.items;
    }
    dispatch(getAttributesListOptions());
    dispatch(getAttributesSuccess(dataArray));

    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const createAttributesValue = (payload) => async (dispatch) => {
  const { attributeData, attributeId, rolesId } = payload;
  dispatch(startLoading());

  try {
    const url = `${ATTRIBUTES}/${attributeId}${ROLES}/${rolesId}`;

    await api.post(url, attributeData);
    dispatch(getAttributesListPerRole(rolesId));

    dispatch(stopLoading());
    successToast('Permissão de publicação atribuída com sucesso!');
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const updateAttributesValue = (payload) => async (dispatch) => {
  const { attributeData, attributeId, rolesId } = payload;
  dispatch(startLoading());

  try {
    const url = `${ATTRIBUTES}/${attributeId}${ROLES}/${rolesId}`;

    await api.patch(url, attributeData);
    dispatch(getAttributesListPerRole(rolesId));
    dispatch(stopLoading());
    successToast('Permissão de publicação atribuída com sucesso!');
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};
