import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-left: 295px;
    background: ${theme.colors.black.lighter};
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background: ${theme.colors.white.default};
    border-top-left-radius: 30px;
    padding-left: 10px;
    box-shadow: inset 2px 0px rgba(0, 0, 0, 0.3);
    min-height: 100vh;
  `}
`;
