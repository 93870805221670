import styled, { css } from 'styled-components';
import Button from 'components/structure/Button/Button';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    font-family: ${theme.typography.family.primary};
  `}
`;

export const PageNavigation = styled(Button)`
  ${({ theme }) => css`
    width: 40px;
    height: 40px;
    background: ${theme.colors.gray.lighter};
    border-radius: 2px;
    margin-left: 8px;
    font-family: ${theme.typography.family.primary};

    img {
      margin: 0;
    }

    :hover {
      background: ${theme.colors.black.lightest2};
    }
  `}
`;

export const PageNumber = styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    font-family: ${theme.typography.family.primary};
    font-size: 14px;
    line-height: 26px;
    font-weight: 700;
    border: none;
    background: none;
    color: ${theme.colors.secondary.default};
    opacity: 30%;
    letter-spacing: 1px;
    cursor: pointer;

    :hover {
      color: ${theme.colors.tertiary.default};
      opacity: 0.6;
    }

    ${({ unnumbered }) =>
      unnumbered &&
      css`
        width: 8px;
        height: 8px;
        background: ${theme.colors.secondary.default};
        opacity: 0.2;
        font-family: ${theme.typography.family.primary};

        &:hover {
          background: ${theme.colors.secondary.default};
          opacity: 100%;
        }
      `};

    ${({ unnumbered, selected }) =>
      unnumbered &&
      selected &&
      css`
        box-shadow: none;
        opacity: 1;
        font-family: ${theme.typography.family.primary};

        &:hover {
          opacity: 0.6;
        }
        &:focus {
          box-shadow: none;
        }
      `};

    ${({ unnumbered, selected }) =>
      !unnumbered &&
      selected &&
      css`
        font-family: ${theme.typography.family.primary};
        font-weight: 700;
        color: ${theme.colors.secondary.default};
        opacity: 0.6;

        &:hover {
          color: ${theme.colors.tertiary.default};
        }

        &:active {
          opacity: 70%;
        }
      `};
  `}
`;
