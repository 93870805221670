import { rgbToColorString } from 'polished';

const rgbToHex = (rgb) => {
  const arrayRGB = rgb.split(',', 3);

  const objColor = {
    red: Number(arrayRGB[0]),
    green: Number(arrayRGB[1]),
    blue: Number(arrayRGB[2]),
  };

  return rgbToColorString(objColor);
};

export default rgbToHex;
