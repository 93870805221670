import styled, { css } from 'styled-components';

export const ContainerTable = styled.div`
  width: 100%;
  border-collapse: collapse;

  span {
    margin-left: 8px;
  }
`;

export const Table = styled.table`
  ${({ theme }) => css`
    width: 100%;
    font-family: ${theme.typography.family.primary};
  `}
`;

export const TableBody = styled.tbody``;
export const TableHead = styled.thead``;

export const Td = styled.td`
  ${({ theme }) => css`
    font-family: ${theme.typography.family.primary};
    font-size: 0.85rem;
    line-height: 16px;
    text-align: left;
    padding: 12px 10px;
    border-bottom: 1px solid ${theme.colors.black.lightest2};
    color: ${theme.colors.secondary.default};

    &:not(:last-child) {
      ${({ isColorTable }) =>
        isColorTable &&
        css`
          padding: 0 10px;
        `};
    }
  `}
`;

export const Th = styled.th`
  ${({ theme }) => css`
    font-family: ${theme.typography.family.primary};
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
    padding: 10px;
    border: none;
    color: ${theme.colors.tertiary.default};
    white-space: pre;
    min-width: 40%;
  `}
`;

export const Tr = styled.tr`
  ${({ theme }) => css`
    font-size: 1rem;
    line-height: 19px;
    width: 100%;
    min-width: 40%;

    &:hover {
      background: ${theme.colors.gray.lighter};
      &:first-child {
        background: none;
      }
    }
  `}
`;
