import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 70vw;
`;

export const ImageStyle = styled.img`
  display: block;
  max-width: 100%;
  object-fit: contain;
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  gap: 12px;
  padding-top: 36px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 26px;
`;

export const RotateButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  cursor: pointer;
`;

export const ButtonTitle = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary.default};
  font-family: ${({ theme }) => theme.typography.family.secondary};
  font-weight: 700;
  padding-top: 4px;
`;

export const CropperContainer = styled.div`
  position: relative;
  width: 60vw;
  height: 50vh;
`;
