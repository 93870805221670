import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import {
  Container,
  FormTitle,
  TitleContainer,
  AddButton,
} from './FormContainerSecondary.styles';
import { preventRedirectEnabled } from 'store/sidebar/sidebar-slice';

const FormContainer = ({ children, title, action, ...rest }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(preventRedirectEnabled());
  }, [dispatch]);

  const NewSkuScope = () => (
    <AddButton
      btnType={action.type}
      borderRadius="4px"
      width="100%"
      height="34px"
      onClick={action.onClick}
    >
      {action.label}
    </AddButton>
  );

  return (
    <Container {...rest}>
      <TitleContainer>
        <FormTitle>{title}</FormTitle> {action ? NewSkuScope() : ''}
      </TitleContainer>
      <div>{children}</div>
    </Container>
  );
};

FormContainer.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  action: PropTypes.object,
};

FormContainer.defaultProps = {
  action: null,
};

export default FormContainer;
