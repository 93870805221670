import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerButtons = styled.div`
  display: flex;
  padding: 30px 0 0 0px;
  justify-content: flex-start;
  align-items: center;
`;

export const ContentButton = styled.div`
  margin-left: -16px;
`;
