import styled, { css } from 'styled-components';
import sortIcon from 'assets/icons/sort.svg';

export default styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: ${theme.colors.default};
    padding: 8px;
    font-size: ${theme.typography.sizes.sm};
    line-height: 16px;
    outline: 0;
    border: none;
    white-space: nowrap;
    text-decoration: none;
    width: ${({ width }) => `${width}`};
    height: ${({ height }) => (height ? `${height}` : '48px')};
    font-family: ${theme.typography.family.primary};
    font-weight: ${({ fontWeight }) =>
      fontWeight ? `${fontWeight}` : 'normal'};
    text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
    border-radius: ${({ borderRadius }) =>
      borderRadius ? `${borderRadius}` : '4px'};
    background: ${theme.colors.gray.light};
    transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
      box-shadow 0.1s ease, background 0.1s ease;

    &:hover {
      background: ${theme.colors.black.lightest2};
    }

    ${({ btnType }) =>
      btnType === 'primary' &&
      css`
        background-color: ${theme.colors.primary.default};
        color: ${theme.colors.white.default};
        &:hover {
          background-color: ${theme.colors.primary.hover};
        }
      `};

    ${({ btnType }) =>
      btnType === 'danger' &&
      css`
        background-color: ${theme.colors.contextual.error.default};

        &:hover {
          background-color: ${theme.colors.contextual.error.hover};
        }
      `};

    ${({ btnType }) =>
      btnType === 'secondary' &&
      css`
        background-color: ${theme.colors.gray.default};
        color: ${theme.colors.tertiary.default};

        &:hover {
          background-color: ${theme.colors.gray.light};
        }
      `};

    ${({ btnType }) =>
      btnType === 'light' &&
      css`
        background-color: ${theme.colors.gray.lighter};
        color: ${theme.colors.tertiary.default};

        &:hover {
          background-color: ${theme.colors.gray.default};
        }
      `};

    ${({ btnType }) =>
      btnType === 'outlined' &&
      css`
        background-color: ${theme.colors.gray.lighter};
        color: ${theme.colors.primary.default};
        border: 1px solid ${theme.colors.primary.default};

        &:hover {
          background-color: ${theme.colors.gray.default};
        }

    `};

    ${({ disabled }) =>
      disabled &&
      css`
        cursor: not-allowed;
        background-color: #bbb;

        &:hover {
          background-color: #bbb;
        }
      `};

    > img {
      padding-right: 5px;
    }
  `}
`;

export const SortButton = styled.button`
  display: inherit;
  margin-left: 5px;
  background: transparent url(${sortIcon}) no-repeat center;
  width: 20px;
  height: 20px;
  padding: 0;
  border: none;
  vertical-align: text-bottom;
`;
