import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 29vw;
    text-align: center;
    padding: 0 49px;

    > img {
      width: 20%;
      height: 20%;
      margin-bottom: 24px;
    }

    > h3 {
      font-family: ${theme.typography.family.secondary};
      font-size: 1.5rem;
      line-height: 38px;
      font-weight: 700;
      color: ${theme.colors.tertiary.default};
      margin-bottom: 16px;
    }

    > p {
      margin-bottom: 24px;
      font-family: ${theme.typography.family.primary};
      color: ${theme.colors.tertiary.default};
      font-size: 1rem;
      line-height: 24px;
      font-weight: 500;
    }
  `}
`;
