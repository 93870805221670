/* eslint-disable react/prop-types */
import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ARTICLES, EDITORIALS } from '../../../../constants/endpoints';

import AsyncSelectPaginate from '../../../form/Select/AsyncSelectPaginate/AsyncSelectPaginate';
import SelectInputOptions from '../../../form/Select/ReactSelect/SelectInputOptions';
import Button from '../../../structure/Button/Button';
import { ButtonsContainer, Container } from './RedirectArticleModalForm.styles';
import { redirectArticleUpdate } from 'store/articles/articles-slice';

const RedirectArticleModalForm = ({
  setModalVisibility,
  advanceFunction,
  id,
  slug,
}) => {
  const [selectedRedirect, setSelectedRedirect] = useState('');
  const [fetchData, setFetchData] = useState();
  const [redirectElement, setRedirectElement] = useState();

  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    switch (selectedRedirect.value) {
      case 'articles':
        setRedirectElement(
          <AsyncSelectPaginate
            label="Artigos"
            value={fetchData}
            url={ARTICLES}
            perPage={50}
            handleChange={setFetchData}
            tooltip
            title="Artigos"
          />,
        );
        break;
      case 'editorials':
        setRedirectElement(
          <AsyncSelectPaginate
            label="Editoria"
            value={fetchData}
            url={EDITORIALS}
            perPage={50}
            handleChange={setFetchData}
            tooltip
            title="Editoria"
          />,
        );
        break;
      case 'home':
        setRedirectElement(
          <SelectInputOptions
            disabled
            value={{ label: 'Home', value: 'home' }}
            handleChange={() => setFetchData({ ...fetchData, id: 0 })}
          />,
        );
        break;
      default:
        break;
    }
  }, [selectedRedirect.value, fetchData]);

  const redirectOptions = [
    { label: 'Artigos', value: 'articles' },
    { label: 'Editoria', value: 'editorials' },
    { label: 'Home', value: 'home' },
  ];

  return (
    <Container>
      <p>
        Para realizar esta ação você deve indicar para onde o usuário será
        redirecionado ao acessar o artigo
      </p>
      <form>
        <SelectInputOptions
          label="Opções de redirecionamento"
          options={redirectOptions}
          value={selectedRedirect}
          handleChange={(value) => {
            setFetchData();
            setRedirectElement(
              <SelectInputOptions
                disabled
                value={{ label: 'Home', value: 'home' }}
              />,
            );
            setSelectedRedirect(value);
          }}
          tooltip
        />
        {redirectElement}
        <ButtonsContainer>
          <Grid item md={3} sm={12}>
            <Button
              btnType="secondary"
              width="100%"
              borderRadius="4px"
              fontWeight="600"
              onClick={() => setModalVisibility(false)}
            >
              {intl.messages['buttons.cancel']}
            </Button>
          </Grid>
          <Grid item md={3} sm={12}>
            <Button
              btnType="primary"
              width="100%"
              borderRadius="4px"
              fontWeight="600"
              onClick={() => {
                advanceFunction();
                setModalVisibility(false);
                dispatch(
                  redirectArticleUpdate({
                    entityId: id,
                    entity: 'articles',
                    destination: selectedRedirect.value,
                    destinationId: fetchData.id,
                    slug,
                  }),
                );
              }}
            >
              {intl.messages['buttons.advance']}
            </Button>
          </Grid>
        </ButtonsContainer>
      </form>
    </Container>
  );
};

export default RedirectArticleModalForm;
