import ModalDeleteConfirmation from 'components/contexts/modal/ModalDeleteConfirmation/ModalDeleteConfirmation';
import PageContent from 'components/structure/PageContent/PageContent';
import PageHeader from 'components/structure/PageHeader/PageHeader';
import PopOver from 'components/structure/PopOver/PopOver';
import TableDefault from 'components/structure/Table/Table';
import Toggle from 'components/structure/ToggleTable/TogleTable';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import ModalMultiConfirmation from 'components/contexts/modal/ModalMultiConfirmation/ModalMultiConfirmation';
import EmptySearch from 'components/structure/EmptySearch/EmptySearch';
import {
  Divider,
  PopItem,
  PopOverContent,
} from 'components/structure/PopOver/PopOver.styles';
import urls from 'constants/urls';
import { useDispatch, useSelector } from 'react-redux';

import { getPermission } from 'utils/permissions';
import { types } from './dataTypesTranslations';
import {
  dismissModalDelete,
  editColorGroups,
  getColorGroupDetail,
  getColorGroupsList,
  removeColorGroups,
  setColorGroupsId,
  showModalDelete,
  statusColorGroupsModal,
} from 'store/colorGroups/colorGroups-slice';
import {
  getColorDetail,
  getListColoursByGroup,
  setColourId,
} from 'store/colours/colours-slice';

const ColorGroups = () => {
  const { items, totalPage } = useSelector(
    (state) => state.colorGroups.colorGroups,
  );
  const { openDeleteModal, openStatusModal, msg, status } = useSelector(
    (state) => state.colorGroups,
  );
  const { permissions } = useSelector((state) => state.auth);

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedSlug, setSelectedSlug] = useState(null);

  const [searchTerm, setSearchTerm] = useState('');
  const [ordenation, setOrdenation] = useState('');
  const [totalPerPage, setTotalPerPage] = useState({ value: 10 });
  const perPage = totalPerPage.value;

  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  useEffect(() => {
    dispatch(
      getColorGroupsList({ currentPage, searchTerm, perPage, ordenation }),
    );
  }, [dispatch, currentPage, searchTerm, perPage, ordenation]);

  const addColorGroup = () => {
    history.push(urls.ROUTES.COLOR_GROUPS_CREATE.path);
  };

  const editColorGroupPage = useCallback(
    (id) => {
      setSelectedId(id);
      dispatch(setColorGroupsId(id));
      history.push(urls.ROUTES.COLOR_GROUPS_EDIT.path);
    },
    [dispatch, history],
  );

  const viewColorGroupPage = useCallback(
    (id) => {
      dispatch(getColorGroupDetail(parseInt(id, 10)));
      setSelectedId(id);
      dispatch(setColorGroupsId(id));
      history.push({
        pathname: urls.ROUTES.COLOR_GROUPS_VIEW.path,
        state: 'view',
      });
    },
    [dispatch, history],
  );

  const viewActivities = useCallback(
    (id) => {
      history.push(
        `${urls.ROUTES.ACCESS.items.logsByRecord.path}/${id}/colorGroups`,
      );
    },
    [history],
  );

  const viewColors = useCallback(
    (id) => {
      setSelectedId(id);
      dispatch(setColourId(id));
      dispatch(
        getListColoursByGroup({
          id,
          currentPage: 1,
          searchTerm: '',
          perPage: 10,
          ordenation,
        }),
      );
      dispatch(getColorGroupDetail(id));
      dispatch(getColorDetail(parseInt(id, 10)));
      history.push(`${urls.ROUTES.CATALOGUE.items.colorsByGroup.path}/${id}`);
    },
    [history],
  );

  const deleteColorGroupsModal = useCallback(
    (id, slug) => {
      setSelectedId(id);
      setSelectedSlug(slug);
      dispatch(showModalDelete());
    },
    [dispatch],
  );

  const findTranslate = (type) => {
    const found = types.find((data) => data.value === type);
    return found.label;
  };

  const COLUMNS = useMemo(
    () => [
      { Header: 'ID', accessor: 'id' },
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Tipo',
        accessor: ({ type }) => findTranslate(type),
      },
      {
        Header: 'Ativos',
        accessor: ({ active, id }) => (
          <Toggle
            key={id}
            checked={active}
            id={id}
            p
            setData={() => {
              const formData = new FormData();
              formData.append('active', !active);
              dispatch(
                editColorGroups({
                  info: formData,
                  id,
                }),
              );
              if (status === 'success') {
                setTimeout(() => {
                  dispatch(
                    getColorGroupsList({
                      currentPage,
                      searchTerm,
                      perPage,
                      ordenation,
                    }),
                  );
                }, 1000);
              }
            }}
            page={currentPage}
            readonly
          />
        ),
      },
      {
        Header: ' ',
        accessor: ({ id, slug }) =>
          getPermission('color-groups', permissions, 'update') === false ? (
            <></>
          ) : (
            <PopOver
              content={
                <PopOverContent>
                  <PopItem
                    onClick={() => {
                      editColorGroupPage(id);
                    }}
                  >
                    {intl.messages['buttons.edit']}
                  </PopItem>
                  {getPermission('color-groups', permissions, 'delete') && (
                    <>
                      <Divider />
                      <PopItem onClick={() => deleteColorGroupsModal(id, slug)}>
                        {intl.messages['buttons.exclusion']}
                      </PopItem>
                    </>
                  )}
                  {getPermission('color-groups', permissions, 'access') && (
                    <>
                      <Divider />
                      <PopItem onClick={() => viewColorGroupPage(id)}>
                        {intl.messages['buttons.view']}
                      </PopItem>
                    </>
                  )}
                  <Divider />
                  <PopItem onClick={() => viewActivities(id)}>
                    Ver atividades
                  </PopItem>
                  <>
                    <Divider />
                    <PopItem onClick={() => viewColors(id)}>Ver Cores</PopItem>
                  </>
                </PopOverContent>
              }
            />
          ),
      },
    ],
    [
      currentPage,
      deleteColorGroupsModal,
      editColorGroupPage,
      intl.messages,
      permissions,
    ],
  );
  const data = useMemo(() => items, [items]);

  const optionsOrdenation = [
    { value: 'id', label: 'ID' },
    { value: 'name', label: 'Nome' },
    { value: 'type', label: 'Tipo' },
  ];

  return (
    <>
      <ModalDeleteConfirmation
        title={intl.messages['textModal.areYouSure']}
        text={intl.messages['textModal.colorGroupDeleteConfirm']}
        placeholder={intl.messages['labelsInputs.enterSlug']}
        buttonName={intl.messages['buttons.delete']}
        setIsOpen={() => dispatch(dismissModalDelete())}
        isOpen={openDeleteModal}
        fieldValue={selectedSlug}
        fieldLabel={selectedSlug}
        confirmOnClick={() =>
          dispatch(removeColorGroups({ id: selectedId, page: currentPage }))
        }
        textFieldLabel={intl.messages['labelsInputs.enterSlug']}
      />
      <ModalMultiConfirmation
        title={msg.title}
        text={msg.text}
        setIsOpen={(payload) => {
          dispatch(statusColorGroupsModal(payload));
        }}
        isOpen={openStatusModal}
        status={status}
      />
      <PageHeader
        title={intl.messages['textHeader.colorGroups']}
        addButtonName={intl.messages['textHeader.newColorGroup']}
        onClickAdd={addColorGroup}
        onClickFilter={() => {}}
        addButton={getPermission('color-groups', permissions, 'create')}
        search
        searchFunc={(searchData) => setSearchTerm(searchData)}
        placeholder="Busque por Nome"
        optionsOrdenation={optionsOrdenation}
        ordenation={ordenation}
        setOrdenation={setOrdenation}
        trash={getPermission('color-groups', permissions, 'list')}
        trashRedirect={() =>
          history.push(urls.ROUTES.COLOR_GROUPS_DELETED.path)
        }
      />
      <PageContent>
        <EmptySearch items={data} term={searchTerm}>
          <TableDefault
            columns={COLUMNS}
            data={data}
            pageCount={totalPage}
            defaultCurrentPage={currentPage}
            setPageCurrent={setCurrentPage}
            totalPerPage={totalPerPage}
            setTotalPerPage={setTotalPerPage}
          />
        </EmptySearch>
      </PageContent>
    </>
  );
};

export default ColorGroups;
