import * as Yup from 'yup';

export const schema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.{6,16}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#_$%^&*])/,
      'Sua senha deve conter letra maiúscula, letra minúscula, caractere especial, número, mínimo de 6 e máximo de 16 caracteres.',
    )
    .required('A senha é obrigatória'),
  passwordConfirmation: Yup.string()
    .oneOf(
      [Yup.ref('password'), null],
      'A senha está diferente, tente de novo.',
    )
    .required(
      'Sua senha deve conter letra maiúscula, letra minúscula, caractere especial, número, mínimo de 6 e máximo de 16 caracteres.',
    ),
});

export const defaultValues = {
  password: '',
  passwordConfirmation: '',
};
