import { Grid } from '@material-ui/core';
import sortIcon from 'assets/icons/sort.svg';
import AsyncSelectPaginate from 'components/form/Select/AsyncSelectPaginate/AsyncSelectPaginate';
import SelectSearch from 'components/form/Select/ReactSelect';
import InputText from 'components/form/Text/TextInput/InputText';
import Toggle from 'components/form/Toggle/Toggle';
import AccordionItem from 'components/structure/AccordionItem/AccordionItem';
import Button from 'components/structure/Button/Button';
import { COLORS } from 'constants/endpoints';
import urls from 'constants/urls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ButtonsContainer } from '../Skus.styles';

import { useSkuStepContext } from '../../../providers/SkuProvider';
import { systemColors } from '../SkuStep/Edit/dataSkusOptions';
import {
  OrdenationContainer,
  OrdenationsHeaderContainer,
} from './SkuSecondStep.styles';

const SkuSecondStep = ({ setActiveStep }) => {
  const intl = useIntl();
  const history = useHistory();
  const [columnOrder, setColumnOrder] = useState('');
  const [order, setOrder] = useState(1);
  const { stepData, setStepData } = useSkuStepContext();

  const handleChange = (index, value, objIdentifier) => {
    const list = [...stepData];

    list[index][`${objIdentifier}`] = value;

    setStepData(list);
  };

  function compareValues(key) {
    return function innerSort(a, b) {
      const varA =
        typeof a[key].name === 'string'
          ? a[key].name.toUpperCase()
          : a[key].name;
      const varB =
        typeof b[key].name === 'string'
          ? b[key].name.toUpperCase()
          : b[key].name;

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === -1 ? comparison * -1 : comparison;
    };
  }

  const handleOrder = (fieldName) => {
    const ordered = stepData.sort(compareValues(fieldName));
    setStepData(ordered);
  };

  const options = [
    { value: 'institucional', label: 'Suvinil' },
    { value: 'glasu', label: 'Glasu' },
    { value: 'app', label: 'App' },
    { value: 'ecommerce', label: 'Ecommerce' },
  ];

  const cancelCreate = () => {
    setActiveStep(0);
    history.push(urls.ROUTES.SKU_LIST.path);
  };

  const [statusForm, setStatus] = useState({
    type: '',
  });

  function validate() {
    if (!stepData) {
      return setStatus({
        type: 'error',
      });
    }
    if (stepData.find((e) => e.ean === '' || !e.ean)) {
      return setStatus({
        type: 'error',
      });
    }
    if (stepData.find((e) => e.sap === '' || !e.sap)) {
      return setStatus({
        type: 'error',
      });
    }
    if (stepData.find((e) => e.systemColor.name === '')) {
      return setStatus({
        type: 'error',
      });
    }
    if (stepData.find((e) => e.platforms.length === 0)) {
      return setStatus({
        type: 'error',
      });
    }
    if (stepData.find((e) => e.colors.length === 0)) {
      return setStatus({
        type: 'error',
      });
    }
    if (stepData.find((e) => e.size === '')) {
      return setStatus({
        type: 'error',
      });
    }
    if (stepData.find((e) => e.width === '')) {
      return setStatus({
        type: 'error',
      });
    }
    if (stepData.find((e) => e.height === '')) {
      return setStatus({
        type: 'error',
      });
    }
    if (stepData.find((e) => e.length === '')) {
      return setStatus({
        type: 'error',
      });
    }

    if (stepData.find((e) => e.liquidWeight === '' || !e.liquidWeight)) {
      return setStatus({
        type: 'error',
      });
    }
    if (stepData.find((e) => e.grossWeight === '' || !e.grossWeight)) {
      return setStatus({
        type: 'error',
      });
    }
    if (stepData.find((e) => e.yield === '' || !e.yield)) {
      return setStatus({
        type: 'error',
      });
    }
    if (stepData.find((e) => e.finishYield === '' || !e.finishYield)) {
      return setStatus({
        type: 'error',
      });
    }
    return true;
  }

  return (
    <>
      <OrdenationsHeaderContainer>
        <OrdenationContainer
          onClick={() => {
            setColumnOrder('base');
            if (order === 1) {
              setOrder(-1);
            }
            if (order === -1) {
              setOrder(1);
            }
            handleOrder('base');
          }}
        >
          <span>Base</span>
          {columnOrder === 'base' ? (
            order === -1 ? (
              <TiArrowSortedUp
                size={14}
                color="#000000"
                style={{ marginLeft: '16px', cursor: 'pointer' }}
              />
            ) : (
              <TiArrowSortedDown
                size={14}
                color="#000000"
                style={{ marginLeft: '16px', cursor: 'pointer' }}
              />
            )
          ) : (
            <img
              src={sortIcon}
              alt=""
              style={{ paddingLeft: '16px', cursor: 'pointer' }}
            />
          )}
        </OrdenationContainer>
        <OrdenationContainer
          onClick={() => {
            setColumnOrder('size');
            if (order === 1) {
              setOrder(-1);
            }
            if (order === -1) {
              setOrder(1);
            }
            handleOrder('size');
          }}
        >
          <span>Tamanho</span>
          {columnOrder === 'size' ? (
            order === -1 ? (
              <TiArrowSortedUp
                size={14}
                color="#000000"
                style={{ marginLeft: '16px', cursor: 'pointer' }}
              />
            ) : (
              <TiArrowSortedDown
                size={14}
                color="#000000"
                style={{ marginLeft: '16px', cursor: 'pointer' }}
              />
            )
          ) : (
            <img
              src={sortIcon}
              alt=""
              style={{ paddingLeft: '16px', cursor: 'pointer' }}
            />
          )}
        </OrdenationContainer>
      </OrdenationsHeaderContainer>
      {stepData.map((item, index) => (
        <AccordionItem
          isError={statusForm.type === 'error'}
          itemHeading={{ base: item.base.name, size: item.size.name }}
          type="primary"
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item md={12}>
                  <InputText
                    label="EAN"
                    placeholder="000"
                    value={item.ean}
                    handleChange={(value) => handleChange(index, value, 'ean')}
                    error={
                      statusForm.type === 'error'
                        ? 'Campo EAN deve ser preenchido'
                        : ''
                    }
                    isError={(!item.ean || item.ean === '') && statusForm.type}
                  />
                </Grid>
                <Grid item md={12}>
                  <InputText
                    placeholder="000"
                    label="SAP"
                    value={item.sap}
                    handleChange={(value) => handleChange(index, value, 'sap')}
                    error={
                      statusForm.type === 'error'
                        ? 'Campo SAP deve ser preenchido'
                        : ''
                    }
                    isError={(!item.sap || item.sap === '') && statusForm.type}
                  />
                </Grid>
                <Grid item md={12}>
                  <SelectSearch
                    label={intl.messages['labelsInputs.systemColorSku']}
                    placeholder={intl.messages['labelsInputs.select']}
                    options={systemColors}
                    defaultOption={item.systemColor}
                    handleChange={(value) =>
                      handleChange(index, value, 'systemColor')
                    }
                    error={
                      statusForm.type === 'error'
                        ? 'Campo Sistema de cor deve ser preenchido'
                        : ''
                    }
                    isError={
                      (!item.systemColor.name ||
                        item.systemColor.name === '') &&
                      statusForm.type
                    }
                  />
                </Grid>
                <Grid item md={12}>
                  <SelectSearch
                    label="Plataformas"
                    placeholder="Selecione"
                    options={options}
                    defaultMultiOptions={item.platforms}
                    multi
                    handleChange={(value) =>
                      handleChange(index, value, 'platforms')
                    }
                    error={
                      statusForm.type === 'error'
                        ? 'Campo Plataformas deve ser preenchido'
                        : ''
                    }
                    isError={item.platforms.length === 0 && statusForm.type}
                  />
                </Grid>
                <Grid item md={12}>
                  <InputText
                    placeholder="000"
                    label="Largura"
                    type="number"
                    value={item.width}
                    handleChange={(value) =>
                      handleChange(index, value, 'width')
                    }
                    error={
                      statusForm.type === 'error'
                        ? 'Campo Largura deve ser preenchido'
                        : ''
                    }
                    isError={item.width === '' && statusForm.type}
                  />
                </Grid>
                <Grid item md={12}>
                  <InputText
                    placeholder="000"
                    label="Altura"
                    type="number"
                    value={item.height}
                    handleChange={(value) =>
                      handleChange(index, value, 'height')
                    }
                    error={
                      statusForm.type === 'error'
                        ? 'Campo Altura deve ser preenchido'
                        : ''
                    }
                    isError={item.height === '' && statusForm.type}
                  />
                </Grid>
                <Grid item md={12}>
                  <InputText
                    placeholder="000"
                    label="Comprimento"
                    type="number"
                    value={item.length}
                    handleChange={(value) =>
                      handleChange(index, value, 'length')
                    }
                    error={
                      statusForm.type === 'error'
                        ? 'Campo Comprimento deve ser preenchido'
                        : ''
                    }
                    isError={item.length === '' && statusForm.type}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item md={12}>
                  <InputText
                    placeholder="000"
                    label="Peso Líquido"
                    type="number"
                    value={item.liquidWeight}
                    handleChange={(value) =>
                      handleChange(index, value, 'liquidWeight')
                    }
                    error={
                      statusForm.type === 'error'
                        ? 'Campo Peso Líquido deve ser preenchido'
                        : ''
                    }
                    isError={
                      (item.liquidWeight === '' || !item.liquidWeight) &&
                      statusForm.type
                    }
                  />
                </Grid>
                <Grid item md={12}>
                  <InputText
                    placeholder="000"
                    label="Peso Bruto"
                    type="number"
                    value={item.grossWeight}
                    handleChange={(value) =>
                      handleChange(index, value, 'grossWeight')
                    }
                    error={
                      statusForm.type === 'error'
                        ? 'Campo Peso Bruto deve ser preenchido'
                        : ''
                    }
                    isError={
                      (item.grossWeight === '' || !item.grossWeight) &&
                      statusForm.type
                    }
                  />
                </Grid>
                <Grid item md={12}>
                  <InputText
                    placeholder="000"
                    label="Rendimento"
                    type="number"
                    value={item.yield}
                    handleChange={(value) =>
                      handleChange(index, value, 'yield')
                    }
                    error={
                      statusForm.type === 'error'
                        ? 'Campo Rendimento deve ser preenchido'
                        : ''
                    }
                    isError={
                      (item.yield === '' || !item.yield) && statusForm.type
                    }
                  />
                </Grid>
                <Grid item md={12}>
                  <InputText
                    placeholder="000"
                    label="Rendimento Acabado"
                    type="number"
                    value={item.finishYield}
                    handleChange={(value) =>
                      handleChange(index, value, 'finishYield')
                    }
                    error={
                      statusForm.type === 'error'
                        ? 'Campo Rendimento Acabado deve ser preenchido'
                        : ''
                    }
                    isError={
                      (item.finishYield === '' || !item.finishYield) &&
                      statusForm.type
                    }
                  />
                </Grid>
                <Grid item md={12}>
                  <Toggle
                    label="Padrão"
                    checked={item.default}
                    onChange={(value) =>
                      handleChange(index, value.target.checked, 'default')
                    }
                    regular
                    paddingBottom="4px"
                  />
                </Grid>
                <Grid item md={12}>
                  <AsyncSelectPaginate
                    label={intl.messages['labelsInputs.colorSku']}
                    value={item.colors}
                    url={COLORS}
                    perPage={50}
                    placeholder="Selecione"
                    handleChange={(value) =>
                      handleChange(index, value, 'colors')
                    }
                    multi
                    title={intl.messages['labelsInputs.colorSku']}
                    error={
                      statusForm.type === 'error'
                        ? 'Campo Largura deve ser preenchido'
                        : ''
                    }
                    isError={item.colors.length < 1 && statusForm.type}
                  />
                </Grid>
                <Grid item md={12}>
                  <Toggle
                    label={intl.messages['labelsInputs.active']}
                    checked={item.active}
                    onChange={(e) => {
                      handleChange(index, e.target.checked, 'active');
                    }}
                    regular
                    paddingTop="4px"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionItem>
      ))}
      <ButtonsContainer>
        <Grid container spacing={2}>
          <Grid item md={3} xs={12}>
            <Button
              btnType="blue"
              width="100%"
              fontWeight="600"
              onClick={() => {
                setActiveStep(0);
              }}
            >
              {intl.messages['buttons.toBack']}
            </Button>
          </Grid>
          <Grid item md={3} sm={12} />
          <Grid item md={3} sm={12}>
            <Button
              btnType="secondary"
              width="100%"
              borderRadius="4px"
              fontWeight="600"
              onClick={() => cancelCreate()}
            >
              {intl.messages['buttons.cancel']}
            </Button>
          </Grid>
          <Grid item md={3} sm={12}>
            <Button
              btnType="primary"
              width="100%"
              borderRadius="4px"
              fontWeight="600"
              onClick={() => {
                if (validate()) {
                  setActiveStep(2);
                }
              }}
            >
              {intl.messages['buttons.advance']}
            </Button>
          </Grid>
        </Grid>
      </ButtonsContainer>
    </>
  );
};

export default SkuSecondStep;

SkuSecondStep.propTypes = { setActiveStep: PropTypes.func };

SkuSecondStep.defaultProps = {
  setActiveStep: () => { },
};
