import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { Container, PageNavigation, PageNumber } from './Pagination.styles';

const Pagination = ({ type, totalPages, currentPage, onPageChange }) => {
  const [index, setIndex] = useState(currentPage || 1);

  useEffect(() => {
    setIndex(currentPage);
  }, [currentPage]);

  const renderPages = (allPages) => {
    const second = (calcAllPagesSecond) => {
      if (calcAllPagesSecond > 5 && index > 3) return '...';
      if (calcAllPagesSecond > 1 && index <= 5) return 2;
      return null;
    };

    const dinamicPage = (CalcPagesDinamic) => {
      if (CalcPagesDinamic > 5 && index >= 3 && index <= CalcPagesDinamic - 2) {
        return index;
      }
      if (CalcPagesDinamic > 5 && index >= CalcPagesDinamic - 2) {
        return CalcPagesDinamic - 2;
      }
      if (CalcPagesDinamic > 2 && index <= 5) {
        return 3;
      }
      return null;
    };

    const penultimate = (calcPagesPenultimate) => {
      if (calcPagesPenultimate < 6) {
        return 4;
      }
      if (calcPagesPenultimate > 5 && index < calcPagesPenultimate - 2) {
        return '...';
      }
      if (calcPagesPenultimate > 5 || index >= calcPagesPenultimate - 2) {
        return calcPagesPenultimate - 1;
      }
      if (calcPagesPenultimate > 5 && index === 5) {
        return 5;
      }
      return null;
    };

    const spreadUnnumberedPages = () => {
      if (allPages > 2 && allPages < 6) return 1;
      if (allPages > 5) return allPages - 4;
      return null;
    };

    const goToPage = (pageNumber) => {
      if (pageNumber !== index) setIndex(pageNumber);
      onPageChange(pageNumber);
    };

    const renderDinamicPage = (calcallPagesRenderDinamic) => {
      if (type === 'numbered') {
        return (
          <PageNumber
            title={`página ${dinamicPage(calcallPagesRenderDinamic)}`}
            key={dinamicPage(calcallPagesRenderDinamic)}
            aria-label={`go to page ${dinamicPage(calcallPagesRenderDinamic)}`}
            selected={index === dinamicPage(calcallPagesRenderDinamic)}
            onClick={() => goToPage(dinamicPage(calcallPagesRenderDinamic))}
          >
            {type === 'numbered' && dinamicPage(calcallPagesRenderDinamic)}
          </PageNumber>
        );
      }
      if (type === 'unnumbered') {
        const unnumberedPages = [];
        for (let page = 0; page < spreadUnnumberedPages(); page += 1) {
          unnumberedPages.push(
            <PageNumber
              key={page + 3}
              unnumbered
              title={`Página ${page + 3}`}
              aria-label={`Ir para a página ${page + 3}`}
              selected={index === page + 3}
              onClick={() => goToPage(page + 3)}
            />,
          );
        }
        return unnumberedPages;
      }
      return null;
    };

    const pageNumbers = [
      <PageNumber
        key="1"
        title="Primeira página"
        aria-label="go to page 1"
        unnumbered={type === 'unnumbered'}
        selected={index === 1}
        onClick={() => goToPage(1)}
      >
        {type === 'numbered' && 1}
      </PageNumber>,
      allPages > 1 && (
        <PageNumber
          key="2"
          title="Segunda página"
          aria-label="go to page 2"
          unnumbered={type === 'unnumbered'}
          selected={index === 2}
          onClick={() => goToPage(2)}
        >
          {type === 'numbered' && second(allPages)}
        </PageNumber>
      ),
      allPages > 2 && renderDinamicPage(allPages),
      allPages > 3 && (
        <PageNumber
          title="Penúltima página"
          key={penultimate(allPages)}
          aria-label={`go to page ${allPages - 1}`}
          unnumbered={type === 'unnumbered'}
          selected={
            (allPages === 4 && index === 4) ||
            (allPages === 5 && index === 4) ||
            (allPages > 5 && index === allPages - 1)
          }
          onClick={() => goToPage(allPages - 1)}
        >
          {type === 'numbered' && penultimate(allPages)}
        </PageNumber>
      ),
      allPages > 4 && (
        <PageNumber
          title="Última página"
          key={allPages}
          aria-label={`go to page ${allPages}`}
          unnumbered={type === 'unnumbered'}
          selected={index === allPages}
          onClick={() => goToPage(allPages)}
        >
          {type === 'numbered' && allPages}
        </PageNumber>
      ),
    ];

    return pageNumbers;
  };

  const prevPage = () => {
    if (index > 1) {
      setIndex((curr) => {
        const next = curr - 1;
        return next;
      });
      onPageChange(index - 1);
    }
  };

  const nextPage = () => {
    if (index < totalPages) {
      setIndex((curr) => {
        const next = curr + 1;
        return next;
      });
      onPageChange(index + 1);
    }
  };

  return (
    <Container>
      {renderPages(totalPages)}
      {type === 'numbered' && totalPages > 1 && (
        <PageNavigation aria-label="previous page" onClick={prevPage}>
          <ArrowLeft />
        </PageNavigation>
      )}
      {type === 'numbered' && totalPages > 1 && (
        <PageNavigation aria-label="next page" onClick={nextPage}>
          <ArrowRight />
        </PageNavigation>
      )}
    </Container>
  );
};

Pagination.propTypes = {
  type: PropTypes.string.isRequired,
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
