import Button from 'components/structure/Button/Button';
import PageContent from 'components/structure/PageContent/PageContent';
import PageHeader from 'components/structure/PageHeader/PageHeader';
import TableDefault from 'components/structure/Table/Table';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import EmptySearch from '../../components/structure/EmptySearch/EmptySearch';
import { getLogsListByRecord } from 'store/logs/logs-slice';

const LogsByRecord = () => {
  const { items, totalPage } = useSelector((state) => state.logs.logs);
  const [searchTerm, setSearchTerm] = useState('');
  const [ordenation, setOrdenation] = useState('');
  const [page, setPage] = useState(1);
  const [totalPerPage, setTotalPerPage] = useState({ value: 10 });
  const perPage = totalPerPage.value;

  const { id, entityName } = useParams();

  const dispatch = useDispatch();
  const history = useHistory();

  const optionsOrdenation = [
    { value: 'user', label: 'Usuário' },
    { value: 'status', label: 'Status' },
    { value: 'createdAt', label: 'Data e Hora' },
    { value: 'action', label: 'Ação' },
  ];

  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  const COLUMNS = useMemo(
    () => [
      {
        Header: 'Usuário',
        accessor: ({ user }) => user.email,
      },
      {
        Header: 'Status',
        accessor: 'translatedStatus',
      },
      {
        Header: 'Data e Hora',
        accessor: ({ createdAt }) => new Date(createdAt).toLocaleString(),
      },
      {
        Header: 'Ação',
        accessor: 'translatedAction',
      },
    ],
    [],
  );

  useEffect(() => {
    dispatch(
      getLogsListByRecord({
        page,
        searchTerm,
        perPage,
        ordenation,
        id,
        entityName,
      }),
    );
  }, [dispatch, page, searchTerm, perPage, ordenation, id, entityName]);

  const data = useMemo(() => items, [items]);

  return (
    <div>
      <PageHeader
        title={`Atividades de ${id}`}
        onClickFilter={() => {}}
        search
        searchFunc={(searchData) => setSearchTerm(searchData)}
        placeholder="Busque por Usuário"
        optionsOrdenation={optionsOrdenation}
        ordenation={ordenation}
        setOrdenation={setOrdenation}
      />

      <PageContent>
        <EmptySearch items={data} term={searchTerm}>
          <TableDefault
            columns={COLUMNS}
            data={data}
            pageCount={totalPage}
            defaultCurrentPage={page}
            setPageCurrent={setPage}
            totalPerPage={totalPerPage}
            setTotalPerPage={setTotalPerPage}
          />
        </EmptySearch>
        <Button
          className="backbutton"
          btnType="blue"
          width="20%"
          fontWeight="600"
          onClick={() => history.goBack()}
        >
          VOLTAR
        </Button>
      </PageContent>
    </div>
  );
};
export default LogsByRecord;
