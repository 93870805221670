import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15px;

  > svg {
    position: absolute;
    right: 10px;
    font-size: 20px;
  }

  .error-icon {
    position: absolute;
    width: 20px;
    top: 36px;
    right: 14px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.colors.secondary.dark};
    font-weight: bold;
    font-size: ${theme.typography.sizes.sm};
    line-height: 17px;
  `}
`;

export const InputStyle = styled.input`
  ${({ theme }) => css`
    position: relative;
    margin: 0;
    outline: 0;
    line-height: 24px;
    padding: 8px;
    font-size: ${theme.typography.sizes.sm};
    background: ${theme.colors.gray.lighter};
    border: none;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 5px;
    box-shadow: inset 0 0 0 0 transparent;
    transition: color 0.1s ease, border-color 0.1s ease;
    height: 48px;
    width: 100%;
    opacity: ${({ disabled }) => (disabled ? 0.5 : '')};
    -webkit-appearance: none;

    &:-webkit-autofill {
      box-shadow: 0 0 0 30px ${theme.colors.gray.lighter} inset;
    }

    ${({ isError }) =>
      isError && `border: 1px solid ${theme.colors.contextual.error.default}`}
  `}
`;
