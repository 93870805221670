/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/structure/Modal/Modal';
import Button from 'components/structure/Button/Button';
import successIcon from 'assets/icons/check-success.svg';
import errorIcon from 'assets/icons/check-error.svg';
import infoIcon from 'assets/icons/check-info.svg';
import confirmIcon from 'assets/icons/check-gray.svg';
import alertIcon from 'assets/icons/info-circle.svg';
import clock from 'assets/icons/clock.svg';
import {
  Container,
  BtnContainer,
  Separator,
} from './ModalMultiConfirmation.styles';

const getIcon = (sts) => {
  switch (sts) {
    case 'success':
      return <img src={successIcon} alt="" />;
    case 'error':
      return <img src={errorIcon} alt="" />;
    case 'info':
      return <img src={infoIcon} alt="" />;
    case 'confirm':
      return <img src={confirmIcon} alt="" />;
    case 'alert':
      return <img src={alertIcon} alt="" />;
    case 'queue':
      return <img src={clock} alt="" />;
    default:
      return <></>;
  }
};

const getButtons = (sts, backFunc, confirmFunc) => {
  switch (sts) {
    case 'success':
      return (
        <BtnContainer>
          <Button
            btnType="primary"
            width="140px"
            borderRadius="4px"
            onClick={backFunc}
            fontWeight="600"
          >
            OK
          </Button>
        </BtnContainer>
      );
    case 'error':
      return (
        <BtnContainer>
          <Button
            btnType="primary"
            width="140px"
            borderRadius="4px"
            onClick={backFunc}
            fontWeight="600"
          >
            OK
          </Button>
        </BtnContainer>
      );
    case 'info':
      return (
        <BtnContainer>
          <Button
            btnType="secondary"
            width="140px"
            borderRadius="4px"
            onClick={backFunc}
            fontWeight="600"
          >
            NÃO
          </Button>
          <Separator />
          <Button
            btnType="primary"
            width="140px"
            borderRadius="4px"
            onClick={confirmFunc}
            fontWeight="600"
          >
            SIM
          </Button>
        </BtnContainer>
      );
    case 'confirm':
      return (
        <BtnContainer>
          <Button
            btnType="blue"
            width="140px"
            borderRadius="4px"
            onClick={backFunc}
            fontWeight="600"
          >
            VOLTAR
          </Button>
          <Separator />
          <Button
            btnType="primary"
            width="140px"
            borderRadius="4px"
            onClick={confirmFunc}
            fontWeight="600"
          >
            SIM
          </Button>
        </BtnContainer>
      );
    case 'queue':
      return (
        <BtnContainer>
          <Button
            btnType="primary"
            width="140px"
            borderRadius="4px"
            onClick={confirmFunc}
            fontWeight="600"
          >
            OK
          </Button>
        </BtnContainer>
      );
    default:
      return <></>;
  }
};

const ModalMultiConfirmation = ({
  status,
  children,
  title,
  text,
  isOpen,
  setIsOpen,
  confirmOnClick,
}) => {
  const confirmationContent = (
    <Container>
      {getIcon(status)}
      {title && <h3>{title}</h3>}
      {text && <p>{text}</p>}
      {children}
      {getButtons(status, () => setIsOpen(false), confirmOnClick)}
    </Container>
  );

  return (
    <Container>
      <Modal
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        content={confirmationContent}
        titleRight
        containerWidth="100%"
        containerHeight="100%"
      />
    </Container>
  );
};

ModalMultiConfirmation.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  status: PropTypes.string,
  setIsOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  confirmOnClick: PropTypes.func,
};

ModalMultiConfirmation.defaultProps = {
  title: '',
  text: '',
  setIsOpen: () => {},
  status: 'success',
  isOpen: false,
  children: null,
  confirmOnClick: () => {},
};

export default ModalMultiConfirmation;
