/* eslint-disable no-unused-vars */
import urls from 'constants/urls';
import Home from 'containers/home/Home';
import ForgotPassword from 'pages/forgotPassword/ForgotPassword';
import ResetPassword from 'pages/resetPassword/ResetPassword';
import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Login from './pages/login/Login';

const {
  APP,
  AUTH,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  USERS,
  EDITORIALS,
  EDITORIALS_CREATE,
  EDITORIALS_EDIT,
  EDITORIALS_VIEW,
  ARTICLES,
  ARTICLES_CREATE,
  TAGS,
  BLOG,
  ARTICLES_TEST,
  BASES,
  ROLES,
  ROLES_CREATE,
  ROLES_EDIT,
  ROLES_VIEW,
  COLORS_CREATE,
  COLORS_EDIT,
  COLORS_VIEW,
  CATALOGUE,
  COLOR_GROUPS_CREATE,
  COLOR_GROUPS_EDIT,
  COLOR_GROUPS_VIEW,
  DRY_PACKAGE_CREATE,
  DRY_PACKAGE_EDIT,
  DRY_PACKAGE_VIEW,
  CATEGORIES,
  CATEGORIES_CREATE,
  CATEGORIES_EDIT,
  CATEGORIES_VIEW,
  PRODUCTS_CREATE,
  PRODUCTS_EDIT,
  PRODUCTS_VIEW,
  PROPERTIES,
  SKU_LIST,
  PRICES_LIST,
  SKU_CREATE,
  SKU_EDIT,
  SKU_VIEW,
  SKU_CREATE_MANY,
  ARTICLES_VIEW,
  NOTIFICATIONS,
} = urls.ROUTES;

const AppRoutes = () => (
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <Switch>
      <Route exact {...AUTH} component={Login} />
      <Route exact {...FORGOT_PASSWORD} component={ForgotPassword} />
      <Route exact {...RESET_PASSWORD} component={ResetPassword} />
      <Route exact {...APP} component={Home} />
      <Route {...USERS} component={Home} />
      <Route {...NOTIFICATIONS} component={Home} />
      <Route {...EDITORIALS} component={Home} />
      <Route {...EDITORIALS_CREATE} component={Home} />
      <Route {...EDITORIALS_EDIT} component={Home} />
      <Route {...EDITORIALS_VIEW} component={Home} />
      <Route {...ARTICLES} component={Home} />
      <Route {...ARTICLES_CREATE} component={Home} />
      <Route {...ARTICLES_VIEW} component={Home} />
      <Route {...TAGS} component={Home} />
      <Route {...BLOG} component={Home} />
      <Route {...ARTICLES_TEST} component={Home} />
      <Route {...BASES} component={Home} />
      <Route {...ROLES} component={Home} />
      <Route {...ROLES_CREATE} component={Home} />
      <Route {...ROLES_EDIT} component={Home} />
      <Route {...ROLES_VIEW} component={Home} />
      <Route {...COLORS_CREATE} component={Home} />
      <Route {...COLORS_EDIT} component={Home} />
      <Route {...COLORS_VIEW} component={Home} />
      <Route {...CATALOGUE} component={Home} />
      <Route {...COLOR_GROUPS_CREATE} component={Home} />
      <Route {...COLOR_GROUPS_EDIT} component={Home} />
      <Route {...COLOR_GROUPS_VIEW} component={Home} />
      <Route {...DRY_PACKAGE_CREATE} component={Home} />
      <Route {...DRY_PACKAGE_EDIT} component={Home} />
      <Route {...DRY_PACKAGE_VIEW} component={Home} />
      <Route {...CATEGORIES} component={Home} />
      <Route {...CATEGORIES_CREATE} component={Home} />
      <Route {...CATEGORIES_EDIT} component={Home} />
      <Route {...CATEGORIES_VIEW} component={Home} />
      <Route {...PRODUCTS_CREATE} component={Home} />
      <Route {...PRODUCTS_EDIT} component={Home} />
      <Route {...PRODUCTS_VIEW} component={Home} />
      <Route {...PROPERTIES} component={Home} />
      <Route {...SKU_LIST} component={Home} />
      <Route {...PRICES_LIST} component={Home} />
      <Route {...SKU_CREATE} component={Home} />
      <Route {...SKU_CREATE_MANY} component={Home} />
      <Route {...SKU_EDIT} component={Home} />
      <Route {...SKU_VIEW} component={Home} />
    </Switch>
  </BrowserRouter>
);

export default AppRoutes;
