import React, { useState, memo, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import ModalMultiConfirmation from 'components/contexts/modal/ModalMultiConfirmation/ModalMultiConfirmation';
import AsyncSelectPaginate from 'components/form/Select/AsyncSelectPaginate/AsyncSelectPaginate';
import InputText from 'components/form/Text/TextInput/InputText';
import ActionButtons from 'components/form/ActionButtons';

import { UNITS, MATERIALS } from 'constants/endpoints';
import { DryPackageSchema, useTitleByRule } from './helpers';
import {
  saveDryPackage,
  patchDryPackage,
  getDryPackage,
} from 'store/dryPackages/dryPackages-slice';
import { useDispatch, useSelector } from 'react-redux';
import urls from 'constants/urls';

import * as S from './styles';

const NumericInput = memo(({ ...params }) => (
  <InputText {...params} padding="0" type="number" min={1} step="any" />
));

export const DryPackageForm = ({ rule }) => {
  const [material, setMaterial] = useState(null);
  const [unit, setUnit] = useState(null);
  const [showModalConfirmationCancel, setShowModalConfirmationCancel] =
    useState(false);

  const intl = useIntl();
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const { register, handleSubmit, watch, setValue } = useForm({
    resolver: yupResolver(DryPackageSchema),
    shouldFocusError: true,
  });
  const { dryPackage } = useSelector((state) => state.dryPackage);

  const getDryPackageDetails = useCallback(
    () => dispatch(getDryPackage(params)),
    [dispatch, params],
  );

  useEffect(() => {
    if (params.id && rule === 'update') {
      // eslint-disable-next-line eqeqeq
      if (dryPackage.id != params.id) {
        getDryPackageDetails();
      }
      setUnit({
        ...dryPackage,
        alias: dryPackage.unitAlias,
        id: dryPackage.lengthUnitId,
      });
      setMaterial({
        ...dryPackage.material,
        id: dryPackage.materialId,
      });
      setValue('width', dryPackage.width);
      setValue('height', dryPackage.height);
      setValue('length', dryPackage.width);
    }
  }, []);

  const whenSubmitClick = useCallback(
    (data) => {
      const payload = {
        ...data,
        unitAlias: unit.alias,
        materialId: material.id,
        widthUnitId: unit.id,
        heightUnitId: unit.id,
        lengthUnitId: unit.id,
      };

      if (rule === 'update') {
        payload.id = params.id;
        dispatch(patchDryPackage(payload));
      } else {
        dispatch(saveDryPackage(payload));
      }
    },
    [material, unit, dispatch, params.id, rule],
  );

  return (
    <S.ContainerBox>
      <S.FormContainer title={useTitleByRule(rule)}>
        <ModalMultiConfirmation
          title="Você realmente quer cancelar?"
          text={intl.messages['textModal.allWillBeLost']}
          isOpen={showModalConfirmationCancel}
          setIsOpen={setShowModalConfirmationCancel}
          buttonNameBack={intl.messages['buttons.not']}
          buttonName={intl.messages['buttons.yes']}
          confirmOnClick={() =>
            history.push(urls.ROUTES.CATALOGUE.items.dryPackages.path)
          }
          status="info"
        />
        <form onSubmit={handleSubmit(whenSubmitClick)}>
          <S.InputContainerGrid>
            <S.InputSubContainerGrid>
              <InputText
                label="Envase"
                name="name"
                padding="0"
                register={register}
                hookForm
                disabled={rule !== 'create'}
              />
              <AsyncSelectPaginate
                zIndex="50"
                label="Unidade"
                name="unitAlias"
                placeholder="Selecione"
                value={unit}
                handleChange={setUnit}
                getOptionLabel={(option) => option.alias}
                physicalQuantity="length"
                url={UNITS}
                perPage={5}
                disabled={rule !== 'create'}
              />
              <AsyncSelectPaginate
                zIndex="40"
                label="Material"
                name="materialId"
                placeholder="Selecione"
                value={material}
                handleChange={setMaterial}
                url={MATERIALS}
                perPage={5}
                hookForm
                disabled={rule !== 'create'}
              />
            </S.InputSubContainerGrid>
            <S.InputSubContainerGrid>
              <NumericInput
                label="Largura"
                name="width"
                register={register}
                hookForm
                disabled={rule === 'view'}
              />
              <NumericInput
                label="Comprimento"
                name="length"
                register={register}
                hookForm
                disabled={rule === 'view'}
              />
              <NumericInput
                label="Altura"
                name="height"
                register={register}
                hookForm
                disabled={rule === 'view'}
              />
            </S.InputSubContainerGrid>
          </S.InputContainerGrid>
          <ActionButtons
            type={rule === 'update' ? 'create' : rule}
            cancelPath={() => setShowModalConfirmationCancel(true)}
            formType
            submitFunc={() => {
              if (rule === 'view') {
                history.push(
                  `${urls.ROUTES.DRY_PACKAGE_EDIT.path}/${params.id}`,
                );
              }
            }}
            disabled={
              rule !== 'view'
                ? ![
                    watch('name'),
                    watch('length'),
                    watch('height'),
                    watch('width'),
                    unit,
                    material,
                  ].every(Boolean)
                : false
            }
          />
        </form>
      </S.FormContainer>
    </S.ContainerBox>
  );
};

DryPackageForm.propTypes = {
  rule: PropTypes.string,
};

DryPackageForm.defaultProps = {
  rule: 'create',
};
