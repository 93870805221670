/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/structure/Modal/Modal';
import Button from 'components/structure/Button/Button';
import InputSlugConfirm from 'components/form/Text/InputSlugConfirm/InputSlugConfirm';
import {
  Container,
  InputContent,
  ButtonsContainer,
} from './ModalDeleteConfirmation.styles';

const ModalDeleteConfirmation = ({
  buttonName,
  isOpen,
  setIsOpen,
  confirmOnClick,
  loading,
  fieldValue,
  fieldLabel,
  title,
  text,
  textFieldLabel,
  placeholder,
  label,
}) => {
  const [isSameValue, setIsSameValue] = useState('');

  const confirmationContent = (
    <Container>
      {text && <p>{text}</p>}
      <span>
        {textFieldLabel} "<strong>{fieldLabel}</strong>" para confirmar.
      </span>

      <InputContent>
        <InputSlugConfirm
          type="text"
          label={label}
          placeholder={placeholder}
          value={isSameValue}
          handleChange={(e) => setIsSameValue(e.target.value)}
        />

        <ButtonsContainer>
          <Button
            btnType="secondary"
            width="100%"
            borderRadius="4px"
            onClick={() => {
              setIsOpen();
              setIsSameValue('');
            }}
            fontWeight="600"
          >
            CANCELAR
          </Button>

          <Button
            btnType="danger"
            width="100%"
            borderRadius="4px"
            onClick={() => {
              confirmOnClick();
              setIsSameValue('');
            }}
            disabled={isSameValue !== fieldValue}
            fontWeight="600"
          >
            {buttonName}
          </Button>
        </ButtonsContainer>
      </InputContent>
    </Container>
  );

  return (
    <Container>
      <Modal
        title={title}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        content={confirmationContent}
        loading={loading}
      />
    </Container>
  );
};

ModalDeleteConfirmation.propTypes = {
  buttonName: PropTypes.node,
  setIsOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  confirmOnClick: PropTypes.func,
  loading: PropTypes.number,
  fieldValue: PropTypes.string,
  fieldLabel: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  textFieldLabel: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
};

ModalDeleteConfirmation.defaultProps = {
  buttonName: null,
  setIsOpen: () => {},
  isOpen: false,
  confirmOnClick: () => {},
  loading: 0,
  fieldValue: '',
  fieldLabel: '',
  title: '',
  text: '',
  textFieldLabel: '',
  placeholder: '',
  label: '',
};

export default ModalDeleteConfirmation;
