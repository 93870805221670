import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputText from 'components/form/Text/TextInput/InputText';
import SelectSearch from 'components/form/Select/ReactSelect';
import Grid from '@material-ui/core/Grid';
import Button from 'components/structure/Button/Button';

import { schema } from './EditTagModalForm.validations';
import {
  Container,
  ButtonsContainer,
} from '../AddTagModalForm/AddTagModalForm.styles';
import { editTag as updateTag } from 'store/tags/tags-slice';

const EditTagModalForm = ({ cancelActionModal, editActionModal }) => {
  const { selectId, tag } = useSelector((state) => state.tags);

  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });
  const [platforms, setPlatforms] = useState(tag.platforms);

  const dispatch = useDispatch();
  const intl = useIntl();

  const options = [
    { value: 'institucional', label: 'Suvinil' },
    { value: 'glasu', label: 'Glasu' },
  ];

  useEffect(() => {
    reset(tag.name);
    setPlatforms(tag.platforms);
  }, [reset, tag]);

  const editTag = (data) => {
    const { name } = data;
    const arrayPlatform = [];
    arrayPlatform.push(platforms);
    const tagData = {
      name,
      platforms: arrayPlatform,
    };
    editActionModal();
    dispatch(updateTag({ info: tagData, id: selectId }));
  };

  function validate(obj) {
    if (obj === null || obj === undefined) {
      return false;
    }
    return true;
  }
  return (
    <Container>
      <form onSubmit={handleSubmit(editTag)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={12}>
                <InputText
                  name="name"
                  label="Nome"
                  error={errors.name}
                  isError={validate(errors.name)}
                  register={register}
                  defaultValue={tag.name}
                  hookForm
                >
                  {intl.messages['labelsInputs.typeYourName']}
                </InputText>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ paddingTop: validate(errors.name) ? '0px' : '21px' }}
              >
                <SelectSearch
                  label={intl.messages['labelsInputs.typePlatform']}
                  options={options}
                  defaultOption={platforms}
                  handleChange={setPlatforms}
                  multi={false}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ButtonsContainer
          platformError={errors.platforms}
          nameError={validate(errors.name)}
        >
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Button
                type="button"
                btnType="secondary"
                width="100%"
                height="44px"
                borderRadius="8px"
                uppercase
                onClick={cancelActionModal}
                fontWeight="600"
              >
                {intl.messages['buttons.cancel']}
              </Button>
            </Grid>
            <Grid item md={6} xs={12}>
              <Button
                type="submit"
                btnType="primary"
                width="100%"
                height="44px"
                borderRadius="8px"
                uppercase
                fontWeight="600"
              >
                {intl.messages['buttons.toSave']}
              </Button>
            </Grid>
          </Grid>
        </ButtonsContainer>
      </form>
    </Container>
  );
};

EditTagModalForm.propTypes = {
  cancelActionModal: PropTypes.func,
  editActionModal: PropTypes.func,
};

EditTagModalForm.defaultProps = {
  cancelActionModal: () => {},
  editActionModal: () => {},
};

export default EditTagModalForm;
