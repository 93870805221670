/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InputText from 'components/form/Text/TextInput/InputText';
import TextAreaDescription from 'components/form/TextArea/TextAreaDescription/TextAreaDescription';
import Grid from '@material-ui/core/Grid';
import { createSlug } from 'utils/createSlug';
import Button from 'components/structure/Button/Button';
import FileInput from 'components/form/FileInput/FileInput';
import SelectInputOptions from 'components/form/Select/ReactSelect/SelectInputOptions';
import AsyncSelectPaginateEditorial from 'components/form/Select/AsyncSelectPaginate/AsyncSelectPaginateEditorial';
import urls from 'constants/urls';
import { errorToast } from 'utils/toasts';

import Resizer from 'react-image-file-resizer';
import ModalMultiConfirmation from 'components/contexts/modal/ModalMultiConfirmation/ModalMultiConfirmation';
import ImageCropModal from 'components/contexts/modal/ImageCropModal/ImageCropModal';
import { ButtonsContainer } from './ArticlesFirstStep.styles';
import {
  setActiveStep,
  statusArticlesModal,
  saveArticleStepOne,
} from 'store/articles/articles-slice';
import AsyncSelectPaginateSEOContent from 'components/form/Select/AsyncSelectPaginate/AsyncSelectPaginateSEOContent';

const seoTypeOptions = [
  { value: 'pdp', label: 'Página de produto' },
  { value: 'collection', label: 'Lista de Compras/Coleções' },
];

const DEFAULT_EDITORIAL = 12;

const ArticlesFirstStep = () => {
  const { openStatusModal, status, msg } = useSelector(
    (state) => state.articles,
  );

  const options = [
    { value: 'institucional', label: 'Suvinil' },
    { value: 'glasu', label: 'Glasu' },
  ];

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [imgSrc, setImgSrc] = useState('');

  const [resource, setResource] = useState(null);
  const [seoType, setSeoType] = useState(null);
  const [siteName, setSiteName] = useState('');
  const [meta, setMeta] = useState('');
  const [altImage, setAltImage] = useState('');
  const [platforms, setPlatforms] = useState('');
  const isPublished = 'false';
  const [fileName, setFileName] = useState('');
  const [imageError, setImageError] = useState(null);
  const [dataImage, setDataImage] = useState([{}]);
  const [showModalCancel, setShowModalCancel] = useState(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);

  const [cancelImage, setCancelImage] = useState(false);
  const [updateImage, setUpdateImage] = useState();

  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const [statusForm, setStatus] = useState({
    type: '',
  });

  function validate() {
    const requiredFields = [seoType, resource, platforms, siteName, meta];
    if (requiredFields.some((field) => !field)) {
      setStatus({
        type: 'error',
      });
      return false;
    }
    return true;
  }

  const cancelArticle = () => {
    history.push(urls.ROUTES.ARTICLES.path);
  };

  const onchangeSelectPlatform = (item) => {
    setPlatforms(item);
  };

  const isImage = (extension) => {
    switch (extension.toLowerCase()) {
      case 'jpg':
      case 'gif':
      case 'bmp':
      case 'jpeg':
      case 'webp':
      case 'png':
      case 'svg':
        return true;
      default:
        return false;
    }
  };

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  const getImageFile = async (event) => {
    if (event.target.files.length !== 0) {
      const file = event.target.files[0];
      const filenames = file.name.split('.');
      const fileType = filenames[filenames.length - 1];
      if (file.size < 5 * 1024 * 1024) {
        if (isImage(fileType)) {
          setFileName(file.name);
          setDataImage({ ...dataImage, image: file });
          let imageDataUrl = await readFile(file);
          setImgSrc(imageDataUrl);
          setImageError(null);
          setIsOpenModal(true);
        } else {
          setImageError(
            errorToast(
              'A imagem precisa estar em um desses formatos: .png, .jpg, .gif, etc...',
            ),
          );
        }
      } else {
        setImageError(errorToast('A imagem deve ter no máximo 5 MB'));
      }
    }
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        platforms.value === 'glasu' ? 1100 : 1366,
        platforms.value === 'glasu' ? 400 : 512,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'file',
        platforms.value === 'glasu' ? 1100 : 1366,
        platforms.value === 'glasu' ? 400 : 512,
      );
    });

  const getResizedImage = async () => {
    const resizedImage = await resizeFile(dataImage.image);
    setDataImage({ ...dataImage, image: resizedImage });
    return resizedImage;
  };

  const saveStepOne = async () => {
    const arrayPlatforms = [];
    arrayPlatforms.push(platforms.value);

    if (!validate()) {
      setShowModalConfirmation(false);
      return;
    }

    const formData = new FormData();
    formData.append('name', resource.name);
    formData.append('slug', resource.slug);
    formData.append('type', seoType?.value);
    formData.append('siteTitle', siteName);
    formData.append('description', '<h2>Título do conteúdo de SEO</h2>');
    formData.append('shortDescription', meta);
    formData.append('platforms', platforms.value);
    formData.append('author', 'Suvinil');
    formData.append('published', isPublished);
    formData.append('platforms', JSON.stringify(arrayPlatforms));
    formData.append('editorialId', DEFAULT_EDITORIAL);

    if (dataImage?.image) {
      const imagem = await getResizedImage();

      const arrayAltImage = [];
      arrayAltImage.push(altImage);
      formData.append('alternativeText', JSON.stringify(arrayAltImage));
      formData.append('image', imagem);
    }

    dispatch(
      saveArticleStepOne({
        info: formData,
        goTo: 1,
      }),
    );
  };

  useEffect(() => {
    if (cancelImage) {
      setImgSrc('');
      setFileName('');
      setDataImage([{}]);
    }
  }, [cancelImage]);

  useEffect(() => {
    setImgSrc('');
    setFileName('');
    setDataImage([{}]);
  }, [platforms.value]);

  useEffect(() => {
    setDataImage({ ...dataImage, image: updateImage });
  }, [updateImage]);

  useEffect(() => {
    if (dataImage.image) {
      getResizedImage();
    }
  }, [dataImage.image]);

  const handleSEOTypeChange = (value) => {
    setSeoType(value);
    setResource(null);
  };

  return (
    <>
      <ImageCropModal
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        imgSrc={imgSrc}
        aspect={platforms.value === 'glasu' ? 11 / 4 : 16 / 6}
        setCancelButton={setCancelImage}
        setImage={setUpdateImage}
        updateFileName={setFileName}
        fileName={fileName}
      />
      <ModalMultiConfirmation
        title={intl.messages['textModal.areYouSure']}
        text={intl.messages['textModal.createArticleConfirm']}
        isOpen={showModalConfirmation}
        setIsOpen={setShowModalConfirmation}
        buttonNameBack={intl.messages['buttons.not']}
        buttonName={intl.messages['buttons.yes']}
        status="confirm"
        confirmOnClick={saveStepOne}
      />
      <ModalMultiConfirmation
        title={msg.title}
        text={msg.text}
        setIsOpen={(payload) => {
          dispatch(statusArticlesModal(payload));
          if (status !== 'error') {
            dispatch(setActiveStep(1));
          }
        }}
        isOpen={openStatusModal}
        status={status}
      />
      <ModalMultiConfirmation
        title={intl.messages['textModal.deleteCreateArticle']}
        text={intl.messages['textModal.articleWillBeLost']}
        isOpen={showModalCancel}
        setIsOpen={setShowModalCancel}
        buttonNameBack={intl.messages['buttons.not']}
        buttonName={intl.messages['buttons.yes']}
        confirmOnClick={cancelArticle}
        status="info"
      />

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid item md={12}>
              <SelectInputOptions
                zIndex="unset"
                label={intl.messages['labelsInputs.contentSEOTypeLabel']}
                value={seoType}
                handleChange={handleSEOTypeChange}
                options={seoTypeOptions}
                error={
                  statusForm.type === 'error'
                    ? 'Tipo de conteúdo deve ser preenchido'
                    : ''
                }
                isError={!seoType && statusForm.type}
              />
            </Grid>

            <Grid item md={12}>
              <SelectInputOptions
                zIndex="unset"
                label={intl.messages['labelsInputs.platforms']}
                value={platforms}
                handleChange={onchangeSelectPlatform}
                options={options}
                tooltip
                title={intl.messages['labelsInputs.tooltipArticlePlatforms']}
                error={
                  statusForm.type === 'error'
                    ? 'Campo Plataformas deve ser preenchido'
                    : ''
                }
                isError={platforms === '' && statusForm.type}
              />
            </Grid>
            <Grid item md={12}>
              <AsyncSelectPaginateSEOContent
                key={seoType?.value}
                seoType={seoType?.value}
                disabled={!seoType}
                label={
                  seoType?.value === 'pdp'
                    ? intl.messages['labelsInputs.selectProductLabel']
                    : intl.messages['labelsInputs.selectCollectionLabel']
                }
                selectedValue={resource}
                selectFieldValue="slug"
                onChange={setResource}
                error={
                  !!resource && statusForm.type === 'error'
                    ? 'Campo obrigatório'
                    : ''
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <InputText
                label={intl.messages['labelsInputs.siteTitle']}
                value={siteName}
                handleChange={(value) => {
                  setSiteName(value);
                }}
                hasTooltip
                textTooltip={
                  intl.messages['labelsInputs.tooltipArticleSiteTitle']
                }
                error={
                  statusForm.type === 'error'
                    ? 'Campo Site Title deve ser preenchido'
                    : ''
                }
                isError={siteName === '' && statusForm.type}
              />
            </Grid>
            <Grid item md={12}>
              <TextAreaDescription
                label={intl.messages['labelsInputs.shortDescription']}
                maxLength={155}
                value={meta}
                handleChange={setMeta}
                heigth="120px"
                textTooltipSecondary={
                  intl.messages['labelsInputs.tooltipArticleShortDescription']
                }
                error={
                  statusForm.type === 'error'
                    ? 'Campo Descrição Curta deve ser preenchido'
                    : ''
                }
                isError={meta === '' && statusForm.type}
              />
            </Grid>
            {!!seoType && seoType?.value === 'collection' && (
              <>
                <Grid item md={12}>
                  <FileInput
                    label={intl.messages['labelsInputs.uplodadImageLabel']}
                    text={intl.messages['labelsInputs.toDoUploadImage']}
                    title={
                      platforms === ''
                        ? intl.messages[
                            'labelsInputs.tooltipArticleUploadImage'
                          ]
                        : platforms.value === 'institucional'
                        ? intl.messages[
                            'labelsInputs.tooltipArticleUploadImageSuvinil'
                          ]
                        : intl.messages[
                            'labelsInputs.tooltipArticleUploadImageGlasu'
                          ]
                    }
                    accept="image/*"
                    fileName={fileName}
                    getFile={getImageFile}
                    error={
                      statusForm.type === 'error'
                        ? 'Campo Imagem de capa deve ser preenchido'
                        : ''
                    }
                    isError={imageError}
                    disabled={platforms === ''}
                  />
                </Grid>
                <Grid item md={12}>
                  <TextAreaDescription
                    label={intl.messages['labelsInputs.altImageLabel']}
                    value={altImage}
                    handleChange={setAltImage}
                    heigth="95px"
                    textTooltipSecondary={
                      intl.messages['labelsInputs.tooltipArticleAltText']
                    }
                    maxLength={100}
                    isCount
                    error={
                      statusForm.type === 'error'
                        ? 'Campo Alt da imagem de capa deve ser preenchido'
                        : ''
                    }
                    isError={altImage === '' && statusForm.type && fileName}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <ButtonsContainer>
        <Grid container spacing={2}>
          <Grid item md={6} sm={12} />
          <Grid item md={3} sm={12}>
            <Button
              btnType="secondary"
              width="100%"
              borderRadius="4px"
              fontWeight="600"
              onClick={() => setShowModalCancel(true)}
            >
              {intl.messages['buttons.cancel']}
            </Button>
          </Grid>
          <Grid item md={3} sm={12}>
            <Button
              btnType="primary"
              width="100%"
              borderRadius="4px"
              fontWeight="600"
              onClick={() => {
                if (!validate()) {
                  setShowModalConfirmation(false);
                } else {
                  saveStepOne();
                }
              }}
            >
              {intl.messages['buttons.advance']}
            </Button>
          </Grid>
        </Grid>
      </ButtonsContainer>
    </>
  );
};

export default ArticlesFirstStep;
