import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;

  @keyframes kf_fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation-name: kf_fadeIn;
  animation-duration: 2s;
`;

export const WelcomeText = styled.h2`
  ${({ theme }) => css`
    font-family: ${theme.typography.family.secondary};
    font-size: 32px;
    color: ${theme.colors.tertiary.default};
  `}
`;
