export const colors = {
  primary: {
    default: '#F66602',
    dark: '',
    darker: '',
    light: '',
    lighter: '#fdd9c0',
    lightest: '',
    lightest1: '',
    active: '#ED4A03',
    hover: '#e25d00',
  },
  secondary: {
    default: '#666666',
    dark: '#606060',
    darker: '',
    light: '',
    lighter: '',
    lightest: '',
    lightest1: '',
    active: '',
    hover: '',
  },
  tertiary: {
    default: '#333333',
    dark: '#555555',
    darker: '#666666',
    light: '#999999',
    lighter: '',
    lightest: '',
    lightest1: '',
    active: '',
    hover: '',
  },
  gray: {
    default: '#f2f2f2',
    dark: '#92979d',
    darker: '#616569',
    light: '#efefef',
    lighter: '#F7F7F7',
    lightest1: '#fcfcfd',
    lightest2: '#f9fafa',
    active: '',
    hover: '',
  },
  black: {
    default: '#000000',
    light: '#7b7b7b',
    lighter: '#b9b9b9',
    lightest1: '#eaeaea',
    lightest2: '#e5e5e5',
    active: '',
    hover: '',
  },
  white: {
    default: '#ffffff',
    light: '#fdfdfd',
    lighter: '#fbfbfb',
    lightest1: '#f9f9f9',
    lightest2: '#fafafa',
    active: '',
    hover: '',
  },
  background: {
    dark: {
      input: '',
      button: '#e25d00',
    },
    light: {
      base: '#fdfdfd',
      sidebar: '#cccccc',
      button: '#bababa',
    },
    float: {
      solid: '#e8e8e8',
    },
  },
  contextual: {
    info: {
      main: '',
      hover: '',
      active: '',
    },
    success: {
      default: '#3cc13b',
      dark: '#2d912c',
      darker: '#1e611d',
      light: '#9de09d',
      lighter: '#ceefce',
      lightest1: '#f5fcf5',
      lightest2: '#ebf9eb',
      hover: '',
      active: '',
    },
    warning: {
      default: '#f3bb1c',
      dark: '#b68c15',
      darker: '#7a5d0e',
      light: '#9de09d',
      lighter: '#ceefce',
      lightest1: '#f5fcf5',
      lightest2: '#ebf9eb',
      hover: '',
      active: '',
    },
    error: {
      default: '#DC2900',
      dark: '#e33d3d',
      darker: '#781b1c',
      light: '#f89b9b',
      lighter: '#fbcdcd',
      lightest1: '#fef5f5',
      lightest2: '#fdebeb',
      label: '#F03738',
      hover: '#b93030',
      active: '#C62A29',
    },
    disabled: {
      main: '',
      hover: '',
      active: '',
    },
  },
};
