import axios from 'axios';
import { isAuthenticated, getAccessToken } from 'lib/contexts/auth';
import { baseURL } from 'constants/endpoints';

const commonHeader = {
  'Content-Type': 'application/json',
  'x-api-key': process.env.REACT_APP_API_KEY,
};

const authHeader = {
  'Content-Type': 'application/json',
  'x-api-key': process.env.REACT_APP_API_KEY,
  token: getAccessToken(),
};

const api = axios.create({
  baseURL,
  headers: isAuthenticated() ? authHeader : commonHeader,
});

api.interceptors.request.use(async (initialConfig) => {
  const config = initialConfig;
  if (isAuthenticated()) {
    config.headers.Authorization = `Bearer ${getAccessToken()}`;
  }

  const url = config.url.toLowerCase();
  if (
    ['PUT', 'PATCH', 'POST', 'DELETE'].includes(config.method.toUpperCase()) &&
    (url.includes('/products') || url.includes('/colors'))
  ) {
    config.headers.CacheControl = 'max-age=0';
  }

  return config;
});

export default api;
