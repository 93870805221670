import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: ${({ padding }) => (padding ? `${padding}` : '0 0 10px 0')};
`;

export const InputContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    background: ${theme.colors.gray.lighter};
    border: none;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 5px;
    box-shadow: inset 0 0 0 0 transparent;
    transition: color 0.1s ease, border-color 0.1s ease;
    height: ${({ height }) => (height ? `${height}` : '48px')};
    width: 100%;
    -webkit-appearance: none;

    img {
      padding-right: 10px;
    }

    ${({ isError }) =>
      isError && `border: 1px solid ${theme.colors.contextual.error.default}`}

    ${({ disabled }) =>
      disabled &&
      css`
        border: 1px solid ${theme.colors.background.float.solid};
        background: ${theme.colors.background.float.solid};
      `};
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.colors.secondary.dark};
    font-weight: 400;
    font-size: ${theme.typography.sizes.sm};
    line-height: 17px;
    ${({ isError }) =>
      isError && `color: ${theme.colors.contextual.error.default}`}

    ${({ secondary }) =>
      secondary &&
      css`
        font-family: 'Montserrat';
      `};
  `}
`;

export const InputStyle = styled.input`
  ${({ theme }) => css`
    width: 95%;
    font-size: ${theme.typography.sizes.sm};
    line-height: 17px;
    background: transparent;
    border: none;
    outline: none;
    padding-left: 10px;
    -webkit-appearance: none;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      box-shadow: 0 0 0 30px ${theme.colors.gray.lighter} inset;
      font-size: ${theme.typography.sizes.sm};
      font-family: ${theme.typography.family.primary};
    }

    ${({ secondary }) =>
      secondary &&
      css`
        font-size: ${theme.typography.sizes.xs};
        line-height: 17px;
        font-family: 'Montserrat';
        height: 38px;
        border: 1px solid #ecedf1;
        border-radius: 8px;
        background: #fff;
      `};

    ${({ disabled }) =>
      disabled &&
      css`
        color: ${theme.colors.background.light.sidebar};
      `};
  `}
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 4px;

  img {
    padding-left: 5px;
    cursor: pointer;
  }
`;

export const OptionalStyle = styled.p`
  ${({ theme }) => css`
    font-weight: 400;
    font-size: ${theme.typography.sizes.sm};
    line-height: 17px;
    align-items: center;
    color: ${theme.colors.black.light};
    padding-left: 5px;
  `}
`;

export const CountInfo = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.typography.family.primary};
    font-weight: 400;
    font-size: ${theme.typography.sizes.sm};
    line-height: 18px;
    color: ${theme.colors.secondary.dark};
    text-align: right;
    padding-top: 5px;
  `}
`;

export const SpinContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
`