import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import TableDefault from 'components/structure/Table/Table';
import PopOver from 'components/structure/PopOver/PopOver';
import PageHeader from 'components/structure/PageHeader/PageHeader';
import PageContent from 'components/structure/PageContent/PageContent';
import Modal from 'components/structure/Modal/Modal';
import ModalConfirmation from 'components/contexts/modal/ModalConfirmation/ModalConfirmation';
import AddUserModalForm from 'components/contexts/modal/AddUserModalForm/AddUserModalForm';
import EditUserModalForm from 'components/contexts/modal/EditUserModalForm/EditUserModalForm';
import { useDispatch, useSelector } from 'react-redux';
import addIcon from 'assets/icons/add.svg';

import urls from 'constants/urls';
import { getUserData } from 'lib/contexts/auth';
import { getPermission } from 'utils/permissions';
import ModalMultiConfirmation from 'components/contexts/modal/ModalMultiConfirmation/ModalMultiConfirmation';
import EmptySearch from 'components/structure/EmptySearch/EmptySearch';
import ViewUserModalForm from 'components/contexts/modal/ViewUserModalForm/ViewUserModalForm';
import { useHistory } from 'react-router-dom';
import { PopOverContent, PopItem, Divider } from './Users.styles';
import { getRolesListAll } from 'store/roles/roles-slice';
import {
  getUserDetail,
  getUsersList,
  openUserModalCreate,
  openUserModalDelete,
  openUserModalEdit,
  setSelectUser,
  statusUsersModal,
  removeUser as deleteUser,
} from 'store/users/users-slice';

function Users() {
  const { totalPage, items } = useSelector((state) => state.users.users);
  const {
    openModalCreate,
    openStatusModal,
    status,
    msg,
    openModalDelete,
    openModalEdit,
  } = useSelector((state) => state.users);

  const rolePermissions = useSelector((state) => state.auth.role);
  const { permissions } = useSelector((state) => state.auth);

  const [selectedId, setSelectedId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showViewModal, setShowViewModal] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [ordenation, setOrdenation] = useState('name');
  const [totalPerPage, setTotalPerPage] = useState({ value: 10 });
  const perPage = totalPerPage.value;

  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const user = getUserData();

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  useEffect(() => {
    dispatch(getUsersList({ currentPage, searchTerm, perPage, ordenation }));
  }, [dispatch, currentPage, searchTerm, perPage, ordenation]);

  useEffect(() => {
    dispatch(getRolesListAll());
  }, [dispatch]);

  const viewUserModal = useCallback(
    (id) => {
      setSelectedId(id);
      dispatch(getUserDetail(id));
      dispatch(setSelectUser(id));
      setShowViewModal(true);
    },
    [dispatch],
  );

  const editUser = useCallback(
    (id) => {
      setSelectedId(id);
      dispatch(getUserDetail(id));
      dispatch(setSelectUser(id));
    },
    [dispatch],
  );

  const viewActivities = useCallback(
    (id) => {
      history.push(`${urls.ROUTES.ACCESS.items.logsByRecord.path}/${id}/users`);
    },
    [history],
  );

  const removeUser = useCallback(
    (id) => {
      setSelectedId(id);
      dispatch(openUserModalDelete(true));
    },
    [dispatch],
  );

  const COLUMNS = useMemo(
    () => [
      {
        Header: 'Nome',
        accessor: 'name',
      },

      {
        Header: 'E-mail',
        accessor: 'email',
      },
      {
        Header: 'Perfil',
        accessor: ({ role }) => role.name,
      },
      {
        Header: ' ',
        sticky: 'right',
        accessor: ({ id, email, role }) =>
          email === user.email ||
            (role.name === 'Admin' && role.name !== rolePermissions.name) ||
            getPermission('users', permissions, 'update') === false ? (
            <></>
          ) : (
            <PopOver
              content={
                <PopOverContent>
                  <PopItem onClick={() => editUser(id)}>Editar</PopItem>

                  {getPermission('users', permissions, 'delete') && (
                    <>
                      <Divider />
                      <PopItem onClick={() => removeUser(id)}>Excluir</PopItem>
                    </>
                  )}
                  {getPermission('users', permissions, 'access') && (
                    <>
                      <Divider />
                      <PopItem onClick={() => viewUserModal(id)}>
                        Visualizar
                      </PopItem>
                    </>
                  )}
                  <Divider />
                  <PopItem onClick={() => viewActivities(id)}>
                    Ver atividades
                  </PopItem>
                </PopOverContent>
              }
            />
          ),
      },
    ],
    [
      editUser,
      permissions,
      removeUser,
      rolePermissions.name,
      user.email,
      viewUserModal,
    ],
  );

  const data = useMemo(() => items, [items]);
  const addUser = () => {
    dispatch(openUserModalCreate(true));
  };

  const addUserModalForm = (
    <AddUserModalForm searchTerm={searchTerm} currentPage={currentPage} />
  );

  const editUserModalForm = (
    <EditUserModalForm searchTerm={searchTerm} currentPage={currentPage} />
  );

  const viewUserModalForm = (
    <ViewUserModalForm
      cancelActionModal={() => setShowViewModal(false)}
      editActionModal={() => {
        setShowViewModal(false);
        dispatch(openUserModalEdit(true));
      }}
    />
  );

  const optionsOrdenation = [
    { value: 'name', label: 'Nome' },
    { value: 'email', label: 'E-mail' },
  ];

  return (
    <>
      <ModalMultiConfirmation
        title={msg.title}
        text={msg.text}
        setIsOpen={(payload) => {
          dispatch(statusUsersModal(payload));
        }}
        isOpen={openStatusModal}
        status={status}
      />
      <Modal
        title={intl.messages['textModal.addNewUser']}
        setIsOpen={(value) => dispatch(openUserModalCreate(value))}
        isOpen={openModalCreate}
        content={addUserModalForm}
        width="35%"
      />
      <Modal
        title={intl.messages['textModal.editUser']}
        setIsOpen={(value) => dispatch(openUserModalEdit(value))}
        isOpen={openModalEdit}
        content={editUserModalForm}
        width="35%"
      />
      <Modal
        title="Usuário"
        setIsOpen={setShowViewModal}
        isOpen={showViewModal}
        content={viewUserModalForm}
        width="35%"
      />
      <ModalConfirmation
        title={intl.messages['textModal.areYouSure']}
        buttonName={intl.messages['buttons.delete']}
        text={intl.messages['textModal.userDeleteConfirmMessage']}
        setIsOpen={(value) => dispatch(openUserModalDelete(value))}
        isOpen={openModalDelete}
        confirmOnClick={() =>
          dispatch(
            deleteUser({ id: selectedId, page: currentPage, searchTerm }),
          )
        }
      />
      <PageHeader
        title="Usuários"
        onClickFilter={() => { }}
        actions={!getPermission('users', permissions, 'create') ? [] : [
          {
            name: 'Adicionar',
            onClick: addUser,
            btnType: "primary",
            icon: addIcon,
          },
        ]
        }
        search
        searchFunc={(searchData) => setSearchTerm(searchData)}
        placeholder="Busque por Nome"
        optionsOrdenation={optionsOrdenation}
        ordenation={ordenation}
        setOrdenation={setOrdenation}
        trash={getPermission('users', permissions, 'list')}
        trashRedirect={() => history.push(urls.ROUTES.USERS_DELETED.path)}
      />
      <PageContent>
        <EmptySearch items={data} term={searchTerm}>
          <TableDefault
            columns={COLUMNS}
            data={data}
            pageCount={totalPage}
            setPageCurrent={setCurrentPage}
            defaultCurrentPage={currentPage}
            totalPerPage={totalPerPage}
            setTotalPerPage={setTotalPerPage}
          />
        </EmptySearch>
      </PageContent>
    </>
  );
}

export default Users;
