import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    font-weight: 400;
    font-size: 0.85rem;
    line-height: 18px;
    color: ${theme.colors.secondary.default};

    span {
      margin: 0 4px;
      position: relative;
      :first-of-type {
        margin: 0px;
      }
      &.informative {
        color: ${theme.colors.tertiary.default};
      }
    }
  `}
`;

export const ContainerInput = styled.label`
  ${({ theme, cursorType }) => css`
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    cursor: ${cursorType};

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    > .slider {
      position: absolute;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 2px;
      background-color: ${theme.colors.background.light.sidebar};
      border: 1px solid ${theme.colors.background.light.sidebar};
      -webkit-transition: 0.4s;
      transition: 0.4s;
      :before {
        content: '';
        height: 16px;
        width: 16px;
        background-color: ${theme.colors.white.default};
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
    }
    > input:not(:disabled) + .slider {
      ${({ isNotClickable }) =>
        isNotClickable &&
        css`
          cursor: default;
        `};
    }
    > input:disabled + .slider {
      cursor: not-allowed;
    }
    > input:checked:not(:disabled) + .slider {
      border: 1px solid ${theme.colors.primary.default};
      background-color: ${theme.colors.primary.default};
    }
    > input:checked:not(:disabled) + .slider:before {
      background-color: ${theme.colors.gray.lighter};
    }
    > input:checked + .slider:before {
      -webkit-transform: translateX(calc(100% + 2px));
      -ms-transform: translateX(calc(100% + 2px));
      transform: translateX(calc(100% + 2px));
    }
    .slider.round {
      border-radius: 12px;
      :before {
        border-radius: 50%;
        background-color: ${theme.colors.white.default};
      }
    }
  `}
`;
