import React from 'react';
import PropTypes from 'prop-types';
import SpanError from 'components/structure/SpanError/SpanError';
import errorIcon from 'assets/icons/ic-x-error.svg';
import Tooltip from 'assets/icons/tooltip-icon.svg';
import TooltipComponent from 'components/structure/Tooltip/Tooltip';
import {
  Container,
  Label,
  InputStyle,
  InputContainer,
  OptionalStyle,
  LabelContainer,
  CountInfo,
} from './InputTextProperties.styles';

const InputText = ({
  type,
  id,
  name,
  label,
  isError,
  error,
  children,
  isSearch,
  disabled,
  placeholder,
  secondary,
  value,
  handleChange,
  height,
  optional,
  hasTooltip,
  textTooltip,
  isCount,
  maxLength,
  padding,
  max,
  required,
  pattern,
  ...rest
}) => {
  return (
    <Container padding={padding}>
      <LabelContainer>
        {!isSearch && (
          <Label htmlFor={name} isError={isError} secondary={secondary}>
            {label}
          </Label>
        )}
        {optional && <OptionalStyle>(Opcional)</OptionalStyle>}
        {hasTooltip && (
          <TooltipComponent id="tooltipInputText" title={textTooltip}>
            <img src={Tooltip} alt="" />
          </TooltipComponent>
        )}
      </LabelContainer>
      <InputContainer isError={isError} height={height}>
        <InputStyle
          type={type}
          id={id}
          name={name}
          isError={isError}
          isSearch={isSearch}
          placeholder={placeholder}
          disabled={disabled}
          secondary={secondary}
          value={value}
          onChange={(e) => handleChange(e)}
          maxLength={maxLength}
          max={max}
          required={required}
          pattern={pattern}
          {...rest}
        />

        {isError && <img src={errorIcon} alt="" className="error-icon" />}
      </InputContainer>

      {isError && <SpanError>{error}</SpanError>}
      {isCount && (
        <CountInfo>
          {value.length}/{maxLength}
        </CountInfo>
      )}
    </Container>
  );
};

InputText.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
  isError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  error: PropTypes.object,
  disabled: PropTypes.bool,
  isSearch: PropTypes.bool,
  placeholder: PropTypes.string,
  secondary: PropTypes.bool,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  height: PropTypes.string,
  optional: PropTypes.bool,
  hasTooltip: PropTypes.bool,
  textTooltip: PropTypes.any,
  maxLength: PropTypes.number,
  isCount: PropTypes.bool,
  padding: PropTypes.string,
  max: PropTypes.string,
  pattern: PropTypes.string,
  required: PropTypes.bool.isRequired,
};

InputText.defaultProps = {
  type: '',
  id: '',
  name: '',
  label: '',
  children: null,
  error: { message: '' },
  isError: false,
  disabled: false,
  isSearch: false,
  placeholder: '',
  secondary: false,
  value: '',
  handleChange: () => {},
  height: '',
  optional: false,
  hasTooltip: false,
  textTooltip: null,
  maxLength: null,
  isCount: false,
  padding: '',
  max: '',
  pattern: '',
};

export default InputText;
