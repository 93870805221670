/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import Grid from '@material-ui/core/Grid';
import ModalMultiConfirmation from 'components/contexts/modal/ModalMultiConfirmation/ModalMultiConfirmation';
import FileInput from 'components/form/FileInput/FileInput';
import AsyncSelectPaginate from 'components/form/Select/AsyncSelectPaginate/AsyncSelectPaginate';
import SelectSearch from 'components/form/Select/ReactSelect';
import InputText from 'components/form/Text/TextInput/InputText';
import TextAreaDescription from 'components/form/TextArea/TextAreaDescription/TextAreaDescription';
import Toggle from 'components/form/Toggle/Toggle';
import Button from 'components/structure/Button/Button';
import urls from 'constants/urls';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { createSlug } from 'utils/createSlug';
import { errorToast } from 'utils/toasts';
import {
  areas,
  coatings,
  dryingByCoatings,
  dryingByFinishes,
  dryingByTouches,
  finishs,
  internalAreas,
  platforms,
  psqs,
  smells,
  surfaces,
} from '../dataProductsOptions';
import { ButtonsContainer } from './ProductsCreateSteps.styles';
import {
  saveProductStepOne,
  setProductActiveStep,
  statusProductsModal,
} from 'store/products/products-slice';

const ProductsFirstStep = () => {
  const departmentItems = useSelector((state) => state.products.items);
  const { openStatusModal, status, msg } = useSelector(
    (state) => state.products,
  );

  const [imageError, setImageError] = useState(null);

  const [showModalCancel, setShowModalCancel] = useState(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [categoryItems, setCategoryItems] = useState([]);
  const [subCategoryItems, setSubCategoryItems] = useState([]);
  const [similarCategoryItems, setSimilarCategoryItems] = useState([]);
  const [similarSubCategoryItems, setSimilarSubCategoryItems] = useState([]);

  const [form, setForm] = useState({
    name: '',
    department: '',
    category: '',
    subCategory: '',
    similarDepartment: '',
    similarCategory: '',
    similarSubCategory: '',
    platform: [],
    siteName: '',
    psq: '',
    area: [],
    internalArea: [],
    finish: '',
    coating: '',
    dryingByTouch: '',
    dryingByCoating: '',
    dryingByFinish: '',
    smell: '',
    surface: '',
    dilution: '',
    description: '',
    shortDescription: '',
    active: false,
    isVisible: false,
    brand: 'Suvinil',
    altImage: '',
    fileName: '',
    alreadyDone: false,
  });

  const [statusForm, setStatus] = useState({
    type: '',
  });

  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  function handleOnChange(key, value) {
    setForm((oldValues) => ({
      ...oldValues,
      [key]: value ?? '',
    }));
  }

  const cancelProductCreate = () => {
    history.push(urls.ROUTES.CATALOGUE.items.products.path);
  };

  useEffect(() => {
    if (form.department !== '') {
      const departments = form.department.childrens?.map((option) => ({
        value: option?.id,
        label: option?.name,
        subcategories: option?.subcategories,
      }));

      setCategoryItems(departments);
    }
    handleOnChange('category', '');
  }, [form.department]);

  useEffect(() => {
    if (form.category !== '') {
      const data = categoryItems.find(
        (element) => element.value === form.category,
      );
      if (data?.subcategories !== undefined) {
        if (data.subcategories.length > 0) {
          const aux = data.subcategories.map((option) => ({
            value: option?.id,
            label: option?.name,
          }));
          setSubCategoryItems(aux);
        } else {
          setSubCategoryItems([]);
        }
      } else {
        setSubCategoryItems([]);
      }
      return;
    }
    handleOnChange('subCategory', '');
  }, [form.category]);

  useEffect(() => {
    if (form.similarDepartment !== '') {
      const similarDepartments = form.similarDepartment.childrens?.map(
        (option) => ({
          value: option?.id,
          label: option?.name,
          subcategories: option?.subcategories,
        }),
      );
      setSimilarCategoryItems(similarDepartments);
    }
  }, [form.similarDepartment]);

  useEffect(() => {
    if (form.similarCategory !== '') {
      const data = categoryItems.find(
        (element) => element.value === form.similarCategory,
      );
      if (data?.subcategories !== undefined) {
        if (data.subcategories.length > 0) {
          const aux = data.subcategories.map((option) => ({
            value: option?.id,
            label: option?.name,
          }));
          setSimilarSubCategoryItems(aux);
        } else {
          setSimilarSubCategoryItems([]);
        }
      } else {
        setSimilarSubCategoryItems([]);
      }
    }
  }, [form.similarCategory]);

  function validate() {
    if (!form.name)
      return setStatus({
        type: 'error',
      });
    if (!form.category)
      return setStatus({
        type: 'error',
      });
    if (!form.department)
      return setStatus({
        type: 'error',
      });
    if (!form.platform)
      return setStatus({
        type: 'error',
      });
    if (!form.siteName)
      return setStatus({
        type: 'error',
      });
    if (!form.description)
      return setStatus({
        type: 'error',
      });
    if (!form.shortDescription)
      return setStatus({
        type: 'error',
      });
    if (!form.brand)
      return setStatus({
        type: 'error',
      });

    return true;
  }

  const convertPSQ = (psqToConvert) => {
    let psqConverted = psqToConvert;

    if (psqToConvert === 'Premium') {
      psqConverted = 'premium';
    } else if (psqToConvert === 'Standard') {
      psqConverted = 'standard';
    } else if (psqToConvert === 'Econômico') {
      psqConverted = 'economic';
    } else if (psqToConvert === 'Super Premium') {
      psqConverted = 'superPremium';
    }

    return psqConverted;
  };

  const saveStepOne = () => {
    const arrayCategories = [];
    arrayCategories.push(
      { id: form.department.id, main: true },
      { id: form.similarDepartment.id, main: false },
      { id: form.category, main: false },
      { id: form.similarCategory, main: false },
      { id: form.subCategory, main: false },
      { id: form.similarSubCategory, main: false },
    );

    const arrayAltImage = [];
    arrayAltImage.push(form.altImage);

    if (!validate()) {
      return;
    }

    const formData = new FormData();
    formData.append('name', form.name);
    formData.append(
      'categories',
      JSON.stringify(
        arrayCategories.filter(
          (categoryData) => categoryData.id !== '' && categoryData.id,
        ),
      ),
    );
    formData.append('platforms', JSON.stringify(form.platform));
    formData.append('siteTitle', form.siteName);
    formData.append('description', form.description);
    formData.append('shortDescription', form.shortDescription);
    formData.append('active', form.active);
    formData.append('isVisible', form.isVisible);
    formData.append('brand', form.brand);
    formData.append('alternativeText', JSON.stringify(arrayAltImage));
    formData.append('alreadyDone', form.alreadyDone);

    if (form.psq) {
      formData.append('psq', convertPSQ(form.psq));
    }
    formData.append('area', JSON.stringify(form.area));
    formData.append('internalArea', JSON.stringify(form.internalArea));
    formData.append('finish', form.finish);
    formData.append('coating', form.coating);
    formData.append('dryingByTouch', form.dryingByTouch);
    formData.append('dryingByCoating', form.dryingByCoating);
    formData.append('dryingByFinish', form.dryingByFinish);
    formData.append('smell', form.smell);
    formData.append('surface', form.surface);
    formData.append('dilution', form.dilution);

    if (
      form.name !== '' &&
      form.department !== '' &&
      form.platform.length > 0 &&
      form.siteName !== '' &&
      form.description !== '' &&
      form.shortDescription !== '' &&
      form.brand !== '' &&
      form.area.length > 0 &&
      form.internalArea !== '' &&
      form.finish !== '' &&
      form.coating !== '' &&
      form.dryingByTouch !== '' &&
      form.dryingByCoating !== '' &&
      form.dryingByFinish !== '' &&
      form.smell !== '' &&
      form.surface !== '' &&
      form.dilution !== ''
    ) {
      dispatch(saveProductStepOne({ info: formData, goTo: 1 }));

      dispatch(statusProductsModal(false));
      if (status !== 'error') {
        dispatch(setProductActiveStep(1));
      }
    }
  };

  return (
    <>
      <ModalMultiConfirmation
        title={intl.messages['textModal.createProductConfirm']}
        isOpen={showModalConfirmation}
        setIsOpen={setShowModalConfirmation}
        buttonNameBack={intl.messages['buttons.not']}
        buttonName={intl.messages['buttons.yes']}
        status="confirm"
        confirmOnClick={saveStepOne}
      />
      <ModalMultiConfirmation
        title={intl.messages['textModal.deleteProductCreate']}
        text={intl.messages['textModal.productWillBeLost']}
        isOpen={showModalCancel}
        setIsOpen={setShowModalCancel}
        buttonNameBack={intl.messages['buttons.not']}
        buttonName={intl.messages['buttons.yes']}
        confirmOnClick={cancelProductCreate}
        status="info"
      />
      <ModalMultiConfirmation
        title={msg.title}
        text={msg.text}
        setIsOpen={(payload) => {
          dispatch(statusProductsModal(payload));
          dispatch(setProductActiveStep(1));
        }}
        isOpen={openStatusModal}
        status={status}
      />

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid item md={12}>
              <InputText
                placeholder={intl.messages['labelsInputs.typeYourName']}
                label={intl.messages['labelsInputs.typeYourName']}
                value={form.name}
                handleChange={(value) => handleOnChange('name', value)}
                hasTooltip
                textTooltip="Este é o nome do produto, que aparece na página e também é enviado para VTEX"
                error={
                  statusForm.type === 'error'
                    ? 'Campo Nome deve ser preenchido'
                    : ''
                }
                isError={form.name === '' && statusForm.type}
              />
            </Grid>
            <Grid item md={12}>
              <InputText
                placeholder={intl.messages['labelsInputs.titleExample']}
                label={intl.messages['labelsInputs.slug']}
                disabled
                value={createSlug(form.name)}
              />
            </Grid>
            <Grid item md={12}>
              <AsyncSelectPaginate
                zIndex="160"
                label={intl.messages['labelsInputs.department']}
                placeholder={intl.messages['labelsInputs.select']}
                url="categories?type=1"
                department
                perPage={100}
                value={form.department}
                handleChange={(value) => handleOnChange('department', value)}
                clearable
                error={
                  statusForm.type === 'error'
                    ? 'Campo Departamento deve ser preenchido'
                    : ''
                }
                isError={form.department === '' && statusForm.type}
              />
            </Grid>
            <Grid item md={12}>
              <SelectSearch
                zIndex="150"
                label={intl.messages['labelsInputs.category']}
                placeholder={intl.messages['labelsInputs.select']}
                defaultOption={form.category}
                options={categoryItems}
                tooltip
                clearable
                error={
                  statusForm.type === 'error'
                    ? 'Campo Categoria deve ser preenchido'
                    : ''
                }
                isError={form.category === '' && statusForm.type}
                title="A categoria é uma subdivisão de departamento"
                handleChange={(value) => handleOnChange('category', value)}
                disabled={form.department === ''}
              />
            </Grid>
            <Grid item md={12}>
              <SelectSearch
                zIndex="140"
                label={intl.messages['labelsInputs.subCategory']}
                placeholder={intl.messages['labelsInputs.select']}
                options={subCategoryItems}
                tooltip
                clearable
                title="A subcategoria é uma subdivisão de categoria"
                defaultOption={form.subCategory}
                handleChange={(value) => handleOnChange('subCategory', value)}
                disabled={form.category === ''}
                optional
              />
            </Grid>

            <Grid item md={12}>
              <AsyncSelectPaginate
                zIndex="130"
                label={intl.messages['labelsInputs.similarDepartment']}
                placeholder={intl.messages['labelsInputs.select']}
                url="categories?type=1"
                department
                perPage={100}
                value={form.similarDepartment}
                handleChange={(value) =>
                  handleOnChange('similarDepartment', value)
                }
                optional
              />
            </Grid>
            <Grid item md={12}>
              <SelectSearch
                zIndex="120"
                label={intl.messages['labelsInputs.similarCategory']}
                placeholder={intl.messages['labelsInputs.select']}
                options={similarCategoryItems}
                tooltip
                title="A categoria é uma subdivisão de departamento"
                defaultOption={form.similarCategory}
                handleChange={(value) =>
                  handleOnChange('similarCategory', value)
                }
                disabled={form.similarDepartment === ''}
                optional
              />
            </Grid>
            <Grid item md={12}>
              <SelectSearch
                zIndex="110"
                label={intl.messages['labelsInputs.similarSubCategory']}
                placeholder={intl.messages['labelsInputs.select']}
                options={similarSubCategoryItems}
                tooltip
                title="A subcategoria é uma subdivisão de categoria"
                defaultOption={form.similarSubCategory}
                handleChange={(value) =>
                  handleOnChange('similarSubCategory', value)
                }
                disabled={form.similarCategory === ''}
                optional
              />
            </Grid>
            <Grid item md={12}>
              <SelectSearch
                zIndex="100"
                label={intl.messages['labelsInputs.platforms']}
                placeholder={intl.messages['labelsInputs.select']}
                options={platforms}
                defaultMultiOptions={form.platform}
                handleChange={(value) => {
                  handleOnChange('platform', value);
                }}
                multi
                error={
                  statusForm.type === 'error'
                    ? 'Campo Plataformas deve ser preenchido'
                    : ''
                }
                isError={form.platform.length === 0 && statusForm.type}
              />
            </Grid>

            <Grid item md={12}>
              <InputText
                placeholder={intl.messages['labelsInputs.siteTitle']}
                label={intl.messages['labelsInputs.siteTitle']}
                value={form.siteName}
                handleChange={(value) => handleOnChange('siteName', value)}
                error={
                  statusForm.type === 'error'
                    ? 'Campo Site Title deve ser preenchido'
                    : ''
                }
                isError={form.siteName === '' && statusForm.type}
              />
            </Grid>

            <Grid item md={12}>
              <SelectSearch
                zIndex="90"
                label={intl.messages['labelsInputs.psq']}
                placeholder={intl.messages['labelsInputs.select']}
                options={psqs}
                defaultOption={form.psq}
                handleChange={(value) => handleOnChange('psq', value)}
              />
            </Grid>

            <Grid item md={12}>
              <SelectSearch
                zIndex="80"
                label={intl.messages['labelsInputs.area']}
                placeholder={intl.messages['labelsInputs.select']}
                options={areas}
                defaultMultiOptions={form.area}
                multi
                handleChange={(value) => handleOnChange('area', value)}
                error={
                  statusForm.type === 'error'
                    ? 'Campo Área deve ser preenchido'
                    : ''
                }
                isError={form.area.length === 0 && statusForm.type}
              />
            </Grid>

            <Grid item md={12}>
              <SelectSearch
                zIndex="70"
                label={intl.messages['labelsInputs.internalArea']}
                placeholder={intl.messages['labelsInputs.select']}
                options={internalAreas}
                defaultMultiOptions={form.internalArea}
                multi
                handleChange={(value) => handleOnChange('internalArea', value)}
                error={
                  statusForm.type === 'error'
                    ? 'Campo Área Interna deve ser preenchido'
                    : ''
                }
                isError={form.internalArea.length === 0 && statusForm.type}
              />
            </Grid>

            <Grid item md={12}>
              <SelectSearch
                zIndex="60"
                label={intl.messages['labelsInputs.finishes']}
                placeholder={intl.messages['labelsInputs.select']}
                options={finishs}
                defaultOption={form.finish}
                handleChange={(value) => handleOnChange('finish', value)}
                error={
                  statusForm.type === 'error'
                    ? 'Campo Acabamentos deve ser preenchido'
                    : ''
                }
                isError={form.finish === '' && statusForm.type}
              />
            </Grid>

            <Grid item md={12}>
              <SelectSearch
                zIndex="50"
                label={intl.messages['labelsInputs.coats']}
                placeholder={intl.messages['labelsInputs.select']}
                options={coatings}
                defaultOption={form.coating}
                handleChange={(value) => handleOnChange('coating', value)}
                error={
                  statusForm.type === 'error'
                    ? 'Campo Demãos deve ser preenchido'
                    : ''
                }
                isError={form.coating === '' && statusForm.type}
              />
            </Grid>

            <Grid item md={12}>
              <SelectSearch
                zIndex="40"
                label={intl.messages['labelsInputs.dryingByTouch']}
                placeholder={intl.messages['labelsInputs.select']}
                options={dryingByTouches}
                defaultOption={form.dryingByTouch}
                handleChange={(value) => handleOnChange('dryingByTouch', value)}
                error={
                  statusForm.type === 'error'
                    ? 'Campo Secagem ao toque deve ser preenchido'
                    : ''
                }
                isError={form.dryingByTouch === '' && statusForm.type}
              />
            </Grid>

            <Grid item md={12}>
              <SelectSearch
                zIndex="30"
                label={intl.messages['labelsInputs.dryingByCoating']}
                placeholder={intl.messages['labelsInputs.select']}
                options={dryingByCoatings}
                defaultOption={form.dryingByCoating}
                handleChange={(value) =>
                  handleOnChange('dryingByCoating', value)
                }
                error={
                  statusForm.type === 'error'
                    ? 'Campo Secagem entre demão deve ser preenchido'
                    : ''
                }
                isError={form.dryingByCoating === '' && statusForm.type}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <SelectSearch
                zIndex="50"
                label={intl.messages['labelsInputs.dryingByFinish']}
                placeholder={intl.messages['labelsInputs.select']}
                options={dryingByFinishes}
                defaultOption={form.dryingByFinish}
                handleChange={(value) =>
                  handleOnChange('dryingByFinish', value)
                }
                error={
                  statusForm.type === 'error'
                    ? 'Campo Secagem final deve ser preenchido'
                    : ''
                }
                isError={form.dryingByFinish === '' && statusForm.type}
              />
            </Grid>
            <Grid item md={12}>
              <SelectSearch
                zIndex="40"
                label={intl.messages['labelsInputs.smell']}
                placeholder={intl.messages['labelsInputs.select']}
                options={smells}
                defaultOption={form.smell}
                handleChange={(value) => handleOnChange('smell', value)}
                error={
                  statusForm.type === 'error'
                    ? 'Campo Cheiro deve ser preenchido'
                    : ''
                }
                isError={form.smell === '' && statusForm.type}
              />
            </Grid>
            <Grid item md={12}>
              <SelectSearch
                zIndex="30"
                label={intl.messages['labelsInputs.surfaces']}
                placeholder={intl.messages['labelsInputs.select']}
                options={surfaces}
                defaultOption={form.surface}
                handleChange={(value) => handleOnChange('surface', value)}
                error={
                  statusForm.type === 'error'
                    ? 'Campo Superfícies deve ser preenchido'
                    : ''
                }
                isError={form.surface === '' && statusForm.type}
              />
            </Grid>
            <Grid item md={12}>
              <TextAreaDescription
                label={intl.messages['labelsInputs.dilution']}
                placeholder={intl.messages['labelsInputs.description']}
                value={form.dilution}
                handleChange={(value) => handleOnChange('dilution', value)}
                heigth="156px"
                maxLength={1300}
                tooltip={false}
                error={
                  statusForm.type === 'error'
                    ? 'Campo Diluição deve ser preenchido'
                    : ''
                }
                isError={form.dilution === '' && statusForm.type}
              />
            </Grid>
            <Grid item md={12}>
              <TextAreaDescription
                label={intl.messages['labelsInputs.description']}
                placeholder={intl.messages['labelsInputs.description']}
                value={form.description}
                handleChange={(value) => handleOnChange('description', value)}
                heigth="156px"
                maxLength={1300}
                tooltip={false}
                error={
                  statusForm.type === 'error'
                    ? 'Campo Descrição deve ser preenchido'
                    : ''
                }
                isError={form.description === '' && statusForm.type}
              />
            </Grid>
            <Grid item md={12}>
              <TextAreaDescription
                label={intl.messages['labelsInputs.shortDescription']}
                placeholder={intl.messages['labelsInputs.shortDescription']}
                value={form.shortDescription}
                handleChange={(value) =>
                  handleOnChange('shortDescription', value)
                }
                heigth="110px"
                maxLength={250}
                tooltip={false}
                error={
                  statusForm.type === 'error'
                    ? 'Campo Descrição Curta deve ser preenchido'
                    : ''
                }
                isError={form.shortDescription === '' && statusForm.type}
              />
            </Grid>
            <Grid item md={12}>
              <Toggle
                label={intl.messages['labelsInputs.active']}
                checked={form.active}
                onChange={(e) => handleOnChange('active', e.target.checked)}
                regular
              />
            </Grid>
            <Grid item md={12} style={{ paddingTop: '10px' }}>
              <Toggle
                label={intl.messages['labelsInputs.pdpVisible']}
                checked={form.isVisible}
                onChange={(e) => handleOnChange('isVisible', e.target.checked)}
                regular
              />
            </Grid>
            <Grid item md={12}>
              <InputText
                label={intl.messages['labelsInputs.brand']}
                placeholder={intl.messages['labelsInputs.select']}
                value={form.brand}
                handleChange={(value) => handleOnChange('brand', value)}
                error={
                  statusForm.type === 'error'
                    ? 'Campo Marca deve ser preenchido'
                    : ''
                }
                isError={form.brand === '' && statusForm.type}
              />
            </Grid>
            <Grid item md={12}>
              <Toggle
                label={intl.messages['labelsInputs.readyToUse']}
                checked={form.alreadyDone}
                onChange={(e) =>
                  handleOnChange('alreadyDone', e.target.checked)
                }
                regular
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <ButtonsContainer>
        <Grid container spacing={2}>
          <Grid item md={6} sm={12} />
          <Grid item md={3} sm={12}>
            <Button
              btnType="secondary"
              width="100%"
              borderRadius="4px"
              fontWeight="600"
              onClick={() => setShowModalCancel(true)}
            >
              {intl.messages['buttons.cancel']}
            </Button>
          </Grid>
          <Grid item md={3} sm={12}>
            <Button
              btnType="primary"
              width="100%"
              borderRadius="4px"
              fontWeight="600"
              onClick={() => {
                if (validate()) {
                  saveStepOne();
                }
              }}
            >
              {intl.messages['buttons.create']}
            </Button>
          </Grid>
        </Grid>
      </ButtonsContainer>
    </>
  );
};

export default ProductsFirstStep;
