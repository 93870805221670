import AddTagModalForm from 'components/contexts/modal/AddTagModalForm/AddTagModalForm';
import EditTagModalForm from 'components/contexts/modal/EditTagModalForm/EditTagModalForm';
import ModalConfirmation from 'components/contexts/modal/ModalConfirmation/ModalConfirmation';
import ModalMultiConfirmation from 'components/contexts/modal/ModalMultiConfirmation/ModalMultiConfirmation';
import ViewTagModalForm from 'components/contexts/modal/ViewTagModalForm/ViewTagModalForm';
import BoxAlert from 'components/structure/BoxAlert/BoxAlert';
import EmptySearch from 'components/structure/EmptySearch/EmptySearch';
import Modal from 'components/structure/Modal/Modal';
import PageContent from 'components/structure/PageContent/PageContent';
import PageHeader from 'components/structure/PageHeader/PageHeader';
import PopOver from 'components/structure/PopOver/PopOver';
import {
  Divider,
  PopItem,
  PopOverContent,
} from 'components/structure/PopOver/PopOver.styles';
import TableDefault from 'components/structure/Table/Table';
import urls from 'constants/urls';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getListTags,
  getTagDetail,
  removeTag,
  setTagId,
  statusTagsModal,
} from 'store/tags/tags-slice';

import arrayToString from 'utils/arrayToString';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { getPermission } from 'utils/permissions';

const Tags = () => {
  const { items, totalPage } = useSelector((state) => state.tags.tags);
  const { openStatusModal, status, msg, selectId } = useSelector(
    (state) => state.tags,
  );
  const { loading } = useSelector((state) => state.loading);
  const { permissions } = useSelector((state) => state.auth);

  const history = useHistory();

  const [selectedId, setSelectedId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [ordenation, setOrdenation] = useState('');
  const [totalPerPage, setTotalPerPage] = useState({ value: 10 });
  const perPage = totalPerPage.value;

  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  const addTagModal = () => {
    setShowAddModal(true);
  };

  const viewTagModal = useCallback(
    (id) => {
      dispatch(getTagDetail(parseInt(id, 10)));
      setSelectedId(id);
      dispatch(setTagId(id));
      setShowViewModal(true);
    },
    [dispatch],
  );

  const viewActivities = useCallback(
    (id) => {
      history.push(`${urls.ROUTES.ACCESS.items.logsByRecord.path}/${id}/tags`);
    },
    [history],
  );

  const editTagModal = useCallback(
    (id) => {
      setSelectedId(id);
      dispatch(setTagId(id));
      setShowEditModal(true);
    },
    [dispatch],
  );

  const removeTagModal = (id) => {
    setSelectedId(id);
    setShowDeleteModal(true);
  };

  const COLUMNS = useMemo(
    () => [
      { Header: 'ID', accessor: 'id' },
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Plataforma',
        accessor: ({ platforms }) =>
          arrayToString(platforms) === 'institucional'
            ? arrayToString(platforms).replace(platforms, 'Suvinil')
            : capitalizeFirstLetter(arrayToString(platforms)),
      },
      {
        Header: ' ',
        accessor: ({ id }) =>
          getPermission('tags', permissions, 'update') === false ? (
            <></>
          ) : (
            <PopOver
              content={
                <PopOverContent>
                  <PopItem onClick={() => editTagModal(id)}>
                    {intl.messages['buttons.edit']}
                  </PopItem>
                  {getPermission('tags', permissions, 'delete') && (
                    <>
                      <Divider />
                      <PopItem onClick={() => removeTagModal(id)}>
                        {intl.messages['buttons.exclusion']}
                      </PopItem>
                    </>
                  )}

                  {getPermission('editorials', permissions, 'access') && (
                    <>
                      <Divider />
                      <PopItem onClick={() => viewTagModal(id)}>
                        Ver detalhes
                      </PopItem>
                    </>
                  )}
                  <>
                    <Divider />
                    <PopItem onClick={() => viewActivities(id)}>
                      Ver atividades
                    </PopItem>
                  </>
                </PopOverContent>
              }
            />
          ),
      },
    ],
    [editTagModal, intl.messages, permissions],
  );

  const data = useMemo(() => items, [items]);

  useEffect(() => {
    dispatch(getListTags({ currentPage, searchTerm, perPage, ordenation }));
  }, [dispatch, currentPage, searchTerm, perPage, ordenation]);

  const addTagModalForm = (
    <AddTagModalForm
      cancelActionModal={() => setShowAddModal(false)}
      createActionModal={() => setShowAddModal(false)}
    />
  );

  const editTagModalForm = (
    <EditTagModalForm
      cancelActionModal={() => setShowEditModal(false)}
      editActionModal={() => setShowEditModal(false)}
    />
  );

  const viewTagModalForm = (
    <ViewTagModalForm
      cancelActionModal={() => setShowViewModal(false)}
      editActionModal={() => {
        setShowViewModal(false);
        setShowEditModal(true);
      }}
    />
  );

  const textBox = (
    <>
      <p>
        Ao pressionar deletar essa tag será apagada da listagem junto com seus
        dados.
      </p>
      <br />
      <BoxAlert
        title="Atenção"
        text="A exclusão de uma tag não acarreta na exclusão ou erros em seus artigos"
      />
    </>
  );

  const optionsOrdenation = [
    { value: 'id', label: 'ID' },
    { value: 'name', label: 'Nome' },
    { value: 'platforms', label: 'Plataforma' },
  ];

  return (
    <>
      <Modal
        title="Criar Tag"
        setIsOpen={setShowAddModal}
        isOpen={showAddModal}
        content={addTagModalForm}
        loading={loading}
      />
      <Modal
        title="Editar Tag"
        setIsOpen={setShowEditModal}
        isOpen={showEditModal}
        content={editTagModalForm}
        loading={loading}
      />
      <Modal
        title="Visualizar tag"
        setIsOpen={setShowViewModal}
        isOpen={showViewModal}
        content={viewTagModalForm}
        loading={loading}
      />
      <ModalConfirmation
        title={intl.messages['textModal.areYouSure']}
        buttonName={intl.messages['buttons.delete']}
        text={textBox}
        setIsOpen={setShowDeleteModal}
        isOpen={showDeleteModal}
        confirmOnClick={() =>
          dispatch(removeTag({ id: selectedId, searchTerm, currentPage }))
        }
        loading={loading}
      />
      <ModalMultiConfirmation
        status={status}
        setIsOpen={(payload) => {
          dispatch(statusTagsModal(payload));
          if (status !== 'error') {
            dispatch(getListTags({ currentPage, searchTerm }));
          }
        }}
        isOpen={openStatusModal}
        title={msg.text}
      />

      <PageHeader
        title="Tags"
        addButtonName="Adicionar"
        onClickAdd={addTagModal}
        onClickFilter={() => {}}
        addButton={getPermission('tags', permissions, 'create')}
        search
        searchFunc={(searchData) => setSearchTerm(searchData)}
        placeholder="Busque por Nome"
        optionsOrdenation={optionsOrdenation}
        ordenation={ordenation}
        setOrdenation={setOrdenation}
        trash={getPermission('tags', permissions, 'list')}
        trashRedirect={() => history.push(urls.ROUTES.TAGS_DELETED.path)}
      />
      <PageContent>
        <EmptySearch items={data} term={searchTerm}>
          <TableDefault
            columns={COLUMNS}
            data={data}
            pageCount={totalPage}
            defaultCurrentPage={currentPage}
            setPageCurrent={setCurrentPage}
            totalPerPage={totalPerPage}
            setTotalPerPage={setTotalPerPage}
          />
        </EmptySearch>
      </PageContent>
    </>
  );
};

export default Tags;
