/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { FORGOT_PASSWORD, ROLES, SIGNIN } from 'constants/endpoints';
import {
  getUserData,
  removeStorage,
  setAccessToken,
  setOnlyAccessToken,
} from 'lib/contexts/auth';
import api from 'services/api';
import multiForm from 'services/multiForm';
import { startLoading, stopLoading } from 'store/loading/loading-slice';
import { errorSessionToast, errorToast, successToast } from 'utils/toasts';

const INITIAL_STATE = {
  authenticated: false,
  role: {
    id: '',
    name: '',
  },
  permissions: [],
  attributes: [],
  status: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    setAuth: (state, { payload }) => {
      state.authenticated = payload;
    },
    setAuthRole: (state, { payload }) => {
      state.role = payload;
    },
    loadAuthPermissonsSuccess: (state, { payload }) => {
      state.permissions = payload;
    },
    loadAuthAttributesSuccess: (state, { payload }) => {
      state.attributes = payload;
    },
    sendForgotPasswordSuccess: (state, { payload }) => {
      state.status = payload;
    },
  },
});

export const auth = authSlice.reducer;
export const {
  loadAuthAttributesSuccess,
  loadAuthPermissonsSuccess,
  sendForgotPasswordSuccess,
  setAuth,
  setAuthRole,
} = authSlice.actions;

export const loadUserData = () => async (dispatch) => {
  dispatch(startLoading());

  try {
    const { permissionsObj, role } = getUserData();
    const userData = JSON.parse(permissionsObj);

    const { id, name } = userData;
    const response = await api.get(`${ROLES}/${role}`);
    dispatch(setAuthRole({ id, name }));
    dispatch(loadAuthPermissonsSuccess(response.data.permissions));
    dispatch(loadAuthAttributesSuccess(response.data.attributes));

    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        case 500:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
    removeStorage();
  }
};

export const login = (payload) => async (dispatch) => {
  dispatch(startLoading());

  try {
    const { data } = await api.post(SIGNIN, payload);

    setOnlyAccessToken(data.jwtToken);

    const response = await api.get(`${ROLES}/${data.roleId}`);

    dispatch(loadAuthPermissonsSuccess(response.data.permissions));
    dispatch(loadAuthAttributesSuccess(response.data.attributes));
    dispatch(setAuthRole(response.data));
    dispatch(setAuth(true));

    setAccessToken({
      accessToken: data.jwtToken,
      name: data.name,
      fullname: data.name,
      email: data.email,
      role: data.roleId,
      permissionsObj: response.data,
    });

    dispatch(stopLoading());
  } catch (error) {
    errorToast(error);
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorToast('Ops, usuário ou senha inválidos!');
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const logout = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    removeStorage();
    dispatch(setAuth(false));
    dispatch(stopLoading());
  } catch (error) {
    errorToast(error);
    dispatch(stopLoading());
  }
};

export const forgotPassword = (payload) => async (dispatch) => {
  dispatch(startLoading());

  try {
    await multiForm.post(FORGOT_PASSWORD, payload);
    dispatch(sendForgotPasswordSuccess('success'));

    successToast('Email enviado com sucesso!');
    dispatch(stopLoading());
  } catch (error) {
    errorToast(error);
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      dispatch(sendForgotPasswordSuccess('error'));

      switch (error.response.status) {
        case 404:
          errorToast('Ops, o usuário não foi encontrado!');
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};
