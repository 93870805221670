import React, { memo, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import FileInput from 'components/form/FileInput/FileInput';
import { useFileList } from 'providers/UploadFileList';
import { FileList } from './FileList';
import Proptypes from 'prop-types';
import ImageModal from 'components/contexts/modal/ImageModal/ImageModel';
import { successToast } from 'utils/toasts';

export const UploadFileList = memo(({ label, weightId, isDisable, name }) => {
  const [showcaseFileCache, setShowcaseFileCache] = useState(null);
  const [showcaseFileNameCache, setShowcaseFileNameCache] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const { fileList, addFile, uploadDisplayImages, setWeightName, setWeightId } =
    useFileList();

  const handleChangeFile = (e) => {
    setShowConfirmationModal(true);
    setShowcaseFileCache(e);
    if (typeof window !== 'undefined') {
      setShowcaseFileNameCache(URL.createObjectURL(e.target.files[0]));
    }
  };

  useEffect(() => {
    setWeightName(name.replace(/\s+/g, '').replace(/\./g, '-'));
    setWeightId(weightId);
  }, [name, setWeightName, weightId, setWeightId]);

  useEffect(() => {
    if (fileList.length > 0) {
      uploadDisplayImages();
    }
  }, [fileList.length, uploadDisplayImages]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
    >
      <ImageModal
        imgUrl={showcaseFileNameCache}
        isOpen={showConfirmationModal}
        setIsOpen={setShowConfirmationModal}
        actions={{
          changeFile: handleChangeFile,
          saveFile: () => {
            addFile(showcaseFileCache);
            setShowConfirmationModal(false);
            successToast('Imagem salva com sucesso!');
          },
        }}
        isPreview
      />
      <FileInput
        label={label}
        text="SELECIONAR IMAGEM"
        title="Adicione mais imagens ao produto"
        accept="image/*"
        dontShowTheFileName
        getFile={handleChangeFile}
        disabled={isDisable}
      />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <FileList disabled={isDisable} />
      </Grid>
    </Grid>
  );
});

UploadFileList.propTypes = {
  label: Proptypes.string,
  weightId: Proptypes.string,
  isDisable: Proptypes.bool,
  name: Proptypes.string,
};

UploadFileList.defaultProps = {
  label: '',
  weightId: '',
  isDisable: false,
  name: '',
};
