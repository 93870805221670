import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { FaChevronDown } from 'react-icons/fa';
import { colors } from 'styles/tokens';
import {
  Container,
  Label,
  LabelContainer,
  OptionalStyle,
} from './SelectSimple.styles';

const selectStyle = {
  control: (provided) => ({
    ...provided,
    boxShadow: 'none',
    border: 'none',
    minHeight: '48px',
    padding: '5px',
    cursor: 'pointer',
  }),
  menu: (provided) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused && 'lightgray',
    color: state.isFocused && colors.secondary.default,
    cursor: 'pointer',
  }),
};

const custom = (theme) => ({
  ...theme,
  borderRadius: 5,
  border: 0,
  boxShadow: 0,
  outline: 0,
  paddingRight: 5,
  colors: {
    ...theme.colors,
    primary25: colors.secondary.default,
    primary: colors.gray.dark,
    primary50: colors.gray.dark,
    neutral0: colors.gray.lighter,
    neutral5: colors.black.default,
    neutra20: colors.gray.lighter,
    neutra30: colors.gray.lighter,
    neutra40: colors.gray.lighter,
  },
});

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaChevronDown />
    </components.DropdownIndicator>
  );
};

const SelectSimple = ({
  options,
  defaultOption,
  handleChange,
  label,
  optional,
  placeholder,
  disabled,
}) => {
  return (
    <Container>
      <LabelContainer>
        <Label>{label}</Label>

        {optional && <OptionalStyle>(Opcional)</OptionalStyle>}
      </LabelContainer>
      <Select
        placeholder={placeholder}
        defaultValue={options.filter(
          (option) => option.value === defaultOption,
        )}
        value={options.filter((option) => option.value === defaultOption)}
        onChange={(item) => {
          if (item) {
            handleChange(item.value);
          } else {
            handleChange(0);
          }
        }}
        options={options}
        styles={selectStyle}
        components={{ IndicatorSeparator: null, DropdownIndicator }}
        theme={custom}
        isClearable
        noOptionsMessage={({ inputValue }) =>
          !inputValue ? '' : 'Item não encontrado'
        }
        loadingMessage={() => 'Carregando...'}
        disabled={disabled}
      />
    </Container>
  );
};

SelectSimple.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  defaultOption: PropTypes.string || PropTypes.number,
  handleChange: PropTypes.func,
  optional: PropTypes.bool,
  disabled: PropTypes.bool,
};

SelectSimple.defaultProps = {
  label: '',
  placeholder: '',
  options: [],
  defaultOption: '',
  handleChange: () => {},
  optional: false,
  disabled: false,
};

export default SelectSimple;
