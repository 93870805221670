import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.black.lighter};
    height: 100vh;
    position: fixed;
    width: 295px;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    padding: 20px 0;

    a {
      text-decoration: none;
    }

    .active {
      border-left: 4.35px solid ${theme.colors.tertiary.default};
      background: linear-gradient(
        90.04deg,
        rgba(0, 0, 0, 0.1) 22.31%,
        rgba(0, 0, 0, 0) 109.16%
      );
      font-weight: 600;
    }
    overflow-y: scroll;
    height: 60vh;

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: ${theme.colors.background.float.solid};
    }
  `}
`;

export const Menu = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    padding: 0 17px 0 38px;
    color: ${theme.colors.tertiary.default};
    height: 48px;
    transition: border 0.2s ease-out;
    text-decoration: none;
    cursor: pointer;
    ${({ active }) =>
      active && `border-left: 5px solid ${theme.colors.tertiary.default};`};

    > img {
      margin-right: 16px;
    }

    &:hover {
      color: ${theme.colors.darkGray};
      border-left: 4.35px solid ${theme.colors.tertiary.default};
      background: linear-gradient(
        90.04deg,
        rgba(0, 0, 0, 0.1) 22.31%,
        rgba(0, 0, 0, 0) 109.16%
      );
    }
  `}
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 120px;

  > img {
    width: 95%;
  }
`;

export const BorderBottom = styled.div`
  border-bottom: 1px solid;

  border-image-source: linear-gradient(
    90.04deg,
    rgba(0, 0, 0, 0.1) 22.31%,
    rgba(0, 0, 0, 0) 109.16%
  );
  border-image-slice: 1;
`;

export const ContainerModal = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    min-width: 500px;

    text-align: center;
    z-index: 10;
    > img {
      width: 20%;
      height: 20%;
      padding-bottom: 24px;
    }

    > h3 {
      font-family: ${theme.typography.family.secondary};
      font-size: 1.5rem;
      line-height: 38px;
      font-weight: 700;
      color: ${theme.colors.tertiary.default};
      padding-bottom: 14px;
    }

    > p {
      padding-bottom: 24px;
      font-family: ${theme.typography.family.primary};
      color: ${theme.colors.tertiary.default};
      font-size: 1rem;
      line-height: 24px;
      font-weight: 500;
    }

    > div {
      width: 100%;
    }

    .image-list {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;

      p {
        font-size: 12px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        min-width: 38px;
      }

      div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
      span {
        width: 45px;
        margin-left: -85px;
      }
    }
  `}
`;
