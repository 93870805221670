export const typography = {
  family: {
    primary: "'Inter', sans-serif",
    secondary: "'Suvinil Sans'",
    tertiary: "Roboto, 'Berthold Imago', Arial",
  },
  sizes: {
    xs: '12px',
    sm: '14px',
    base: '16px',
    md: '18px',
    lg: '24px',
    xl: '32px',
  },
};
