import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/structure/Modal/Modal';
import Button from 'components/structure/Button/Button';
import successIcon from 'assets/icons/check-mark.svg';
import errorIcon from 'assets/icons/close-error.svg';
import infoIcon from 'assets/icons/check-info.svg';
import confirmIcon from 'assets/icons/check-gray.svg';
import alertIcon from 'assets/icons/info-circle.svg';
import { Container } from './ModalEditorialStatus.styles';

const getIcon = (sts) => {
  switch (sts) {
    case 'success':
      return <img src={successIcon} alt="" />;
    case 'error':
      return <img src={errorIcon} alt="" />;
    case 'info':
      return <img src={infoIcon} alt="" />;
    case 'confirm':
      return <img src={confirmIcon} alt="" />;
    case 'alert':
      return <img src={alertIcon} alt="" />;
    default:
      return <></>;
  }
};

const ModalEditorialStatus = ({
  status,
  children,
  title,
  text,
  isOpen,
  setIsOpen,
}) => {
  const confirmationContent = (
    <Container>
      {getIcon(status)}

      {title && <h3>{title}</h3>}

      {text && <p>{text}</p>}

      {children}

      <Button
        btnType="primary"
        width="140px"
        borderRadius="4px"
        onClick={() => setIsOpen(false)}
        fontWeight="600"
      >
        OK
      </Button>
    </Container>
  );

  return (
    <Container>
      <Modal
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        content={confirmationContent}
        zIndex="1600"
      />
    </Container>
  );
};

ModalEditorialStatus.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  status: PropTypes.string,
  setIsOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

ModalEditorialStatus.defaultProps = {
  title: '',
  text: '',
  setIsOpen: () => {},
  status: 'success',
  isOpen: false,
  children: null,
};

export default ModalEditorialStatus;
