import Grid from '@material-ui/core/Grid';
import SelectSearch from 'components/form/Select/ReactSelect';
import BoxAlert from 'components/structure/BoxAlert/BoxAlert';
import urls from 'constants/urls';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SkuProperties from '../../../components/contexts/skus/SkuProperties';
import Button from '../../../components/structure/Button/Button';
import { ButtonsContainer } from '../Skus.styles';
import { Container } from './SkuFirstStep.styles';
import { useSkuStepContext } from '../../../providers/SkuProvider';

const SkuFirstStep = ({ setActiveStep }) => {
  const intl = useIntl();
  const history = useHistory();
  const selectedProduct = useSelector((state) => state.sku.product);
  const { combineArrays, stepData } = useSkuStepContext();
  const [platform, setPlatform] = useState([]);
  const [baseData, setBaseData] = useState([
    { base: '', systemColor: '', colors: [] },
  ]);
  const [sizeData, setSizeData] = useState([
    { size: '', width: '', height: '', length: '' },
  ]);

  const [statusForm, setStatus] = useState({
    type: '',
  });

  useEffect(() => {
    let reduxBaseData = [];
    let reduxSizeData = [];

    stepData.forEach((data) => {
      reduxBaseData.push({
        base: data.base,
        systemColor: data.systemColor,
        colors: data.colors,
      });
      reduxSizeData.push({
        size: data.size,
        width: data.width,
        height: data.height,
        length: data.length,
      });

      reduxSizeData = reduxSizeData.filter(
        (v, i, a) =>
          a.findIndex((v2) => ['base', 'size'].every((k) => v2[k] === v[k])) ===
          i,
      );

      reduxBaseData = reduxBaseData.filter(
        (v, i, a) =>
          a.findIndex((v2) => ['base', 'size'].every((k) => v2[k] === v[k])) ===
          i,
      );
    });

    setBaseData(reduxBaseData);
    setSizeData(reduxSizeData);
    setPlatform(stepData[0].platforms);
  }, []);

  const options = [
    { value: 'institucional', label: 'Suvinil' },
    { value: 'glasu', label: 'Glasu' },
    { value: 'app', label: 'App' },
    { value: 'ecommerce', label: 'Ecommerce' },
  ];

  const cancelCreate = () => {
    setActiveStep(0);
    history.push(urls.ROUTES.SKU_LIST.path);
  };

  const saveStepOne = () => {
    combineArrays(baseData, sizeData, platform);
    setActiveStep(1);
  };

  function validate() {
    if (platform.length === 0) {
      return setStatus({
        type: 'error',
      });
    }
    if (baseData.find((e) => e.base.name === '')) {
      return setStatus({
        type: 'error',
      });
    }
    if (baseData.find((e) => e.systemColor.name === '')) {
      return setStatus({
        type: 'error',
      });
    }
    if (baseData.find((e) => e.colors === [])) {
      return setStatus({
        type: 'error',
      });
    }
    if (sizeData.find((e) => e.size === '')) {
      return setStatus({
        type: 'error',
      });
    }

    return true;
  }

  return (
    <Grid container spacing={3}>
      <Grid container>
        <Grid item md={12}>
          <Container>
            <Grid md={4} className="selectContainer">
              <SelectSearch
                label="Produto"
                multi={false}
                tooltip
                placeholder={selectedProduct.name}
                options={[selectedProduct.name]}
                defaultOption={selectedProduct.name}
                disabled
              />
            </Grid>

            <Grid md={4} className="">
              <SelectSearch
                label={intl.messages['labelsInputs.platforms']}
                placeholder={intl.messages['labelsInputs.select']}
                options={options}
                defaultMultiOptions={platform}
                handleChange={(value) => setPlatform(value)}
                multi
                error={
                  statusForm.type === 'error'
                    ? 'Campo Plataforma deve ser preenchido'
                    : ''
                }
                isError={platform.length === 0 && statusForm.type === 'error'}
              />
            </Grid>
          </Container>
          <SkuProperties
            baseList={baseData}
            setBaseList={setBaseData}
            sizeList={sizeData}
            setSizeList={setSizeData}
            statusForm={statusForm}
          />
        </Grid>
      </Grid>
      <ButtonsContainer>
        <Grid container spacing={2}>
          <Grid item md={6} sm={12} />
          <Grid item md={3} sm={12}>
            <Button
              btnType="secondary"
              width="100%"
              borderRadius="4px"
              fontWeight="600"
              onClick={cancelCreate}
            >
              {intl.messages['buttons.cancel']}
            </Button>
          </Grid>
          <Grid item md={3} sm={12}>
            <Button
              btnType="primary"
              width="100%"
              borderRadius="4px"
              fontWeight="600"
              onClick={() => {
                if (validate()) {
                  saveStepOne();
                }
              }}
            >
              {intl.messages['buttons.advance']}
            </Button>
          </Grid>
        </Grid>
      </ButtonsContainer>
    </Grid>
  );
};
export default SkuFirstStep;

SkuFirstStep.propTypes = { setActiveStep: PropTypes.func };

SkuFirstStep.defaultProps = {
  setActiveStep: () => {},
};
