import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/structure/IconButton/IconButton';
import { ReactComponent as TrashPropertyIcon } from 'assets/icons/trash.svg';
import { ReactComponent as MoveIcon } from 'assets/icons/move.svg';
import { useDrag, useDrop } from 'react-dnd';

import * as S from './SkuImage.styles';

const SkuImage = ({ id, image, index, removeSkuImageSelf, moveItem }) => {
  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <S.Container
      draggable
      ref={ref}
      isDragging={isDragging}
      data-handler-id={handlerId}
    >
      <p>{index + 1}</p>
      <img
        style={{ width: '60px', height: '60px' }}
        src={typeof image === 'string' ? image : URL.createObjectURL(image)}
        alt="Imagem"
      />

      <div className="actions">
        <IconButton
          className="move-icon"
          btnType="transparent"
          icon={<MoveIcon />}
        />
        <IconButton
          btnType="transparent"
          icon={<TrashPropertyIcon />}
          onClick={() => removeSkuImageSelf(index)}
        />
      </div>
    </S.Container>
  );
};

SkuImage.propTypes = {
  id: PropTypes.string.isRequired,
  image: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired,
  removeSkuImageSelf: PropTypes.func.isRequired,
  moveItem: PropTypes.func.isRequired,
};

export default SkuImage;
