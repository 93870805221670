/* eslint-disable no-useless-return */
/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import PropTypes from 'prop-types';
import tinymce from 'tinymce/tinymce';
import { POST_ARTICLES_UPLOADS } from 'constants/endpoints';
import { getAccessToken } from 'lib/contexts/auth';
import { useDispatch, useSelector } from 'react-redux';

import ModalStatus from 'components/contexts/modal/ModalEditorialStatus/ModalEditorialStatus';
import './NewEditor.css';
// Theme
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';
// importing the plugin js.
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import './tinymce/langs/pt_BR';
import { getArticleDetail } from 'store/articles/articles-slice';

const NewEditor = ({ onInit, onEditorChange, initialValue, disabled }) => {
  const { selectId } = useSelector((state) => state.articles);
  const [showModalError, setShowModalError] = useState(false);
  const [showModalErrorEmptyField, setShowModalErrorEmptyField] =
    useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getArticleDetail(parseInt(selectId, 10)));
  }, [selectId, dispatch]);

  function uploadImageCallBack(blobInfo, success, failure, progress) {
    let xhr;
    let formData;

    xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open('POST', `${POST_ARTICLES_UPLOADS}`);
    xhr.setRequestHeader('x-api-key', process.env.REACT_APP_API_KEY);
    xhr.setRequestHeader('Authorization', `Bearer ${getAccessToken()}`);

    xhr.upload.onprogress = function (e) {
      progress((e.loaded / e.total) * 100);
    };

    xhr.onload = function () {
      let json;

      if (xhr.status === 400) {
        failure(`HTTP Error: ${xhr.status}`, { remove: true });
        return;
      }

      if (xhr.status === 403) {
        failure(`HTTP Error: ${xhr.status}`, { remove: true });
        return;
      }

      if (xhr.status < 200 || xhr.status >= 300) {
        failure(`HTTP Error: ${xhr.status}`);
        return;
      }
      json = JSON.parse(xhr.responseText);

      let obj = { location: json ? json[0].fullUrl : '' };

      if (!obj || typeof obj.location !== 'string') {
        failure(`Invalid JSON: ${xhr.responseText}`);
        return;
      }

      success(obj.location);
    };

    xhr.onerror = function () {
      failure(
        `Não foi possível fazer o upload da imagem. Code: ${xhr.status}`,
        { remove: true },
      );
    };

    formData = new FormData();
    formData.append('files', blobInfo.blob());
    formData.append('id', selectId);
    formData.append('entity', 'articles');
    formData.append('service', 'contents');
    formData.append('context', 'showcase');
    formData.append('type', 'image');
    formData.append('alternativeText', JSON.stringify([blobInfo.filename()]));

    xhr.send(formData);
  }
  const textModalAlert = (
    <p>
      O texto do campo <strong>Descrição alternativa</strong> deve explicar o
      que vemos na imagem, de forma clara e objetiva. É de extrema importância
      para acessibilidade (pessoas com deficiência visual que usam esses
      recursos) e SEO.
    </p>
  );

  return (
    <>
      <Editor
        tinymceScriptSrc="/path/to/tinymce.min.js"
        onInit={onInit}
        onEditorChange={onEditorChange}
        initialValue={initialValue}
        disabled={disabled}
        init={{
          plugins:
            'paste importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr nonbreaking toc insertdatetime advlist lists wordcount imagetools textpattern noneditable charmap',
          imagetools_cors_hosts: ['picsum.photos'],
          menubar: false,
          toolbar:
            'undo redo | bold italic underline strikethrough superscript subscript | formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | insertfile image media link table',
          media_filter_html: false,
          toolbar_sticky: false,
          block_formats:
            'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6',
          autosave_ask_before_unload: true,
          autosave_interval: '30s',
          autosave_prefix: '{path}{query}-{id}-',
          autosave_restore_when_empty: false,
          autosave_retention: '2m',
          image_advtab: false,
          image_list: false,
          image_class_list: false,
          importcss_append: true,

          templates: [
            {
              title: 'New Table',
              description: 'creates a new table',
              content:
                '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
            },
            {
              title: 'Starting my story',
              description: 'A cure for writers block',
              content: 'Once upon a time...',
            },
            {
              title: 'New list with dates',
              description: 'New List with dates',
              content:
                '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
            },
          ],
          table_class_list: [
            { title: 'None', value: 'table_no_content' },
            { title: 'Conteúdo', value: 'table_content' },
          ],
          template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
          template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
          height: 600,
          image_caption: true,
          noneditable_noneditable_class: 'mceNonEditable',
          toolbar_mode: 'sliding',
          contextmenu: 'link image imagetools table',

          image_title: false,

          file_picker_types: 'image',

          images_upload_handler: uploadImageCallBack,

          file_picker_callback(cb, value) {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');
            input.setAttribute('alt', value);

            input.onchange = function () {
              const file = input.files[0];
              const reader = new FileReader();
              if (file.size < 1.5 * 1024 * 1024) {
                reader.onload = function () {
                  const id = `blobid${new Date().getTime()}`;
                  const { blobCache } = tinymce.activeEditor.editorUpload;
                  const base64 = reader.result.split(',')[1];
                  const blobInfo = blobCache.create(id, file, base64);
                  blobCache.add(blobInfo);
                  cb(blobInfo.blobUri(), {
                    alt: value === '' && setShowModalErrorEmptyField(true),
                    width: '80%',
                    height: '80%',
                  });
                };
                setShowModalError(false);
              } else {
                setShowModalError(true);
              }

              reader.readAsDataURL(file);
            };

            input.click();
          },
          file_browser_callback_types: 'image',

          paste_data_images: true,
          language: 'pt_BR',
          content_css: '/NewEditor.css',
          media_poster: false,
          default_link_target: '_blank',

          setup(editor) {
            editor.on('GetContent', function (e) {
              e.content = e.content
                .replace(/&lt;\/strong &gt;/g, '</strong>')
                .replace(/&lt;\/a &gt;/g, '</a>');
            });
          },
        }}
      />
      {showModalError && (
        <ModalStatus
          title="Ops!"
          text="Não foi possível fazer o upload da imagem. A imagem deve ter no máximo 1.5 MB."
          setIsOpen={() => setShowModalError(false)}
          isOpen={showModalError}
          status="error"
        />
      )}
      {showModalErrorEmptyField && (
        <ModalStatus
          title="Atenção!"
          text={textModalAlert}
          setIsOpen={() => setShowModalErrorEmptyField(false)}
          isOpen={showModalErrorEmptyField}
          status="alert"
        />
      )}
    </>
  );
};

NewEditor.propTypes = {
  initialValue: PropTypes.string,
  onInit: PropTypes.func,
  onEditorChange: PropTypes.func,
  disabled: PropTypes.bool,
};

NewEditor.defaultProps = {
  initialValue: '',
  onInit: () => {},
  onEditorChange: () => {},
  disabled: false,
};

export default NewEditor;
