/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { FaChevronDown } from 'react-icons/fa';
import Tooltip from 'assets/icons/tooltip-icon.svg';
import TooltipComponent from 'components/structure/Tooltip/Tooltip';
import api from 'services/api';
import { EDITORIALS } from 'constants/endpoints';
import SpanError from 'components/structure/SpanError/SpanError';

import {
  selectStyle,
  custom,
  Container,
  Label,
  LabelContainer,
  OptionalStyle,
} from './AsyncSelectPaginate.styles';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaChevronDown />
    </components.DropdownIndicator>
  );
};

const AsyncSelectPaginateEditorial = (props) => {
  const [currentOptions, setCurrentOptions] = useState({
    page: 1,
    searchQuery: null,
  });
  const [platforms, setPlatforms] = useState('');
  useEffect(() => {
    setPlatforms(props.platforms);
  }, [props.platforms]);

  const loadOptionsData = async (searchQuery, loadedOptions, { page }) => {
    const { data } = await api.get(
      searchQuery === ''
        ? `${EDITORIALS}?perPage=10&page=${page}&platforms=[${platforms}]&sort.field=name&sort.order=ASC`
        : `${EDITORIALS}?perPage=10&page=${page}&platforms=[${platforms}]&sort.field=name&sort.order=ASC&name=${searchQuery}`,
    );

    setCurrentOptions({ page, searchQuery });

    return {
      options: data.items !== undefined ? data.items : [],
      hasMore: data.totalPage !== undefined ? data.totalPage > page + 1 : 0,
      additional: {
        page: searchQuery === currentOptions.searchQuery ? page + 1 : page + 1,
      },
    };
  };

  const onChange = (option) => {
    if (typeof props.onChange === 'function') {
      props.onChange(option);
    }
  };

  return (
    <Container isError={props.isError}>
      <LabelContainer>
        <Label isError={props.isError}>{props.label}</Label>

        <TooltipComponent id="tooltipImage" title={props.title} multiline>
          {props.optional && <OptionalStyle>(Opcional)</OptionalStyle>}
          {props.tooltip && <img src={Tooltip} alt="" />}
        </TooltipComponent>
      </LabelContainer>
      {props.multi ? (
        <AsyncPaginate
          key={JSON.stringify(platforms)}
          name={props.name}
          placeholder={props.placeholder}
          value={props.value || ''}
          loadOptions={loadOptionsData}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.name}
          onChange={onChange}
          isSearchable
          additional={{
            page: 1,
          }}
          isMulti
          isDisabled={props.disabled}
          styles={selectStyle}
          components={{ IndicatorSeparator: null, DropdownIndicator }}
          theme={custom}
          noOptionsMessage={({ inputValue }) =>
            !inputValue ? '' : 'Item não encontrado'
          }
          loadingMessage={() => 'Carregando...'}
        />
      ) : (
        <AsyncPaginate
          key={JSON.stringify(platforms)}
          name={props.name}
          placeholder={props.placeholder}
          value={props.value || ''}
          loadOptions={loadOptionsData}
          getOptionValue={(option) => option.name}
          getOptionLabel={(option) => option.name}
          onChange={onChange}
          isSearchable
          additional={{
            page: 1,
          }}
          isDisabled={props.disabled}
          styles={selectStyle}
          components={{ IndicatorSeparator: null, DropdownIndicator }}
          theme={custom}
          noOptionsMessage={({ inputValue }) =>
            !inputValue ? '' : 'Item não encontrado'
          }
          loadingMessage={() => 'Carregando...'}
        />
      )}
      {props.isError && <SpanError>{props.error}</SpanError>}
      {props.hookForm && props.isError && (
        <SpanError>{props.error.message}</SpanError>
      )}
    </Container>
  );
};

AsyncSelectPaginateEditorial.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  multi: PropTypes.bool,
  optional: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  tooltip: PropTypes.bool,
  value: PropTypes.object,
  platforms: PropTypes.string.isRequired,
  error: PropTypes.string,
  isError: PropTypes.bool,
  hookForm: PropTypes.bool,
};

AsyncSelectPaginateEditorial.defaultProps = {
  name: '',
  label: '',
  placeholder: '',
  multi: false,
  onChange: () => {},
  optional: false,
  disabled: false,
  title: '',
  tooltip: false,
  value: {},
  error: '',
  isError: false,
  hookForm: false,
};

export default AsyncSelectPaginateEditorial;
