import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SpanError from 'components/structure/SpanError/SpanError';
import Tooltip from 'assets/icons/tooltip-icon.svg';
import TooltipComponent from 'components/structure/Tooltip/Tooltip';
import {
  Container,
  SelectWrapper,
  Label,
  SelectTag,
  LabelContainer,
  OptionalStyle,
} from './InputSelectProperties.styles';

const InputSelect = ({
  type,
  name,
  label,
  options,
  value,
  isError,
  error,
  disabled,
  handleChange,
  register,
  optional,
  title,
  tooltip,
  ...rest
}) => {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (value) {
      setSelected(true);
    }
  }, [value]);

  return (
    <Container isError={isError}>
      <LabelContainer>
        <Label htmlFor={name} isError={isError}>
          {label}
        </Label>

        <TooltipComponent id="tooltipImage" title={title} multiline>
          {optional && <OptionalStyle>(Opcional)</OptionalStyle>}
          {tooltip && <img src={Tooltip} alt="" />}
        </TooltipComponent>
      </LabelContainer>
      <SelectWrapper isError={isError}>
        <SelectTag
          selected={selected}
          type={type}
          name={name}
          value={`${value}`}
          onChange={handleChange}
          isError={isError}
          disabled={disabled}
          ref={register}
          {...rest}
        >
          <option value="">Selecione</option>
          {options.map((item) => (
            <option key={item.value} value={`${item.value}`}>
              {item.label}
            </option>
          ))}
        </SelectTag>
      </SelectWrapper>
      {isError && <SpanError>{error.message}</SpanError>}
    </Container>
  );
};

InputSelect.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  handleChange: PropTypes.func,
  value: PropTypes.string,
  isError: PropTypes.bool,
  error: PropTypes.object,
  disabled: PropTypes.bool,
  register: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  optional: PropTypes.bool,
  title: PropTypes.string,
  tooltip: PropTypes.bool,
};

InputSelect.defaultProps = {
  type: '',
  name: '',
  label: '',
  options: [{}],
  handleChange: () => {},
  value: '',
  isError: false,
  error: {},
  disabled: false,
  register: undefined,
  optional: false,
  title: '',
  tooltip: false,
};

export default InputSelect;
