/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  ARTICLES,
  COLLECTIONS,
  COLORS,
  EDITORIALS,
  FAMILIES,
  PRODUCTS,
  TAGS,
} from 'constants/endpoints';
import api from 'services/api';
import multiForm from 'services/multiForm';
import { startLoading, stopLoading } from 'store/loading/loading-slice';
import { errorSessionToast, errorToast, successToast } from 'utils/toasts';

const INITIAL_STATE = {
  articles: {
    totalPage: 1,
    items: [],
  },
  article: {
    id: 0,
    name: '',
    slug: '',
    siteTitle: '',
    description: '',
    author: '',
    shortDescription: '',
    published: 'false',
    platforms: [],
    defaultEditorial: [{ id: 0 }],
    editorials: [],
    images: [],
    colorGroups: [],
    colors: [],
    products: [],
    tags: [],
    collections: [],
  },
  selectId: 0,
  isLoading: 0,
  status: '',
  editorials: [],
  openStatusModal: false,
  msg: {
    title: '',
    text: '',
  },
  activeStep: 0,
  tags: [],
  products: [],
  colors: [],
  collections: [],
  colorGroups: [],
  publishedModal: false,
};

const articlesSlice = createSlice({
  name: 'articles',
  initialState: INITIAL_STATE,
  reducers: {
    getArticles: (state) => {
      state.isLoading = 1;
      state.status = '';
    },
    getArticlesSuccess: (state, { payload }) => {
      state.articles = payload;
      state.isLoading = 0;
    },
    getArticlesByEditorial: (state) => {
      state.isLoading = 1;
      state.status = '';
    },
    getArticlesByEditorialSuccess: (state, { payload }) => {
      state.articles = payload;
      state.isLoading = 0;
    },
    deleteArticle: (state) => {
      state.isLoading = 1;
      state.status = '';
    },
    deleteArticleSuccess: (state) => {
      state.isLoading = 0;
      state.status = 'delete-success';
    },
    getEditorialsListSuccess: (state, { payload }) => {
      state.editorials = payload;
    },
    statusArticlesModal: (state, { payload }) => {
      state.openStatusModal = payload;
    },
    setArticlesStatus: (state, { payload }) => {
      state.status = payload;
    },
    setArticlesMsg: (state, { payload }) => {
      state.msg = payload;
    },
    setArticleId: (state, { payload }) => {
      state.selectId = payload;
    },
    getArticleSuccess: (state, { payload }) => {
      state.article = payload;
    },
    setActiveStep: (state, { payload }) => {
      state.activeStep = payload;
    },
    getTagsListSuccess: (state, { payload }) => {
      state.tags = payload;
    },
    getProductsListSuccess: (state, { payload }) => {
      state.products = payload;
    },
    getColorsListSuccess: (state, { payload }) => {
      state.colors = payload;
    },
    getCollectionsArticlesListSuccess: (state, { payload }) => {
      state.collections = payload;
    },
    getFamiliesListArticlesSuccess: (state, { payload }) => {
      state.colorGroups = payload;
    },

    setPublishedModal: (state, { payload }) => {
      state.publishedModal = payload;
    },
    getEditorialsListInstitucionalSuccess: (state, { payload }) => {
      state.editorials = payload;
    },
    getEditorialsListGlasuSuccess: (state, { payload }) => {
      state.editorials = payload;
    },
    getPublishedArticleSuccess: (state, { payload }) => {
      state.article = payload;
    },
  },
});
export const {
  deleteArticle,
  deleteArticleSuccess,
  getArticleSuccess,
  getArticles,
  getArticlesByEditorial,
  getArticlesByEditorialSuccess,
  getArticlesSuccess,
  getCollectionsArticlesListSuccess,
  getColorsListSuccess,
  getEditorialsListGlasuSuccess,
  getEditorialsListInstitucionalSuccess,
  getEditorialsListSuccess,
  getFamiliesListArticlesSuccess,
  getProductsListSuccess,
  getPublishedArticleSuccess,
  getTagsListSuccess,
  setActiveStep,
  setArticleId,
  setArticlesMsg,
  setArticlesStatus,
  setPublishedModal,
  statusArticlesModal,
} = articlesSlice.actions;
export const articles = articlesSlice.reducer;
export const getListArticles = (payload) => async (dispatch) => {
  const { currentPage, searchTerm, perPage, ordenation, type } = payload;
  dispatch(startLoading());

  try {
    let searchTermReplaced = searchTerm;
    let params = {};

    if (searchTerm !== '' && searchTerm.indexOf('|') !== -1) {
      searchTermReplaced = searchTerm.replace(/\|/g, '%7C');
    }

    if (searchTerm === '') {
      params = {
        perPage: perPage || 10,
        type: type || 'pdp',
        page: currentPage,
        'sort.field': ordenation || 'createdAt',
        '&sort.order': ordenation ? 'ASC' : 'DESC',
      };
    } else {
      params = {
        name: searchTermReplaced,
        type: type || 'pdp',
        perPage: perPage || 10,
        page: currentPage,
        'sort.field': ordenation || 'createdAt',
        '&sort.order': ordenation ? 'ASC' : 'DESC',
      };
    }

    const { data } = await api.get(ARTICLES, {
      params,
    });

    const articlesData = {
      totalPage: data.totalPage,
      items: data.items,
    };
    dispatch(getArticlesSuccess(articlesData));
  } catch (error) {
    errorToast(error);
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  } finally {
    dispatch(stopLoading());
  }
};

export const getListArticlesbyEditorial = (payload) => async (dispatch) => {
  const { id, page, searchTerm, perPage, ordenation } = payload;
  dispatch(startLoading());

  try {
    let url = '';
    if (searchTerm === '') {
      url = `${EDITORIALS}/${id}/articles?perPage=${
        perPage || '10'
      }&page=${page}&sort.field=${ordenation || 'createdAt'}&sort.order=${
        ordenation ? 'ASC' : 'DESC'
      }`;
    } else {
      url = `${EDITORIALS}/${id}/articles?perPage=${
        perPage || '10'
      }&page=${page}&name=${searchTerm}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}`;
    }
    const { data } = await api.get(url);

    const articlesData = {
      totalPage: data.totalPage,
      items: data.items,
    };
    dispatch(getArticlesByEditorialSuccess(articlesData));
    dispatch(stopLoading());
  } catch (error) {
    errorToast(error);
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getDeletedArticlesList = (payload) => async (dispatch) => {
  const { page, searchTerm, perPage, ordenation } = payload;
  dispatch(startLoading());
  try {
    let url = '';
    if (searchTerm === '') {
      url = `${ARTICLES}?perPage=${perPage || '10'}&page=${page}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}&trash=true`;
    } else {
      url = `${ARTICLES}?perPage=${
        perPage || '10'
      }&page=${page}&name=${searchTerm}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}`;
    }
    const { data } = await api.get(url);

    const articlesData = {
      totalPage: data.totalPage,
      items: data.items,
    };
    dispatch(getArticleSuccess(articlesData));

    dispatch(stopLoading());
  } catch (error) {
    errorToast(error);
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const removeArticle = (payload) => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api.delete(`${ARTICLES}/${payload}`);
    dispatch(deleteArticleSuccess());
  } catch (error) {
    errorToast('Ocorreu um erro ao excluir o artigo! Tente novamente.');
    dispatch(stopLoading());
  }
};

export const getEditorialOptions = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await api.get(`${EDITORIALS}?perPage=1000&page=1`);
    const optionsData = response.data.items.map((option) => ({
      value: option.id,
      label: option.name,
    }));
    dispatch(getEditorialsListSuccess(optionsData));

    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const saveArticleStepOne = (payload) => async (dispatch) => {
  const { info, goTo } = payload;
  dispatch(startLoading());
  try {
    const response = await multiForm.post(ARTICLES, info);
    dispatch(setArticleId(response.data.id));
    dispatch(stopLoading());
    dispatch(setActiveStep(goTo));
    successToast('Artigo criado com sucesso!');
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Ops!',
            text: 'Não foi possível criar o seu artigo, verifique as informações',
          };

          dispatch(setArticlesMsg(msg));
          dispatch(setArticlesStatus('error'));
          dispatch(statusArticlesModal(true));
          break;
        case 409:
          msg = {
            title: 'Ops!',
            text: 'Artigo já existente.',
          };
          dispatch(setArticlesMsg(msg));
          dispatch(setArticlesStatus('error'));
          dispatch(statusArticlesModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const redirectArticleUpdate = (payload) => async (dispatch) => {
  dispatch(startLoading());

  try {
    await api.post(`/redirects`, payload);

    successToast('Redirecionamento feito com sucesso');
  } catch (error) {
    errorToast(error);
    dispatch(stopLoading());
  }
};

export const getArticleDetail = (payload) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await api.get(`${ARTICLES}/${payload}`);
    dispatch(getArticleSuccess(response.data));

    dispatch(stopLoading());
  } catch (error) {
    errorToast(error);
    dispatch(stopLoading());
  }
};

export const getDeletedArticleDetail = (payload) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await api.get(`${ARTICLES}/${payload}?trash=true`);
    dispatch(getArticleSuccess(response.data));

    dispatch(stopLoading());
  } catch (error) {
    errorToast(error);
    dispatch(stopLoading());
  }
};

export const getArticleDetailPublished = (payload) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await api.get(`${ARTICLES}/${payload}`);
    dispatch(getPublishedArticleSuccess(response.data));
    dispatch(setPublishedModal(true));

    dispatch(stopLoading());
  } catch (error) {
    errorToast(error);
    dispatch(stopLoading());
  }
};

export const updateArticle = (payload) => async (dispatch) => {
  const { id, info, goTo } = payload;

  dispatch(startLoading());
  try {
    await multiForm.patch(`${ARTICLES}/${id}`, info);
    dispatch(stopLoading());

    dispatch(setArticlesStatus('success'));
    dispatch(setActiveStep(goTo));

    successToast('Artigo alterado com sucesso!');
    if (info.get('pub')) {
      successToast(`O artigo ${info.get('name')} foi editado com sucesso!`);
    }
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Erros de Validação',
            text: 'Verifique os campos e tente novamente!',
          };
          dispatch(setArticlesMsg(msg));
          dispatch(setArticlesStatus('error'));
          dispatch(statusArticlesModal(true));
          break;
        case 409:
          msg = {
            title: 'Artigo já existente',
            text: '',
          };
          dispatch(setArticlesMsg(msg));
          dispatch(setArticlesStatus('error'));
          dispatch(statusArticlesModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const getTagsOptions = (payload) => async (dispatch) => {
  try {
    const response = await api.get(TAGS + payload);
    const optionsData = response.data.items.map((option) => ({
      value: option.id,
      label: option.name,
    }));
    dispatch(getTagsListSuccess(optionsData));
  } catch (error) {
    /// TODO
    console.log(error);
  }
};

export const getProductsOptions = (payload) => async (dispatch) => {
  try {
    const response = await api.get(PRODUCTS + payload);
    const optionsData = response.data.items.map((option) => ({
      value: option.id,
      label: option.name,
    }));
    dispatch(getProductsListSuccess(optionsData));
  } catch (error) {
    /// TODO
    console.log(error);
  }
};

export const getFamiliesArticlesOptions = (payload) => async (dispatch) => {
  try {
    const response = await api.get(FAMILIES + payload);
    const optionsData = response.data.items.map((option) => ({
      value: option.id,
      label: option.name,
    }));
    dispatch(getFamiliesListArticlesSuccess(optionsData));
  } catch (error) {
    /// TODO
    console.log(error);
  }
};

export const getColorsOptions = (payload) => async (dispatch) => {
  try {
    const response = await api.get(COLORS + payload);

    const optionsData = response.data.items.map((option) => ({
      value: option.id,
      label: option.name,
    }));
    dispatch(getColorsListSuccess(optionsData));
  } catch (error) {
    /// TODO
    console.log(error);
  }
};

export const getCollectionsOptions = () => async (dispatch) => {
  try {
    const response = await api.get(
      `${COLLECTIONS}?perPage=1000&page=1&platforms=["institucional"]`,
    );
    const optionsData = response.data.items.map((option) => ({
      value: option.id,
      label: option.name,
    }));
    dispatch(getCollectionsArticlesListSuccess(optionsData));
  } catch (error) {
    /// TODO
    console.log(error);
  }
};

export const getEditorialOptionsInstitucional = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await api.get(
      `${EDITORIALS}?perPage=1000&page=1&platforms=["institucional"]`,
    );
    const optionsData = response.data.items.map((option) => ({
      value: option.id,
      label: option.name,
    }));
    dispatch(getEditorialsListInstitucionalSuccess(optionsData));

    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getEditorialOptionsGlasu = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await api.get(
      `${EDITORIALS}?perPage=1000&page=1&platforms=["glasu"]`,
    );
    const optionsData = response.data.items.map((option) => ({
      value: option.id,
      label: option.name,
    }));
    dispatch(getEditorialsListGlasuSuccess(optionsData));

    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const updatePublishedArticle = (payload) => async (dispatch) => {
  const { id, info, goTo } = payload;

  dispatch(startLoading());

  try {
    await multiForm.patch(`${ARTICLES}/${id}`, info);
    dispatch(stopLoading());
    dispatch(setArticlesStatus('success'));
    dispatch(setActiveStep(goTo));
    if (info.get('pub')) {
      successToast(`O artigo ${info.get('name')} foi editado com sucesso!`);
    }
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Ops!',
            text: 'Não foi possível publicar o artigo. Verifique os campos e tente novamente!',
          };
          dispatch(setArticlesMsg(msg));
          dispatch(setArticlesStatus('error'));
          dispatch(statusArticlesModal(true));
          break;
        case 409:
          msg = {
            title: 'Artigo já publicado',
            text: '',
          };
          dispatch(setArticlesMsg(msg));
          dispatch(setArticlesStatus('error'));
          dispatch(statusArticlesModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};
