import Grid from '@material-ui/core/Grid';
import ActionButtons from 'components/form/ActionButtons';
import AsyncSelectPaginateRgb from 'components/form/Select/AsyncSelectPaginate/AsyncSelectPaginateRgb';
import InputText from 'components/form/Text/TextInput/InputText';
import Toggle from 'components/form/Toggle/Toggle';
import FormContainer from 'components/structure/FormContainer/FormContainer';
import { COLORS } from 'constants/endpoints';
import urls from 'constants/urls';
import { getUserData } from 'lib/contexts/auth';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getPermission } from 'utils/permissions';

const ColorCombinationsView = () => {
  const { selectId, colorCombination } = useSelector(
    (state) => state.colorCombinations,
  );
  const user = getUserData();
  const [name, setName] = useState('');
  const [color1, setColor1] = useState();
  const [color2, setColor2] = useState();
  const [color3, setColor3] = useState();
  const [color4, setColor4] = useState();
  const [publicationStatus, setPublicationStatus] = useState(true);

  const { permissions } = useSelector((state) => state.auth);
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    if (getPermission('color-groups', permissions, 'create') === false) {
      history.push('/');
    } // trocar pra color-combination
  }, [history, permissions]);

  const cancelColorCombination = () => {
    history.goBack();
  };

  useEffect(() => {
    setName(colorCombination.name);
    if (colorCombination?.colorToColorCombinations[0]?.id) {
      setColor1(colorCombination.colorToColorCombinations[0]);
    }
    if (colorCombination?.colorToColorCombinations[1]?.id) {
      setColor2(colorCombination.colorToColorCombinations[1]);
    }
    if (colorCombination?.colorToColorCombinations[2]?.id) {
      setColor3(colorCombination?.colorToColorCombinations[2]);
    }
    if (colorCombination?.colorToColorCombinations[3]?.id) {
      setColor4(colorCombination?.colorToColorCombinations[3]);
    }

    setPublicationStatus(colorCombination.publicationStatus);
  }, [colorCombination]);

  return (
    <>
      <FormContainer title={intl.messages['textHeader.viewColorCombination']}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <InputText
                  placeholder={intl.messages['labelsInputs.combinationName']}
                  label={intl.messages['labelsInputs.combinationName']}
                  value={name}
                  disabled
                />
              </Grid>
              <Grid item md={12}>
                <InputText
                  placeholder={intl.messages['labelsInputs.typeAuthor']}
                  label={intl.messages['labelsInputs.typeAuthor']}
                  value={user.name}
                  disabled
                />
              </Grid>

              <Grid item md={12}>
                <AsyncSelectPaginateRgb
                  label={intl.messages['labelsInputs.firstColor']}
                  placeholder={color1}
                  value={color1}
                  url={COLORS}
                  perPage={50}
                  rgbPreview
                  disabled
                />
              </Grid>
              <Grid item md={12}>
                <AsyncSelectPaginateRgb
                  label={intl.messages['labelsInputs.secondColor']}
                  placeholder={color2}
                  value={color2}
                  url={COLORS}
                  perPage={50}
                  rgbPreview
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <AsyncSelectPaginateRgb
                  label={intl.messages['labelsInputs.thirdColor']}
                  placeholder={color3}
                  value={color3}
                  url={COLORS}
                  perPage={50}
                  rgbPreview
                  optional
                  disabled
                />
              </Grid>
              <Grid item md={12}>
                <AsyncSelectPaginateRgb
                  label={intl.messages['labelsInputs.fourthColor']}
                  placeholder={color4}
                  value={color4}
                  url={COLORS}
                  perPage={50}
                  rgbPreview
                  optional
                  disabled
                />
              </Grid>
              <Grid item md={12}>
                <Toggle
                  label={intl.messages['labelsInputs.publicationStatus']}
                  checked={publicationStatus}
                  onChange={(e) => setPublicationStatus(e.target.checked)}
                  regular
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ActionButtons
          type="none"
          cancelPath={cancelColorCombination}
          submitFunc={() =>
            history.push(urls.ROUTES.COLOR_COMBINATIONS_VIEW.path)
          }
        />
      </FormContainer>
    </>
  );
};

export default ColorCombinationsView;
