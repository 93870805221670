/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseURL, SKUS, SKUS_BATCH } from 'constants/endpoints';
import { getAccessToken } from 'lib/contexts/auth';
import api from 'services/api';
import multiForm from 'services/multiForm';
import { startLoading, stopLoading } from 'store/loading/loading-slice';
import { preventRedirectDisabled } from 'store/sidebar/sidebar-slice';
import { errorSessionToast, errorToast, successToast } from 'utils/toasts';

const INITIAL_STATE = {
  product: {
    id: 0,
    name: '',
  },
  skus: {
    totalPage: 1,
    items: [],
  },
  sku: {
    id: null,
    sizeId: null,
    productToColorId: null,
    productId: null,
    baseId: null,
    yield: null,
    finishYield: null,
    grossWeight: null,
    liquidWeight: null,
    width: null,
    height: null,
    length: null,
    ean: '',
    sapCode: null,
    systemColor: '',
    default: false,
    vtexSkuId: null,
    platforms: [],
    productToColor: {
      id: [{ id: 0 }],
      productId: null,
      colorId: null,
      vtexProductId: null,
      platforms: [],
      color: {
        id: null,
      },
      product: {
        id: null,
        name: '',
      },
    },
    size: {
      id: [{ id: 0 }],
      name: '',
    },
    base: {
      id: [{ id: 0 }],
      name: '',
    },
    images: [],
    prices: [],
    complements: [],
  },
  openStatusModal: false,
  status: '',
  msg: {
    title: '',
    text: '',
  },
  selectId: 0,
  openModalDelete: false,
};

const skuSlice = createSlice({
  name: 'sku',
  initialState: INITIAL_STATE,
  reducers: {
    setProductSku: (state, { payload }) => {
      state.product = payload;
    },
    createManySkus: (state, { payload }) => {
      state.skus = payload;
    },
    getSkusSuccess: (state, { payload }) => {
      state.skus = payload;
    },
    setSkusStatus: (state, { payload }) => {
      state.status = payload;
    },
    statusSkusModal: (state, { payload }) => {
      state.openStatusModal = payload;
    },
    setSkusMsg: (state, { payload }) => {
      state.msg = payload;
    },
    setSkuId: (state, { payload }) => {
      state.selectId = payload;
    },
    getSkuSuccess: (state, { payload }) => {
      state.sku = payload;
    },
    openSkuModalDelete: (state) => {
      state.openModalDelete = true;
    },
    dismissSkuModalDelete: (state) => {
      state.openModalDelete = false;
    },
    getDeletedSkusSuccess: (state, { payload }) => {
      state.skus = payload;
    },
  },
});

export const sku = skuSlice.reducer;
export const {
  createManySkus,
  dismissSkuModalDelete,
  getDeletedSkusSuccess,
  getSkuSuccess,
  getSkusSuccess,
  openSkuModalDelete,
  setProductSku,
  setSkuId,
  setSkusMsg,
  setSkusStatus,
  statusSkusModal,
} = skuSlice.actions;

export const getListSkus = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { currentPage, searchTerm, productId, perPage, ordenation } = payload;
  try {
    let url = '';
    if (searchTerm === '') {
      url = `${SKUS}?perPage=${perPage || '10'
        }&page=${currentPage}&productId=${productId}&sort.field=${ordenation || 'createdAt'
        }&sort.order=${ordenation !== 'updatedAt' ? 'ASC' : 'DESC'}`;
    } else {
      url = `${SKUS}?perPage=${perPage || '10'
        }&page=${currentPage}&ean=${searchTerm}&productId=${productId}&sort.field=${ordenation || 'createdAt'
        }&sort.order=${ordenation !== 'updatedAt' ? 'ASC' : 'DESC'}`;
    }
    const { data } = await api.get(url);

    const skusData = {
      totalPage: data.totalPage,
      items: data.items,
    };

    dispatch(getSkusSuccess(skusData));
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getListDeletedSkus = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { currentPage, searchTerm, productId, perPage, ordenation } = payload;
  try {
    let url = '';
    if (searchTerm === '') {
      url = `${SKUS}?perPage=${perPage || '10'
        }&page=${currentPage}&productId=${productId}&sort.field=${ordenation || 'createdAt'
        }&sort.order=${ordenation ? 'ASC' : 'DESC'}&trash=true`;
    } else {
      url = `${SKUS}?perPage=${perPage || '10'
        }&page=${currentPage}&ean=${searchTerm}&productId=${productId}&sort.field=${ordenation || 'createdAt'
        }&sort.order=${ordenation ? 'ASC' : 'DESC'}&trash=true`;
    }
    const { data } = await api.get(url);

    const skusData = {
      totalPage: data.totalPage,
      items: data.items,
    };

    dispatch(getDeletedSkusSuccess(skusData));
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const saveSku = (payload) => async (dispatch) => {
  dispatch(startLoading());
  multiForm.post(SKUS_BATCH, payload).then(() => {
    dispatch(stopLoading());
    payload.nextStep(1);
    dispatch(setSkusStatus('success'));
  }).catch((error) => {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Ops!',
            text: 'Não foi possível criar esse SKU, verifique as informações.',
          };

          dispatch(statusSkusModal(true));
          dispatch(setSkusStatus('error'));
          dispatch(setSkusMsg(msg));
          break;
        case 409:
          msg = {
            title: 'Sku já existente',
            text: '',
          };
          dispatch(statusSkusModal(true));
          dispatch(setSkusStatus('error'));
          dispatch(setSkusMsg(msg));
          break;
        case 202:
          dispatch(setSkusStatus('success'));
          break;
        default:
          // esta apresentando falsos negativos sera resolvido em uma proxima versao
          break;
      }
    }
  })
};

export const saveSkus = (payload) => async (dispatch) => {
  dispatch(startLoading());
  try {
    await new Promise(
      (resolve) => resolve(api.post(`${SKUS}/many`, payload)),

      successToast('SKUs em massa criados com sucesso'),

      dispatch(stopLoading()),
    );

    dispatch(preventRedirectDisabled());
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Ops!',
            text: 'Não foi possível criar esses SKUs, verifique as informações.',
          };
          dispatch(statusSkusModal(true));
          dispatch(setSkusStatus('error'));
          dispatch(setSkusMsg(msg));
          break;
        case 409:
          msg = {
            title: 'Sku já existente',
            text: '',
          };
          dispatch(statusSkusModal(true));
          dispatch(setSkusStatus('error'));
          dispatch(setSkusMsg(msg));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const putSku = (payload) => async (dispatch) => {
  const { id, info } = payload;

  dispatch(startLoading());

  try {
    await multiForm.patch(`${SKUS}/${id}`, info);
    dispatch(stopLoading());

    const msg = { title: 'Sku editado com sucesso!' };

    dispatch(setSkusMsg(msg));
    dispatch(setSkusStatus('success'));
    dispatch(statusSkusModal(true));
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Ops!',
            text: 'Não foi possível editar esse sku, verifique as informações.',
          };
          dispatch(setSkusMsg(msg));
          dispatch(setSkusStatus('error'));
          dispatch(statusSkusModal(true));
          break;
        case 409:
          msg = {
            title: 'Sku já existente',
            text: '',
          };
          dispatch(setSkusMsg(msg));
          dispatch(setSkusStatus('error'));
          dispatch(statusSkusModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast('Falha ao se comunicar com o servidor, verifique a conexão!');
    }
  }
};

export const putSkus = (payload) => async (dispatch) => {
  dispatch(startLoading());

  try {
    await api.patch(`${SKUS}`, payload);
    dispatch(stopLoading());

    const msg = { title: 'Skus editados com sucesso!' };

    dispatch(setSkusMsg(msg));
    dispatch(setSkusStatus('success'));
    dispatch(statusSkusModal(true));
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Ops!',
            text: 'Não foi possível editar esse sku, verifique as informações.',
          };
          dispatch(setSkusMsg(msg));
          dispatch(setSkusStatus('error'));
          dispatch(statusSkusModal(true));
          break;
        case 409:
          msg = {
            title: 'Sku já existente',
            text: '',
          };
          dispatch(setSkusMsg(msg));
          dispatch(setSkusStatus('error'));
          dispatch(statusSkusModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast('Falha ao se comunicar com o servidor, verifique a conexão!');
    }
  }
};

export const deleteSkus = (payload) => async (dispatch) => {
  const { ids } = payload;
  dispatch(startLoading());
  try {
    await axios.delete(`${baseURL}/stock-keeping-units`, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_API_KEY,
        authorization: `Bearer ${getAccessToken()}`,
      },
      data: {
        ids,
      },
    });
    dispatch(stopLoading());

    const msg = { title: 'Skus deletados com sucesso!' };

    dispatch(setSkusMsg(msg));
    dispatch(setSkusStatus('success'));
    dispatch(statusSkusModal(true));
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Ops!',
            text: 'Não foi possível deletar esses skus, verifique as informações.',
          };
          dispatch(setSkusMsg(msg));
          dispatch(setSkusStatus('error'));
          dispatch(statusSkusModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast('Falha ao se comunicar com o servidor, verifique a conexão!');
    }
  }
};

export const getSkuDetail = (payload) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await api.get(`${SKUS}/${payload}`);

    dispatch(getSkuSuccess(response.data));
    dispatch(stopLoading());
  } catch (error) {
    errorToast(error);
    dispatch(stopLoading());
  }
};

export const removeSku = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { id, page, searchTerm, productId } = payload;
  try {
    await api.delete(`${SKUS}/${id}`);
    const msg = {
      title: 'SKU removido com sucesso!',
    };
    dispatch(setSkusMsg(msg));

    dispatch(dismissSkuModalDelete());
    dispatch(stopLoading());
    dispatch(setSkusStatus('success'));
    dispatch(statusSkusModal(true));
    dispatch(getListSkus({ currentPage: page, searchTerm, productId }));
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    dispatch(dismissSkuModalDelete());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Ops!',
            text: 'Não foi possível remover esse SKU, verifique as informações.',
          };
          dispatch(statusSkusModal(true));
          dispatch(setSkusStatus('error'));
          dispatch(setSkusMsg(msg));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};
