import React from 'react';
import PropTypes from 'prop-types';
import NotFound from 'assets/img/Tinta-Caida.svg';
import {
  Container,
  Title,
  ContainerImage,
  ContainerText,
  Subitle,
} from './EmptySearch.style';

const EmptySearch = ({ term, children, items }) => {
  const show = items !== undefined;

  if (term === '' && items.length === 0) {
    return (
      <Container>
        <ContainerImage>
          <img src={NotFound} alt="" />
        </ContainerImage>
        <ContainerText>
          <Title>Ops</Title>
          <Subitle>
            <b>Não foram encontrados registros!</b>
          </Subitle>
        </ContainerText>
      </Container>
    );
  }

  return (
    <>
      {show && term !== '' && items.length === 0 ? (
        <Container>
          <ContainerImage>
            <img src={NotFound} alt="" />
          </ContainerImage>
          <ContainerText>
            <Title>Ops</Title>
            <Subitle>
              <b>Nenhuma resposta encontrada para:</b>
              <br />
              <span>{`"${term}"`}</span>
            </Subitle>
          </ContainerText>
        </Container>
      ) : (
        children
      )}
    </>
  );
};

EmptySearch.propTypes = {
  term: PropTypes.string,
  children: PropTypes.node.isRequired,
  items: PropTypes.array,
};

EmptySearch.defaultProps = {
  term: '',
  items: [],
};

export default EmptySearch;
