import styled, { css } from 'styled-components';

export const Content = styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: ${theme.colors.secondary.default};
    padding: 8px;
    font-size: 0.85rem;
    line-height: 16px;
    outline: 0;
    white-space: nowrap;
    text-decoration: none;
    width: ${({ width }) => (width ? `${width}` : '48px')};
    height: ${({ height }) => (height ? `${height}` : '48px')};
    font-family: ${theme.typography.family.primary};
    font-weight: ${({ fontWeight }) =>
      fontWeight ? `${fontWeight}` : 'normal'};
    text-transform: uppercase;
    border-radius: ${({ borderRadius }) =>
      borderRadius ? `${borderRadius}` : '2px'};
    background: ${theme.colors.white.default};
    transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
      box-shadow 0.1s ease, background 0.1s ease;

    ${({ btnType }) =>
      btnType === 'primary' &&
      css`
        background-color: ${theme.colors.white.default};
        color: ${theme.colors.secondary.default};
        border: 1px solid ${theme.colors.secondary.default};

        &:hover {
          background-color: ${theme.colors.primary.default};
          color: ${theme.colors.white.default};
          border: none;

           svg > path {
            stroke:${theme.colors.white.default};
        }

        &:active {
          background-color: ${theme.colors.primary.active};
          color: ${theme.colors.white.default};
          border: none;

           svg > path {
            stroke:${theme.colors.white.default};
          }
        }
      `};

    ${({ btnType }) =>
      btnType === 'secondary' &&
      css`
        background-color: ${theme.colors.white.default};
        color: ${theme.colors.secondary.default};
        border: 1px solid ${theme.colors.secondary.default};

        svg > path {
          stroke: ${theme.colors.secondary.default};
        }

        &:hover {
          background-color: ${theme.colors.contextual.error.active};
          color: ${theme.colors.white.default};
          border: none;

          svg > path {
            stroke: ${theme.colors.white.default};
          }
        }
      `};

    ${({ btnType }) =>
      btnType === 'tertiary' &&
      css`
        background-color: ${theme.colors.background.light.button};
        color: ${theme.colors.secondary.default};
        border: none;

        svg {
          display: flex;
          width: 16px;
          height: 16px;
        }

        &:hover {
          background-color: ${theme.colors.background.light.button};
          color: ${theme.colors.secondary.default};
          border: none;
        }
      `};

    ${({ btnType }) =>
      btnType === 'transparent' &&
      css`
        background-color: transparent;
        color: ${theme.colors.secondary.default};
        border: none;

        svg {
          display: flex;
          width: 16px;
          height: 16px;
        }

        &:hover {
          background-color: transparent;
          color: ${theme.colors.secondary.default};
          border: none;
        }
      `};

    ${({ disabled }) =>
      disabled &&
      css`
        cursor: not-allowed;
        background-color: ${theme.colors.white.default};
        color: ${theme.colors.background.light.button};
        border: 1px solid ${theme.colors.background.light.button};
        svg > path {
          stroke: ${theme.colors.background.light.button};
        }

        &:hover {
          background-color: ${theme.colors.white.default};
          color: ${theme.colors.background.light.button};
          border: 1px solid ${theme.colors.background.light.button};

          svg > path {
            stroke: ${theme.colors.background.light.button};
          }
        }
      `};

    ${({ active }) =>
      active &&
      css`
        background-color: ${theme.colors.primary.active};
      `};
  `}
`;
