import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './SpanError.styles';

const SpanError = ({ children }) => <Container>{children}</Container>;

SpanError.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

export default SpanError;
