/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import Grid from '@material-ui/core/Grid';
import LABColorInput from 'components/contexts/colors/LABColorInput/LABColorInput';
import RGBColorInput from 'components/contexts/colors/RGBColorInput/RGBColorInput';
import ActionButtons from 'components/form/ActionButtons';
import AsyncSelectPaginate from 'components/form/Select/AsyncSelectPaginate/AsyncSelectPaginate';
import SelectSearch from 'components/form/Select/ReactSelect';
import InputText from 'components/form/Text/TextInput/InputText';
import Toggle from 'components/form/Toggle/Toggle';
import FormContainer from 'components/structure/FormContainer/FormContainer';
import { PAGE_SAFE_COLOR_CHOICE } from 'constants/defaultValues';
import { FAMILIES } from 'constants/endpoints';
import urls from 'constants/urls';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import arrayToString from 'utils/arrayToString';
import { createSlug } from 'utils/createSlug';
import { getPermission } from 'utils/permissions';
import { nscMask } from 'utils/nscMask';
import { yearlyColorOptions } from './yearlyColorOptions';
import { getFamiliesOptions } from 'store/colours/colours-slice';

const ColorsView = (props) => {
  const { color, selectId } = useSelector((state) => state.colours);
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [ncs, setNCS] = useState('');
  const [page, setPage] = useState(null);
  const [position, setPosition] = useState(null);
  const [pageV4, setPageV4] = useState('');
  const [positionV4, setPositionV4] = useState('');
  const [pageV5, setPageV5] = useState('');
  const [positionV5, setPositionV5] = useState('');
  const [yearlyColor, setYearlyColor] = useState(null);
  const [stickerTest, setStickerTest] = useState(false);
  const [background, setBackground] = useState(false);
  const [groups, setGroups] = useState([]);
  const [active, setActive] = useState(false);
  const [color1, setColor1] = useState('');
  const [color2, setColor2] = useState('');
  const [color3, setColor3] = useState('');
  const [labColor1, setLabColor1] = useState('');
  const [labColor2, setLabColor2] = useState('');
  const [labColor3, setLabColor3] = useState('');

  const tempColorRgb = [color1, color2, color3];
  const colorRgb = arrayToString(tempColorRgb);

  const tempColorLab = [labColor1, labColor2, labColor3];
  const colorLab = arrayToString(tempColorLab);

  const history = useHistory();
  const dispatch = useDispatch();

  const { permissions } = useSelector((state) => state.auth);

  useEffect(() => {
    if (getPermission('colors', permissions, 'access') === false) {
      history.push('/');
    }
  }, [history, permissions]);

  useEffect(() => {
    dispatch(getFamiliesOptions());
  }, [dispatch]);

  useEffect(() => {
    const separatorColorStringRgb = color?.rgb?.split(',', 3);
    const separatorColorStringLab = color?.lab?.split(',', 3);

    setName(color?.name);
    setCode(color?.code);
    setPage(color?.page === PAGE_SAFE_COLOR_CHOICE ? '' : color?.page);
    setPosition(color?.position);
    setYearlyColor(color?.yearlyColor);
    setStickerTest(color?.stickerTest);
    setBackground(color?.background);
    setGroups(
      color?.groups?.map((option) => ({
        id: option.id,
        name: option.name,
      })),
    );

    const infosV4 = color?.fans?.[0];
    const infosV5 = color?.fans?.[1];

    if (infosV4) {
      setPageV4(infosV4?.page);
      setPositionV4(infosV4?.position);
    }

    if (infosV5) {
      setPageV5(infosV5?.page);
      setPositionV5(infosV5?.position);
    }

    setNCS(color?.ncs);
    setActive(color?.active);
    setColor1(separatorColorStringRgb?.[0]);
    setColor2(separatorColorStringRgb?.[1]);
    setColor3(separatorColorStringRgb?.[2]);
    setLabColor1(separatorColorStringLab?.[0]);
    setLabColor2(separatorColorStringLab?.[1]);
    setLabColor3(separatorColorStringLab?.[2]);
  }, [color]);

  const cancelColor = () => {
    history.goBack();
  };

  return (
    <>
      <FormContainer title={`Cor - ${name}`}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item md={12}>
                <InputText
                  placeholder="Nome"
                  label="Nome"
                  value={name}
                  handleChange={setName}
                  disabled
                />
              </Grid>
              <Grid item md={12}>
                <InputText
                  placeholder="Código"
                  label="Código"
                  value={code}
                  handleChange={setCode}
                  disabled
                />
              </Grid>
              <Grid item md={12}>
                <InputText
                  placeholder="Título-exemplo"
                  label="Slug"
                  disabled
                  value={createSlug(`${name}-${code}`)}
                />
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid item md={12}>
                      <InputText
                        label="Página do leque 1700"
                        placeholder="0"
                        value={pageV4}
                        handleChange={setPageV4}
                        disabled
                      />
                    </Grid>
                    <Grid item md={12}>
                      <InputText
                        label="Linha do leque 1700"
                        placeholder="0"
                        value={positionV4}
                        handleChange={setPositionV4}
                        hasTooltip
                        textTooltip="Posição do leque"
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid item md={12}>
                      <InputText
                        label="Página do leque 500"
                        placeholder="0"
                        value={pageV5}
                        handleChange={setPageV5}
                        disabled
                      />
                    </Grid>
                    <Grid item md={12}>
                      <InputText
                        label="Linha do leque 500"
                        placeholder="0"
                        value={positionV5}
                        handleChange={setPositionV5}
                        hasTooltip
                        textTooltip="Posição do leque"
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <SelectSearch
                  label="Cor do Ano"
                  placeholder="Selecione"
                  options={yearlyColorOptions}
                  defaultOption={yearlyColor}
                  handleChange={setYearlyColor}
                  optional
                  disabled
                />
              </Grid>
              <Grid item md={12}>
                <Toggle
                  label="Teste sua cor adesivo"
                  checked={stickerTest}
                  onChange={(e) => setStickerTest(e.target.checked)}
                  regular
                  disabled
                />
              </Grid>
              <Grid item md={12}>
                <Toggle
                  label="Fundo para Cores Especiais"
                  checked={background}
                  onChange={(e) => setBackground(e.target.checked)}
                  regular
                  disabled
                />
              </Grid>
              <Grid item md={12}>
                <RGBColorInput
                  color1={color1}
                  color2={color2}
                  color3={color3}
                  setColor1={(e) => setColor1(e)}
                  setColor2={(e) => setColor2(e)}
                  setColor3={(e) => setColor3(e)}
                  colorRgbString={colorRgb}
                  disabled
                />
              </Grid>
              <Grid item md={12}>
                <LABColorInput
                  labColor1={labColor1}
                  labColor2={labColor2}
                  labColor3={labColor3}
                  setLabColor1={setLabColor1}
                  setLabColor2={setLabColor2}
                  setLabColor3={setLabColor3}
                  colorLabString={colorLab}
                  disabled
                />
              </Grid>
              <Grid item md={12}>
                <InputText
                  placeholder="NCS"
                  label="NCS"
                  value={ncs}
                  handleChange={(value) => setNCS(nscMask(value))}
                  disabled
                />
              </Grid>
              <Grid item md={12}>
                <Toggle
                  label="Ativo"
                  checked={active}
                  onChange={(e) => setActive(e.target.checked)}
                  regular
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ActionButtons
          type={props.location.state === 'deleted' ? 'none' : 'view'}
          cancelPath={() => {
            cancelColor();
          }}
          submitFunc={() => {
            history.push(urls.ROUTES.COLORS_EDIT.path);
          }}
        />
      </FormContainer>
    </>
  );
};

export default ColorsView;
