import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    > img {
      width: 20%;
      height: 28%;
      padding-top: -34px;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      width: 100%;
    }
  `}
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    font-family: ${theme.typography.family.secondary};
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    width: 100%;
    padding: 20px 0;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      width: 80%;
    }
  `}
`;

export const ButtonsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 80%;

    button:first-child {
      margin-right: 12px;

      @media only screen and (max-width: ${theme.breakpoints.md}) {
        margin-bottom: 12px;
      }
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
    }
  `}
`;
