/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import Tooltip from 'assets/icons/tooltip-icon.svg';
import { ReactComponent as TrashPropertyIcon } from 'assets/icons/trashProperty.svg';
import { Color } from 'components/contexts/colors/RGBColorInput/RGBColorInput.styles';
import SpanError from 'components/structure/SpanError/SpanError';
import TooltipComponent from 'components/structure/Tooltip/Tooltip';
import { COLORS } from 'constants/endpoints';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { components } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import api from 'services/api';
import rgbToHex from 'utils/rgbToHex';

import {
  Container,
  custom,
  Label,
  LabelContainer,
  OptionalStyle,
  PaginatedSelect,
  SelectPreviewContainer,
  selectStyle,
} from './AsyncSelectPaginate.styles';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaChevronDown />
    </components.DropdownIndicator>
  );
};

const AsyncSelectPaginateRgb = (props) => {
  const [currentOptions, setCurrentOptions] = useState({
    page: 1,
    searchQuery: null,
  });
  const [platforms, setPlatforms] = useState('');
  useEffect(() => {
    setPlatforms(props.platforms);
  }, [props.platforms]);

  const loadOptionsData = async (searchQuery, loadedOptions, { page }) => {
    const { data } = await api.get(
      searchQuery === ''
        ? `${COLORS}?perPage=10&page=${page}&sort.field=name&sort.order=ASC`
        : `${COLORS}?perPage=10&page=${page}&sort.field=name&sort.order=ASC&name=${searchQuery}`,
    );

    setCurrentOptions({ page, searchQuery });

    return {
      options: data.items !== undefined ? data.items : [],
      hasMore: data.totalPage !== undefined ? data.totalPage > page + 1 : 0,
      additional: {
        page: searchQuery === currentOptions.searchQuery ? page + 1 : page + 1,
      },
    };
  };

  const onChange = (option) => {
    if (typeof props.onChange === 'function') {
      props.onChange(option);
    }
  };

  return (
    <Container isError={props.isError}>
      <LabelContainer>
        <Label isError={props.isError}>{props.label}</Label>

        <TooltipComponent id="tooltipImage" title={props.title} multiline>
          {props.optional && <OptionalStyle>(Opcional)</OptionalStyle>}
          {props.tooltip && <img src={Tooltip} alt="" />}
        </TooltipComponent>
      </LabelContainer>
      <SelectPreviewContainer>
        <PaginatedSelect>
          <AsyncPaginate
            key={JSON.stringify(platforms)}
            name={props.name}
            placeholder={props.disabled ? props.value.name : props.placeholder}
            value={props.disabled ? props.value.name : props.value || ''}
            loadOptions={loadOptionsData}
            getOptionValue={(option) => option.name}
            getOptionLabel={(option) => option.name}
            onChange={onChange}
            isSearchable
            additional={{
              page: 1,
            }}
            isDisabled={props.disabled}
            styles={selectStyle}
            components={{ IndicatorSeparator: null, DropdownIndicator }}
            theme={custom}
            noOptionsMessage={({ inputValue }) =>
              !inputValue ? '' : 'Item não encontrado'
            }
            loadingMessage={() => 'Carregando...'}
          />
        </PaginatedSelect>
        {props.isError && <SpanError>{props.error}</SpanError>}
        {props.hookForm && props.isError && (
          <SpanError>{props.error.message}</SpanError>
        )}
        {props.rgbPreview && (
          <Color
            rgbCode={rgbToHex(
              props.value?.rgb !== undefined ? props.value?.rgb : '255,255,255',
            )}
          />
        )}
        {props.isDeletable && (
          <TrashPropertyIcon className="trash" onClick={props.deleteAction} />
        )}
      </SelectPreviewContainer>
    </Container>
  );
};

AsyncSelectPaginateRgb.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  optional: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  tooltip: PropTypes.bool,
  value: PropTypes.object,
  platforms: PropTypes.string.isRequired,
  error: PropTypes.string,
  isError: PropTypes.bool,
  hookForm: PropTypes.bool,
  rgbPreview: PropTypes.bool,
  isDeletable: PropTypes.bool,
  deleteAction: PropTypes.func,
};

AsyncSelectPaginateRgb.defaultProps = {
  name: '',
  label: '',
  placeholder: '',
  onChange: () => {},
  optional: false,
  disabled: false,
  title: '',
  tooltip: false,
  value: {},
  error: '',
  isError: false,
  hookForm: false,
  rgbPreview: false,
  isDeletable: true,
  deleteAction: () => {},
};

export default AsyncSelectPaginateRgb;
