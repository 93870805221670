import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 28vw;

    text-align: center;
    padding: 0 10px;
    z-index: 10;
    > img {
      width: 20%;
      height: 20%;
      padding-bottom: 24px;
    }

    > h3 {
      font-family: ${theme.typography.family.secondary};
      font-size: 1.2rem;
      line-height: 38px;
      font-weight: 700;
      color: ${theme.colors.tertiary.default};
      padding-bottom: 14px;
    }

    > p {
      padding-bottom: 24px;
      font-family: ${theme.typography.family.primary};
      color: ${theme.colors.tertiary.default};
      font-size: 1rem;
      line-height: 24px;
      font-weight: 500;
      width: 90%;
    }
  `}
`;

export const PermissionContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    padding-bottom: 10px;
    border-bottom: 1px solid ${theme.colors.gray.light};
  `}
`;

export const Text = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.typography.family.primary};
    font-size: 1rem;
    font-weight: 700;
    color: ${theme.colors.tertiary.default};
  `}
`;
