import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    > p {
      font-size: ${theme.typography.sizes.sm};
      line-height: 21px;
      padding: 8px 0px 12px;
      font-family: ${theme.typography.family.primary};
      font-weight: 400;
      color: ${theme.colors.tertiary.default};
      text-align: center;

      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        padding: 8px 16px 12px;
      }
    }
  `}
`;
