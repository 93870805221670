import axios from 'axios';
import { isAuthenticated, getAccessToken } from 'lib/contexts/auth';
import { baseURL } from 'constants/endpoints';

const authHeader = {
  'Content-Type': 'multipart/form-data',
  'x-api-key': process.env.REACT_APP_API_KEY,
  token: getAccessToken(),
};

const multiForm = axios.create({
  baseURL,
  headers: authHeader,
});

multiForm.interceptors.request.use(async (initialConfig) => {
  const config = initialConfig;
  if (isAuthenticated()) {
    config.headers.Authorization = `Bearer ${getAccessToken()}`;
  }
  return config;
});

export default multiForm;
