const arrayToString = (array) => {
  let string = '';
  if (array.length === 0) {
    return '';
  }

  for (let i = 0; array.length > i; i += 1) {
    string += array[i];
    if (i < array.length - 1) {
      string += ', ';
    }
  }

  return string;
};

export default arrayToString;
