export const platforms = [
  { value: 'app', label: 'App' },
  { value: 'ecommerce', label: 'Ecommerce' },
  { value: 'institucional', label: 'Suvinil' },
  { value: 'glasu', label: 'Glasu' },
];

export const systemColors = [
  { value: 'selfcolor', label: 'SelfColor' },
  { value: 'readymix', label: 'Readymix' },
  { value: 'nocolor', label: 'NoColor' },
];
