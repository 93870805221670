/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import Tooltip from 'assets/icons/tooltip-icon.svg';
import SpanError from 'components/structure/SpanError/SpanError';
import TooltipComponent from 'components/structure/Tooltip/Tooltip';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { components } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import api from 'services/api';

import {
  Container,
  custom,
  Label,
  LabelContainer,
  OptionalStyle,
  selectStyle,
} from './AsyncSelectPaginate.styles';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaChevronDown />
    </components.DropdownIndicator>
  );
};

const AsyncSelectPaginate = ({
  name,
  label,
  placeholder,
  handleChange,
  department,
  multi,
  optional,
  disabled,
  clearable,
  title,
  tooltip,
  value,
  url,
  perPage,
  platforms,
  hasPlatforms,
  isError,
  error,
  productId,
  zIndex,
  slug,
  physicalQuantity,
  ...rest
}) => {
  const [currentOptions, setCurrentOptions] = useState({
    page: 1,
    searchQuery: null,
  });

  const loadOptionsData = async (searchQuery, loadedOptions, { page }) => {
    const queryParameters = `physicalQuantity=${physicalQuantity}&perPage=${perPage}&page=${page}&sort.field=name&sort.order=ASC`;

    let endpoint = '';

    if (department) {
      endpoint = `${url}&${queryParameters}`;
    } else if (hasPlatforms) {
      const platformQuery =
        searchQuery === ''
          ? `&platforms=[${platforms}]`
          : `&platforms=[${platforms}]&term=${searchQuery}`;
      endpoint = `${url}?${queryParameters}${platformQuery}`;
    } else {
      const nameQuery = searchQuery === '' ? '' : `&term=${searchQuery}`;
      const productIdQuery = productId === '' ? '' : `&productId=${productId}`;
      const slugQuery = slug === '' ? '' : `&slug=${slug}`;
      endpoint = `${url}?${queryParameters}${nameQuery}${productIdQuery}${slugQuery}`;
    }

    const { data } = await api.get(endpoint);

    setCurrentOptions({ page, searchQuery });
    const options = data.items !== undefined ? data.items : [];
    if (optional) {
      options.unshift({ id: null, name: 'Nenhum' });
    }
    return {
      options,
      hasMore: data.totalPage !== undefined ? data.totalPage > page : 0,
      additional: {
        page: searchQuery === currentOptions.searchQuery ? page + 1 : page + 1,
      },
    };
  };
  return (
    <Container zIndex={zIndex} isError={isError}>
      <LabelContainer>
        <Label isError={isError}>{label}</Label>

        <TooltipComponent id="tooltipImage" title={title} multiline>
          {optional && <OptionalStyle>(Opcional)</OptionalStyle>}
          {tooltip && <img src={Tooltip} alt="" />}
        </TooltipComponent>
      </LabelContainer>
      {multi ? (
        <AsyncPaginate
          name={name}
          placeholder={placeholder}
          value={value}
          loadOptions={loadOptionsData}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.name}
          onChange={handleChange}
          isSearchable
          additional={{
            page: 1,
          }}
          isMulti
          isDisabled={disabled}
          styles={selectStyle}
          components={{ IndicatorSeparator: null, DropdownIndicator }}
          theme={custom}
          noOptionsMessage={({ inputValue }) =>
            !inputValue ? '' : 'Item não encontrado'
          }
          loadingMessage={() => 'Carregando...'}
          {...rest}
        />
      ) : (
        <AsyncPaginate
          name={name}
          placeholder={placeholder}
          value={value}
          loadOptions={loadOptionsData}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => {
            return option.material?.name
              ? `${option.material?.name} - ${option.name} ${option.unitAlias}`
              : option.code
              ? `${option.code} ${option.name}`
              : option.name;
          }}
          onChange={(option) =>
            !option?.id ? handleChange('') : handleChange(option)
          }
          isSearchable
          additional={{
            page: 1,
          }}
          isDisabled={disabled}
          isClearable={clearable}
          styles={selectStyle}
          components={{ IndicatorSeparator: null, DropdownIndicator }}
          theme={custom}
          noOptionsMessage={({ inputValue }) =>
            !inputValue ? '' : 'Item não encontrado'
          }
          loadingMessage={() => 'Carregando...'}
          {...rest}
        />
      )}
      {isError && <SpanError>{error}</SpanError>}
    </Container>
  );
};

AsyncSelectPaginate.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  multi: PropTypes.bool,
  optional: PropTypes.bool,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  title: PropTypes.string,
  tooltip: PropTypes.bool,
  value: PropTypes.string || PropTypes.number,
  url: PropTypes.string,
  perPage: PropTypes.number,
  platforms: PropTypes.string,
  hasPlatforms: PropTypes.bool,
  error: PropTypes.object,
  isError: PropTypes.bool,
  department: PropTypes.bool,
  productId: PropTypes.string,
  zIndex: PropTypes.string,
  slug: PropTypes.string,
  physicalQuantity: PropTypes.string,
};

AsyncSelectPaginate.defaultProps = {
  name: '',
  label: '',
  placeholder: '',
  multi: false,
  handleChange: () => {},
  optional: false,
  disabled: false,
  clearable: false,
  title: '',
  tooltip: false,
  value: '' || null,
  url: '',
  perPage: null,
  platforms: '',
  hasPlatforms: false,
  error: {},
  isError: false,
  department: false,
  productId: '',
  zIndex: '1',
  slug: '',
  physicalQuantity: '',
};

export default AsyncSelectPaginate;
