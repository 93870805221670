import React from 'react';
import PropTypes from 'prop-types';
import { Container, Content } from './MainContainer.styles';

const MainContainer = ({ children }) => {
  return (
    <Container>
      <Content>{children}</Content>
    </Container>
  );
};

MainContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainContainer;
