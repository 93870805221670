import React from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import theme from 'styles/theme';
import { Container, BlurBackground } from './Loader.styles';

const Loader = ({ loading, loadingText }) => (
  <Container loading={loading}>
    <BlurBackground />
    <ReactLoading
      type="spin"
      color={theme.colors.primary.default}
      height={60}
      width={60}
      className="loader-spin"
    />
    <h3 className="loader-text">{loadingText}</h3>
  </Container>
);

Loader.propTypes = {
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
};

Loader.defaultProps = {
  loading: false,
  loadingText: '',
};

export default Loader;
