import styled, { css } from 'styled-components';

export const AlertContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;

    background-color: ${theme.colors.primary.lighter};
    border-left: 4px solid ${theme.colors.primary.default};
    border-radius: 4px;
    padding: 10px 5px 10px 13px;
    height: 100%;
  `}
`;

export const AlertText = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.secondary.default};
    font-size: 14px;
    font-family: ${theme.typography.family.primary};
    line-height: 18px;
  `}
`;

export const AlertTitleContainer = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.primary.default};
    display: flex;
    width: 100%;
    padding-bottom: 5px;
    h3 {
      font-size: 18px;
      font-weight: 700;
      font-family: ${theme.typography.family.secondary};
    }

    img {
      padding-right: 5px;
    }
  `}
`;
