import PageContent from 'components/structure/PageContent/PageContent';
import PageHeader from 'components/structure/PageHeader/PageHeader';
import PopOver from 'components/structure/PopOver/PopOver';
import TableDefault from 'components/structure/Table/Table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import ModalDeleteConfirmation from 'components/contexts/modal/ModalDeleteConfirmation/ModalDeleteConfirmation';
import ModalMultiConfirmation from 'components/contexts/modal/ModalMultiConfirmation/ModalMultiConfirmation';
import EmptySearch from 'components/structure/EmptySearch/EmptySearch';
import ReportModal from 'components/structure/ReportModal';
import {
  Divider,
  PopItem,
  PopOverContent,
} from 'components/structure/PopOver/PopOver.styles';
import urls from 'constants/urls';
import { useDispatch, useSelector } from 'react-redux';

import { getPermission } from 'utils/permissions';
import {
  dismissModalDelete,
  removeProducts,
  showModalDelete,
  statusProductsModal,
} from 'store/products/products-slice';
import { setProductSku } from 'store/sku/sku-slice';

// *************************************************************************************************
import { dryPackageList } from 'store/dryPackages/dryPackages-slice';

// *************************************************************************************************

export const DryPackageList = () => {
  const { permissions } = useSelector((state) => state.auth);

  const { items, totalPage } = useSelector((state) => state.dryPackage.list);

  const { openDeleteModal, openStatusModal, msg, status } = useSelector(
    (state) => state.products,
  );

  const [isOpenReportModal, setIsOpenReportModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedId, setSelectedId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSlug, setSelectedSlug] = useState(null);
  const [ordenation, setOrdenation] = useState('');
  const [totalPerPage, setTotalPerPage] = useState({ value: 10 });
  const perPage = totalPerPage.value;

  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  useEffect(() => {
    dispatch(dryPackageList({ currentPage, searchTerm, perPage, ordenation }));
  }, [dispatch, currentPage, searchTerm, perPage, ordenation]);

  const redirectTo = useCallback(
    (flag, id = null) => {
      const redirectMap = {
        create: urls.ROUTES.DRY_PACKAGE_CREATE.path,
        edit: `${urls.ROUTES.DRY_PACKAGE_EDIT.path}/${id}`,
        view: `${urls.ROUTES.DRY_PACKAGE_VIEW.path}/${id}`,
      };
      history.push(redirectMap[flag]);
    },
    [history],
  );

  const COLUMNS = useMemo(
    () => [
      { Header: 'ID', accessor: 'id', sticky: 'left' },
      {
        Header: 'Nome',
        accessor: ({ name, unitAlias }) => `${name} ${unitAlias}`,
        sticky: 'left',
      },
      { Header: 'Tipo', accessor: 'material.name', sticky: 'left' },
      {
        Header: ' ',
        accessor: ({ id, slug, name }) =>
          getPermission('products', permissions, 'update') === false ? (
            <></>
          ) : (
            <PopOver
              content={
                <PopOverContent>
                  <PopItem
                    onClick={() => {
                      redirectTo('edit', id);
                    }}
                  >
                    {intl.messages['buttons.edit']}
                  </PopItem>
                  {getPermission('products', permissions, 'access') && (
                    <>
                      <Divider />
                      <PopItem
                        onClick={() => {
                          redirectTo('view', id);
                        }}
                      >
                        Ver detalhes
                      </PopItem>
                    </>
                  )}
                </PopOverContent>
              }
            />
          ),
        sticky: 'right',
      },
    ],
    [currentPage, redirectTo, intl.messages, permissions],
  );
  const data = useMemo(() => items, [items]);

  const optionsOrdenation = [
    { value: 'id', label: 'ID' },
    { value: 'name', label: 'Nome' },
  ];

  return (
    <>
      <ModalDeleteConfirmation
        title={intl.messages['textModal.areYouSure']}
        text={intl.messages['textModal.colorProductConfirm']}
        placeholder={intl.messages['labelsInputs.enterSlug']}
        buttonName={intl.messages['buttons.delete']}
        setIsOpen={() => dispatch(dismissModalDelete())}
        isOpen={openDeleteModal}
        fieldValue={selectedSlug}
        fieldLabel={selectedSlug}
        confirmOnClick={() =>
          dispatch(
            removeProducts({ id: selectedId, page: currentPage, searchTerm }),
          )
        }
        textFieldLabel={intl.messages['labelsInputs.enterSlug']}
      />
      <ModalMultiConfirmation
        title={msg.title}
        text={msg.text}
        setIsOpen={(payload) => {
          dispatch(statusProductsModal(payload));
        }}
        isOpen={openStatusModal}
        status={status}
      />
      <ReportModal
        isOpen={isOpenReportModal}
        setIsOpen={setIsOpenReportModal}
        title="Período do relatório de produtos"
        entity="products"
      />
      <PageHeader
        title="Embalagens"
        actions={
          !getPermission('products', permissions, 'create')
            ? []
            : [
                {
                  name: 'NOVA EMBALAGEM',
                  onClick: () => redirectTo('create'),
                  btnType: 'primary',
                },
              ]
        }
        search
        searchFunc={(searchData) => setSearchTerm(searchData)}
        placeholder="Busque por Nome"
        optionsOrdenation={optionsOrdenation}
        ordenation={ordenation}
        setOrdenation={setOrdenation}
      />
      <PageContent>
        <EmptySearch items={data} term={searchTerm}>
          <TableDefault
            columns={COLUMNS}
            data={data}
            pageCount={totalPage}
            setPageCurrent={setCurrentPage}
            defaultCurrentPage={currentPage}
            totalPerPage={totalPerPage}
            setTotalPerPage={setTotalPerPage}
          />
        </EmptySearch>
      </PageContent>
    </>
  );
};
