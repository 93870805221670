import PageContent from 'components/structure/PageContent/PageContent';
import PageHeader from 'components/structure/PageHeader/PageHeader';
import PopOver from 'components/structure/PopOver/PopOver';
import TableDefault from 'components/structure/Table/Table';
import Toggle from 'components/structure/ToggleTable/TogleTable';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import ModalDeleteConfirmation from 'components/contexts/modal/ModalDeleteConfirmation/ModalDeleteConfirmation';
import ModalMultiConfirmation from 'components/contexts/modal/ModalMultiConfirmation/ModalMultiConfirmation';
import EmptySearch from 'components/structure/EmptySearch/EmptySearch';
import ReportModal from "components/structure/ReportModal";
import {
  Divider,
  PopItem,
  PopOverContent,
} from 'components/structure/PopOver/PopOver.styles';
import urls from 'constants/urls';
import { useDispatch, useSelector } from 'react-redux';

import arrayToString from 'utils/arrayToString';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { getPermission } from 'utils/permissions';
import { Centered } from './Products.styles';
import {
  dismissModalDelete,
  getProductDetail,
  getProductsList,
  putProduct,
  removeProducts,
  setProductActiveStep,
  setProductId,
  showModalDelete,
  statusProductsModal,
} from 'store/products/products-slice';
import { setProductSku } from 'store/sku/sku-slice';

const Bases = () => {
  const { permissions } = useSelector((state) => state.auth);

  const { items, totalPage } = useSelector((state) => state.products.products);

  const { openDeleteModal, openStatusModal, msg, status } = useSelector(
    (state) => state.products,
  );

  const [isOpenReportModal, setIsOpenReportModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedId, setSelectedId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSlug, setSelectedSlug] = useState(null);
  const [ordenation, setOrdenation] = useState('');
  const [totalPerPage, setTotalPerPage] = useState({ value: 10 });
  const perPage = totalPerPage.value;

  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  const findCategory = (level, categoriesArray) => {
    const founded = categoriesArray.find((category) => category.type === level);
    if (founded) {
      return founded.name;
    }
    return <Centered>-</Centered>;
  };

  useEffect(() => {
    dispatch(getProductsList({ currentPage, searchTerm, perPage, ordenation }));
  }, [dispatch, currentPage, searchTerm, perPage, ordenation]);

  const addProduct = () => {
    history.push(urls.ROUTES.PRODUCTS_CREATE.path);
    dispatch(setProductActiveStep(0));
  };

  const editProduct = useCallback(
    (id) => {
      setSelectedId(id);
      dispatch(setProductId(id));
      history.push(urls.ROUTES.PRODUCTS_EDIT.path);
      dispatch(setProductActiveStep(0));
    },
    [dispatch, history],
  );

  const viewProduct = useCallback(
    (id) => {
      dispatch(getProductDetail(parseInt(id, 10)));
      setSelectedId(id);
      dispatch(setProductId(id));
      history.push({ pathname: urls.ROUTES.PRODUCTS_VIEW.path, state: 'view' });
      dispatch(setProductActiveStep(0));
    },
    [dispatch, history],
  );

  const removeBase = useCallback(
    (id, slug) => {
      setSelectedId(id);
      setSelectedSlug(slug);
      dispatch(showModalDelete());
    },
    [dispatch],
  );

  const viewActivities = useCallback(
    (id) => {
      history.push(
        `${urls.ROUTES.ACCESS.items.logsByRecord.path}/${id}/products`,
      );
    },
    [history],
  );

  const openSKU = useCallback(
    (id, name) => {
      dispatch(setProductSku({ id, name }));
      history.push(urls.ROUTES.SKU_LIST.path);
    },
    [dispatch, history],
  );

  const COLUMNS = useMemo(
    () => [
      { Header: 'ID', accessor: 'id', sticky: 'left' },
      {
        Header: 'Nome',
        accessor: 'name',
        sticky: 'left',
      },
      {
        Header: 'Plataforma',
        accessor: ({ platforms }) =>
          capitalizeFirstLetter(arrayToString(platforms)),
      },
      {
        Header: 'Departamento',
        accessor: ({ categories }) => findCategory(1, categories),
      },
      {
        Header: 'Categoria',
        accessor: ({ categories }) => findCategory(2, categories),
      },
      {
        Header: 'Subcategoria',
        accessor: ({ categories }) => findCategory(3, categories),
      },
      {
        Header: 'Ativos',
        accessor: ({ active, id }) => (
          <Toggle
            key={id}
            checked={active}
            id={id}
            setData={() => {
              const formData = new FormData();
              formData.append('active', !active);
              dispatch(
                putProduct({
                  info: formData,
                  id,
                }),
              );
            }}
            page={currentPage}
            readonly
          />
        ),
      },
      {
        Header: ' ',
        accessor: ({ id, slug, name }) =>
          getPermission('products', permissions, 'update') === false ? (
            <></>
          ) : (
            <PopOver
              content={
                <PopOverContent>
                  <PopItem onClick={() => editProduct(id)}>
                    {intl.messages['buttons.edit']}
                  </PopItem>
                  {getPermission('bases', permissions, 'delete') && (
                    <>
                      <Divider />
                      <PopItem onClick={() => removeBase(id, slug)}>
                        {intl.messages['buttons.exclusion']}
                      </PopItem>
                    </>
                  )}
                  {getPermission(
                    'stock-keeping-units',
                    permissions,
                    'list',
                  ) && (
                      <>
                        <Divider />
                        <PopItem onClick={() => openSKU(id, name)}>
                          Ver SKUs
                        </PopItem>
                      </>
                    )}
                  {getPermission('products', permissions, 'access') && (
                    <>
                      <Divider />
                      <PopItem onClick={() => viewProduct(id)}>
                        Ver detalhes
                      </PopItem>
                    </>
                  )}

                  <Divider />
                  <PopItem onClick={() => viewActivities(id)}>
                    Ver atividades
                  </PopItem>
                </PopOverContent>
              }
            />
          ),
        sticky: 'right',
      },
    ],
    [currentPage, editProduct, intl.messages, openSKU, permissions, removeBase],
  );
  const data = useMemo(() => items, [items]);

  const optionsOrdenation = [
    { value: 'id', label: 'ID' },
    { value: 'name', label: 'Nome' },
    { value: 'platforms', label: 'Plataforma' },
  ];

  return (
    <>
      <ModalDeleteConfirmation
        title={intl.messages['textModal.areYouSure']}
        text={intl.messages['textModal.colorProductConfirm']}
        placeholder={intl.messages['labelsInputs.enterSlug']}
        buttonName={intl.messages['buttons.delete']}
        setIsOpen={() => dispatch(dismissModalDelete())}
        isOpen={openDeleteModal}
        fieldValue={selectedSlug}
        fieldLabel={selectedSlug}
        confirmOnClick={() =>
          dispatch(
            removeProducts({ id: selectedId, page: currentPage, searchTerm }),
          )
        }
        textFieldLabel={intl.messages['labelsInputs.enterSlug']}
      />
      <ModalMultiConfirmation
        title={msg.title}
        text={msg.text}
        setIsOpen={(payload) => {
          dispatch(statusProductsModal(payload));
        }}
        isOpen={openStatusModal}
        status={status}
      />
      <ReportModal
        isOpen={isOpenReportModal}
        setIsOpen={setIsOpenReportModal}
        title="Período do relatório de produtos"
        entity='products'
      />
      <PageHeader
        title="Produtos"
        actions={!getPermission('products', permissions, 'create') ? [] : [
          {
            name: 'RELATORIOS',
            onClick: () => {
              setIsOpenReportModal(true)
            },
            btnType: "outlined",
          },
          {
            name: 'NOVO PRODUTO',
            onClick: addProduct,
            btnType: "primary",
          },
        ]}
        search
        searchFunc={(searchData) => setSearchTerm(searchData)}
        placeholder="Busque por Nome"
        optionsOrdenation={optionsOrdenation}
        ordenation={ordenation}
        setOrdenation={setOrdenation}
        trash={getPermission('products', permissions, 'list')}
        trashRedirect={() => history.push(urls.ROUTES.PRODUCTS_DELETED.path)}
      />
      <PageContent>
        <EmptySearch items={data} term={searchTerm}>
          <TableDefault
            columns={COLUMNS}
            data={data}
            pageCount={totalPage}
            setPageCurrent={setCurrentPage}
            defaultCurrentPage={currentPage}
            totalPerPage={totalPerPage}
            setTotalPerPage={setTotalPerPage}
          />
        </EmptySearch>
      </PageContent>
    </>
  );
};

export default Bases;
