import errorIcon from 'assets/icons/ic-x-error.svg';
import Tooltip from 'assets/icons/tooltip-icon.svg';
import Button from 'components/structure/Button/Button';
import SpanError from 'components/structure/SpanError/SpanError';
import TooltipComponent from 'components/structure/Tooltip/Tooltip';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';

import {
  Container,
  InputStyle,
  Label,
  LabelContainer,
  OptionalStyle,
} from './InputPassword.styles';

const InputPassword = ({
  id,
  name,
  isError,
  error,
  children,
  disabled,
  placeholder,
  secondary,
  register,
  optional,
  hasTooltip,
  textTooltip,
  hookForm,
  value,
  handleChange,
  handleMouseLeave,
  handleOnFocus,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Container>
      <LabelContainer>
        <Label htmlFor={name} isError={isError} secondary={secondary}>
          {children}
        </Label>
        {optional && <OptionalStyle>(Opcional)</OptionalStyle>}
        {hasTooltip && (
          <TooltipComponent id="tooltipInputText" title={textTooltip}>
            <img src={Tooltip} alt="" />
          </TooltipComponent>
        )}
      </LabelContainer>
      {hookForm ? (
        <>
          <InputStyle
            type={showPassword ? 'text' : 'password'}
            id={id}
            name={name}
            isError={isError}
            placeholder={placeholder}
            disabled={disabled}
            secondary={secondary}
            ref={register}
            {...rest}
          />

          <Button
            type="button"
            className="show-password"
            width="30"
            onClick={() => setShowPassword(!showPassword)}
            style={{ right: isError ? '42px' : '14px' }}
          >
            {showPassword && <IoEyeOutline size={25} color="#D8D8D8" />}
            {!showPassword && <IoEyeOffOutline size={25} color="#D8D8D8" />}
          </Button>
        </>
      ) : (
        <>
          <InputStyle
            type={showPassword ? 'text' : 'password'}
            id={id}
            name={name}
            isError={isError}
            placeholder={placeholder}
            disabled={disabled}
            secondary={secondary}
            value={value}
            onChange={handleChange}
            onMouseLeave={handleMouseLeave}
            onFocus={handleOnFocus}
            {...rest}
          />
        </>
      )}

      {hookForm && error.password && name === 'password' && (
        <SpanError>{error.password.message}</SpanError>
      )}
      {hookForm &&
        error.passwordConfirmation &&
        name === 'passwordConfirmation' && (
          <SpanError>{error.passwordConfirmation.message}</SpanError>
        )}
      {isError && !hookForm && <SpanError>{error}</SpanError>}
      {isError && <img src={errorIcon} alt="" className="error-icon" />}
    </Container>
  );
};

InputPassword.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  isError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  error: PropTypes.any,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  secondary: PropTypes.bool,
  register: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  optional: PropTypes.bool,
  hasTooltip: PropTypes.bool,
  textTooltip: PropTypes.any,
  hookForm: PropTypes.bool,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  handleMouseLeave: PropTypes.func,
  handleOnFocus: PropTypes.func,
};

InputPassword.defaultProps = {
  id: '',
  name: '',
  error: '',
  isError: false,
  disabled: false,
  placeholder: '',
  secondary: false,
  register: undefined,
  optional: false,
  hasTooltip: false,
  textTooltip: null,
  hookForm: false,
  value: '',
  handleChange: () => {},
  handleMouseLeave: () => {},
  handleOnFocus: () => {},
};

export default InputPassword;
