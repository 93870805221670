import styled, { css } from 'styled-components';

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

export const LinkTitle = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.typography.sizes.sm};
    line-height: 16px;
    font-weight: 400;
    padding-right: 5px;
  `}
`;

export const LinkItem = styled.a`
  ${({ theme }) => css`
    text-decoration: none;
    color: ${theme.colors.black.light};
    cursor: pointer;
    img {
      width: ${theme.typography.sizes.sm};
    }
  `}
`;
