/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const useStyles = makeStyles(() => ({
  root: {
    width: '45%',
    '& .MuiStepIcon-root': {
      width: 10,
      height: 10,
      color: '#F7F7F7',
      borderRadius: '50%',
      border: '1px solid #F66602',
    },
    '& .MuiStepIcon-active': {
      color: '#F66602',
      borderRadius: '50%',
      border: '0px solid #F66602',
    },
    '& .MuiStepIcon-completed': {
      color: '#008702',
      borderRadius: '50%',
      border: '0px solid #008702',
    },
    '& .MuiStepIcon-text': {
      display: 'none',
    },
    '& .MuiStepLabel-alternativeLabel': {
      marginTop: '3.5px',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '24px',
      color: '#999999',
    },
    '& .MuiStepConnector-alternativeLabel': {
      left: 'calc(-50% + 5px)',
      right: 'calc(50% + 5px)',
    },
  },
}));

const StepProgress = ({ activeStep, steps }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

StepProgress.propTypes = {
  activeStep: PropTypes.number,
  steps: PropTypes.array,
};

StepProgress.defaultProps = {
  activeStep: null,
  steps: [{}],
};

export default StepProgress;
