/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useIntl } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import InputText from 'components/form/Text/TextInput/InputText';

import Button from 'components/structure/Button/Button';
import { useDispatch, useSelector } from 'react-redux';

import SelectSearch from 'components/form/Select/ReactSelect';
import { schema } from './EditUserModalForm.validations';
import { Container, ButtonContent } from './EditUserModalForm.styles';
import { getRolesListAll } from 'store/roles/roles-slice';
import { editUser, getUserDetail } from 'store/users/users-slice';

const EditUserModalForm = ({
  emailErrorMessage,
  values,
  searchTerm,
  currentPage,
}) => {
  const { selectedUser, user } = useSelector((state) => state.users);
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const intl = useIntl();
  const dispatch = useDispatch();

  const { options } = useSelector((state) => state.roles);
  const { role } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.loading);
  const [currentRole, setCurrentRole] = useState(user.roleId);
  const [enableButton, setEnableButton] = useState(false);

  // TODO
  const handleSubmitModalForm = (data) => {
    setEnableButton(true);
    const { name, email } = data;
    const payload = { name, email, roleId: currentRole };
    dispatch(
      editUser({
        userData: payload,
        userId: selectedUser,
        searchTerm,
        currentPage,
      }),
    );
  };

  useEffect(() => {
    dispatch(getRolesListAll());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUserDetail(selectedUser));
  }, [dispatch, selectedUser]);

  useEffect(() => {
    if (loading === 0) {
      setEnableButton(false);
    }
  }, [loading]);

  function validate(obj) {
    if (obj === null || obj === undefined) {
      return false;
    }
    return true;
  }

  return (
    <Container>
      <form onSubmit={handleSubmit(handleSubmitModalForm)}>
        <Row>
          <Col md="12">
            <InputText
              name="name"
              label="Nome"
              error={errors.name}
              isError={validate(errors.name)}
              register={register}
              defaultValue={user.name}
              hookForm
            >
              {intl.messages['labelsInputs.typeYourName']}
            </InputText>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <InputText
              name="email"
              type="email"
              label="Email"
              error={errors.email || emailErrorMessage}
              isError={validate(errors.email) || emailErrorMessage}
              register={register}
              defaultValue={user.email}
              hookForm
            >
              {intl.messages['labelsInputs.typeYourEmail']}
            </InputText>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <SelectSearch
              label="Perfil"
              options={
                role.name === 'Admin'
                  ? options
                  : options.filter((op) => op.name !== 'Admin')
              }
              defaultOption={currentRole}
              handleChange={setCurrentRole}
              multi={false}
              tooltip
              title={intl.messages['labelsInputs.tooltipUserRole']}
            />
          </Col>
        </Row>
        <Row>
          <ButtonContent>
            <Button
              type="submit"
              btnType="primary"
              width="60%"
              height="44px"
              borderRadius="8px"
              fontWeight="600"
              disabled={enableButton}
            >
              {intl.messages['buttons.toSave']}
            </Button>
          </ButtonContent>
        </Row>
      </form>
    </Container>
  );
};

EditUserModalForm.propTypes = {
  emailErrorMessage: PropTypes.string,
  values: PropTypes.object,
  currentPage: PropTypes.number.isRequired,
  searchTerm: PropTypes.string.isRequired,
};

EditUserModalForm.defaultProps = {
  emailErrorMessage: '',
  values: {},
};

export default EditUserModalForm;
