import React from 'react';
import PropTypes from 'prop-types';
import icon from 'assets/icons/Alerta.svg';
import {
  AlertContainer,
  AlertTitleContainer,
  AlertText,
} from './BoxAlert.styles';

const BoxAlert = ({ title, text }) => {
  return (
    <AlertContainer>
      <AlertTitleContainer>
        <img src={icon} alt="" />
        <h3>{title}</h3>
      </AlertTitleContainer>
      <AlertText>{text}</AlertText>
    </AlertContainer>
  );
};

BoxAlert.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

BoxAlert.defaultProps = {
  title: '',
  text: '',
};

export default BoxAlert;
