import styled from 'styled-components';

export const TooltipCard = styled.div`
  .__react_component_tooltip {
    background: #04456b;
    font-size: 14px;
    line-height: 24px;
  }

  .__react_component_tooltip .multi-line {
    text-align: center;
  }

  .__react_component_tooltip::before,
  .__react_component_tooltip::after {
    left: 43% !important;
    bottom: -10px !important;
    border-top-width: 15px !important;
  }
`;
export const TooltipContent = styled.div`
  display: flex;
  align-items: center;
`;
