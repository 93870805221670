import Grid from '@material-ui/core/Grid';
import ModalMultiConfirmation from 'components/contexts/modal/ModalMultiConfirmation/ModalMultiConfirmation';
import ActionButtons from 'components/form/ActionButtons';
import AsyncSelectPaginateRgb from 'components/form/Select/AsyncSelectPaginate/AsyncSelectPaginateRgb';
import InputText from 'components/form/Text/TextInput/InputText';
import Toggle from 'components/form/Toggle/Toggle';
import FormContainer from 'components/structure/FormContainer/FormContainer';
import { COLORS } from 'constants/endpoints';
import urls from 'constants/urls';
import { getUserData } from 'lib/contexts/auth';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addColorCombination } from 'store/colorCombinations/colorCombinations-slice';

import { getPermission } from 'utils/permissions';

const ColorCombinationsCreate = () => {
  const user = getUserData();
  const [name, setName] = useState('');
  const [colors, setColors] = useState([{}, {}, {}, {}]);
  const [publicationStatus, setPublicationStatus] = useState(true);

  const [showModalConfirmationSave, setShowModalConfirmationSave] =
    useState(false);
  const [showModalConfirmationCancel, setShowModalConfirmationCancel] =
    useState(false);

  const [statusForm, setStatus] = useState({
    type: '',
  });

  const { permissions } = useSelector((state) => state.auth);
  const { status } = useSelector((state) => state.colorCombinations);
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    if (getPermission('color-groups', permissions, 'create') === false) {
      history.push('/');
    }
  }, [history, permissions]);

  const cancelColorCombination = () => {
    history.push(urls.ROUTES.CATALOGUE.items.combinations.path);
  };

  function validate() {
    if (name === '') {
      return setStatus({
        type: 'error',
      });
    }
    if (!colors[0].id) {
      return setStatus({
        type: 'error',
      });
    }
    if (!colors[1].id) {
      return setStatus({
        type: 'error',
      });
    }
    return true;
  }

  const saveCombination = () => {
    let parsedColors = colors.filter((color) => color.id);

    if (!colors[2]?.id) {
      parsedColors.splice(2, 1);
    }
    if (!colors[3]?.id) {
      parsedColors.splice(3, 1);
    }

    parsedColors = parsedColors.map((color, index) => {
      return { id: color.id, position: index + 1 };
    });

    dispatch(
      addColorCombination({
        name,
        published: publicationStatus,
        colors: parsedColors,
      }),
    );
    if (status !== 'error') {
      history.push(urls.ROUTES.CATALOGUE.items.combinations.path);
    }
  };

  return (
    <>
      <ModalMultiConfirmation
        title={intl.messages['textModal.createColorCombination']}
        isOpen={showModalConfirmationSave}
        setIsOpen={setShowModalConfirmationSave}
        buttonNameBack={intl.messages['buttons.not']}
        buttonName={intl.messages['buttons.yes']}
        confirmOnClick={() => {
          if (validate) {
            saveCombination();
          } else {
            setShowModalConfirmationSave(false);
          }
        }}
        status="confirm"
      />
      <ModalMultiConfirmation
        title={intl.messages['textModal.cancelCreateColorCombination']}
        isOpen={showModalConfirmationCancel}
        setIsOpen={setShowModalConfirmationCancel}
        buttonNameBack={intl.messages['buttons.not']}
        buttonName={intl.messages['buttons.yes']}
        confirmOnClick={cancelColorCombination}
        status="info"
      />

      <FormContainer title={intl.messages['textHeader.createColorCombination']}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <InputText
                  placeholder={intl.messages['labelsInputs.combinationName']}
                  label={intl.messages['labelsInputs.combinationName']}
                  value={name}
                  handleChange={setName}
                  error={
                    statusForm.type === 'error'
                      ? 'Campo Nome da Combinação deve ser preenchido'
                      : ''
                  }
                  isError={name === '' && statusForm.type}
                />
              </Grid>
              <Grid item md={12}>
                <InputText
                  placeholder={intl.messages['labelsInputs.typeAuthor']}
                  label={intl.messages['labelsInputs.typeAuthor']}
                  value={user.name}
                  disabled
                />
              </Grid>

              <Grid item md={12}>
                <AsyncSelectPaginateRgb
                  label={intl.messages['labelsInputs.firstColor']}
                  placeholder={intl.messages['labelsInputs.select']}
                  value={colors[0]}
                  onChange={(value) => {
                    colors[0] = value;
                    setColors([...colors]);
                  }}
                  url={COLORS}
                  perPage={50}
                  rgbPreview
                  error={
                    statusForm.type === 'error'
                      ? 'Campo Primeira Cor deve ser preenchido'
                      : ''
                  }
                  isError={!colors[0].id && statusForm.type}
                  isDeletable={(colors[0].id && !colors[1].id) || false}
                  deleteAction={() => {
                    colors[0] = {};
                    setColors([...colors]);
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <AsyncSelectPaginateRgb
                  label={intl.messages['labelsInputs.secondColor']}
                  placeholder={intl.messages['labelsInputs.select']}
                  value={colors[1]}
                  onChange={(value) => {
                    colors[1] = value;
                    setColors([...colors]);
                  }}
                  url={COLORS}
                  perPage={50}
                  rgbPreview
                  error={
                    statusForm.type === 'error'
                      ? 'Campo Segunda Cor deve ser preenchido'
                      : ''
                  }
                  isError={!colors[1].id && statusForm.type}
                  isDeletable={
                    (colors[0].id && colors[1].id && !colors[2]?.id) || false
                  }
                  deleteAction={() => {
                    colors[1] = {};
                    setColors([...colors]);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid container md={12}>
                <AsyncSelectPaginateRgb
                  label={intl.messages['labelsInputs.thirdColor']}
                  placeholder={intl.messages['labelsInputs.select']}
                  value={colors[2]}
                  onChange={(value) => {
                    colors[2] = value;
                    setColors([...colors]);
                  }}
                  url={COLORS}
                  perPage={50}
                  rgbPreview
                  optional
                  disabled={!colors[0].id || !colors[1].id}
                  isDeletable={
                    (colors[0].id &&
                      colors[1].id &&
                      colors[2]?.id &&
                      !colors[3]?.id) ||
                    false
                  }
                  deleteAction={() => {
                    colors[2] = {};
                    setColors([...colors]);
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <AsyncSelectPaginateRgb
                  label={intl.messages['labelsInputs.fourthColor']}
                  placeholder={intl.messages['labelsInputs.select']}
                  value={colors[3]}
                  onChange={(value) => {
                    colors[3] = value;
                    setColors([...colors]);
                  }}
                  url={COLORS}
                  perPage={50}
                  rgbPreview
                  optional
                  disabled={!colors[2]?.id}
                  isDeletable={(colors[2]?.id && colors[3]?.id) || false}
                  deleteAction={() => {
                    colors[3] = {};
                    setColors([...colors]);
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <Toggle
                  label={intl.messages['labelsInputs.publicationStatus']}
                  checked={publicationStatus}
                  onChange={(e) => setPublicationStatus(e.target.checked)}
                  regular
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <ActionButtons
          type="create"
          cancelPath={() => setShowModalConfirmationCancel(true)}
          submitFunc={() => setShowModalConfirmationSave(true)}
        />
      </FormContainer>
    </>
  );
};

export default ColorCombinationsCreate;
