import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import InputText from 'components/form/Text/TextInput/InputText';
import Toggle from 'components/form/Toggle/Toggle';
import Grid from '@material-ui/core/Grid';
import Button from 'components/structure/Button/Button';
import { Container } from '../AddBaseModalForm/AddBaseModalForm.styles';

const EditBaseModalForm = ({
  name,
  setName,
  isActive,
  setIsActive,
  cancelActionModal,
  confirmOnClick,
}) => {
  const [statusForm, setStatus] = useState({
    type: '',
  });

  const intl = useIntl();

  function validate() {
    if (name === '') {
      return setStatus({
        type: 'error',
      });
    }
    return true;
  }

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Grid container>
            <Grid item xs={12}>
              <InputText
                type="text"
                label={intl.messages['labelsInputs.typeYourName']}
                placeholder={intl.messages['labelsInputs.typeYourName']}
                value={name}
                handleChange={setName}
                error={
                  statusForm.type === 'error'
                    ? 'Campo Nome deve ser preenchido'
                    : ''
                }
                isError={name === '' && statusForm.type}
              />
            </Grid>
            <Grid item xs={12}>
              <Toggle
                label={intl.messages['labelsInputs.activation']}
                paddingTop={name === '' && statusForm.type ? '34px' : '56px'}
                paddingBottom="56px"
                checked={isActive}
                onChange={(e) => setIsActive(e.target.checked)}
                large
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Button
            type="button"
            btnType="secondary"
            width="100%"
            height="44px"
            borderRadius="8px"
            uppercase
            onClick={cancelActionModal}
            fontWeight="600"
          >
            {intl.messages['buttons.cancel']}
          </Button>
        </Grid>
        <Grid item md={6} xs={12}>
          <Button
            btnType="primary"
            width="100%"
            height="44px"
            borderRadius="8px"
            uppercase
            onClick={() => {
              if (validate()) {
                confirmOnClick(true);
              } else {
                confirmOnClick(false);
              }
            }}
            fontWeight="600"
          >
            {intl.messages['buttons.toSave']}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

EditBaseModalForm.propTypes = {
  name: PropTypes.string,
  setName: PropTypes.func,
  isActive: PropTypes.bool,
  setIsActive: PropTypes.func,
  cancelActionModal: PropTypes.func,
  confirmOnClick: PropTypes.func,
};

EditBaseModalForm.defaultProps = {
  name: '',
  setName: () => {},
  isActive: false,
  setIsActive: () => {},
  cancelActionModal: () => {},
  confirmOnClick: () => {},
};

export default EditBaseModalForm;
