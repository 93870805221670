import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
export const ContainerText = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 50%;
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    font-family: ${theme.typography.family.secondary};
    font-weight: bold;
    font-size: 36px;
    color: ${theme.colors.tertiary.default};
  `}
`;

export const Subitle = styled.h3`
  ${({ theme }) => css`
    font-family: ${theme.typography.family.primary};
    font-size: 18px;
    color: ${theme.colors.tertiary.darker};
    text-align: center;
    padding-top: 10px;
    line-height: 25px;

    b {
      font-weight: 700;
    }

    span {
      font-weight: 400;
      color: ${theme.colors.tertiary.light};
    }
  `}
`;

export const ContainerImage = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  height: 40%;
  img {
    height: 70%;
  }
`;
