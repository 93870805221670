import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 425px;
    text-align: center;

    > img {
      width: 69px;
      height: 69px;
      margin-bottom: 32px;
    }

    > p {
      margin-bottom: 32px;
      font-family: ${theme.typography.family.secondary};
      font-size: 24px;
      font-weight: 700;
    }
  `}
`;
