export const platforms = [
  { value: 'app', label: 'App' },
  { value: 'ecommerce', label: 'Ecommerce' },
  { value: 'institucional', label: 'Suvinil' },
  { value: 'glasu', label: 'Glasu' },
];

export const areas = [
  { value: 'internal', label: 'Interno' },
  { value: 'external', label: 'Externo' },
];

export const internalAreas = [
  { value: 'social', label: 'Social' },
  { value: 'humid', label: 'Húmido' },
];

export const psqs = [
  { value: 'premium', label: 'Premium' },
  { value: 'standard', label: 'Standard' },
  { value: 'economic', label: 'Economic' },
  { value: 'superPremium', label: 'Super Premium' },
  { value: 'n/a', label: 'N/A' },
];

export const coatings = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '1 a 2', label: '1 a 2' },
  { value: '2 a 3', label: '2 a 3' },
  { value: '3 a 4', label: '3 a 4' },
  { value: 'na', label: 'N/A' },
];

export const smells = [
  { value: 'Baixo odor', label: 'Baixo odor' },
  { value: 'Não deixa cheiro', label: 'Não deixa cheiro' },
  { value: 'na', label: 'N/A' },
];

export const finishs = [
  { value: 'Acetinado', label: 'Acetinado' },
  { value: 'Alumínio', label: 'Alumínio' },
  { value: 'Brilhante', label: 'Brilhante' },
  { value: 'Fosco', label: 'Fosco' },
  { value: 'Fosco Suave', label: 'Fosco Suave' },
  { value: 'Semiacetinado', label: 'Semiacetinado' },
  { value: 'Semibrilho', label: 'Semibrilho' },
  { value: 'N/A', label: 'N/A' },
];

export const surfaces = [
  {
    value: 'Azulejos, pastilhas e pisos',
    label: 'Azulejos, pastilhas e pisos',
  },
  {
    value: 'Fundo para reboco, gesso, drywall e blocos de concreto',
    label: 'Fundo para reboco, gesso, drywall e blocos de concreto',
  },
  { value: 'Madeiras', label: 'Madeiras' },
  { value: 'Madeiras e metais', label: 'Madeiras e metais' },
  {
    value:
      'Madeiras, ferro novo, metalon, aço carbono, alumínio, PVC, polietileno, policarbonato, acrílico ou galvanizados',
    label:
      'Madeiras, ferro novo, metalon, aço carbono, alumínio, PVC, polietileno, policarbonato, acrílico ou galvanizados',
  },
  { value: 'Metais', label: 'Metais' },
  { value: 'Outras superfícies', label: 'Outras superfícies' },
  { value: 'Paredes', label: 'Paredes' },
  { value: 'Paredes e concretos', label: 'Paredes e concretos' },
  { value: 'Paredes e tetos', label: 'Paredes e tetos' },
  {
    value: 'Paredes, azulejos e pastilhas',
    label: 'Paredes, azulejos e pastilhas',
  },
  { value: 'Pisos', label: 'Pisos' },
  {
    value: 'Reboco, massa acrílica e textura',
    label: 'Reboco, massa acrílica e textura',
  },
  {
    value: 'Superfícies de alvenaria',
    label: 'Superfícies de alvenaria',
  },
  { value: 'Tetos', label: 'Tetos' },
  {
    value: 'Tijolos, telhas e pedras naturais',
    label: 'Tijolos, telhas e pedras naturais',
  },
  { value: 'N/A', label: 'N/A' },
];

export const dryingByTouches = [
  { value: '20 minutos', label: '20 minutos' },
  { value: '30 minutos', label: '30 minutos' },
  { value: '40 minutos', label: '40 minutos' },
  { value: '1 hora', label: '1 hora' },
  { value: '2 horas', label: '2 horas' },
  { value: '3 horas', label: '3 horas' },
  { value: '4 horas', label: '4 horas' },
  { value: '4 a 6 horas', label: '4 a 6 horas' },
  { value: '8 horas', label: '8 horas' },
  { value: 'N/A', label: 'N/A' },
];

export const dryingByCoatings = [
  { value: '5 a 10 minutos', label: '5 a 10 minutos' },
  { value: '30 minutos a 2 horas', label: '30 minutos a 2 horas' },
  { value: '1 hora', label: '1 hora' },
  { value: '2 horas', label: '2 horas' },
  { value: '2 a 3 horas', label: '2 a 3 horas' },
  { value: '3 horas', label: '3 horas' },
  { value: '4 horas', label: '4 horas' },
  { value: '6 horas', label: '6 horas' },
  { value: '8 horas', label: '8 horas' },
  { value: '12 horas', label: '12 horas' },
  { value: '16 a 48 horas', label: '16 a 48 horas' },
  { value: '24 horas', label: '24 horas' },
  { value: 'Mínimo 4 horas', label: 'Mínimo 4 horas' },
  { value: 'N/A', label: 'N/A' },
];

export const dryingByFinishes = [
  { value: '1 a 2 horas', label: '1 a 2 horas' },
  { value: '2 horas', label: '2 horas' },
  { value: '3 horas', label: '3 horas' },
  { value: '4 horas', label: '4 horas' },
  { value: '5 horas', label: '5 horas' },
  { value: '6 horas', label: '6 horas' },
  { value: '8 horas', label: '8 horas' },
  { value: '12 horas', label: '12 horas' },
  { value: '16 a 48 horas', label: '16 a 48 horas' },
  { value: '24 horas', label: '24 horas' },
  { value: '78 horas', label: '78 horas' },
  { value: '7 dias', label: '7 dias' },
  {
    value: '48 horas para circulação de pessoas',
    label: '48 horas para circulação de pessoas',
  },
  {
    value: '120 horas para circulação de veículos',
    label: '120 horas para circulação de veículos',
  },
  {
    value:
      'Para tráfego após aplicação da Suvinil Resina Piso: 3 a 7 dias De acordo com o produto de acabamento',
    label:
      'Para tráfego após aplicação da Suvinil Resina Piso: 3 a 7 dias De acordo com o produto de acabamento',
  },
  {
    value:
      'Pode andar sobre o piso após 3 dias e para tráfegos mais pesados 7 dias',
    label:
      'Pode andar sobre o piso após 3 dias e para tráfegos mais pesados 7 dias',
  },
  { value: 'N/A', label: 'N/A' },
];
