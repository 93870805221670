import ModalMultiConfirmation from 'components/contexts/modal/ModalMultiConfirmation/ModalMultiConfirmation';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import AsyncSelectPaginate from 'components/form/Select/AsyncSelectPaginate/AsyncSelectPaginate';
import SelectSearch from 'components/form/Select/ReactSelect';
import InputText from 'components/form/Text/TextInput/InputText';
import Toggle from 'components/form/Toggle/Toggle';
import { BASES, COLORS, DRY_PACKAGES } from 'constants/endpoints';
import urls from 'constants/urls';
import ActionButtons from 'components/form/ActionButtons';
import React, { useEffect, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import FileInput from 'components/form/FileInput/FileInput';
import SkuImageList from 'components/contexts/skus/SkuImageList/SkuImageList';
import { useHistory } from 'react-router-dom';
import api from 'services/api';
import Modal from 'components/structure/Modal/Modal';
import { startLoading } from 'store/loading/loading-slice';
import { getPermission } from 'utils/permissions';
import { systemColors } from './dataSkusOptions';
import {
  preventRedirectDisabled,
  preventRedirectEnabled,
} from 'store/sidebar/sidebar-slice';
import {
  getSkuDetail,
  statusSkusModal,
  putSku as updateSku,
} from 'store/sku/sku-slice';
import * as S from './SkuEdit.styles';
import Button from 'components/structure/Button/Button';
import { useSkuImageList } from 'hooks/useSkuImageList';

const SkuEdit = () => {
  const [ean, setEan] = useState('');
  const [dryPackage, setDryPackage] = useState(null);
  const [packageContentId, setPackageContentId] = useState(null);
  const [systemColor, setSystemColor] = useState('');
  const [isLoadingWeightOptions, setLoadingWeightOptions] = useState(false);
  const [weightOptions, setWeightOptions] = useState([]);
  const [base, setBase] = useState(null);
  const [color, setColor] = useState(null);
  const [active, setActive] = useState(true);
  const [uploadImageModal, setUploadImageModal] = useState(false);
  const [showModalConfirmationCancel, setShowModalConfirmationCancel] =
    useState(false);
  const { loading } = useSelector((state) => state.loading);
  const { permissions } = useSelector((state) => state.auth);
  const { openStatusModal, status, msg, selectId, sku } = useSelector(
    (state) => state.sku,
  );
  const product = useSelector((state) => state.sku.product);
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [statusForm] = useState({
    type: '',
  });
  const {
    skuImages,
    setSkuImages,
    saveSkuImages,
    imagesToDeleteFromBucket,
    setImagesToDeleteFromBucket,
    addSkuImage,
  } = useSkuImageList(product, sku);

  useEffect(() => {
    if (getPermission('stock-keeping-units', permissions, 'update') === false) {
      history.push('/');
    }
  }, [history, permissions]);
  useEffect(() => {
    if (selectId === 0) {
      history.push(urls.ROUTES.SKU_LIST.path);
    } else {
      dispatch(getSkuDetail(parseInt(selectId, 10)));
    }
  }, [selectId, dispatch, history]);
  useEffect(() => {
    dispatch(preventRedirectEnabled());
  }, [dispatch]);

  const findDryPackage = async (dryPackageId) => {
    const res = await api.get(`${DRY_PACKAGES}/${dryPackageId}`);
    setDryPackage(res.data);
  };

  const hadleDryPackageContent = useCallback(async (id) => {
    setLoadingWeightOptions(true);

    const { data } = await api.get(
      `${DRY_PACKAGES}/${id}/package-contents?perPage=1000`,
    );

    setWeightOptions(
      data.items.map((item) => ({
        value: item.id,
        label: `${item.grossWeight.toString()} ${item.unit.alias}`,
      })),
    );

    setLoadingWeightOptions(false);
  }, []);

  useEffect(() => {
    setSystemColor(systemColors[sku?.colorSystemId - 1]?.value);
    setEan(sku?.ean);

    setBase(sku.base);

    setColor({
      name: sku?.productToColor?.color?.name,
      id: sku?.productToColor?.color?.id,
    });
    findDryPackage(sku.dryPackageId);

    setActive(sku.active);
    setPackageContentId(sku.packageContentId);

    setLoadingWeightOptions(true);
    hadleDryPackageContent(sku.dryPackageId);
    setLoadingWeightOptions(false);
  }, [sku, dispatch]);

  const putSku = async () => {
    dispatch(startLoading());

    const colorSystemsMap = {
      selfcolor: 1,
      readymix: 2,
      nocolor: 3,
    };

    const formData = new FormData();
    formData.append('productId', product.id);
    formData.append('active', active);
    formData.append('ean', ean);
    formData.append('dryPackageId', dryPackage?.id);
    formData.append('colorSystemId', colorSystemsMap[systemColor]);
    formData.append('baseId', base?.id);
    if (systemColor !== 'selfcolor') formData.append('colorId', color?.id);
    formData.append('packageContentId', packageContentId);
    dispatch(
      updateSku({
        id: sku.id,
        info: formData,
      }),
    );
  };

  const cancelSku = () => {
    dispatch(preventRedirectDisabled());
    history.push(urls.ROUTES.SKU_LIST.path);
  };

  const ImagePreview = useCallback(() => {
    return typeof window !== 'undefined' ? (
      <img
        style={{ width: '24px', height: '24px' }}
        src={
          typeof skuImages[0] === 'string'
            ? skuImages[0]
            : URL.createObjectURL(skuImages[0])
        }
        alt="Imagem"
      />
    ) : (
      <></>
    );
  }, [skuImages]);

  return (
    <>
      <ModalMultiConfirmation
        title={intl.messages['textModal.cancelCreateSku']}
        text={intl.messages['textModal.allWillBeLost']}
        isOpen={showModalConfirmationCancel}
        setIsOpen={setShowModalConfirmationCancel}
        buttonNameBack={intl.messages['buttons.not']}
        buttonName={intl.messages['buttons.yes']}
        confirmOnClick={cancelSku}
        status="info"
      />

      <S.ContainerModal>
        <Modal
          title={'Carregar imagens'}
          isOpen={uploadImageModal}
          setIsOpen={setUploadImageModal}
          confirmOnClick={() => {}}
          status=""
          containerWidth="100%"
          containerHeight="100%"
          titleRight
        >
          <S.ContainerModal>
            <div>
              <FileInput
                label="Imagem na PDP (carrossel)"
                text={`CARREGAR IMAGEM (CARROSSEL)`}
                title="Imagem que sera usada como showcase do produto na loja."
                accept="image/*"
                getFile={addSkuImage}
                dontShowTheFileName
              />
              <br />
              <div className="image-list">
                <div>
                  <p>Posição no carrossel</p>
                  <p>Imagem</p>
                  <p>Ações</p>
                </div>
                <SkuImageList
                  setImagesToDeleteFromBucket={setImagesToDeleteFromBucket}
                  items={skuImages.map((img) => ({
                    id: img.toString(),
                    image: img,
                  }))}
                  setItems={setSkuImages}
                />
              </div>
              <Button
                btnType="primary"
                width="140px"
                fontWeight="600"
                onClick={() => saveSkuImages(() => setUploadImageModal(false))}
                disabled={
                  loading ||
                  (skuImages.length === 0 &&
                    imagesToDeleteFromBucket.length === 0)
                }
              >
                CONFIRMAR
              </Button>
            </div>
          </S.ContainerModal>
        </Modal>
      </S.ContainerModal>

      <ModalMultiConfirmation
        title={msg.title}
        text={msg.text}
        setIsOpen={(payload) => {
          dispatch(statusSkusModal(payload));
          if (status !== 'error') {
            history.push(urls.ROUTES.SKU_LIST.path);
          }
        }}
        isOpen={openStatusModal}
        status={status}
      />

      <S.Container>
        <h3>{sku.name}</h3>

        <S.ToggleContainer>
          <Toggle
            label={intl.messages['labelsInputs.active']}
            checked={active}
            onChange={(e) => setActive(e.target.checked)}
            regular
          />
        </S.ToggleContainer>

        <S.FormContainerAParty>
          <InputText
            type="number"
            maxLength={13}
            minLength={13}
            placeholder="000"
            label={intl.messages['labelsInputs.eanSku']}
            value={ean}
            handleChange={setEan}
            padding={'0'}
            disabled
          />
          <AsyncSelectPaginate
            zIndex="50"
            label="Embalagem"
            placeholder="Selecione"
            value={dryPackage}
            url={DRY_PACKAGES}
            perPage={5}
            handleChange={(data) => {
              setDryPackage(data);
              hadleDryPackageContent(data);
            }}
            disabled
            productId={product?.id}
          />

          <SelectSearch
            zIndex="40"
            label="Peso"
            placeholder="Selecione"
            options={weightOptions}
            defaultOption={packageContentId}
            handleChange={setPackageContentId}
            disabled={isLoadingWeightOptions}
            error={
              statusForm.type === 'error'
                ? 'Campo Peso deve ser preenchido'
                : ''
            }
            isError={packageContentId === '' && statusForm.type}
          />

          <SelectSearch
            zIndex="30"
            label={intl.messages['labelsInputs.systemColorSku']}
            placeholder={intl.messages['labelsInputs.select']}
            options={systemColors}
            defaultOption={systemColor}
            handleChange={setSystemColor}
            error={
              statusForm.type === 'error' ? 'Campo Cor deve ser preenchido' : ''
            }
            isError={systemColor === '' && statusForm.type}
          />
        </S.FormContainerAParty>
        <S.FormContainerBParty>
          <S.InputSmallWrapper>
            <AsyncSelectPaginate
              zIndex="20"
              label={intl.messages['labelsInputs.baseSku']}
              placeholder={intl.messages['labelsInputs.select']}
              value={base}
              url={BASES}
              perPage={5}
              handleChange={setBase}
              error={
                statusForm.type === 'error'
                  ? 'Campo Base deve ser preenchido'
                  : ''
              }
              isError={base?.id === undefined && statusForm.type}
            />
          </S.InputSmallWrapper>
          {!!systemColor && systemColor !== 'selfcolor' && (
            <S.InputSmallWrapper>
              <AsyncSelectPaginate
                label={intl.messages['labelsInputs.colorSku']}
                placeholder={intl.messages['labelsInputs.select']}
                value={color}
                url={COLORS}
                perPage={10}
                handleChange={setColor}
                error={
                  statusForm.type === 'error'
                    ? 'Campo Cor deve ser preenchido'
                    : ''
                }
                isError={color?.id === undefined && statusForm.type}
              />
            </S.InputSmallWrapper>
          )}
          <S.ImageCTAContainer onClick={() => setUploadImageModal(true)}>
            <p>Imagens</p>
            <S.ImageCTAContent>
              {skuImages.length === 0 ? (
                <>
                  <div>
                    <p className="orange-text">
                      Clique para selecionar a imagem
                    </p>
                    &nbsp;
                    <p>| PNG ou JPG</p>
                  </div>
                  <UploadIcon />
                </>
              ) : (
                <>
                  <div>
                    <ImagePreview /> + {skuImages.length}
                  </div>
                  <EditIcon />
                </>
              )}
            </S.ImageCTAContent>
          </S.ImageCTAContainer>
        </S.FormContainerBParty>
        <ActionButtons
          type="create"
          cancelPath={() => setShowModalConfirmationCancel(true)}
          submitFunc={putSku}
        />
      </S.Container>
    </>
  );
};

export default SkuEdit;
