import styled, { css } from 'styled-components';

export const Color = styled.a`
  display: flex;
  flex: 1;
  align-items: center;
  border: 1px solid;
  border-color: ${({ rgbCode }) =>
    rgbCode === '255,255,255' ? '#ccc' : rgbCode};
  background-color: ${({ rgbCode }) => rgbCode};
  width: 100%;
  height: 50px;
  padding: 15px 12px;
  font-weight: 400;
  font-size: 0.85rem;

  color: ${({ colorText }) => colorText};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 20px 0;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerButtons = styled.div`
  display: flex;
  padding: 20px 0 0 8px;

  button {
    :first-child {
      margin-right: 8px;
    }
  }
`;

export const Divider = styled.hr`
  ${({ theme }) => css`
    background: ${theme.colors.background.light.sidebar};
    border: none;
    opacity: 0.5;
    height: 1px;
  `}
`;

export const PopOverContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    position: absolute;
    min-width: 134px;
    font-weight: 400;
    font-family: ${theme.typography.family.primary};
    background: ${theme.colors.white.default};
    box-shadow: 2px 2px 12px rgba(102, 102, 102, 0.2);
    border-radius: 4px;
    z-index: 1;
    right: 0;
    top: -6.5vh;
    cursor: pointer;
  `}
`;

export const PopItem = styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    line-height: 18px;
    color: ${theme.colors.tertiary.default};
    padding: 12px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    :hover {
      background-color: rgba(245, 140, 56, 0.2);
    }
  `}
`;

export const StepContainerBox = styled.div`
  ${({ theme }) => css`
    height: 100vh;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: ${theme.colors.primary.hover};
    }
  `}
`;

export const StepContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const FormContainer = styled.div``;

export const PropertiesTitle = styled.h2`
  ${({ theme }) => css`
    font-weight: 619;
    font-size: 20px;
    line-height: 124%;
    color: ${theme.colors.tertiary.default};
    font-family: ${theme.typography.family.secondary};
  `}
`;

export const FooterButtons = styled.div`
  display: flex;

  button {
    :first-child {
      margin-right: 8px;
    }
  }

  .eanExclusionButton {
    font-size: 1rem;
    color: #f76902;

    svg {
      margin-right: 8px;
      transform: scaleX(-1);

      path {
        fill: #f76902;
      }
    }

    margin-top: -49px;
    z-index: 9;

    @media only screen and (max-width: 1280px) {
      margin-top: -63px;
    }
  }
`;
