/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { LOGS } from 'constants/endpoints';

import api from 'services/api';
import { startLoading, stopLoading } from 'store/loading/loading-slice';

import { errorSessionToast, errorToast } from 'utils/toasts';

export const INITIAL_STATE = {
  logs: {
    totalPage: 1,
    totalItems: 0,
    items: [],
  },
  log: {
    id: '',
    action: '',
    userId: '',
    status: '',
    entity: '',
    entityId: '',
    request: '',
    response: '',
    createdAt: new Date(),
  },
};

const logsSlice = createSlice({
  name: 'logs',
  initialState: INITIAL_STATE,
  reducers: {
    getLogsSuccess: (state, { payload }) => {
      state.logs = payload;
    },
    getLogsByRecordSuccess: (state, { payload }) => {
      state.logs = payload;
    },
  },
});

export const logs = logsSlice.reducer;

export const { getLogsByRecordSuccess, getLogsSuccess } = logsSlice.actions;

export const getLogsList = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { perPage, page, ordenation, searchTerm } = payload;

  try {
    let url = '';
    if (searchTerm === '') {
      url = `${LOGS}?perPage=${perPage || '10'}&page=${page}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}`;
    } else {
      url = `${LOGS}?perPage=${
        perPage || '10'
      }&page=${page}&name=${searchTerm}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}`;
    }
    const response = await api.get(url);

    dispatch(getLogsSuccess(response.data));

    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getLogsListByRecord = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { perPage, page, ordenation, searchTerm, id, entityName } = payload;

  try {
    let url = '';
    if (searchTerm === '') {
      url = `${LOGS}?perPage=${perPage || '10'}&page=${page}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${
        ordenation ? 'ASC' : 'DESC'
      }&entityId=${id}&entity=${entityName}`;
    } else {
      url = `${LOGS}/${id}?perPage=${
        perPage || '10'
      }&page=${page}&name=${searchTerm}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${
        ordenation ? 'ASC' : 'DESC'
      }&entityId=${id}}&entity=${entityName}`;
    }
    const response = await api.get(url);

    dispatch(getLogsByRecordSuccess(response.data));
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};
