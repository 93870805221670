import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import { ReactComponent as PopOverIcon } from 'assets/icons/pop-over.svg';
import * as S from './PopOver.styles';

const arrowStyle = { color: '#0000' };

const Popover = ({ content, position, ...attrs }) => (
  <S.PopoverWrapper>
    <Popup
      trigger={
        <div>
          <PopOverIcon />
        </div>
      }
      position={position}
      {...{ arrowStyle }}
      {...attrs}
    >
      <div>{content}</div>
    </Popup>
  </S.PopoverWrapper>
);

Popover.propTypes = {
  content: PropTypes.node.isRequired,
  position: PropTypes.string,
};

Popover.defaultProps = {
  position: 'left center',
};

export default Popover;
