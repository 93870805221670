/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Modal from 'components/structure/Modal/Modal';
import Toggle from 'components/form/Toggle/Toggle';
import ActionButtons from 'components/form/ActionButtons/modalActionButtons';
import { useSelector, useDispatch } from 'react-redux';

import {
  getPublishArticleAttributeId,
  getPublishArticlePermission,
} from 'utils/permissions';
import {
  Container,
  PermissionContainer,
  Text,
} from './MorePermissionsModal.styles';
import {
  createAttributesValue,
  setArticlesModal,
  updateAttributesValue,
} from 'store/morePermissions/morePermissions-slice';

export const MorePermissionsModal = () => {
  const dispatch = useDispatch();
  const { articlesModal, articleResourceId, attributes, attributtesList } =
    useSelector((state) => state.morePermissions);

  const [publishArticles, setPublishArticles] = useState(false);

  useEffect(() => {
    setPublishArticles(getPublishArticlePermission(attributes));
  }, [attributes]);

  function submitMorePermissions() {
    const publishAttribute = getPublishArticleAttributeId(attributes);
    const publishAttributeId = getPublishArticleAttributeId(attributtesList);

    if (publishAttribute) {
      dispatch(
        updateAttributesValue({
          attributeData: { value: publishArticles },
          attributeId: publishAttributeId,
          rolesId: articleResourceId,
        }),
      );
    } else {
      dispatch(
        createAttributesValue({
          attributeData: { value: publishArticles },
          attributeId: publishAttributeId,
          rolesId: articleResourceId,
        }),
      );
    }

    dispatch(setArticlesModal(false));
  }

  const modalContent = (
    <Container>
      <h3>Permissões extras de Artigos</h3>
      <p>Permitir que o usuário realize as seguintes ações em Artigos:</p>
      <PermissionContainer>
        <Text>Publicação</Text>
        <Toggle
          checked={publishArticles}
          onChange={(e) => {
            setPublishArticles(e.target.checked);
          }}
          large
        />
      </PermissionContainer>
      <ActionButtons
        cancelPath={() => dispatch(setArticlesModal(false))}
        submitFunc={() => submitMorePermissions()}
      />
    </Container>
  );

  return (
    <Container>
      <Modal
        setIsOpen={() => dispatch(setArticlesModal(false))}
        isOpen={articlesModal}
        content={modalContent}
        containerWidth="100%"
        containerHeight="100%"
      />
    </Container>
  );
};
