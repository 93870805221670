import styled, { css } from 'styled-components';
import sortIcon from 'assets/icons/sort.svg';

export const ContainerTable = styled.div`
  width: 100%;
  border-collapse: collapse;

  .img-icon {
    margin-left: 50px;
  }
`;

export const Table = styled.table`
  ${({ theme }) => css`
    width: 100%;
    font-family: ${theme.typography.family.primary};
  `}
`;

export const TableBody = styled.tbody``;
export const TableHead = styled.thead``;

export const Td = styled.td`
  ${({ theme }) => css`
    font-family: ${theme.typography.family.primary};
    font-size: 0.85rem;
    line-height: 16px;
    text-align: left;
    padding: 12px 0 10px 12px;
    border-bottom: 1px solid ${theme.colors.black.lightest2};
    color: ${theme.colors.secondary.default};

    &:not(:last-child) {
      padding: 12px 10px;
    }
  `}
`;

export const Th = styled.th`
  ${({ theme }) => css`
    font-family: ${theme.typography.family.primary};
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
    padding: 10px;
    border: none;
    color: ${theme.colors.tertiary.default};
    white-space: pre;

    &:nth-child(2) {
      position: relative;
      left: 28px;
    }

    &:nth-child(3) {
      position: relative;
      left: -40px;
    }

    &:nth-child(4) {
      text-align: left;
    }

    img {
      padding-left: 16px;
    }
  `}
`;

export const Tr = styled.tr`
  font-size: 1rem;
  line-height: 19px;
  width: 100%;
`;

export const SortButton = styled.button`
  display: inherit;
  margin-left: 5px;
  background: transparent url(${sortIcon}) no-repeat center;
  width: 20px;
  height: 20px;
  padding: 0;
  border: none;
  vertical-align: text-bottom;
  cursor: pointer;
  outline: none;
`;

export const ButtonIcon = styled.button`
  display: inherit;
  background: transparent;
  width: 20px;
  height: auto;
  padding: 0;
  border: none;
  vertical-align: text-bottom;
  cursor: pointer;
  outline: none;

  img {
    display: flex;
    align-items: center;
  }
`;
