export const types = [
  {
    value: 'string',
    label: 'Texto',
  },
  {
    value: 'integer',
    label: 'Número Inteiro',
  },
  {
    value: 'double',
    label: 'Número Decimal',
  },
  {
    value: 'boolean',
    label: 'Verdadeiro/Falso',
  },
  {
    value: 'timestamp',
    label: 'Data',
  },
];
