const sm = '320px';
const md = '768px';
const lg = '1024px';
const xl = '1280px';
const xlg = '1400px';
const xxlg = '1600px';
const xxxlg = '1920px';

export const breakpoints = {
  sm,
  md,
  lg,
  xl,
  xlg,
  xxlg,
  xxxlg,
};
