import Grid from '@material-ui/core/Grid';
import ExternalLinkArticlePreview from 'components/contexts/Links/ExternalLinkArticlePreview/ExternalLinkArticlePreview';
import NewEditor from 'components/contexts/NewEditor/NewEditor';
import BoxAlert from 'components/structure/BoxAlert/BoxAlert';
import Button from 'components/structure/Button/Button';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { extractCaractersFromBackend } from 'utils/extractCaractersFromBackend';
import { ButtonsContainer } from './ArticlesSecondStepView.styles';
import { setActiveStep } from 'store/articles/articles-slice';

const ArticlesSecondStepView = () => {
  const { selectId, article } = useSelector((state) => state.articles);

  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const cancelArticle = () => {
    history.goBack();
  };

  const advanceStepArticle = () => {
    dispatch(setActiveStep(2));
  };

  const backPreviousStep = () => {
    dispatch(setActiveStep(0));
  };

  const textBox1 = (
    <p>
      Informações sobre imagens:
      <br />
      1. Os titles das imagens devem ter no máximo 125 caracteres, com máximo
      recomendado de 100 caracteres.
      <br />
      2. Elementos visuais mais complexos devem ser adicionados como imagem.
      <br />
      3. As imagens devem possuir no máximo 1MB e proporção 16:9 ou 4:3.
    </p>
  );

  const textBox2 = (
    <p>
      Dicas:
      <br />
      1. O título H1 já foi adicionado na primeira etapa e não deve ser repetido
      nesta etapa.
      <br />
      2. Links externos devem sempre abrir em nova aba e links internos (do site
      institucional) podem abrir na mesma aba.
      <br />
      3. O alt text da imagem pode ser editada clicando na imagem e acessando
      &ldquo;editar imagem&ldquo; na barra de ferramentas.
    </p>
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Grid container>
            <Grid container spacing={4}>
              <Grid item md={6}>
                <BoxAlert title="Atenção" text={textBox1} />
              </Grid>
              <Grid item md={6}>
                <BoxAlert title="Atenção" text={textBox2} />
              </Grid>
            </Grid>

            <Grid item md={12} style={{ paddingTop: 24 }}>
              <NewEditor
                initialValue={extractCaractersFromBackend(article.description)}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <ButtonsContainer>
        <Grid container spacing={2}>
          {article.published === false && (
            <Grid item md={2} xs={12}>
              <div style={{ paddingTop: '12px' }}>
                <ExternalLinkArticlePreview
                  plataform={article.platforms}
                  articleId={article.id}
                  isPageArticle
                />
              </div>
            </Grid>
          )}

          <Grid item md={3} xs={12}>
            <Button
              btnType="blue"
              width="100%"
              fontWeight="600"
              onClick={backPreviousStep}
            >
              {intl.messages['buttons.toBack']}
            </Button>
          </Grid>

          <Grid item md={1} sm={12} />
          <Grid item md={3} sm={12}>
            <Button
              btnType="secondary"
              width="100%"
              borderRadius="4px"
              fontWeight="600"
              onClick={cancelArticle}
            >
              {intl.messages['buttons.cancel']}
            </Button>
          </Grid>
          <Grid item md={3} sm={12}>
            <Button
              btnType="primary"
              width="100%"
              borderRadius="4px"
              fontWeight="600"
              onClick={advanceStepArticle}
            >
              {intl.messages['buttons.advance']}
            </Button>
          </Grid>
        </Grid>
      </ButtonsContainer>
    </>
  );
};

export default ArticlesSecondStepView;
