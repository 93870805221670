import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import StepProgress from 'components/contexts/StepProgress/StepProgress';
import FormContainerSecondary from 'components/structure/FormContainerSecondary/FormContainerSecondary';
import { getPermission } from 'utils/permissions';
import { useHistory } from 'react-router-dom';
import { Container, StepContainer } from './Products.styles';
import ProductsViewFirstStep from './ProductsViewSteps/ProductsViewFirstStep';
import ProductsViewSecondStep from './ProductsViewSteps/ProductsViewSecondStep';

const ProductsView = (props) => {
  const { activeStep } = useSelector((state) => state.products);
  const { permissions } = useSelector((state) => state.auth);
  const intl = useIntl();
  const history = useHistory();
  const steps = ['PRODUTO', 'DETALHES'];

  useEffect(() => {
    if (getPermission('products', permissions, 'access') === false) {
      history.push('/');
    }
  }, [history, permissions]);

  return (
    <Container>
      <StepContainer>
        <StepProgress activeStep={activeStep} steps={steps} />
      </StepContainer>
      <FormContainerSecondary title={intl.messages['textHeader.viewProduct']}>
        {activeStep === 0 && <ProductsViewFirstStep />}
        {activeStep === 1 && <ProductsViewSecondStep />}
      </FormContainerSecondary>
    </Container>
  );
};

export default ProductsView;
