import styled, { css } from 'styled-components';

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

export const LinkTitle = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.typography.sizes.sm};
    line-height: 16px;
    font-weight: 400;
    padding-right: 5px;
  `}
`;

export const LinkItem = styled.a`
  ${({ theme }) => css`
    text-decoration: none;
    font-size: 1rem;
    cursor: pointer;

    ${({ isListArticles }) =>
      isListArticles &&
      css`
        line-height: 18px;
        color: ${theme.colors.tertiary.default};
      `};

    ${({ isPageArticle }) =>
      isPageArticle &&
      css`
        font-family: ${theme.typography.family.secondary};
        font-weight: 700;
        line-height: 24px;
        color: ${theme.colors.primary.default};
        margin-bottom: 5px;
      `};
    img {
      width: ${theme.typography.sizes.sm};
      ${({ isPageArticle }) =>
        isPageArticle &&
        css`
          width: ${theme.typography.sizes.md};
          position: relative;
          top: 3px;
          left: 5px;
        `};
    }
  `}
`;
