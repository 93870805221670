/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { PROPERTIES } from 'constants/endpoints';
import { startLoading, stopLoading } from 'store/loading/loading-slice';
import api from 'services/api';

import { errorSessionToast, errorToast, successToast } from 'utils/toasts';

const INITIAL_STATE = {
  properties: {
    totalPage: 1,
    totalItems: 0,
    items: [],
  },
  property: {
    id: '',
    name: '',
    type: '',
  },
  openStatusModal: false,
  status: '',
  msg: {
    text: '',
  },
  openDeleteModal: false,
  selectId: 0,
};

const propertiesSlice = createSlice({
  name: 'properties',
  initialState: INITIAL_STATE,
  reducers: {
    getPropertiesSuccess: (state, { payload }) => {
      state.properties = payload;
    },
    statusPropertiesModal: (state, { payload }) => {
      state.openStatusModal = payload;
    },
    setPropertiesStatus: (state, { payload }) => {
      state.status = payload;
    },
    setPropertiesMsg: (state, { payload }) => {
      state.msg = payload;
    },
    showModalDelete: (state) => {
      state.openDeleteModal = true;
    },
    dismissModalDelete: (state) => {
      state.openDeleteModal = false;
    },
    getPropertySuccess: (state, { payload }) => {
      state.property = payload;
    },
    setPropertyId: (state, { payload }) => {
      state.selectId = payload;
    },
    getDeletedPropertiesSuccess: (state, { payload }) => {
      state.properties = payload;
    },
  },
});

export const properties = propertiesSlice.reducer;
export const {
  dismissModalDelete,
  getDeletedPropertiesSuccess,
  getPropertiesSuccess,
  getPropertySuccess,
  setPropertiesMsg,
  setPropertiesStatus,
  setPropertyId,
  showModalDelete,
  statusPropertiesModal,
} = propertiesSlice.actions;

export const getPropertiesList = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { currentPage, searchTerm, perPage, ordenation } = payload;
  try {
    let url = '';
    if (searchTerm === '') {
      url = `${PROPERTIES}?perPage=${
        perPage || '10'
      }&page=${currentPage}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}`;
    } else {
      url = `${PROPERTIES}?perPage=${
        perPage || '10'
      }&page=${currentPage}&name=${searchTerm}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}`;
    }
    const response = await api.get(url);

    dispatch(getPropertiesSuccess(response.data));

    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getDeletedPropertiesList = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { page, searchTerm, perPage, ordenation } = payload;
  try {
    let url = '';
    if (searchTerm === '') {
      url = `${PROPERTIES}?perPage=${perPage || '10'}&page=${page}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}&trash=true`;
    } else {
      url = `${PROPERTIES}?perPage=${
        perPage || '10'
      }&page=${page}&name=${searchTerm}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}&trash=true`;
    }
    const response = await api.get(url);

    dispatch(getDeletedPropertiesSuccess(response.data));

    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const addProperties = (payload) => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api.post(PROPERTIES, payload);
    const msg = { title: 'Propriedade criada com sucesso!' };

    dispatch(setPropertiesMsg(msg));

    dispatch(setPropertiesStatus('success'));
    dispatch(stopLoading());
  } catch (error) {
    let msg = { text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Ops!',
            text: 'Não foi possível criar essa propriedade, verifique as informações.',
          };
          dispatch(setPropertiesMsg(msg));
          dispatch(setPropertiesStatus('error'));
          dispatch(statusPropertiesModal(true));
          break;
        case 409:
          msg = {
            title: 'Ops!',
            text: 'Propriedade já existente',
          };
          dispatch(setPropertiesMsg(msg));
          dispatch(setPropertiesStatus('error'));
          dispatch(statusPropertiesModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const removeProperties = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { id, page } = payload;
  try {
    await api.delete(`${PROPERTIES}/${id}`);
    const msg = { title: 'Propriedade deletada com sucesso!' };
    dispatch(setPropertiesMsg(msg));

    dispatch(dismissModalDelete());
    dispatch(stopLoading());
    dispatch(setPropertiesStatus('success'));
    dispatch(statusPropertiesModal(true));

    dispatch(getPropertiesList(page));
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    dispatch(dismissModalDelete());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Ops!',
            text: 'Não foi possível deletar a propriedade, verifique as informações.',
          };
          dispatch(setPropertiesMsg(msg));
          dispatch(statusPropertiesModal(true));
          dispatch(setPropertiesStatus('error'));
          dispatch(statusPropertiesModal(true));
          break;
        case 422:
          msg = {
            title: 'Ops!',
            text: 'Não foi possível deletar a propriedade. Propriedade pertence a um produto.',
          };
          dispatch(setPropertiesMsg(msg));
          dispatch(statusPropertiesModal(true));
          dispatch(setPropertiesStatus('error'));
          dispatch(statusPropertiesModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const getPropertyDetail = (payload) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await api.get(`${PROPERTIES}/${payload}`);

    const { id, name, type } = response.data;

    const property = {
      id,
      name,
      type,
    };

    dispatch(getPropertySuccess(property));
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getDeletedPropertyDetail = (payload) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await api.get(`${PROPERTIES}/${payload}?trash=true`);

    const { id, name, type } = response.data;

    const property = {
      id,
      name,
      type,
    };
    dispatch(getPropertySuccess(property));
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const editProperty = (payload) => async (dispatch) => {
  const { id, info } = payload;
  dispatch(startLoading());
  try {
    await api.patch(`${PROPERTIES}/${id}`, info);

    successToast('Propriedade alterada com sucesso!');
    dispatch(stopLoading());
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Ops!',
            text: 'Não foi possível alterar essa propriedade, verifique as informações.',
          };
          dispatch(setPropertiesMsg(msg));
          dispatch(setPropertiesStatus('error'));
          dispatch(statusPropertiesModal(true));
          break;
        case 409:
          msg = {
            title: 'Ops!',
            text: 'Propriedade já existente',
          };
          dispatch(setPropertiesMsg(msg));
          dispatch(setPropertiesStatus('error'));
          dispatch(statusPropertiesModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};
