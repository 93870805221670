export default {
  'pt-BR': {
    labelsInputs: {
      login: 'Login',
      password: 'Senha',
      passwordConfirm: 'Confirmar Senha',
      newPassword: 'Nova Senha',
      newPasswordConfirm: 'Confirmar Nova Senha',
      typeYourEmail: 'E-mail',
      typeYourName: 'Nome',
      typeYourRole: 'Perfil',
      typePlatform: 'Plataforma',
      enterSlug: 'Digite o slug',
      uplodadImageLabel: 'Imagem de capa',
      toDoUploadImage: 'Fazer upload da imagem',
      toDoUploadFile: 'Fazer upload do arquivo',
      tooltipArticleUploadImage: `<div style ="text-align: center;"><strong>Suvinil:</strong>  <br/>
      Dimensões da imagem de capa: 1366 x 512px <br/>
      Proporção: 16:6 <br/>
      Orientação: <i>landscape</i> (paisagem) <br/>
      Resolução mínima: 72dpi <br/>
      Limite de tamanho: 5MB <br/>
      Tipos permitidos: jpg, jpeg, webp e png<br/>
      <strong>Glasu!:</strong> <br/>
      Dimensões da imagem de capa: 1100 x 400px <br/>
      Proporção: 11:4 <br/>
      Orientação: <i>landscape</i> (paisagem) <br/>
      Resolução mínima: 72dpi <br/>
      Limite de tamanho: 5MB <br/>
      Tipos permitidos: jpg, jpeg, webp e png
      </div>`,

      altImageLabel: 'Alt da Imagem de Capa',
      description: 'Descrição',
      typeArticleTitle: 'Digite o Título do artigo',
      title: 'Título',
      titleExample: 'titulo-exemplo',
      slug: 'Slug',
      typeSiteTitle: 'Digite título do site',
      siteTitle: 'Site Title',
      defaultEditorial: 'Editoria Principal',
      selectProductLabel: 'Selecionar Produto',
      selectCollectionLabel: 'Selecionar Coleção/Lista de compras',
      select: 'Selecione',
      secondaryEditorial: 'Editoria Secundária',
      createArticles: 'Criar artigos',
      createSeoContentTitlePage: 'Criar conteúdo de SEO',
      editSeoContentTitlePage: 'Editar conteúdo de SEO',
      contentSEOTypeLabel: 'Tipo de página',
      platforms: 'Plataformas',
      relatedProducts: 'Produtos Relacionados',
      relatedColors: 'Cores Relacionadas',
      relatedFamilies: 'Famílias Relacionadas',
      relatedCollections: 'Coleções Relacionadas',
      tags: 'Tags',
      published: 'Publicado',
      shortDescription: 'Descrição Curta',
      editArticles: 'Editar artigos',
      activation: 'Ativação',
      enterName: 'Digite o nome',
      type: 'Tipo',
      metaDescription: 'Meta description',
      isVisibleOnTheHomeOfTheDigitalFan: 'Visível na home do leque digital',
      active: 'Ativo',
      pdpVisible: 'Visível na PDP',
      motherDepartment: 'Departamento mãe',
      motherCategory: 'Categoria mãe',
      tooltipTypeCategory:
        'Selecione o tipo de <br/> categoria que deseja criar.',
      tooltipMotherDepartment:
        'Selecione o departamento mãe <br/> da categoria que está sendo criada.',
      tooltipMotherCategory:
        'Selecione a categoria mãe <br/> da categoria que está sendo criada.',
      altTextImage: 'Alt text da Imagem',
      imageDescription: 'Descrição da imagem',
      typeSlugCategory: 'Slug da categoria',
      typeAuthor: 'Autor',
      subCategory: 'Subcategoria',
      similarDepartment: 'Departamento Similar',
      similarCategory: 'Categoria Similar',
      similarSubCategory: 'Subcategoria Similar',
      area: 'Área',
      internalArea: 'Área Interna',
      siteMetatags: 'Site Metatags',
      readyToUse: 'Pronto para usar',
      brand: 'Marca',
      productImage: 'Imagem do Produto',
      productImages: 'Imagens do Produto',
      mainProductImage: 'Imagem Principal do Produto',
      department: 'Departamento',
      category: 'Categoria',
      psq: 'PSQ',
      finishes: 'Acabamentos ',
      coats: 'Demãos',
      combinationName: 'Nome da Combinação',
      smell: 'Cheiro',
      dilution: 'Diluição',
      dryingByTouch: 'Secagem ao toque',
      dryingByCoating: 'Secagem entre demão',
      dryingByFinish: 'Secagem final',
      surfaces: 'Superfícies',
      specification: 'Especificação',
      specificationValue: 'Valor da especificação',
      specificationPosition: 'Posição da especificação',
      specificationType: 'Tipo da especificação',
      price: 'Preço',
      state: 'Estado',
      metaTag: 'Metatag',
      fispq: 'FISPQ',
      technical: 'Boletim Técnico',
      firstColor: 'Primeira Cor',
      secondColor: 'Segunda Cor',
      thirdColor: 'Terceira Cor',
      fourthColor: 'Quarta Cor',
      publicationStatus: 'Status da Publicação',

      /**
       * ToolTips
       */
      tooltipEditorialName: 'O Nome se refere ao nome da editoria',
      tooltipEditorialSlug: `<div style ="text-align: center;">O <i>slug</i> é montado a partir do nome <br/> e é usado para criar o <i>link</i> da editoria <br/> e de todos os artigos pertencentes a ela.</div>`,
      tooltipEditorialMetaDescription: `<div style ="text-align: center;">A <i>meta description</i> é uma rápida descrição sobre o conteúdo <br/> das páginas que aparecem nos resultados de busca <br/> e tem importância para <i>SEO</i>.</div>`,
      tooltipEditorialDescription:
        'A descrição é um resumo que <br/> aparece na página da editoria.',
      tooltipEditorialPlatform: `<div style ="text-align: center;">Em qual site a editoria deve ser publicada.</div>`,
      tooltipSuvinilEditorialImage: `<div style ="text-align: center;"><strong>Suvinil:</strong>  <br/>
       Dimensões da imagem de capa: 1366 x 360 px <br/>
       Orientação: <i>landscape</i> (paisagem) <br/>
       Limite de tamanho: 1MB <br/></div>`,
      tooltipGlasuEditorialImage: `<div style ="text-align: center;"><strong>Glasu:</strong>  <br/>
       Dimensões da imagem de capa: 1200 x 901 px <br/>
       Orientação: <i>landscape</i> (paisagem) <br/>
       Limite de tamanho: 1MB <br/></div>`,
      tooltipEditorialImage: `<div style ="text-align: center;"><strong>Suvinil e Glasu:</strong>  <br/>
       Orientação: <i>landscape</i> (paisagem) <br/>
       Limite de tamanho: 1MB <br/></div>`,
      tooltipEditorialAltTextImage: `<div style ="text-align: center;">Esse texto deve explicar o que vemos na imagem, <br/> de forma clara e objetiva. <br/> É de extrema importância para acessibilidade <br/> (pessoas com deficiência visual que usam esses recursos) e <i>SEO</i>.</div>`,
      tooltipArticleTitle: 'O título se refere ao <i>H1</i> da página.',
      tooltipArticleSlug: `<p style ="text-align: center;">
         O <i>slug</i> é montado a partir do título <br/> e é usado para criar o <i>link</i> do
         artigo. <br/> É gerado de forma automática
       </p>`,
      tooltipArticleSiteTitle: `<div style ="text-align: center;">É o título que vai aparecer na aba do navegador <br/> e nos resultados dos buscadores (como <i>Google</i>). <br/> Tem importância para <i>SEO</i>.</div>`,
      tooltipArticlePlatforms: `<div style ="text-align: center;">Em qual site o artigo deve ser publicado. <br/> São exibidas abaixo do título.</div>`,
      tooltipArticleEditorial: `<div style ="text-align: center;">Editoria principal que o artigo será publicado.</div>`,
      tooltipArticleSecondaryEditorial: `<div style ="text-align: center;">A segunda editoria que o artigo será publicado.</div>`,
      tooltipArticleShortDescription: `<div style ="text-align: center;">É o texto descritivo que irá aparecer nos buscadores (como <i>Google</i>), <br/>  quando alguém procurar por uma palavra chave relacionada ao artigo. <br/> Esse texto deve ser convidativo, "marketeiro", para atrair as pessoas <br/> a clicarem nesse conteúdo. Ele também tem importância para <br/> o <i>SEO</i> da página.</div>`,
      tooltipArticleAuthor: `<div style ="text-align: center;">Nome do autor do artigo.</div>`,
      tooltipArticleAltText: `<div style ="text-align: center;">Esse texto deve explicar o que vemos na imagem, de forma clara e objetiva. <br/> É de extrema importância para acessibilidade <br/> (pessoas com deficiência visual que usam esses recursos) e <i>SEO</i>.</div>`,
      tooltipArticleProducts: `<div style ="text-align: center;">Os produtos (tintas, texturas, preparadores) <br/> que são citados no artigo.</div>`,
      tooltipArticleColors: 'As cores que são citadas no artigo.',
      tooltipArticleFamilies: 'As famílias de cor que são citadas no artigo.',
      tooltipArticleCollections:
        'As coleções Suvinil que são citadas nesse artigo.',
      tooltipArticleTags: 'Palavras que se relacionam ao artigo',
      tooltipUserRole: 'Apenas um Admin pode criar usuários com Perfil “Admin”',
      tooltipArticleUploadImageSuvinil: `<div style ="text-align: center;"><strong>Suvinil:</strong> <br/> Dimensões da imagem de capa: 1366 x 512px <br/> Proporção: 16:6 <br/> Orientação: <i>landscape</i> (paisagem) <br/> Resolução mínima: 72dpi <br/> Limite de tamanho: 5MB</div>`,
      tooltipArticleUploadImageGlasu: `<div style ="text-align: center;"><strong>Glasu!:</strong> <br/> Dimensões da imagem de capa: 1100 x 400px <br/> Proporção: 11:4 <br/> Orientação: <i>landscape</i> (paisagem) <br/> Resolução mínima: 72dpi <br/> Limite de tamanho: 5MB</div>`,
      tooltipArticleUploadEditorialImageSuvinil: `<div style ="text-align: center;"><strong>Suvinil:</strong> <br/> Dimensões da imagem de editoria: 1366 x 360px <br/> Proporção: 19:10 <br/> Limite de tamanho: 500KB</div>`,
      tooltipArticleUploadEditorialImageGlasu: `<div style ="text-align: center;"><strong>Glasu!:</strong> <br/> Dimensões da imagem de editoria: 1200 x 901px <br/> Proporção: 12:9 <br/> Limite de tamanho: 500KB</div>`,
      tooltipUserPassword:
        'Sua senha deve conter letra maiúscula, letra minúscula, caractere especial, número, tendo na totalidade um mínimo de 6 e máximo de 16 caracteres.',
      tooltipProductName:
        'Este é o nome do produto, que aparece na página e também é enviado para VTEX',
      tooltipProductCategory: 'A categoria é uma subdivisão de departamento',
      tooltipProductDepartment: 'O departamento é uma subdivisão de categoria',
      /**
       * Skus Labels
       */
      systemColorSku: 'Sistema de Cor ',
      eanSku: 'EAN',
      sapSku: 'SAP',
      baseSku: 'Base',
      sizeSku: 'Tamanho',
      colorSku: 'Cor',
      defaultSku: 'Padrão',
      yieldSku: 'Rendimento',
      finishYieldSku: 'Rendimento acabado',
      widthSku: 'Largura',
      heightSku: 'Altura',
      lengthSku: 'Comprimento',
      liquidWeightSku: 'Peso Líquido',
      grossWeightSku: 'Peso Bruto',
      imageSku: 'Imagem do SKU',
      productSku: 'Produto',
      idSku: 'ID do SKU',
    },
    buttons: {
      enter: 'Entrar',
      continue: 'Continuar',
      resetPassword: 'Redefinir Senha',
      createNewPassword: 'Criar Nova Senha',
      goToLogin: 'Ir para login',
      add: 'Adicionar',
      edit: 'Editar',
      delete: 'DELETAR',
      activate: 'ATIVAR',
      deactivate: 'DESATIVAR',
      create: 'Criar',
      cancel: 'Cancelar',
      toSave: 'Salvar',
      exclusion: 'Excluir',
      toBack: 'Voltar',
      advance: 'Avançar',
      toSaveAndAdvance: 'Salvar e avançar',
      not: 'Não',
      yes: 'Sim',
      finish: 'Finalizar',
      toSaveAndFinish: 'Salvar e Finalizar',
      advanceNotSave: 'Avançar sem salvar',
      view: 'Ver detalhes',
    },

    toast: {
      emailError: 'Email ou senha incorretos!',
    },
    textModal: {
      addNewUser: 'Adicionar novo usuário',
      addNewUserSuccess: 'Usuário adicionado com sucesso!',
      editUser: 'Editar usuário',
      areYouSure: 'Tem certeza?',
      userDeleteConfirmMessage:
        'Ao pressionar deletar esse usuário será apagado da listagem junto com seus dados.',
      editorialdeleteFailTitle: 'Ops!',
      editorialdeleteFailText:
        'Não é possível deletar esta editoria, pois há pelo menos 01 artigo vinculado a ela',
      editorialDeleteConfirm:
        'Ao pressionar deletar essa editoria será apagada.',
      articleDeleteConfirm:
        'Ao pressionar deletar esse artigo será apagado da listagem junto com seus dados.',
      tagDeleteConfirmMessage:
        'Ao pressionar deletar essa tag será apagada da listagem junto com seus dados.',
      deleteCreateArticle: 'Você realmente quer cancelar a criação do artigo?',
      articleWillBeLost: 'Tudo o que estiver preenchido no artigo será perdido',
      deleteEditArticle: 'Você realmente quer cancelar a edição do artigo?',
      goBackPreviousStep:
        'Você realmente deseja voltar para a página anterior?',
      modificationsWillBeLost:
        'Todas as modificações serão perdidas ao executar essa ação.',
      articleNotPublished:
        'O seu artigo ainda está em modo não publicado. Você deseja salvar mesmo assim?',
      articlePublished:
        'O seu artigo está publicado. Você deseja salvar as alterações mesmo assim?',
      createArticleConfirm:
        'Seu artigo será criado em modo não publicado. Você deseja criar o artigo?',
      createEditorial: 'Você realmente deseja criar uma nova editoria?',
      cancelCreateEditorial:
        'Você realmente quer cancelar a criação da editoria?',
      saveEditEditorial: 'Você realmente deseja salvar as mudanças?',
      cancelEditEditorial: 'Você realmente quer cancelar a edição da editoria?',

      baseDeleteConfirm:
        'Ao pressionar deletar essa base será apagada da listagem junto com seus dados.',
      baseCreateConfirm: 'Deseja confirmar a criação dessa base?',
      baseEditConfirm: 'Salvar alteração de bases',
      modalToggleTitle:
        'Você realmente quer mudar o status de publicação do artigo?',
      roleDeleteConfirm:
        'Ao pressionar deletar esse perfil será apagado da listagem junto com seus dados.',
      sizeDeleteConfirm:
        'Ao pressionar deletar esse tamanho será apagada da listagem junto com seus dados.',
      sizeCreateConfirm: 'Deseja confirmar a criação desse tamanho?',
      sizeEditConfirm: 'Salvar alteração de tamanhos',
      createRoles: 'Criar Perfis',
      roleNameCreateConfirm: 'Deseja confirmar a criação do perfil?',
      roleNameEditConfirm: 'Deseja confirmar a edição do perfil?',
      cancelCreateRole:
        'Você realmente quer cancelar a criação do perfil e suas respectivas permissões?',
      cancelEditRole:
        'Você realmente quer cancelar a edição do perfil e suas respectivas permissões?',

      createColor: 'Deseja confirmar a criação dessa cor?',
      cancelCreateColor: 'Você realmente quer cancelar a criação da cor?',
      editColor: 'Deseja confirmar a edição dessa cor?',
      cancelEditColor: 'Você realmente quer cancelar a edição dessa cor?',
      colorDeleteConfirm:
        'Ao pressionar deletar essa cor será apagada da listagem junto com seus dados.',
      enterColor: 'cor',
      createColorGroup: 'Deseja confirmar a criação desse Grupo?',
      cancelCreateColorGroup:
        'Você realmente quer cancelar a criação desse Grupo?',
      editColorGroup: 'Deseja confirmar a edição desse Grupo?',
      cancelEditColorGroup:
        'Você realmente quer cancelar a edição desse Grupo?',
      colorGroupDeleteConfirm:
        'Ao pressionar deletar esse grupo de cor será apagado da listagem junto com seus dados',
      createColorCombination: 'Deseja confirmar a criação dessa combinação?',

      cancelCreateColorCombination:
        'Você realmente quer cancelar a criação dessa combinação?',
      editColorCombination: 'Deseja confirmar a edição dessa combinação?',
      cancelEditColorCombination:
        'Você realmente quer cancelar a edição dessa combinação?',
      colorCombinationDeleteConfirm:
        'Ao pressionar deletar esse grupo de cor será apagado da listagem junto com seus dados',
      createCategory: 'Deseja confirmar a criação dessa categoria?',
      cancelCreateCategory:
        'Você realmente quer cancelar a criação dessa categoria?',
      createCategoryWillBeLost: 'Tudo o que estiver preenchido será perdido!',
      editCategory: 'Deseja confirmar a edição dessa categoria?',
      cancelEditCategory:
        'Você realmente quer cancelar a alteração dessa categoria?',
      categoryDeleteConfirm:
        'Ao pressionar deletar essa categoria será apagada da listagem junto com seus dados.',
      createProductConfirm: 'Deseja confirmar a criação desse produto?',
      deleteProductCreate:
        'Você realmente quer cancelar a criação desse produto?',
      productWillBeLost: 'Tudo o que estiver preenchido será perdido!',
      editProductConfirm: 'Deseja confirmar a edição desse produto?',
      deleteProductEdit: 'Você realmente quer cancelar a edição desse produto?',
      createProperty: 'Criar Propriedade',
      editProperty: 'Editar Propriedade',
      viewProperty: 'Visualizar Propriedade',
      createPropertyConfirm: 'Deseja confirmar a criação dessa propriedade?',
      createPropertyCancel:
        'Você realmente quer cancelar a criação dessa propriedade?',
      colorProductConfirm:
        'Ao pressionar deletar esse produto será apagado da listagem junto com seus dados',
      propertiesConfirm:
        'Ao pressionar deletar essa propriedade será apagado da listagem junto com seus dados',
      allWillBeLost: 'Tudo o que estiver preenchido será perdido!',
      editPropertyConfirm: 'Deseja confirmar a edição dessa propriedade?',
      editPropertyCancel:
        'Você realmente quer cancelar a edição dessa propriedade?',

      /**
       * Prices Modal
       */
      createPrice: 'Criar Preço',
      priceCreateConfirm: 'Deseja confirmar a criação desse preço?',
      editPrice: 'Editar Preço',
      priceEditConfirm: 'Deseja confirmar a edição dessa preço?',
      priceDeleteText:
        'Ao pressionar deletar esse preço será apagada da listagem junto com seus dados. Digite o nome da estado para confirmar!',
      priceDeleteConfirm: 'Deseja confirmar a exclusão desse preço?',

      /**
       * Skus Modal
       */
      createSku: 'Deseja confirmar a criação dessa SKU?',
      cancelCreateSku: 'Você realmente quer cancelar a criação desse SKU?',
      editSku: 'Deseja confirmar a edição desse SKU?',
      cancelEditSku: 'Você realmente quer cancelar a alteração desse SKU?',
      deleteSkuConfirm:
        'Ao pressionar deletar esse SKU será apagado da listagem junto com seus dados.',
      idSku: 'ID do SKU',

      /**
       * New Password Modal
       */
      newPasswordTextModal:
        'Crie uma nova senha abaixo. Para a senha ser forte ela deve conter entre 8 e 16 caracteres incluindo uma ou mais letras maiúsculas, minúsculas, números e caracteres especiais.',
    },
    textTable: {
      titleTableUsers: 'Usuários',
      textTableContributors: {
        titleColumnName: 'Nome',
        titleColumnEmail: 'E-mail',
        titleColumnRole: 'Permissão',
      },
    },

    links: {
      forgotPassword: 'Esqueci minha senha',
    },
    textHeader: {
      createRole: 'Criar perfil',
      editRole: 'Editar perfil',
      viewRole: 'Visualizar perfil',
      colorGroups: 'Grupo de Cores',
      createColorGroups: 'Criar Grupo de Cores',
      newColorGroup: 'NOVO GRUPO',
      editColorGroups: 'Editar Grupo de Cores',
      createCategory: 'Criar Categoria',
      editCategory: 'Editar Categoria',
      createProduct: 'Criar Produto',
      editProduct: 'Editar Produto',
      viewProduct: 'Visualizar Produto',
      createSkus: 'Criar SKU',
      editSkus: 'Editar SKU',
      activitiesLogs: 'Atividades',
      colorCombination: 'NOVA COMBINAÇÃO',
      createColorCombination: 'Criar combinação',
      viewColorCombination: 'Visualizar combinação',
      editColorCombination: 'Editar combinação',
    },
  },
};
