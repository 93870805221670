import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: ${({ loading }) => (loading === 1 ? 'block' : 'none')};

  h3 {
    position: absolute;
    top: 62%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: blue !important;
    text-align: center;
    z-index: 10;
  }

  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
`;

export const BlurBackground = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    background: ${theme.colors.white.default};
    opacity: 0.8;
    z-index: 10;
    border-radius: 20px;
  `}
`;
