/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { TAGS } from 'constants/endpoints';

import api from 'services/api';
import { startLoading, stopLoading } from 'store/loading/loading-slice';

import { errorSessionToast, errorToast, successToast } from 'utils/toasts';

const INITIAL_STATE = {
  tags: {
    totalPage: 1,
    items: [],
  },
  openStatusModal: false,
  status: '',
  msg: {
    text: '',
  },
  tag: {
    id: 0,
    name: '',
    platforms: '',
  },
  selectId: 0,
};

const tagsSlice = createSlice({
  name: 'tags',
  initialState: INITIAL_STATE,
  reducers: {
    getTagsSuccess: (state, { payload }) => {
      state.tags = payload;
    },
    statusTagsModal: (state, { payload }) => {
      state.openStatusModal = payload;
    },
    setTagsStatus: (state, { payload }) => {
      state.status = payload;
    },
    setTagsMsg: (state, { payload }) => {
      state.msg = payload;
    },
    setTagId: (state, { payload }) => {
      state.selectId = payload;
    },
    getTagSuccess: (state, { payload }) => {
      state.tag = payload;
    },
  },
});

export const tags = tagsSlice.reducer;
export const {
  getTagSuccess,
  getTagsSuccess,
  setTagId,
  setTagsMsg,
  setTagsStatus,
  statusTagsModal,
} = tagsSlice.actions;

export const getListTags = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { currentPage, searchTerm, perPage, ordenation } = payload;
  try {
    let url = '';
    if (searchTerm === '') {
      url = `${TAGS}?perPage=${
        perPage || '10'
      }&page=${currentPage}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}`;
    } else {
      url = `${TAGS}?perPage=${
        perPage || '10'
      }&page=${currentPage}&name=${searchTerm}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}`;
    }
    const { data } = await api.get(url);

    const tagsData = {
      totalPage: data.totalPage,
      items: data.items,
    };

    dispatch(getTagsSuccess(tagsData));
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getDeletedTagsList = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { page, searchTerm, perPage, ordenation } = payload;

  try {
    let url = '';
    if (searchTerm === '') {
      url = `${TAGS}?perPage=${perPage || '10'}&page=${page}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}&trash=true`;
    } else {
      url = `${TAGS}?perPage=${
        perPage || '10'
      }&page=${page}&name=${searchTerm}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}&trash=true`;
    }
    const { data } = await api.get(url);

    const tagsData = {
      totalPage: data.totalPage,
      items: data.items,
    };
    dispatch(getTagsSuccess(tagsData));
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const addTag = (payload) => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api.post(TAGS, payload);
    dispatch(stopLoading());
    successToast('Tag criada com sucesso!');
  } catch (error) {
    let msg = { text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            text: 'Erros de Validação. Verifique os campos e tente novamente!',
          };

          dispatch(setTagsMsg(msg));
          dispatch(setTagsStatus('error'));
          dispatch(statusTagsModal(true));
          break;
        case 409:
          msg = {
            text: 'Tag já existente',
          };
          dispatch(setTagsMsg(msg));
          dispatch(setTagsStatus('error'));
          dispatch(statusTagsModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const getTagDetail = (payload) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await api.get(`${TAGS}/${payload}`);

    const { id, name, platforms } = response.data;

    const tag = {
      id,
      name,
      platforms: platforms[0],
    };

    dispatch(getTagSuccess(tag));
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getDeletedTagDetail = (payload) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await api.get(`${TAGS}/${payload}?trash=true`);

    const { id, name, platforms } = response.data;

    const tag = {
      id,
      name,
      platforms: platforms[0],
    };
    dispatch(getTagSuccess(tag));
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const editTag = (payload) => async (dispatch) => {
  const { id, info } = payload;
  dispatch(startLoading());
  try {
    await api.patch(`${TAGS}/${id}`, info);
    dispatch(stopLoading());
    successToast('Tag alterada com sucesso!');
  } catch (error) {
    let msg = { text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            text: 'Erros de Validação. Verifique os campos e tente novamente!',
          };
          dispatch(setTagsMsg(msg));
          dispatch(setTagsStatus('error'));
          dispatch(statusTagsModal(true));
          break;
        case 409:
          msg = {
            text: 'Tag já existente',
          };
          dispatch(setTagsMsg(msg));
          dispatch(setTagsStatus('error'));
          dispatch(statusTagsModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const removeTag = (payload) => async (dispatch) => {
  const { id } = payload;
  dispatch(startLoading());
  try {
    await api.delete(`${TAGS}/${id}`);
    const msg = { text: 'Tag excluída com sucesso!' };
    dispatch(setTagsMsg(msg));

    dispatch(setTagsStatus('success'));
    dispatch(statusTagsModal(true));
    dispatch(stopLoading());
  } catch (error) {
    let msg = { text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            text: 'Erro ao excluir tag. Tente novamente.',
          };
          dispatch(setTagsMsg(msg));
          dispatch(setTagsStatus('error'));
          dispatch(statusTagsModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};
