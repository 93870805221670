import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  .selectContainer {
    margin: 0 16px;
  }

  * {
    &:first-child {
      margin-left: 0px;

      &:disabled {
        height: 24px;
      }
    }
  }
`;
