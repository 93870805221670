import React from 'react';
import PropTypes from 'prop-types';
import SpanError from 'components/structure/SpanError/SpanError';
import errorIcon from 'assets/icons/ic-x-error.svg';
import { Container, Label, InputStyle } from './InputSlugConfirm.styles';

const InputSlugConfirm = ({
  type,
  id,
  name,
  isError,
  error,
  placeholder,
  value,
  handleChange,
  label,
  ...rest
}) => {
  return (
    <Container>
      {label && <Label htmlFor={name}>{label}</Label>}
      <InputStyle
        type={type}
        id={id}
        name={name}
        isError={isError}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        {...rest}
      />

      {isError && <SpanError>{error.message}</SpanError>}
      {isError && <img src={errorIcon} alt="" className="error-icon" />}
    </Container>
  );
};

InputSlugConfirm.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  isError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  error: PropTypes.any,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  label: PropTypes.string,
};

InputSlugConfirm.defaultProps = {
  type: '',
  id: '',
  name: '',
  error: '',
  isError: false,
  placeholder: '',
  value: '',
  handleChange: () => {},
  label: '',
};

export default InputSlugConfirm;
