/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { CATEGORIES, PRESIGN, PRODUCTS, PROPERTIES } from 'constants/endpoints';

import api from 'services/api';
import multiForm from 'services/multiForm';
import { startLoading, stopLoading } from 'store/loading/loading-slice';

import { errorSessionToast, errorToast, successToast } from 'utils/toasts';

const INITIAL_STATE = {
  products: {
    totalPage: 1,
    totalItems: 0,
    items: [],
  },
  product: {
    id: 0,
    name: '',
    psq: '',
    finish: '',
    coating: '',
    alreadyDone: false,
    smell: null,
    dilution: null,
    surface: null,
    dryingByTouch: null,
    dryingByCoating: null,
    dryingByFinish: null,
    description: '',
    shortDescription: '',
    slug: '',
    siteTitle: '',
    siteMetatags: '',
    readyToUse: false,
    active: false,
    platforms: [],
    brand: '',
    specifications: [],
    categories: [],
    images: [],
    properties: [],
    docs: {
      fispq: [],
      bt: [],
      other: [],
    },
    sizes: {
      items: [],
    },
  },
  activeStep: 0,
  openStatusModal: false,
  status: '',
  msg: {
    title: '',
    text: '',
  },
  items: [],
  selectId: 0,
  openDeleteModal: false,
  properties: [],
  fispqUrl: '',
  btUrl: '',
};

const productsSlice = createSlice({
  name: 'products',
  initialState: INITIAL_STATE,
  reducers: {
    getProductsSuccess: (state, { payload }) => {
      state.products = payload;
    },
    setProductActiveStep: (state, { payload }) => {
      state.activeStep = payload;
    },
    statusProductsModal: (state, { payload }) => {
      state.openStatusModal = payload;
    },
    setProductsStatus: (state, { payload }) => {
      state.status = payload;
    },
    setProductsMsg: (state, { payload }) => {
      state.msg = payload;
    },
    getDepartmentsSuccess: (state, { payload }) => {
      state.items = payload;
    },
    getSimilarDepartmentsSuccess: (state, { payload }) => {
      state.items = payload;
    },
    setProductId: (state, { payload }) => {
      state.selectId = payload;
    },
    getProductSuccess: (state, { payload }) => {
      state.product = payload;
    },
    showModalDelete: (state) => {
      state.openDeleteModal = true;
    },
    dismissModalDelete: (state) => {
      state.openDeleteModal = false;
    },
    getProductPropertiesSuccess: (state, { payload }) => {
      state.properties = payload;
    },
    getProductTypePropertiesSuccess: (state, { payload }) => {
      state.properties = payload;
    },
    getPresignFispqUrlSuccess: (state, { payload }) => {
      state.fispqUrl = payload;
    },
    getPresignBtUrlSuccess: (state, { payload }) => {
      state.btUrl = payload;
    },
    getDeletedProductsSuccess: (state, { payload }) => {
      state.products = payload;
    },
    setProductActiveToggle: (state, action) => {
      state.products = {
        ...state.products,
        items: state.products?.items?.map((product) => {
          return {
            ...product,
            active:
              product.id === action.payload.id
                ? action.payload.active === 'true'
                : product.active,
          };
        }),
      };
    },
  },
});

export const products = productsSlice.reducer;
export const {
  dismissModalDelete,
  getDeletedProductsSuccess,
  getDepartmentsSuccess,
  getPresignBtUrlSuccess,
  getPresignFispqUrlSuccess,
  getProductPropertiesSuccess,
  getProductSuccess,
  getProductTypePropertiesSuccess,
  getProductsSuccess,
  getSimilarDepartmentsSuccess,
  setProductActiveStep,
  setProductActiveToggle,
  setProductId,
  setProductsMsg,
  setProductsStatus,
  showModalDelete,
  statusProductsModal,
} = productsSlice.actions;

export const getProductsList = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { currentPage, searchTerm, perPage, ordenation } = payload;
  try {
    let url = '';
    if (searchTerm === '') {
      url = `${PRODUCTS}?perPage=${
        perPage || '10'
      }&page=${currentPage}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}`;
    } else {
      url = `${PRODUCTS}?perPage=${
        perPage || '10'
      }&page=${currentPage}&name=${searchTerm}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}`;
    }
    const response = await api.get(url);

    dispatch(getProductsSuccess(response.data));

    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getDeletedProductsList = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { page, searchTerm, perPage, ordenation } = payload;
  try {
    let url = '';
    if (searchTerm === '') {
      url = `${PRODUCTS}?perPage=${perPage || '10'}&page=${page}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}&trash=true`;
    } else {
      url = `${PRODUCTS}?perPage=${
        perPage || '10'
      }&page=${page}&name=${searchTerm}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}&trash=true`;
    }
    const response = await api.get(url);

    dispatch(getDeletedProductsSuccess(response.data));

    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getDepartmentsOptions = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await api.get(
      `${CATEGORIES}?type=1&sort.field=name&sort.order=ASC`,
    );
    const optionsData = response.data.items.map((option) => ({
      value: option?.id,
      label: option?.name,
      items: option?.childrens,
    }));

    dispatch(getDepartmentsSuccess(optionsData));
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getSimilarDepartmentsOptions = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await api.get(
      `${CATEGORIES}?type=1&sort.field=name&sort.order=ASC&perPage=100&page=1`,
    );
    const optionsData = response.data.items.map((option) => ({
      value: option?.id,
      label: option?.name,
      items: option?.childrens,
    }));

    dispatch(getSimilarDepartmentsSuccess(optionsData));
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const saveProductStepOne = (payload) => async (dispatch) => {
  const { info, goTo } = payload;
  dispatch(startLoading());
  try {
    const response = await multiForm.post(PRODUCTS, info);
    dispatch(setProductId(response.data.id));

    const msg = { title: 'Produto criado com sucesso!' };
    dispatch(setProductsMsg(msg));
    dispatch(setProductsStatus('success'));
    dispatch(setProductActiveStep(goTo));
    dispatch(stopLoading());
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Ops!',
            text: 'Não foi possível criar esse produto, verifique as informações.',
          };

          dispatch(setProductsMsg(msg));
          dispatch(setProductsStatus('error'));
          dispatch(statusProductsModal(true));
          break;
        case 409:
          msg = {
            title: 'Ops!',
            text: 'Produto já existente.',
          };
          dispatch(setProductsMsg(msg));
          dispatch(setProductsStatus('error'));
          dispatch(statusProductsModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const putProduct = (payload) => async (dispatch) => {
  const { id, info, activeStep } = payload;
  dispatch(startLoading());

  try {
    await multiForm.patch(`${PRODUCTS}/${id}`, info);

    dispatch(
      setProductActiveToggle({
        id,
        active: info.get('active'),
      }),
    );

    dispatch(stopLoading());

    if (activeStep === 0) {
      dispatch(setProductsStatus('first-step-success'));

      dispatch(setProductActiveStep(1));
    }

    if (activeStep === 1) {
      dispatch(setProductsStatus('second-step-success'));
    }

    successToast('Produto editado com sucesso');
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Ops!',
            text: 'Não foi possível editar esse produto, verifique as informações.',
          };
          dispatch(setProductsMsg(msg));
          dispatch(setProductsStatus('error'));
          dispatch(statusProductsModal(true));
          break;
        case 409:
          msg = {
            title: 'Produto já existente',
            text: '',
          };
          dispatch(setProductsMsg(msg));
          dispatch(setProductsStatus('error'));
          dispatch(statusProductsModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const getProductDetail = (payload) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await api.get(`${PRODUCTS}/${payload}`);
    const sizes = await api.get(`${PRODUCTS}/${payload}/sizes`);

    response.data.sizes = sizes.data;

    dispatch(getProductSuccess(response.data));
    dispatch(stopLoading());
  } catch (error) {
    errorToast(error);
    dispatch(stopLoading());
  }
};

export const getDeletedProductDetail = (payload) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await api.get(`${PRODUCTS}/${payload}?trash=true`);

    dispatch(getProductSuccess(response.data));
    dispatch(stopLoading());
  } catch (error) {
    errorToast(error);
    dispatch(stopLoading());
  }
};

export const removeProducts = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { id, page, searchTerm } = payload;
  try {
    await api.delete(`${PRODUCTS}/${id}`);
    const msg = { title: 'Produto deletado com sucesso!' };
    dispatch(setProductsMsg(msg));

    dispatch(dismissModalDelete());
    dispatch(stopLoading());
    dispatch(setProductsStatus('success'));
    dispatch(statusProductsModal(true));

    dispatch(getProductsList({ currentPage: page, searchTerm }));
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    dispatch(dismissModalDelete());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Ops!',
            text: 'Não foi possível deletar o produto, verifique as informações.',
          };
          dispatch(setProductsMsg(msg));
          dispatch(statusProductsModal(true));
          dispatch(setProductsStatus('error'));
          dispatch(statusProductsModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const getPropertiesOptions = () => async (dispatch) => {
  try {
    const response = await api.get(
      `${PROPERTIES}?perPage=1000&page=1&sort.field=name&sort.order=ASC`,
    );
    const optionsData = response.data.items.map((option) => ({
      value: option.id,
      label: option.name,
      type: option.type,
    }));

    dispatch(getProductPropertiesSuccess(optionsData));
  } catch (error) {
    /// TODO
    console.log(error);
  }
};

export const getInputsUrl = (payload) => async (dispatch) => {
  const { info, type } = payload;

  dispatch(startLoading());
  try {
    const response = await api.post(PRESIGN, info);

    if (type === 'fispq') {
      dispatch(getPresignFispqUrlSuccess(response.data));
    } else if (type === 'bt') {
      dispatch(getPresignBtUrlSuccess(response.data));
    }

    dispatch(stopLoading());
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Ops!',
            text: 'Não foi possível adicionar esse arquivo ao produto, verifique as informações.',
          };
          dispatch(setProductsMsg(msg));
          dispatch(setProductsStatus('error'));
          dispatch(statusProductsModal(true));
          break;
        case 500:
          msg = {
            title: 'Erro interno no servidor',
            text: '',
          };
          dispatch(setProductsMsg(msg));
          dispatch(setProductsStatus('error'));
          dispatch(statusProductsModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};
