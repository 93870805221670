import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FormContainerSecondary from 'components/structure/FormContainerSecondary/FormContainerSecondary';
import { getPermission } from 'utils/permissions';
import ModalMultiConfirmation from 'components/contexts/modal/ModalMultiConfirmation/ModalMultiConfirmation';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { StepContainerBox, StepContainer } from './Skus.styles';
import FirstStep from './SkuStep/Create/SkuCreate';
import { ImageRegisters, rules } from 'containers/UploadFilesImage';
import ActionButtons from 'components/form/ActionButtons';
import { saveSku as createSku, getListSkus } from 'store/sku/sku-slice';
import urls from 'constants/urls';
import { preventRedirectDisabled } from 'store/sidebar/sidebar-slice';
import { errorSessionToast, errorToast, successToast } from 'utils/toasts';

export default function SkuCreateStep() {
  const [showQueueModal, setShowQueueModal] = useState(false);
  const [showModalConfirmationCancel, setShowModalConfirmationCancel] =
    useState(false);
  const { permissions } = useSelector((state) => state.auth);
  const product = useSelector((state) => state.sku.product);
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const [validationOk, setValidationOk] = useState(false);
  const [formDataFromForms, setFormDataFromForms] = useState([]);
  const [formList, setFormList] = useState([
    {
      component: FirstStep,
      position: 0,
    },
  ]);

  const addNewSkuForm = () => {
    if (formList.length < 5) {
      setFormList([
        ...formList,
        {
          component: FirstStep,
          position: Math.max(...formList.map((form) => form.position)) + 1,
        },
      ]);
    }
  };

  const cancelSku = () => {
    dispatch(preventRedirectDisabled());
    history.push(urls.ROUTES.SKU_LIST.path);
  };

  const isDuplicated = () => {
    const eanList = formDataFromForms.map((form) => form.ean);
    const eanIndex = eanList.findIndex(
      (ean, index) => eanList.indexOf(ean) !== index,
    );
    return eanIndex !== -1;
  };

  const saveSku = () => {
    const colorSystemsMap = {
      selfcolor: 1,
      readymix: 2,
      nocolor: 3,
    };

    if (isDuplicated(formDataFromForms)) {
      errorToast('Impossível cadastrar SKUs com EANs idênticos.');
      return;
    }

    setShowQueueModal(true);

    formList.forEach((_, index) => {
      const {
        active,
        ean,
        dryPackage,
        systemColor,
        base,
        color,
        packageContentId,
      } = formDataFromForms[index];

      const formData = new FormData();
      formData.append('productId', product.id);
      formData.append('active', active);
      formData.append('ean', ean);
      formData.append('dryPackageId', dryPackage?.id);
      formData.append('colorSystemId', colorSystemsMap[systemColor]);
      formData.append('baseId', base?.id);
      if (systemColor !== 'selfcolor') formData.append('colorId', color?.id);
      formData.append('packageContentId', packageContentId);
      dispatch(createSku(formData));
    });
  };

  useEffect(() => {
    if (getPermission('products', permissions, 'create') === false) {
      history.push('/');
    }
  }, [history, permissions]);

  useEffect(() => {
    const validateFormCreateSku = () => {
      let isDisabled = false;

      formList.forEach((_, index) => {
        if (formDataFromForms[index]) {
          const { ean } = formDataFromForms[index];

          if (ean.length !== 13) {
            isDisabled = true;
          }
        }
      });

      setValidationOk(isDisabled);
    };

    validateFormCreateSku();
  }, [formList, formDataFromForms]);

  return (
    <StepContainerBox>
      <ModalMultiConfirmation
        title={intl.messages['textModal.cancelCreateSku']}
        text={intl.messages['textModal.allWillBeLost']}
        isOpen={showModalConfirmationCancel}
        setIsOpen={setShowModalConfirmationCancel}
        buttonNameBack={intl.messages['buttons.not']}
        buttonName={intl.messages['buttons.yes']}
        confirmOnClick={cancelSku}
        status="info"
      />

      <ModalMultiConfirmation
        isOpen={showQueueModal}
        setIsOpen={setShowQueueModal}
        buttonName={intl.messages['buttons.yes']}
        confirmOnClick={() => {
          setShowQueueModal(false);
          dispatch(
            getListSkus({
              currentPage: 1,
              searchTerm: '',
              productId: product.id,
              perPage: 10,
              ordenation: '',
            }),
          );
          history.push(urls.ROUTES.SKU_LIST.path);
        }}
        status="queue"
      >
        <h3 style={{ color: '#FF9500' }}>Processo iniciado</h3>
        <p>
          O processo de cadastro de SKU foi iniciado, o tempo estimado para
          conclusão é de até <strong>30 minutos</strong>
        </p>
      </ModalMultiConfirmation>
      <StepContainer />
      <FormContainerSecondary
        style={{
          marginTop: '100px',
        }}
        title="Criar SKU"
        action={{
          type: 'outlined',
          label: 'ADICIONAR NOVO SKU',
          onClick: addNewSkuForm,
        }}
      >
        {formList.map(({ formData, component: Component, position }, index) => {
          return (
            <Component
              // eslint-disable-next-line react/no-array-index-key
              key={`sku-form-${position}`}
              remove={setFormList}
              updateFormList={setFormDataFromForms}
              position={position}
              index={index}
            />
          );
        })}
        <ActionButtons
          type="create"
          cancelPath={() => setShowModalConfirmationCancel(true)}
          submitFunc={saveSku}
          disabled={validationOk}
        />
      </FormContainerSecondary>
    </StepContainerBox>
  );
}
