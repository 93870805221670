import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    > p {
      font-size: 18px;
      line-height: 24px;
      max-width: 90%;
      font-family: ${theme.typography.family.primary};
      color: ${theme.colors.secondary.default};
      text-align: center;

      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        padding: 8px 16px 12px;
      }
    }

    > span {
      font-size: 16px;
      padding: 0px 36px 12px;
      font-family: ${theme.typography.family.primary};
      color: ${theme.colors.secondary.default};
      text-align: center;

      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        padding: 0px 16px 12px;
      }
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      max-width: 100%;
    }
  `}
`;

export const InputContent = styled.div`
  ${({ theme }) => css`
    width: 70%;
    padding: 16px 0;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      padding: 0 16px 0;
    }
  `}
`;

export const Label = styled.h3`
  ${({ theme }) => css`
    color: ${theme.colors.secondary.dark};
    font-weight: 700;
    font-size: ${theme.typography.sizes.md};
    padding: 10px 0 5px;
  `}
`;

export const ButtonsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;

    button:first-child {
      margin-right: 12px;

      @media only screen and (max-width: ${theme.breakpoints.md}) {
        margin-bottom: 12px;
      }
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
    }
  `}
`;

export const AlertContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;

    background-color: ${theme.colors.primary.lighter};
    border-left: 5px solid ${theme.colors.primary.default};
    border-radius: 5px;
    padding: 10px 5px 15px 10px;
  `}
`;

export const AlertText = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.secondary.default};
    font-size: 14px;
    font-family: ${theme.typography.family.primary};
    line-height: 18px;
  `}
`;

export const AlertTitleContainer = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.primary.default};
    display: flex;
    width: 100%;
    padding-bottom: 5px;
    h3 {
      font-size: 18px;
      font-weight: 700;
      font-family: ${theme.typography.family.secondary};
    }

    img {
      padding-right: 5px;
    }
  `}
`;
