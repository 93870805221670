import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from 'components/structure/ActionButton/ActionButton';
import PropTypes from 'prop-types';
import { Container } from './ActionButtons.styles';

const ActionButtons = ({
  type,
  cancelPath,
  submitFunc,
  disabled,
  formType,
}) => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item md={type === 'none' ? 9 : 6} sm={12} />
        <Grid item md={3} sm={12}>
          <Button
            width="100%"
            type="button"
            btnType="secondary"
            borderRadius="5px"
            onClick={() => cancelPath()}
          >
            {type === 'edit' || type === 'none' ? (
              <b>VOLTAR</b>
            ) : (
              <b>CANCELAR</b>
            )}
          </Button>
        </Grid>
        <Grid item md={3} sm={12}>
          {type !== 'none' && (
            <Button
              width="100%"
              type={formType ? 'submit' : 'button'}
              btnType="primary"
              borderRadius="5px"
              fontWeight="bold"
              disabled={type === 'create' && disabled}
              onClick={() => submitFunc?.()}
            >
              {type === 'create'
                ? 'CADASTRAR'
                : type === 'view'
                ? 'EDITAR'
                : 'SALVAR'}
            </Button>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default ActionButtons;

ActionButtons.propTypes = {
  type: PropTypes.string.isRequired,
  cancelPath: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  submitFunc: PropTypes.func.isRequired,
  formType: PropTypes.bool,
};

ActionButtons.defaultProps = {
  disabled: false,
  formType: false,
};
