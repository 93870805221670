/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { MdAdd, MdRemove } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Menu, Item } from './sidelink.styles';

const Sidelink = ({
  nameLink,
  iconLink,
  items,
  handleSetLink,
  isActive,
  handleActive,
}) => {
  const [open, setOpen] = useState(false);
  const { permissions } = useSelector((state) => state.auth);

  const findPermission = (key) => {
    if (permissions.length === 0) {
      return false;
    }
    const found = permissions.find((permission) => permission.name === key);
    if (found) return found.canAccess;

    return false;
  };

  return (
    <>
      <Menu onClick={() => setOpen(!open)}>
        <div>
          <img src={iconLink} alt="" />
          {nameLink}
        </div>
        {open ? (
          <MdRemove className="openner" size={22} />
        ) : (
          <MdAdd className="openner" size={22} />
        )}
      </Menu>
      {open &&
        Object.keys(items).map((route) => {
          const { path, name, label, key } = items[route];
          if (findPermission(key)) {
            return (
              <Link
                key={path}
                to={path}
                onClick={(e) => handleSetLink(name, e, path)}
              >
                <Item
                  className={isActive === name ? 'active' : ''}
                  onClick={() => handleActive(name)}
                >
                  {label}
                </Item>
              </Link>
            );
          }
          return null;
        })}
    </>
  );
};

export default Sidelink;
