import styled, { css } from 'styled-components';

export const Divider = styled.hr`
  ${({ theme }) => css`
    background: ${theme.colors.background.light.sidebar};
    border: none;
    opacity: 0.5;
    height: 1px;
  `}
`;

export const PopOverContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    position: absolute;
    min-width: 134px;
    font-weight: 400;
    font-family: ${theme.typography.family.primary};
    background: ${theme.colors.white.default};
    box-shadow: 2px 2px 12px rgba(102, 102, 102, 0.2);
    border-radius: 4px;
    z-index: 1;
    right: 0;
    top: -6.5vh;
    cursor: pointer;
  `}
`;

export const PopItem = styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    line-height: 18px;
    color: ${theme.colors.tertiary.default};
    padding: 12px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    :hover {
      background-color: rgba(245, 140, 56, 0.2);
    }
  `}
`;
