import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: auto;
    align-items: flex-start;
    font-family: ${theme.typography.family.primary};
    flex-direction: column;

    input {
      width: 67px;
      height: 48px;
      color: ${theme.colors.secondary.default};
      font-size: 1rem;
      text-align: center;
      margin-right: 16px;
      border-radius: 2px;
    }

    span {
      font-family: ${theme.typography.family.primary};
      color: ${theme.colors.secondary.default};
      font-size: 14px;
    }
  `}
`;

export const Color = styled.div`
  border: 1px solid;
  border-color: ${({ rgbCode }) =>
    rgbCode === '255,255,255' ? '#ccc' : rgbCode};
  background-color: ${({ rgbCode }) => rgbCode};
  width: 48px;
  height: 48px;
  font-weight: 400;
  font-size: 0.85rem;
  line-height: 16px;
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
