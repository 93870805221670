import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Button from 'components/structure/Button/Button';
import NewEditor from 'components/contexts/NewEditor/NewEditor';
import urls from 'constants/urls';

import ModalMultiConfirmation from 'components/contexts/modal/ModalMultiConfirmation/ModalMultiConfirmation';
import BoxAlert from 'components/structure/BoxAlert/BoxAlert';
import ExternalLinkArticlePreview from 'components/contexts/Links/ExternalLinkArticlePreview/ExternalLinkArticlePreview';
import { Container, ButtonsContainer } from './ArticlesSecondStep.styles';
import {
  getArticleDetail,
  setActiveStep,
  setArticleId,
  statusArticlesModal,
  updateArticle,
} from 'store/articles/articles-slice';

const ArticlesSecondStep = () => {
  const { openStatusModal, msg, status, selectId, article } = useSelector(
    (state) => state.articles,
  );

  const [fullDescription, setFullDescription] = useState(null);

  const [showModalCancel, setShowModalCancel] = useState(false);
  const [showModalBackFirstStep, setShowModalBackFirstStep] = useState(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);

  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getArticleDetail(parseInt(selectId, 10)));
  }, [selectId, dispatch]);

  const cancelArticle = () => {
    history.push(urls.ROUTES.BLOG.items.articles.path);
  };

  const backPreviousStep = () => {
    history.push(urls.ROUTES.ARTICLES_EDIT.path);
    dispatch(setActiveStep(0));
    dispatch(setArticleId(selectId));
  };

  const saveStepTwo = () => {
    const formData = new FormData();

    formData.append('description', fullDescription?.getContent());

    dispatch(updateArticle({ info: formData, id: selectId }));
    history.push(urls.ROUTES.ARTICLES.path);
  };

  const textBox1 = (
    <p>
      Informações sobre imagens:
      <br />
      1. Os titles das imagens devem ter no máximo 125 caracteres, com máximo
      recomendado de 100 caracteres.
      <br />
      2. Elementos visuais mais complexos devem ser adicionados como imagem.
      <br />
      3. As imagens devem possuir no máximo 1MB e proporção 16:9 ou 4:3.
    </p>
  );

  const textBox2 = (
    <p>
      Dicas:
      <br />
      1. Para conteúdos de produtos, evite utilizar utilizar título H1 por conta
      do nome do produto.
      <br />
      2. Para conteúdos de coleções/lista de compras o primeiro título deve ser
      um H1, pois o conteúdo fica no início da página.
      <br />
      3. Links externos devem sempre abrir em nova aba e links internos (do site
      institucional) podem abrir na mesma aba.
      <br />
      4. O alt text da imagem pode ser editada clicando na imagem e acessando
      &ldquo;editar imagem&ldquo; na barra de ferramentas.
    </p>
  );

  const textBox3 = (
    <p>
      Para inserir conteúdo em colunas, é preciso:
      <br />
      1. Inserir uma tabela com uma linha, e duas ou mais colunas
      <br />
      2. Nas propriedades da tabela, colocar espessura da borda como zero, e
      classe &ldquo;Conteúdo&ldquo;
    </p>
  );

  return (
    <Container>
      <ModalMultiConfirmation
        title={intl.messages['textModal.areYouSure']}
        text={intl.messages['textModal.articleNotPublished']}
        isOpen={showModalConfirmation}
        setIsOpen={setShowModalConfirmation}
        buttonNameBack={intl.messages['buttons.not']}
        buttonName={intl.messages['buttons.yes']}
        status="confirm"
        confirmOnClick={saveStepTwo}
      />
      <ModalMultiConfirmation
        title={msg.title}
        text={msg.text}
        setIsOpen={(payload) => {
          dispatch(statusArticlesModal(payload));
          if (status !== 'error') {
            dispatch(setActiveStep(2));
          }
        }}
        isOpen={openStatusModal}
        status={status}
      />
      <ModalMultiConfirmation
        title={intl.messages['textModal.deleteEditArticle']}
        text={intl.messages['textModal.articleWillBeLost']}
        isOpen={showModalCancel}
        setIsOpen={setShowModalCancel}
        buttonNameBack={intl.messages['buttons.not']}
        buttonName={intl.messages['buttons.yes']}
        confirmOnClick={cancelArticle}
        status="info"
      />
      <ModalMultiConfirmation
        title={intl.messages['textModal.goBackPreviousStep']}
        text={intl.messages['textModal.modificationsWillBeLost']}
        isOpen={showModalBackFirstStep}
        setIsOpen={setShowModalBackFirstStep}
        buttonNameBack={intl.messages['buttons.not']}
        buttonName={intl.messages['buttons.yes']}
        confirmOnClick={() => backPreviousStep()}
        status="info"
      />

      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Grid container>
            <Grid container spacing={4}>
              <Grid item md={6}>
                <BoxAlert title="Atenção" text={textBox1} />
              </Grid>
              <Grid item md={6}>
                <BoxAlert title="Atenção" text={textBox2} />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={12} style={{ paddingTop: 24 }}>
                <BoxAlert title="Atenção" text={textBox3} />
              </Grid>
            </Grid>
            <Grid
              item
              md={12}
              sm={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            />

            <Grid item md={12} style={{ paddingTop: 24 }}>
              <NewEditor
                onEditorChange={(evt, editor) => setFullDescription(editor)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <ButtonsContainer>
        <Grid container spacing={2}>
          <Grid item md={3} xs={12}>
            <Button
              btnType="blue"
              width="100%"
              fontWeight="600"
              onClick={() => setShowModalBackFirstStep(true)}
            >
              {intl.messages['buttons.toBack']}
            </Button>
          </Grid>
          <Grid item md={3} sm={12} />
          <Grid item md={3} xs={12}>
            <Button
              btnType="secondary"
              width="100%"
              borderRadius="4px"
              fontWeight="600"
              onClick={() => setShowModalCancel(true)}
            >
              {intl.messages['buttons.cancel']}
            </Button>
          </Grid>

          <Grid item md={3} sm={12}>
            <Button
              btnType="primary"
              width="100%"
              borderRadius="4px"
              fontWeight="600"
              onClick={() => saveStepTwo()}
            >
              {intl.messages['buttons.toSaveAndAdvance']}
            </Button>
          </Grid>
        </Grid>
      </ButtonsContainer>
    </Container>
  );
};

export default ArticlesSecondStep;
