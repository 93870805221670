import StepProgress from 'components/contexts/StepProgress/StepProgress';
import FormContainerSecondary from 'components/structure/FormContainerSecondary/FormContainerSecondary';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getPermission } from 'utils/permissions';
import PropTypes from 'prop-types';
import ArticlesFirstStepView from '../../components/contexts/articles/ArticlesFirstStepView/ArticlesFirstStepView';
import ArticlesSecondStepView from '../../components/contexts/articles/ArticlesSecondStepView/ArticlesSecondStepView';
import ArticlesThirdStepView from '../../components/contexts/articles/ArticlesThirdStepView/ArticlesThirdStepView';
import { Container, StepContainer } from './Articles.styles';

const ArticlesView = ({ location }) => {
  const history = useHistory();
  const { permissions } = useSelector((state) => state.auth);
  const { activeStep } = useSelector((state) => state.articles);
  const steps = ['ARTIGO', 'CONTEÚDO', 'RELACIONADOS'];

  useEffect(() => {
    if (getPermission('articles', permissions, 'access') === false) {
      history.push('/');
    }
  }, [history, permissions]);

  return (
    <Container>
      <StepContainer>
        <StepProgress activeStep={activeStep} steps={steps} />
      </StepContainer>
      <FormContainerSecondary title="Visualizar artigos">
        {activeStep === 0 && <ArticlesFirstStepView state={location.state} />}
        {activeStep === 1 && <ArticlesSecondStepView state={location.state} />}
        {activeStep === 2 && <ArticlesThirdStepView state={location.state} />}
      </FormContainerSecondary>
    </Container>
  );
};

ArticlesView.propTypes = {
  location: PropTypes.object,
};

ArticlesView.defaultProps = {
  location: {},
};

export default ArticlesView;
