import { yupResolver } from '@hookform/resolvers/yup';
import InputPassword from 'components/form/Text/InputPassword/InputPassword';
import InputText from 'components/form/Text/InputText/InputText';
import BackgroundImageContainer from 'components/structure/BackgroundImageContainer/BackgroundImageContainer';
import Button from 'components/structure/Button/Button';
import { removeStorage } from 'lib/contexts/auth';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Body,
  ButtonContent,
  Container,
  ForgotPasswordLink,
} from './Login.styles';
import { defaultValues, schema } from './Login.validations';
import { login } from 'store/auth/auth-slice';

const Login = () => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
    shouldFocusError: true,
  });

  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const { authenticated } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.loading);

  const LoginSubmit = (data) => {
    removeStorage();
    dispatch(login(data));
  };

  useEffect(() => {
    if (authenticated) {
      history.push('/');
    }
  }, [history, authenticated]);

  function validate(obj) {
    if (obj === null || obj === undefined) {
      return false;
    }
    return true;
  }

  return (
    <BackgroundImageContainer loading={loading}>
      <Container>
        <Body>
          <form onSubmit={handleSubmit(LoginSubmit)}>
            <InputText
              type="email"
              name="email"
              error={errors.email}
              isError={validate(errors.email)}
              register={register}
            >
              {intl.messages['labelsInputs.login']}
            </InputText>

            <InputPassword
              id="password"
              name="password"
              error={errors}
              isError={validate(errors.password)}
              register={register}
              hookForm
            >
              {intl.messages['labelsInputs.password']}
            </InputPassword>
            <ForgotPasswordLink
              onClick={() => history.push('/forgot-password')}
            >
              Esqueceu a senha?
            </ForgotPasswordLink>
            <ButtonContent>
              <Button
                type="submit"
                btnType="primary"
                width="181px"
                height="48px"
                uppercase
                fontWeight="600"
              >
                {intl.messages['buttons.enter']}
              </Button>
            </ButtonContent>
          </form>
        </Body>
      </Container>
    </BackgroundImageContainer>
  );
};

export default Login;
