import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding-right: 20px;

  ${({ theme }) => css`
    span {
      font-weight: 400;
      font-size: ${theme.typography.sizes.sm};
      color: ${theme.colors.secondary.default};
      padding-right: 4px;
    }
  `}
`;

export const SelectGroup = styled.div`
  ${({ theme }) => css`
    z-index: 1;
    position: relative;
    width: auto;
    height: 39px;
    border: 1px solid ${theme.colors.gray.lighter};
    border-radius: 4px;
  `}
`;

export const SelectGroupBox = styled.button`
  ${({ theme }) => css`
    background-color: ${theme.colors.gray.lighter};
    width: 100%;
    height: 100%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    outline: none;
    padding: 0 5px 1px 0;
    transition-property: background-color;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
    span {
      color: ${theme.colors.secondary.dark};
      font-size: ${theme.typography.sizes.sm};
      display: inline-block;
    }

    svg {
      fill: ${theme.colors.tertiary.light};
      margin-left: 8px;
    }

    svg > path {
      stroke: ${theme.colors.gray.lighter};
    }
  `}
`;

export const SelectGroupDropdown = styled.div`
  ${({ theme }) => css`
    text-size-adjust: none;
    background-color: white;
    right: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.08), 0px 2px 3px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    max-height: 200px;
    overflow: auto;
    z-index: 2;
    transition: opacity .5s cubic-bezier(.5, 0, 0, 1);

    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar:hover{
      width: 8px;
    }
    ::-webkit-scrollbar-thumb:hover {
      width: 8px;
    }
    ::-webkit-scrollbar-track:hover {
      width: 8px;
    }
    :hover::-webkit-scrollbar-corner, :hover::-webkit-scrollbar-track {
      width: 8px;
    }
    ::-webkit-scrollbar-track{
      border-radius: 1px;
      background: ${theme.colors.background.light.button};
    }
    ::-webkit-scrollbar-thumb {
      background: ${theme.colors.gray.lighter};
    }
    > ul{
      m: 0,
      p: 0,
      list-style: none outside none;
      li {
        position: relative;
        width: 100%;
        display: block;
        &.active button, :hover button, :focus button {
          background-color: ${theme.colors.background.light.sidebar};
        }
        button {
          background-color: transparent;
          font-size: 1;
          line-height: 1.4;
          text-align: left;
          width: 100%;
          min-height: 40px;
          border: none;
          cursor: pointer;
          outline: none;
          padding: 0 10px 1px;
          transition: background-color .5s cubic-bezier(.5, 0, 0, 1);
        }
      }
    }
    &.omnisearch > ul > li > button > span {
      color: ${theme.colors.secondary.dark};
    }

    ${({ active }) =>
      active &&
      css`
        opacity: 1;
        pointer-events: initial;
      `};
      ${({ isFooter }) =>
        isFooter &&
        css`
          position: relative;
          top: calc(100% + -224px);
        `};


  ${({ topDropdown }) =>
    topDropdown &&
    css`
      top: unset;
      bottom: calc(100% + 5px);
    `};
`}
`;
