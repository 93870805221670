/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { BASES } from 'constants/endpoints';
import api from 'services/api';
import { errorSessionToast, errorToast, successToast } from 'utils/toasts';

import { startLoading, stopLoading } from 'store/loading/loading-slice';

const INITIAL_STATE = {
  bases: {
    totalPage: 1,
    totalItems: 0,
    items: [],
  },
  base: {
    id: 0,
    name: '',
    active: null,
  },
  selectedBase: 0,
  openStatusModal: false,
  status: '',
  msg: {
    title: '',
    text: '',
  },
  openModalCreate: false,
  openModalEdit: false,
  openModalDelete: false,
};

const basesSlice = createSlice({
  name: 'bases',
  initialState: INITIAL_STATE,
  reducers: {
    getBasesSuccess: (state, { payload }) => {
      state.bases = payload;
    },
    getBaseSuccess: (state, { payload }) => {
      state.base = payload;
    },

    statusBasesModal: (state, { payload }) => {
      state.openStatusModal = payload;
    },
    setBasesStatus: (state, { payload }) => {
      state.status = payload;
    },
    setMsg: (state, { payload }) => {
      state.msg = payload;
    },

    setSelectBase: (state, { payload }) => {
      state.selectedBase = payload;
    },

    openBaseModalCreate: (state, { payload }) => {
      state.openModalCreate = payload;
    },
    openBaseModalEdit: (state, { payload }) => {
      state.openModalEdit = payload;
    },
    openBaseModalDelete: (state, { payload }) => {
      state.openModalDelete = payload;
    },
    getDeletedBasesSuccess: (state, { payload }) => {
      state.bases = payload;
    },
  },
});
export const bases = basesSlice.reducer;
export const {
  getBaseSuccess,
  getBasesSuccess,
  getDeletedBasesSuccess,
  openBaseModalCreate,
  openBaseModalDelete,
  openBaseModalEdit,
  setBasesStatus,
  setMsg,
  setSelectBase,
  statusBasesModal,
} = basesSlice.actions;

export const getBasesList = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { currentPage, searchTerm, perPage, ordenation } = payload;
  try {
    let url = '';
    if (searchTerm === '') {
      url = `${BASES}?perPage=${
        perPage || '10'
      }&page=${currentPage}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}`;
    } else {
      url = `${BASES}?perPage=${
        perPage || '10'
      }&page=${currentPage}&name=${searchTerm}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}`;
    }
    const response = await api.get(url);
    dispatch(getBasesSuccess(response.data));

    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getDeletedBasesList = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { page, searchTerm, perPage, ordenation } = payload;
  try {
    let url = '';
    if (searchTerm === '') {
      url = `${BASES}?perPage=${perPage || '10'}&page=${page}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}&trash=true`;
    } else {
      url = `${BASES}?perPage=${
        perPage || '10'
      }&page=${page}&name=${searchTerm}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}&trash=true`;
    }
    const response = await api.get(url);
    dispatch(getDeletedBasesSuccess(response.data));

    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getBaseDetail = (payload) => async (dispatch) => {
  const { id, deleteSearch } = payload;
  dispatch(startLoading());
  try {
    const response = await api.get(`${BASES}/${id}`);

    dispatch(getBaseSuccess(response.data));

    dispatch(stopLoading());
    if (deleteSearch === 'delete') {
      dispatch(openBaseModalDelete(true));
    } else if (deleteSearch === 'edit') {
      dispatch(openBaseModalEdit(true));
    }
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getDeletedBaseDetail = (payload) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await api.get(`${BASES}/${payload}?trash=true`);
    dispatch(getBaseSuccess(response.data));

    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const saveBase = (payload) => async (dispatch) => {
  const { baseData, currentPage, searchTerm, perPage, ordenation } = payload;
  dispatch(startLoading());
  try {
    await api.post(BASES, baseData);
    const msg = {
      title: 'Base salva com sucesso!',
      text: 'Base salva com sucesso!',
    };
    dispatch(openBaseModalCreate(false));

    dispatch(setMsg(msg));

    dispatch(setBasesStatus('success'));
    dispatch(stopLoading());
    dispatch(getBasesList({ currentPage, searchTerm, perPage, ordenation }));
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Erros de Validação',
            text: 'Verifique os campos e tente novamente!',
          };
          dispatch(setMsg(msg));
          dispatch(setBasesStatus('error'));
          dispatch(statusBasesModal(true));
          break;
        case 409:
          msg = {
            title: 'Base já existente',
            text: 'Base já existente',
          };
          dispatch(setMsg(msg));

          dispatch(setBasesStatus('error'));
          dispatch(statusBasesModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const editBase = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { baseData, baseId } = payload;
  try {
    await api.patch(`${BASES}/${baseId}`, baseData);

    successToast('Base editada com sucesso');
    dispatch(stopLoading());
    dispatch(
      getBasesList({
        currentPage: 1,
        searchTerm: '',
        perPage: '10',
        ordenation: '',
      }),
    );
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Falha no Processamento!',
            text: 'Não foi possível alterar a base, tente novamente!',
          };
          dispatch(setMsg(msg));

          dispatch(setBasesStatus('error'));
          dispatch(statusBasesModal(true));
          break;
        case 409:
          msg = {
            title: 'Base já existente',
            text: 'Base já existente',
          };
          dispatch(setMsg(msg));

          dispatch(setBasesStatus('error'));
          dispatch(statusBasesModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const removeBase = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { id, currentPage, searchTerm, perPage, ordenation } = payload;
  try {
    await api.delete(`${BASES}/${id}`);
    const msg = {
      title: 'Base removida com sucesso!',
      text: 'Base removida com sucesso!',
    };
    dispatch(setMsg(msg));

    dispatch(openBaseModalDelete(false));
    dispatch(stopLoading());
    dispatch(setBasesStatus('success'));
    dispatch(statusBasesModal(true));
    dispatch(getBasesList({ currentPage, searchTerm, perPage, ordenation }));
  } catch (error) {
    dispatch(stopLoading());

    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          dispatch(setBasesStatus('error'));
          dispatch(statusBasesModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};
