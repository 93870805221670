import React, { useState } from 'react';
import PropTypes from 'prop-types';
import externalIcon from 'assets/icons/open-in-new.svg';
import arrayToString from 'utils/arrayToString';
import { LinkContainer, LinkTitle, LinkItem } from './ExternalLink.styles';

const ExternalLink = ({
  slug,
  plataform,
  articleLink,
  articleSlug,
  published,
}) => {
  const [showIcon, setShowIcon] = useState(true);
  const getRedirectLink = () => {
    const suvinilPath = process.env.REACT_APP_BLOG_BASE_PATH;
    const glasuPath = process.env.REACT_APP_GLASU_BASE_PATH;

    const selectArticlePath = {
      glasu: (path, glasuSlug) => `${path}busca?term=${glasuSlug}`,
      institucional: (path, institutionalSlug) =>
        `${path}busca?q=${institutionalSlug}`,
    };

    const selectContentPath = {
      glasu: (path, glasuSlug) => `${path}dicas-e-tutoriais/${glasuSlug}`,
      institucional: (path, institutionalSlug) =>
        `${path}blog/${institutionalSlug}`,
    };

    const platforms = ['glasu', 'institucional'];
    const platform = arrayToString(plataform);

    let string = '';
    if (platforms.includes(platform)) {
      const path = platform === 'glasu' ? glasuPath : suvinilPath;
      string = articleLink
        ? selectArticlePath[platform](path, articleSlug || slug)
        : selectContentPath[platform](path, articleSlug || slug);
    } else {
      setShowIcon(false);
    }

    return string;
  };

  return (
    <LinkContainer>
      <LinkTitle>{articleSlug || slug}</LinkTitle>
      {showIcon && published && (
        <LinkItem href={getRedirectLink()} target="_blank" rel="noreferrer">
          <img
            alt={articleLink ? articleSlug : slug}
            src={externalIcon}
            title={getRedirectLink()}
          />
        </LinkItem>
      )}
    </LinkContainer>
  );
};

export default ExternalLink;

ExternalLink.propTypes = {
  slug: PropTypes.string.isRequired,
  plataform: PropTypes.array.isRequired,
  articleLink: PropTypes.bool,
  articleSlug: PropTypes.string,
  published: PropTypes.bool,
};

ExternalLink.defaultProps = {
  articleLink: false,
  articleSlug: '',
  published: true,
};
