import Grid from '@material-ui/core/Grid';
import { ReactComponent as AddPropertyIcon } from 'assets/icons/addProperty.svg';
import { ReactComponent as TrashPropertyIcon } from 'assets/icons/trashProperty.svg';
import { BASES, COLORS, SIZES } from 'constants/endpoints';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { systemColors } from '../../../pages/sku/SkuStep/Edit/dataSkusOptions';
import AsyncSelectPaginate from '../../form/Select/AsyncSelectPaginate/AsyncSelectPaginate';
import SelectSearch from '../../form/Select/ReactSelect';
import InputText from '../../form/Text/TextInput/InputText';
import IconButton from '../../structure/IconButton/IconButton';
import {
  Container,
  ContainerButtons,
  ContentButton,
  Header,
} from './SkuProperties.styles';

const SkuProperties = ({
  baseList,
  setBaseList,
  sizeList,
  setSizeList,
  statusForm,
}) => {
  const intl = useIntl();

  const handleAdd = (identifier) => {
    if (identifier === 'base')
      setBaseList([...baseList, { base: '', systemColor: '', colors: [] }]);
    else
      setSizeList([
        ...sizeList,
        { size: '', width: '', height: '', length: '' },
      ]);
  };

  const handleRemove = (index, identifier) => {
    const list = identifier === 'base' ? [...baseList] : [...sizeList];
    list.splice(index, 1);

    if (identifier === 'base') setBaseList(list);
    else setSizeList(list);
  };

  const handleChange = (index, value, objIdentifier, identifier) => {
    const list = identifier === 'base' ? [...baseList] : [...sizeList];

    list[index][`${objIdentifier}`] = value;

    if (identifier === 'base') setBaseList(list);
    else setSizeList(list);
  };
  const baseXML = (
    <Grid>
      <Header>Base</Header>
      <Container>
        {baseList.map((baseItem, index) => (
          <Grid container spacing={2} className="selectContainer">
            <Grid item md={3}>
              <AsyncSelectPaginate
                label={intl.messages['labelsInputs.baseSku']}
                value={baseItem.base}
                url={BASES}
                perPage={50}
                handleChange={(value) =>
                  handleChange(index, value, 'base', 'base')
                }
                placeholder="Selecione"
                title={intl.messages['labelsInputs.baseSku']}
                error={
                  statusForm.type === 'error'
                    ? 'Campo Base deve ser preenchido'
                    : ''
                }
                isError={baseItem.base === '' && statusForm.type === 'error'}
              />
            </Grid>
            <Grid item md={3} className="systemColor">
              <SelectSearch
                label={intl.messages['labelsInputs.systemColorSku']}
                placeholder={intl.messages['labelsInputs.select']}
                options={systemColors}
                defaultOption={baseItem.systemColor}
                handleChange={(value) =>
                  handleChange(index, value, 'systemColor', 'base')
                }
                error={
                  statusForm.type === 'error'
                    ? 'Campo Sistema de cor deve ser preenchido'
                    : ''
                }
                isError={!baseItem.systemColor && statusForm.type === 'error'}
              />
            </Grid>
            <Grid item md={3}>
              <AsyncSelectPaginate
                label={intl.messages['labelsInputs.colorSku']}
                value={baseItem.colors}
                url={COLORS}
                perPage={50}
                placeholder="Selecione"
                handleChange={(value) =>
                  handleChange(index, value, 'colors', 'base')
                }
                multi
                title={intl.messages['labelsInputs.colorSku']}
                error={
                  statusForm.type === 'error'
                    ? 'Campo Cor deve ser preenchido'
                    : ''
                }
                isError={
                  baseItem.colors.length === 0 && statusForm.type === 'error'
                }
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <ContainerButtons>
                <ContentButton>
                  {baseList.length > 1 && (
                    <IconButton
                      btnType="secondary"
                      icon={<TrashPropertyIcon />}
                      onClick={() => handleRemove(index, 'base')}
                    />
                  )}
                </ContentButton>
                {baseList.length - 1 === index && (
                  <IconButton
                    btnType="primary"
                    style={{ minWidth: '48px' }}
                    icon={<AddPropertyIcon />}
                    onClick={() => handleAdd('base')}
                  />
                )}
              </ContainerButtons>
            </Grid>
          </Grid>
        ))}
      </Container>
    </Grid>
  );

  const sizeXML = (
    <Grid>
      <Header>Tamanhos</Header>
      <Container>
        {sizeList.map((sizeItem, index) => (
          <Grid container spacing={2} className="selectContainer">
            <Grid item md={3}>
              <AsyncSelectPaginate
                label={intl.messages['labelsInputs.sizeSku']}
                value={sizeItem.size}
                url={SIZES}
                perPage={50}
                hasPlatforms
                placeholder="Selecione"
                name="size"
                handleChange={(value) =>
                  handleChange(index, value, 'size', 'size')
                }
                error={
                  statusForm.type === 'error'
                    ? 'Campo Tamanho deve ser preenchido'
                    : ''
                }
                isError={
                  sizeItem.size.length === 0 && statusForm.type === 'error'
                }
                title={intl.messages['labelsInputs.sizeSku']}
              />
            </Grid>
            <Grid className="input" item md={2}>
              <InputText
                label="Largura"
                placeholder="000"
                type="number"
                value={sizeItem.width}
                handleChange={(value) =>
                  handleChange(index, value, 'width', 'size')
                }
              />
            </Grid>
            <Grid className="input" item md={2}>
              <InputText
                label="Altura"
                placeholder="000"
                type="number"
                value={sizeItem.height}
                handleChange={(value) =>
                  handleChange(index, value, 'height', 'size')
                }
              />
            </Grid>
            <Grid className="input" item md={3}>
              <InputText
                label="Comprimento"
                placeholder="000"
                type="number"
                value={sizeItem.length}
                handleChange={(value) =>
                  handleChange(index, value, 'length', 'size')
                }
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <ContainerButtons>
                {sizeList.length - 1 === index && (
                  <IconButton
                    btnType="primary"
                    style={{ minWidth: '48px' }}
                    icon={<AddPropertyIcon />}
                    onClick={() => handleAdd('size')}
                  />
                )}
                <ContentButton>
                  {sizeList.length > 1 && (
                    <IconButton
                      btnType="secondary"
                      icon={<TrashPropertyIcon />}
                      onClick={() => handleRemove(index, 'size')}
                    />
                  )}
                </ContentButton>
              </ContainerButtons>
            </Grid>
          </Grid>
        ))}
      </Container>
    </Grid>
  );

  return (
    <>
      {baseXML}
      {sizeXML}
    </>
  );
};

SkuProperties.propTypes = {
  baseList: PropTypes.array.isRequired,
  setBaseList: PropTypes.func.isRequired,
  sizeList: PropTypes.array.isRequired,
  setSizeList: PropTypes.func.isRequired,
  statusForm: PropTypes.object.isRequired,
};

export default SkuProperties;
