/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import InputText from 'components/form/Text/TextInput/InputText';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import SelectSearch from 'components/form/Select/ReactSelect';
import Button from 'components/structure/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonsContent, Container } from './ViewUserModalForm.styles';
import { schema } from './ViewUserModalForm.validations';

const ViewUserModalForm = ({ cancelActionModal, editActionModal, deleted }) => {
  const { selectedUser, user } = useSelector((state) => state.users);
  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const intl = useIntl();
  const dispatch = useDispatch();

  const { options } = useSelector((state) => state.roles);
  const { role } = useSelector((state) => state.auth);
  const [currentRole, setCurrentRole] = useState(user.roleId);

  return (
    <Container>
      <form onSubmit={handleSubmit(editActionModal)}>
        <Row>
          <Col md="12">
            <InputText
              name="name"
              label="Nome"
              register={register}
              defaultValue={user.name}
              hookForm
              disabled
            >
              {intl.messages['labelsInputs.typeYourName']}
            </InputText>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <InputText
              name="email"
              type="email"
              label="Email"
              register={register}
              defaultValue={user.email}
              hookForm
              disabled
            >
              {intl.messages['labelsInputs.typeYourEmail']}
            </InputText>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <SelectSearch
              label="Perfil"
              options={
                role.name === 'Admin'
                  ? options
                  : options.filter((op) => op.name !== 'Admin')
              }
              defaultOption={currentRole}
              handleChange={setCurrentRole}
              multi={false}
              tooltip
              title={intl.messages['labelsInputs.tooltipUserRole']}
              disabled
            />
          </Col>
        </Row>
        <Row>
          <ButtonsContent>
            {deleted === false && (
              <Button
                type="submit"
                btnType="primary"
                fontWeight="600"
                width="40%"
                height="44px"
                uppercase
                onClick={editActionModal}
              >
                {intl.messages['buttons.edit']}
              </Button>
            )}
            <Button
              btnType="blue"
              fontWeight="600"
              width="40%"
              height="44px"
              onClick={cancelActionModal}
              uppercase
            >
              {intl.messages['buttons.toBack']}
            </Button>
          </ButtonsContent>
        </Row>
      </form>
    </Container>
  );
};

ViewUserModalForm.propTypes = {
  cancelActionModal: PropTypes.func,
  editActionModal: PropTypes.func,
  deleted: PropTypes.bool,
};

ViewUserModalForm.defaultProps = {
  cancelActionModal: () => {},
  editActionModal: () => {},
  deleted: false,
};

export default ViewUserModalForm;
