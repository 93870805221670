import Grid from '@material-ui/core/Grid';
import SelectSearch from 'components/form/Select/ReactSelect';
import InputText from 'components/form/Text/TextInput/InputText';
import Button from 'components/structure/Button/Button';
import Modal from 'components/structure/Modal/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { types } from '../dataProperties';
import { Container } from './properties.styles';

const ViewPropertyModalForm = ({
  isOpen,
  setIsOpen,
  cancelActionModal,
  confirmActionModal,
  loading,
  deleted,
}) => {
  const intl = useIntl();
  const { property } = useSelector((state) => state.properties);

  const modalContent = (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Grid container>
            <Grid item xs={12}>
              <InputText
                placeholder={intl.messages['labelsInputs.typeYourName']}
                label={intl.messages['labelsInputs.typeYourName']}
                value={property.name}
                disabled
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                paddingTop: '21px',
              }}
            >
              <SelectSearch
                label={intl.messages['labelsInputs.type']}
                placeholder={intl.messages['labelsInputs.select']}
                options={types}
                defaultOption={property.type}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {deleted === false && (
          <Grid item md={6} xs={12}>
            <Button
              type="button"
              btnType="secondary"
              width="100%"
              height="48px"
              borderRadius="4px"
              uppercase
              onClick={cancelActionModal}
              fontWeight="600"
            >
              {intl.messages['buttons.cancel']}
            </Button>
          </Grid>
        )}
        <Grid item md={6} xs={12}>
          <Button
            btnType="primary"
            width="100%"
            height="48px"
            borderRadius="4px"
            uppercase
            fontWeight="600"
            onClick={confirmActionModal}
          >
            {deleted === false
              ? intl.messages['buttons.edit']
              : intl.messages['buttons.toBack']}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );

  return (
    <Modal
      title={intl.messages['textModal.viewProperty']}
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      content={modalContent}
      loading={loading}
    />
  );
};

ViewPropertyModalForm.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  cancelActionModal: PropTypes.func,
  confirmActionModal: PropTypes.func,
  loading: PropTypes.number,
  deleted: PropTypes.bool,
};

ViewPropertyModalForm.defaultProps = {
  isOpen: false,
  setIsOpen: () => {},
  cancelActionModal: () => {},
  confirmActionModal: () => {},
  loading: 0,
  deleted: false,
};

export default ViewPropertyModalForm;
