/* eslint-disable no-unused-vars */
import ModalConfirmation from 'components/contexts/modal/ModalConfirmation/ModalConfirmation';
import ModalDeleteConfirmationSku from 'components/contexts/modal/ModalDeleteConfirmationSku/ModalDeleteConfirmationSku';
import ModalMultiConfirmation from 'components/contexts/modal/ModalMultiConfirmation/ModalMultiConfirmation';
import Button from 'components/structure/Button/Button';
import EmptySearch from 'components/structure/EmptySearch/EmptySearch';
import PageContent from 'components/structure/PageContent/PageContent';
import PageHeader from 'components/structure/PageHeader/PageHeader';
import PopOver from 'components/structure/PopOver/PopOver';
import ReportModal from 'components/structure/ReportModal';
import EanSkusExclusionModal from 'components/structure/EanSkusExclusionModal';
import {
  Divider,
  PopItem,
  PopOverContent,
} from 'components/structure/PopOver/PopOver.styles';
import CheckboxTable from 'components/structure/Table/CheckboxTable';
import urls from 'constants/urls';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as TrashIcon } from 'assets/icons/outlinedtrash.svg';
import tinycolor from 'tinycolor2';
import arrayToString from 'utils/arrayToString';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { getPermission } from 'utils/permissions';
import rgbToHex from 'utils/rgbToHex';
import { findTranslate } from 'utils/unitTranslated';
import { setSkuPrice } from 'store/prices/prices-slice';
import {
  dismissSkuModalDelete,
  getListSkus,
  putSkus,
  setSkuId,
  statusSkusModal,
  removeSku as deleteSku,
  openSkuModalDelete,
  deleteSkus,
} from 'store/sku/sku-slice';

import * as S from './Skus.styles';

const Skus = () => {
  const { permissions } = useSelector((state) => state.auth);
  const product = useSelector((state) => state.sku.product);
  const { items, totalPage } = useSelector((state) => state.sku.skus);
  const { openStatusModal, status, msg, openModalDelete } = useSelector(
    (state) => state.sku,
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedId, setSelectedId] = useState(null);
  const [ordenation, setOrdenation] = useState('');
  const [totalPerPage, setTotalPerPage] = useState({ value: 10 });
  const perPage = totalPerPage.value;

  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalActivate, setShowModalActivate] = useState(false);
  const [showModalDeactivate, setShowModalDeactivate] = useState(false);
  const [isOpenReportModal, setIsOpenReportModal] = useState(false);
  const [isOpenEanSkusExclusionModal, setIsOpenEanSkusExclusionModal] =
    useState(false);

  const [selected, setSelected] = useState([]);

  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const { loading } = useSelector((state) => state.loading);

  useEffect(() => {
    if (product.id === 0 || product.id === undefined) {
      history.push(urls.ROUTES.CATALOGUE.items.products.path);
    }
  }, [history, product.id]);

  useEffect(() => {
    dispatch(
      getListSkus({
        currentPage,
        searchTerm,
        productId: product.id,
        perPage,
        ordenation,
      }),
    );
  }, [
    history,
    dispatch,
    currentPage,
    searchTerm,
    product.id,
    perPage,
    ordenation,
    status,
  ]);

  const openPrices = useCallback(
    (skuId) => {
      dispatch(setSkuPrice({ id: skuId }));
      history.push(urls.ROUTES.PRICES_LIST.path);
    },
    [dispatch, history],
  );

  const addSku = () => {
    if (product.id !== 0 || product.id !== undefined) {
      history.push(urls.ROUTES.SKU_CREATE.path);
    }
  };

  const addManySkus = () => {
    if (product.id !== 0 || product.id !== undefined) {
      history.push(urls.ROUTES.SKU_CREATE_MANY.path);
    }
  };

  const editSku = useCallback(
    (skuId) => {
      setSelectedId(skuId);
      dispatch(setSkuId(skuId));
      if (product.id !== 0 || product.id !== undefined) {
        history.push(urls.ROUTES.SKU_EDIT.path);
      }
    },
    [dispatch, product.id, history],
  );

  const viewSku = useCallback(
    (skuId) => {
      setSelectedId(skuId);
      dispatch(setSkuId(skuId));
      if (product.id !== 0 || product.id !== undefined) {
        history.push({ pathname: urls.ROUTES.SKU_VIEW.path, state: 'view' });
      }
    },
    [dispatch, product.id, history],
  );

  const removeSku = useCallback(
    (skuId) => {
      setSelectedId(skuId);

      dispatch(openSkuModalDelete());
    },
    [dispatch],
  );

  const COLUMNS = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'ID VTEX',
        accessor: 'vtexSkuId',
      },
      {
        Header: 'EAN',
        accessor: 'ean',
      },
      {
        Header: 'Cor',
        accessor: ({ productToColor }) => {
          const colorRgb = rgbToHex(productToColor?.color?.rgb);
          const txtColor = tinycolor(colorRgb);

          return (
            <S.Color
              key={productToColor?.id}
              rgbCode={colorRgb || ''}
              colorText={txtColor.isLight() ? '#666666' : '#F7F7F7'}
            >
              {productToColor?.color?.name}
            </S.Color>
          );
        },
      },
      {
        Header: 'Tamanhos',
        accessor: ({ size }) => `${size?.name} ${findTranslate(size?.unit)}`,
      },
      {
        Header: 'Base',
        accessor: ({ base }) => base?.name,
      },
      {
        Header: 'Plataforma',
        accessor: ({ platforms }) =>
          capitalizeFirstLetter(arrayToString(platforms)),
      },
      {
        Header: ' ',
        accessor: ({ id }) =>
          getPermission('stock-keeping-units', permissions, 'update') ===
          false ? (
            <></>
          ) : (
            <PopOver
              content={
                <PopOverContent>
                  <PopItem onClick={() => editSku(id)}>
                    {intl.messages['buttons.edit']}
                  </PopItem>
                  {getPermission(
                    'stock-keeping-units',
                    permissions,
                    'delete',
                  ) && (
                    <>
                      <Divider />
                      <PopItem onClick={() => removeSku(id)}>
                        {intl.messages['buttons.exclusion']}
                      </PopItem>
                    </>
                  )}
                  {getPermission(
                    'stock-keeping-units',
                    permissions,
                    'access',
                  ) && (
                    <>
                      <Divider />
                      <PopItem onClick={() => viewSku(id)}>
                        Ver Detalhes
                      </PopItem>
                    </>
                  )}
                  {/**
                  * ? Removido momentaneamente
                  getPermission('prices', permissions, 'list') && (
                    <>
                      <Divider />
                      <PopItem onClick={() => openPrices(id)}>
                        Ver Preço
                      </PopItem>
                    </>
                  ) */}
                </PopOverContent>
              }
            />
          ),
        sticky: 'right',
      },
    ],
    [editSku, intl.messages, permissions, removeSku],
  );

  const data = useMemo(() => items, [items]);

  const optionsOrdenation = [
    { value: 'id', label: 'ID' },
    { value: 'ean', label: 'EAN' },
    { value: 'productToColorId', label: 'Cor' },
    { value: 'platforms', label: 'Plataforma' },
    { value: 'updatedAt', label: 'Data de atualização' },
  ];

  return (
    <>
      <ModalDeleteConfirmationSku
        label={intl.messages['labelsInputs.idSku']}
        title={intl.messages['textModal.areYouSure']}
        text={intl.messages['textModal.deleteSkuConfirm']}
        placeholder={intl.messages['labelsInputs.idSku']}
        buttonName={intl.messages['buttons.delete']}
        setIsOpen={() => dispatch(dismissSkuModalDelete())}
        isOpen={openModalDelete}
        fieldValue={selectedId}
        fieldLabel={selectedId}
        confirmOnClick={() =>
          dispatch(
            deleteSku({
              id: selectedId,
              page: currentPage,
              searchTerm,
              productId: product.id,
            }),
          )
        }
      />
      <ModalConfirmation
        title={intl.messages['textModal.areYouSure']}
        buttonName={intl.messages['buttons.delete']}
        text="Ao pressionar deletar, os SKUs selecionados serão deletados"
        setIsOpen={setShowModalDelete}
        isOpen={showModalDelete}
        confirmOnClick={() => {
          const newArray = selected.map((item) => item.original.id);
          const payload = {
            ids: newArray,
          };
          dispatch(deleteSkus(payload));
          if (status === 'success') {
            setShowModalDelete(false);
          }
          dispatch(
            getListSkus({
              currentPage,
              searchTerm,
              productId: product.id,
              perPage,
              ordenation,
            }),
          );
        }}
        loading={loading}
      />
      <ModalConfirmation
        title={intl.messages['textModal.areYouSure']}
        buttonName={intl.messages['buttons.activate']}
        text="Ao pressionar ativar, os SKUs selecionados serão ativados"
        setIsOpen={setShowModalActivate}
        isOpen={showModalActivate}
        confirmOnClick={() => {
          const newArray = selected.map((item) => item.original.id);
          const payload = {};
          payload.actions = newArray.map((item) => ({
            id: item,
            active: 'true',
          }));
          dispatch(putSkus(payload));
          if (status === 'success') {
            setShowModalActivate(false);
          }
        }}
        loading={loading}
      />
      <ModalConfirmation
        title={intl.messages['textModal.areYouSure']}
        buttonName={intl.messages['buttons.deactivate']}
        text="Ao pressionar desativar, os SKUs selecionados serão desativados"
        setIsOpen={setShowModalDeactivate}
        isOpen={showModalDeactivate}
        confirmOnClick={() => {
          const newArray = selected.map((item) => item.original.id);
          const payload = {};
          payload.actions = newArray.map((item) => ({
            id: item,
            active: 'false',
          }));
          dispatch(putSkus(payload));
          if (status === 'success') {
            setShowModalDeactivate(false);
          }
        }}
        loading={loading}
      />
      <ModalMultiConfirmation
        title={msg.title}
        text={msg.text}
        setIsOpen={(payload) => {
          dispatch(statusSkusModal(payload));
        }}
        isOpen={openStatusModal}
        status={status}
      />
      <ReportModal
        isOpen={isOpenReportModal}
        setIsOpen={setIsOpenReportModal}
        title="Período do relatório de SKUs"
        entity="sku"
        id={product.id}
      />
      <EanSkusExclusionModal
        isOpen={isOpenEanSkusExclusionModal}
        setIsOpen={setIsOpenEanSkusExclusionModal}
        onSuccessCallback={() => {
          history.push(urls.ROUTES.CATALOGUE.items.products.path);
        }}
      />
      <PageHeader
        title={`SKUs de ${product.name} (ID: ${product.id})`}
        search
        searchFunc={(searchData) => setSearchTerm(searchData)}
        placeholder="Busque por EAN"
        optionsOrdenation={optionsOrdenation}
        ordenation={ordenation}
        setOrdenation={setOrdenation}
        actions={
          !getPermission('products', permissions, 'create')
            ? []
            : [
                {
                  name: 'RELATORIOS',
                  onClick: () => {
                    setIsOpenReportModal(true);
                  },
                  btnType: 'outlined',
                },
                {
                  name: 'NOVO SKU',
                  onClick: addSku,
                  btnType: 'primary',
                },
              ]
        }
        secondaryMultiAction={
          selected.length !== 0
            ? {
                primary: {
                  label: 'EXCLUIR SELECIONADOS',
                  function: () => {
                    if (getPermission('products', permissions, 'delete'))
                      setShowModalDelete(true);
                  },
                },
                secondary: {
                  label: 'ATIVAR SELECIONADOS',
                  function: () => {
                    if (getPermission('products', permissions, 'update'))
                      setShowModalActivate(true);
                  },
                },
                tertiary: {
                  label: 'DESATIVAR SELECIONADOS',
                  function: () => {
                    if (getPermission('products', permissions, 'update'))
                      setShowModalDeactivate(true);
                  },
                },
              }
            : null
        }
      />

      <PageContent>
        <EmptySearch items={data} term={searchTerm}>
          <CheckboxTable
            columns={COLUMNS}
            data={data}
            pageCount={totalPage}
            setPageCurrent={setCurrentPage}
            isColorTable
            totalPerPage={totalPerPage}
            setTotalPerPage={setTotalPerPage}
            setSelected={setSelected}
            hasCheckbox
          />
        </EmptySearch>

        <S.FooterButtons>
          <Button
            className="eanExclusionButton"
            btnType="link"
            icon={<TrashIcon />}
            fontWeight="600"
            height="24px"
            onClick={() => setIsOpenEanSkusExclusionModal(true)}
          >
            {intl.messages['buttons.exclusion']}{' '}
            {intl.messages['labelsInputs.eanSku']}
          </Button>

          <Button
            className="backbutton"
            btnType="blue"
            width="20%"
            fontWeight="600"
            onClick={() =>
              history.push(urls.ROUTES.CATALOGUE.items.products.path)
            }
          >
            {intl.messages['buttons.toBack']}
          </Button>
        </S.FooterButtons>
      </PageContent>
    </>
  );
};

export default Skus;
