import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${({ paddingTop }) => (paddingTop ? '16px' : '0px')};

  .error-icon-textarea {
    display: flex;
    justify-content: flex-end;
    margin-left: 92%;
    margin-top: -27px;
    padding: 0;
    z-index: 1;
    background: transparent;
    width: 20px;
    height: 20px;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 4px;

  img {
    padding-left: 5px;
    cursor: pointer;
  }
`;

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.colors.secondary.default};
    font-weight: 400;
    font-size: ${theme.typography.sizes.sm};
    line-height: 17px;
    align-items: center;

    ${({ isError }) =>
      isError && `color: ${theme.colors.contextual.error.default}`}
  `}
`;

export const TextareaStyle = styled.textarea`
  ${({ theme, heigth }) => css`
    resize: none;
    position: relative;
    margin: 0;
    outline: 0;
    font-family: ${theme.typography.family.primary};
    font-size: ${theme.typography.sizes.sm};
    line-height: 17px;
    padding: 0.67rem 1rem;
    background: ${theme.colors.gray.lighter};
    border: ${({ isError }) =>
      isError ? `1px solid ${theme.colors.contextual.error.default}` : 'none'};
    color: rgba(0, 0, 0, 0.87);
    border-radius: 5px;
    box-shadow: inset 0 0 0 0 transparent;
    transition: color 0.1s ease, border-color 0.1s ease;
    width: 100%;
    height: ${heigth};
    opacity: ${({ disabled }) => (disabled ? 0.5 : '')};
    -webkit-appearance: none;

    &:-webkit-autofill {
      box-shadow: 0 0 0 30px ${theme.colors.gray.lighter} inset;
    }

    /* ${({ isError }) =>
      isError &&
      `border: solid 1px ${theme.colors.contextual.error.default}`} */

    &::placeholder {
      color: #999999;
    }

    ${({ disabled }) =>
      disabled &&
      css`
        border: 1px solid ${theme.colors.background.float.solid};
        background: ${theme.colors.background.float.solid};
      `};
  `}
`;

export const CountInfo = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.typography.family.primary};
    font-weight: 400;
    font-size: ${theme.typography.sizes.sm};
    line-height: 18px;
    color: ${theme.colors.secondary.dark};
    text-align: right;
    padding-top: 5px;
  `}
`;

export const OptionalStyle = styled.p`
  ${({ theme }) => css`
    font-weight: 400;
    font-size: ${theme.typography.sizes.sm};
    line-height: 17px;
    align-items: center;
    color: ${theme.colors.black.light};
    padding-left: 5px;
  `}
`;
