import styled, { css } from 'styled-components';
import Modal from 'components/structure/Modal/Modal';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ImageContainer = styled.div`
  width: 50vw;
  height: 50vh;
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
`;

export const ImageStyle = styled.img`
  display: block;
  max-width: 100%;
  object-fit: contain;
`;

export const ContainerImageInfo = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 7px 12px;
    background-color: ${theme.colors.black.lightest2};

    > span {
      font-size: 12px;
      line-height: 150%;
      font-family: ${theme.typography.family.primary};
      font-weight: 700;
      color: ${theme.colors.secondary.default};
      align-self: center;
    }

    > p {
      font-size: 12px;
      line-height: 150%;
      font-family: ${theme.typography.family.primary};
      color: ${theme.colors.secondary.default};
    }
  `}
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 20px;

  label {
    cursor: pointer;
    input {
      display: none;
    }
  }
`;

export const Separator = styled.div`
  padding: 0 20px;
`;

export const ChangeFileButton = styled.label`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: ${theme.colors.white.default};
    padding: 8px;
    font-size: 0.85rem;
    line-height: 16px;
    outline: 0;
    border: none;
    white-space: nowrap;
    text-decoration: none;
    width: ${({ width }) => `${width}`};
    height: ${({ height }) => (height ? `${height}` : '48px')};
    font-family: ${theme.typography.family.primary};
    font-weight: ${({ fontWeight }) =>
      fontWeight ? `${fontWeight}` : 'normal'};
    text-transform: uppercase;
    border-radius: ${({ borderRadius }) =>
      borderRadius ? `${borderRadius}` : '4px'};
    background: ${theme.colors.gray.light};
    transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
      box-shadow 0.1s ease, background 0.1s ease;

    &:hover {
      background: ${theme.colors.black.lightest2};
    }

    ${({ btnType }) =>
      btnType === 'primary' &&
      css`
        background-color: ${theme.colors.primary.default};

        &:hover {
          background-color: ${theme.colors.background.dark.button};
        }
      `};

    ${({ btnType }) =>
      btnType === 'danger' &&
      css`
        background-color: ${theme.colors.contextual.error.dark};

        &:hover {
          background-color: ${theme.colors.contextual.error.hover};
        }
      `};

    ${({ btnType }) =>
      btnType === 'secondary' &&
      css`
        background-color: ${theme.colors.gray.light};
        color: ${theme.colors.tertiary.default};

        &:hover {
          background-color: ${theme.colors.gray.default};
        }
      `};

    ${({ btnType }) =>
      btnType === 'light' &&
      css`
        background-color: ${theme.colors.gray.lighter};
        color: ${theme.colors.tertiary.default};

        &:hover {
          background-color: ${theme.colors.gray.default};
        }
      `};

    ${({ btnType }) =>
      btnType === 'blue' &&
      css`
        background-color: #04456b;
        color: ${theme.colors.gray.lighter};

        &:hover {
          background-color: #465368;
        }
      `};

    ${({ btnType }) =>
      btnType === 'ghost' &&
      css`
        background-color: #fff;
        color: #f76902;
        border: 1px solid #f76902;

        &:hover {
          background-color: ${theme.colors.gray.default};
        }
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        cursor: not-allowed;
        background-color: #bbb;

        &:hover {
          background-color: #bbb;
        }
      `};

    > img {
      margin-right: 12px;
    }
  `}
`;

export const AbstractModal = styled(Modal)`
  width: 100%;
`;
