/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  preventRedirect: false,
  openModal: false,
  targetPath: '/',
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: INITIAL_STATE,
  reducers: {
    preventRedirectEnabled: (state) => {
      state.preventRedirect = true;
    },
    preventRedirectDisabled: (state) => {
      state.preventRedirect = false;
    },
    setTargetPath: (state, { payload }) => {
      state.targetPath = payload;
    },
    setOpenSidebarModal: (state, { payload }) => {
      state.openModal = payload;
    },
    resetSidebarState: (state) => {
      state.preventRedirect = false;
      state.openModal = false;
    },
  },
});

export const sidebar = sidebarSlice.reducer;
export const {
  preventRedirectDisabled,
  preventRedirectEnabled,
  resetSidebarState,
  setOpenSidebarModal,
  setTargetPath,
} = sidebarSlice.actions;
