import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/structure/Modal/Modal';
import Button from 'components/structure/Button/Button';
import BoxAlert from 'components/structure/BoxAlert/BoxAlert';
import {
  Container,
  ImageContainer,
  ImageStyle,
  ContainerImageInfo,
  BtnContainer,
  Separator,
  ChangeFileButton,
} from './ImageModal.styles';

const setImageSize = (setImageDimensions, imageUrl) => {
  const img = new Image();
  img.src = imageUrl;

  img.onload = () => {
    setImageDimensions({
      height: img.height,
      width: img.width,
    });
  };
};

const ImageModal = ({ imgUrl, isOpen, setIsOpen, isPreview, actions }) => {
  const [imageDimensions, setImageDimensions] = useState({});
  const filename = imgUrl.substring(imgUrl.lastIndexOf('/') + 1);

  useEffect(() => {
    setImageSize(setImageDimensions, imgUrl);
  }, [imgUrl, imageDimensions]);

  const modalContent = (
    <Container>
      <BoxAlert
        title="Atenção"
        text="A imagem está redimensionada e seu tamanho pode diferir do tamanho usado na aplicação."
      />
      <br />
      <ImageContainer>
        <ImageStyle src={imgUrl} alt={imgUrl} />
      </ImageContainer>

      <ContainerImageInfo>
        {Object.keys(imageDimensions).length === 0 ? (
          <span>Carregando informações da imagem...</span>
        ) : (
          <>
            <p>
              <strong>Nome:</strong> {filename}
            </p>
            <p>
              <strong>Largura:</strong> {imageDimensions.width}px
            </p>
            <p>
              <strong>Altura:</strong> {imageDimensions.height}px
            </p>
          </>
        )}
      </ContainerImageInfo>
      {isPreview && (
        <BtnContainer>
          <ChangeFileButton
            htmlFor="change-file"
            btnType="ghost"
            width="140px"
            borderRadius="4px"
            fontWeight="600"
          >
            ALTERAR
            <input
              id="change-file"
              name="file"
              type="file"
              onChange={actions.changeFile}
              accept="image/*"
            />
          </ChangeFileButton>

          <Separator />
          <Button
            btnType="primary"
            width="140px"
            borderRadius="4px"
            onClick={actions.saveFile}
            fontWeight="600"
          >
            CONFIRMAR
          </Button>
        </BtnContainer>
      )}
    </Container>
  );

  return (
    <Container>
      <Modal
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        content={modalContent}
        containerWidth="100%"
        containerHeight="100%"
        zIndex="9999"
      />
    </Container>
  );
};

ImageModal.propTypes = {
  imgUrl: PropTypes.string,
  setIsOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  isPreview: PropTypes.bool,
  actions: PropTypes.shape({
    changeFile: PropTypes.func,
    saveFile: PropTypes.func,
  }),
};

ImageModal.defaultProps = {
  imgUrl: '',
  setIsOpen: () => {},
  isOpen: false,
  isPreview: false,
  actions: {},
};

export default ImageModal;
