/* eslint-disable no-unused-vars */
import { useRowSelectColumn } from '@lineup-lite/hooks';
import FooterTable from 'components/structure/FooterTable/FooterTable';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import {
  ContainerTable,
  Table,
  TableBody,
  TableHead,
  Td,
  Th,
  Tr,
} from './Table.styles';

const CheckboxTable = ({
  columns,
  data,
  pageCount,
  setPageCurrent,
  footerAttrs,
  isColorTable,
  defaultCurrentPage,
  totalPerPage,
  setTotalPerPage,
  noCheckBox,
  setSelected,
}) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    gotoPage,
    setPageSize,
    prepareRow,
    state: { selectedRowIds },
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      pageCount,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    !noCheckBox && useRowSelectColumn,
  );

  const { pageIndex } = state;

  useEffect(() => {
    setPageSize(totalPerPage.value);
  }, [totalPerPage.value, setPageSize]);

  useEffect(() => {
    setSelected(selectedFlatRows);
  }, [selectedFlatRows]);

  return (
    <ContainerTable>
      <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {column.id === 'selection' && column.render('Summary')}
                </Th>
              ))}
            </Tr>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <Td {...cell.getCellProps()} isColorTable={isColorTable}>
                      {cell.render('Cell')}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </TableBody>
      </Table>
      <div>
        <FooterTable
          onPageChange={(newPage) => {
            gotoPage(newPage);
            setPageCurrent(newPage);
          }}
          totalPages={pageCount}
          currentPage={defaultCurrentPage || 1}
          totalItems={tableData.length}
          onChangePageSize={(option) => {
            setTotalPerPage(option);
            setPageSize(option.value);
          }}
          style={{ marginTop: '1em' }}
          {...footerAttrs}
        />
      </div>
    </ContainerTable>
  );
};

CheckboxTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  pageCount: PropTypes.number,
  setPageCurrent: PropTypes.func,
  footerAttrs: PropTypes.object,
  isColorTable: PropTypes.bool,
  defaultCurrentPage: PropTypes.number,
  totalPerPage: PropTypes.object,
  setTotalPerPage: PropTypes.func,
  setSelected: PropTypes.func,
  noCheckBox: PropTypes.bool,
};

CheckboxTable.defaultProps = {
  columns: [],
  data: [],
  pageCount: 1,
  setPageCurrent: () => {},
  footerAttrs: {},
  isColorTable: false,
  defaultCurrentPage: null,
  totalPerPage: null,
  setTotalPerPage: () => {},
  setSelected: () => {},
  noCheckBox: false,
};
export default CheckboxTable;
