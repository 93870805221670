import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import TableDefault from 'components/structure/Table/Table';
import PopOver from 'components/structure/PopOver/PopOver';
import PageHeader from 'components/structure/PageHeader/PageHeader';
import PageContent from 'components/structure/PageContent/PageContent';
import Modal from 'components/structure/Modal/Modal';
import Toggle from 'components/structure/ToggleTable/TogleTable';
import AddBaseModalForm from 'components/contexts/modal/AddBaseModalForm/AddBaseModalForm';
import EditBaseModalForm from 'components/contexts/modal/EditBaseModalForm/EditBaseModalForm';
import ModalDeleteConfirmation from 'components/contexts/modal/ModalDeleteConfirmation/ModalDeleteConfirmation';

import {
  PopOverContent,
  PopItem,
  Divider,
} from 'components/structure/PopOver/PopOver.styles';
import { useDispatch, useSelector } from 'react-redux';

import { getPermission } from 'utils/permissions';
import ModalMultiConfirmation from 'components/contexts/modal/ModalMultiConfirmation/ModalMultiConfirmation';
import EmptySearch from 'components/structure/EmptySearch/EmptySearch';
import { successToast } from 'utils/toasts';
import ViewBaseModalForm from 'components/contexts/modal/ViewBaseModalForm/ViewBaseModalForm';
import urls from 'constants/urls';
import { useHistory } from 'react-router-dom';
import {
  getBasesList,
  openBaseModalCreate,
  openBaseModalDelete,
  openBaseModalEdit,
  removeBase as deleteBase,
  saveBase,
  statusBasesModal,
  editBase,
} from 'store/bases/bases-slice';

const Bases = () => {
  const { items, totalPage } = useSelector((state) => state.bases.bases);
  const {
    openModalCreate,
    msg,
    openStatusModal,
    status,
    openModalDelete,
    openModalEdit,
    base,
  } = useSelector((state) => state.bases);
  const { loading } = useSelector((state) => state.loading);
  const [selectedId, setSelectedId] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [name, setName] = useState('');

  const [isActive, setIsActive] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const [showEditConfirmModal, setShowEditConfirmModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);

  const [updateName, setUpdateName] = useState(base.name);
  const [updateActive, setUpdateActive] = useState(base.active);

  const { permissions } = useSelector((state) => state.auth);
  const [searchTerm, setSearchTerm] = useState('');
  const [ordenation, setOrdenation] = useState('');
  const [totalPerPage, setTotalPerPage] = useState({ value: 10 });
  const perPage = totalPerPage.value;

  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  useEffect(() => {
    dispatch(getBasesList({ currentPage, searchTerm, perPage, ordenation }));
  }, [dispatch, currentPage, searchTerm, perPage, ordenation]);

  useEffect(() => {
    setUpdateName(base.name);
    setUpdateActive(base.active);
  }, [base]);

  const editBaseModal = useCallback(
    (id) => {
      setSelectedId(id);
      dispatch(getBasesList({ id, deleteSearch: 'edit' }));
    },
    [dispatch],
  );

  const viewBaseModal = useCallback(
    (id) => {
      setSelectedId(id);
      dispatch(getBasesList({ id, deleteSearch: 'view' }));
      setShowViewModal(true);
    },
    [dispatch],
  );

  const viewActivities = useCallback(
    (id) => {
      history.push(`${urls.ROUTES.ACCESS.items.logsByRecord.path}/${id}/bases`);
    },
    [history],
  );

  const addBaseModal = () => {
    setShowAddModal(true);
  };

  const removeBase = useCallback(
    (id) => {
      setSelectedId(id);
      dispatch(getBasesList({ id, deleteSearch: 'delete' }));
    },
    [dispatch],
  );

  const COLUMNS = useMemo(
    () => [
      { Header: 'ID', accessor: 'id' },
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Ativos',
        accessor: ({ active, id }) => (
          <Toggle
            key={id}
            checked={active}
            id={id}
            page={currentPage}
            readonly
            isNotClickable
          />
        ),
      },
      {
        Header: ' ',
        accessor: ({ id }) =>
          getPermission('bases', permissions, 'update') === false ? (
            <></>
          ) : (
            <PopOver
              content={
                <PopOverContent>
                  <PopItem onClick={() => editBaseModal(id)}>
                    {intl.messages['buttons.edit']}
                  </PopItem>
                  {getPermission('bases', permissions, 'delete') && (
                    <>
                      <Divider />
                      <PopItem onClick={() => removeBase(id)}>
                        {intl.messages['buttons.exclusion']}
                      </PopItem>
                    </>
                  )}
                  {getPermission('bases', permissions, 'access') && (
                    <>
                      <Divider />
                      <PopItem onClick={() => viewBaseModal(id)}>
                        {intl.messages['buttons.view']}
                      </PopItem>
                    </>
                  )}
                  <Divider />
                  <PopItem onClick={() => viewActivities(id)}>
                    Ver atividades
                  </PopItem>
                </PopOverContent>
              }
            />
          ),
      },
    ],
    [
      currentPage,
      editBaseModal,
      intl.messages,
      permissions,
      removeBase,
      viewBaseModal,
    ],
  );
  const data = useMemo(() => items, [items]);

  const optionsOrdenation = [
    { value: 'id', label: 'ID' },
    { value: 'name', label: 'Nome' },
  ];

  const addBaseModalForm = (
    <AddBaseModalForm
      name={name}
      setName={setName}
      isActive={isActive}
      setIsActive={setIsActive}
      cancelActionModal={() => {
        dispatch(openBaseModalCreate(false));
        setShowAddModal(false);
      }}
      confirmOnClick={() => {
        dispatch(openBaseModalCreate(true));
        setShowAddModal(false);
      }}
    />
  );

  const editBaseModalForm = (
    <EditBaseModalForm
      name={updateName}
      setName={setUpdateName}
      isActive={updateActive}
      setIsActive={setUpdateActive}
      cancelActionModal={() => {
        dispatch(openBaseModalEdit(false));
      }}
      confirmOnClick={setShowEditConfirmModal}
    />
  );

  const viewBaseModalForm = (
    <ViewBaseModalForm
      name={updateName}
      isActive={updateActive}
      cancelActionModal={() => {
        setShowViewModal(false);
      }}
      confirmOnClick={() => {
        setShowViewModal(false);
        dispatch(openBaseModalEdit(true));
      }}
    />
  );

  return (
    <>
      <ModalDeleteConfirmation
        title={intl.messages['textModal.areYouSure']}
        text={intl.messages['textModal.baseDeleteConfirm']}
        placeholder={intl.messages['labelsInputs.enterName']}
        buttonName={intl.messages['buttons.delete']}
        setIsOpen={(value) => dispatch(openBaseModalDelete(value))}
        isOpen={openModalDelete}
        fieldValue={base.name}
        fieldLabel={base.name}
        confirmOnClick={() =>
          dispatch(
            deleteBase({
              id: selectedId,
              currentPage,
              searchTerm,
              perPage,
              ordenation,
            }),
          )
        }
        textFieldLabel={intl.messages['labelsInputs.enterName']}
      />
      <Modal
        title="Criar Base"
        setIsOpen={setShowAddModal}
        isOpen={showAddModal}
        content={addBaseModalForm}
        loading={loading}
      />
      <Modal
        title="Editar Base"
        setIsOpen={(value) => dispatch(openBaseModalEdit(value))}
        isOpen={openModalEdit}
        content={editBaseModalForm}
        loading={loading}
      />
      <Modal
        title="Visualizar Base"
        setIsOpen={setShowViewModal}
        isOpen={showViewModal}
        content={viewBaseModalForm}
        loading={loading}
      />
      <ModalMultiConfirmation
        title={intl.messages['textModal.baseCreateConfirm']}
        buttonNameBack={intl.messages['buttons.not']}
        buttonName={intl.messages['buttons.yes']}
        setIsOpen={(value) => dispatch(openBaseModalCreate(value))}
        isOpen={openModalCreate}
        loading={loading}
        status="confirm"
        confirmOnClick={() => {
          dispatch(
            saveBase({
              baseData: { active: isActive, name },
              currentPage,
              searchTerm,
              perPage,
              ordenation,
            }),
          );
          dispatch(statusBasesModal(false));
          if (status !== 'error') {
            successToast('Base criada com sucesso!');
          }
        }}
      />
      <ModalMultiConfirmation
        title={intl.messages['textModal.baseEditConfirm']}
        buttonNameBack={intl.messages['buttons.toBack']}
        buttonName={intl.messages['buttons.yes']}
        setIsOpen={setShowEditConfirmModal}
        isOpen={showEditConfirmModal}
        loading={loading}
        status="confirm"
        confirmOnClick={() => {
          dispatch(
            editBase({
              baseId: selectedId,
              baseData: { name: updateName, active: updateActive },
              currentPage,
              searchTerm,
              perPage,
              ordenation,
            }),
          );
          dispatch(statusBasesModal(false));
          if (status !== 'error') {
            successToast('Base editada com sucesso!');
          }
          setShowEditConfirmModal(false);
        }}
      />
      <ModalMultiConfirmation
        title={msg.title}
        text={msg.text}
        setIsOpen={(payload) => {
          dispatch(statusBasesModal(payload));
        }}
        isOpen={openStatusModal}
        status={status}
      />
      <PageHeader
        title="Bases"
        addButtonName="NOVA BASE"
        onClickAdd={addBaseModal}
        onClickFilter={() => {}}
        addButton={getPermission('bases', permissions, 'create')}
        search
        searchFunc={(searchData) => setSearchTerm(searchData)}
        placeholder="Busque por Nome"
        optionsOrdenation={optionsOrdenation}
        ordenation={ordenation}
        setOrdenation={setOrdenation}
        trashRedirect={() => history.push(urls.ROUTES.BASES_DELETED.path)}
        trash={getPermission('bases', permissions, 'list')}
      />
      <PageContent>
        <EmptySearch items={data} term={searchTerm}>
          <TableDefault
            columns={COLUMNS}
            data={data}
            pageCount={totalPage}
            defaultCurrentPage={currentPage}
            setPageCurrent={setCurrentPage}
            totalPerPage={totalPerPage}
            setTotalPerPage={setTotalPerPage}
          />
        </EmptySearch>
      </PageContent>
    </>
  );
};

export default Bases;
