import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import InputText from 'components/form/Text/TextInput/InputText';
import Toggle from 'components/form/Toggle/Toggle';
import Grid from '@material-ui/core/Grid';
import Button from 'components/structure/Button/Button';
import { Container } from '../AddBaseModalForm/AddBaseModalForm.styles';

const ViewBaseModalForm = ({
  name,
  isActive,
  cancelActionModal,
  confirmOnClick,
  deleted,
}) => {
  const intl = useIntl();

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Grid container>
            <Grid item xs={12}>
              <InputText
                type="text"
                label={intl.messages['labelsInputs.typeYourName']}
                placeholder={intl.messages['labelsInputs.typeYourName']}
                value={name}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <Toggle
                label={intl.messages['labelsInputs.activation']}
                paddingTop="56px"
                paddingBottom="56px"
                checked={isActive}
                disabled
                large
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {deleted === false && (
          <Grid item md={6} xs={12}>
            <Button
              type="button"
              btnType="secondary"
              width="100%"
              height="44px"
              borderRadius="8px"
              uppercase
              onClick={cancelActionModal}
              fontWeight="600"
            >
              {intl.messages['buttons.cancel']}
            </Button>
          </Grid>
        )}
        <Grid item md={6} xs={12}>
          <Button
            btnType="primary"
            width="100%"
            height="44px"
            borderRadius="8px"
            uppercase
            onClick={() => {
              confirmOnClick();
            }}
            fontWeight="600"
          >
            {deleted === false
              ? intl.messages['buttons.edit']
              : intl.messages['buttons.toBack']}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

ViewBaseModalForm.propTypes = {
  name: PropTypes.string,
  isActive: PropTypes.bool,
  cancelActionModal: PropTypes.func,
  confirmOnClick: PropTypes.func,
  deleted: PropTypes.bool,
};

ViewBaseModalForm.defaultProps = {
  name: '',
  isActive: false,
  cancelActionModal: () => {},
  confirmOnClick: () => {},
  deleted: false,
};

export default ViewBaseModalForm;
