import React from 'react';
import PropTypes from 'prop-types';
import { Content } from './IconButton.styles';

const IconButton = ({
  type,
  name,
  onClick,
  disabled,
  btnType,
  children,
  width,
  borderRadius,
  height,
  className,
  style,
  fontWeight,
  icon,
  onChange,
}) => (
  <Content
    type={type}
    name={name}
    btnType={btnType}
    onClick={onClick}
    disabled={disabled}
    width={width}
    borderRadius={borderRadius}
    height={height}
    className={className}
    style={style}
    fontWeight={fontWeight}
    onChange={onChange}
  >
    {icon && <i>{icon}</i>}
    {children}
  </Content>
);

IconButton.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  btnType: PropTypes.string,
  disabled: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  borderRadius: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.any,
  fontWeight: PropTypes.string,
  icon: PropTypes.node,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onChange: PropTypes.func,
};

IconButton.defaultProps = {
  type: '',
  name: '',
  onClick: () => {},
  btnType: '',
  disabled: false,
  width: '',
  height: '',
  borderRadius: '',
  className: '',
  style: undefined,
  fontWeight: '',
  icon: null,
  children: null,
  onChange: () => {},
};

export default IconButton;
