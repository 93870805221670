import ModalMultiConfirmation from 'components/contexts/modal/ModalMultiConfirmation/ModalMultiConfirmation';
import Loader from 'components/structure/Loader/Loader';
import MainContainer from 'components/structure/MainContainer/MainContainer';
import Sidebar from 'components/structure/Sidebar/Sidebar';
import urls from 'constants/urls';
import Articles from 'pages/articles/Articles';
import ArticlesCreate from 'pages/articles/ArticlesCreate';
import ArticlesEdit from 'pages/articles/ArticlesEdit';
import Bases from 'pages/bases/Bases';
import BlogTest from 'pages/blog/Articles';
import Categories from 'pages/categories/Categories';
import CategoriesCreate from 'pages/categories/CategoriesCreate';
import CategoriesEdit from 'pages/categories/CategoriesEdit';
import ColorGroups from 'pages/colorGroups/ColorGroups';
import ColorGroupsCreate from 'pages/colorGroups/ColorGroupsCreate';
import ColorGroupsEdit from 'pages/colorGroups/ColorGroupsEdit';
import ColorsCreate from 'pages/colours/ColorsCreate';
import ColorsEdit from 'pages/colours/ColorsEdit';
import Colours from 'pages/colours/Colours';
import Editorials from 'pages/editorials/Editorials';
import EditorialsEdit from 'pages/editorials/EditorialsEdit';
import PageHome from 'pages/PageHome/PageHome';
import Products from 'pages/products/Products';
import ProductsCreate from 'pages/products/ProductsCreate';
import ProductsEdit from 'pages/products/ProductsEdit';
import ProductsView from 'pages/products/ProductsView';
import Properties from 'pages/properties/Properties';
import Roles from 'pages/roles/Roles';
import RolesCreate from 'pages/roles/RolesCreate';
import RolesEdit from 'pages/roles/RolesEdit';
import SkuList from 'pages/sku/Sku';
import SkuCreate from 'pages/sku/SkuCreate';
import SkuCreateMany from 'pages/sku/SkuCreateManySteps/SkuCreateMany';
import SkuEdit from 'pages/sku/SkuEdit';
import Tags from 'pages/tags/Tags';
import Users from 'pages/Users/Users';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'store/index';
import { Route, Switch, useHistory } from 'react-router-dom';
import isAuthenticated from 'utils/isAuthenticated';

import ImageModal from 'components/contexts/modal/ImageModal/ImageModel';
import ResetPasswordModal from 'components/contexts/modal/ResetPasswordModal/ResetPasswordModal';
import { getUserData } from 'lib/contexts/auth';
import ActivitiesLogs from 'pages/activitiesLogs/Logs';
import ArticlesByEditorial from 'pages/articles/ArticlesByEditorial';
import DeletedBases from 'pages/bases/DeletedBases';
import CategoriesView from 'pages/categories/CategoriesView';
import DeletedCategories from 'pages/categories/DeletedCategories';
import ColorCombinationsCreate from 'pages/colorCombinations/colorCombinationsCreate';
import ColorCombinationsView from 'pages/colorCombinations/ColorCombinationsView';
import DeletedColorCombinations from 'pages/colorCombinations/DeletedColorCombinations';
import ColorGroupsView from 'pages/colorGroups/ColorGroupsView';
import DeletedColorGroups from 'pages/colorGroups/DeletedColorGroups';
import ColorsByGroup from 'pages/colours/ColorsByGroup';
import ColorsView from 'pages/colours/ColorsView';
import DeletedColors from 'pages/colours/DeletedColors';
import EditorialsView from 'pages/editorials/EditorialsView';
import DeletedProducts from 'pages/products/DeletedProducts';
import DeletedProperties from 'pages/properties/DeletedProperties';
import DeletedRoles from 'pages/roles/DeletedRoles';
import RolesView from 'pages/roles/RolesView';
import DeletedUsers from 'pages/Users/DeletedUsers';
import { DryPackageList } from 'pages/DryPackage';
import { DryPackageForm } from 'pages/DryPackage/DryPackageForm';
import Notifications from 'pages/Notifications';

import ArticlesView from '../../pages/articles/ArticlesView';
import DeletedArticles from '../../pages/articles/DeletedArticles';
import ColorCombinations from '../../pages/colorCombinations/ColorCombinations';
import ColorCombinationsEdit from '../../pages/colorCombinations/ColorCombinationsEdit';
import DeletedEditorials from '../../pages/editorials/DeletedEditorials';
import EditorialsCreate from '../../pages/editorials/EditorialsCreate';
import LogsByRecord from '../../pages/logsByRecord/LogsByRecord';
import SkuView from '../../pages/sku/SkuView';
import DeletedTags from '../../pages/tags/DeletedTags';
import { CoreContainer } from './Home.styles';
import { loadUserData } from 'store/auth/auth-slice';
import {
  resetSidebarState,
  setOpenSidebarModal,
} from 'store/sidebar/sidebar-slice';
import {
  resetModalFileInput,
  setModalResetPassword,
  statusModalStatus,
} from 'store/modals/modals-slice';

const Home = () => {
  const loader = useSelector((state) => state.loading.loading);
  const { role } = useSelector((state) => state.auth);
  const { targetPath, openModal } = useSelector((state) => state.sidebar);
  const { status, imgUrl } = useSelector(
    (state) => state.modals.modalFileInput,
  );
  const openResetPasswordModal = useSelector(
    (state) => state.modals.modalResetPassword.openModal,
  );
  const { modalStatus } = useSelector((state) => state.modals);
  const userData = getUserData();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userData.role !== role.id) {
      dispatch(loadUserData());
    }
  }, [dispatch, role.id, userData.role]);

  useEffect(() => {
    if (!isAuthenticated()) {
      history.push('/login');
    }
  }, [history]);

  if (role.id !== userData.role) {
    return <Loader loading={1} />;
  }
  return (
    <>
      <ModalMultiConfirmation
        title="Deseja realmente sair da página?"
        text="Todo o progresso não salvo será perdido!"
        setIsOpen={() => dispatch(setOpenSidebarModal(false))}
        isOpen={openModal}
        status="info"
        confirmOnClick={() => {
          if (targetPath === '/alterar-senha') {
            history.push('/');
            dispatch(setModalResetPassword({ openModal: true }));
          } else {
            history.push(targetPath);
          }
          dispatch(resetSidebarState());
        }}
      />
      <ImageModal
        imgUrl={imgUrl}
        isOpen={status}
        setIsOpen={() => dispatch(resetModalFileInput())}
      />
      <ResetPasswordModal isOpen={openResetPasswordModal} />
      <ModalMultiConfirmation
        title={modalStatus.msg.title}
        text={modalStatus.msg.text}
        setIsOpen={(payload) => dispatch(statusModalStatus(payload))}
        isOpen={modalStatus.openStatusModal}
        status={modalStatus.status}
      />
      <CoreContainer>
        <Sidebar />
        <MainContainer>
          <Loader loading={loader} />
          <Switch>
            <Route exact path={urls.ROUTES.APP.path} component={PageHome} />
            <Route
              exact
              path={urls.ROUTES.ACCESS.items.users.path}
              component={Users}
            />
            <Route
              exact
              path={urls.ROUTES.ACCESS.items.roles.path}
              component={Roles}
            />
            <Route
              exact
              path={urls.ROUTES.ROLES_CREATE.path}
              component={RolesCreate}
            />
            <Route
              exact
              path={urls.ROUTES.ROLES_EDIT.path}
              component={RolesEdit}
            />
            <Route
              exact
              path={urls.ROUTES.ROLES_VIEW.path}
              component={RolesView}
            />
            <Route
              exact
              path={urls.ROUTES.ACCESS.items.activitiesLogs.path}
              component={ActivitiesLogs}
            />
            {/* A opcao no menu referente ao Blog sofreu uma delecao logica podendo ser recuperada neste commit: 71ab283b42d4b965e7a0b37ad10fd33563a93f88 */}
            <Route
              exact
              path={urls.ROUTES.CATALOGUE?.items.bases.path}
              component={Bases}
            />
            <Route
              exact
              path={urls.ROUTES.CATALOGUE?.items.colours.path}
              component={Colours}
            />{' '}
            <Route
              exact
              path={urls.ROUTES.NOTIFICATIONS?.path}
              component={Notifications}
            />
            <Route
              exact
              path={urls.ROUTES.COLORS_CREATE?.path}
              component={ColorsCreate}
            />
            <Route
              exact
              path={urls.ROUTES.COLORS_EDIT?.path}
              component={ColorsEdit}
            />
            <Route
              exact
              path={urls.ROUTES.COLORS_VIEW?.path}
              component={ColorsView}
            />
            <Route
              exact
              path={`${urls.ROUTES.CATALOGUE.items.colorsByGroup.path}/:id`}
              component={ColorsByGroup}
            />
            <Route
              exact
              path={urls.ROUTES.CATALOGUE?.items.colorGroups.path}
              component={ColorGroups}
            />
            <Route
              exact
              path={urls.ROUTES.COLOR_GROUPS_CREATE?.path}
              component={ColorGroupsCreate}
            />
            <Route
              exact
              path={urls.ROUTES.COLOR_GROUPS_EDIT?.path}
              component={ColorGroupsEdit}
            />
            <Route
              exact
              path={urls.ROUTES.COLOR_GROUPS_VIEW?.path}
              component={ColorGroupsView}
            />
            <Route
              exact
              path={urls.ROUTES.CATALOGUE?.items.dryPackages.path}
              component={DryPackageList}
            />
            <Route
              exact
              path={urls.ROUTES.DRY_PACKAGE_CREATE?.path}
              component={DryPackageForm}
            />
            <Route
              exact
              path={`${urls.ROUTES.DRY_PACKAGE_EDIT?.path}/:id`}
              component={() => <DryPackageForm rule="update" />}
            />
            <Route
              exact
              path={`${urls.ROUTES.DRY_PACKAGE_VIEW?.path}/:id`}
              component={() => <DryPackageForm rule="view" />}
            />
            <Route
              exact
              path={urls.ROUTES.CATALOGUE?.items.categories.path}
              component={Categories}
            />
            <Route
              exact
              path={urls.ROUTES.CATEGORIES_CREATE?.path}
              component={CategoriesCreate}
            />
            <Route
              exact
              path={urls.ROUTES.CATEGORIES_EDIT?.path}
              component={CategoriesEdit}
            />
            <Route
              exact
              path={urls.ROUTES.CATEGORIES_VIEW?.path}
              component={CategoriesView}
            />
            <Route
              exact
              path={urls.ROUTES.CATALOGUE?.items.products.path}
              component={Products}
            />
            <Route
              exact
              path={urls.ROUTES.PRODUCTS_CREATE?.path}
              component={ProductsCreate}
            />
            <Route
              exact
              path={urls.ROUTES.PRODUCTS_EDIT?.path}
              component={ProductsEdit}
            />
            <Route
              exact
              path={urls.ROUTES.PRODUCTS_VIEW?.path}
              component={ProductsView}
            />
            <Route
              exact
              path={urls.ROUTES.CATALOGUE?.items.properties.path}
              component={Properties}
            />
            <Route
              exact
              path={urls.ROUTES.SKU_LIST?.path}
              component={SkuList}
            />
            {/*

                  <Route
                    exact
                    path={urls.ROUTES.PRICES_LIST?.path}
                    component={PricesList}
                  />
                  */}
            <Route
              exact
              path={urls.ROUTES.SKU_CREATE?.path}
              component={SkuCreate}
            />
            <Route
              exact
              path={urls.ROUTES.SKU_EDIT?.path}
              component={SkuEdit}
            />
            <Route
              exact
              path={urls.ROUTES.SKU_CREATE_MANY?.path}
              component={SkuCreateMany}
            />
            <Route
              exact
              path={urls.ROUTES.SKU_VIEW?.path}
              component={SkuView}
            />
            <Route
              exact
              path={`${urls.ROUTES.ACCESS.items.logsByRecord.path}/:id/:entityName`}
              component={LogsByRecord}
            />
            <Route
              exact
              path={urls.ROUTES.BASES_DELETED?.path}
              component={DeletedBases}
            />
            <Route
              exact
              path={urls.ROUTES.PRODUCTS_DELETED?.path}
              component={DeletedProducts}
            />
            <Route
              exact
              path={urls.ROUTES.COLORS_DELETED?.path}
              component={DeletedColors}
            />
            <Route
              exact
              path={urls.ROUTES.COLOR_GROUPS_DELETED?.path}
              component={DeletedColorGroups}
            />
            <Route
              exact
              path={urls.ROUTES.CATEGORIES_DELETED?.path}
              component={DeletedCategories}
            />
            <Route
              exact
              path={urls.ROUTES.PROPERTIES_DELETED?.path}
              component={DeletedProperties}
            />
            <Route
              exact
              path={urls.ROUTES.ARTICLES?.path}
              component={Articles}
            />
            <Route
              exact
              path={urls.ROUTES.ARTICLES_CREATE?.path}
              component={ArticlesCreate}
            />
            <Route
              exact
              path={urls.ROUTES.ARTICLES_VIEW?.path}
              component={ArticlesView}
            />
            <Route
              exact
              path={urls.ROUTES.ARTICLES_EDIT?.path}
              component={ArticlesEdit}
            />
            <Route
              exact
              path={urls.ROUTES.ARTICLES_DELETED?.path}
              component={DeletedArticles}
            />
            <Route
              exact
              path={urls.ROUTES.EDITORIALS_DELETED?.path}
              component={DeletedEditorials}
            />
            <Route
              exact
              path={urls.ROUTES.TAGS_DELETED?.path}
              component={DeletedTags}
            />
            <Route
              exact
              path={urls.ROUTES.USERS_DELETED?.path}
              component={DeletedUsers}
            />
            <Route
              exact
              path={urls.ROUTES.ROLES_DELETED?.path}
              component={DeletedRoles}
            />
            <Route
              exact
              path={urls.ROUTES.SKU_CREATE?.path}
              component={SkuCreate}
            />
            <Route
              exact
              path={urls.ROUTES.SKU_EDIT?.path}
              component={SkuEdit}
            />
            <Route
              exact
              path={urls.ROUTES.SKU_CREATE_MANY?.path}
              component={SkuCreateMany}
            />
            <Route
              exact
              path={urls.ROUTES.SKU_VIEW?.path}
              component={SkuView}
            />
            <Route
              exact
              path={`${urls.ROUTES.ACCESS.items.logsByRecord.path}/:id/:entityName`}
              component={LogsByRecord}
            />
            <Route
              exact
              path={urls.ROUTES.BASES_DELETED?.path}
              component={DeletedBases}
            />
            <Route
              exact
              path={urls.ROUTES.PRODUCTS_DELETED?.path}
              component={DeletedProducts}
            />
            <Route
              exact
              path={urls.ROUTES.COLORS_DELETED?.path}
              component={DeletedColors}
            />
            <Route
              exact
              path={urls.ROUTES.COLOR_GROUPS_DELETED?.path}
              component={DeletedColorGroups}
            />
            <Route
              exact
              path={urls.ROUTES.CATEGORIES_DELETED?.path}
              component={DeletedCategories}
            />
            <Route
              exact
              path={urls.ROUTES.PROPERTIES_DELETED?.path}
              component={DeletedProperties}
            />
            <Route
              exact
              path={urls.ROUTES.ARTICLES_DELETED?.path}
              component={DeletedArticles}
            />
            <Route
              exact
              path={urls.ROUTES.EDITORIALS_DELETED?.path}
              component={DeletedEditorials}
            />
            <Route
              exact
              path={urls.ROUTES.TAGS_DELETED?.path}
              component={DeletedTags}
            />
            <Route
              exact
              path={urls.ROUTES.USERS_DELETED?.path}
              component={DeletedUsers}
            />
            <Route
              exact
              path={urls.ROUTES.ROLES_DELETED?.path}
              component={DeletedRoles}
            />
            <Route
              exact
              path={urls.ROUTES.CATALOGUE?.items.combinations.path}
              component={ColorCombinations}
            />
            <Route
              exact
              path={urls.ROUTES.COLOR_COMBINATIONS_CREATE.path}
              component={ColorCombinationsCreate}
            />
            <Route
              exact
              path={urls.ROUTES.COLOR_COMBINATIONS_VIEW.path}
              component={ColorCombinationsView}
            />
            <Route
              exact
              path={urls.ROUTES.CATALOGUE?.items.combinations_edit.path}
              component={ColorCombinationsEdit}
            />
            <Route
              exact
              path={urls.ROUTES.CATALOGUE?.items.combinations_deleted.path}
              component={DeletedColorCombinations}
            />
          </Switch>
        </MainContainer>
      </CoreContainer>
    </>
  );
};
export default Home;
