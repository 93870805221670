import styled, { css } from 'styled-components';

export const EanSkusExclusionModalContent = styled.div`
  ${({ theme }) => css`
    max-width: 406px;
    margin: 0 auto;
    padding-bottom: 87px;

    p {
      font-family: 'Trebuchet MS', sans-serif;
      font-family: 1rem;
      text-align: center;
    }

    &.step--exclusion {
      padding-bottom: 0;

      p {
        color: ${theme.colors.secondary.default};
      }
    }
  `}
`;

export const Flex = styled.div`
  ${({ theme, justify, pt, gap }) => css`
    display: flex;
    justify-content: ${justify ?? 'unset'};
    align-items: center;
    padding-top: ${pt ?? '0'};
    gap: ${gap ?? '0'};

    .input--search {
      width: 100%;
      background-color: white;
      font-size: 1rem;
      border: 1px solid #bababa;
      border-radius: 2px;

      &::placeholder {
        color: #bababa;
      }
    }

    .error-icon {
      display: none;
    }

    .btn--submit-search {
      min-width: 141px;
    }

    button {
      font-family: ${theme.typography.family.secondary};
      font-weight: 600;
      font-size: 16px;

      &.btn--exclude {
        color: white;
      }
    }
  `}
`;

export const ResumeAction = styled.div`
  display: flex;
  border: 1px solid #cccccc;
  border-radius: 8px;
  gap: 40px;
  padding: 1rem;
  margin-top: 28px;
`;

export const ResumeActionBlock = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 4px;

    span {
      font-family: 'Trebuchet MS', sans-serif;
      font-size: 14px;
      font-weight: 400;

      &.action-block--title {
        text-transform: uppercase;
        font-family: ${theme.typography.family.secondary};
        font-weight: 600;
        font-size: 1rem;
      }
    }
  `}
`;
