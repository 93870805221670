import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ containerWidth, containerHeight, zIndex }) => css`
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: ${containerWidth};
    height: ${containerHeight};
    background-color: rgba(0, 0, 0, 0.4);
    transition: opacity 400ms ease-in;
    z-index: ${zIndex};
  `}
`;

export const ModalContent = styled.div`
  ${({ theme, width, height, top, left }) => css`
    position: fixed;
    top: ${top === '' ? '50%' : top};
    left: ${left === '' ? '50%' : left};
    max-width: 100%;
    max-height: 100%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-flow: column;
    margin: auto;
    background-color: ${theme.colors.white.default};
    border-radius: 8px;
    padding: ${({ paddingRolesModal }) =>
      paddingRolesModal ? '50px 49px 80px' : '30px 40px 30px'};
    box-sizing: border-box;
    width: ${width};
    height: ${height};

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      position: absolute;
      transform: translate(0%, 0%);
      padding: 16px;
      top: 10%;
      left: 0.5%;
      right: 0.8%;
      width: 80%;
    }
  `}
`;

export const ModalHeader = styled.div`
  ${({ theme, paddingTop }) => css`
    display: flex;
    width: 100%;
    justify-content: ${({ titleRight }) =>
      titleRight ? 'flex-start' : 'center'};
    align-items: center;
    padding-top: ${paddingTop};
    margin-bottom: 20px;
    margin-top: -10px;
    color: ${theme.colors.black.default};

    button {
      position: absolute;
      top: 15px;
      right: 15px;
      > img {
        margin: 0;
      }
    }
  `}
`;

export const Title = styled.h3`
  ${({ theme, titleWidth }) => css`
    font-family: ${theme.typography.family.secondary};
    font-size: ${({ titleSize }) => (titleSize ? `${titleSize}` : '24px')};
    font-weight: 700;
    text-align: center;
    align-self: center;
    width: ${titleWidth};

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      width: 80%;
    }
  `}
`;

export const ModalBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
