/* eslint-disable no-param-reassign */
import Grid from '@material-ui/core/Grid';
import PropertiesComponent from 'components/contexts/products/PropertiesComponent/PropertiesComponent';
import FileInput from 'components/form/FileInput/FileInput';
import BoxAlert from 'components/structure/BoxAlert/BoxAlert';
import Button from 'components/structure/Button/Button';
import { Divider } from 'components/structure/PopOver/PopOver.styles';
import urls from 'constants/urls';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { PropertiesTitle } from '../Products.styles';
import { ButtonsContainer } from '../ProductsEditSteps/ProductsEditSteps.styles';
import { ImageRegisters, rules } from 'containers/UploadFilesImage';

const defaultShowcase = {
  file: null,
  name: 'Showcase',
  altText: '',
};

const ProductsViewSecondStep = () => {
  const { selectId, openStatusModal, status, msg, product } = useSelector(
    (state) => state.products,
  );

  const [fispqFilename, setFispqFilename] = useState('');
  const [btFilename, setBtFilename] = useState('');
  const [dataFileFispq, setDataFileFispq] = useState([{}]);
  const [dataFileBt, setDataFileBt] = useState([{}]);

  const property = 0;
  const valueProperty = null;
  const [properties, setProperties] = useState([
    {
      idProp: uuidv4(),
      id: property,
      value: valueProperty,
      type: '',
    },
  ]);

  const [showCase, setShowCase] = useState(defaultShowcase);

  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const cancelProductView = () => {
    history.goBack();
  };

  const handleAdd = () => {
    setProperties([
      ...properties,
      { idProp: uuidv4(), id: 0, value: null, type: '' },
    ]);
  };

  const handleDelete = (id) => {
    const values = [...properties];
    values.splice(
      values.findIndex((value) => value.idProp === id),
      1,
    );

    setProperties(values);
  };

  const handleChangeInput = (id, event, type) => {
    const newInputFields = properties.map((i) => {
      if (id === i.idProp) {
        i[event.target?.name] = event.target?.value;
        i.type = type;
      }

      return i;
    });

    setProperties(newInputFields);
  };

  useEffect(() => {
    if (product.properties?.length > 0) {
      setProperties(
        product?.properties?.map((option) => ({
          idProp: uuidv4(),
          id: option.id,
          value: option.ProductToProperty?.value,
          type: option.type,
        })),
      );
    }

    const showcaseImage =
      product.images.showcase.length > 0 ? product.images.showcase[0] : null;

    setShowCase({
      file: defaultShowcase.file,
      name: showcaseImage?.fullUrl ?? defaultShowcase.name,
      altText: showcaseImage?.alternativeText ?? defaultShowcase.altText,
    });

    setBtFilename(product.docs.bt[0]);
    setFispqFilename(product.docs.fispq[0]);
  }, [product, dispatch]);

  const typeCast = {
    string: (value) => String(value),
    boolean: (value) => Boolean(value),
    integer: (value) => parseInt(value, 10),
    float: (value) => Number(value),
    double: (value) => Number(value),
    timestamp: (value) => new Date(value),
  };

  const textBox =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sit non vestibulum at.';

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} md={5}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <BoxAlert title="Atenção" text={textBox} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ paddingBottom: '30px' }} />
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item md={5}>
            <FileInput
              label={intl.messages['labelsInputs.fispq']}
              text={intl.messages['labelsInputs.toDoUploadFile']}
              title="Lorem ipsum"
              fileName={fispqFilename}
              getFile={() => undefined}
              disabled
              dontShowViewButton
              useOpenInNewIcon
              optional
            />
          </Grid>
          <Grid item md={5}>
            <FileInput
              label={intl.messages['labelsInputs.technical']}
              text={intl.messages['labelsInputs.toDoUploadFile']}
              title="Lorem ipsum"
              fileName={btFilename}
              getFile={() => undefined}
              disabled
              dontShowViewButton
              useOpenInNewIcon
              optional
            />
          </Grid>
          <ImageRegisters
            showcaseFileName={showCase.name}
            showcaseAltText={showCase.altText}
            sizeList={product.sizes.items}
            rule={rules.DETAILS}
          />
        </Grid>
      </Grid>
      <div style={{ paddingBottom: '30px' }} />
      <Divider />
      <div style={{ paddingBottom: '30px' }} />
      <PropertiesTitle>Propriedades</PropertiesTitle>
      <Grid item md={12}>
        {properties.map((item) => (
          <div key={item.idProp}>
            <PropertiesComponent
              property={parseInt(item.id, 10)}
              valueProperty={item.value}
              setProperty={(event, type) =>
                handleChangeInput(item.idProp, event, type)
              }
              setValueProperty={(event, type) =>
                handleChangeInput(item.idProp, event, type)
              }
              handleDeleteProperty={() => handleDelete(item.idProp)}
              handleAddProperty={handleAdd}
              properties={properties}
              objectProperty={item}
              disabled
            />
          </div>
        ))}
      </Grid>

      <ButtonsContainer>
        <Grid container spacing={2}>
          <Grid item md={3} xs={12}>
            <Button
              btnType="secondary"
              width="100%"
              borderRadius="4px"
              fontWeight="600"
              onClick={() => {
                cancelProductView();
              }}
            >
              {intl.messages['buttons.cancel']}
            </Button>
          </Grid>

          <Grid item md={3} sm={12}>
            <Button
              btnType="primary"
              width="100%"
              borderRadius="4px"
              fontWeight="600"
              onClick={() => {
                history.push(urls.ROUTES.CATALOGUE.items.products.path);
              }}
            >
              {intl.messages['buttons.advance']}
            </Button>
          </Grid>
        </Grid>
      </ButtonsContainer>
    </>
  );
};

export default ProductsViewSecondStep;
