/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { COLLECTIONS, COLORS, FAMILIES } from 'constants/endpoints';

import api from 'services/api';
import multiForm from 'services/multiForm';
import { startLoading, stopLoading } from 'store/loading/loading-slice';

import { errorSessionToast, errorToast, successToast } from 'utils/toasts';

const INITIAL_STATE = {
  colours: {
    totalPage: 1,
    items: [],
  },
  color: {
    id: 0,
    name: '',
    code: '',
    page: null,
    position: null,
    yearlyColor: '',
    stickerTest: false,
    background: false,
    active: null,
    rgb: '',
    slug: '',
    groups: [],
  },
  selectId: 0,
  openStatusModal: false,
  status: '',
  msg: {
    title: '',
    text: '',
  },
  families: [],
  collections: [],
  openModalDelete: false,
};

const coloursSlice = createSlice({
  name: 'colours',
  initialState: INITIAL_STATE,
  reducers: {
    getColoursSuccess: (state, { payload }) => {
      state.colours = payload;
    },
    getColorsByGroupSuccess: (state, { payload }) => {
      state.colours = payload;
    },
    statusColoursModal: (state, { payload }) => {
      state.openStatusModal = payload;
    },
    setColoursStatus: (state, { payload }) => {
      state.status = payload;
    },
    setColoursMsg: (state, { payload }) => {
      state.msg = payload;
    },
    setColourId: (state, { payload }) => {
      state.selectId = payload;
    },
    getColorSuccess: (state, { payload }) => {
      state.color = payload;
    },
    getFamiliesListSuccess: (state, { payload }) => {
      state.families = payload;
    },
    getCollectionsListSuccess: (state, { payload }) => {
      state.collections = payload;
    },
    openColorModalDelete: (state, { payload }) => {
      state.openModalDelete = payload;
    },
    getColorToDeleteSuccess: (state, { payload }) => {
      state.color = payload;
    },
    getDeletedColoursSuccess: (state, { payload }) => {
      state.colours = payload;
    },
  },
});

export const colours = coloursSlice.reducer;

export const {
  getCollectionsListSuccess,
  getColorSuccess,
  getColorToDeleteSuccess,
  getColorsByGroupSuccess,
  getColoursSuccess,
  getDeletedColoursSuccess,
  getFamiliesListSuccess,
  openColorModalDelete,
  setColourId,
  setColoursMsg,
  setColoursStatus,
  statusColoursModal,
} = coloursSlice.actions;

export const getListColours = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { currentPage, searchTerm, perPage, ordenation } = payload;
  try {
    let url = '';
    if (searchTerm === '') {
      url = `${COLORS}?perPage=${
        perPage || '10'
      }&page=${currentPage}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}`;
    } else {
      url = `${COLORS}?perPage=${
        perPage || '10'
      }&page=${currentPage}&term=${searchTerm}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}`;
    }
    const response = await api.get(url);
    dispatch(getColoursSuccess(response.data));

    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getListColoursByGroup = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { id, currentPage, searchTerm, perPage, ordenation } = payload;
  try {
    let url = '';
    if (searchTerm === '') {
      url = `${FAMILIES}/${id}/colors?perPage=${
        perPage || '10'
      }&page=${currentPage}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}`;
    } else {
      url = `${FAMILIES}/${id}/colors?perPage=${
        perPage || '10'
      }&page=${currentPage}&name=${searchTerm}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}`;
    }
    const response = await api.get(url);
    dispatch(getColorsByGroupSuccess(response.data));

    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getListDeletedColours = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { page, searchTerm, perPage, ordenation } = payload;
  try {
    let url = '';
    if (searchTerm === '') {
      url = `${COLORS}?perPage=${perPage || '10'}&page=${page}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}&trash=true`;
    } else {
      url = `${COLORS}?perPage=${
        perPage || '10'
      }&page=${page}&name=${searchTerm}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}&trash=true`;
    }
    const response = await api.get(url);
    dispatch(getDeletedColoursSuccess(response.data));

    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const addColour = (payload) => async (dispatch) => {
  dispatch(startLoading());
  try {
    await multiForm.post(COLORS, payload);
    const msg = { title: 'Cor criada com sucesso!' };

    dispatch(setColoursMsg(msg));
    dispatch(setColoursStatus('success'));

    dispatch(stopLoading());
  } catch (error) {
    let msg = { text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Ops!',
            text: 'Não foi possível criar essa cor, verifique as informações.',
          };
          dispatch(setColoursMsg(msg));
          dispatch(setColoursStatus('error'));
          dispatch(statusColoursModal(true));
          break;
        case 409:
          msg = {
            title: 'Cor já existente',
          };
          dispatch(setColoursMsg(msg));
          dispatch(setColoursStatus('error'));
          dispatch(statusColoursModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const getColorDetail = (payload) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await api.get(`${COLORS}/${payload}`);

    dispatch(getColorSuccess(response.data));

    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getDeletedColorDetail = (payload) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await api.get(`${COLORS}/${payload}?trash=true`);

    dispatch(getColorSuccess(response.data));

    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getFamiliesOptions = () => async (dispatch) => {
  try {
    const response = await api.get(`${FAMILIES}?perPage=1000&page=1`);
    const optionsData = response.data.items.map((option) => ({
      value: option.id,
      label: option.name,
    }));
    dispatch(getFamiliesListSuccess(optionsData));
  } catch (error) {
    /// TODO
    console.log(error);
  }
};

export const getCollectionsOptions = () => async (dispatch) => {
  try {
    const response = await api.get(`${COLLECTIONS}?perPage=1000&page=1`);
    const optionsData = response.data.items.map((option) => ({
      value: option.id,
      label: option.name,
    }));
    dispatch(getCollectionsListSuccess(optionsData));
  } catch (error) {
    /// TODO
    console.log(error);
  }
};

export const editColor = (payload) => async (dispatch) => {
  const { id, info } = payload;
  dispatch(startLoading());
  try {
    await api.patch(`${COLORS}/${id}`, info);

    successToast('Cor alterada com sucesso');
    dispatch(stopLoading());
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Ops!',
            text: 'Não foi possível alterar essa cor, verifique as informações.',
          };
          dispatch(setColoursMsg(msg));
          dispatch(setColoursStatus('error'));
          dispatch(statusColoursModal(true));
          break;
        case 409:
          msg = {
            title: 'Cor já existente',
          };
          dispatch(setColoursMsg(msg));
          dispatch(setColoursStatus('error'));
          dispatch(statusColoursModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const getColorDetailToDelete = (payload) => async (dispatch) => {
  const { id, deleteSearch } = payload;
  dispatch(startLoading());
  try {
    const response = await api.get(`${COLORS}/${id}`);
    dispatch(getColorToDeleteSuccess(response.data));

    dispatch(stopLoading());
    if (deleteSearch) {
      dispatch(openColorModalDelete(true));
    }
  } catch (error) {
    errorToast(error);
    dispatch(stopLoading());
  }
};

export const removeColor = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { id, page } = payload;
  try {
    await api.delete(`${COLORS}/${id}`);
    const msg = { title: 'Cor deletada com sucesso!' };
    dispatch(setColoursMsg(msg));
    dispatch(openColorModalDelete(false));

    dispatch(stopLoading());
    dispatch(setColoursStatus('success'));
    dispatch(statusColoursModal(true));
    dispatch(getListColours(page));
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Ops!',
            text: 'Não foi possível deletar essa cor, verifique as informações.',
          };
          dispatch(setColoursMsg(msg));
          dispatch(setColoursStatus('error'));
          dispatch(statusColoursModal(true));
          break;
        case 422:
          msg = {
            title: 'Ops!',
            text: 'Não foi possível deletar essa cor, ela possui grupos vinculados.',
          };
          dispatch(setColoursMsg(msg));
          dispatch(setColoursStatus('error'));
          dispatch(statusColoursModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};
