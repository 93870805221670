import styled from 'styled-components';

export const Header = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: #666666;
`;

export const Container = styled.div`
  border: 2px solid #f7f7f7;
  border-radius: 4px;

  .selectContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  padding: 24px;
  margin-bottom: 56px;
`;

export const ContainerButtons = styled.div`
  display: flex;
  padding: 20px 0 0 0px;
  justify-content: flex-end;
  align-items: center;

  button {
    margin-left: 8px;
  }
`;

export const ContentButton = styled.div`
  margin-left: 8px;
`;
