import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputSelectProperties from 'components/contexts/products/PropertiesComponent/InputSelectProperties/InputSelectProperties';
import IconButton from 'components/structure/IconButton/IconButton';
import Grid from '@material-ui/core/Grid';
import { ReactComponent as AddPropertyIcon } from 'assets/icons/addProperty.svg';
import { ReactComponent as TrashPropertyIcon } from 'assets/icons/trashProperty.svg';
import { useDispatch, useSelector } from 'react-redux';

import {
  Container,
  ContainerButtons,
  ContentButton,
} from './PropertiesComponent.styles';
import { TypeInputComponent } from './getInputs';
import { getPropertiesOptions } from 'store/products/products-slice';

const PropertiesComponent = ({
  handleAddProperty,
  handleDeleteProperty,
  properties,
  property,
  setProperty,
  valueProperty,
  setValueProperty,
  disabled,
  objectProperty,
}) => {
  const propertyItems = useSelector((state) => state.products.properties);

  const [typeProperty, setTypeProperty] = useState('');

  const dispatch = useDispatch();

  const getVisible = () => {
    if (properties.length > 1) {
      const idx = properties.indexOf(objectProperty);

      if (properties.length - 1 === idx) {
        return true;
      }
      return false;
    }
    return true;
  };

  useEffect(() => {
    dispatch(getPropertiesOptions());
  }, [dispatch]);

  useEffect(() => {
    const type = propertyItems.find((option) => option.value === property);
    if (type) {
      setTypeProperty(type.type);
    }
  }, [propertyItems, property]);

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item md={5}>
              <InputSelectProperties
                name="id"
                label="Propriedade"
                options={propertyItems}
                value={property}
                handleChange={(event) => setProperty(event, typeProperty)}
                disabled={disabled}
              />
            </Grid>

            <Grid item md={5}>
              <TypeInputComponent
                name="value"
                typeProperty={typeProperty}
                valueProperty={valueProperty}
                setValueProperty={(event) =>
                  setValueProperty(event, typeProperty)
                }
                disabled={disabled}
              />
            </Grid>

            <Grid item xs={12} md={1}>
              <ContainerButtons>
                {disabled === false && (
                  <IconButton
                    disabled={disabled}
                    btnType="secondary"
                    icon={<TrashPropertyIcon />}
                    onClick={
                      disabled === false ? handleDeleteProperty : undefined
                    }
                  />
                )}
              </ContainerButtons>
            </Grid>
            <Grid item xs={12} md={1}>
              <ContainerButtons>
                <ContentButton>
                  {getVisible() && disabled === false && (
                    <IconButton
                      btnType="primary"
                      icon={<AddPropertyIcon />}
                      onClick={
                        disabled === false ? handleAddProperty : undefined
                      }
                    />
                  )}
                </ContentButton>
              </ContainerButtons>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

PropertiesComponent.propTypes = {
  handleAddProperty: PropTypes.func,
  handleDeleteProperty: PropTypes.func,
  properties: PropTypes.array,
  property: PropTypes.number,
  setProperty: PropTypes.func,
  valueProperty: PropTypes.string,
  setValueProperty: PropTypes.func,
  disabled: PropTypes.bool,
  objectProperty: PropTypes.object,
};

PropertiesComponent.defaultProps = {
  handleAddProperty: () => {},
  handleDeleteProperty: () => {},
  properties: [],
  property: null,
  setProperty: () => {},
  valueProperty: '',
  setValueProperty: () => {},
  disabled: false,
  objectProperty: {},
};

export default PropertiesComponent;
