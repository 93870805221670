import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Container, FormTitle } from './FormContainer.styles';
import { preventRedirectEnabled } from 'store/sidebar/sidebar-slice';

const FormContainer = ({ children, title }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(preventRedirectEnabled());
  }, [dispatch]);

  return (
    <Container>
      <FormTitle>{title}</FormTitle>
      <div>{children}</div>
    </Container>
  );
};

FormContainer.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default FormContainer;
