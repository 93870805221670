import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: auto;
    align-items: center;
    font-family: ${theme.typography.family.primary};

    input {
      width: 67px;
      height: 48px;
      color: ${theme.colors.secondary.default};
      font-size: 1rem;
      text-align: center;
      margin-right: 16px;
      border-radius: 2px;
    }
  `}
`;
