import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0;

  label {
    margin-bottom: 0;
  }

  .error-icon {
    display: flex;
    align-items: flex-end;
    position: absolute;
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;
    top: 44px;
    right: 14px;
  }
`;

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.colors.secondary.dark};
    font-weight: 400;
    font-size: ${theme.typography.sizes.sm};
    line-height: 17px;

    ${({ isError }) =>
      isError && `color: ${theme.colors.contextual.error.default}`}

    ${({ secondary }) =>
      secondary &&
      css`
        font-family: 'Montserrat';
      `};
  `}
`;

export const InputStyle = styled.input`
  ${({ theme }) => css`
    position: relative;
    margin: 0;
    outline: 0;
    padding: 0.67em 1em;
    font-size: ${theme.typography.sizes.sm};
    line-height: 17px;
    background: ${theme.colors.gray.lighter};
    border: none;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 2px;
    box-shadow: inset 0 0 0 0 transparent;
    transition: color 0.1s ease, border-color 0.1s ease;
    height: 48px;
    width: 100%;
    -webkit-appearance: none;

    &:-webkit-autofill {
      box-shadow: 0 0 0 30px ${theme.colors.gray.lighter} inset;
    }

    ${({ isError }) =>
      isError && `border: 1px solid ${theme.colors.contextual.error.default}`}

    ${({ secondary }) =>
      secondary &&
      css`
        font-size: ${theme.typography.sizes.xs};
        font-family: 'Montserrat';
        height: 38px;
        border: 1px solid #ecedf1;
        border-radius: 8px;
        background: #fff;
      `};

    ${({ disabled }) =>
      disabled &&
      css`
        cursor: not-allowed;
        border: 1px solid ${theme.colors.background.float.solid};
        background: ${theme.colors.background.float.solid};
      `};
  `}
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 4px;
`;

export const OptionalStyle = styled.p`
  ${({ theme }) => css`
    font-weight: 400;
    font-size: ${theme.typography.sizes.sm};
    line-height: 17px;
    align-items: center;
    color: ${theme.colors.black.light};
    padding-left: 5px;
  `}
`;
