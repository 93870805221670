import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputText from 'components/form/Text/InputText/InputText';
import InputSelect from 'components/form/Select/InputSelect/InputSelect';
import Grid from '@material-ui/core/Grid';
import Button from 'components/structure/Button/Button';
import { addTag as createTag } from 'store/tags/tags-slice';

import { defaultValues, schema } from './AddTagModalForm.validations';
import { ButtonsContainer, Container } from './AddTagModalForm.styles';

const AddTagModalForm = ({ setName, cancelActionModal, createActionModal }) => {
  const [platforms, setPlatforms] = useState('institucional');

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
    shouldFocusError: true,
  });
  const dispatch = useDispatch();
  const intl = useIntl();

  const options = [
    { value: 'institucional', name: 'Suvinil' },
    { value: 'glasu', name: 'Glasu' },
  ];

  function validate(obj) {
    if (obj === null || obj === undefined) {
      return false;
    }
    return true;
  }

  const resetStates = () => {
    setName('');
    setPlatforms('institucional');
  };

  const addTag = (data) => {
    const { name } = data;
    const arrayPlatform = [];
    arrayPlatform.push(platforms);
    const tagData = {
      name,
      platforms: arrayPlatform,
    };
    createActionModal();
    dispatch(createTag(tagData));
    resetStates();
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(addTag)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={12}>
                <InputText
                  type="text"
                  id="name"
                  name="name"
                  error={errors.name}
                  isError={validate(errors.name)}
                  register={register}
                >
                  {intl.messages['labelsInputs.typeYourName']}
                </InputText>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ paddingTop: validate(errors.name) ? '0px' : '21px' }}
              >
                <InputSelect
                  name="platforms"
                  label={intl.messages['labelsInputs.typePlatform']}
                  options={options}
                  value={platforms}
                  error={errors.platforms}
                  isError={errors.platforms}
                  register={register}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ButtonsContainer
          platformError={errors.platforms}
          nameError={validate(errors.name)}
        >
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Button
                type="button"
                btnType="secondary"
                width="100%"
                height="44px"
                borderRadius="8px"
                uppercase
                onClick={cancelActionModal}
                fontWeight="600"
              >
                {intl.messages['buttons.cancel']}
              </Button>
            </Grid>
            <Grid item md={6} xs={12}>
              <Button
                type="submit"
                btnType="primary"
                width="100%"
                height="44px"
                borderRadius="8px"
                uppercase
                fontWeight="600"
              >
                {intl.messages['buttons.create']}
              </Button>
            </Grid>
          </Grid>
        </ButtonsContainer>
      </form>
    </Container>
  );
};

AddTagModalForm.propTypes = {
  setName: PropTypes.func,
  cancelActionModal: PropTypes.func,
  createActionModal: PropTypes.func,
};

AddTagModalForm.defaultProps = {
  setName: () => {},
  cancelActionModal: () => {},
  createActionModal: () => {},
};

export default AddTagModalForm;
