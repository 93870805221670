import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10%;
  width: 100%;
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
`;

export const ButtonContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  margin-top: 40px;
`;

export const ForgotPasswordLink = styled.span`
  color: ${({ theme }) => theme.colors.primary.default};
  text-decoration: underline;
  float: right;
  cursor: pointer;
`;
