import styled from 'styled-components';

export const OrdenationsHeaderContainer = styled.div`
  display: flex;
  width: 470px;
  justify-content: space-around;
  margin-bottom: 16px;

  span {
    font-family: ${({ theme }) => theme.typography.family.primary};
    color: ${({ theme }) => theme.colors.tertiary.default};
    font-size: ${({ theme }) => theme.typography.sizes.base};
    font-weight: 600;
  }
`;
