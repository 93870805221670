import * as Yup from 'yup';

export const schema = Yup.object().shape({
  name: Yup.string().required('Nome não preenchido'),
  email: Yup.string()
    .email('Por favor informe um email válido')
    .required('Email não preenchido'),
  password: Yup.string()
    .matches(
      /^(?=.{6,16}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*#?&\-_])/,
      'Sua senha deve conter letra maiúscula, letra minúscula, caractere especial, número, mínimo de 6 e máximo de 16 caracteres.',
    )
    .required('A senha é obrigatória'),
  role: Yup.string().required('Perfil não foi selecionado'),
});

export const defaultValues = {
  name: '',
  email: '',
  password: '',
  role: '',
};
