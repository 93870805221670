import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import api from '../services/api';
import { useSelector } from 'react-redux';

const UploadFileListContext = createContext({});

export const UploadFileListProvider = ({ children }) => {
  const [fileList, setFileList] = useState([]);
  const [weightName, setWeightName] = useState('');
  const [weightId, setWeightId] = useState('');

  const { selectId, product } = useSelector((state) => state.products);

  const addFile = useCallback((event) => {
    const eventCopy = event;
    eventCopy.target.files[0].altText = '';
    eventCopy.target.files[0].weightId = weightId;
    setFileList((prev) => [...prev, eventCopy.target.files[0]]);
  }, []);

  const removeFile = useCallback(
    (name) => setFileList((prev) => prev.filter((file) => file.name !== name)),
    [],
  );

  const updateAltText = useCallback((name, altText) => {
    setFileList((prev) => {
      return prev.map((file = {}) => {
        const fileCopy = file;
        if (fileCopy.name === name) {
          fileCopy.altText = altText;
        }
        return fileCopy;
      });
    });
  }, []);

  const uploadDisplayImages = useCallback(() => {
    fileList.forEach((file, index) => {
      if (file.size) {
        const formData = new FormData();
        formData.append('files', file);
        formData.append('sizeId', weightId);
        formData.append('alternativeText', JSON.stringify([file.altText]));
        formData.append('position', index + 1);
        formData.append('context', 'display');

        api.post(`/products/${selectId}/image`, formData);
      }
    });
  }, [selectId, fileList]);

  useEffect(() => {
    if (selectId) {
      const displayImages = product.images.display;
      const displayImagesWeight = [];

      displayImages.forEach((image) => {
        if (
          !image.fullUrl?.includes('.webp') &&
          image.fullUrl?.includes(weightName.toLowerCase())
        ) {
          const position = image.fullUrl.split('.').at(-2).at(-1);
          displayImagesWeight[Number(position) - 1] = {
            name: image.fullUrl,
            altText: image.alternativeText ?? '',
            weightId,
          };
        }
      });
      setFileList(displayImagesWeight);
    }
  }, [selectId, weightName, product, weightId]);

  const memorized = useMemo(
    () => ({
      fileList,
      addFile,
      setFileList,
      removeFile,
      updateAltText,
      uploadDisplayImages,
      setWeightName,
      weightId,
      setWeightId,
    }),
    [
      fileList,
      addFile,
      removeFile,
      setFileList,
      updateAltText,
      uploadDisplayImages,
      setWeightName,
      weightId,
      setWeightId,
    ],
  );

  return (
    <UploadFileListContext.Provider value={memorized}>
      {children}
    </UploadFileListContext.Provider>
  );
};

export const useFileList = () => useContext(UploadFileListContext);

UploadFileListProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
