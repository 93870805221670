export const base = process.env.REACT_APP_API_BASE_PATH;

export const baseURL = `${base}`;
export const ARTICLES = `${baseURL}/articles`;
export const EDITORIALS = `${base}/editorials`;
export const TAGS = `${base}/tags`;
export const USERS = `${base}/users`;
export const SIGNIN = `${base}/sign-in`;
export const SIGNOUT = `${base}/sign-out`;
export const POST_ARTICLES_UPLOADS = `${base}/uploads`;
export const PRODUCTS = `${base}/products`;
export const COLORS = `${base}/colors`;
export const COLLECTIONS = `${base}/collections`;
export const FAMILIES = `${base}/color-groups`;
export const ROLES = `/roles`;
export const BASES = `${base}/bases`;
export const PERMISSIONS = `${base}/permissions`;
export const SIZES = `${base}/sizes`;
export const RESOURCES = `/resources`;
export const CATEGORIES = `${base}/categories`;
export const PROPERTIES = `${base}/properties`;
export const SKUS = `${base}/stock-keeping-units`;
export const PRICES = `/prices`;
export const ATTRIBUTES = `/attributes`;
export const LOGS = '/logs';
export const PRESIGN = '/presigned';
export const COLOR_COMBINATIONS = '/color-combinations';
export const FORGOT_PASSWORD = '/forgot';
export const MATERIALS = `${baseURL}/materials`;
export const DRY_PACKAGES = `${baseURL}/dry-packages`;
export const SKUS_BATCH = `${base}/stock-keeping-units/batch`;
export const UNITS = `${base}/units`;
