import { yupResolver } from '@hookform/resolvers/yup';
import successIcon from 'assets/icons/check-success.svg';
import InputPassword from 'components/form/Text/InputPassword/InputPassword';
import BackgroundImageContainer from 'components/structure/BackgroundImageContainer/BackgroundImageContainer';
import Button from 'components/structure/Button/Button';
import { useQuery } from 'hooks/useQuery';
import { setOnlyAccessToken } from 'lib/contexts/auth';
import { Description, Title } from 'pages/forgotPassword/ForgotPassword.styles';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Body, ButtonContent, Container } from '../login/Login.styles';
import { ResetPasswordSuccessContainer } from './ResetPassword.styles';
import { defaultValues, schema } from './resetPassword.validations';
import { resetForgottenPassword } from 'store/users/users-slice';

const ResetPassword = () => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
    shouldFocusError: true,
  });
  const [done, setDone] = useState(false);

  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const token = query.get('token');
  const [userId, setUserId] = useState(null);

  const { status } = useSelector((state) => state.users);
  const { loading } = useSelector((state) => state.loading);

  const handleSubmitModalForm = (data) => {
    const newPass = { ...data };
    newPass.forgot = true;
    dispatch(
      resetForgottenPassword({ resetPasswordData: newPass, id: userId }),
    );
    setDone(true);
  };

  useEffect(() => {
    const user = JSON.parse(atob(token.split('.')[1]));
    setUserId(user.user.id);
  }, [history]);

  useEffect(() => {
    setOnlyAccessToken(token);
  }, [token]);

  function validate(obj) {
    if (!obj) {
      return false;
    }
    return true;
  }

  return (
    <BackgroundImageContainer loading={loading}>
      <Container>
        {!done ? (
          <Body>
            <Title>Redefinição de senha</Title>
            <Description>
              Crie uma nova senha abaixo. Sua senha deve conter letra maiúscula,
              letra minúscula, número e, no mínimo, 8 caracteres.
            </Description>
            <form onSubmit={handleSubmit(handleSubmitModalForm)}>
              <InputPassword
                id="password"
                name="password"
                register={register}
                error={errors}
                isError={validate(errors.password)}
                hookForm
              >
                {intl.messages['labelsInputs.newPassword']}
              </InputPassword>

              <InputPassword
                id="passwordConfirmation"
                name="passwordConfirmation"
                register={register}
                error={errors}
                isError={validate(errors.passwordConfirmation)}
                hookForm
              >
                {intl.messages['labelsInputs.newPasswordConfirm']}
              </InputPassword>

              <ButtonContent>
                <Button
                  type="submit"
                  btnType="primary"
                  width="181px"
                  height="48px"
                  uppercase
                  fontWeight="600"
                >
                  {intl.messages['buttons.resetPassword']}
                </Button>
              </ButtonContent>
            </form>
          </Body>
        ) : (
          <ResetPasswordSuccessContainer>
            <img width={69} height={69} src={successIcon} alt="" />
            <Title>Senha redefinida com sucesso!</Title>
            <ButtonContent>
              <Button
                type="button"
                btnType="primary"
                width="181px"
                height="48px"
                uppercase
                fontWeight="600"
                onClick={() => history.push('/login')}
              >
                Ir para login
              </Button>
            </ButtonContent>
          </ResetPasswordSuccessContainer>
        )}
      </Container>
    </BackgroundImageContainer>
  );
};

export default ResetPassword;
