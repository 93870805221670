/* eslint-disable prefer-const */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import FormContainer from 'components/structure/FormContainer/FormContainer';
import InputText from 'components/form/Text/TextInput/InputText';
import Grid from '@material-ui/core/Grid';
import Button from 'components/structure/Button/Button';
import BoxAlert from 'components/structure/BoxAlert/BoxAlert';
import Checkbox from 'components/form/Checkbox/Checkbox';
import urls from 'constants/urls';

import ModalMultiConfirmation from 'components/contexts/modal/ModalMultiConfirmation/ModalMultiConfirmation';
import api from 'services/api';
import { PERMISSIONS, ROLES } from 'constants/endpoints';

import { MorePermissionsModal } from 'components/contexts/permissions/MorePermissionsModal';
import { getPermission } from 'utils/permissions';
import {
  ButtonsContainer,
  PermissionsTitle,
  ButtonPermissionsContent,
  Title,
  Table,
  Tr,
  TableBody,
  Td,
  Line,
} from './Roles.styles';
import { MorePermissionsButton } from '../../components/contexts/permissions/MorePermissionsButton';
import {
  getAttributesListPerRole,
  setArticlesModal,
  setArticlesResourceId,
} from 'store/morePermissions/morePermissions-slice';
import {
  getResourcesOptions,
  getRoleEditDetail,
  roleNameUpdate,
  setRoleStatus,
  statusRoleModal,
} from 'store/roles/roles-slice';
import { startLoading, stopLoading } from 'store/loading/loading-slice';

const RolesEdit = () => {
  const { openStatusModal, status, msg, role, selectId } = useSelector(
    (state) => state.roles,
  );
  const resourcesItems = useSelector((state) => state.roles.resources);
  const { permissions } = useSelector((state) => state.auth);

  const [changeToMarkOffAll, setChangeToMarkOffAll] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showModalConfirmationCancel, setShowModalConfirmationCancel] =
    useState(false);
  const [roleName, setRoleName] = useState(role.name);
  const [newResourceList, setNewResourceList] = useState([]);

  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    if (getPermission('roles', permissions, 'update') === false) {
      history.push('/');
    }
  }, [history, permissions]);

  const cancelRoleEdit = () => {
    history.push(urls.ROUTES.ACCESS.items.roles.path);
  };

  useEffect(() => {
    dispatch(getResourcesOptions());
  }, [dispatch]);

  function getArr() {
    let obj = {};
    return resourcesItems.map((resource) => {
      const found = role.permissions.filter(
        (item) => item.resourceId === resource.resourceId,
      );

      if (found.length > 0) {
        obj = {
          id: found[0]?.id,
          resourceId: found[0]?.resourceId,
          roleId: role.id,
          name: found[0]?.name,
          translatedName: found[0]?.translatedName,
          canList: found[0]?.canList,
          canCreate: found[0]?.canCreate,
          canUpdate: found[0]?.canUpdate,
          canDelete: found[0]?.canDelete,
          canAccess: found[0]?.canAccess,
        };
      } else {
        obj = {
          resourceId: resource.resourceId,
          roleId: role.id,
          name: resource.name,
          translatedName: resource.translatedName,
          canCreate: false,
          canUpdate: false,
          canDelete: false,
          canAccess: false,
        };
      }
      return obj;
    });
  }

  useEffect(() => {
    let arr = getArr();
    setNewResourceList(arr);
  }, [resourcesItems, role.permissions, role.id]);

  const saveRoleName = () => {
    const data = {
      name: roleName,
    };
    dispatch(roleNameUpdate({ info: data, id: selectId }));
  };

  const saveRole = async () => {
    dispatch(startLoading());
    let errors = 0;
    let roleInfo = await api.get(`${ROLES}/${selectId}`);
    let currentPermissions = roleInfo.data.permissions;

    newResourceList.forEach(async (resource) => {
      let existsPermission = currentPermissions.find(
        (currentPermission) =>
          currentPermission.resourceId === resource.resourceId,
      );
      if (resource.id !== undefined || existsPermission !== undefined) {
        const data = {
          id:
            existsPermission !== undefined ? existsPermission.id : resource.id,
          resourceId: resource.resourceId,
          roleId: role.id,
          canCreate: resource.canCreate,
          canUpdate: resource.canCreate,
          canList: resource.canList,
          canDelete: resource.canDelete,
          canAccess: resource.canAccess,
        };

        await api.put(`${PERMISSIONS}/${resource.id}`, data);
      } else {
        const data = {
          resourceId: resource.resourceId,
          roleId: role.id,
          canCreate: resource.canCreate,
          canUpdate: resource.canCreate,
          canList: resource.canList,
          canDelete: resource.canDelete,
          canAccess: resource.canAccess,
        };

        await api.post(PERMISSIONS, data).catch(async (error) => {
          if (error?.response?.status === 409) {
            roleInfo = await api.get(`${ROLES}/${selectId}`);
            currentPermissions = roleInfo.data.permissions;
            let permissionFounded = currentPermissions.find(
              (currentPermission) =>
                currentPermission.resourceId === resource.resourceId,
            );

            await api.put(`${PERMISSIONS}/${permissionFounded.id}`, {
              ...data,
              id: permissionFounded.id,
            });
          } else {
            errors += 1;
          }
        });
      }
    });

    setShowConfirmModal(false);
    dispatch(setRoleStatus(errors > 0 ? 'error' : 'success'));
    dispatch(stopLoading());

    history.push(urls.ROUTES.ACCESS.items.roles.path);
  };

  useEffect(() => {
    if (selectId === 0) {
      history.push(urls.ROUTES.ACCESS.items.roles.path);
    } else {
      dispatch(getRoleEditDetail(selectId));
    }
  }, [selectId, dispatch, history]);

  useEffect(() => {
    setRoleName(role.name);
  }, [role, dispatch]);

  const handleChangeAll = () => {
    let tempResource = [];

    tempResource = newResourceList.map((resource) => {
      const obj = {
        id: resource.id,
        resourceId: resource.resourceId,
        name: resource.name,
        translatedName: resource.translatedName,
        canAccess: !changeToMarkOffAll,
        canCreate: !changeToMarkOffAll,
        canUpdate: !changeToMarkOffAll,
        canDelete: !changeToMarkOffAll,
      };
      return obj;
    });

    setNewResourceList(tempResource);
  };

  function verifyChanges() {
    let arr = getArr();
    if (roleName !== role.name) {
      return false;
    }
    if (JSON.stringify(newResourceList) !== JSON.stringify(arr)) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    let tempResource = [];

    tempResource = newResourceList.filter((resource) => {
      return (
        resource.canAccess === true &&
        resource.canCreate === true &&
        resource.canUpdate === true &&
        resource.canDelete === true
      );
    });

    if (tempResource.length === newResourceList.length) {
      setChangeToMarkOffAll(true);
    } else {
      setChangeToMarkOffAll(false);
    }
  }, [newResourceList]);

  const handleChange = (e, id, method) => {
    const { checked } = e.target;

    let tempResource = [];
    let markPermissions = [];

    switch (method) {
      case 'canList':
        if (checked) {
          tempResource = newResourceList.map((resource) =>
            resource.resourceId === id
              ? {
                  ...resource,
                  canAccess: checked,
                  canCreate: false,
                  canUpdate: false,
                  canDelete: false,
                }
              : resource,
          );
        } else {
          tempResource = newResourceList.map((resource) =>
            resource.resourceId === id
              ? {
                  ...resource,
                  canAccess: checked,
                  canCreate: false,
                  canUpdate: false,
                  canDelete: false,
                }
              : resource,
          );
        }
        setNewResourceList(tempResource);
        break;
      case 'canCreateUpdate':
        if (checked) {
          markPermissions = {
            canCreate: checked,
            canUpdate: checked,
            canAccess: checked,
            canDelete: false,
          };
        } else {
          markPermissions = {
            canCreate: checked,
            canUpdate: checked,
            canDelete: false,
          };
        }

        tempResource = newResourceList.map((resource) =>
          resource.resourceId === id
            ? {
                ...resource,
                ...markPermissions,
              }
            : resource,
        );
        setNewResourceList(tempResource);
        break;
      case 'canDelete':
        if (checked) {
          markPermissions = {
            canCreate: checked,
            canUpdate: checked,
            canAccess: checked,
            canDelete: checked,
          };
        } else {
          markPermissions = {
            canDelete: checked,
          };
        }

        tempResource = newResourceList.map((resource) =>
          resource.resourceId === id
            ? {
                ...resource,
                ...markPermissions,
              }
            : resource,
        );
        setNewResourceList(tempResource);
        break;

      default:
        break;
    }
  };

  const textBox = (
    <p>
      Alguns resources possuem dependências entre si e precisam ter o mesmo
      nível de permissão. São eles:
      <br />
      <br />
      i. Perfis, Recursos e Permissões
      <br />
      ii. SKUs e Preços
    </p>
  );

  return (
    <>
      <MorePermissionsModal />
      <ModalMultiConfirmation
        title={intl.messages['textModal.roleNameEditConfirm']}
        isOpen={showConfirmModal}
        setIsOpen={setShowConfirmModal}
        buttonNameBack={intl.messages['buttons.not']}
        buttonName={intl.messages['buttons.yes']}
        confirmOnClick={() => {
          saveRoleName();
          saveRole();
        }}
        status="confirm"
      />
      <ModalMultiConfirmation
        title={intl.messages['textModal.areYouSure']}
        text={intl.messages['textModal.cancelEditRole']}
        isOpen={showModalConfirmationCancel}
        setIsOpen={setShowModalConfirmationCancel}
        buttonNameBack={intl.messages['buttons.not']}
        buttonName={intl.messages['buttons.yes']}
        confirmOnClick={cancelRoleEdit}
        status="info"
      />
      <ModalMultiConfirmation
        title={msg.title}
        text={msg.text}
        setIsOpen={(payload) => {
          dispatch(statusRoleModal(payload));
          if (status !== 'error') {
            history.push(urls.ROUTES.ACCESS.items.roles.path);
          }
        }}
        isOpen={openStatusModal}
        status={status}
      />
      <FormContainer title={intl.messages['textHeader.editRole']}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item md={12}>
                <InputText
                  placeholder={intl.messages['labelsInputs.typeYourName']}
                  label={intl.messages['labelsInputs.typeYourName']}
                  value={roleName}
                  handleChange={setRoleName}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12}>
          <PermissionsTitle>Perfis permissões</PermissionsTitle>
        </Grid>

        <Grid item md={9}>
          <BoxAlert title="Atenção" text={textBox} />
        </Grid>

        <Grid item md={9}>
          <ButtonPermissionsContent>
            <Title>Acesso de administrador</Title>

            <Button
              type="checkbox"
              btnType={changeToMarkOffAll ? 'secondary' : 'primary'}
              name="allSelect"
              width="28%"
              height="40px"
              borderRadius="4px"
              fontWeight="600"
              onClick={() => {
                handleChangeAll();
              }}
            >
              {changeToMarkOffAll ? 'Desmarcar tudo' : 'Selecionar tudo'}
            </Button>
          </ButtonPermissionsContent>
          <Line />
        </Grid>
        <Table>
          <TableBody>
            {newResourceList.map((resource) => (
              <Tr key={resource.resourceId}>
                <Td>{resource.name && resource.translatedName}</Td>
                <Td>
                  <Checkbox
                    label="Ver"
                    name={resource.canAccess}
                    checked={resource.canAccess}
                    onChange={(e) =>
                      handleChange(e, resource.resourceId, 'canList')
                    }
                  />
                </Td>
                <Td>
                  <Checkbox
                    label="Criar/Editar"
                    name={resource.canCreate}
                    checked={resource.canCreate}
                    onChange={(e) =>
                      handleChange(e, resource.resourceId, 'canCreateUpdate')
                    }
                  />
                </Td>
                <Td>
                  <Checkbox
                    label="Deletar"
                    name={resource.canDelete}
                    checked={resource?.canDelete}
                    onChange={(e) =>
                      handleChange(e, resource.resourceId, 'canDelete')
                    }
                  />
                </Td>
                <Td>
                  <MorePermissionsButton
                    visible={resource.name === 'articles'}
                    actionFunction={() => {
                      dispatch(setArticlesModal(true));
                      dispatch(getAttributesListPerRole(selectId));
                      dispatch(setArticlesResourceId(selectId));
                    }}
                  />
                </Td>
              </Tr>
            ))}
          </TableBody>
        </Table>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Grid container>
              <ButtonsContainer>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <Button
                      btnType="secondary"
                      width="100%"
                      borderRadius="4px"
                      fontWeight="600"
                      onClick={() => {
                        if (!verifyChanges()) {
                          setShowModalConfirmationCancel(true);
                        } else {
                          cancelRoleEdit();
                        }
                      }}
                    >
                      {intl.messages['buttons.cancel']}
                    </Button>
                  </Grid>
                  <Grid item md={6}>
                    <Button
                      btnType="primary"
                      width="100%"
                      borderRadius="4px"
                      fontWeight="600"
                      onClick={() => {
                        saveRoleName();
                        saveRole();
                      }}
                    >
                      {intl.messages['buttons.toSave']}
                    </Button>
                  </Grid>
                </Grid>
              </ButtonsContainer>
            </Grid>
          </Grid>
        </Grid>
      </FormContainer>
    </>
  );
};

export default RolesEdit;
