import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from 'components/structure/ActionButton/ActionButton';
import PropTypes from 'prop-types';
import { Centered } from './ActionButtons.styles';

const ActionButtons = ({ type, cancelPath, submitFunc }) => {
  return (
    <Centered>
      <Grid container spacing={3}>
        <Grid item md={1} sm={0} />
        <Grid item md={5} sm={12}>
          <Button
            width="100%"
            btnType="secondary"
            borderRadius="5px"
            onClick={() => cancelPath()}
          >
            <b>CANCELAR</b>
          </Button>
        </Grid>

        <Grid item md={5} sm={12}>
          <Button
            width="100%"
            btnType="primary"
            borderRadius="5px"
            fontWeight="bold"
            onClick={() => submitFunc()}
          >
            {type === 'create' ? 'CRIAR' : 'SALVAR'}
          </Button>
        </Grid>
        <Grid item md={1} sm={0} />
      </Grid>
    </Centered>
  );
};

export default ActionButtons;

ActionButtons.propTypes = {
  type: PropTypes.string.isRequired,
  cancelPath: PropTypes.func.isRequired,
  submitFunc: PropTypes.func.isRequired,
};
