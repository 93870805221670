import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import InputText from 'components/form/Text/TextInput/InputText';
import SelectSearch from 'components/form/Select/ReactSelect';
import Grid from '@material-ui/core/Grid';
import Button from 'components/structure/Button/Button';
import Modal from 'components/structure/Modal/Modal';
import { types } from '../dataProperties';

const AddPropertyModalForm = ({
  name,
  setName,
  type,
  setType,
  isOpen,
  setIsOpen,
  cancelActionModal,
  confirmActionModal,
  loading,
  nameError,
  typeError,
}) => {
  const intl = useIntl();

  const modalContent = (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Grid container>
            <Grid item xs={12}>
              <InputText
                placeholder={intl.messages['labelsInputs.typeYourName']}
                label={intl.messages['labelsInputs.typeYourName']}
                value={name}
                handleChange={setName}
                error={nameError.type === 'error' ? nameError.message : ''}
                isError={nameError.type && name === ''}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                paddingTop: nameError.type && name === '' ? '0px' : '21px',
              }}
            >
              <SelectSearch
                label={intl.messages['labelsInputs.type']}
                placeholder={intl.messages['labelsInputs.select']}
                options={types}
                defaultOption={type}
                handleChange={setType}
                error={typeError.type === 'error' ? typeError.message : ''}
                isError={type === '' && typeError.type}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{
          paddingTop:
            (type === '' && typeError.type && nameError.type && name === '') ||
            (type === '' && typeError.type)
              ? '0px'
              : '21px',
        }}
      >
        <Grid item md={6} xs={12}>
          <Button
            type="button"
            btnType="secondary"
            width="100%"
            height="48px"
            borderRadius="4px"
            uppercase
            onClick={cancelActionModal}
            fontWeight="600"
          >
            {intl.messages['buttons.cancel']}
          </Button>
        </Grid>
        <Grid item md={6} xs={12}>
          <Button
            btnType="primary"
            width="100%"
            height="48px"
            borderRadius="4px"
            uppercase
            fontWeight="600"
            onClick={confirmActionModal}
          >
            {intl.messages['buttons.create']}
          </Button>
        </Grid>
      </Grid>
    </>
  );

  return (
    <Modal
      title={intl.messages['textModal.createProperty']}
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      content={modalContent}
      loading={loading}
      height="356px"
      width="562px"
    />
  );
};

AddPropertyModalForm.propTypes = {
  name: PropTypes.string,
  setName: PropTypes.func,
  type: PropTypes.string,
  setType: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  cancelActionModal: PropTypes.func,
  confirmActionModal: PropTypes.func,
  loading: PropTypes.number,
  nameError: PropTypes.object,
  typeError: PropTypes.object,
};

AddPropertyModalForm.defaultProps = {
  name: '',
  setName: () => {},
  type: '',
  setType: () => {},
  isOpen: false,
  setIsOpen: () => {},
  cancelActionModal: () => {},
  confirmActionModal: () => {},
  loading: 0,
  nameError: {
    type: '',
    message: '',
  },
  typeError: {
    type: '',
    message: '',
  },
};

export default AddPropertyModalForm;
