/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropertiesComponent from 'components/contexts/products/PropertiesComponent/PropertiesComponent';
import Grid from '@material-ui/core/Grid';
import Button from 'components/structure/Button/Button';
import BoxAlert from 'components/structure/BoxAlert/BoxAlert';
import urls from 'constants/urls';
import ModalMultiConfirmation from 'components/contexts/modal/ModalMultiConfirmation/ModalMultiConfirmation';

import { v4 as uuidv4 } from 'uuid';
import { errorToast, successToast } from 'utils/toasts';
import FileInput from 'components/form/FileInput/FileInput';
import axios from 'axios';

import { Divider } from 'components/structure/PopOver/PopOver.styles';
import { createSlug } from 'utils/createSlug';
import { ButtonsContainer } from './ProductsCreateSteps.styles';
import api from '../../../services/api';
import { PropertiesTitle } from '../Products.styles';
import { ImageRegisters, rules } from 'containers/UploadFilesImage';
import {
  getProductDetail,
  putProduct,
  setProductActiveStep,
  setProductId,
  statusProductsModal,
} from 'store/products/products-slice';
import { startLoading, stopLoading } from 'store/loading/loading-slice';

const ProductsSecondStep = () => {
  const { selectId, openStatusModal, status, msg, product, fispqUrl, btUrl } =
    useSelector((state) => state.products);
  const [imageError, setImageError] = useState(null);
  const [showModalCancel, setShowModalCancel] = useState(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [showModalBackFirstStep, setShowModalBackFirstStep] = useState(false);
  const property = 0;
  const valueProperty = null;

  const [properties, setProperties] = useState([
    {
      idProp: uuidv4(),
      id: property,
      value: valueProperty,
      type: '',
    },
  ]);

  const [imageFile, setImageFile] = useState([{}]);
  const [altImage, setAltImage] = useState('');
  const [fispqFilename, setFispqFilename] = useState('');
  const [btFileName, setBtFileName] = useState('');
  const [dataFileFispq, setDataFileFispq] = useState([{}]);
  const [dataFileBt, setDataFileBt] = useState([{}]);

  const fispqUrlName = fispqUrl.url;
  const btUrlName = btUrl.url;

  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProductDetail(parseInt(selectId, 10)));
  }, [selectId, dispatch]);

  const cancelProductCreate = () => {
    history.push(urls.ROUTES.CATALOGUE.items.products.path);
  };

  const editProduct = useCallback(
    (id) => {
      dispatch(setProductId(id));
      history.push(urls.ROUTES.PRODUCTS_EDIT.path);
      dispatch(setProductActiveStep(0));
    },
    [dispatch, history],
  );

  const backPreviousStep = () => {
    editProduct(selectId);
  };

  const handleAdd = () => {
    setProperties([
      ...properties,
      { idProp: uuidv4(), id: 0, value: null, type: '' },
    ]);
  };

  const handleDelete = (id) => {
    const values = [...properties];
    values.splice(
      values.findIndex((value) => value.idProp === id),
      1,
    );
    setProperties(values);
  };

  const uploadShowcaseImage = async (data) => {
    dispatch(startLoading());
    const formData = new FormData();
    formData.append('context', 'showcase');
    formData.append('files', data.file);
    formData.append('entity', 'products');
    formData.append('alternativeText', JSON.stringify([data.altText]));
    await api
      .put(`/products/${selectId}/image`, formData)
      .then((response) => {
        setImageFile((prev) => {
          return {
            ...prev,
            name: response.data[1].fullUrl,
            altText: response.data[2]?.fullUrl || '',
          };
        });
        successToast('Imagem salva com sucesso!');
      })
      .catch(() => {
        errorToast('Erro ao salvar imagem!');
      })
      .finally(() => dispatch(stopLoading()));
  };

  const handleFileWasChanged = (event) => {
    if (event.target.files.length !== 0) {
      const file = event.target.files[0];
      if (file.size < 1.5 * 1024 * 1024) {
        if (
          ['jpg', 'gif', 'bmp', 'jpeg', 'webp', 'png', 'svg'].includes(
            file.name.split('.').pop(),
          )
        ) {
          uploadShowcaseImage({
            ...event,
            file: event.target.files[0],
            name: event.target.files[0].name,
            altText: altImage,
            entity: 'products',
          });
        } else {
          errorToast(
            'A imagem precisa estar em um desses formatos: .png, .jpg, .gif, etc...',
          );
        }
      } else {
        errorToast('A imagem deve ter no máximo 1.5 MB');
      }
    }
  };

  const handleChangeInput = (id, event, type) => {
    const newInputFields = properties.map((i) => {
      if (id === i.idProp) {
        i[event.target?.name] = event.target?.value;
        i.type = type;
      }

      return i;
    });

    setProperties(newInputFields);
  };

  const typeCast = {
    string: (value) => String(value),
    boolean: (value) => Boolean(value),
    integer: (value) => parseInt(value, 10),
    float: (value) => Number(value),
    double: (value) => Number(value),
    timestamp: (value) => new Date(value),
  };

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsArrayBuffer(file);
    });
  }

  async function fetchData(url, dataFileType) {
    try {
      const dataFile =
        dataFileType === 'fispq' ? dataFileFispq.file : dataFileBt.file;
      const file = await readFile(dataFile);

      await axios.put(url, file, {
        headers: {
          'Content-Type': dataFile.type,
          'Access-Control-Allow-Origin': '*',
        },
      });
    } catch (error) {
      /// TODO
      console.log(error);
    }
  }

  const saveAttachments = async () => {
    dispatch(startLoading());
    if (fispqFilename && fispqFilename !== '') {
      const formData = new FormData();

      const fileExt = fispqFilename.split('.').pop();
      formData.append('name', `${createSlug(product.name)}.${fileExt}`);
      formData.append('service', 'catalog');
      formData.append('entity', 'products');
      formData.append('id', product.id);
      formData.append('type', 'docs');
      formData.append('context', 'fispq');

      const presignedUrl = await api.post('presigned', formData);
      await fetchData(presignedUrl.data.url, 'fispq');
    }
    if (btFileName && btFileName !== '') {
      const formData = new FormData();

      const fileExt = btFileName.split('.').pop();
      formData.append('name', `${createSlug(product.name)}.${fileExt}`);
      formData.append('service', 'catalog');
      formData.append('entity', 'products');
      formData.append('id', product.id);
      formData.append('type', 'docs');
      formData.append('context', 'bt');
      formData.append('fileName', imageFile.name);
      formData.append('image', imageFile);
      formData.append('alternativeText', [`${altImage ?? 'text default'}`]);

      const presignedUrl = await api.post('presigned', formData);
      await fetchData(presignedUrl.data.url, 'bt');
    }
    dispatch(stopLoading());
  };

  const saveStepTwo = () => {
    const arrayCategories = [];
    arrayCategories.push(
      { id: product?.categories?.[0]?.id, main: true },
      { id: product?.categories?.[1]?.id, main: false },
      { id: product?.categories?.[2]?.id, main: false },
      { id: product?.categories?.[3]?.id, main: false },
      { id: product?.categories?.[4]?.id, main: false },
      { id: product?.categories?.[5]?.id, main: false },
    );

    const newArrayCategories = arrayCategories.filter(
      (item) => item.id !== undefined,
    );

    const formData = new FormData();
    const propertiesConversion = properties.map((currentProperties) => ({
      id: parseInt(currentProperties.id, 10),
      value: typeCast[currentProperties.type]?.(currentProperties.value),
    }));
    const propertiesValidation = properties.filter((item) => item.type === '');

    formData.append('categories', JSON.stringify(newArrayCategories));
    formData.append('properties', JSON.stringify(propertiesConversion));

    if (propertiesValidation.length) {
      dispatch(statusProductsModal(false));
      history.push(urls.ROUTES.CATALOGUE.items.products.path);
      successToast('Produto criado com sucesso!');
    } else {
      dispatch(putProduct({ info: formData, id: selectId, goTo: 1 }));
      dispatch(statusProductsModal(false));
      if (status !== 'error') {
        history.push(urls.ROUTES.CATALOGUE.items.products.path);
        successToast('Produto criado com sucesso!');
      }
    }
  };

  const textBox =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sit non vestibulum at.';

  return (
    <>
      <ModalMultiConfirmation
        title={intl.messages['textModal.createProductConfirm']}
        isOpen={showModalConfirmation}
        setIsOpen={setShowModalConfirmation}
        buttonNameBack={intl.messages['buttons.not']}
        buttonName={intl.messages['buttons.yes']}
        status="confirm"
        confirmOnClick={async () => {
          if (dataFileBt.length !== 0 || dataFileFispq.length !== 0) {
            await saveAttachments();
          }
          saveStepTwo();
          uploadShowcaseImage();
        }}
      />
      <ModalMultiConfirmation
        title={intl.messages['textModal.deleteProductCreate']}
        text={intl.messages['textModal.productWillBeLost']}
        isOpen={showModalCancel}
        setIsOpen={setShowModalCancel}
        buttonNameBack={intl.messages['buttons.not']}
        buttonName={intl.messages['buttons.yes']}
        confirmOnClick={cancelProductCreate}
        status="info"
      />
      <ModalMultiConfirmation
        title={intl.messages['textModal.goBackPreviousStep']}
        text={intl.messages['textModal.modificationsWillBeLost']}
        isOpen={showModalBackFirstStep}
        setIsOpen={setShowModalBackFirstStep}
        buttonNameBack={intl.messages['buttons.not']}
        buttonName={intl.messages['buttons.yes']}
        confirmOnClick={() => backPreviousStep()}
        status="info"
      />
      <ModalMultiConfirmation
        title={msg.title}
        text={msg.text}
        setIsOpen={(payload) => {
          dispatch(statusProductsModal(payload));
          if (status !== 'error') {
            history.push(urls.ROUTES.CATALOGUE.items.products.path);
          }
        }}
        isOpen={openStatusModal}
        status={status}
      />
      <Grid container spacing={4}>
        <Grid item xs={12} md={5}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <BoxAlert title="Atenção" text={textBox} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ paddingBottom: '30px' }} />
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item md={5}>
            <FileInput
              label={intl.messages['labelsInputs.fispq']}
              text={intl.messages['labelsInputs.toDoUploadFile']}
              title="O arquivo precisa estar em formato .pdf, realize a conversão para o formato aceito antes de fazer o upload"
              accept=".zip"
              fileName={fispqFilename}
              getFile={(event) => {
                if (event.target.files.length !== 0) {
                  const file = event.target.files[0];
                  if (/.zip$/.test(file.name)) {
                    setFispqFilename(file.name);
                    setDataFileFispq({ ...dataFileFispq, file });
                  } else {
                    errorToast('O arquivo precisa ser no formato .zip');
                  }
                }
              }}
              optional
            />
          </Grid>
          <Grid item md={5}>
            <FileInput
              label={intl.messages['labelsInputs.technical']}
              text={intl.messages['labelsInputs.toDoUploadFile']}
              title="O arquivo precisa estar em formato .pdf, realize a conversão para o formato aceito antes de fazer o upload"
              accept=".pdf"
              fileName={btFileName}
              getFile={(event) => {
                if (event.target.files.length !== 0) {
                  const file = event.target.files[0];
                  if (/.pdf$/.test(file.name)) {
                    setBtFileName(file.name);
                    setDataFileBt({ ...dataFileBt, file });
                  } else {
                    errorToast('O arquivo precisa ser no formato .pdf');
                  }
                }
              }}
              optional
            />
          </Grid>
          <ImageRegisters
            showcaseFileName={imageFile.name}
            handleFileChange={handleFileWasChanged}
            showcaseAltText={altImage}
            handleAltText={setAltImage}
            rule={rules.CREATE}
            showcase
          />
        </Grid>
      </Grid>
      <div style={{ paddingBottom: '30px' }} />
      <Grid container spacing={4}>
        <Grid item xs={12} md={5}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <BoxAlert
                title="Atenção"
                text="Para cadastrar as imagens que serão exibidas no carrossel da PDP, deve-se selecionar seus tamanhos(SKU) primeiro; em seguida, atualizar o produto com suas imagens."
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ paddingBottom: '30px' }} />
      <Divider />
      <div style={{ paddingBottom: '30px' }} />
      <PropertiesTitle>Propriedades</PropertiesTitle>
      <Grid item md={12}>
        {properties.map((item) => (
          <div key={item.idProp}>
            <PropertiesComponent
              property={parseInt(item.id, 10)}
              valueProperty={item.value}
              setProperty={(event, type) =>
                handleChangeInput(item.idProp, event, type)
              }
              setValueProperty={(event, type) =>
                handleChangeInput(item.idProp, event, type)
              }
              handleDeleteProperty={() => {
                if (properties.length > 1) {
                  handleDelete(item.idProp);
                }
              }}
              handleAddProperty={() => {
                if (item.value) {
                  handleAdd();
                }
              }}
              properties={properties}
              objectProperty={item}
            />
          </div>
        ))}
      </Grid>
      <ButtonsContainer>
        <Grid container spacing={2}>
          <Grid item md={3} xs={12}>
            <Button
              btnType="blue"
              width="100%"
              fontWeight="600"
              onClick={() => backPreviousStep()}
            >
              {intl.messages['buttons.toBack']}
            </Button>
          </Grid>
          <Grid item md={3} sm={12} />
          <Grid item md={3} xs={12}>
            <Button
              btnType="secondary"
              width="100%"
              borderRadius="4px"
              fontWeight="600"
              onClick={() => setShowModalCancel(true)}
            >
              {intl.messages['buttons.cancel']}
            </Button>
          </Grid>

          <Grid item md={3} sm={12}>
            <Button
              btnType="primary"
              width="100%"
              borderRadius="4px"
              fontWeight="600"
              onClick={() => setShowModalConfirmation(true)}
            >
              {intl.messages['buttons.toSave']}
            </Button>
          </Grid>
        </Grid>
      </ButtonsContainer>
    </>
  );
};

export default ProductsSecondStep;
