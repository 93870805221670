import React from 'react';
import PropTypes from 'prop-types';
import { StyledInput } from './Slider.styles';

const Slider = ({ minValue, maxValue, onChange, value }) => {
  return (
    <StyledInput
      min={minValue}
      max={maxValue}
      onInput={onChange}
      value={value}
      type="range"
    />
  );
};

Slider.propTypes = {
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func,
};

Slider.defaultProps = {
  minValue: 0,
  maxValue: 0,
  value: 0,
  onChange: () => {},
};

export default Slider;
