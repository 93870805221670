import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 0;
  margin-top: 32px;
`;
