export const getPermission = (key, permissionsArray, type) => {
  if (permissionsArray.length === 0) {
    return false;
  }

  const found = permissionsArray.find((permission) => permission.name === key);
  if (found) {
    switch (type) {
      case 'create':
        return found.canCreate;
      case 'update':
        return found.canUpdate;
      case 'delete':
        return found.canDelete;
      case 'list':
        return found.canList;
      case 'access':
        return found.canAccess;
      default:
        return false;
    }
  }

  return false;
};

export const getPublishArticlePermission = (morePermissionsArray) => {
  if (morePermissionsArray) {
    if (morePermissionsArray?.length === 0) {
      return false;
    }

    const found = morePermissionsArray.find(
      (attribute) => attribute.slug === 'botao-publicar',
    );

    if (found) {
      return found?.value;
    }
    return false;
  }
  return false;
};

export const getPublishArticleAttributeId = (morePermissionsArray) => {
  if (morePermissionsArray) {
    const found = morePermissionsArray.find(
      (attribute) => attribute.slug === 'botao-publicar',
    );
    if (found) {
      return found?.id;
    }
  }
  return undefined;
};
