import React from 'react';
import PropTypes from 'prop-types';
import SpanError from 'components/structure/SpanError/SpanError';
import errorIcon from 'assets/icons/ic-x-error.svg';
import {
  Container,
  Label,
  InputStyle,
  OptionalStyle,
  LabelContainer,
} from './InputText.styles';

const InputText = ({
  type,
  id,
  name,
  isError,
  error,
  children,
  isSearch,
  disabled,
  placeholder,
  secondary,
  register,
  optional,
  ...rest
}) => {
  return (
    <Container>
      <LabelContainer>
        {!isSearch && (
          <Label htmlFor={name} isError={isError} secondary={secondary}>
            {children}
          </Label>
        )}
        {optional && <OptionalStyle>(Opcional)</OptionalStyle>}
      </LabelContainer>
      <InputStyle
        type={type}
        id={id}
        name={name}
        isError={isError}
        isSearch={isSearch}
        placeholder={placeholder}
        disabled={disabled}
        secondary={secondary}
        ref={register}
        {...rest}
      />

      {isError && <SpanError>{error.message}</SpanError>}
      {isError && <img src={errorIcon} alt="" className="error-icon" />}
    </Container>
  );
};

InputText.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.node,
  isError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  error: PropTypes.any,
  disabled: PropTypes.bool,
  isSearch: PropTypes.bool,
  placeholder: PropTypes.string,
  secondary: PropTypes.bool,
  register: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  optional: PropTypes.bool,
};

InputText.defaultProps = {
  type: '',
  id: '',
  name: '',
  children: null,
  error: '',
  isError: false,
  disabled: false,
  isSearch: false,
  placeholder: '',
  secondary: false,
  register: undefined,
  optional: false,
};

export default InputText;
