import styled, { css } from 'styled-components';

export const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  justifycontent: center;
  alignitems: flex-start;

  list-style: none;
  margin-bottom: 1.2em;
  background-color: #f55e3038;
  border-radius: 5px;
  padding: 1.2em 0.7em;
  max-width: 335px;
  .card {
    display: flex;
    file-direction: row;
    justifycontent: space-between;
    alignitems: center;
    margin-bottom: 0.7em;

    svg {
      color: #f55321;
      font-size: 1.2em;
      margin: 0 0.8em;
      width: 18px;
    }

    .icon-file {
      min-width: 18px;
      min-height: 24px;
    }

    p {
      flex: 1;
      font-size: 0.9rem;
    }

    .action {
      justify-self: flex-end;
      cursor: pointer;
      ${'' /* remove all button default styles */}
      background: none;
      border: none;
      outline: none;
    }
  }
`;

export const TableContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-top: 0.5em;
  margin-bottom: 1em;

  tr {
    th {
      font-family: 'Trebuchet MS';
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      color: #999;
      text-align: left;

      border-bottom: 1px solid #e8e8e8;

      padding-right: 50px;
      padding-bottom: 5px;
      &:nth-child(2) {
        width: 100%;
      }
    }
    td {
      font-family: 'Trebuchet MS';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      color: #666;
      text-align: left;
      padding: 10px 0;
      border-bottom: 1px solid #e8e8e8;

      min-width: 100px;

      a {
        margin-right: 10px;
      }
    }
  }
`;

export const LinkItem = styled.a`
  ${({ theme }) => css`
    text-decoration: none;
    color: ${theme.colors.black.light};
    cursor: pointer;

    width: 10%;

    img {
      width: ${theme.typography.sizes.md};
    }
  `}
`;
