import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 5% 10% 0;
    background: ${theme.colors.white.default};
    border-top-left-radius: 30px;
    overflow-y: scroll;
    height: 100vh;

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: ${theme.colors.primary.hover};
    }
  `}
`;

export const FormTitle = styled.h3`
  ${({ theme }) => css`
    color: ${theme.colors.tertiary.default};
    font-family: 'Suvinil Sans';
    font-weight: bold;
    font-size: 24px;
    padding-bottom: 30px;
  `}
`;
