import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import PageHeader from 'components/structure/PageHeader/PageHeader';
import PageContent from 'components/structure/PageContent/PageContent';
import EmptySearch from 'components/structure/EmptySearch/EmptySearch';
import TableDefault from 'components/structure/Table/Table';

import { useDispatch, useSelector } from 'react-redux';
import { getLogsList } from 'store/logs/logs-slice';

const ActivitiesLogs = () => {
  const { items, totalPage } = useSelector((state) => state.logs.logs);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [ordenation, setOrdenation] = useState('');
  const [totalPerPage, setTotalPerPage] = useState({ value: 10 });
  const perPage = totalPerPage.value;

  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  useEffect(() => {
    dispatch(getLogsList({ page, searchTerm, perPage, ordenation }));
  }, [dispatch, page, searchTerm, perPage, ordenation]);

  const COLUMNS = useMemo(
    () => [
      {
        Header: 'Data e hora',
        accessor: ({ createdAt }) => new Date(createdAt).toLocaleString(),
      },
      {
        Header: 'Status',
        accessor: 'translatedStatus',
      },
      {
        Header: 'Entidade',
        accessor: ({ translatedEntity, entityId }) =>
          `${translatedEntity} (ID: ${entityId})`,
      },
      {
        Header: 'Ação',
        accessor: 'translatedAction',
      },
      {
        Header: 'Usuário',
        accessor: ({ user }) => user.email,
      },
    ],
    [],
  );

  const data = useMemo(() => items, [items]);

  const optionsOrdenation = [
    { value: 'user', label: 'Usuário' },
    { value: 'entity', label: 'Entidade' },
    { value: 'status', label: 'Status' },
    { value: 'createdAt', label: 'Data e Hora' },
    { value: 'action', label: 'Ação' },
  ];

  return (
    <>
      <PageHeader
        title={intl.messages['textHeader.activitiesLogs']}
        onClickFilter={() => {}}
        search
        searchFunc={(searchData) => setSearchTerm(searchData)}
        placeholder="Busque por Usuário"
        optionsOrdenation={optionsOrdenation}
        ordenation={ordenation}
        setOrdenation={setOrdenation}
      />
      <PageContent>
        <EmptySearch items={data} term={searchTerm}>
          <TableDefault
            columns={COLUMNS}
            data={data}
            pageCount={totalPage}
            defaultCurrentPage={page}
            setPageCurrent={setPage}
            totalPerPage={totalPerPage}
            setTotalPerPage={setTotalPerPage}
          />
        </EmptySearch>
      </PageContent>
    </>
  );
};

export default ActivitiesLogs;
