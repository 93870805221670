/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FaChevronDown } from 'react-icons/fa';
import {
  Container,
  SelectGroup,
  SelectGroupBox,
  SelectGroupDropdown,
} from './SelectTable.styles';

const SelectTable = ({
  options,
  isFooter,
  topDropdown,
  placeholder,
  onChange,
  disabled,
  numTrunc,
  noBorder,
  selected,
  ...attrs
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [open, setOpen] = useState(false);

  const selectRef = useRef(null);

  useEffect(() => {
    if (typeof selected === 'object' && selected.value) {
      setSelectedOption(
        options.find(
          (option) => option.value === selected.value || option.selected,
        ),
      );
    } else if (typeof selected === 'number' || typeof selected === 'string') {
      setSelectedOption(
        options.find((option) => option.value === selected || option.selected),
      );
    }
  }, [selected, options]);

  useEffect(() => setOpen(false), [selectedOption]);

  useEffect(() => {
    const selectNode = selectRef.current;

    document.addEventListener('click', (e) => {
      if (e.target !== selectNode && !selectNode.contains(e.target)) {
        setOpen(false);
      }
    });
  }, []);

  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return `${str.slice(0, num)}...`;
  };

  const handleChange = (option) => {
    setSelectedOption(option);
    onChange(option);
  };

  return (
    <Container>
      <span>Itens por página:</span>

      <SelectGroup
        ref={selectRef}
        noBorder={noBorder}
        disabled={disabled}
        {...attrs}
      >
        <SelectGroupBox
          onClick={() => setOpen(!open)}
          disabled={disabled}
          type="button"
        >
          {selectedOption ? (
            <span>{truncateString(selectedOption.label, numTrunc)}</span>
          ) : (
            <span>{placeholder}</span>
          )}
          <FaChevronDown />
        </SelectGroupBox>

        <SelectGroupDropdown
          isFooter={isFooter}
          topDropdown={topDropdown}
          active={open}
          disabled={disabled}
        >
          <ul>
            {options.map((option, index) => (
              <li
                className={
                  selectedOption && selectedOption.value === option.value
                    ? 'active'
                    : ''
                }
                key={`select-${index}`}
              >
                <button
                  type="button"
                  title="Selecionar opção"
                  aria-label="Selecionar opção"
                  onClick={() => {
                    handleChange({
                      value: option.value,
                      label: option.label,
                    });
                  }}
                >
                  {option.label}
                </button>
              </li>
            ))}
          </ul>
        </SelectGroupDropdown>
      </SelectGroup>
    </Container>
  );
};

SelectTable.propTypes = {
  options: PropTypes.array,
  selected: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
    PropTypes.string,
    PropTypes.number,
  ]),
  isFooter: PropTypes.bool,
  topDropdown: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  numTrunc: PropTypes.number,
  noBorder: PropTypes.bool,
};

SelectTable.defaultProps = {
  options: [],
  selected: '',
  isFooter: false,
  topDropdown: false,
  onChange: () => {},
  disabled: false,
  placeholder: 'Selecione...',
  numTrunc: 70,
  noBorder: false,
};

export default SelectTable;
