import InputText from 'components/form/Text/TextInput/InputText';
import BackgroundImageContainer from 'components/structure/BackgroundImageContainer/BackgroundImageContainer';
import Button from 'components/structure/Button/Button';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Body, ButtonContent, Container } from '../login/Login.styles';
import { Description, Title } from './ForgotPassword.styles';
import { forgotPassword } from 'store/auth/auth-slice';

const ForgotPassword = () => {
  const { handleSubmit } = useForm();
  const intl = useIntl();
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.loading);
  const { status } = useSelector((state) => state.auth);

  const [email, setEmail] = useState();
  const [sent, setSent] = useState(false);

  const handleSubmitForm = () => {
    const formData = new FormData();

    formData.append('email', email);
    if (process.env.REACT_APP_ENV === 'development') {
      formData.append(
        'baseUrl',
        'https://new-admin.dev.suvinil.com.br/reset-password',
      );
    } else {
      formData.append('baseUrl', 'https://samp.suvinil.com.br/reset-password');
    }

    dispatch(forgotPassword(formData));
    setSent(true);
  };

  return (
    <BackgroundImageContainer loading={loading}>
      <Container>
        <Body>
          {!sent ? (
            <form onSubmit={handleSubmit(handleSubmitForm)}>
              <Title>Redefinição de senha</Title>
              <Description>
                Para redefinir sua senha, digite o e-mail que foi utilizado no
                cadastro.
              </Description>
              <InputText
                type="email"
                name="email"
                placeholder="Example@basf.com.br"
                value={email}
                handleChange={setEmail}
              >
                {intl.messages['labelsInputs.typeYourEmail']}
              </InputText>
              <ButtonContent>
                <Button
                  type="submit"
                  btnType="primary"
                  width="181px"
                  height="48px"
                  uppercase
                  fontWeight="600"
                >
                  {intl.messages['buttons.continue']}
                </Button>
              </ButtonContent>
            </form>
          ) : (
            <>
              <Title>E-mail enviado</Title>
              <Description>
                Verifique a caixa de entrada de <b>{email}</b> para ver as
                instruções para redefinir a senha.
              </Description>
            </>
          )}
        </Body>
      </Container>
    </BackgroundImageContainer>
  );
};

export default ForgotPassword;
