import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import SkuImage from './SkuImage';

import * as S from './SkuImageList.styles';

const SkuImageList = ({ items, setItems, setImagesToDeleteFromBucket }) => {
  const moveItem = useCallback((dragIndex, hoverIndex) => {
    setItems((prevItems) =>
      update(prevItems, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevItems[dragIndex]],
        ],
      }),
    );
  }, []);

  const removeItem = useCallback((index) => {
    setItems((prevItems) => {
      if (typeof prevItems[index] === 'string')
        setImagesToDeleteFromBucket((prev) => [...prev, prevItems[index]]);

      return update(prevItems, {
        $splice: [[index, 1]],
      });
    });
  }, []);

  const renderItem = useCallback((item, index) => {
    return (
      <SkuImage
        key={item.id}
        index={index}
        id={item.id}
        image={item.image}
        moveItem={moveItem}
        removeSkuImageSelf={() => removeItem(index)}
      />
    );
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      <S.List>{items.map((item, index) => renderItem(item, index))}</S.List>
    </DndProvider>
  );
};

SkuImageList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  setItems: PropTypes.func.isRequired,
  setImagesToDeleteFromBucket: PropTypes.func.isRequired,
};

export default SkuImageList;
