/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { CATEGORIES } from 'constants/endpoints';
import api from 'services/api';
import multiForm from 'services/multiForm';
import { startLoading, stopLoading } from 'store/loading/loading-slice';
import { errorSessionToast, errorToast, successToast } from 'utils/toasts';

const INITIAL_STATE = {
  categories: {
    totalPage: 1,
    items: [],
  },
  category: {
    id: 0,
    name: '',
    category: null,
    description: '',
    siteTitle: '',
    siteDescription: '',
    platforms: [],
    type: null,
    images: [],
  },
  items: [],
  openStatusModal: false,
  status: '',
  msg: {
    title: '',
    text: '',
  },
  selectId: 0,
  openDeleteModal: false,
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: INITIAL_STATE,
  reducers: {
    getCategoriesSuccess: (state, { payload }) => {
      state.categories = payload;
    },
    getMotherDepartmentsSuccess: (state, { payload }) => {
      state.items = payload;
    },
    statusCategoriesModal: (state, { payload }) => {
      state.openStatusModal = payload;
    },
    setCategoriesStatus: (state, { payload }) => {
      state.status = payload;
    },
    setCategoriesMsg: (state, { payload }) => {
      state.msg = payload;
    },
    getCategorySuccess: (state, { payload }) => {
      state.category = payload;
    },
    setCategoriesId: (state, { payload }) => {
      state.selectId = payload;
    },
    showModalDelete: (state) => {
      state.openDeleteModal = true;
    },
    dismissModalDelete: (state) => {
      state.openDeleteModal = false;
    },
    getDeletedCategoriesSuccess: (state, { payload }) => {
      state.categories = payload;
    },
  },
});

export const {
  dismissModalDelete,
  getCategoriesSuccess,
  getCategorySuccess,
  getDeletedCategoriesSuccess,
  getMotherDepartmentsSuccess,
  setCategoriesId,
  setCategoriesMsg,
  setCategoriesStatus,
  showModalDelete,
  statusCategoriesModal,
} = categoriesSlice.actions;

export const categories = categoriesSlice.reducer;

export const getCategoriesList = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { currentPage, searchTerm, perPage, ordenation } = payload;
  try {
    let url = '';
    if (searchTerm === '') {
      url = `${CATEGORIES}?perPage=${
        perPage || '10'
      }&page=${currentPage}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}&type=1`;
    } else {
      url = `${CATEGORIES}?perPage=${
        perPage || '10'
      }&page=${currentPage}&name=${searchTerm}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}&type=1`;
    }
    const response = await api.get(url);
    dispatch(getCategoriesSuccess(response.data));
    dispatch(stopLoading());
  } catch (error) {
    errorToast(error);
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getDeletedCategoriesList = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { page, searchTerm, perPage, ordenation } = payload;
  try {
    let url = '';
    if (searchTerm === '') {
      url = `${CATEGORIES}?perPage=${perPage || '10'}&page=${page}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}&type=1&trash=true`;
    } else {
      url = `${CATEGORIES}?perPage=${
        perPage || '10'
      }&page=${page}&name=${searchTerm}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}&type=1&trash=true`;
    }
    const response = await api.get(url);
    dispatch(getDeletedCategoriesSuccess(response.data));
    dispatch(stopLoading());
  } catch (error) {
    errorToast(error);
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getDepartmentOptions = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await api.get(
      `${CATEGORIES}?type=1&sort.field=name&sort.order=ASC&perPage=100&page=1`,
    );
    const optionsData = response.data.items.map((option) => ({
      value: option?.id,
      label: option?.name,
      items: option?.childrens,
    }));
    dispatch(getMotherDepartmentsSuccess(optionsData));
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const addNewCategory = (payload) => async (dispatch) => {
  dispatch(startLoading());
  try {
    await multiForm.post(CATEGORIES, payload);

    dispatch(setCategoriesStatus('success'));
    dispatch(stopLoading());
    successToast('Categoria criada com sucesso');
  } catch (error) {
    let msg = { text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Ops!',
            text: 'Não foi possível criar sua categoria, verifique as informações.',
          };
          dispatch(setCategoriesMsg(msg));
          dispatch(setCategoriesStatus('error'));
          dispatch(statusCategoriesModal(true));
          break;
        case 409:
          msg = {
            title: 'Categoria já existente',
          };
          dispatch(setCategoriesMsg(msg));
          dispatch(setCategoriesStatus('error'));
          dispatch(statusCategoriesModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const getCategoryDetail = (payload) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await api.get(`${CATEGORIES}/${payload}`);

    dispatch(getCategorySuccess(response.data));

    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getDeletedCategoryDetail = (payload) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await api.get(`${CATEGORIES}/${payload}?trash=true`);

    dispatch(getCategorySuccess(response.data));

    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const editCategory = (payload) => async (dispatch) => {
  const { id, info } = payload;
  dispatch(startLoading());
  try {
    await api.patch(`${CATEGORIES}/${id}`, info);
    dispatch(stopLoading());
    successToast('Categoria editada com sucesso');
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Ops!',
            text: 'Não foi possível alterar sua categoria, verifique as informações.',
          };
          dispatch(setCategoriesMsg(msg));
          dispatch(setCategoriesStatus('error'));
          dispatch(statusCategoriesModal(true));
          break;
        case 409:
          msg = {
            title: 'Categoria já existente',
          };
          dispatch(setCategoriesMsg(msg));
          dispatch(setCategoriesStatus('error'));
          dispatch(statusCategoriesModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const removeCategory = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { id, page } = payload;
  try {
    await api.delete(`${CATEGORIES}/${id}`);
    const msg = { title: 'Categoria deletada com sucesso!' };
    dispatch(setCategoriesMsg(msg));

    dispatch(dismissModalDelete());
    dispatch(stopLoading());
    dispatch(setCategoriesStatus('success'));
    dispatch(statusCategoriesModal(true));
    dispatch(getCategoriesList(page));
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    dispatch(dismissModalDelete());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Ops!',
            text: 'Não foi possível deletar sua categoria, verifique as informações.',
          };
          dispatch(setCategoriesMsg(msg));
          dispatch(statusCategoriesModal(true));
          dispatch(setCategoriesStatus('error'));
          dispatch(statusCategoriesModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};
