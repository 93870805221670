/**
 * Valida um arquivo de imagem com base em seu tamanho e extensão.
 * @param {File} file - O arquivo de imagem a ser validado.
 * @returns {(string|boolean)} - Retorna uma mensagem de erro se o arquivo não for válido, caso contrário, retorna true.
 */
export const validateImageFile = (file) => {
  const maxSizeInBytes = 1.5 * 1024 * 1024; // 1.5 MB

  if (file.size > maxSizeInBytes) {
    return 'A imagem deve ter no máximo 1.5 MB.';
  }

  const allowedExtensions = /\.(jpg|gif|bmp|webp|png|svg)$/i;
  if (!allowedExtensions.test(file.name)) {
    return 'A imagem precisa estar em um dos seguintes formatos: .png, .jpg, .gif, .bmp, .webp, .svg.';
  }

  return true;
};
