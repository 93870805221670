/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { SIZES } from 'constants/endpoints';

import api from 'services/api';
import { startLoading, stopLoading } from 'store/loading/loading-slice';

import { errorSessionToast, errorToast, successToast } from 'utils/toasts';

const INITIAL_STATE = {
  sizes: {
    totalPage: 1,
    totalItems: 0,
    items: [],
  },
  size: {
    id: 0,
    name: '',
    unit: '',
    active: false,
  },
  selectedSize: 0,
  openStatusModal: false,
  status: '',
  msg: {
    title: '',
    text: '',
  },
  openModalCreate: false,
  openModalEdit: false,
  openModalDelete: false,
};

const sizesSlice = createSlice({
  name: 'sizes',
  initialState: INITIAL_STATE,
  reducers: {
    getSizesSuccess: (state, { payload }) => {
      state.sizes = payload;
    },
    getSizeSuccess: (state, { payload }) => {
      state.size = payload;
    },
    statusSizesModal: (state, { payload }) => {
      state.openStatusModal = payload;
    },
    setSizesStatus: (state, { payload }) => {
      state.status = payload;
    },
    setMsg: (state, { payload }) => {
      state.msg = payload;
    },
    setSelectSize: (state, { payload }) => {
      state.selectedSize = payload;
    },
    openSizeModalCreate: (state, { payload }) => {
      state.openModalCreate = payload;
    },
    openSizeModalEdit: (state, { payload }) => {
      state.openModalEdit = payload;
    },
    openSizeModalDelete: (state, { payload }) => {
      state.openModalDelete = payload;
    },
    getDeletedSizesSuccess: (state, { payload }) => {
      state.sizes = payload;
    },
  },
});

export const sizes = sizesSlice.reducer;
export const {
  getDeletedSizesSuccess,
  getSizeSuccess,
  getSizesSuccess,
  openSizeModalCreate,
  openSizeModalDelete,
  openSizeModalEdit,
  setMsg,
  setSelectSize,
  setSizesStatus,
  statusSizesModal,
} = sizesSlice.actions;

export const getSizesList = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { currentPage, searchTerm, perPage, ordenation } = payload;
  try {
    let url = '';
    if (searchTerm === '') {
      url = `${SIZES}?perPage=${
        perPage || '10'
      }&page=${currentPage}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}`;
    } else {
      url = `${SIZES}?perPage=${
        perPage || '10'
      }&page=${currentPage}&name=${searchTerm}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}`;
    }

    const response = await api.get(url);

    dispatch(getSizesSuccess(response.data));
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getDeletedSizesList = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { page, searchTerm, perPage, ordenation } = payload;
  try {
    let url = '';
    if (searchTerm === '') {
      url = `${SIZES}?perPage=${perPage || '10'}&page=${page}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}&trash=true`;
    } else {
      url = `${SIZES}?perPage=${
        perPage || '10'
      }&page=${page}&name=${searchTerm}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}&trash=true`;
    }

    const response = await api.get(url);

    dispatch(getDeletedSizesSuccess(response.data));
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getSizeDetail = (payload) => async (dispatch) => {
  const { id, deleteSearch } = payload;
  dispatch(startLoading());
  try {
    const response = await api.get(`${SIZES}/${id}`);

    dispatch(getSizeSuccess(response.data));

    dispatch(stopLoading());
    if (deleteSearch === 'delete') {
      dispatch(openSizeModalDelete(true));
    } else if (deleteSearch === 'edit') {
      dispatch(openSizeModalEdit(true));
    }
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getDeletedSizeDetail = (payload) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await api.get(`${SIZES}/${payload}?trash=true`);
    dispatch(getSizeSuccess(response.data));

    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const saveSize = (payload) => async (dispatch) => {
  const { sizeData, currentPage, searchTerm, perPage, ordenation } = payload;
  dispatch(startLoading());
  try {
    await api.post(SIZES, sizeData);
    const msg = {
      title: 'Tamanho salvo com sucesso!',
      text: 'Tamanho salvo com sucesso!',
    };

    dispatch(openSizeModalCreate(false));
    dispatch(setMsg(msg));
    dispatch(setSizesStatus('success'));
    dispatch(stopLoading());

    dispatch(getSizesList({ currentPage, searchTerm, perPage, ordenation }));
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Erros de Validação',
            text: 'Verifique os campos e tente novamente!',
          };
          dispatch(setMsg(msg));

          dispatch(setSizesStatus('error'));

          dispatch(statusSizesModal(true));
          break;
        case 409:
          msg = {
            title: 'Tamanho já existente',
            text: 'Tamanho já existente',
          };
          dispatch(setMsg(msg));
          dispatch(setSizesStatus('error'));
          dispatch(statusSizesModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const editSize = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { sizeData, sizeId, currentPage, searchTerm, perPage, ordenation } =
    payload;
  try {
    await api.patch(`${SIZES}/${sizeId}`, sizeData);

    successToast('Tamanho alterado com sucesso!');
    dispatch(stopLoading());

    dispatch(getSizesList({ currentPage, searchTerm, perPage, ordenation }));
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Falha no Processamento!',
            text: 'Não foi possível alterar o tamanho, tente novamente!',
          };
          dispatch(setMsg(msg));
          dispatch(setSizesStatus('error'));
          dispatch(statusSizesModal(true));
          break;
        case 409:
          msg = {
            title: 'Tamanho já existente',
            text: 'Tamanho já existente',
          };
          dispatch(setMsg(msg));
          dispatch(setSizesStatus('error'));
          dispatch(statusSizesModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const removeSize = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { id, currentPage, searchTerm, perPage, ordenation } = payload;
  try {
    await api.delete(`${SIZES}/${id}`);
    const msg = {
      title: 'Tamanho removido com sucesso!',
      text: 'Tamanho removido com sucesso!',
    };
    dispatch(setMsg(msg));

    dispatch(openSizeModalDelete(false));
    dispatch(stopLoading());
    dispatch(setSizesStatus('success'));
    dispatch(statusSizesModal(true));
    dispatch(getSizesList({ currentPage, searchTerm, perPage, ordenation }));
  } catch (error) {
    dispatch(stopLoading());

    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          dispatch(setSizesStatus('error'));
          dispatch(statusSizesModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};
