import React, { useMemo, useState } from 'react';
import * as S from './styles';
import truncateString from '../../../utils/truncateString';

import { useDispatch, useSelector } from 'react-redux';
import externalIcon from 'assets/icons/open-in-new.svg';
import downloadIcon from 'assets/icons/download-dark.svg';
import EditIcon from 'assets/icons/edit.svg';
import api from 'services/api';
import ImageModal from 'components/contexts/modal/ImageModal/ImageModel';
import { successToast } from 'utils/toasts';
import Proptypes from 'prop-types';

import { useFileList } from 'providers/UploadFileList';
import { setModalFileInput } from 'store/modals/modals-slice';

export const FileList = ({ disabled }) => {
  const [valueForInvalidateCache, setValueForInvalidateCache] = useState(0);
  const [showcaseFileCache, setShowcaseFileCache] = useState(null);
  const [showcaseFileNameCache, setShowcaseFileNameCache] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const { selectId } = useSelector((state) => state.products);
  const { setFileList, fileList, weightId } = useFileList();

  const dispatch = useDispatch();

  const getURLWithInvalidateCacheDateAproach = (url) => {
    setValueForInvalidateCache(Date.now());
    return `${url}?${valueForInvalidateCache}`;
  };

  const urlFile = (item) =>
    item.name.includes('http') ? item.name : URL.createObjectURL(item);

  const updateDisplayImage = (file) => {
    const index = fileList.findIndex((item) => item.name === file.name);
    const formData = new FormData();
    formData.append('files', file);
    formData.append('sizeId', weightId);
    formData.append('position', index + 1);
    formData.append('context', 'display');

    api.post(`/products/${selectId}/image`, formData);
  };

  const getURLWithInvalidateCacheAproach = useMemo(
    (name) => getURLWithInvalidateCacheDateAproach(name),
    [],
  );

  const handleChangeFile = (file) => {
    setShowConfirmationModal(true);
    setShowcaseFileCache(file);
    if (typeof window !== 'undefined') {
      setShowcaseFileNameCache(urlFile(file));
    }
  };

  const updateFileList = (file) => {
    return setFileList((prev) => {
      return prev.map((item) => {
        if (item.name === showcaseFileNameCache) {
          setShowcaseFileNameCache(urlFile(file));
          setShowcaseFileCache(file);
          return file;
        }
        return item;
      });
    });
  };

  return (
    <S.TableContainer>
      <ImageModal
        imgUrl={showcaseFileNameCache}
        isOpen={showConfirmationModal}
        setIsOpen={setShowConfirmationModal}
        actions={{
          changeFile: (e) => updateFileList(e.target.files[0]),
          saveFile: () => {
            setShowConfirmationModal(false);
            updateDisplayImage(showcaseFileCache);
            successToast(`Imagem salva com sucesso!`);
          },
        }}
        isPreview
      />
      <thead>
        <tr>
          <th>Posicao</th>
          <th>Nome do Arquivo</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        {fileList.map((item, index) => {
          const showExternalImage = (e, imgUrl) => {
            e.preventDefault();
            dispatch(setModalFileInput({ status: true, imgUrl }));
          };

          return (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>{truncateString(item.name, 50)}</td>
              <td>
                <S.LinkItem
                  href={getURLWithInvalidateCacheAproach}
                  target="_blank"
                  rel="noreferrer"
                  onClick={(e) => {
                    showExternalImage(e, urlFile(item));
                  }}
                >
                  <img alt="" src={externalIcon} title="Visualizar imagem" />
                </S.LinkItem>
                {!disabled && (
                  <S.LinkItem
                    onClick={() => {
                      handleChangeFile(item);
                    }}
                  >
                    <img alt="" src={EditIcon} title="Editar imagem" />
                  </S.LinkItem>
                )}
                <S.LinkItem href={urlFile(item)} target="_blank" download>
                  <img alt="" src={downloadIcon} title="Baixar imagem" />
                </S.LinkItem>
              </td>
            </tr>
          );
        })}
      </tbody>
    </S.TableContainer>
  );
};

FileList.propTypes = {
  disabled: Proptypes.bool,
};

FileList.defaultProps = {
  disabled: false,
};
