/* eslint-disable prefer-const */
import Grid from '@material-ui/core/Grid';
import ModalMultiConfirmation from 'components/contexts/modal/ModalMultiConfirmation/ModalMultiConfirmation';
import ActionButtons from 'components/form/ActionButtons';
import FileInput from 'components/form/FileInput/FileInput';
import SelectSearch from 'components/form/Select/ReactSelect';
import InputText from 'components/form/Text/TextInput/InputText';
import TextAreaDescription from 'components/form/TextArea/TextAreaDescription/TextAreaDescription';
import FormContainer from 'components/structure/FormContainer/FormContainer';
import urls from 'constants/urls';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { createSlug } from 'utils/createSlug';
import { getPermission } from 'utils/permissions';
import { errorToast } from 'utils/toasts';
import { platforms, typesForm } from './dataOptionsCategories';
import {
  addNewCategory,
  statusCategoriesModal,
} from 'store/categories/categories-slice';
import { getDepartmentsOptions } from 'store/products/products-slice';

const CategoriesCreate = () => {
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [motherDepartment, setMotherDepartment] = useState('');
  const [motherCategory, setMotherCategory] = useState('');
  const [platform, setPlatform] = useState([]);
  const [description, setDescription] = useState('');
  const [siteTitle, setSiteTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [fileName, setFileName] = useState('');
  const [altImage, setAltImage] = useState('');
  const [imageError, setImageError] = useState(null);
  const [dataImage, setDataImage] = useState([{}]);
  const [showModalConfirmationCancel, setShowModalConfirmationCancel] =
    useState(false);
  const [showModalConfirmationSave, setShowModalConfirmationSave] =
    useState(false);
  const [categoryItems, setCategoryItems] = useState([]);

  const [statusForm, setStatus] = useState({
    type: '',
  });

  const { permissions } = useSelector((state) => state.auth);
  const departmentItems = useSelector((state) => state.categories.items);
  const { openStatusModal, status, msg } = useSelector(
    (state) => state.categories,
  );

  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    if (getPermission('categories', permissions, 'create') === false) {
      history.push('/');
    }
  }, [history, permissions]);

  const cancelCategorie = () => {
    history.push(urls.ROUTES.CATALOGUE.items.categories.path);
  };

  function validate() {
    if (!name)
      return setStatus({
        type: 'error',
      });
    if (!motherDepartment && !(type === 1 || type === ''))
      return setStatus({
        type: 'error',
      });
    if (
      !motherCategory &&
      !(type === 1 || type === 2 || motherDepartment === '')
    )
      return setStatus({
        type: 'error',
      });
    if (!platform)
      return setStatus({
        type: 'error',
      });
    if (!description)
      return setStatus({
        type: 'error',
      });
    if (!siteTitle)
      return setStatus({
        type: 'error',
      });
    if (!metaDescription)
      return setStatus({
        type: 'error',
      });
    if (!fileName)
      return setStatus({
        type: 'error',
      });
    if (!altImage)
      return setStatus({
        type: 'error',
      });
    if (!type)
      return setStatus({
        type: 'error',
      });
    return true;
  }

  useEffect(() => {
    dispatch(getDepartmentsOptions());
  }, [dispatch]);

  useEffect(() => {
    if (motherDepartment !== '') {
      let data = departmentItems.find(
        (department) => department.value === motherDepartment,
      );
      let aux = data.items?.map((option) => ({
        value: option?.id,
        label: option?.name,
      }));

      if (aux) {
        setCategoryItems(aux);
      }
    }
  }, [departmentItems, motherDepartment]);

  const isImage = (extension) => {
    switch (extension.toLowerCase()) {
      case 'jpg':
      case 'gif':
      case 'bmp':
      case 'jpeg':
      case 'webp':
      case 'png':
      case 'svg':
        return true;
      default:
        return false;
    }
  };

  const getImageFile = (event) => {
    if (event.target.files.length !== 0) {
      const file = event.target.files[0];
      const filenames = file.name.split('.');
      const fileType = filenames[filenames.length - 1];
      if (file.size < 1.5 * 1024 * 1024) {
        if (isImage(fileType)) {
          setFileName(file.name);
          setDataImage({ ...dataImage, image: file });
          setImageError(null);
        } else {
          setImageError(
            errorToast(
              'A imagem precisa estar em um desses formatos: .png, .jpg, .gif, etc...',
            ),
          );
        }
      } else {
        setImageError(errorToast('A imagem deve ter no máximo 1.5 MB'));
      }
    }
  };

  const saveCategory = () => {
    const arrayAltImage = [];
    arrayAltImage.push(altImage);

    const formData = new FormData();

    formData.append('name', name);
    formData.append('type', type);

    if (type === 2) {
      formData.append('category', motherDepartment);
    }
    if (type === 3) {
      formData.append('category', motherCategory);
    }

    formData.append('platforms', JSON.stringify(platform));
    formData.append('description', description);
    formData.append('siteTitle', siteTitle);
    formData.append('siteDescription', metaDescription);
    formData.append('alternativeText', JSON.stringify(arrayAltImage));
    formData.append('image', dataImage.image);

    if (type !== '' && altImage !== '' && fileName !== '') {
      dispatch(addNewCategory(formData));
    }

    history.push(urls.ROUTES.CATALOGUE.items.categories.path);
  };

  return (
    <>
      <ModalMultiConfirmation
        title={intl.messages['textModal.createCategory']}
        isOpen={showModalConfirmationSave}
        setIsOpen={setShowModalConfirmationSave}
        buttonNameBack={intl.messages['buttons.toBack']}
        buttonName={intl.messages['buttons.yes']}
        confirmOnClick={saveCategory}
        status="confirm"
      />
      <ModalMultiConfirmation
        title={intl.messages['textModal.cancelCreateCategory']}
        text={intl.messages['textModal.createCategoryWillBeLost']}
        isOpen={showModalConfirmationCancel}
        setIsOpen={setShowModalConfirmationCancel}
        buttonNameBack={intl.messages['buttons.not']}
        buttonName={intl.messages['buttons.yes']}
        confirmOnClick={cancelCategorie}
        status="info"
      />

      <ModalMultiConfirmation
        title={msg.title}
        text={msg.text}
        setIsOpen={(payload) => {
          dispatch(statusCategoriesModal(payload));
          if (status !== 'error') {
            history.push(urls.ROUTES.CATALOGUE.items.categories.path);
          }
        }}
        isOpen={openStatusModal}
        status={status}
      />
      <FormContainer title={intl.messages['textHeader.createCategory']}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item md={12}>
                <InputText
                  placeholder={intl.messages['labelsInputs.typeYourName']}
                  label={intl.messages['labelsInputs.typeYourName']}
                  value={name}
                  handleChange={setName}
                  error={
                    statusForm.type === 'error'
                      ? 'Campo Nome deve ser preenchido'
                      : ''
                  }
                  isError={name === '' && statusForm.type}
                />
              </Grid>
              <Grid item md={12}>
                <InputText
                  placeholder={intl.messages['labelsInputs.slug']}
                  label={intl.messages['labelsInputs.slug']}
                  disabled
                  value={createSlug(name)}
                />
              </Grid>

              <Grid item md={12}>
                <SelectSearch
                  label={intl.messages['labelsInputs.type']}
                  placeholder={intl.messages['labelsInputs.select']}
                  options={typesForm}
                  defaultOption={type}
                  handleChange={setType}
                  tooltip
                  title={intl.messages['labelsInputs.tooltipTypeCategory']}
                  error={
                    statusForm.type === 'error'
                      ? 'Campo Tipo deve ser preenchido'
                      : ''
                  }
                  isError={type === '' && statusForm.type}
                />
              </Grid>
              <Grid item md={12}>
                <SelectSearch
                  label={intl.messages['labelsInputs.motherDepartment']}
                  placeholder={intl.messages['labelsInputs.select']}
                  options={departmentItems}
                  defaultOption={motherDepartment}
                  handleChange={setMotherDepartment}
                  disabled={type === 1 || type === ''}
                  tooltip
                  title={intl.messages['labelsInputs.tooltipMotherDepartment']}
                  error={
                    statusForm.type === 'error'
                      ? 'Campo Departamento Mãe deve ser preenchido'
                      : ''
                  }
                  isError={
                    motherDepartment === '' &&
                    statusForm.type &&
                    type !== 1 &&
                    type !== ''
                  }
                />
              </Grid>
              <Grid item md={12}>
                <SelectSearch
                  label={intl.messages['labelsInputs.motherCategory']}
                  placeholder={intl.messages['labelsInputs.select']}
                  options={categoryItems}
                  defaultOption={motherCategory}
                  handleChange={setMotherCategory}
                  disabled={type === 1 || type === 2 || motherDepartment === ''}
                  tooltip
                  title={intl.messages['labelsInputs.tooltipMotherCategory']}
                  error={
                    statusForm.type === 'error'
                      ? 'Campo Categoria Mãe deve ser preenchido'
                      : ''
                  }
                  isError={
                    motherCategory === '' &&
                    statusForm.type &&
                    type !== 1 &&
                    type !== 2 &&
                    motherDepartment !== ''
                  }
                />
              </Grid>
              <Grid item md={12}>
                <SelectSearch
                  label={intl.messages['labelsInputs.platforms']}
                  placeholder={intl.messages['labelsInputs.select']}
                  options={platforms}
                  defaultMultiOptions={platform}
                  handleChange={setPlatform}
                  multi
                  error={
                    statusForm.type === 'error'
                      ? 'Campo Plataforma deve ser preenchido'
                      : ''
                  }
                  isError={platform.length === 0 && statusForm.type}
                />
              </Grid>
              <Grid item md={12}>
                <TextAreaDescription
                  label={intl.messages['labelsInputs.description']}
                  placeholder={intl.messages['labelsInputs.description']}
                  value={description}
                  handleChange={setDescription}
                  heigth="110px"
                  tooltip={false}
                  isCount={false}
                  error={
                    statusForm.type === 'error'
                      ? 'Campo Descrição deve ser preenchido'
                      : ''
                  }
                  isError={description === '' && statusForm.type}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <TextAreaDescription
                  label={intl.messages['labelsInputs.metaDescription']}
                  placeholder={intl.messages['labelsInputs.metaDescription']}
                  value={metaDescription}
                  handleChange={setMetaDescription}
                  heigth="110px"
                  tooltip={false}
                  isCount={false}
                  error={
                    statusForm.type === 'error'
                      ? 'Campo Meta Descrição deve ser preenchido'
                      : ''
                  }
                  isError={metaDescription === '' && statusForm.type}
                />
              </Grid>
              <Grid item md={12}>
                <TextAreaDescription
                  label={intl.messages['labelsInputs.siteTitle']}
                  placeholder={intl.messages['labelsInputs.siteTitle']}
                  value={siteTitle}
                  handleChange={setSiteTitle}
                  heigth="110px"
                  isCount={false}
                  tooltip={false}
                  error={
                    statusForm.type === 'error'
                      ? 'Campo Site Title deve ser preenchido'
                      : ''
                  }
                  isError={siteTitle === '' && statusForm.type}
                />
              </Grid>

              <Grid item md={12}>
                <FileInput
                  label={intl.messages['labelsInputs.uplodadImageLabel']}
                  text={intl.messages['labelsInputs.toDoUploadImage']}
                  title={intl.messages['labelsInputs.tooltipUploadImage']}
                  accept="image/*"
                  fileName={fileName}
                  getFile={getImageFile}
                  error={
                    statusForm.type === 'error'
                      ? 'Campo Imagem deve ser preenchido'
                      : ''
                  }
                  isError={(fileName === '' && statusForm.type) || imageError}
                />
              </Grid>

              <Grid item md={12}>
                <TextAreaDescription
                  label={intl.messages['labelsInputs.altTextImage']}
                  placeholder={intl.messages['labelsInputs.imageDescription']}
                  value={altImage}
                  handleChange={setAltImage}
                  heigth="134px"
                  isCount={false}
                  tooltip={false}
                  error={
                    statusForm.type === 'error'
                      ? 'Campo Alt text deve ser preenchido'
                      : ''
                  }
                  isError={altImage === '' && statusForm.type}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ActionButtons
          type="create"
          cancelPath={() => setShowModalConfirmationCancel(true)}
          submitFunc={() => {
            if (!validate()) {
              setShowModalConfirmationSave(false);
            } else {
              saveCategory();
            }
          }}
        />
      </FormContainer>
    </>
  );
};

export default CategoriesCreate;
