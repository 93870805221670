import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputText from 'components/form/Text/TextInput/InputText';
import Toggle from 'components/form/Toggle/Toggle';
import Grid from '@material-ui/core/Grid';
import Button from 'components/structure/Button/Button';

import { defaultValues, schema } from './AddBaseModalForm.validations';
import { Container } from './AddBaseModalForm.styles';

const AddBaseModalForm = ({
  setName,
  isActive,
  setIsActive,
  cancelActionModal,
  confirmOnClick,
}) => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const intl = useIntl();

  const handleSubmitModalForm = (data) => {
    setName(data.name);
    confirmOnClick();
  };

  function validate(obj) {
    if (obj === null || obj === undefined) {
      return false;
    }
    return true;
  }

  return (
    <Container>
      <form onSubmit={handleSubmit(handleSubmitModalForm)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={12}>
                <InputText
                  type="text"
                  label={intl.messages['labelsInputs.typeYourName']}
                  placeholder={intl.messages['labelsInputs.typeYourName']}
                  id="name"
                  name="name"
                  error={errors.name}
                  isError={validate(errors.name)}
                  register={register}
                  hookForm
                />
              </Grid>
              <Grid item xs={12}>
                <Toggle
                  label={intl.messages['labelsInputs.activation']}
                  paddingTop={validate(errors.name) ? '34px' : '56px'}
                  paddingBottom="56px"
                  checked={isActive}
                  onChange={(e) => setIsActive(e.target.checked)}
                  large
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Button
              type="button"
              btnType="secondary"
              width="100%"
              height="44px"
              borderRadius="8px"
              uppercase
              onClick={cancelActionModal}
              fontWeight="600"
            >
              {intl.messages['buttons.cancel']}
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            <Button
              type="submit"
              btnType="primary"
              width="100%"
              height="44px"
              borderRadius="8px"
              uppercase
              fontWeight="600"
            >
              {intl.messages['buttons.create']}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

AddBaseModalForm.propTypes = {
  setName: PropTypes.func,
  isActive: PropTypes.bool,
  setIsActive: PropTypes.func,
  cancelActionModal: PropTypes.func,
  confirmOnClick: PropTypes.func,
};

AddBaseModalForm.defaultProps = {
  setName: () => {},
  isActive: false,
  setIsActive: () => {},
  cancelActionModal: () => {},
  confirmOnClick: () => {},
};

export default AddBaseModalForm;
