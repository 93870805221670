import { toast } from 'react-toastify';

const config = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const successToast = (content) => {
  toast.success(content, config);
};

const successToastFunc = (content, func) =>
  toast.success(content, { ...config, onClose: func });

const errorToast = (content) => toast.error(content, config);

const errorToastFunc = (content, func) =>
  toast.error(content, { ...config, onClose: func });

const infoToast = (content) => toast.info(content, config);

const warningToast = (content) => toast.warning(content, config);

const defaultToast = (content) => toast(content, config);

const errorSessionToast = () =>
  toast.error('A sessão foi expirada, você será redirecionado para o login', {
    ...config,
    onClose: () => {
      window.history.pushState(null, 'Login Page', '/login');
      window.history.go();
    },
  });

export {
  successToast,
  errorToast,
  infoToast,
  defaultToast,
  successToastFunc,
  errorToastFunc,
  warningToast,
  errorSessionToast,
};
