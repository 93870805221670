import StepProgress from 'components/contexts/StepProgress/StepProgress';
import FormContainerSecondary from 'components/structure/FormContainerSecondary/FormContainerSecondary';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Container, StepContainer } from './SkuCreateMany.styles';
import { SkuProvider } from '../../../providers/SkuProvider';
import SkuFirstStep from './SkuFirstStep';
import SkuSecondStep from './SkuSecondStep';
import SkuThirdStep from './SkuThirdStep';

const SkuCreateMany = () => {
  const [activeStep, setActiveStep] = useState(0);
  const intl = useIntl();
  const steps = ['CRIAR', 'MAIS DETALHES', 'VALIDAR'];

  return (
    <SkuProvider>
      <Container>
        <StepContainer>
          <StepProgress activeStep={activeStep} steps={steps} />
        </StepContainer>
        <FormContainerSecondary title={intl.messages['textHeader.createSkus']}>
          {activeStep === 0 && <SkuFirstStep setActiveStep={setActiveStep} />}
          {activeStep === 1 && <SkuSecondStep setActiveStep={setActiveStep} />}
          {activeStep === 2 && <SkuThirdStep setActiveStep={setActiveStep} />}
        </FormContainerSecondary>
      </Container>
    </SkuProvider>
  );
};

export default SkuCreateMany;
