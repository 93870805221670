/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { PERMISSIONS, RESOURCES, ROLES } from 'constants/endpoints';
import api from 'services/api';
import { startLoading, stopLoading } from 'store/loading/loading-slice';
import { errorSessionToast, errorToast, successToast } from 'utils/toasts';

const INITIAL_STATE = {
  roles: {
    totalPage: 1,
    totalItems: 0,
    items: [],
  },
  role: {
    id: 0,
    name: '',
    translatedName: '',
    slug: '',
    permissions: [],
  },
  options: [],
  selectId: 0,
  openStatusModal: false,
  status: '',
  msg: {
    title: '',
    text: '',
  },
  openModalDelete: false,
  openStatusModalCreate: false,
  resources: [],
};

const rolesSlice = createSlice({
  name: 'roles',
  initialState: INITIAL_STATE,
  reducers: {
    getRolesOptionsSuccess: (state, { payload }) => {
      state.options = payload;
    },
    getRolesSuccess: (state, { payload }) => {
      state.roles = payload;
    },
    getRoleSuccess: (state, { payload }) => {
      state.role = payload;
    },
    setRoleStatus: (state, { payload }) => {
      state.status = payload;
    },
    statusRoleModal: (state, { payload }) => {
      state.openStatusModal = payload;
    },
    setRoleMsg: (state, { payload }) => {
      state.msg = payload;
    },
    setRoleId: (state, { payload }) => {
      state.selectId = payload;
    },
    openRoleModalDelete: (state, { payload }) => {
      state.openModalDelete = payload;
    },
    getRoleEditSuccess: (state, { payload }) => {
      state.role = payload;
    },
    statusRoleModalCreate: (state, { payload }) => {
      state.openStatusModalCreate = payload;
    },
    getResourcesListSuccess: (state, { payload }) => {
      state.resources = payload;
    },
    getDeletedRolesSuccess: (state, { payload }) => {
      state.roles = payload;
    },
    getDeletedRoleSuccess: (state, { payload }) => {
      state.role = payload;
    },
  },
});

export const roles = rolesSlice.reducer;
export const {
  getDeletedRoleSuccess,
  getDeletedRolesSuccess,
  getResourcesListSuccess,
  getRoleEditSuccess,
  getRoleSuccess,
  getRolesOptionsSuccess,
  getRolesSuccess,
  openRoleModalDelete,
  setRoleId,
  setRoleMsg,
  setRoleStatus,
  statusRoleModal,
  statusRoleModalCreate,
} = rolesSlice.actions;

export const getRolesListAll = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await api.get(
      `${ROLES}?perPage=1000&page=1&sort.field=name&sort.order=ASC`,
    );
    const options = response.data.items.map((option) => ({
      value: option.id,
      label: option.name,
      name: option.name,
    }));

    dispatch(getRolesOptionsSuccess(options));
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getListRoles = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { currentPage, searchTerm, perPage, ordenation } = payload;
  try {
    let url = '';
    if (searchTerm === '') {
      url = `${ROLES}?perPage=${
        perPage || '10'
      }&page=${currentPage}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}`;
    } else {
      url = `${ROLES}?perPage=${
        perPage || '10'
      }&page=${currentPage}&name=${searchTerm}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}`;
    }
    const { data } = await api.get(url);

    const rolesData = {
      totalPage: data.totalPage,
      items: data.items,
    };
    dispatch(getRolesSuccess(rolesData));

    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getListDeletedRoles = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { page, searchTerm, perPage, ordenation } = payload;
  try {
    let url = '';
    if (searchTerm === '') {
      url = `${ROLES}?perPage=${perPage || '10'}&page=${page}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}&trash=true`;
    } else {
      url = `${ROLES}?perPage=${
        perPage || '10'
      }&page=${page}&name=${searchTerm}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}&trash=true`;
    }
    const { data } = await api.get(url);

    const rolesData = {
      totalPage: data.totalPage,
      items: data.items,
    };

    dispatch(getDeletedRolesSuccess(rolesData));
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getRoleDetail = (payload) => async (dispatch) => {
  const { id, deleteSearch } = payload;
  dispatch(startLoading());
  try {
    const response = await api.get(`${ROLES}/${id}`);

    dispatch(getRoleSuccess(response.data));

    dispatch(stopLoading());
    if (deleteSearch) {
      dispatch(openRoleModalDelete(true));
    }
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getDeletedRoleDetail = (payload) => async (dispatch) => {
  const { id } = payload;
  dispatch(startLoading());
  try {
    const response = await api.get(`${ROLES}/${id}?trash=true`);

    dispatch(getDeletedRoleSuccess(response.data));

    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getRoleEditDetail = (payload) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await api.get(`${ROLES}/${payload}`);

    const { id, name, translatedName, slug, permissions } = response.data;

    const role = {
      id,
      name,
      translatedName,
      slug,
      permissions,
    };

    dispatch(getRoleEditSuccess(role));
    dispatch(stopLoading());
  } catch (error) {
    errorToast(error);
    dispatch(stopLoading());
  }
};

export const saveRoleName = (payload) => async (dispatch) => {
  const { info } = payload;
  dispatch(startLoading());
  try {
    const response = await api.post(ROLES, info);

    dispatch(setRoleId(response.data.id));
    dispatch(setRoleStatus('success'));
    dispatch(stopLoading());
    successToast('Perfil salvo com sucesso!');

    dispatch(
      getListRoles({
        currentPage: 1,
        searchTerm: '',
        perPage: '10',
        ordenation: '',
      }),
    );
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Ops!',
            text: 'Não foi possível criar seu perfil, verifique as informações.',
          };

          dispatch(setRoleMsg(msg));
          dispatch(setRoleStatus('error'));
          dispatch(statusRoleModal(true));
          break;
        case 409:
          msg = {
            title: 'Perfil já existente',
            text: 'Perfil já existente',
          };
          dispatch(setRoleMsg(msg));
          dispatch(setRoleStatus('error'));
          dispatch(statusRoleModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const removeRole = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { id, page, searchTerm } = payload;
  try {
    await api.delete(`${ROLES}/${id}`);
    const msg = {
      title: 'Perfil removido com sucesso!',
      text: 'Perfil removido com sucesso!',
    };
    dispatch(setRoleMsg(msg));

    dispatch(openRoleModalDelete(false));
    dispatch(stopLoading());
    dispatch(setRoleStatus('success'));
    dispatch(statusRoleModal(true));

    dispatch(getListRoles({ currentPage: page, searchTerm }));
  } catch (error) {
    dispatch(stopLoading());

    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          dispatch(setRoleStatus('error'));
          dispatch(statusRoleModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const roleNameUpdate = (payload) => async (dispatch) => {
  const { id, info } = payload;
  dispatch(startLoading());
  try {
    await api.patch(`${ROLES}/${id}`, info);
    dispatch(stopLoading());

    successToast('Perfil salvo com sucesso!');
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Ops!',
            text: 'Não foi possível alterar seu perfil, verifique as informações.',
          };
          dispatch(setRoleMsg(msg));
          dispatch(setRoleStatus('error'));
          dispatch(statusRoleModal(true));
          break;
        case 409:
          msg = {
            title: 'Perfil já existente',
            text: 'Perfil já existente',
          };
          dispatch(setRoleMsg(msg));
          dispatch(setRoleStatus('error'));
          dispatch(statusRoleModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const roleUpdate = (payload) => async (dispatch) => {
  const { id, info } = payload;
  dispatch(startLoading());
  try {
    await api.patch(`${ROLES}/${id}`, info);
    dispatch(stopLoading());
    successToast('Permissão alterado com sucesso!');
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Ops',
            text: 'Não foi possível alterar as permissões, verifique as informações.',
          };
          dispatch(setRoleMsg(msg));
          dispatch(setRoleStatus('error'));
          dispatch(statusRoleModal(true));
          break;
        case 409:
          msg = {
            title: 'Permissão já existente',
            text: 'Permissão já existente',
          };
          dispatch(setRoleMsg(msg));
          dispatch(setRoleStatus('error'));
          dispatch(statusRoleModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const getRoleToPermissionsDetail = (payload) => async (dispatch) => {
  dispatch(startLoading());

  try {
    const response = await api.get(`${ROLES}/${payload}`);
    const { id } = response.data;
    const role = {
      id,
    };
    // ***Procurar
    // dispatch(getRoleToPermissionsSuccess(role));
  } catch (error) {
    errorToast(error);
    dispatch(stopLoading());
  }
};

export const savePermissions = (payload) => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api.post(PERMISSIONS, payload);
    dispatch(stopLoading());
    successToast('Permissão salva com sucesso!');
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Ops!',
            text: 'Não foi possível criar esta permissão, verifique as informações.',
          };
          dispatch(setRoleMsg(msg));
          dispatch(setRoleStatus('error'));
          dispatch(statusRoleModal(true));
          break;
        case 409:
          msg = {
            title: 'Permissão já existente',
            text: 'Permissão já existente',
          };
          dispatch(setRoleMsg(msg));
          dispatch(setRoleStatus('error'));
          dispatch(statusRoleModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const getResourcesOptions = () => async (dispatch) => {
  try {
    const response = await api.get(`${RESOURCES}?perPage=10000&page=1`);
    const optionsData = response.data.items.map((option) => ({
      resourceId: option.id,
      name: option.name,
      translatedName: option.translatedName,
    }));
    dispatch(getResourcesListSuccess(optionsData));
  } catch (error) {
    /// TODO
    console.log(error);
  }
};
