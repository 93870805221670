import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useOutsideClick } from 'hooks/useOutsideClick';
import closeIcon from 'assets/icons/close.svg';
import Button from 'components/structure/Button/Button';
import Loader from 'components/structure/Loader/Loader';
import { useHistory } from 'react-router-dom';
import urls from 'constants/urls';
import {
  Container,
  ModalHeader,
  ModalContent,
  ModalBody,
  Title,
} from './Modal.styles';

const Modal = ({
  setIsOpen,
  isOpen,
  title,
  content,
  titleRight,
  titleSize,
  loading,
  titleWidth,
  containerWidth,
  containerHeight,
  width,
  height,
  paddingTop,
  paddingRolesModal,
  isNotificationButton,
  zIndex,
  children,
  top,
  left,
}) => {
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => setIsOpen(false));
  const history = useHistory();

  return (
    isOpen && (
      <Container
        containerWidth={containerWidth}
        containerHeight={containerHeight}
        zIndex={zIndex}
      >
        <ModalContent
          ref={wrapperRef}
          width={width}
          height={height}
          paddingRolesModal={paddingRolesModal}
          top={top}
          left={left}
        >
          <Loader loading={loading} />
          <ModalHeader titleRight={titleRight} paddingTop={paddingTop}>
            <Title titleSize={titleSize} titleWidth={titleWidth}>
              {title}
            </Title>
            {!isNotificationButton ? (
              <Button
                onClick={() => setIsOpen(false)}
                icon={closeIcon}
                btnType="light"
                width="40px"
                height="40px"
              />
            ) : (
              <Button
                onClick={() => {
                  setIsOpen(false);
                  history.push(urls.ROUTES.NOTIFICATIONS?.path);
                }}
                btnType="light"
                width="110px"
                height="40px"
              >
                Exibir Todas
              </Button>
            )}
          </ModalHeader>
          <ModalBody>{content ?? children}</ModalBody>
        </ModalContent>
      </Container>
    )
  );
};

Modal.propTypes = {
  title: PropTypes.node,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  setIsOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  titleSize: PropTypes.string,
  titleRight: PropTypes.bool,
  loading: PropTypes.number,
  titleWidth: PropTypes.string,
  containerWidth: PropTypes.string,
  containerHeight: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  paddingTop: PropTypes.string,
  paddingRolesModal: PropTypes.bool,
  zIndex: PropTypes.string,
  children: PropTypes.node,
  top: PropTypes.string,
  left: PropTypes.string,
  isNotificationButton: PropTypes.bool,
};

Modal.defaultProps = {
  title: null,
  setIsOpen: () => {},
  isOpen: false,
  titleSize: '',
  titleRight: false,
  loading: 0,
  titleWidth: '',
  containerWidth: '100vw',
  containerHeight: '100vh',
  width: '',
  height: '',
  paddingTop: '',
  paddingRolesModal: false,
  zIndex: '999',
  children: null,
  top: '',
  left: '',
  isNotificationButton: false,
};

export default Modal;
