import ViewUserModalForm from 'components/contexts/modal/ViewUserModalForm/ViewUserModalForm';
import Button from 'components/structure/Button/Button';
import Modal from 'components/structure/Modal/Modal';
import PageContent from 'components/structure/PageContent/PageContent';
import PageHeader from 'components/structure/PageHeader/PageHeader';
import Popover from 'components/structure/PopOver/PopOver';
import {
  PopItem,
  PopOverContent,
} from 'components/structure/PopOver/PopOver.styles';
import TableDefault from 'components/structure/Table/Table';
import urls from 'constants/urls';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getPermission } from 'utils/permissions';
import EmptySearch from '../../components/structure/EmptySearch/EmptySearch';
import {
  editUser,
  getDeletedUserDetail,
  getDeletedUsersList,
  setSelectUser,
} from 'store/users/users-slice';

const DeletedUsers = () => {
  const { permissions } = useSelector((state) => state.auth);

  const [searchTerm, setSearchTerm] = useState('');
  const [ordenation, setOrdenation] = useState('');
  const [page, setPage] = useState(1);
  const [totalPerPage, setTotalPerPage] = useState({ value: 10 });
  const perPage = totalPerPage.value;

  const [selectedId, setSelectedId] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false);
  const { totalPage, items } = useSelector((state) => state.users.users);

  const optionsOrdenation = [
    { value: 'name', label: 'Nome' },
    { value: 'email', label: 'E-mail' },
    { value: 'role', label: 'Perfil' },
    { value: 'createdAt', label: 'Data e hora' },
  ];

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getDeletedUsersList({ page, searchTerm, perPage, ordenation }));
  }, [dispatch, page, searchTerm, perPage, ordenation]);

  useEffect(() => {
    if (getPermission('articles', permissions, 'delete') === false) {
      history.push('/');
    }
  }, [history, permissions]);

  const viewDeletedUser = useCallback(
    (id) => {
      setSelectedId(id);
      dispatch(getDeletedUserDetail(id));
      dispatch(setSelectUser(id));
      setShowViewModal(true);
    },
    [dispatch],
  );

  const restoreUser = useCallback(
    (id) => {
      const formData = new FormData();
      formData.append('restore', true);
      dispatch(editUser({ userId: id, userData: formData }));
    },
    [dispatch],
  );

  const COLUMNS = useMemo(
    () => [
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'E-mail',
        accessor: 'email',
      },
      {
        Header: 'Perfil',
        accessor: ({ role }) => role.name,
      },
      {
        Header: 'Removido em',
        accessor: ({ deletedAt }) => new Date(deletedAt).toLocaleString(),
      },
      {
        Header: ' ',
        accessor: ({ id }) => (
          <Popover
            content={
              <PopOverContent>
                <PopItem onClick={() => restoreUser(id)}>Restaurar</PopItem>
                <PopItem onClick={() => viewDeletedUser(id)}>
                  Ver detalhes
                </PopItem>
              </PopOverContent>
            }
          />
        ),
        sticky: 'right',
      },
    ],
    [],
  );

  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  const data = useMemo(() => items, [items]);

  const viewUserModalForm = (
    <ViewUserModalForm
      cancelActionModal={() => setShowViewModal(false)}
      editActionModal={() => {
        setShowViewModal(false);
      }}
      deleted
    />
  );

  return (
    <div>
      <Modal
        title="Usuário"
        setIsOpen={setShowViewModal}
        isOpen={showViewModal}
        content={viewUserModalForm}
        width="35%"
      />
      ;
      <PageHeader
        title="Usuários removidos"
        onClickFilter={() => {}}
        search
        searchFunc={(searchData) => setSearchTerm(searchData)}
        placeholder="Busque por ID"
        optionsOrdenation={optionsOrdenation}
        ordenation={ordenation}
        setOrdenation={setOrdenation}
        trash={false}
      />
      <PageContent>
        <EmptySearch items={data} term={searchTerm}>
          <TableDefault
            columns={COLUMNS}
            data={data}
            pageCount={totalPage}
            defaultCurrentPage={page}
            setPageCurrent={setPage}
            totalPerPage={totalPerPage}
            setTotalPerPage={setTotalPerPage}
          />
        </EmptySearch>
        <Button
          className="backbutton"
          btnType="blue"
          width="20%"
          fontWeight="600"
          onClick={() => history.push(urls.ROUTES.ACCESS.items.users.path)}
        >
          VOLTAR
        </Button>
      </PageContent>
    </div>
  );
};
export default DeletedUsers;
