import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;

  h3 {
    margin-top: 20px;
  }
`;

export const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  div {
    justify-content: flex-start;
    gap: 20px;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const FormContainerAParty = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 15px;
`;

export const FormContainerBParty = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 15px;
`;

export const ImageCTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 5px;
  user-select: none;
  p {
    color: #606060;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
`;

export const ImageCTAContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-radius: 2px;
  background-color: #f7f7f7;
  border: 1px dashed #cccccc;

  cursor: pointer;

  div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
    }

    .orange-text {
      color: #f76902;
    }
  }
`;

export const ContainerModal = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    min-width: 500px;

    text-align: center;
    z-index: 10;
    > img {
      width: 20%;
      height: 20%;
      padding-bottom: 24px;
    }

    > h3 {
      font-family: ${theme.typography.family.secondary};
      font-size: 1.5rem;
      line-height: 38px;
      font-weight: 700;
      color: ${theme.colors.tertiary.default};
      padding-bottom: 14px;
    }

    > p {
      padding-bottom: 24px;
      font-family: ${theme.typography.family.primary};
      color: ${theme.colors.tertiary.default};
      font-size: 1rem;
      line-height: 24px;
      font-weight: 500;
    }

    > div {
      width: 100%;
    }

    .btn-save-img-list {
      margin-left: auto;
    }

    .image-list {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;

      > div:first-of-type {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 8px;

        p:first-child {
          width: 130px;
        }

        p:last-child {
          text-align: center;
          width: 96px;
        }
      }

      p {
        font-size: 12px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  `}
`;

export const InputSmallWrapper = styled.div`
  width: 261px;
  flex-shrink: 0;
`;
