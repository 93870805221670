import styled from 'styled-components';

export const ResetPasswordSuccessContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;

  img {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-flow: column;
    align-items: center;
    margin-top: 40px;
  }
`;
