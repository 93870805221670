import styled from 'styled-components';

export const AccordionItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #cccccc;
  width: 100%;
  cursor: pointer;

  button {
    border: none;
    background: transparent;
    padding-right: 14px;
  }
`;

export const StyledText = styled.span`
  font-family: ${({ theme }) => theme.typography.family.primary};
  color: ${({ theme }) => theme.colors.secondary.default};
  font-weight: 400;
  font-size: ${({ theme }) => theme.typography.sizes.sm};
  padding: 14px 0;
  width: 220px;
`;

export const AccordionItemHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const PrimaryAccordionItemHeader = styled.div`
  display: flex;
  width: 300px;
  justify-content: space-around;
  align-items: center;
  margin-left: 50px;
  cursor: pointer;
`;

export const Color = styled.div`
  display: block;
  align-items: center;
  border: 1px solid;
  border-color: ${({ rgbCode }) =>
    rgbCode === '255,255,255' ? '#ccc' : rgbCode};
  background-color: ${({ rgbCode }) => rgbCode};
  width: 185px;
  height: 50px;
  padding: 15px 12px;
  top: 0;
  font-weight: 400;
  font-size: 0.85rem;
  line-height: 16px;
  color: ${({ colorText }) => colorText};
`;

export const InputsWrapper = styled.div`
  padding: 14px;
  border: 1px solid #cccccc;
  width: 100%;
  height: auto;
`;

export const ItemsContainer = styled.div`
  display: flex;
  width: 472px;
  justify-content: space-between;
  margin-left: 50px;
`;

export const ButtonContainer = styled.div`
  border: none;
  display: flex;
  justify-content: flex-end;

  img {
    padding: 0;
  }
`;
