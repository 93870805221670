import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  alith: flex-start;
  gap: 24px;

  margin: 0 10px;
  ${({ alignCenter }) => alignCenter && css`
   margin: 0 auto;
   max-width: 700px;
  `}
  width: 100%;
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;

  .sub-container {
    width: 100%;
  }

  h2 {
    color: #333;
    font-family: 'Suvinil Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
  }

  h3 {
    color: #333;

    font-family: 'Trebuchet MS';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    margin-bottom: 8px;
  }
`;


export const SelectBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`