import Grid from '@material-ui/core/Grid';
import ActionButtons from 'components/form/ActionButtons';
import FileInput from 'components/form/FileInput/FileInput';
import SelectSearch from 'components/form/Select/ReactSelect';
import InputText from 'components/form/Text/TextInput/InputText';
import TextAreaDescription from 'components/form/TextArea/TextAreaDescription/TextAreaDescription';
import FormContainer from 'components/structure/FormContainer/FormContainer';
import urls from 'constants/urls';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { preventRedirectEnabled } from 'store/sidebar/sidebar-slice';

import { createSlug } from 'utils/createSlug';
import { getPermission } from 'utils/permissions';

const EditorialsView = ({ location }) => {
  const { editorial, selectId } = useSelector((state) => state.editorials);

  const { permissions } = useSelector((state) => state.auth);
  const history = useHistory();

  useEffect(() => {
    if (getPermission('editorials', permissions, 'update') === false) {
      history.push('/');
    }
  }, [history, permissions]);

  const [name, setName] = useState(editorial.name);
  const [meta, setMeta] = useState(editorial.meta);
  const [platform, setPlatform] = useState(editorial.platform);
  const [description, setDescription] = useState(editorial.description);
  const [altImage, setAltImage] = useState('');
  const [fileName, setFileName] = useState('');

  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    dispatch(preventRedirectEnabled());
  }, [dispatch]);

  const options = [
    { value: 'institucional', label: 'Suvinil' },
    { value: 'glasu', label: 'Glasu' },
  ];

  useEffect(() => {
    if (selectId === 0) {
      history.push(urls.ROUTES.EDITORIALS.path);
    }
  }, [dispatch, history]);

  useEffect(() => {
    setName(editorial.name);
    setMeta(editorial.meta);
    setPlatform(editorial.platform);
    setDescription(editorial.description);
    setFileName(editorial?.images?.[0]?.fullUrl);
    setAltImage(editorial?.images?.[0]?.alternativeText);
  }, [editorial, dispatch]);

  const cancelEditEditorial = () => {
    history.goBack();
  };

  return (
    <>
      <FormContainer title={`Editoria - ${name}`}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item md={12}>
                <InputText
                  label="Nome"
                  value={name}
                  handleChange={setName}
                  maxLength={60}
                  isCount
                  heigth="7vh"
                  disabled
                  hasTooltip
                  textTooltip={
                    intl.messages['labelsInputs.tooltipEditorialName']
                  }
                />
              </Grid>
              <Grid item md={12}>
                <InputText
                  label="Slug"
                  disabled
                  value={createSlug(name)}
                  hasTooltip
                  textTooltip={
                    intl.messages['labelsInputs.tooltipEditorialSlug']
                  }
                />
              </Grid>
              <Grid item md={12}>
                <TextAreaDescription
                  label="Meta description"
                  maxLength={140}
                  value={meta}
                  handleChange={setMeta}
                  heigth="111px"
                  hasTooltip
                  textTooltipSecondary={
                    intl.messages[
                      'labelsInputs.tooltipEditorialMetaDescription'
                    ]
                  }
                  disabled
                />
              </Grid>
              <Grid item md={12}>
                <SelectSearch
                  label="Plataforma"
                  options={options}
                  defaultOption={platform}
                  handleChange={setPlatform}
                  tooltip
                  title={intl.messages['labelsInputs.tooltipEditorialPlatform']}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <TextAreaDescription
                  label="Descrição"
                  maxLength={300}
                  value={description}
                  handleChange={setDescription}
                  heigth="46vh"
                  textTooltipSecondary={
                    intl.messages['labelsInputs.tooltipEditorialDescription']
                  }
                  disabled
                />
              </Grid>

              <Grid item md={12}>
                <FileInput
                  label={intl.messages['labelsInputs.uplodadImageLabel']}
                  text={intl.messages['labelsInputs.toDoUploadImage']}
                  title={intl.messages['labelsInputs.tooltipEditorialImage']}
                  accept="image/*"
                  fileName={fileName}
                  hasTooltip
                  disabled
                />
              </Grid>
              <Grid item md={12}>
                <InputText
                  label={intl.messages['labelsInputs.altImageLabel']}
                  value={altImage}
                  handleChange={setAltImage}
                  height="10vh"
                  maxLength={100}
                  isCount
                  hasTooltip
                  textTooltip={
                    intl.messages['labelsInputs.tooltipEditorialAltTextImage']
                  }
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ActionButtons
          type={location.state === 'deleted' ? 'none' : 'view'}
          cancelPath={() => {
            cancelEditEditorial();
          }}
          submitFunc={() => {
            history.push(urls.ROUTES.EDITORIALS_EDIT.path);
          }}
        />
      </FormContainer>
    </>
  );
};

EditorialsView.propTypes = {
  location: PropTypes.object,
};

EditorialsView.defaultProps = {
  location: {},
};

export default EditorialsView;
