const units = [
  {
    value: 'unit',
    label: 'Unidade',
  },
  {
    value: 'mass',
    label: 'Massa',
  },
  {
    value: 'liters',
    label: 'Litros',
  },
  {
    value: 'length',
    label: 'Comprimento',
  },
  {
    value: 'inches',
    label: 'Polegadas',
  },
];

export const findTranslate = (unit) => {
  const found = units.find((data) => data.value === unit);
  return found.label;
};
