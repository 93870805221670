/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { DRY_PACKAGES } from 'constants/endpoints';
import api from 'services/api';
import { startLoading, stopLoading } from 'store/loading/loading-slice';
import { errorSessionToast, errorToast } from 'utils/toasts';

const INITIAL_STATE = {
  list: {
    totalPage: 1,
    totalItems: 0,
    items: [],
  },
  dryPackage: {
    id: 0,
    name: '',
    slug: '',
    unitAlias: '',
    materialId: 0,
    width: 0,
    widthUnitId: 0,
    height: 0,
    heightUnitId: 0,
    length: 0,
    lengthUnitId: 0,
    createdAt: '',
    updatedAt: '',
    deletedAt: null,
    widthUnit: {
      name: '',
      slug: '',
      alias: '',
      physicalQuantity: '',
    },
    heightUnit: {
      name: '',
      slug: '',
      alias: '',
      physicalQuantity: '',
    },
    lengthUnit: {
      name: '',
      slug: '',
      alias: '',
      physicalQuantity: '',
    },
    material: {
      id: 0,
      name: '',
      slug: '',
    },
  },
};

const dryPakageSlice = createSlice({
  name: 'dryPackage',
  initialState: INITIAL_STATE,
  reducers: {
    getDrypackageSuccess: (state, { payload }) => {
      state.list = payload;
    },
    getDryPackageDetails: (state, { payload }) => {
      state.dryPackage = payload;
    },
  },
});

export const dryPackage = dryPakageSlice.reducer;
export const { getDrypackageSuccess, getDryPackageDetails } =
  dryPakageSlice.actions;

export const dryPackageList = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { currentPage, searchTerm, perPage, ordenation } = payload;
  try {
    let url = '';
    if (searchTerm === '') {
      url = `${DRY_PACKAGES}?perPage=${
        perPage || '10'
      }&page=${currentPage}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}`;
    } else {
      url = `${DRY_PACKAGES}?perPage=${
        perPage || '10'
      }&page=${currentPage}&name=${searchTerm}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}`;
    }
    const response = await api.get(url);

    dispatch(getDrypackageSuccess(response.data));

    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const saveDryPackage = (payload) => async (dispatch) => {
  dispatch(startLoading());

  try {
    await api.post(DRY_PACKAGES, payload);
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          errorToast(
            'Não foi possível criar essa embalagem, verifique as informações.',
          );
          break;
        case 409:
          errorToast('Embalagem já existente.');
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const patchDryPackage = (payload) => async (dispatch) => {
  dispatch(startLoading());

  try {
    await api.patch(`${DRY_PACKAGES}/${payload.id}`, payload);
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          errorToast(
            'Não foi possível atualizar essa embalagem, verifique as informações.',
          );
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const getDryPackage = (payload) => async (dispatch) => {
  dispatch(startLoading());

  try {
    const res = await api.get(`${DRY_PACKAGES}/${payload.id}`);
    dispatch(getDryPackageDetails(res.data));
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 404:
          errorToast(
            'Não foi possível encontrar essa embalagem, verifique as informações.',
          );
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};
