import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/structure/Modal/Modal';
import Button from 'components/structure/Button/Button';
import { Container, ButtonsContainer } from './ModalConfirmation.styles';

const ModalConfirmation = ({
  buttonName,
  text,
  isOpen,
  setIsOpen,
  title,
  confirmOnClick,
  loading,
}) => {
  const confirmationContent = (
    <Container>
      {text && <p>{text}</p>}

      <ButtonsContainer>
        <Button
          btnType="secondary"
          width="100%"
          borderRadius="4px"
          onClick={() => setIsOpen(false)}
          fontWeight="600"
        >
          CANCELAR
        </Button>

        <Button
          btnType="danger"
          width="100%"
          borderRadius="4px"
          onClick={confirmOnClick}
          fontWeight="600"
        >
          {buttonName}
        </Button>
      </ButtonsContainer>
    </Container>
  );

  return (
    <Modal
      titleRight
      title={title}
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      content={confirmationContent}
      loading={loading}
    />
  );
};

ModalConfirmation.propTypes = {
  text: PropTypes.node,
  title: PropTypes.node,
  buttonName: PropTypes.node,
  setIsOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  confirmOnClick: PropTypes.func,
  loading: PropTypes.number,
};

ModalConfirmation.defaultProps = {
  text: null,
  title: null,
  buttonName: null,
  setIsOpen: () => {},
  isOpen: false,
  confirmOnClick: () => {},
  loading: 0,
};

export default ModalConfirmation;
