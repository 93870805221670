import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  p {
    width: 410px;
    text-align: center;
    margin: 8px 0px 32px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  padding: 0;
  margin-top: 30px;
`;
