import styled from 'styled-components';
import calendarIcon from 'assets/icons/calendar.svg';


export const ReportModalStyledContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;

    button {
        margin-left: 0px;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
        filter: invert(1);
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
        background-image: url(${calendarIcon});
        background-position: center;
        background-repeat: no-repeat;
        background-size: 20px;
    }

    a {
        text-decoration: none;
        underline: none;
    }

    a:hover {
        underline: none;
    }
`


export const ReportModalStyledButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
`

export const ReportModalStyledInput = styled.input`
    width: 415px;
    height: 40px;
    border: 1px solid #BABABA;
    border-radius: 2px;
    padding: 5px;

    color: #BABABA;
    font-family: Trebuchet MS;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

`
export const ReportModalStyledLabel = styled.label`
    font-family: Trebuchet MS;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
`;


export const CheckBoxContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
`;