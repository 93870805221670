import styled from 'styled-components';

export const StyledInput = styled.input`
  cursor: pointer;
  all: unset;
  -webkit-appearance: none;
  color: #f66602;
  background-color: #d3d3d3;
  width: 100%;
  height: 6px;
  border-radius: 2px;

  ::-moz-range-thumb {
    background-color: #f66602;
    border: none;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #f66602;
    margin-top: -4px;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 6px;
    background: #d3d3d3;
  }

  &::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    height: 12px;
    width: 12px;
    background: #f66602;
    border-radius: 100%;
    border: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;
