import PropTypes from 'prop-types';
import React from 'react';
import { Container, ContainerInput } from './TogleTable.styles';

const Toggle = ({
  id,
  name,
  checked,
  disabled,
  readonly,
  setData,
  slug,
  isNotClickable,
  title,
  onChangeFunction,
}) => {
  return (
    <>
      <Container>
        <ContainerInput
          isNotClickable={isNotClickable}
          cursorType={!readonly ? 'no-drop' : 'pointer'}
          title={!readonly ? title : ''}
        >
          <input
            name={name}
            type="checkbox"
            checked={checked}
            disabled={disabled}
            onChange={
              !readonly
                ? () => {}
                : () => {
                    setData(slug);
                    onChangeFunction();
                  }
            }
          />
          <span className="slider round" />
        </ContainerInput>
      </Container>
    </>
  );
};

Toggle.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  setData: PropTypes.func,
  slug: PropTypes.string,
  isNotClickable: PropTypes.bool,
  title: PropTypes.string,
  onChangeFunction: PropTypes.func,
};

Toggle.defaultProps = {
  name: '',
  checked: false,
  disabled: false,
  readonly: false,
  setData: () => {},
  slug: '',
  isNotClickable: false,
  title: '',
  onChangeFunction: () => {},
};

export default Toggle;
