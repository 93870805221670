/* eslint-disable no-unused-vars */
import Grid from '@material-ui/core/Grid';
import AsyncSelectPaginate from 'components/form/Select/AsyncSelectPaginate/AsyncSelectPaginate';
import SelectSearch from 'components/form/Select/ReactSelect';
import InputText from 'components/form/Text/TextInput/InputText';
import Toggle from 'components/form/Toggle/Toggle';
import FormContainer from 'components/structure/FormContainer/FormContainer';
import BoxAlert from 'components/structure/BoxAlert/BoxAlert';
import { BASES, COLORS, SIZES } from 'constants/endpoints';
import urls from 'constants/urls';
import React, { useEffect, useState } from 'react';
import Button from '../../../../components/structure/Button/Button';

import { platforms, systemColors } from '../Edit/dataSkusOptions';
import { ButtonsContainer } from '../../Skus.styles';
import { getSkuDetail } from 'store/sku/sku-slice';

const { useIntl } = require('react-intl');
const { useSelector, useDispatch } = require('react-redux');
const { useHistory } = require('react-router-dom');

const SkuView = () => {
  const { sku, selectId } = useSelector((state) => state.sku);

  const product = useSelector((state) => state.sku.product);

  const [systemColor, setSystemColor] = useState('');
  const [ean, setEan] = useState('');
  const [sap, setSap] = useState('');
  const [platform, setPlatform] = useState([]);
  const [base, setBase] = useState(null);
  const [size, setSize] = useState(null);
  const [color, setColor] = useState(null);
  const [defaultSku, setDefaultSku] = useState(false);
  const [yieldSku, setYieldSku] = useState(null);
  const [finishYield, setFinishYield] = useState(null);
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const [length, setLength] = useState(null);
  const [liquidWeight, setLiquidWeight] = useState(null);
  const [grossWeight, setGrossWeight] = useState(null);
  const [fileName, setFileName] = useState('');
  const [altImage, setAltImage] = useState('');
  const [imageError, setImageError] = useState(null);
  const [altImageError, setAltImageError] = useState('');
  const [active, setActive] = useState(false);

  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectId === 0) {
      history.push(urls.ROUTES.SKU_LIST.path);
    } else {
      dispatch(getSkuDetail(parseInt(selectId, 10)));
    }
  }, [selectId, dispatch, history]);

  useEffect(() => {
    setSystemColor(sku?.systemColor);
    setEan(sku?.ean);
    setSap(sku?.sapCode);
    setPlatform(sku?.platforms?.map((value) => value));
    setBase({ name: sku?.base?.name });
    setSize({ name: sku?.size?.name });
    setColor({ name: sku?.productToColor?.color?.name });
    setDefaultSku(sku?.default);
    setYieldSku(sku?.yield);
    setFinishYield(sku?.finishYield);
    setHeight(sku?.height);
    setWidth(sku?.width);
    setLength(sku?.length);
    setLiquidWeight(sku?.liquidWeight);
    setGrossWeight(sku?.grossWeight);
    setActive(sku.active);

    if (sku?.images?.showcase) {
      setFileName(sku?.images?.showcase[0]?.fullUrl);
      setAltImage(sku?.images?.showcase[1]?.alternativeText);
    }
  }, [sku]);

  return (
    <FormContainer title="Visualizar SKU">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid item md={12}>
              <InputText
                type="text"
                placeholder="000"
                label={intl.messages['labelsInputs.productSku']}
                value={product.name}
                disabled
              />
              <Grid item md={12}>
                <SelectSearch
                  label={intl.messages['labelsInputs.systemColorSku']}
                  placeholder={intl.messages['labelsInputs.select']}
                  options={systemColors}
                  defaultOption={systemColor}
                  disabled
                />
              </Grid>
            </Grid>

            <Grid item md={12}>
              <InputText
                type="text"
                placeholder="000"
                label={intl.messages['labelsInputs.eanSku']}
                value={ean}
                disabled
              />
            </Grid>
            <Grid item md={12}>
              <InputText
                type="number"
                placeholder="000"
                label={intl.messages['labelsInputs.sapSku']}
                value={parseInt(sap, 10)}
                disabled
              />
            </Grid>
            <Grid item md={12}>
              <SelectSearch
                label={intl.messages['labelsInputs.typePlatform']}
                placeholder={intl.messages['labelsInputs.select']}
                options={platforms}
                defaultMultiOptions={platform}
                multi
                disabled
              />
            </Grid>
            <Grid item md={12}>
              <AsyncSelectPaginate
                label={intl.messages['labelsInputs.baseSku']}
                placeholder={intl.messages['labelsInputs.select']}
                value={base}
                url={BASES}
                perPage={5}
                disabled
              />
            </Grid>
            <Grid item md={12}>
              <AsyncSelectPaginate
                label={intl.messages['labelsInputs.sizeSku']}
                placeholder={intl.messages['labelsInputs.select']}
                value={size}
                url={SIZES}
                perPage={10}
                disabled
              />
            </Grid>
            <Grid item md={12}>
              <AsyncSelectPaginate
                label={intl.messages['labelsInputs.colorSku']}
                placeholder={intl.messages['labelsInputs.select']}
                value={color}
                url={COLORS}
                perPage={10}
                disabled
              />
            </Grid>
            <Grid item md={12}>
              <Toggle
                label={intl.messages['labelsInputs.defaultSku']}
                checked={defaultSku}
                onChange={(e) => setDefaultSku(e.target.checked)}
                regular
                disabled
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid item md={12}>
              <InputText
                type="number"
                placeholder="000"
                label={intl.messages['labelsInputs.yieldSku']}
                value={yieldSku}
                step="0.01"
                disabled
              />
            </Grid>
            <Grid item md={12} style={{ paddingTop: '9.5px' }}>
              <Grid item md={12}>
                <InputText
                  type="number"
                  placeholder="000"
                  label={intl.messages['labelsInputs.finishYieldSku']}
                  value={finishYield}
                  step="0.01"
                  disabled
                />
              </Grid>
              <Grid item md={12}>
                <InputText
                  type="number"
                  placeholder="000"
                  label={intl.messages['labelsInputs.widthSku']}
                  value={width}
                  step="0.01"
                  disabled
                />
              </Grid>
              <Grid item md={12}>
                <InputText
                  type="number"
                  placeholder="000"
                  label={intl.messages['labelsInputs.heightSku']}
                  value={height}
                  step="0.01"
                  disabled
                />
              </Grid>
              <Grid item md={12}>
                <InputText
                  type="number"
                  placeholder="000"
                  label={intl.messages['labelsInputs.lengthSku']}
                  value={length}
                  step="0.01"
                  disabled
                />
              </Grid>

              <Grid item md={12} style={{ paddingTop: '10px' }}>
                <InputText
                  type="number"
                  placeholder="000"
                  label={intl.messages['labelsInputs.liquidWeightSku']}
                  value={liquidWeight}
                  step="0.01"
                  disabled
                />
              </Grid>
              <Grid item md={12} style={{ paddingTop: '10px' }}>
                <InputText
                  type="number"
                  placeholder="000"
                  label={intl.messages['labelsInputs.grossWeightSku']}
                  value={grossWeight}
                  step="0.01"
                  disabled
                />
              </Grid>
              <Grid item md={12}>
                <Toggle
                  label={intl.messages['labelsInputs.active']}
                  checked={active}
                  onChange={(e) => setActive(e.target.checked)}
                  regular
                  disabled
                />
              </Grid>
              <Grid
                item
                md={12}
                style={{
                  paddingTop: '10px',
                }}
              >
                <BoxAlert
                  title="Atenção"
                  text="Acesse: Catalogo > Produtos > Editar. Para visualizar as imagens."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ButtonsContainer>
        <Grid container spacing={12} md={3}>
          <Button
            btnType="primary"
            width="100%"
            borderRadius="4px"
            fontWeight="600"
            onClick={() => {
              history.push(urls.ROUTES.SKU_LIST.path);
            }}
          >
            {intl.messages['buttons.toBack']}
          </Button>
        </Grid>
      </ButtonsContainer>
    </FormContainer>
  );
};

export default SkuView;
