import * as Yup from 'yup';

export const schema = Yup.object().shape({
  name: Yup.string().required('Nome não preenchido'),
  platforms: Yup.string().required('Plataforma não foi selecionada'),
});

export const defaultValues = {
  name: '',
  platforms: '',
};
