import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import FormContainer from 'components/structure/FormContainer/FormContainer';
import Grid from '@material-ui/core/Grid';
import InputText from 'components/form/Text/TextInput/InputText';
import Toggle from 'components/form/Toggle/Toggle';
import TextAreaDescription from 'components/form/TextArea/TextAreaDescription/TextAreaDescription';
import ActionButtons from 'components/form/ActionButtons';

import SelectSearch from 'components/form/Select/ReactSelect';
import urls from 'constants/urls';
import { createSlug } from 'utils/createSlug';
import arrayToString from 'utils/arrayToString';
import { useDispatch, useSelector } from 'react-redux';
import { errorToast, successToast } from 'utils/toasts';

import { getPermission } from 'utils/permissions';
import ModalMultiConfirmation from 'components/contexts/modal/ModalMultiConfirmation/ModalMultiConfirmation';
import { dataTypes, dataPlatforms } from './dataOptionsColorGroups';
import {
  addColorGroups,
  statusColorGroupsModal,
} from 'store/colorGroups/colorGroups-slice';

const ColorGroupsCreate = () => {
  const { openStatusModal, status, msg } = useSelector(
    (state) => state.colorGroups,
  );
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [platforms, setPlatforms] = useState([]);
  const [description, setDescription] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [active, setActive] = useState(true);
  // essas cores faziam parte de uma propriedade RGB que foi ocultada no momento
  const [color1] = useState('0');
  const [color2] = useState('0');
  const [color3] = useState('0');
  const [showModalConfirmationSave, setShowModalConfirmationSave] =
    useState(false);
  const [showModalConfirmationCancel, setShowModalConfirmationCancel] =
    useState(false);
  const [colorRgbError, setColorRgbError] = useState({
    color1: '',
    color2: '',
    color3: '',
  });
  const [colorRgbNumberError, setColorRgbNumberError] = useState({
    color1: '',
    color2: '',
    color3: '',
  });

  const [statusForm, setStatus] = useState({
    type: '',
  });

  const tempColorRgb = [color1, color2, color3];
  const colorRgb = arrayToString(tempColorRgb);
  const { permissions } = useSelector((state) => state.auth);
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    if (getPermission('color-groups', permissions, 'create') === false) {
      history.push('/');
    }
  }, [history, permissions]);

  const cancelColorGroup = () => {
    history.push(urls.ROUTES.CATALOGUE.items.colorGroups.path);
  };

  function validate() {
    if (!name)
      return setStatus({
        type: 'error',
      });
    if (!platforms)
      return setStatus({
        type: 'error',
      });
    if (!description)
      return setStatus({
        type: 'error',
      });
    if (!shortDescription)
      return setStatus({
        type: 'error',
      });
    if (!metaDescription)
      return setStatus({
        type: 'error',
      });
    if (!type)
      return setStatus({
        type: 'error',
      });
    if (!color1)
      return setStatus({
        type: 'error',
      });
    if (!color2)
      return setStatus({
        type: 'error',
      });
    if (!color3)
      return setStatus({
        type: 'error',
      });
    return true;
  }

  const saveColorGroup = () => {
    if (!validate()) {
      setShowModalConfirmationSave(false);
      return;
    }
    const formData = new FormData();

    formData.append('name', name);
    formData.append('type', type);
    formData.append('platforms', JSON.stringify(platforms));
    formData.append('description', description);
    formData.append('shortDescription', shortDescription);
    formData.append('metaDescription', metaDescription);
    formData.append('isVisible', isVisible);
    formData.append('active', active);
    formData.append('rgb', colorRgb);

    if (
      color1 !== '' &&
      color2 !== '' &&
      color3 !== '' &&
      color1 >= 0 &&
      color1 <= 255 &&
      color2 >= 0 &&
      color2 <= 255 &&
      color3 >= 0 &&
      color3 <= 255
    ) {
      dispatch(addColorGroups(formData));
      if (status !== 'error') {
        history.push(urls.ROUTES.CATALOGUE.items.colorGroups.path);
        successToast(`O grupo de cor ${name} foi criado com sucesso!`);
      }
    } else {
      setColorRgbError(
        color1 === '' &&
          color2 === '' &&
          color3 === '' &&
          errorToast('Campo RGB deve ser preenchido'),
      );

      if (
        color1 < 0 ||
        color1 > 255 ||
        color2 < 0 ||
        color2 > 255 ||
        color3 < 0 ||
        color3 > 255
      ) {
        setColorRgbNumberError(
          errorToast('Campo RGB não deve ser menor que zero e maior que 255'),
        );
      }
    }
  };

  return (
    <>
      <ModalMultiConfirmation
        title={intl.messages['textModal.createColorGroup']}
        isOpen={showModalConfirmationSave}
        setIsOpen={setShowModalConfirmationSave}
        buttonNameBack={intl.messages['buttons.not']}
        buttonName={intl.messages['buttons.yes']}
        confirmOnClick={saveColorGroup}
        status="confirm"
      />
      <ModalMultiConfirmation
        title={intl.messages['textModal.cancelCreateColorGroup']}
        isOpen={showModalConfirmationCancel}
        setIsOpen={setShowModalConfirmationCancel}
        buttonNameBack={intl.messages['buttons.not']}
        buttonName={intl.messages['buttons.yes']}
        confirmOnClick={cancelColorGroup}
        status="info"
      />

      <ModalMultiConfirmation
        title={msg.title}
        text={msg.text}
        setIsOpen={(payload) => {
          dispatch(statusColorGroupsModal(payload));
          if (status !== 'error') {
            history.push(urls.ROUTES.CATALOGUE.items.colorGroups.path);
          }
        }}
        isOpen={openStatusModal}
        status={status}
      />

      <FormContainer title={intl.messages['textHeader.createColorGroups']}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item md={12}>
                <InputText
                  placeholder={intl.messages['labelsInputs.typeYourName']}
                  label={intl.messages['labelsInputs.typeYourName']}
                  value={name}
                  handleChange={setName}
                  error={
                    statusForm.type === 'error'
                      ? 'Campo Nome deve ser preenchido'
                      : ''
                  }
                  isError={name === '' && statusForm.type}
                />
              </Grid>
              <Grid item md={12}>
                <InputText
                  placeholder={intl.messages['labelsInputs.slug']}
                  label={intl.messages['labelsInputs.slug']}
                  disabled
                  value={createSlug(name)}
                />
              </Grid>

              <Grid item md={12}>
                <SelectSearch
                  label={intl.messages['labelsInputs.type']}
                  placeholder={intl.messages['labelsInputs.select']}
                  options={dataTypes}
                  defaultOption={type}
                  handleChange={setType}
                  error={
                    statusForm.type === 'error'
                      ? 'Campo Tipo deve ser preenchido'
                      : ''
                  }
                  isError={type === '' && statusForm.type}
                />
              </Grid>
              <Grid item md={12}>
                <SelectSearch
                  label={intl.messages['labelsInputs.platforms']}
                  placeholder={intl.messages['labelsInputs.select']}
                  options={dataPlatforms}
                  defaultMultiOptions={platforms}
                  handleChange={setPlatforms}
                  multi
                  error={
                    statusForm.type === 'error'
                      ? 'Campo Plataformas deve ser preenchido'
                      : ''
                  }
                  isError={platforms.length === 0 && statusForm.type}
                />
              </Grid>
              <Grid item md={12}>
                <TextAreaDescription
                  label={intl.messages['labelsInputs.description']}
                  maxLength={140}
                  value={description}
                  handleChange={setDescription}
                  heigth="145px"
                  error={
                    statusForm.type === 'error'
                      ? 'Campo Descrição deve ser preenchido'
                      : ''
                  }
                  isError={description === '' && statusForm.type}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <TextAreaDescription
                  label={intl.messages['labelsInputs.shortDescription']}
                  maxLength={140}
                  value={shortDescription}
                  handleChange={setShortDescription}
                  heigth="145px"
                  error={
                    statusForm.type === 'error'
                      ? 'Campo Descrição Curta deve ser preenchido'
                      : ''
                  }
                  isError={shortDescription === '' && statusForm.type}
                />
              </Grid>
              <Grid item md={12}>
                <TextAreaDescription
                  label={intl.messages['labelsInputs.metaDescription']}
                  maxLength={140}
                  value={metaDescription}
                  handleChange={setMetaDescription}
                  heigth="145px"
                  error={
                    statusForm.type === 'error'
                      ? 'Campo Meta Descrição deve ser preenchido'
                      : ''
                  }
                  isError={metaDescription === '' && statusForm.type}
                />
              </Grid>

              <Grid item md={12}>
                <Toggle
                  label={
                    intl.messages[
                      'labelsInputs.isVisibleOnTheHomeOfTheDigitalFan'
                    ]
                  }
                  checked={isVisible}
                  onChange={(e) => setIsVisible(e.target.checked)}
                  regular
                />
              </Grid>

              <Grid item md={12}>
                <Toggle
                  label={intl.messages['labelsInputs.active']}
                  checked={active}
                  onChange={(e) => setActive(e.target.checked)}
                  regular
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ActionButtons
          type="create"
          cancelPath={() => setShowModalConfirmationCancel(true)}
          submitFunc={() => {
            if (!validate()) {
              setShowModalConfirmationSave(false);
            } else {
              setShowModalConfirmationSave(true);
            }
          }}
        />
      </FormContainer>
    </>
  );
};

export default ColorGroupsCreate;
