/* eslint-disable react/prop-types */
import React from 'react';
import InputTextProperties from 'components/contexts/products/PropertiesComponent/InputTextProperties/InputTextProperties';
import InputSelectProperties from 'components/contexts/products/PropertiesComponent/InputSelectProperties/InputSelectProperties';

const types = {
  string: 'text',
  integer: 'number',
  double: 'number',
  timestamp: 'date',
};

const options = [
  { value: true, label: 'Verdadeiro' },
  { value: false, label: 'Falso' },
];

const steps = {
  string: 'any',
  integer: 'any',
  double: '.01',
  timestamp: 'any',
};

const formateDate = (value) => {
  return value
    ? new Date(value)?.toISOString()?.slice(0, 10)
    : new Date()?.toISOString()?.slice(0, 10);
};

const getInputs = (
  typeProperty,
  valueProperty,
  setValueProperty,
  name,
  disabled,
) => {
  switch (typeProperty) {
    case 'string':
    case 'double':
      return (
        <InputTextProperties
          name={name}
          type={types[typeProperty]}
          step={steps[typeProperty]}
          label="Valor"
          value={valueProperty}
          handleChange={setValueProperty}
          padding="9px 0 0 0"
          disabled={disabled}
        />
      );

    case 'integer':
      return (
        <InputTextProperties
          name={name}
          type={types[typeProperty]}
          step={steps[typeProperty]}
          label="Valor"
          value={parseInt(valueProperty, 10)}
          handleChange={setValueProperty}
          padding="9px 0 0 0"
          disabled={disabled}
        />
      );

    case 'boolean':
      return (
        <InputSelectProperties
          name={name}
          label="Valor"
          options={options}
          value={valueProperty}
          handleChange={setValueProperty}
          disabled={disabled}
        />
      );

    case 'timestamp':
      return (
        <InputTextProperties
          name={name}
          type={types[typeProperty]}
          step={steps[typeProperty]}
          label="Valor"
          required
          pattern="\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])"
          max="9999-12-31"
          value={formateDate(valueProperty)}
          handleChange={setValueProperty}
          padding="9px 0 0 0"
          disabled={disabled}
        />
      );

    default:
      return (
        <InputTextProperties
          name="value"
          label="Valor"
          padding="9px 0 0 0"
          disabled
        />
      );
  }
};

export const TypeInputComponent = ({
  typeProperty,
  valueProperty,
  setValueProperty,
  name,
  disabled,
}) => {
  return (
    <>
      {getInputs(typeProperty, valueProperty, setValueProperty, name, disabled)}
    </>
  );
};
