import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InputText from 'components/form/Text/TextInput/InputText';
import TextAreaDescription from 'components/form/TextArea/TextAreaDescription/TextAreaDescription';
import SelectInputOptions from 'components/form/Select/ReactSelect/SelectInputOptions';
import Grid from '@material-ui/core/Grid';
import Button from 'components/structure/Button/Button';
import FileInput from 'components/form/FileInput/FileInput';

import urls from 'constants/urls';
import { errorToast } from 'utils/toasts';

import { ButtonsContainer } from 'components/contexts/articles/ArticlesFirstStep/ArticlesFirstStep.styles';
import ModalMultiConfirmation from 'components/contexts/modal/ModalMultiConfirmation/ModalMultiConfirmation';
import MultiActionButton from 'components/form/MultiActionButton';
import ImageCropModal from 'components/contexts/modal/ImageCropModal/ImageCropModal';
import FileResizer from 'react-image-file-resizer';
import Loader from 'components/structure/Loader/Loader';
import {
  getArticleDetail,
  getEditorialOptions,
  setActiveStep,
  statusArticlesModal,
  updateArticle,
} from 'store/articles/articles-slice';

const options = [
  { value: 'institucional', label: 'Suvinil' },
  { value: 'glasu', label: 'Glasu' },
];

const seoTypeOptions = [
  { value: 'pdp', label: 'Página de produto' },
  { value: 'collection', label: 'Lista de Compras/Coleções' },
];

const ArticlesFirstStepEdit = () => {
  const { openStatusModal, status, msg, article, selectId } = useSelector(
    (state) => state.articles,
  );

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [imgSrc, setImgSrc] = useState('');
  const [cancelImage, setCancelImage] = useState(false);
  const [updateImage, setUpdateImage] = useState();

  const [resource, setResource] = useState(null);
  const [seoType, setSeoType] = useState(null);
  const [siteName, setSiteName] = useState('');
  const [meta, setMeta] = useState('');
  const [altImage, setAltImage] = useState('');
  const [platforms, setPlatforms] = useState('');
  const [isPublished, setIsPublished] = useState(article.published);
  const [fileName, setFileName] = useState('');
  const [imageError, setImageError] = useState(null);
  const [dataImage, setDataImage] = useState([{}]);
  const [showModalCancel, setShowModalCancel] = useState(false);
  const [statusButton, setStatusButton] = useState(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);

  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const [statusForm, setStatus] = useState({
    type: '',
  });

  function validate() {
    const requiredFields = [seoType, resource, platforms, siteName, meta];
    if (requiredFields.some((field) => !field)) {
      setStatus({
        type: 'error',
      });
      return false;
    }

    return true;
  }

  const cancelArticle = () => {
    history.push(urls.ROUTES.ARTICLES.path);
  };

  const advanceStepArticle = () => {
    dispatch(setActiveStep(1));
  };

  const onchangeSelectPlatform = (item) => {
    setPlatforms(item);
  };

  useEffect(() => {
    dispatch(getEditorialOptions());
  }, [dispatch]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (selectId === 0) {
      history.push(urls.ROUTES.ARTICLES.path);
    } else {
      dispatch(getArticleDetail(parseInt(selectId, 10)));
      return <Loader loading />;
    }
  }, [selectId, dispatch, history]);

  useEffect(() => {
    setSiteName(article.siteTitle);
    setMeta(article.shortDescription);
    setResource({ value: article.slug, label: article.name });
    setIsPublished(article.published);
    if (article?.platforms?.[0] === 'institucional') {
      setPlatforms({ value: 'institucional', label: 'Suvinil' });
    } else {
      setPlatforms({ value: 'glasu', label: 'Glasu' });
    }

    if (article?.type === 'pdp') {
      setSeoType(seoTypeOptions[0]);
    }

    if (article?.type === 'collection') {
      setSeoType(seoTypeOptions[1]);
    }

    if (article?.images?.[0]) {
      setAltImage(article?.images?.[0]?.alternativeText);
      setFileName(article.images[0]?.fullUrl);
      setImgSrc(article?.images?.[0]?.fullUrl);
    }

    return <Loader loading={false} />;
  }, [article, dispatch]);

  useEffect(() => {
    if (article?.images?.[0]) {
      setFileName(article.images[0]?.fullUrl);
    }
  }, [article.images]);

  const isImage = (extension) => {
    switch (extension.toLowerCase()) {
      case 'jpg':
      case 'gif':
      case 'bmp':
      case 'jpeg':
      case 'webp':
      case 'png':
      case 'svg':
        return true;
      default:
        return false;
    }
  };

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  const getImageFile = async (event) => {
    if (event.target.files.length !== 0) {
      const file = event.target.files[0];
      const filenames = file.name.split('.');
      const fileType = filenames[filenames.length - 1];
      if (file.size < 5 * 1024 * 1024) {
        if (isImage(fileType)) {
          setFileName(file.name);
          setDataImage({ ...dataImage, image: file });
          const imageDataUrl = await readFile(file);
          setImgSrc(imageDataUrl);
          setImageError(null);
          setIsOpenModal(true);
        } else {
          setImageError(
            errorToast(
              'A imagem precisa estar em um desses formatos: .png, .jpg, .gif, etc...',
            ),
          );
        }
      } else {
        setImageError(errorToast('A imagem deve ter no máximo 5 MB'));
      }
    }
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      FileResizer.imageFileResizer(
        file,
        platforms.value === 'glasu' ? 1100 : 1366,
        platforms.value === 'glasu' ? 400 : 512,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'file',
        platforms.value === 'glasu' ? 1100 : 1366,
        platforms.value === 'glasu' ? 400 : 512,
      );
    });

  const getResizedImage = async () => {
    const resizedImage = await resizeFile(dataImage.image);
    setDataImage({ ...dataImage, image: resizedImage });
    return resizedImage;
  };

  const saveStepOne = async () => {
    const arrayPlatforms = [];
    arrayPlatforms.push(platforms.value);

    const arrayAltImage = [];
    if (altImage) {
      arrayAltImage.push(altImage);
    }

    if (!validate()) return;

    const formData = new FormData();

    formData.append('siteTitle', siteName);
    formData.append('shortDescription', meta);
    formData.append('name', article.name);
    formData.append('slug', article.slug);
    formData.append('description', article.description);
    formData.append('author', article.author);
    formData.append('published', isPublished);
    formData.append('platforms', JSON.stringify(arrayPlatforms));
    formData.append('editorialId', article.editorialId);
    formData.append('alternativeText', JSON.stringify(arrayAltImage));
    if (updateImage) {
      const imagem = await getResizedImage();
      formData.append('image', imagem);
    } else {
      formData.append('image', dataImage.image);
    }

    dispatch(
      updateArticle({
        info: formData,
        id: selectId,
        goTo: 1,
      }),
    );
  };

  useEffect(() => {
    if (cancelImage) {
      setImgSrc('');
      setFileName('');
      setDataImage([{}]);
    }
  }, [cancelImage]);

  useEffect(() => {
    setDataImage({ ...dataImage, image: updateImage });
  }, [updateImage]);

  function verifyChanges() {
    if (siteName !== article.siteTitle) {
      return false;
    }
    if (meta !== article.shortDescription) {
      return false;
    }
    if (platforms.value !== article.platforms[0]) {
      return false;
    }

    if (
      article?.images?.[0] &&
      altImage !== article?.images[0].alternativeText
    ) {
      return false;
    }
    if (updateImage) {
      return false;
    }

    return true;
  }

  return (
    <>
      <ImageCropModal
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        imgSrc={imgSrc}
        aspect={platforms.value === 'glasu' ? 11 / 4 : 16 / 6}
        setCancelButton={setCancelImage}
        setImage={setUpdateImage}
        updateFileName={setFileName}
        fileName={fileName}
      />
      <ModalMultiConfirmation
        title={intl.messages['textModal.areYouSure']}
        text={
          isPublished === false
            ? intl.messages['textModal.articleNotPublished']
            : intl.messages['textModal.articlePublished']
        }
        isOpen={showModalConfirmation}
        setIsOpen={setShowModalConfirmation}
        buttonNameBack={intl.messages['buttons.not']}
        buttonName={intl.messages['buttons.yes']}
        status="confirm"
        confirmOnClick={saveStepOne}
      />
      <ModalMultiConfirmation
        title={msg.title}
        text={msg.text}
        setIsOpen={(payload) => {
          dispatch(statusArticlesModal(payload));
          if (status !== 'error') {
            dispatch(setActiveStep(1));
          }
        }}
        isOpen={openStatusModal}
        status={status}
      />
      <ModalMultiConfirmation
        title={intl.messages['textModal.deleteEditArticle']}
        text={intl.messages['textModal.articleWillBeLost']}
        isOpen={showModalCancel}
        setIsOpen={setShowModalCancel}
        buttonNameBack={intl.messages['buttons.not']}
        buttonName={intl.messages['buttons.yes']}
        confirmOnClick={cancelArticle}
        status="info"
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid item md={12}>
              <SelectInputOptions
                zIndex="unset"
                label={intl.messages['labelsInputs.contentSEOTypeLabel']}
                value={seoType}
                disabled
                options={seoTypeOptions}
              />
            </Grid>
            <Grid item md={12}>
              <SelectInputOptions
                zIndex="unset"
                label={intl.messages['labelsInputs.platforms']}
                value={platforms}
                tooltip
                title={intl.messages['labelsInputs.tooltipArticlePlatforms']}
                disabled
                options={options}
              />
            </Grid>
            <Grid item md={12}>
              <SelectInputOptions
                zIndex="unset"
                label={
                  seoType?.value === 'pdp'
                    ? intl.messages['labelsInputs.selectProductLabel']
                    : intl.messages['labelsInputs.selectCollectionLabel']
                }
                value={resource}
                tooltip
                title={intl.messages['labelsInputs.tooltipArticlePlatforms']}
                disabled
                options={[resource]}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <InputText
                label={intl.messages['labelsInputs.siteTitle']}
                value={siteName}
                handleChange={(value) => {
                  setSiteName(value);
                  setStatusButton(true);
                }}
                hasTooltip
                textTooltip={
                  intl.messages['labelsInputs.tooltipArticleSiteTitle']
                }
                error={
                  statusForm.type === 'error'
                    ? 'Campo Site Title deve ser preenchido'
                    : ''
                }
                isError={siteName === '' && statusForm.type}
              />
            </Grid>
            <Grid item md={12}>
              <TextAreaDescription
                label={intl.messages['labelsInputs.shortDescription']}
                maxLength={155}
                value={meta}
                handleChange={(value) => {
                  setMeta(value);
                  setStatusButton(true);
                }}
                heigth="120px"
                textTooltipSecondary={
                  intl.messages['labelsInputs.tooltipArticleShortDescription']
                }
                error={
                  statusForm.type === 'error'
                    ? 'Campo Descrição Curta deve ser preenchido'
                    : ''
                }
                isError={meta === '' && statusForm.type}
              />
            </Grid>
            {!!seoType && seoType?.value === 'collection' && (
              <>
                <Grid item md={12}>
                  <FileInput
                    label={intl.messages['labelsInputs.uplodadImageLabel']}
                    text={intl.messages['labelsInputs.toDoUploadImage']}
                    title={
                      platforms === ''
                        ? intl.messages[
                            'labelsInputs.tooltipArticleUploadImage'
                          ]
                        : platforms.value === 'institucional'
                        ? intl.messages[
                            'labelsInputs.tooltipArticleUploadImageSuvinil'
                          ]
                        : intl.messages[
                            'labelsInputs.tooltipArticleUploadImageGlasu'
                          ]
                    }
                    accept="image/*"
                    fileName={fileName}
                    getFile={(value) => {
                      getImageFile(value);
                      setStatusButton(true);
                    }}
                    error={
                      statusForm.type === 'error'
                        ? 'Campo Imagem de capa deve ser preenchido'
                        : ''
                    }
                    isError={imageError}
                  />
                </Grid>
                <Grid item md={12}>
                  <TextAreaDescription
                    label={intl.messages['labelsInputs.altImageLabel']}
                    value={altImage}
                    handleChange={(value) => {
                      setAltImage(value);
                      setStatusButton(true);
                    }}
                    heigth="95px"
                    textTooltipSecondary={
                      intl.messages['labelsInputs.tooltipArticleAltText']
                    }
                    maxLength={100}
                    isCount
                    error={
                      statusForm.type === 'error'
                        ? 'Campo Alt da imagem de capa deve ser preenchido'
                        : ''
                    }
                    isError={altImage === '' && statusForm.type && fileName}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>

      <ButtonsContainer>
        <Grid container spacing={2}>
          <Grid item md={6} sm={12} />
          <Grid item md={3} sm={12}>
            <Button
              btnType="secondary"
              width="100%"
              borderRadius="4px"
              fontWeight="600"
              onClick={() => {
                if (!verifyChanges()) {
                  setShowModalCancel(true);
                } else {
                  cancelArticle();
                }
              }}
            >
              {intl.messages['buttons.cancel']}
            </Button>
          </Grid>
          {statusButton ? (
            <Grid item md={3} sm={12}>
              <MultiActionButton
                primaryLabel={intl.messages['buttons.toSaveAndAdvance']}
                primaryfunction={() => {
                  if (!validate()) {
                    setShowModalConfirmation(false);
                  } else {
                    saveStepOne();
                  }
                }}
                secondaryLabel={intl.messages['buttons.advanceNotSave']}
                secondaryFunction={() => advanceStepArticle()}
              />
            </Grid>
          ) : (
            <Grid item md={3} sm={12}>
              <Button
                btnType="primary"
                width="100%"
                borderRadius="4px"
                fontWeight="600"
                onClick={advanceStepArticle}
              >
                {intl.messages['buttons.advance']}
              </Button>
            </Grid>
          )}
        </Grid>
      </ButtonsContainer>
    </>
  );
};

export default ArticlesFirstStepEdit;
