export const platforms = [
  { value: 'app', label: 'App' },
  { value: 'ecommerce', label: 'Ecommerce' },
  { value: 'institucional', label: 'Suvinil' },
  { value: 'glasu', label: 'Glasu' },
];

export const types = [
  { value: 1, label: 'Departamento' },
  { value: 2, label: 'Categoria' },
  { value: 3, label: 'Subcategoria' },
];

export const typesForm = [
  { value: 1, label: 'Departamento Mãe' },
  { value: 2, label: 'Categoria' },
  { value: 3, label: 'Subcategoria' },
];
