import * as Yup from 'yup';

export const schema = Yup.object().shape({
  name: Yup.string().required('Nome não preenchido'),
  email: Yup.string()
    .email('Por favor informe um email válido')
    .required('Email não preenchido'),
});

export const defaultValues = {
  name: '',
  email: '',
};
