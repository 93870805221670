import Grid from '@material-ui/core/Grid';
import ModalMultiConfirmation from 'components/contexts/modal/ModalMultiConfirmation/ModalMultiConfirmation';
import ActionButtons from 'components/form/ActionButtons';
import AsyncSelectPaginateRgb from 'components/form/Select/AsyncSelectPaginate/AsyncSelectPaginateRgb';
import InputText from 'components/form/Text/TextInput/InputText';
import Toggle from 'components/form/Toggle/Toggle';
import FormContainer from 'components/structure/FormContainer/FormContainer';
import { COLORS } from 'constants/endpoints';
import urls from 'constants/urls';
import { getUserData } from 'lib/contexts/auth';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import BoxAlert from '../../components/structure/BoxAlert/BoxAlert';
import {
  getCombinationDetail,
  editColorCombination,
} from 'store/colorCombinations/colorCombinations-slice';

const ColorCombinationsEdit = () => {
  const user = getUserData();

  const { selectId, colorCombination } = useSelector(
    (state) => state.colorCombinations,
  );
  const [name, setName] = useState('');
  const [color1, setColor1] = useState({});
  const [color2, setColor2] = useState({});
  const [color3, setColor3] = useState({});
  const [color4, setColor4] = useState({});
  const [publicationStatus, setPublicationStatus] = useState(true);

  const [showModalConfirmationSave, setShowModalConfirmationSave] =
    useState(false);
  const [showModalConfirmationCancel, setShowModalConfirmationCancel] =
    useState(false);

  const [statusForm, setStatus] = useState({
    type: '',
  });

  const { permissions } = useSelector((state) => state.auth);
  const { status } = useSelector((state) => state.colorCombinations);
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    /*     if (getPermission('color-combination', permissions, 'create') === false) {
      history.push('/');
    } */
  }, [history, permissions]);

  useEffect(() => {
    if (selectId === 0) {
      history.push(urls.ROUTES.CATALOGUE.items.combinations.path);
    } else {
      dispatch(getCombinationDetail(selectId));
    }
  }, [selectId, dispatch, history]);

  const cancelColorCombination = () => {
    history.push(urls.ROUTES.CATALOGUE.items.combinations.path);
  };

  useEffect(() => {
    setName(colorCombination.name);
    if (colorCombination?.colorToColorCombinations[0]?.id) {
      setColor1(colorCombination.colorToColorCombinations[0]);
    }
    if (colorCombination?.colorToColorCombinations[1]?.id) {
      setColor2(colorCombination.colorToColorCombinations[1]);
    }
    if (colorCombination?.colorToColorCombinations[2]?.id) {
      setColor3(colorCombination?.colorToColorCombinations[2]);
    }
    if (colorCombination?.colorToColorCombinations[3]?.id) {
      setColor4(colorCombination?.colorToColorCombinations[3]);
    }
    setPublicationStatus(colorCombination.published);
  }, [colorCombination]);
  function validate() {
    if (!name) {
      return setStatus({
        type: 'error',
      });
    }
    if (!color1.id) {
      return setStatus({
        type: 'error',
      });
    }
    if (!color2.id) {
      return setStatus({
        type: 'error',
      });
    }
    return true;
  }

  const saveCombination = () => {
    const colorsArray = [];

    colorsArray.push({ id: color1.id, position: 1 });
    colorsArray.push({ id: color2.id, position: 2 });
    if (color3.id) {
      colorsArray.push({ id: color3.id, position: 3 });
    }
    if (color4.id) {
      colorsArray.push({ id: color4.id, position: 4 });
    }
    dispatch(
      editColorCombination({
        id: selectId,
        info: {
          name,
          published: publicationStatus,
          colors: colorsArray,
        },
      }),
    );
    if (status !== 'error') {
      history.push(urls.ROUTES.CATALOGUE.items.combinations.path);
    }
  };

  return (
    <>
      <ModalMultiConfirmation
        title={intl.messages['textModal.editColorCombination']}
        isOpen={showModalConfirmationSave}
        setIsOpen={setShowModalConfirmationSave}
        buttonNameBack={intl.messages['buttons.not']}
        buttonName={intl.messages['buttons.yes']}
        confirmOnClick={() => {
          if (validate) {
            saveCombination();
          } else {
            setShowModalConfirmationSave(false);
          }
        }}
        status="confirm"
      />
      <ModalMultiConfirmation
        title={intl.messages['textModal.cancelEditColorCombination']}
        isOpen={showModalConfirmationCancel}
        setIsOpen={setShowModalConfirmationCancel}
        buttonNameBack={intl.messages['buttons.not']}
        buttonName={intl.messages['buttons.yes']}
        confirmOnClick={cancelColorCombination}
        status="info"
      />

      <FormContainer title={intl.messages['textHeader.editColorCombination']}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <BoxAlert
                text="Labore tempor pariatur consectetur elit amet Lorem in velit fugiat fugiat quis eu consectetur eu."
                title="Atenção"
              />
              <Grid item md={12}>
                <InputText
                  placeholder={intl.messages['labelsInputs.combinationName']}
                  label={intl.messages['labelsInputs.combinationName']}
                  value={name}
                  handleChange={setName}
                  error={
                    statusForm.type === 'error'
                      ? 'Campo Nome da Combinação deve ser preenchido'
                      : ''
                  }
                  isError={name === '' && statusForm.type}
                />
              </Grid>
              <Grid item md={12}>
                <InputText
                  placeholder={intl.messages['labelsInputs.typeAuthor']}
                  label={intl.messages['labelsInputs.typeAuthor']}
                  value={user.name}
                  disabled
                />
              </Grid>

              <Grid item md={12}>
                <AsyncSelectPaginateRgb
                  label={intl.messages['labelsInputs.firstColor']}
                  placeholder={intl.messages['labelsInputs.select']}
                  value={color1}
                  onChange={setColor1}
                  url={COLORS}
                  perPage={50}
                  rgbPreview
                  error={
                    statusForm.type === 'error'
                      ? 'Campo Primeira Cor deve ser preenchido'
                      : ''
                  }
                  isError={!color1.id && statusForm.type}
                />
              </Grid>
              <Grid item md={12}>
                <AsyncSelectPaginateRgb
                  label={intl.messages['labelsInputs.secondColor']}
                  placeholder={intl.messages['labelsInputs.select']}
                  value={color2}
                  onChange={setColor2}
                  url={COLORS}
                  perPage={50}
                  rgbPreview
                  error={
                    statusForm.type === 'error'
                      ? 'Campo Segunda Cor deve ser preenchido'
                      : ''
                  }
                  isError={!color2.id && statusForm.type}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <AsyncSelectPaginateRgb
                  label={intl.messages['labelsInputs.thirdColor']}
                  placeholder={intl.messages['labelsInputs.select']}
                  value={color3}
                  onChange={setColor3}
                  url={COLORS}
                  perPage={50}
                  rgbPreview
                  optional
                />
              </Grid>
              <Grid item md={12}>
                <AsyncSelectPaginateRgb
                  label={intl.messages['labelsInputs.fourthColor']}
                  placeholder={intl.messages['labelsInputs.select']}
                  value={color4}
                  onChange={setColor4}
                  url={COLORS}
                  perPage={50}
                  rgbPreview
                  optional
                />
              </Grid>
              <Grid item md={12}>
                <Toggle
                  label={intl.messages['labelsInputs.publicationStatus']}
                  checked={publicationStatus}
                  onChange={(e) => setPublicationStatus(e.target.checked)}
                  regular
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ActionButtons
          type="edit"
          cancelPath={() => setShowModalConfirmationCancel(true)}
          submitFunc={() => setShowModalConfirmationSave(true)}
        />
      </FormContainer>
    </>
  );
};

export default ColorCombinationsEdit;
