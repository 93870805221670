import React from 'react';
import PropTypes from 'prop-types';
import logo from 'assets/img/logo-suvinil-glasu.svg';
import Loader from 'components/structure/Loader/Loader';
import backgroundImg from 'assets/img/mantra.webp';
import {
  Container,
  Content,
  ContainerImage,
} from './BackgroundImageContainer.styles';

const BackgroundImageContainer = ({
  children,
  title,
  text,
  customImg,
  loading,
}) => (
  <Container>
    <ContainerImage>
      <img src={backgroundImg} alt="" />
    </ContainerImage>
    <Content>
      <Loader loading={loading} />
      {customImg || <img src={logo} alt="" />}
      {title && <h4>{title}</h4>}
      {text && <p>{text}</p>}
      {children}
    </Content>
  </Container>
);

BackgroundImageContainer.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node,
  text: PropTypes.node,
  customImg: PropTypes.node,
  loading: PropTypes.number,
};

BackgroundImageContainer.defaultProps = {
  title: null,
  text: null,
  customImg: null,
  loading: 0,
};

export default BackgroundImageContainer;
