import React from 'react';
import PropTypes from 'prop-types';
import InputText from 'components/form/Text/InputText/InputText';
import { Container } from './LABColorInput.styles';

const LABColorInput = ({
  labColor1,
  labColor2,
  labColor3,
  setLabColor1,
  setLabColor2,
  setLabColor3,
  colorLabString,
  disabled,
}) => {
  return (
    <Container colorLabString={colorLabString}>
      <InputText
        placeholder="000"
        value={labColor1}
        onChange={(e) => setLabColor1(e.target.value)}
        style={{ marginBottom: '16px' }}
        optional
        disabled={disabled}
      >
        LAB
      </InputText>
      <InputText
        placeholder="000"
        value={labColor2}
        onChange={(e) => setLabColor2(e.target.value)}
        disabled={disabled}
      />
      <InputText
        placeholder="000"
        value={labColor3}
        onChange={(e) => setLabColor3(e.target.value)}
        disabled={disabled}
      />
    </Container>
  );
};

LABColorInput.propTypes = {
  labColor1: PropTypes.string,
  labColor2: PropTypes.string,
  labColor3: PropTypes.string,
  setLabColor1: PropTypes.func,
  setLabColor2: PropTypes.func,
  setLabColor3: PropTypes.func,
  colorLabString: PropTypes.string,
  disabled: PropTypes.bool,
};

LABColorInput.defaultProps = {
  labColor1: '',
  labColor2: '',
  labColor3: '',
  setLabColor1: () => {},
  setLabColor2: () => {},
  setLabColor3: () => {},
  colorLabString: '',
  disabled: false,
};

export default LABColorInput;
