import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 15px 20px 10px;
    overflow-y: scroll;
    height: 82vh;

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: ${theme.colors.primary.hover};
    }

    .backbutton {
      margin-top: -61px;
      z-index: 9;
      padding: 20px;
    }

    @media only screen and (max-width: 1280px) {
      .backbutton {
        margin-top: -63px;
      }
    }
  `}
`;
