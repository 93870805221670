import axios from 'axios';
import { useEffect, useState } from 'react';
import api from 'services/api';
import { changeFileExtension } from 'utils/changeFileExtension';
import {
  convertBlobToWebP,
  convertImageToBlob,
  getBlobExtension,
} from 'utils/convertImageToBlob';
import { createSlug } from 'utils/createSlug';
import { errorToast, successToast } from 'utils/toasts';
import { validateImageFile } from 'utils/validateImageFile';
import { useDispatch } from 'react-redux';
import { startLoading, stopLoading } from 'store/loading/loading-slice';

export function useSkuImageList(product, sku) {
  const [skuImages, setSkuImages] = useState([]);
  const [imagesToDeleteFromBucket, setImagesToDeleteFromBucket] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setImagesToDeleteFromBucket([]);

    if (sku?.images?.display) {
      const filterWebpImages = sku?.images?.display.filter(
        (imgUrl) => !imgUrl.includes('.webp'),
      );

      setSkuImages(filterWebpImages);
    }
  }, [sku]);

  const addSkuImage = (event) => {
    const file = event.target.files[0];

    if (skuImages.length >= 5) {
      throw errorToast('O limite máximo de imagens foi atingido (4)');
    }

    const imageValidation = validateImageFile(file);

    if (typeof imageValidation === 'string') {
      throw errorToast(`${imageValidation}`);
    }

    const newSkuImages = [...skuImages, event.target.files[0]];
    setSkuImages(newSkuImages);
    // eslint-disable-next-line no-param-reassign
    event.target.value = null;
  };

  const deleteImagesFromBucket = async () => {
    const deletePromises = imagesToDeleteFromBucket.map(async (name) => {
      const imageUrl = name.split('/');
      const imageFileName = imageUrl[imageUrl.length - 1];

      try {
        const res = await api.delete(`uploads/${product?.id}`, {
          data: {
            service: 'catalog',
            entity: 'products',
            type: 'image',
            context: 'display',
            name: imageFileName,
          },
        });

        if (res.status === 204) {
          const imageExt = imageFileName.split('.').pop();

          if (imageExt.toLowerCase() !== 'webp') {
            const webpFileName = changeFileExtension(imageFileName, '.webp');
            await api.delete(`uploads/${product?.id}`, {
              data: {
                service: 'catalog',
                entity: 'products',
                type: 'image',
                context: 'display',
                name: webpFileName,
              },
            });
          }

          return { success: true, message: 'Imagem deletada com sucesso!' };
        }
        return { success: false, message: 'Erro ao deletar imagem!' };
      } catch (error) {
        return { success: false, message: 'Erro ao deletar imagem!' };
      }
    });

    const results = await Promise.all(deletePromises);

    // Contar o número de operações bem-sucedidas
    const successfulDeletes = results.filter((result) => result.success).length;

    if (successfulDeletes === imagesToDeleteFromBucket.length) {
      successToast('Todas as imagens foram deletadas com sucesso!');
    } else {
      errorToast(
        'Algumas imagens não puderam ser deletadas. Por favor, tente novamente.',
      );
    }
  };

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsArrayBuffer(file);
    });
  }

  async function fetchData(url, dataFile, fileType) {
    try {
      const file = await readFile(dataFile);

      await axios.put(url, file, {
        headers: {
          'Content-Type': fileType.replace('jpeg', 'jpg'),
          'Access-Control-Allow-Origin': '*',
        },
      });
    } catch (error) {
      console.log(error);
      throw errorToast(
        `Houve um erro ao salvar as imagens. Tente novamente mais tarde.`,
      );
    }
  }

  const createPreSigned = async ({ file, index }) => {
    const formData = new FormData();

    const fileExt = getBlobExtension(file)?.replace('jpeg', 'jpg');

    if (!sku?.dryPackage) {
      throw errorToast(
        `Erro - Selecione o campo Embalagem antes de cadastrar imagens!`,
      );
    }

    const sizeString = `${sku?.dryPackage?.slug}${String(
      sku?.dryPackage?.unitAlias,
    ).toLowerCase()}`;

    formData.append(
      'name',
      `${createSlug(
        product.name,
      )}-${sizeString}-${index.toString()}.${fileExt}`,
    );
    formData.append('service', 'catalog');
    formData.append('entity', 'products');
    formData.append('id', product.id);
    formData.append('type', 'image');
    formData.append('context', 'display');

    const presignedUrl = await api.post('presigned', formData);
    await fetchData(presignedUrl.data.url, file, file.type);

    if (fileExt.toLowerCase() !== 'webp') {
      const webpVersion = await convertBlobToWebP(file);
      await createPreSigned({ file: webpVersion, index });
    }
  };

  const saveSkuImages = async (onSuccessCallback = () => {}) => {
    try {
      dispatch(startLoading());

      /* Deletar imagens do Bucket que foram manualmente deletadas via UI */
      if (imagesToDeleteFromBucket.length > 0) {
        await deleteImagesFromBucket();
      }

      const validImages = skuImages.filter((image, index) => {
        if (typeof image === 'string') {
          return true;
        }

        const imageValidation = validateImageFile(image);
        if (typeof imageValidation !== 'string') return true;

        throw errorToast(`Imagem ${index + 1} - ${imageValidation}`);
      });

      const blobImages = await Promise.all(
        validImages.map(async (img) => {
          if (typeof img === 'string') {
            const blob = await convertImageToBlob(img);
            return blob;
          }
          return img;
        }),
      );

      await Promise.all(
        blobImages.map((image, index) =>
          createPreSigned({ file: image, index: index + 1 }),
        ),
      );

      successToast('Sucesso ao salvar imagens!');
      onSuccessCallback();
    } catch (error) {
      console.error('Erro ao salvar imagens:', error);
    } finally {
      dispatch(stopLoading());
    }
  };

  return {
    skuImages,
    saveSkuImages,
    imagesToDeleteFromBucket,
    setImagesToDeleteFromBucket,
    setSkuImages,
    addSkuImage,
  };
}
