/**
 * Função que converte uma URL de imagem para um objeto Blob.
 *
 * @param {string} url A URL da imagem.
 * @returns {Promise<Blob>} Uma promessa que resolve para um objeto Blob contendo a imagem.
 *
 * @example
 * const blob = await convertImageToBlob('https://example.com/image.jpg');
 * console.log(blob); // Blob object representing the image
 */
export async function convertImageToBlob(url) {
  const response = await fetch(url, {
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  });
  const blob = await response.blob();
  return blob;
}

export function getBlobExtension(blob) {
  const { type } = blob;
  const match = type.match(/\/([a-zA-Z0-9]+)$/);
  if (match) {
    return match[1];
  }
  return null;
}

/**
 * Converte uma imagem em formato BLOB para WebP.
 * @param {Blob} imageBlob - A imagem em formato BLOB.
 * @param {number} quality - A qualidade da imagem WebP (de 0 a 1).
 * @returns {Promise<Blob>} - Uma Promise que resolve com o BLOB da imagem WebP.
 */
export async function convertBlobToWebP(imageBlob, quality = 0.8) {
  const imageUrl = URL.createObjectURL(imageBlob);

  const image = new Image();
  image.src = imageUrl;

  await new Promise((resolve, reject) => {
    image.onload = resolve;
    image.onerror = reject;
  });

  const canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;

  const ctx = canvas.getContext('2d');
  ctx.drawImage(image, 0, 0);

  const webpDataUrl = canvas.toDataURL('image/webp', quality);

  const webpBlob = await (await fetch(webpDataUrl)).blob();

  URL.revokeObjectURL(imageUrl);

  return webpBlob;
}
