import React from 'react';
import { BsPlus } from 'react-icons/bs';
import PropTypes from 'prop-types';
import { Button, ButtonContent } from './MorePermissionButton.style';

export const MorePermissionsButton = ({ visible, actionFunction }) => {
  return (
    <>
      {visible && (
        <Button type="button" onClick={() => actionFunction()}>
          <ButtonContent>
            <BsPlus size={18} />
            <span>Mais Permissões</span>
          </ButtonContent>
        </Button>
      )}
    </>
  );
};

MorePermissionsButton.propTypes = {
  visible: PropTypes.bool,
  actionFunction: PropTypes.func,
};

MorePermissionsButton.defaultProps = {
  visible: false,
  actionFunction: () => {},
};
