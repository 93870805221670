import styled, { css } from 'styled-components';
import FormContainerSecondary from 'components/structure/FormContainerSecondary/FormContainerSecondary';

export const FormContainer = styled(FormContainerSecondary)`
  margin-top: 100px;
`;

export const ContainerBox = styled.div`
  ${({ theme }) => css`
    height: 100vh;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: ${theme.colors.primary.hover};
    }
  `}

  input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const InputContainerGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`;

export const InputSubContainerGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;

  width: 100%;
`;
