import React, { useState, useEffect } from "react";
import Modal from "../Modal/Modal";
import * as S from "./ReportModalStyled"
import propTypes from 'prop-types';
import {
  AddButton,
} from '../PageHeader/PageHeader.styles';
import { Link } from "@material-ui/core";
import api from "services/api";
import { successToast } from "utils/toasts";

import ReactLoading from 'react-loading';

const ReportModal = (props) => {
  const { setIsOpen, entity, id } = props
  const [urlFile, setUrlFile] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const resetStates = () => {
    setTimeout(() => {
      setStartDate(null);
      setEndDate(null);
      setUrlFile(null);
      setIsOpen(false)
    }, 1e3);
  }

  const downloadFile = async () => {

    setIsLoading(true)

    const contract = {
      entity,
      startDate,
      endDate,
      "dateField": "createdAt",
      "greaterThan": false,
      "vtexInfo": true
    }

    if (contract.entity === "sku") {
      contract.entity = "stockKeepingUnits"
      contract.id = id;
    };

    await api.post("/reports", contract).then(response => {
      setUrlFile(response.data.url)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    if (startDate && endDate) {
      downloadFile()
    }
  }, [startDate, endDate])

  return (
    <Modal zIndex="1400" {...props} height="335px" width="485px" titleSize="20px" titleRight>
      <S.ReportModalStyledContent>

        <div>
          <S.ReportModalStyledLabel>Data Inicial</S.ReportModalStyledLabel>
          <S.ReportModalStyledInput type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        </div>

        <div>
          <S.ReportModalStyledLabel>Data Final</S.ReportModalStyledLabel>
          <S.ReportModalStyledInput type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        </div>

        <S.ReportModalStyledButtonContainer>
          <AddButton
            btnType="light"
            borderRadius="4px"
            width="132px"
            height="44px"
            onClick={() => resetStates()}
          >
            CANCELAR
          </AddButton>

          <Link href={urlFile} target="_blank" download>
            <AddButton
              disabled={!urlFile}
              btnType="primary"
              borderRadius="4px"
              width="132px"
              height="44px"
              onClick={() => {
                successToast("Download realizado com sucesso!")
                resetStates()
              }}
            >
              {isLoading ?
                <ReactLoading
                  type="spin"
                  color="#fff"
                  height={30}
                  width={30}
                  className="loader-spin"
                />
                : "BAIXAR"}
            </AddButton>
          </Link>

        </S.ReportModalStyledButtonContainer>
      </S.ReportModalStyledContent>
    </Modal>
  );
};

ReportModal.propTypes = {
  setIsOpen: propTypes.func.isRequired,
  isOpen: propTypes.bool.isRequired,
  title: propTypes.string.isRequired,
  entity: propTypes.string.isRequired,
  id: propTypes.string,
  hasVtexOption: propTypes.bool,
};

ReportModal.defaultProps = {
  id: null,
  hasVtexOption: false,
}

export default ReportModal;