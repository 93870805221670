import React, { useContext } from 'react';
import PropTypes from 'prop-types';

const { useState, createContext } = require('react');

const SkuContext = createContext();

const SkuProvider = ({ children }) => {
  const [stepData, setStepData] = useState([
    {
      base: '',
      colors: [],
      colorGroups: '',
      size: '',
      width: '',
      height: '',
      length: '',
      ean: '',
      sap: '',
      platforms: [],
      liquidWeight: '',
      grossWeight: '',
      yield: '',
      finishYield: '',
      default: false,
    },
  ]);

  const combineArrays = (arr1, arr2, platform) => {
    let res = [];
    for (let i = 0; i < arr1.length; i += 1) {
      for (let j = 0; j < arr2.length; j += 1) {
        res.push({
          ...arr1[i],
          ...arr2[j],
        });
      }
    }
    res = res.map((e) => {
      e.platforms = platform;
      return e;
    });

    setStepData(res);
  };

  return (
    <SkuContext.Provider value={{ stepData, setStepData, combineArrays }}>
      {children}
    </SkuContext.Provider>
  );
};

const useSkuStepContext = () => useContext(SkuContext);

SkuProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { SkuProvider, useSkuStepContext };
