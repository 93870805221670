import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FormContainerSecondary from 'components/structure/FormContainerSecondary/FormContainerSecondary';
import { getPermission } from 'utils/permissions';
import { useHistory } from 'react-router-dom';
import { StepContainerBox } from './Skus.styles';
import FirstStep from './SkuStep/Edit/SkuEdit';

export default function SkuCreateStep() {
  const { permissions } = useSelector((state) => state.auth);
  const history = useHistory();

  useEffect(() => {
    if (getPermission('products', permissions, 'update') === false) {
      history.push('/');
    }
  }, [history, permissions]);

  return (
    <StepContainerBox>
      <FormContainerSecondary
        style={{
          marginTop: '100px',
        }}
        title="Editar SKU"
      >
        <FirstStep />
      </FormContainerSecondary>
    </StepContainerBox>
  );
}
