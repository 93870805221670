/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  modalFileInput: {
    status: false,
    imgUrl: '',
  },
  modalResetPassword: {
    openModal: false,
  },
  modalStatus: {
    status: '',
    msg: {
      title: '',
      text: '',
    },
    openStatusModal: false,
  },
};

const modalsSlice = createSlice({
  name: 'modal',
  initialState: INITIAL_STATE,
  reducers: {
    setModalFileInput: (state, { payload }) => {
      state.modalFileInput = payload;
    },
    resetModalFileInput: (state) => {
      state.modalFileInput = {
        status: false,
        imgUrl: '',
      };
    },
    setModalResetPassword: (state, { payload }) => {
      state.modalResetPassword = payload;
    },
    setModalStatus: (state, { payload }) => {
      state.modalStatus = { ...state.modalStatus, status: payload };
    },
    statusModalStatus: (state, { payload }) => {
      state.modalStatus = { ...state.modalStatus, openStatusModal: payload };
    },
    setModalStatusMsg: (state, { payload }) => {
      state.modalStatus = { ...state.modalStatus, msg: payload };
    },
  },
});

export const modals = modalsSlice.reducer;
export const {
  resetModalFileInput,
  setModalFileInput,
  setModalResetPassword,
  setModalStatus,
  setModalStatusMsg,
  statusModalStatus,
} = modalsSlice.actions;
