import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Pagination from 'components/structure/Pagination/Pagination';
import SelectTable from 'components/form/Select/SelectTable/SelectTable';
import { Container } from './FooterTable.styles';

const FooterTable = ({
  totalPages,
  currentPage,
  onPageChange,
  onChangePageSize,
}) => {
  const [options, setOptions] = useState([
    {
      selected: true,
      value: 10,
      label: '10',
    },
    {
      value: 25,
      label: '25',
    },
    {
      value: 50,
      label: '50',
    },
    {
      value: 100,
      label: '100',
    },
  ]);

  const updateOptions = (option) => {
    setOptions((oldOptions) =>
      oldOptions.map(({ value, label }) =>
        option.value === value
          ? {
              selected: true,
              value,
              label,
            }
          : { value, label },
      ),
    );
  };
  return (
    <Container>
      {totalPages && (
        <Pagination
          type="numbered"
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={onPageChange}
          style={{ alignSelf: 'center' }}
        />
      )}

      <SelectTable
        mx={2}
        height="32px"
        onChange={(size) => {
          updateOptions(size);
          onChangePageSize(size);
        }}
        options={options}
        isFooter
      />
    </Container>
  );
};

FooterTable.propTypes = {
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func,
};

FooterTable.defaultProps = {
  onChangePageSize: () => {},
};
export default FooterTable;
