import Button from 'components/structure/Button/Button';
import PageContent from 'components/structure/PageContent/PageContent';
import PageHeader from 'components/structure/PageHeader/PageHeader';
import {
  Divider,
  PopItem,
  PopOverContent,
} from 'components/structure/PopOver/PopOver.styles';
import TableDefault from 'components/structure/Table/Table';
import urls from 'constants/urls';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getPermission } from 'utils/permissions';
import EmptySearch from '../../components/structure/EmptySearch/EmptySearch';
import Popover from '../../components/structure/PopOver/PopOver';

import arrayToString from '../../utils/arrayToString';
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter';
import {
  getDeletedEditorialDetail,
  getDeletedEditorialsList,
  setEditorialId,
  updateEditorial,
} from 'store/editorials/editorials-slice';

const DeletedEditorials = () => {
  const { totalPage, items } = useSelector(
    (state) => state.editorials.editorials,
  );
  const { permissions } = useSelector((state) => state.auth);

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [ordenation, setOrdenation] = useState('');
  const [totalPerPage, setTotalPerPage] = useState({ value: 10 });
  const perPage = totalPerPage.value;

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      getDeletedEditorialsList({ page, searchTerm, perPage, ordenation }),
    );
  }, [dispatch, page, searchTerm, perPage, ordenation]);

  useEffect(() => {
    if (getPermission('editorials', permissions, 'delete') === false) {
      history.push('/');
    }
  }, [history, permissions]);

  const optionsOrdenation = [
    { value: 'id', label: 'ID' },
    { value: 'name', label: 'Título' },
    { value: 'slug', label: 'Slug' },
    { value: 'platform', label: 'Plataforma' },
    { value: 'createdAt', label: 'Data e hora' },
  ];

  const viewDeletedEditorial = useCallback(
    (id) => {
      dispatch(setEditorialId(id));
      dispatch(getDeletedEditorialDetail(parseInt(id, 10)));
      history.push(urls.ROUTES.EDITORIALS_VIEW.path);
    },
    [dispatch, history],
  );

  const restoreEditorial = useCallback(
    (id) => {
      const formData = new FormData();
      formData.append('restore', true);
      dispatch(updateEditorial({ id, info: formData }));
    },
    [dispatch],
  );

  const COLUMNS = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Slug',
        accessor: 'slug',
      },
      {
        Header: 'Plataforma',
        accessor: ({ platforms }) =>
          arrayToString(platforms) === 'institucional'
            ? arrayToString(platforms).replace(platforms, 'Suvinil')
            : capitalizeFirstLetter(arrayToString(platforms)),
      },
      {
        Header: 'Removido em',
        accessor: ({ deletedAt }) => new Date(deletedAt).toLocaleString(),
      },
      {
        Header: ' ',
        accessor: ({ id }) => (
          <Popover
            content={
              <PopOverContent>
                <PopItem onClick={() => viewDeletedEditorial(id)}>
                  Ver detalhes
                </PopItem>
                <Divider />
                <PopItem onClick={() => restoreEditorial(id)}>
                  Restaurar
                </PopItem>
              </PopOverContent>
            }
          />
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  const data = useMemo(() => items, [items]);

  return (
    <div>
      <PageHeader
        title="Editorias removidas"
        onClickFilter={() => {}}
        search
        searchFunc={(searchData) => setSearchTerm(searchData)}
        placeholder="Busque por ID"
        optionsOrdenation={optionsOrdenation}
        ordenation={ordenation}
        setOrdenation={setOrdenation}
        trash={false}
      />

      <PageContent>
        <EmptySearch items={data} term={searchTerm}>
          <TableDefault
            columns={COLUMNS}
            data={data}
            pageCount={totalPage}
            defaultCurrentPage={page}
            setPageCurrent={setPage}
            totalPerPage={totalPerPage}
            setTotalPerPage={setTotalPerPage}
          />
        </EmptySearch>
        <Button
          className="backbutton"
          btnType="blue"
          width="20%"
          fontWeight="600"
          onClick={() => history.push(urls.ROUTES.BLOG.items.editorials.path)}
        >
          VOLTAR
        </Button>
      </PageContent>
    </div>
  );
};
export default DeletedEditorials;
