import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useIntl } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import InputText from 'components/form/Text/InputText/InputText';
import InputPassword from 'components/form/Text/InputPassword/InputPassword';
import Button from 'components/structure/Button/Button';
import { useDispatch, useSelector } from 'react-redux';

import SelectSearch from 'components/form/Select/ReactSelect/hookFormSelect';
import { defaultValues, schema } from './AddUserModalForm.validations';
import { Container, ButtonContent } from './AddUserModalForm.styles';
import { getRolesListAll } from 'store/roles/roles-slice';
import { saveUser } from 'store/users/users-slice';

const AddUserModalForm = ({ emailErrorMessage, searchTerm, currentPage }) => {
  const { register, handleSubmit, errors, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
    shouldFocusError: true,
  });
  const intl = useIntl();
  const dispatch = useDispatch();

  const { options } = useSelector((state) => state.roles);
  const { role } = useSelector((state) => state.auth);

  const handleSubmitModalForm = (data) => {
    const userData = { ...data, roleId: data.role };
    dispatch(saveUser({ userData, searchTerm, currentPage }));
  };

  useEffect(() => {
    dispatch(getRolesListAll());
  }, [dispatch]);

  function validate(obj) {
    if (obj === null || obj === undefined) {
      return false;
    }
    return true;
  }

  return (
    <Container>
      <form onSubmit={handleSubmit(handleSubmitModalForm)}>
        <Row>
          <Col md="12">
            <InputText
              type="text"
              id="name"
              name="name"
              error={errors.name}
              isError={validate(errors.name)}
              register={register}
            >
              {intl.messages['labelsInputs.typeYourName']}
            </InputText>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <InputText
              type="email"
              id="email"
              name="email"
              error={errors.email || emailErrorMessage}
              isError={validate(errors.email) || emailErrorMessage}
              register={register}
            >
              {intl.messages['labelsInputs.typeYourEmail']}
            </InputText>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <InputPassword
              id="password"
              name="password"
              error={errors}
              isError={validate(errors.password)}
              register={register}
              hasTooltip
              textTooltip={intl.messages['labelsInputs.tooltipUserPassword']}
              hookForm
            >
              {intl.messages['labelsInputs.password']}
            </InputPassword>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Controller
              render={({ onChange, value, name }) => (
                <SelectSearch
                  label="Perfil"
                  options={
                    role.name === 'Admin'
                      ? options
                      : options.filter((op) => op.name !== 'Admin')
                  }
                  multi={false}
                  tooltip
                  title={intl.messages['labelsInputs.tooltipUserRole']}
                  isError={validate(errors.role)}
                  error={errors?.role}
                  name={name}
                  defaultOption={value}
                  handleChange={onChange}
                />
              )}
              control={control}
              name="role"
            />
          </Col>
        </Row>
        <Row>
          <ButtonContent>
            <Button
              type="submit"
              btnType="primary"
              width="60%"
              height="44px"
              borderRadius="8px"
              fontWeight="600"
            >
              {intl.messages['buttons.add']}
            </Button>
          </ButtonContent>
        </Row>
      </form>
    </Container>
  );
};

AddUserModalForm.propTypes = {
  emailErrorMessage: PropTypes.string,
  currentPage: PropTypes.number.isRequired,
  searchTerm: PropTypes.string.isRequired,
};

AddUserModalForm.defaultProps = {
  emailErrorMessage: '',
};

export default AddUserModalForm;
