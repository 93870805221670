/* eslint-disable no-unused-vars */
import Button from 'components/structure/Button/Button';
import EmptySearch from 'components/structure/EmptySearch/EmptySearch';
import PageContent from 'components/structure/PageContent/PageContent';
import PageHeader from 'components/structure/PageHeader/PageHeader';
import EanSkusExclusionModal from 'components/structure/EanSkusExclusionModal';
import CheckboxTable from 'components/structure/Table/CheckboxTable';
import urls from 'constants/urls';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import * as S from './styles';
import api from 'services/api';

const Notifications = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [ordenation, setOrdenation] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [items, setItems] = useState([]);
  const [totalPage, setTotalPage] = useState([]);
  const [totalPerPage, setTotalPerPage] = useState({ value: 10 });

  const [isOpenEanSkusExclusionModal, setIsOpenEanSkusExclusionModal] =
    useState(false);

  const [selected, setSelected] = useState([]);

  const intl = useIntl();
  const history = useHistory();

  const getNotifications = async () => {
    const res = await api.get('/notifications');
    setTotalPage(res.data.totalItems);
    setItems(res.data.items);
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const COLUMNS = useMemo(
    () => [
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Progresso',
        // accessor: ({ message }) => JSON.parse(message).percente,
        accessor: () => 'Em desenvolvimento',
      },
      {
        Header: 'Detalhe',
        // accessor: ({ message }) => {
        //   const json = JSON.parse(message);
        //   return json?.message;
        // },
        accessor: () => 'Em desenvolvimento',
      },
      {
        Header: 'Data de Execução',
        // accessor: ({ message }) => {
        //   const json = JSON.parse(message);
        //   return json?.message;
        // },
        accessor: () => 'Em desenvolvimento',
      },
      {
        Header: ' ',
        accessor: ({ status: notificationStatus }) =>
          notificationStatus !== 'processing' ? (
            <Button
              className="eanExclusionButton"
              btnType="link"
              // icon={<TrashIcon />}
              fontWeight="600"
              height="24px"
              // disabled
              // onClick={() => setIsOpenEanSkusExclusionModal(true)}
            >
              EXIBIR
            </Button>
          ) : (
            <div style={{ height: '24px' }} />
          ),
        sticky: 'left',
      },
    ],
    [intl.messages],
  );

  const data = useMemo(() => items, [items]);

  const optionsOrdenation = [
    { value: 'createdAt', label: 'Mais antigas' },
    { value: 'updatedAt', label: 'Mais antigas' },
  ];

  return (
    <>
      <EanSkusExclusionModal
        isOpen={isOpenEanSkusExclusionModal}
        setIsOpen={setIsOpenEanSkusExclusionModal}
        onSuccessCallback={() => {
          history.push(urls.ROUTES.CATALOGUE.items.products.path);
        }}
      />
      <PageHeader
        title={`Notificações`}
        search
        searchFunc={(searchData) => setSearchTerm(searchData)}
        placeholder="Busque por notificações"
        optionsOrdenation={optionsOrdenation}
        ordenation={ordenation}
        setOrdenation={setOrdenation}
      />

      <PageContent>
        <EmptySearch items={data} term={searchTerm}>
          <CheckboxTable
            columns={COLUMNS}
            data={data}
            pageCount={totalPage}
            setPageCurrent={setCurrentPage}
            totalPerPage={totalPerPage}
            setTotalPerPage={setTotalPerPage}
            setSelected={setSelected}
            noCheckBox
          />
        </EmptySearch>

        <S.FooterButtons>
          <Button
            className="backbutton"
            btnType="blue"
            width="20%"
            fontWeight="600"
            onClick={() =>
              history.push(urls.ROUTES.CATALOGUE.items.products.path)
            }
          >
            {intl.messages['buttons.toBack']}
          </Button>
        </S.FooterButtons>
      </PageContent>
    </>
  );
};

export default Notifications;
