import errorIcon from 'assets/icons/ic-x-error.svg';
import Tooltip from 'assets/icons/tooltip-icon.svg';
import SpanError from 'components/structure/SpanError/SpanError';
import ReactLoading from 'react-loading';
import TooltipComponent from 'components/structure/Tooltip/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Container,
  CountInfo,
  InputContainer,
  InputStyle,
  Label,
  LabelContainer,
  OptionalStyle,
  SpinContainer,
} from './InputText.styles';

const InputText = ({
  type,
  id,
  name,
  label,
  isError,
  error,
  children,
  isSearch,
  disabled,
  placeholder,
  secondary,
  value,
  handleChange,
  height,
  hookForm,
  register,
  optional,
  hasTooltip,
  textTooltip,
  isCount,
  maxLength,
  padding,
  step,
  isLoading,
  hideLabel,
  ...rest
}) => {
  const handleChangeNumber = (evt) => {
    if (evt.target.maxLength > 0) {
      const data = evt.target.value.slice(0, evt.target.maxLength);
      handleChange(data);
    } else {
      handleChange(evt.target.value);
    }
  };

  return (
    <Container padding={padding}>
      {!hideLabel && (
        <LabelContainer>
          {!isSearch && (
            <Label htmlFor={name} isError={isError} secondary={secondary}>
              {label}
            </Label>
          )}
          {optional && <OptionalStyle>(Opcional)</OptionalStyle>}
          {hasTooltip && (
            <TooltipComponent
              id="tooltipInputText"
              title={textTooltip}
              multiline
            >
              <img src={Tooltip} alt="" />
            </TooltipComponent>
          )}
        </LabelContainer>
      )}
      <InputContainer isError={isError} height={height} disabled={disabled}>
        {hookForm ? (
          <InputStyle
            type={type}
            id={id}
            name={name}
            isError={isError}
            isSearch={isSearch}
            placeholder={placeholder}
            disabled={disabled}
            secondary={secondary}
            ref={register}
            autoComplete={false}
            step={step}
            {...rest}
          />
        ) : (
          <InputStyle
            type={type}
            id={id}
            name={name}
            isError={isError}
            isSearch={isSearch}
            placeholder={placeholder}
            disabled={disabled}
            secondary={secondary}
            value={value}
            onChange={
              type === 'number'
                ? (e) => handleChangeNumber(e)
                : (e) => handleChange(e.target.value)
            }
            ref={register}
            maxLength={maxLength}
            max={maxLength}
            step={step}
            {...rest}
          />
        )}
        {isError && <img src={errorIcon} alt="" className="error-icon" />}
        {isLoading && (
          <SpinContainer>
            <ReactLoading
              type="spin"
              color="#F66602"
              height={24}
              width={24}
              className="loader-spin"
            />
          </SpinContainer>
        )}
      </InputContainer>
      {isError && hookForm && <SpanError>{error.message ?? ''}</SpanError>}
      {isError && !hookForm && <SpanError>{error}</SpanError>}
      {isCount && (
        <CountInfo>
          {value.length}/{maxLength}
        </CountInfo>
      )}
    </Container>
  );
};

InputText.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
  isError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  error: PropTypes.object,
  disabled: PropTypes.bool,
  isSearch: PropTypes.bool,
  placeholder: PropTypes.string,
  secondary: PropTypes.bool,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  height: PropTypes.string,
  register: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  hookForm: PropTypes.bool,
  optional: PropTypes.bool,
  hasTooltip: PropTypes.bool,
  textTooltip: PropTypes.any,
  maxLength: PropTypes.number,
  isCount: PropTypes.bool,
  padding: PropTypes.string,
  step: PropTypes.string,
  isLoading: PropTypes.bool,
  hideLabel: PropTypes.bool,
};

InputText.defaultProps = {
  type: '',
  id: '',
  name: '',
  label: '',
  children: null,
  error: { message: '' },
  isError: false,
  disabled: false,
  isSearch: false,
  placeholder: '',
  secondary: false,
  value: '',
  handleChange: () => {},
  height: '',
  register: undefined,
  hookForm: false,
  optional: false,
  hasTooltip: false,
  textTooltip: null,
  maxLength: null,
  isCount: false,
  padding: '',
  step: '',
  isLoading: false,
  hideLabel: false,
};

export default InputText;
