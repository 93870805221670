import Grid from '@material-ui/core/Grid';
import SelectSearch from 'components/form/Select/ReactSelect';
import InputText from 'components/form/Text/TextInput/InputText';
import Button from 'components/structure/Button/Button';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  ButtonsContainer,
  Container,
} from '../AddTagModalForm/AddTagModalForm.styles';

const ViewTagModalForm = ({
  cancelActionModal,
  editActionModal,
  isEditable,
}) => {
  const { tag } = useSelector((state) => state.tags);

  const [platforms, setPlatforms] = useState(tag.platforms);

  const intl = useIntl();

  const options = [
    { value: 'institucional', label: 'Suvinil' },
    { value: 'glasu', label: 'Glasu' },
  ];

  useEffect(() => {
    setPlatforms(tag.platforms);
  }, [tag]);

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Grid container>
            <Grid item xs={12}>
              <InputText
                name="name"
                label="Nome"
                defaultValue={tag.name}
                disabled
                hookForm
              >
                {intl.messages['labelsInputs.typeYourName']}
              </InputText>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: '21px' }}>
              <SelectSearch
                label={intl.messages['labelsInputs.typePlatform']}
                options={options}
                defaultOption={platforms}
                handleChange={setPlatforms}
                multi={false}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ButtonsContainer>
        <Grid container spacing={2}>
          <Grid item md={isEditable ? 6 : 12} xs={12}>
            <Button
              type="button"
              btnType="secondary"
              width="100%"
              height="44px"
              borderRadius="8px"
              uppercase
              onClick={cancelActionModal}
              fontWeight="600"
            >
              {intl.messages['buttons.cancel']}
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            {isEditable && (
              <Button
                type="submit"
                btnType="primary"
                width="100%"
                height="44px"
                borderRadius="8px"
                uppercase
                fontWeight="600"
                onClick={editActionModal}
              >
                {intl.messages['buttons.edit']}
              </Button>
            )}
          </Grid>
        </Grid>
      </ButtonsContainer>
    </Container>
  );
};

ViewTagModalForm.propTypes = {
  cancelActionModal: PropTypes.func,
  editActionModal: PropTypes.func,
  isEditable: PropTypes.bool,
};

ViewTagModalForm.defaultProps = {
  cancelActionModal: () => {},
  editActionModal: () => {},
  isEditable: true,
};

export default ViewTagModalForm;
