import { configureStore } from '@reduxjs/toolkit';
import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from 'react-redux';
import { articles } from './articles/articles-slice';
import { loading } from './loading/loading-slice';
import { auth } from './auth/auth-slice';
import { bases } from './bases/bases-slice';
import { categories } from './categories/categories-slice';

import { editorials } from './editorials/editorials-slice';
import { tags } from './tags/tags-slice';
import { users } from './users/users-slice';
import { roles } from './roles/roles-slice';
import { colours } from './colours/colours-slice';
import { sizes } from './sizes/sizes-slice';
import { colorGroups } from './colorGroups/colorGroups-slice';
import { products } from './products/products-slice';
import { sidebar } from './sidebar/sidebar-slice';
import { properties } from './properties/properties-slice';
import { modals } from './modals/modals-slice';
import { sku } from './sku/sku-slice';
import { prices } from './prices/prices-slice';
import { morePermissions } from './morePermissions/morePermissions-slice';
import { logs } from './logs/logs-slice';
import { dryPackage } from './dryPackages/dryPackages-slice';
import { colorCombinations } from './colorCombinations/colorCombinations-slice';

export const store = configureStore({
  reducer: {
    articles,
    editorials,
    loading,
    tags,
    users,
    auth,
    roles,
    bases,
    colours,
    sizes,
    colorGroups,
    categories,
    products,
    sidebar,
    properties,
    modals,
    sku,
    prices,
    morePermissions,
    logs,
    colorCombinations,
    dryPackage,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

// export type AppState = ReturnType<typeof store.getState>
// export type AppDispatch = typeof store.dispatch
// export type RootState = ReturnType<typeof store.getState>
// export type AppThunk<ReturnType = void> = ThunkAction<
//   ReturnType,
//   RootState,
//   unknown,
//   Action<string>
// >
export const useSelector = useReduxSelector;

export const useDispatch = () => useReduxDispatch();
