/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import Grid from '@material-ui/core/Grid';
import ActionButtons from 'components/form/ActionButtons';
import InputText from 'components/form/Text/TextInput/InputText';
import TextAreaDescription from 'components/form/TextArea/TextAreaDescription/TextAreaDescription';
import Toggle from 'components/form/Toggle/Toggle';
import FormContainer from 'components/structure/FormContainer/FormContainer';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import SelectSearch from 'components/form/Select/ReactSelect';
import urls from 'constants/urls';
import arrayToString from 'utils/arrayToString';
import { createSlug } from 'utils/createSlug';
import { getPermission } from 'utils/permissions';
import { dataPlatforms, dataTypes } from './dataOptionsColorGroups';

const ColorGroupsView = (props) => {
  const { colorGroup, selectId } = useSelector((state) => state.colorGroups);
  const { permissions } = useSelector((state) => state.auth);

  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [platforms, setPlatforms] = useState([]);
  const [description, setDescription] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [active, setActive] = useState(false);
  const [color1, setColor1] = useState('');
  const [color2, setColor2] = useState('');
  const [color3, setColor3] = useState('');

  const tempColorRgb = [color1, color2, color3];
  const colorRgb = arrayToString(tempColorRgb);

  const history = useHistory();
  const intl = useIntl();

  const cancelColorGroup = () => {
    history.goBack();
  };

  useEffect(() => {
    if (getPermission('color-groups', permissions, 'access') === false) {
      history.push('/');
    }
  }, [history, permissions]);

  useEffect(() => {
    const separatorColorStringRgb = colorGroup?.rgb?.split(',', 3);

    setName(colorGroup?.name);
    setType(colorGroup?.type);
    setPlatforms(colorGroup?.platforms?.map((value) => value));
    setDescription(colorGroup?.description);
    setShortDescription(colorGroup?.shortDescription);
    setMetaDescription(colorGroup?.metaDescription);
    setIsVisible(colorGroup?.isVisible);
    setActive(colorGroup?.active);
    setColor1(separatorColorStringRgb?.[0]);
    setColor2(separatorColorStringRgb?.[1]);
    setColor3(separatorColorStringRgb?.[2]);
  }, [colorGroup]);

  return (
    <>
      <FormContainer
        title={`${intl.messages['textHeader.colorGroups']} - ${name}`}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item md={12}>
                <InputText
                  placeholder={intl.messages['labelsInputs.typeYourName']}
                  label={intl.messages['labelsInputs.typeYourName']}
                  value={name}
                  handleChange={setName}
                  disabled
                />
              </Grid>
              <Grid item md={12}>
                <InputText
                  placeholder={intl.messages['labelsInputs.slug']}
                  label={intl.messages['labelsInputs.slug']}
                  disabled
                  value={createSlug(name)}
                />
              </Grid>

              <Grid item md={12}>
                <SelectSearch
                  label={intl.messages['labelsInputs.type']}
                  placeholder={intl.messages['labelsInputs.select']}
                  options={dataTypes}
                  defaultOption={type}
                  handleChange={setType}
                  disabled
                />
              </Grid>
              <Grid item md={12}>
                <SelectSearch
                  label={intl.messages['labelsInputs.platforms']}
                  placeholder={intl.messages['labelsInputs.select']}
                  options={dataPlatforms}
                  defaultMultiOptions={platforms}
                  handleChange={setPlatforms}
                  multi
                  disabled
                />
              </Grid>
              <Grid item md={12}>
                <TextAreaDescription
                  label={intl.messages['labelsInputs.description']}
                  maxLength={140}
                  value={description}
                  handleChange={setDescription}
                  heigth="145px"
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <TextAreaDescription
                  label={intl.messages['labelsInputs.shortDescription']}
                  maxLength={140}
                  value={shortDescription}
                  handleChange={setShortDescription}
                  heigth="145px"
                  disabled
                />
              </Grid>
              <Grid item md={12}>
                <TextAreaDescription
                  label={intl.messages['labelsInputs.metaDescription']}
                  maxLength={140}
                  value={metaDescription}
                  handleChange={setMetaDescription}
                  heigth="145px"
                  disabled
                />
              </Grid>

              <Grid item md={12}>
                <Toggle
                  label={
                    intl.messages[
                      'labelsInputs.isVisibleOnTheHomeOfTheDigitalFan'
                    ]
                  }
                  checked={isVisible}
                  onChange={(e) => setIsVisible(e.target.checked)}
                  regular
                  disabled
                />
              </Grid>

              <Grid item md={12}>
                <Toggle
                  label={intl.messages['labelsInputs.active']}
                  checked={active}
                  onChange={(e) => setActive(e.target.checked)}
                  regular
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ActionButtons
          type={props.location.state === 'deleted' ? 'none' : 'view'}
          cancelPath={() => {
            cancelColorGroup();
          }}
          submitFunc={() => {
            history.push(urls.ROUTES.COLOR_GROUPS_EDIT.path);
          }}
        />
      </FormContainer>
    </>
  );
};

export default ColorGroupsView;
