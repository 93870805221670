import styled, { css } from 'styled-components';

export const Container = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: #fff;
  border: 2px dashed transparent;
  border-top: 1px solid #ddd;
  padding: 8px 0;
  pointer-events: none;

  p {
    width: 130px;
  }

  .actions {
    display: flex;
    width: max-content;
    pointer-events: auto;
  }

  .move-icon {
    cursor: move;
  }

  ${(props) =>
    props.isDragging &&
    css`
      border: 2px dashed rgba(0, 0, 0, 0.2);
      cursor: grabbing;

      > * {
        opacity: 0;
      }
    `}
`;
