/* eslint-disable prefer-const */
/* eslint-disable no-useless-return */
/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@material-ui/core/Grid';
import InputPassword from 'components/form/Text/InputPassword/InputPassword';
import Button from 'components/structure/Button/Button';
import Modal from 'components/structure/Modal/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Container } from './ResetPassword.styles';
import { defaultValues, schema } from './resetPassword.validations';
import { resetForgottenPassword } from 'store/users/users-slice';
import { setModalResetPassword } from 'store/modals/modals-slice';

const ResetPasswordModal = ({ isOpen }) => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
    shouldFocusError: true,
  });

  const intl = useIntl();
  const dispatch = useDispatch();

  const handleSubmitModalForm = (data) => {
    const userData = { ...data };
    dispatch(
      resetForgottenPassword({
        resetPasswordData: userData,
      }),
    );
  };

  function validate(obj) {
    if (obj === null || obj === undefined) {
      return false;
    }
    return true;
  }

  const resetPasswordModal = (
    <Container>
      <p>{intl.messages['textModal.newPasswordTextModal']}</p>
      <form onSubmit={handleSubmit(handleSubmitModalForm)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={12}>
                <InputPassword
                  id="password"
                  name="password"
                  register={register}
                  error={errors}
                  isError={validate(errors.password)}
                  hookForm
                >
                  {intl.messages['labelsInputs.newPassword']}
                </InputPassword>
              </Grid>

              <Grid item xs={12}>
                <InputPassword
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  register={register}
                  error={errors}
                  isError={validate(errors.passwordConfirmation)}
                  hookForm
                >
                  {intl.messages['labelsInputs.newPasswordConfirm']}
                </InputPassword>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Button
              type="button"
              btnType="secondary"
              width="100%"
              height="44px"
              borderRadius="8px"
              uppercase
              onClick={() =>
                dispatch(setModalResetPassword({ openModal: false }))
              }
              fontWeight="600"
            >
              {intl.messages['buttons.cancel']}
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            <Button
              type="submit"
              btnType="primary"
              width="100%"
              height="44px"
              borderRadius="8px"
              uppercase
              fontWeight="600"
            >
              {intl.messages['buttons.toSave']}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );

  return (
    <Modal
      title={intl.messages['labelsInputs.newPassword']}
      setIsOpen={() => dispatch(setModalResetPassword({ openModal: false }))}
      isOpen={isOpen}
      content={resetPasswordModal}
      width="35%"
    />
  );
};

ResetPasswordModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default ResetPasswordModal;
