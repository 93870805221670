/* eslint-disable react/prop-types */
import arrowDown from 'assets/icons/chevron-down.svg';
import arrowUp from 'assets/icons/chevron-up.svg';
import trashIcon from 'assets/icons/trashProperty.svg';
import React, { useEffect, useState } from 'react';
import tinycolor from 'tinycolor2';
import rgbToHex from 'utils/rgbToHex';
import Button from '../ActionButton/ActionButton';
import IconButton from '../IconButton/IconButton';
import {
  AccordionItemContainer,
  AccordionItemHeader,
  ButtonContainer,
  Color,
  InputsWrapper,
  ItemsContainer,
  PrimaryAccordionItemHeader,
  StyledText,
} from './AccordionItem.styles';

const AccordionItem = ({
  children,
  itemHeading,
  type,
  removeItemFunction,
  isError,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isError) {
      setIsOpen(true);
    }
  }, [isError]);

  return (
    <>
      <AccordionItemContainer onClick={() => setIsOpen(!isOpen)}>
        {type === 'primary' ? (
          <PrimaryAccordionItemHeader>
            <StyledText>{itemHeading.base}</StyledText>
            <StyledText>{itemHeading.size}</StyledText>
          </PrimaryAccordionItemHeader>
        ) : (
          <AccordionItemHeader onClick={() => setIsOpen(!isOpen)}>
            <ItemsContainer>
              <StyledText>{itemHeading.base}</StyledText>
              <StyledText>{itemHeading.size}</StyledText>
              <Color
                key={itemHeading.color.name}
                rgbCode={rgbToHex(itemHeading.color.rgb) || ''}
                colorText={
                  tinycolor(rgbToHex(itemHeading.color.rgb)).isLight()
                    ? '#666666'
                    : '#F7F7F7'
                }
              >
                {itemHeading.color.name}
              </Color>
            </ItemsContainer>
          </AccordionItemHeader>
        )}
        {!isOpen && type === 'validate' && (
          <IconButton
            type="button"
            onClick={() => {
              removeItemFunction();
            }}
          >
            <img src={trashIcon} alt="trash" />
          </IconButton>
        )}
        <IconButton type="button" onClick={() => setIsOpen(!isOpen)}>
          <img src={isOpen ? arrowUp : arrowDown} alt="arrow" />
        </IconButton>
      </AccordionItemContainer>
      {isOpen && (
        <InputsWrapper>
          {children}
          {isOpen && type === 'validate' && (
            <ButtonContainer>
              <Button type="button" onClick={() => removeItemFunction()}>
                <img src={trashIcon} alt="trash" />
              </Button>
            </ButtonContainer>
          )}
        </InputsWrapper>
      )}
    </>
  );
};

export default AccordionItem;
