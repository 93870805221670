import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import FormContainer from 'components/structure/FormContainer/FormContainer';
import Grid from '@material-ui/core/Grid';
import InputText from 'components/form/Text/TextInput/InputText';
import AsyncSelectPaginate from 'components/form/Select/AsyncSelectPaginate/AsyncSelectPaginate';
import Toggle from 'components/form/Toggle/Toggle';
import RGBColorInput from 'components/contexts/colors/RGBColorInput/RGBColorInput';
import LABColorInput from 'components/contexts/colors/LABColorInput/LABColorInput';
import ActionButtons from 'components/form/ActionButtons';

import SelectSimple from 'components/form/Select/SelectSimple/SelectSimple';
import { useDispatch, useSelector } from 'react-redux';
import { errorToast, successToast } from 'utils/toasts';
import { FAMILIES } from 'constants/endpoints';

import urls from 'constants/urls';
import { createSlug } from 'utils/createSlug';
import arrayToString from 'utils/arrayToString';
import { getPermission } from 'utils/permissions';
import ModalMultiConfirmation from 'components/contexts/modal/ModalMultiConfirmation/ModalMultiConfirmation';

import { PAGE_SAFE_COLOR_CHOICE } from 'constants/defaultValues';
import { nscMask, ncsRegex } from 'utils/nscMask';
import { getFanId } from 'utils/getFanId';
import { yearlyColorOptions } from './yearlyColorOptions';
import {
  getColorDetail,
  getFamiliesOptions,
  getListColours,
  statusColoursModal,
  editColor as updateColor,
} from 'store/colours/colours-slice';
import { preventRedirectDisabled } from 'store/sidebar/sidebar-slice';

const ColorsEdit = () => {
  const { openStatusModal, status, msg, color, selectId } = useSelector(
    (state) => state.colours,
  );
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [ncs, setNCS] = useState('');
  const [page, setPage] = useState(null);
  const [position, setPosition] = useState(null);
  const [pageV4, setPageV4] = useState('');
  const [positionV4, setPositionV4] = useState('');
  const [pageV5, setPageV5] = useState('');
  const [positionV5, setPositionV5] = useState('');

  const [yearlyColor, setYearlyColor] = useState(null);
  const [stickerTest, setStickerTest] = useState(false);
  const [background, setBackground] = useState(false);
  const [groups, setGroups] = useState([]);
  const [active, setActive] = useState(false);
  const [color1, setColor1] = useState('');
  const [color2, setColor2] = useState('');
  const [color3, setColor3] = useState('');
  const [labColor1, setLabColor1] = useState('');
  const [labColor2, setLabColor2] = useState('');
  const [labColor3, setLabColor3] = useState('');
  const [showModalConfirmationSave, setShowModalConfirmationSave] =
    useState(false);
  const [showModalConfirmationCancel, setShowModalConfirmationCancel] =
    useState(false);
  const [colorRgbError, setColorRgbError] = useState({
    color1: '',
    color2: '',
    color3: '',
  });
  const [colorRgbNumberError, setColorRgbNumberError] = useState({
    color1: '',
    color2: '',
    color3: '',
  });

  const [statusForm, setStatus] = useState({
    type: '',
  });

  const tempColorRgb = [color1, color2, color3];
  const colorRgb = arrayToString(tempColorRgb);

  const tempColorLab = [labColor1, labColor2, labColor3];
  const colorLab = arrayToString(tempColorLab);

  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();

  const { permissions } = useSelector((state) => state.auth);

  useEffect(() => {
    if (getPermission('colors', permissions, 'update') === false) {
      history.push('/');
    }
  }, [history, permissions]);

  useEffect(() => {
    dispatch(getFamiliesOptions());
  }, [dispatch]);

  useEffect(() => {
    if (selectId === 0) {
      history.push(urls.ROUTES.CATALOGUE.items.colours.path);
    } else {
      dispatch(getColorDetail(parseInt(selectId, 10)));
    }
  }, [selectId, dispatch, history]);

  useEffect(() => {
    const separatorColorStringRgb = color?.rgb?.split(',', 3);
    const separatorColorStringLab = color?.lab?.split(',', 3);

    setName(color?.name);
    setCode(color?.code);
    setPage(color?.page === PAGE_SAFE_COLOR_CHOICE ? '' : color?.page);
    setPosition(color?.position);
    setYearlyColor(color?.yearlyColor);
    setStickerTest(color?.stickerTest);
    setBackground(color?.background);
    const infosV4 = color?.fans?.[0];
    const infosV5 = color?.fans?.[1];

    if (infosV4) {
      setPageV4(infosV4?.page);
      setPositionV4(infosV4?.position);
    }

    if (infosV5) {
      setPageV5(infosV5?.page);
      setPositionV5(infosV5?.position);
    }

    setGroups(
      color?.groups?.map((option) => ({
        id: option.id,
        name: option.name,
      })),
    );
    setNCS(color?.ncs);
    setActive(color?.active);
    setColor1(separatorColorStringRgb?.[0]);
    setColor2(separatorColorStringRgb?.[1]);
    setColor3(separatorColorStringRgb?.[2]);
    setLabColor1(separatorColorStringLab?.[0]);
    setLabColor2(separatorColorStringLab?.[1]);
    setLabColor3(separatorColorStringLab?.[2]);
  }, [color]);

  function validate() {
    if (!name)
      return setStatus({
        type: 'error',
      });
    if (!code)
      return setStatus({
        type: 'error',
      });
    if (!position)
      return setStatus({
        type: 'error',
      });
    if (!positionV4)
      return setStatus({
        type: 'error',
      });
    if (!pageV4)
      return setStatus({
        type: 'error',
      });
    if (!color1)
      return setStatus({
        type: 'error',
      });
    if (!color2)
      return setStatus({
        type: 'error',
      });
    if (!color3)
      return setStatus({
        type: 'error',
      });
    if (!ncsRegex.test(ncs)) {
      return setStatus({
        type: 'error',
      });
    }

    return true;
  }

  function verifyChanges() {
    const separatorColorStringRgb = color?.rgb?.split(',', 3);
    const separatorColorStringLab = color?.lab?.split(',', 3);

    if (name !== color.name) {
      return false;
    }
    if (code !== color.code) {
      return false;
    }
    if (page !== color.page) {
      return false;
    }
    if (position !== color.position) {
      return false;
    }
    if (yearlyColor !== color.yearlyColor) {
      return false;
    }
    if (stickerTest !== color.stickerTest) {
      return false;
    }
    if (background !== color.background) {
      return false;
    }
    if (
      groups[0].id !== color?.groups[0].id ||
      groups.length !== color.groups.length
    ) {
      return false;
    }
    if (active !== color.active) {
      return false;
    }
    if (color1 !== separatorColorStringRgb?.[0]) {
      return false;
    }
    if (color2 !== separatorColorStringRgb?.[1]) {
      return false;
    }
    if (color3 !== separatorColorStringRgb?.[2]) {
      return false;
    }
    if (labColor1 !== separatorColorStringLab?.[0]) {
      return false;
    }
    if (labColor2 !== separatorColorStringLab?.[1]) {
      return false;
    }
    if (labColor3 !== separatorColorStringLab?.[2]) {
      return false;
    }

    return true;
  }

  const editColor = () => {
    if (!validate()) {
      setShowModalConfirmationSave(false);
      return;
    }
    const formData = new FormData();
    const { v4, v5 } = getFanId();

    const fans = [
      {
        id: v4,
        page: pageV4,
        position: positionV4,
      },
      {
        id: v5,
        page: pageV5,
        position: positionV5,
      },
    ];

    formData.append('name', name);
    formData.append('code', code);
    formData.append('page', page || PAGE_SAFE_COLOR_CHOICE);
    formData.append('position', position);
    if (yearlyColor) {
      formData.append('yearlyColor', yearlyColor);
    }
    formData.append('stickerTest', stickerTest);
    formData.append('background', background);
    if (color?.groups?.lenght > 0) {
      const group = color?.groups?.map((value) => value?.id);
      formData.append('groups', JSON.stringify(group));
    } else {
      const groupsData = groups?.map((value) => value?.id);
      formData.append('groups', JSON.stringify(groupsData));
    }

    formData.append('active', active);
    formData.append('rgb', colorRgb);
    formData.append('lab', colorLab);
    formData.append('ncs', ncs);
    formData.append('fans', JSON.stringify(fans));

    if (
      color1 !== '' &&
      color2 !== '' &&
      color3 !== '' &&
      name !== '' &&
      code !== '' &&
      color1 >= 0 &&
      color1 <= 255 &&
      color2 >= 0 &&
      color2 <= 255 &&
      color3 >= 0 &&
      color3 <= 255
    ) {
      dispatch(updateColor({ info: formData, id: selectId }));
      dispatch(statusColoursModal(false));
      if (status !== 'error') {
        setShowModalConfirmationSave(false);
        dispatch(
          getListColours({
            currentPage: 1,
            searchTerm: '',
            perPage: 10,
            ordenation: '',
          }),
        );
        history.push(urls.ROUTES.CATALOGUE.items.colours.path);
        successToast(`A cor ${name} foi editada com sucesso!`);
      }
    } else {
      setColorRgbError(
        color1 === '' &&
        color2 === '' &&
        color3 === '' &&
        errorToast('Campo RGB deve ser preenchido'),
      );

      if (
        color1 < 0 ||
        color1 > 255 ||
        color2 < 0 ||
        color2 > 255 ||
        color3 < 0 ||
        color3 > 255
      ) {
        setColorRgbNumberError(
          errorToast('Campo RGB não deve ser menor que zero e maior que 255'),
        );
      }
    }
  };

  const cancelColor = () => {
    dispatch(preventRedirectDisabled());
    history.push(urls.ROUTES.CATALOGUE.items.colours.path);
  };

  return (
    <>
      <ModalMultiConfirmation
        title={intl.messages['textModal.areYouSure']}
        text={intl.messages['textModal.editColor']}
        isOpen={showModalConfirmationSave}
        setIsOpen={setShowModalConfirmationSave}
        buttonNameBack={intl.messages['buttons.not']}
        buttonName={intl.messages['buttons.yes']}
        status="confirm"
        confirmOnClick={editColor}
      />
      <ModalMultiConfirmation
        title={intl.messages['textModal.areYouSure']}
        text={intl.messages['textModal.cancelEditColor']}
        isOpen={showModalConfirmationCancel}
        setIsOpen={setShowModalConfirmationCancel}
        buttonNameBack={intl.messages['buttons.not']}
        buttonName={intl.messages['buttons.yes']}
        status="info"
        confirmOnClick={cancelColor}
      />
      <ModalMultiConfirmation
        title={msg.title}
        text={msg.text}
        setIsOpen={(payload) => {
          dispatch(statusColoursModal(payload));
          if (status !== 'error') {
            history.push(urls.ROUTES.CATALOGUE.items.colours.path);
          }
        }}
        isOpen={openStatusModal}
        status={status}
      />
      <FormContainer title="Editar cor">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item md={12}>
                <InputText
                  placeholder="Nome"
                  label="Nome"
                  value={name}
                  handleChange={setName}
                  error={
                    statusForm.type === 'error'
                      ? 'Campo Nome deve ser preenchido'
                      : ''
                  }
                  isError={name === '' && statusForm.type}
                />
              </Grid>
              <Grid item md={12}>
                <InputText
                  placeholder="Código"
                  label="Código"
                  value={code}
                  handleChange={setCode}
                  error={
                    statusForm.type === 'error'
                      ? 'Campo Código deve ser preenchido'
                      : ''
                  }
                  isError={code === '' && statusForm.type}
                />
              </Grid>
              <Grid item md={12}>
                <InputText
                  placeholder="Título-exemplo"
                  label="Slug"
                  disabled
                  value={createSlug(`${name}-${code}`)}
                />
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid item md={12}>
                      <InputText
                        label="Página do leque 1700"
                        placeholder="0"
                        type="number"
                        min="0"
                        value={pageV4}
                        handleChange={setPageV4}
                        error={
                          statusForm.type === 'error'
                            ? 'Campo Posição deve ser preenchido'
                            : ''
                        }
                        isError={pageV4 === '' && statusForm.type}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <InputText
                        label="Linha do leque 1700"
                        placeholder="0"
                        type="number"
                        min="0"
                        value={positionV4}
                        handleChange={setPositionV4}
                        hasTooltip
                        textTooltip="Posição do leque"
                        error={
                          statusForm.type === 'error'
                            ? 'Campo Posição deve ser preenchido'
                            : ''
                        }
                        isError={positionV4 === '' && statusForm.type}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid item md={12}>
                      <InputText
                        label="Página do leque 500"
                        placeholder="0"
                        type="number"
                        min="0"
                        value={pageV5}
                        handleChange={setPageV5}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <InputText
                        label="Linha do leque 500"
                        placeholder="0"
                        type="number"
                        min="0"
                        value={positionV5}
                        handleChange={setPositionV5}
                        hasTooltip
                        textTooltip="Posição do leque"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <SelectSimple
                  label="Cor do Ano"
                  placeholder="Selecione"
                  options={yearlyColorOptions}
                  defaultOption={yearlyColor}
                  handleChange={setYearlyColor}
                  optional
                />
              </Grid>
              <Grid item md={12}>
                <Toggle
                  label="Teste sua cor adesivo"
                  checked={stickerTest}
                  onChange={(e) => setStickerTest(e.target.checked)}
                  regular
                />
              </Grid>
              <Grid item md={12}>
                <Toggle
                  label="Fundo para Cores Especiais"
                  checked={background}
                  onChange={(e) => setBackground(e.target.checked)}
                  regular
                />
              </Grid>
              <Grid item md={12}>
                <RGBColorInput
                  color1={color1}
                  color2={color2}
                  color3={color3}
                  setColor1={(e) => setColor1(e)}
                  setColor2={(e) => setColor2(e)}
                  setColor3={(e) => setColor3(e)}
                  colorRgbString={colorRgb}
                  errors={
                    (colorRgbError && colorRgbNumberError) ||
                    statusForm.type === 'error'
                  }
                  isError={
                    (color1 === '' || color2 === '' || color3 === '') &&
                    statusForm.type
                  }
                />
              </Grid>
              <Grid item md={12}>
                <LABColorInput
                  labColor1={labColor1}
                  labColor2={labColor2}
                  labColor3={labColor3}
                  setLabColor1={setLabColor1}
                  setLabColor2={setLabColor2}
                  setLabColor3={setLabColor3}
                  colorLabString={colorLab}
                />
              </Grid>
              <Grid item md={12}>
                <InputText
                  placeholder="NCS"
                  label="NCS"
                  value={ncs}
                  handleChange={(value) => setNCS(nscMask(value))}
                />
              </Grid>
              <Grid item md={12}>
                <Toggle
                  label="Ativo"
                  checked={active}
                  onChange={(e) => setActive(e.target.checked)}
                  regular
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ActionButtons
          type="edit"
          cancelPath={() => {
            if (!verifyChanges()) {
              setShowModalConfirmationCancel(true);
            } else {
              cancelColor();
            }
          }}
          submitFunc={() => {
            if (!validate()) {
              setShowModalConfirmationSave(false);
            } else {
              setShowModalConfirmationSave(true);
            }
          }}
        />
      </FormContainer>
    </>
  );
};

export default ColorsEdit;
