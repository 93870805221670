import ModalMultiConfirmation from 'components/contexts/modal/ModalMultiConfirmation/ModalMultiConfirmation';
import { ReactComponent as TrashPropertyIcon } from 'assets/icons/trash.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import AsyncSelectPaginate from 'components/form/Select/AsyncSelectPaginate/AsyncSelectPaginate';
import SelectSearch from 'components/form/Select/ReactSelect';
import InputText from 'components/form/Text/TextInput/InputText';
import Toggle from 'components/form/Toggle/Toggle';
import IconButton from 'components/structure/IconButton/IconButton';
import { BASES, COLORS, DRY_PACKAGES } from 'constants/endpoints';
import urls from 'constants/urls';
import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import FileInput from 'components/form/FileInput/FileInput';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import api from 'services/api';
import Modal from 'components/structure/Modal/Modal';
import { getPermission } from 'utils/permissions';
import { systemColors } from '../Edit/dataSkusOptions';
import {
  preventRedirectDisabled,
  preventRedirectEnabled,
} from 'store/sidebar/sidebar-slice';
import { statusSkusModal } from 'store/sku/sku-slice';
import { errorToast } from 'utils/toasts';

import * as S from './SkuCreate.styles';
import { useSkuImageList } from 'hooks/useSkuImageList';
import SkuImageList from 'components/contexts/skus/SkuImageList/SkuImageList';
import Button from 'components/structure/Button/Button';

const SkuCreate = ({ updateFormList, position, remove, index: indexForm }) => {
  const [ean, setEan] = useState('');
  const [dryPackage, setDryPackage] = useState(null);
  const [packageContentId, setPackageContentId] = useState(null);
  const [systemColor, setSystemColor] = useState('');
  const [isLoadingWeightOptions, setLoadingWeightOptions] = useState(false);
  const [weightOptions, setWeightOptions] = useState([]);
  const [base, setBase] = useState(null);
  const [color, setColor] = useState(null);
  const [active, setActive] = useState(true);
  const [eanExist, setEanExist] = useState(false);
  const [checkingEan, setCheckingEan] = useState(false);
  const [checkingEanLength, setCheckingEanLength] = useState(false);
  const [uploadImageModal, setUploadImageModal] = useState(false);
  const [hasBase, setHasBase] = useState(false);
  const [showModalConfirmationCancel, setShowModalConfirmationCancel] =
    useState(false);
  const { permissions } = useSelector((state) => state.auth);
  const { openStatusModal, status, msg } = useSelector((state) => state.sku);
  const product = useSelector((state) => state.sku.product);
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [statusForm] = useState({
    type: '',
  });
  const { loading } = useSelector((state) => state.loading);

  const sku = useMemo(
    () => ({
      dryPackage,
    }),
    [dryPackage],
  );

  const {
    skuImages,
    setSkuImages,
    saveSkuImages,
    imagesToDeleteFromBucket,
    setImagesToDeleteFromBucket,
    addSkuImage,
  } = useSkuImageList(product, sku);

  useEffect(() => {
    if (getPermission('stock-keeping-units', permissions, 'create') === false) {
      history.push('/');
    }
  }, [history, permissions]);

  useEffect(() => {
    dispatch(preventRedirectEnabled());
  }, [dispatch]);

  const checkBases = useCallback(async () => {
    const { data } = await api.get(
      `${BASES}?slug=${systemColor}&productId=${product.id}`,
    );

    if (data.totalItems === 0) {
      errorToast('O produto não possui base para este sistema de cor.');
      setBase(null);
      setHasBase(false);
    } else {
      setHasBase(true);
    }
  }, [product.id, systemColor]);

  useEffect(() => {
    checkBases();
  }, [product.id, systemColor, checkBases]);

  const checkEan = useCallback(async () => {
    const { data } = await api.get(
      `/stock-keeping-units?checkEan=true&ean=${ean}`,
    );
    setCheckingEan(false);
    setEanExist(Boolean(data.totalItems));
  }, [ean]);

  useEffect(() => {
    let timeoutId;
    if (ean.length !== 13 && ean.length !== 0) {
      setCheckingEanLength(true);
    } else if (ean) {
      setCheckingEanLength(false);
      setEanExist(false);
      setCheckingEan(true);
      timeoutId = setTimeout(() => {
        checkEan();
      }, 3000);
    }

    return () => {
      clearTimeout(timeoutId);
      setCheckingEan(false);
    };
  }, [ean, checkEan]);

  const cancelSku = () => {
    dispatch(preventRedirectDisabled());
    history.push(urls.ROUTES.SKU_LIST.path);
  };

  const removeSelf = () => {
    remove((prev) =>
      prev.filter((formComponent) => formComponent.position !== position),
    );
  };

  const hadleDryPackageContent = useCallback(async (infos) => {
    setLoadingWeightOptions(true);

    const { data } = await api.get(
      `${DRY_PACKAGES}/${infos?.id.toString()}/package-contents?perPage=1000&productId=${
        product.id
      }`,
    );

    setWeightOptions(
      data.items.map((item) => ({
        value: item.id,
        label: `${item.grossWeight.toString()} ${item.unit.alias}`,
      })),
    );

    setLoadingWeightOptions(false);
  }, []);

  const ImagePreview = useCallback(() => {
    return typeof window !== 'undefined' ? (
      <img
        style={{ width: '24px', height: '24px' }}
        src={
          typeof skuImages[0] === 'string'
            ? skuImages[0]
            : URL.createObjectURL(skuImages[0])
        }
        alt="Imagem"
      />
    ) : (
      <></>
    );
  }, [skuImages]);

  useEffect(() => {
    updateFormList((prev) => {
      const aux = [...prev];
      aux[position] = {
        ean,
        dryPackage,
        systemColor,
        base,
        color,
        packageContentId,
        active,
      };
      return aux;
    });
  }, [
    active,
    ean,
    dryPackage,
    systemColor,
    base,
    color,
    packageContentId,
    updateFormList,
    position,
  ]);

  useEffect(() => {
    const getBaseOptions = async () => {
      const url = `${BASES}?slug=${systemColor}`;
      const { data } = await api.get(url);
      const options =
        data.items !== undefined
          ? data.items.map((item) => ({ ...item, value: item.slug }))
          : [];
      setBase(options[0]);
    };

    if (systemColor !== 'selfcolor' && systemColor !== '') {
      getBaseOptions();
    } else {
      setBase(null);
    }
  }, [systemColor]);

  return (
    <>
      <ModalMultiConfirmation
        title={intl.messages['textModal.cancelCreateSku']}
        text={intl.messages['textModal.allWillBeLost']}
        isOpen={showModalConfirmationCancel}
        setIsOpen={setShowModalConfirmationCancel}
        buttonNameBack={intl.messages['buttons.not']}
        buttonName={intl.messages['buttons.yes']}
        confirmOnClick={cancelSku}
        status="info"
      />

      <S.ContainerModal>
        <Modal
          title={'Carregar imagens'}
          isOpen={uploadImageModal}
          setIsOpen={setUploadImageModal}
          confirmOnClick={() => {}}
          status=""
          containerWidth="100%"
          containerHeight="100%"
          titleRight
        >
          <S.ContainerModal>
            <div>
              <FileInput
                label="Imagem na PDP (carrossel)"
                text={`CARREGAR IMAGEM (CARROSSEL)`}
                title="Imagem que sera usada como showcase do produto na loja."
                accept="image/*"
                getFile={addSkuImage}
                dontShowTheFileName
              />
              <br />
              <div className="image-list">
                <div>
                  <p>Posição no carrossel</p>
                  <p>Imagem</p>
                  <p>Ações</p>
                </div>
                <SkuImageList
                  setImagesToDeleteFromBucket={setImagesToDeleteFromBucket}
                  items={skuImages.map((img) => ({
                    id: img.toString(),
                    image: img,
                  }))}
                  setItems={setSkuImages}
                />
              </div>
              <Button
                btnType="primary"
                width="140px"
                fontWeight="600"
                onClick={() => saveSkuImages(() => setUploadImageModal(false))}
                disabled={
                  loading ||
                  (skuImages.length === 0 &&
                    imagesToDeleteFromBucket.length === 0)
                }
              >
                CONFIRMAR
              </Button>
            </div>
          </S.ContainerModal>
        </Modal>
      </S.ContainerModal>

      <ModalMultiConfirmation
        title={msg.title}
        text={msg.text}
        setIsOpen={(payload) => {
          dispatch(statusSkusModal(payload));
          if (status !== 'error') {
            history.push(urls.ROUTES.SKU_LIST.path);
          }
        }}
        isOpen={openStatusModal}
        status={status}
      />

      <S.Container>
        <h3>Sku {indexForm + 1}</h3>

        <S.ToggleContainer>
          <Toggle
            label={intl.messages['labelsInputs.active']}
            checked={active}
            onChange={(e) => setActive(e.target.checked)}
            regular
          />
          {position !== 0 && (
            <IconButton
              btnType="transparent"
              icon={<TrashPropertyIcon />}
              onClick={removeSelf}
            />
          )}
        </S.ToggleContainer>

        <S.FormContainerAParty>
          <InputText
            type="number"
            placeholder="000"
            maxLength={13}
            label={intl.messages['labelsInputs.eanSku']}
            value={ean}
            handleChange={setEan}
            isLoading={checkingEan}
            padding="0"
            error={
              statusForm.type === 'error'
                ? 'Campo EAN deve ser preenchido'
                : '' ||
                  (eanExist && 'Número de EAN já cadastrado') ||
                  (checkingEanLength && 'Número de EAN deve conter 13 dígitos')
            }
            isError={
              (ean === '' && statusForm.type) || eanExist || checkingEanLength
            }
          />
          <AsyncSelectPaginate
            zIndex="none"
            label="Embalagem"
            placeholder="Selecione"
            value={dryPackage}
            url={DRY_PACKAGES}
            perPage={5}
            handleChange={(data) => {
              setDryPackage(data);
              hadleDryPackageContent(data);
            }}
            error={
              statusForm.type === 'error'
                ? 'Campo Embalagem deve ser preenchido'
                : ''
            }
            isError={dryPackage?.id === undefined && statusForm.type}
            productId={product?.id}
          />

          <SelectSearch
            zIndex="40"
            label="Peso"
            placeholder="Selecione"
            options={weightOptions}
            defaultOption={packageContentId}
            handleChange={setPackageContentId}
            disabled={isLoadingWeightOptions}
            error={
              statusForm.type === 'error'
                ? 'Campo Peso deve ser preenchido'
                : ''
            }
            isError={packageContentId === '' && statusForm.type}
          />

          <SelectSearch
            zIndex="30"
            label={intl.messages['labelsInputs.systemColorSku']}
            placeholder={intl.messages['labelsInputs.select']}
            options={systemColors}
            defaultOption={systemColor}
            handleChange={setSystemColor}
            error={
              statusForm.type === 'error' ? 'Campo Cor deve ser preenchido' : ''
            }
            isError={systemColor === '' && statusForm.type}
          />
        </S.FormContainerAParty>
        <S.FormContainerBParty>
          <S.InputSmallWrapper key={systemColor}>
            <AsyncSelectPaginate
              zIndex="20"
              label={intl.messages['labelsInputs.baseSku']}
              placeholder={intl.messages['labelsInputs.select']}
              value={base}
              url={BASES}
              productId={systemColor === 'selfcolor' ? product?.id : ''}
              slug={systemColor === 'selfcolor' ? '' : systemColor}
              perPage={5}
              disabled={!hasBase}
              handleChange={setBase}
              error={
                statusForm.type === 'error'
                  ? 'Campo Base deve ser preenchido'
                  : ''
              }
              isError={base?.id === undefined && statusForm.type}
            />
          </S.InputSmallWrapper>
          {!!systemColor && systemColor === 'readymix' && (
            <S.InputSmallWrapper>
              <AsyncSelectPaginate
                zIndex="none"
                label={intl.messages['labelsInputs.colorSku']}
                placeholder={intl.messages['labelsInputs.select']}
                value={color}
                url={COLORS}
                perPage={10}
                handleChange={setColor}
                error={
                  statusForm.type === 'error'
                    ? 'Campo Cor deve ser preenchido'
                    : ''
                }
                isError={color?.id === undefined && statusForm.type}
              />
            </S.InputSmallWrapper>
          )}
          <S.ImageCTAContainer onClick={() => setUploadImageModal(true)}>
            <p>Imagens</p>
            <S.ImageCTAContent>
              {skuImages.length === 0 ? (
                <>
                  <div>
                    <p className="orange-text">
                      Clique para selecionar a imagem
                    </p>
                    &nbsp;
                    <p>| PNG ou JPG</p>
                  </div>
                  <UploadIcon />
                </>
              ) : (
                <>
                  <div>
                    <ImagePreview /> + {skuImages.length}
                  </div>
                  <EditIcon />
                </>
              )}
            </S.ImageCTAContent>
          </S.ImageCTAContainer>
        </S.FormContainerBParty>
      </S.Container>
    </>
  );
};

export default SkuCreate;

SkuCreate.propTypes = {
  updateFormList: PropTypes.func.isRequired,
  position: PropTypes.number.isRequired,
  remove: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};
