import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: row-reverse;
    background: ${theme.colors.white.default};
    padding: 12px 32px 18px;

    span {
      font-weight: 400;
      font-size: ${theme.typography.sizes.sm};
      color: ${theme.colors.secondary.default};
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.secondary.default};
  `}
`;
