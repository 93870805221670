/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import api from 'services/api';
import { COLOR_COMBINATIONS } from '../../constants/endpoints';
import {
  errorSessionToast,
  errorToast,
  successToast,
} from '../../utils/toasts';
import { startLoading, stopLoading } from 'store/loading/loading-slice';

const INITIAL_STATE = {
  colorCombinations: {
    totalPage: 1,
    items: [],
  },
  colorCombination: {
    id: 0,
    name: '',
    published: false,
    colorToColorCombinations: [
      {
        id: 0,
        colorId: 0,
        position: 0,
        rgb: '0,0,0',
        code: '',
        yearlyColor: null,
        nameColor: '',
      },
    ],
  },
  colorToColorCombination: {
    id: 0,
    colorId: 0,
    position: 0,
    rgb: '0,0,0',
    code: '',
    yearlyColor: null,
    nameColor: '',
  },
  items: [],
  openStatusModal: false,
  status: '',
  msg: {
    title: '',
    text: '',
  },
  selectId: 0,
  openDeleteModal: false,
};

const colorCombinationsSlice = createSlice({
  name: 'colorCombinations',
  initialState: INITIAL_STATE,
  reducers: {
    getColorCombinationsSuccess: (state, { payload }) => {
      state.colorCombinations = payload;
    },
    getDeletedColorCombinationsSuccess: (state, { payload }) => {
      state.colorCombinations = payload;
    },
    setCombinationId: (state, { payload }) => {
      state.selectId = payload;
    },
    getColorCombinationSuccess: (state, { payload }) => {
      state.colorCombination = payload;
    },
    getDeletedColorCombinationSuccess: (state, { payload }) => {
      state.colorCombination = payload;
    },
    setCombinationStatus: (state, { payload }) => {
      state.status = payload;
    },
    setCombinationMsg: (state, { payload }) => {
      state.msg = payload;
    },
  },
});

export const colorCombinations = colorCombinationsSlice.reducer;
export const {
  getColorCombinationSuccess,
  getColorCombinationsSuccess,
  getDeletedColorCombinationSuccess,
  getDeletedColorCombinationsSuccess,
  setCombinationId,
  setCombinationMsg,
  setCombinationStatus,
} = colorCombinationsSlice.actions;

export const getColorCombinationsList = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { currentPage, searchTerm, perPage, ordenation } = payload;

  try {
    let url = '';
    if (searchTerm === '') {
      url = `${COLOR_COMBINATIONS}?perPage=${
        perPage || '10'
      }&page=${currentPage}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}&type=1`;
    } else {
      url = `${COLOR_COMBINATIONS}?perPage=${
        perPage || '10'
      }&page=${currentPage}&name=${searchTerm}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}&type=1`;
    }
    const response = await api.get(url);
    dispatch(getColorCombinationsSuccess(response.data));

    dispatch(stopLoading());
  } catch (error) {
    errorToast(error);
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getDeletedColorCombinationsList =
  (payload) => async (dispatch) => {
    dispatch(startLoading());
    const { currentPage, searchTerm, perPage, ordenation } = payload;

    try {
      let url = '';
      if (searchTerm === '') {
        url = `${COLOR_COMBINATIONS}?perPage=${
          perPage || '10'
        }&page=${currentPage}&sort.field=${
          ordenation || 'createdAt'
        }&sort.order=${ordenation ? 'ASC' : 'DESC'}&type=1&trash=true`;
      } else {
        url = `${COLOR_COMBINATIONS}?perPage=${
          perPage || '10'
        }&page=${currentPage}&name=${searchTerm}&sort.field=${
          ordenation || 'createdAt'
        }&sort.order=${ordenation ? 'ASC' : 'DESC'}&type=1&trash=true`;
      }
      const response = await api.get(url);
      dispatch(getDeletedColorCombinationsSuccess(response.data));

      dispatch(stopLoading());
    } catch (error) {
      errorToast(error);
      dispatch(stopLoading());
      if (error.response !== undefined) {
        const { status, data } = error.response;
        switch (error.response.status) {
          case 401:
            errorSessionToast();
            break;
          default:
            errorToast(`Erro: ${status}: ${data.error}`);
            break;
        }
      }
    }
  };

export const getCombinationDetail = (payload) => async (dispatch) => {
  dispatch(startLoading());

  try {
    const response = await api.get(`${COLOR_COMBINATIONS}/${payload}`);
    dispatch(getColorCombinationSuccess(response.data));

    dispatch(stopLoading());
  } catch (error) {
    errorToast(error);
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getDeletedCombinationDetail = (payload) => async (dispatch) => {
  dispatch(startLoading());

  try {
    const response = await api.get(
      `${COLOR_COMBINATIONS}/${payload}?trash=true`,
    );
    dispatch(getDeletedColorCombinationSuccess(response.data));

    dispatch(stopLoading());
  } catch (error) {
    errorToast(error);
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const addColorCombination = (payload) => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api.post(COLOR_COMBINATIONS, payload);

    successToast(`A cor ${payload.name} foi criada com sucesso!`);
    dispatch(
      getColorCombinationsList({
        currentPage: 1,
        searchTerm: '',
        perPage: '10',
        ordenation: '',
      }),
    );

    dispatch(stopLoading());
  } catch (error) {
    let msg = { text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Ops!',
            text: 'Não foi possível criar essa combinação, verifique as informações.',
          };

          dispatch(setCombinationMsg(msg));
          dispatch(setCombinationStatus('error'));
          break;
        case 409:
          msg = {
            title: 'Combinação já existente',
          };
          dispatch(setCombinationMsg(msg));
          dispatch(setCombinationStatus('error'));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const editColorCombination = (payload) => async (dispatch) => {
  const { id, info } = payload;
  dispatch(startLoading());

  try {
    await api.patch(`${COLOR_COMBINATIONS}/${id}`, info);
    const msg = { title: 'Combinação alterada com sucesso' };

    dispatch(setCombinationMsg(msg));
    dispatch(setCombinationStatus('success'));
    successToast('Combinação editada com sucesso');

    dispatch(stopLoading());
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Ops!',
            text: 'Não foi possível alterar essa cor, verifique as informações.',
          };
          dispatch(setCombinationMsg(msg));
          dispatch(setCombinationStatus('error'));
          break;
        case 409:
          msg = {
            title: 'Cor já existente',
          };
          dispatch(setCombinationMsg(msg));
          dispatch(setCombinationStatus('error'));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const removeColorCombination = (payload) => async (dispatch) => {
  const { id } = payload;
  dispatch(startLoading());

  try {
    await api.delete(`${COLOR_COMBINATIONS}/${id}`);
    dispatch(stopLoading());
    successToast('Combinação removida com sucesso');
  } catch (error) {
    errorToast(error);
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};
