import styled from 'styled-components';

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.typography.family.secondary};
  color: ${({ theme }) => theme.colors.tertiary.default};
  font-weight: 700;
  font-size: ${({ theme }) => theme.typography.sizes.lg};
  line-height: 38px;
  text-align: center;
`;

export const Description = styled.p`
  font-family: ${({ theme }) => theme.typography.family.tertiary};
  color: ${({ theme }) => theme.colors.tertiary.default};
  font-weight: 400;
  font-size: ${({ theme }) => theme.typography.sizes.base};
  line-height: 26px;
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing.xs};
`;
