import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 296px;

    > p {
      margin-bottom: 16px;
      font-family: ${theme.typography.family.primary};
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      width: 100%;
    }
  `}
`;

export const ButtonsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;

    button:first-child {
      margin-right: 12px;

      @media only screen and (max-width: ${theme.breakpoints.md}) {
        margin-bottom: 12px;
      }
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
    }
  `}
`;
