import styled, { css } from 'styled-components';
import CheckboxIcon from 'assets/icons/checkbox.svg';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const LabelInput = styled.label`
  ${({ theme }) => css`
    color: ${theme.colors.secondary.default};
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    padding-left: 8px;
  `}
`;

export const InputStyle = styled.input`
  ${({ theme }) => css`
    all: unset;
    float: left;
    width: 20px;
    height: 20px;
    background-color: ${theme.colors.background.float.solid};
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.87);
    cursor: pointer;
    box-shadow: inset 0 0 0 0 transparent;
    transition: color 0.1s ease, border-color 0.1s ease;

    ${({ checked }) =>
      checked &&
      `
    ::before {
      float: left;
      width: 20px;
      height: 20px;
      content: "";
      border-radius: 2px;
      background-image: url(${CheckboxIcon});
      background-repeat: no-repeat;
      box-sizing: border-box;
      margin-top: -1px;
      margin-left: -1px;
    }
  `}

    ${({ disabled }) =>
      disabled &&
      css`
        cursor: not-allowed;
        background-color: #bbb;
      `};
  `}
`;
