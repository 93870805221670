import styled, { css } from 'styled-components';

export const Button = styled.button`
  ${({ theme }) => css`
    background: transparent;
    color: ${theme.colors.primary.default};
    outline: none;
    border: none;
    cursor: pointer;
    padding-left: 1vw;
    font-weight: 700;
  `}
`;

export const ButtonContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  span {
    padding-left: 8px;
  }
`;
