/* eslint-disable default-case */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import SpanError from 'components/structure/SpanError/SpanError';
import FileIcon from 'assets/icons/upload.svg';
import DisabledFileIcon from 'assets/icons/disabled_upload.svg';
import Tooltip from 'assets/icons/tooltip-icon.svg';
import TooltipComponent from 'components/structure/Tooltip/Tooltip';
import externalIcon from 'assets/icons/open-in-new.svg';
import fileTextIcon from 'assets/icons/file-text.svg';
import downloadIcon from 'assets/icons/download-dark.svg';

import { useDispatch } from 'react-redux';
import {
  FileContainer,
  LabelContainer,
  Label,
  FileWrapper,
  Icon,
  Text,
  InputTag,
  FileNameTitle,
  OptionalStyle,
  LinkContainer,
  LinkItem,
} from './FileInput.styles';
import truncateString from '../../../utils/truncateString';
import { setModalFileInput } from 'store/modals/modals-slice';

const FileInput = ({
  name,
  id,
  label,
  title,
  text,
  disabled,
  accept,
  isError,
  error,
  getFile,
  fileName,
  optional,
  dontShowViewButton,
  useOpenInNewIcon,
  dontShowTheFileName,
  hasTooltip,
}) => {
  const [showRedirect, setShowRedirect] = useState(false);
  const dispatch = useDispatch();
  const [valueForInvalidateCache, setValueForInvalidateCache] = useState(0);

  const getURLWithInvalidateCacheDateAproach = (url) => {
    setValueForInvalidateCache(Date.now());
    return `${url}?${valueForInvalidateCache}`;
  };

  const getURLWithInvalidateCacheAproach = useMemo(
    () => getURLWithInvalidateCacheDateAproach(fileName),
    [fileName],
  );

  useEffect(() => {
    if (fileName !== null) {
      const prefix = fileName.substr(0, 5);
      if (prefix === 'https') {
        setShowRedirect(true);
      } else {
        setShowRedirect(false);
      }
    }
  }, [fileName]);

  const showExternalImage = (e, imgUrl) => {
    e.preventDefault();
    dispatch(setModalFileInput({ status: true, imgUrl }));
  };

  return (
    <FileContainer>
      <LabelContainer>
        {label && (
          <Label htmlFor={name} isError={isError}>
            {label}
          </Label>
        )}
        {hasTooltip && (
          <TooltipComponent id="tooltipImage" title={title} multiline>
            {optional && <OptionalStyle>(Opcional)</OptionalStyle>}
            <img src={Tooltip} alt="" />
          </TooltipComponent>
        )}
      </LabelContainer>
      <FileWrapper>
        <Icon src={disabled ? DisabledFileIcon : FileIcon} alt="FileIcon" />
        <Text disabled={disabled}>{text}</Text>
        <InputTag
          name={name}
          id={id}
          type="file"
          onChange={getFile}
          disabled={disabled}
          accept={accept}
          isError={isError}
        />
      </FileWrapper>
      {!dontShowTheFileName && (
        <LinkContainer>
          <img alt="" src={fileTextIcon} title="Imagem" />
          <FileNameTitle>{truncateString(fileName, 50)}</FileNameTitle>
          {showRedirect && (
            <>
              {!dontShowViewButton ? (
                <LinkItem
                  href={getURLWithInvalidateCacheAproach}
                  target="_blank"
                  rel="noreferrer"
                  onClick={(e) => showExternalImage(e, fileName)}
                >
                  <img alt="" src={externalIcon} title="Visualizar imagem" />
                </LinkItem>
              ) : null}
              <LinkItem href={getURLWithInvalidateCacheAproach} target="_blank">
                <img
                  alt=""
                  src={useOpenInNewIcon ? externalIcon : downloadIcon}
                  title="Baixar imagem"
                />
              </LinkItem>
            </>
          )}
        </LinkContainer>
      )}
      {isError && <SpanError>{error}</SpanError>}
    </FileContainer>
  );
};

FileInput.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  text: PropTypes.string,
  disabled: PropTypes.bool,
  accept: PropTypes.string,
  isError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  error: PropTypes.object,
  getFile: PropTypes.func,
  fileName: PropTypes.string,
  optional: PropTypes.bool,
  dontShowViewButton: PropTypes.bool,
  useOpenInNewIcon: PropTypes.bool,
  hasTooltip: PropTypes.bool,
  dontShowTheFileName: PropTypes.bool,
};

FileInput.defaultProps = {
  name: '',
  id: '',
  label: '',
  title: {},
  text: '',
  disabled: false,
  accept: '',
  isError: false,
  error: {},
  getFile: () => {},
  fileName: '',
  optional: false,
  dontShowViewButton: false,
  useOpenInNewIcon: false,
  hasTooltip: true,
  dontShowTheFileName: false,
};

export default FileInput;
