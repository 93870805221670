import { createGlobalStyle, css } from 'styled-components';
import Suvinilbook from 'assets/fonts/SuvinilSans-Book.ttf';
import Suvinilbold from 'assets/fonts/SuvinilSans-Bold.ttf';
import Trebuchet from 'assets/fonts/Trebuchet_MS.ttf';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,300&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

  *,
  *::after,
  *::before {
    padding: 0;
    margin: 0;
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
    height: 100%;
    scroll-behavior: smooth;

  }

  body {
    ${({ theme }) => css`
      font-family: ${theme.typography.family.primary};
      font-size: ${theme.typography.sizes.base};
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      height: 100%;
    `}
  }

  @font-face {
    font-family: 'Suvinil Sans';
    src: url(${Suvinilbook}) format("truetype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Suvinil Sans';
    src: url(${Suvinilbold}) format("truetype");
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Trebuchet MS';
    src: url(${Trebuchet}) format("truetype");
    font-weight: normal;
    font-style: normal;
  }
`;
