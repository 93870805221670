/* eslint-disable no-unused-vars */
import ExternalLink from 'components/contexts/Links/ExternalLink';
import ExternalLinkArticlePreview from 'components/contexts/Links/ExternalLinkArticlePreview/ExternalLinkArticlePreview';
import ModalDeleteConfirmation from 'components/contexts/modal/ModalDeleteConfirmation/ModalDeleteConfirmation';
import ModalDeleteConfirmationToggle from 'components/contexts/modal/ModalDeleteConfirmationToggle/ModalDeleteConfirmation';
import ModalStatus from 'components/contexts/modal/ModalStatus/ModalStatus';
import EmptySearch from 'components/structure/EmptySearch/EmptySearch';
import PageContent from 'components/structure/PageContent/PageContent';
import PageHeader from 'components/structure/PageHeader/PageHeader';
import PopOver from 'components/structure/PopOver/PopOver';
import TableDefault from 'components/structure/Table/Table';
import Toggle from 'components/structure/ToggleTable/TogleTable';
import urls from 'constants/urls';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import addIcon from 'assets/icons/add.svg';

import arrayToString from 'utils/arrayToString';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { getPermission, getPublishArticlePermission } from 'utils/permissions';
import RedirectArticleModalForm from '../../components/contexts/modal/RedirectArticleModalForm/RedirectArticleModalForm';
import Modal from '../../components/structure/Modal/Modal';
import { Divider, PopItem, PopOverContent } from './Articles.styles';
import {
  getArticleDetail,
  getArticleDetailPublished,
  getListArticles,
  setActiveStep,
  setArticleId,
  setPublishedModal,
  updatePublishedArticle,
  removeArticle as deleteArticle,
} from 'store/articles/articles-slice';

const contentTypeOptions = [
  { value: 'pdp', label: 'Conteúdo de Produtos' },
  { value: 'collection', label: 'Conteúdo de Listas' },
];

const contentTypeDict = {
  pdp: 'Página de Produto',
  collection: 'Página de Lista de compras/coleções',
};

const Articles = () => {
  const [type, setType] = React.useState('');
  const [selectedId, setSelectedId] = useState('');
  const [selectedSlug, setSelectedSlug] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [requestStatusType, setRequestStatusType] = useState();
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [responseStatus, setResponseStatus] = useState('success');
  const [redirectModalContent, setRedirectModalContent] = useState(undefined);

  const [searchTerm, setSearchTerm] = useState('');
  const [ordenation, setOrdenation] = useState('');
  const [totalPerPage, setTotalPerPage] = useState({ value: 10 });
  const perPage = totalPerPage.value;

  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const { items, totalPage } = useSelector((state) => state.articles.articles);
  const { permissions, attributes } = useSelector((state) => state.auth);
  const { isLoading, status } = useSelector((state) => state.articles);
  const { article, publishedModal } = useSelector((state) => state.articles);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  const addArticle = () => {
    history.push(urls.ROUTES.ARTICLES_CREATE.path);
    dispatch(setActiveStep(0));
  };

  const editArticle = useCallback(
    (id) => {
      setSelectedId(id);
      dispatch(setArticleId(id));
      history.push(urls.ROUTES.ARTICLES_EDIT.path);
      dispatch(setActiveStep(0));
    },
    [dispatch, history],
  );

  const viewArticle = useCallback(
    (id) => {
      setSelectedId(id);
      dispatch(setArticleId(id));
      dispatch(getArticleDetail(parseInt(id, 10)));
      history.push(urls.ROUTES.ARTICLES_VIEW.path);
      dispatch(setActiveStep(0));
    },
    [dispatch, history],
  );

  const removeArticle = useCallback(
    (id, slug) => {
      if (requestStatusType) {
        setRequestStatusType('');
      }
      setSelectedId(id);
      setSelectedSlug(slug);
      setShowDeleteModal(true);
    },
    [requestStatusType],
  );

  const viewActivities = useCallback(
    (id) => {
      history.push(
        `${urls.ROUTES.ACCESS.items.logsByRecord.path}/${id}/articles`,
      );
    },
    [history],
  );

  const statusMethod = useCallback(() => {
    switch (requestStatusType) {
      case 'delete-success':
        setResponseStatus('success');
        setShowDeleteModal(false);
        setShowStatusModal(true);
        setConfirmationMessage('Artigo excluído com sucesso!');
        break;

      default:
        break;
    }
  }, [requestStatusType]);

  const COLUMNS = useMemo(
    () => [
      { Header: 'ID', accessor: 'id' },
      {
        Header: 'Título',
        accessor: 'name',
      },
      {
        Header: 'Conteúdo',
        accessor: ({ type: seoType }) => contentTypeDict[seoType],
      },
      {
        Header: 'Slug',
        accessor: ({ slug, platforms, editorial, published, id }) =>
          published === true ? (
            <ExternalLink
              slug={editorial?.slug}
              plataform={platforms}
              articleSlug={slug}
              published={published}
            />
          ) : (
            <ExternalLinkArticlePreview
              plataform={platforms}
              articleSlug={slug}
              isListSlugArticles
              articleSlugTitle
              articleId={id}
            />
          ),
      },
      {
        Header: 'Publicado',
        accessor: ({ published, id, slug }) =>
          getPermission('articles', permissions, 'update') === false ? (
            <></>
          ) : (
            <Toggle
              key={id}
              checked={published}
              id={id}
              page={currentPage}
              slug={slug}
              setData={() => setSelectedSlug(slug)}
              readonly={getPublishArticlePermission(attributes)}
              title={
                getPublishArticlePermission(attributes)
                  ? 'Publicar'
                  : 'Você não possui permissão para publicar artigos'
              }
              onChangeFunction={() => dispatch(getArticleDetailPublished(id))}
            />
          ),
      },
      {
        Header: 'Plataforma',
        accessor: ({ platforms }) =>
          arrayToString(platforms) === 'institucional'
            ? arrayToString(platforms).replace(platforms, 'Suvinil')
            : capitalizeFirstLetter(arrayToString(platforms)),
      },
      {
        Header: ' ',
        accessor: ({ id, slug, published, platforms }) =>
          getPermission('articles', permissions, 'update') === false ? (
            <></>
          ) : (
            <PopOver
              content={
                <PopOverContent>
                  <PopItem onClick={() => editArticle(id)}>
                    {intl.messages['buttons.edit']}
                  </PopItem>
                  {getPermission('articles', permissions, 'delete') &&
                    published === false && (
                      <>
                        <Divider />
                        <PopItem onClick={() => removeArticle(id, slug)}>
                          {intl.messages['buttons.exclusion']}
                        </PopItem>
                      </>
                    )}
                  {published === false && (
                    <>
                      <Divider />
                      <PopItem>
                        <ExternalLinkArticlePreview
                          plataform={platforms}
                          articleId={id}
                          isListArticles
                        />
                      </PopItem>
                    </>
                  )}
                  {getPermission('articles', permissions, 'access') && (
                    <>
                      <Divider />
                      <PopItem onClick={() => viewArticle(id)}>
                        Visualizar
                      </PopItem>
                    </>
                  )}
                  <>
                    <Divider />
                    <PopItem onClick={() => viewActivities(id)}>
                      Ver atividades
                    </PopItem>
                  </>
                </PopOverContent>
              }
            />
          ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentPage,
      editArticle,
      intl.messages,
      permissions,
      removeArticle,
      attributes,
      viewActivities,
      viewArticle,
    ],
  );

  const data = useMemo(() => items, [items]);

  useEffect(() => {
    dispatch(
      getListArticles({ currentPage, searchTerm, perPage, ordenation, type }),
    );
  }, [dispatch, currentPage, searchTerm, perPage, ordenation, type]);

  useEffect(() => {
    if (status) {
      setRequestStatusType(status);
      dispatch(
        getListArticles({ currentPage, searchTerm, perPage, ordenation }),
      );
    }
    statusMethod();
  }, [
    status,
    currentPage,
    searchTerm,
    dispatch,
    statusMethod,
    perPage,
    ordenation,
  ]);

  function handleChange() {
    if (article.published)
      setRedirectModalContent(
        <RedirectArticleModalForm
          setModalVisibility={setRedirectModalContent}
          advanceFunction={() => setSelectedSlug(article.slug)}
        />,
      );

    dispatch(setPublishedModal(false));

    const formData = new FormData();

    formData.append('name', article.name);
    formData.append('description', article.description);
    formData.append('published', !article.published);
    formData.append('platforms', JSON.stringify(article.platforms));
    formData.append('pub', true);

    dispatch(
      updatePublishedArticle({
        info: formData,
        id: article.id,
        goTo: currentPage,
        edit: true,
      }),
    );
  }

  const optionsOrdenation = [
    { value: 'id', label: 'ID' },
    { value: 'name', label: 'Título' },
    { value: 'slug', label: 'Slug' },
    { value: 'platforms', label: 'Plataforma' },
  ];

  return (
    <>
      <ModalDeleteConfirmationToggle
        title={intl.messages['textModal.modalToggleTitle']}
        text={`Ao pressionar salvar, o status de publicação do artigo  será alterado. Digite o slug '${selectedSlug}' para confirmar`}
        isOpen={publishedModal}
        placeholder={intl.messages['labelsInputs.enterSlug']}
        setIsOpen={(value) => dispatch(setPublishedModal(value))}
        buttonNameBack={intl.messages['buttons.not']}
        buttonName={intl.messages['buttons.toSave']}
        confirmOnClick={() => handleChange()}
        fieldValue={selectedSlug}
        fieldLabel={selectedSlug}
      />
      <Modal
        title="Redirecionamento SAMP"
        setIsOpen={setRedirectModalContent}
        isOpen={redirectModalContent}
        content={redirectModalContent}
      />
      <ModalDeleteConfirmation
        title={intl.messages['textModal.areYouSure']}
        text={intl.messages['textModal.articleDeleteConfirm']}
        placeholder={intl.messages['labelsInputs.enterSlug']}
        buttonName={intl.messages['buttons.delete']}
        setIsOpen={setShowDeleteModal}
        isOpen={showDeleteModal}
        loading={isLoading}
        fieldValue={selectedSlug}
        fieldLabel={selectedSlug}
        confirmOnClick={() => {
          setShowDeleteModal(false);
          setRedirectModalContent(
            <RedirectArticleModalForm
              setModalVisibility={setRedirectModalContent}
              id={selectedId}
              slug={selectedSlug}
              advanceFunction={() => {
                dispatch(deleteArticle(selectedId));
              }}
            />,
          );
        }}
        textFieldLabel={intl.messages['labelsInputs.enterSlug']}
        tooltipText={
          getPublishArticlePermission(attributes) === false
            ? 'Você não possui permissão para publicar artigos'
            : 'É importante verificar se será necessário solicitar a inclusão ou exclusão de redirects'
        }
      />
      <ModalStatus
        status={responseStatus}
        setIsOpen={setShowStatusModal}
        isOpen={showStatusModal}
        text={confirmationMessage}
      />

      <PageHeader
        title="Conteúdos de SEO"
        onClickAdd={addArticle}
        onClickFilter={() => {}}
        search
        searchFunc={(searchData) => setSearchTerm(searchData)}
        placeholder="Busque por Título"
        optionsOrdenation={optionsOrdenation}
        ordenation={ordenation}
        setOrdenation={setOrdenation}
        trash={getPermission('articles', permissions, 'list')}
        trashRedirect={() => history.push(urls.ROUTES.ARTICLES_DELETED.path)}
        customSelect={{
          options: contentTypeOptions,
          defaultOption: type,
          handleChange: setType,
          placeholder: 'Tipo de conteúdo',
        }}
        actions={
          !getPermission('articles', permissions, 'create')
            ? []
            : [
                {
                  name: 'Novo Conteúdo',
                  onClick: addArticle,
                  btnType: 'primary',
                  icon: addIcon,
                },
              ]
        }
      />

      <PageContent>
        <EmptySearch items={data} term={searchTerm}>
          <TableDefault
            columns={COLUMNS}
            data={data}
            pageCount={totalPage}
            defaultCurrentPage={currentPage}
            setPageCurrent={setCurrentPage}
            totalPerPage={totalPerPage}
            setTotalPerPage={setTotalPerPage}
          />
        </EmptySearch>
      </PageContent>
    </>
  );
};

export default Articles;
