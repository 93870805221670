import styled, { css } from 'styled-components';
import { colors } from 'styles/tokens';

export const Container = styled.div`
  ${({ theme, zIndex }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: ${({ width }) => (width ? `${width}` : '100%')};
    padding: ${({ padding }) => (padding ? `${padding}` : '10px 0')};
    margin: ${({ margin }) => (margin ? `${margin}` : '0')};
    position: relative;
    z-index: ${zIndex || '1'};

    .css-1wa3eu0-placeholder {
      font-size: ${theme.typography.sizes.sm};
      line-height: 17px;
    }

    .css-1uccc91-singleValue {
      font-size: ${theme.typography.sizes.sm};
      line-height: 17px;
    }

    .css-1htoicw-menu {
      font-size: ${theme.typography.sizes.sm};
      line-height: 17px;
    }

    .css-g1d714-ValueContainer {
      padding: 2px 10px;
    }
    .css-2b097c-container {
      border: none;
      border-radius: 4px;
      ${({ isError }) =>
        isError && `border: solid 1px ${theme.colors.contextual.error.default}`}
    }

    .css-1dgy6fl-control {
      min-height: ${({ minHeight }) => (minHeight ? `${minHeight}` : '48px')};
    }

    .css-432gg-control {
      padding: ${({ paddingControl }) => (paddingControl ? '0px' : '5px')};
      min-height: ${({ minHeight }) => (minHeight ? `${minHeight}` : '48px')};
    }
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.colors.secondary.dark};
    font-weight: 400;
    font-size: ${theme.typography.sizes.sm};
    line-height: 17px;

    ${({ isError }) =>
      isError && `color: ${theme.colors.contextual.error.default}`}

    ${({ secondary }) =>
      secondary &&
      css`
        font-family: 'Montserrat';
      `};
  `}
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 4px;
  img {
    padding-left: 5px;
    cursor: pointer;
  }
`;

export const OptionalStyle = styled.p`
  ${({ theme }) => css`
    font-weight: 400;
    font-size: ${theme.typography.sizes.sm};
    line-height: 17px;
    align-items: center;
    color: ${theme.colors.black.light};
    padding-left: 5px;
    padding-bottom: 2px;
  `}
`;

export const selectStyleError = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: 'none',
    border: `solid 1px ${colors.contextual.error.default}`,
    minHeight: '48px',
    padding: '5px',
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    backgroundColor: state.isDisabled
      ? colors.background.float.solid
      : colors.gray.lighter,
  }),
  menu: (provided) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused && 'lightgray',
    color: state.isFocused && colors.secondary.default,
    cursor: 'pointer',
  }),
};

export const selectStyle = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: 'none',
    border: 'none',
    padding: '5px',
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    backgroundColor: state.isDisabled
      ? colors.background.float.solid
      : colors.gray.lighter,
  }),
  menu: (provided) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused && 'lightgray',
    color: state.isFocused && colors.secondary.default,
    cursor: 'pointer',
  }),
};

export const custom = (theme) => ({
  ...theme,
  borderRadius: 5,
  border: 0,
  boxShadow: 0,
  outline: 0,
  paddingRight: 5,
  colors: {
    ...theme.colors,
    primary25: colors.secondary.default,
    primary: colors.gray.dark,
    primary50: colors.gray.dark,
    neutral0: colors.gray.lighter,
    neutral5: colors.black.default,
    neutra20: colors.gray.lighter,
    neutra30: colors.gray.lighter,
    neutra40: colors.gray.lighter,
  },
});
