/**
 * Applies a string mask to a given value based on a pattern.
 * @param {string} value - The value to be masked.
 * @param {string} pattern - The pattern used for masking.
 * @returns {string} - The masked string.
 * @example
 * const maskedValue = stringMask('s0000b00y', 'LNNNNLNNL');
 * console.log(maskedValue); // Output: 'S0000B00Y'
 */
function stringMask(value, pattern) {
  let formatted = '';
  let valuePos = 0;

  for (let i = 0;i < pattern.length && valuePos < value.length;i += 1) {
    const patternChar = pattern[i];
    const valueChar = value[valuePos];

    if (patternChar === 'N') {
      if (/\d/.test(valueChar)) {
        formatted += valueChar;
      }
    }

    if (patternChar === 'L') {
      if (/[bgnryBGNRY]/.test(valueChar)) {
        formatted += valueChar.toUpperCase();
      }
    }
    valuePos += 1;
  }

  return formatted;
}

/**
 * Applies a specific mask to a given string using the 'stringMask' function
 * and formats it to a NSC format.
 * @param {string} string - The string to be formatted.
 * @returns {string} - The formatted string.
 * @example
 * const formattedString = nscMask('s0000b00y');
 * console.log(formattedString); // Output: 'S 0000-B00Y'
 */
export const nscMask = (string) => {
  return string.length > 6
    ? stringMask(string.replace(' ', '').replace('-', ''), 'NNNNLNNL').replace(
      /^(\d{4})(\w)(\d{2})(\w)$/,
      '$1-$2$3$4',
    )
    : stringMask(string.replace(' ', '').replace('-', ''), 'NNNNL').replace(
      /^(\d{4})(\w)$/,
      '$1-$2',
    );
};

export const ncsRegex = /^\d{4}-[BGNRY]\d{2}[BGNRY]$|^\d{4}-[BGNRY]$/g;

export const callToTestNCS = (string) => string.length >= 6
