import styled, { css } from 'styled-components';
import ArrowDownIcon from 'assets/icons/arrow-down.svg';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15px;
`;

export const SelectWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    align-items: center;
    border: none;
    border-radius: 5px;
    background: ${theme.colors.gray.lighter};

    ${({ isError }) =>
      isError && `border: 1px solid ${theme.colors.contextual.error.default}`}
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.colors.secondary.dark};
    font-weight: 400;
    font-size: ${theme.typography.sizes.sm};
    line-height: 17px;
    margin-bottom: 0;

    ${({ isError }) =>
      isError && `color: ${theme.colors.contextual.error.default}`}
  `}
`;

export const SelectTag = styled.select`
  ${({ theme }) => css`
    border: none;
    border-radius: 5px;
    color: ${({ selected }) =>
      selected
        ? `${theme.colors.secondary.default}`
        : `${theme.colors.gray.dark}`};
    padding: 0.67em 3em 0.67em 1em;
    font-size: ${theme.typography.sizes.sm};
    line-height: 17px;
    width: 100%;
    height: 48px;
    background: ${theme.colors.gray.lighter} url(${ArrowDownIcon}) no-repeat
      right 16px center/20px 22px;
    outline: none;
    position: relative;
    z-index: 1;
    cursor: pointer;
    box-shadow: inset 0 0 0 0 transparent;
    transition: color 0.1s ease, border-color 0.1s ease;
    appearance: none;

    ${({ isError }) =>
      isError && `border: 1px solid ${theme.colors.contextual.error.default}`}

    option {
      color: ${theme.colors.secondary.default};
      font-size: ${theme.typography.sizes.sm};
      line-height: 17px;
    }
  `}
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 4px;
  img {
    padding-left: 5px;
    cursor: pointer;
  }
`;

export const OptionalStyle = styled.p`
  ${({ theme }) => css`
    font-weight: 400;
    font-size: ${theme.typography.sizes.sm};
    line-height: 17px;
    align-items: center;
    color: ${theme.colors.black.light};
    padding-left: 5px;
  `}
`;
