/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { FaChevronDown } from 'react-icons/fa';
import Tooltip from 'assets/icons/tooltip-icon.svg';
import TooltipComponent from 'components/structure/Tooltip/Tooltip';
import api from 'services/api';
import { COLLECTIONS, PRODUCTS } from 'constants/endpoints';
import SpanError from 'components/structure/SpanError/SpanError';

import {
  selectStyle,
  custom,
  Container,
  Label,
  LabelContainer,
} from './AsyncSelectPaginate.styles';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaChevronDown />
    </components.DropdownIndicator>
  );
};

const AsyncSelectPaginateSEOContent = ({
  seoType,
  title,
  label,
  selectedValue,
  name,
  placeholder,
  onChange,
  selectFieldValue,
  disabled,
  error,
  multi,
}) => {
  const [currentOptions, setCurrentOptions] = useState({
    page: 1,
    searchQuery: null,
  });

  const loadOptionsData = async (searchQuery, loadedOptions, { page }) => {
    const resource = seoType === 'pdp' ? PRODUCTS : COLLECTIONS;
    const { data } = await api.get(
      searchQuery === ''
        ? `${resource}?perPage=10&page=${page}&sort.field=name&sort.order=ASC`
        : `${resource}?perPage=10&page=${page}&sort.field=name&sort.order=ASC&name=${searchQuery}`,
    );

    setCurrentOptions({ page, searchQuery });

    return {
      options: data.items !== undefined ? data.items : [],
      hasMore: data.totalPage !== undefined ? data.totalPage > page + 1 : 0,
      additional: {
        page: searchQuery === currentOptions.searchQuery ? page + 1 : page + 1,
      },
    };
  };

  return (
    <Container isError={!!error}>
      <LabelContainer>
        <Label isError={!!error}>{label}</Label>

        <TooltipComponent id="tooltipImage" title={title} multiline />
      </LabelContainer>
      {multi ? (
        <AsyncPaginate
          name={name}
          placeholder={placeholder}
          value={selectedValue || ''}
          loadOptions={loadOptionsData}
          getOptionValue={(option) => option[selectFieldValue]}
          getOptionLabel={(option) => option.name}
          onChange={onChange}
          isSearchable
          additional={{
            page: 1,
          }}
          isMulti
          isDisabled={disabled}
          styles={selectStyle}
          components={{ IndicatorSeparator: null, DropdownIndicator }}
          theme={custom}
          noOptionsMessage={({ inputValue }) =>
            !inputValue ? '' : 'Item não encontrado'
          }
          loadingMessage={() => 'Carregando...'}
        />
      ) : (
        <AsyncPaginate
          name={name}
          placeholder={placeholder}
          value={selectedValue || ''}
          loadOptions={loadOptionsData}
          getOptionValue={(option) => option[selectFieldValue]}
          getOptionLabel={(option) => option.name}
          onChange={onChange}
          isSearchable
          additional={{
            page: 1,
          }}
          isDisabled={disabled}
          styles={selectStyle}
          components={{ IndicatorSeparator: null, DropdownIndicator }}
          theme={custom}
          noOptionsMessage={({ inputValue }) =>
            !inputValue ? '' : 'Item não encontrado'
          }
          loadingMessage={() => 'Carregando...'}
        />
      )}
      {!!error && <SpanError>{error}</SpanError>}
    </Container>
  );
};

AsyncSelectPaginateSEOContent.propTypes = {
  seoType: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  multi: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  selectedValue: PropTypes.object,
  error: PropTypes.string,
  selectFieldValue: PropTypes.string,
};

AsyncSelectPaginateSEOContent.defaultProps = {
  name: '',
  label: '',
  placeholder: '',
  multi: false,
  onChange: () => {},
  disabled: false,
  title: '',
  selectedValue: {},
  error: '',
  selectFieldValue: 'id',
  seoType: 'pdp',
};

export default AsyncSelectPaginateSEOContent;
