import styled, { css } from 'styled-components';

export const Container = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.contextual.error.default};
    font-size: 14px;
    padding-top: 5px;
    margin: 0;
    text-align: end;
    line-height: normal;
  `}
`;
