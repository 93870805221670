import styled from 'styled-components';

export const Color = styled.div`
  display: block;
  align-items: center;
  border: 1px solid;
  border-color: ${({ rgbCode }) =>
    rgbCode === '255,255,255' ? '#ccc' : rgbCode};
  background-color: ${({ rgbCode }) => rgbCode};
  width: 60%;
  height: 50px;
  padding: 15px 12px;
  top: 0;
  font-weight: 400;
  font-size: 0.85rem;
  line-height: 16px;
  color: ${({ colorText }) => colorText};
`;
