import Button from 'components/structure/Button/Button';
import PageContent from 'components/structure/PageContent/PageContent';
import PageHeader from 'components/structure/PageHeader/PageHeader';
import Popover from 'components/structure/PopOver/PopOver';
import {
  PopItem,
  PopOverContent,
} from 'components/structure/PopOver/PopOver.styles';
import TableDefault from 'components/structure/Table/Table';
import urls from 'constants/urls';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getPermission } from 'utils/permissions';
import EmptySearch from '../../components/structure/EmptySearch/EmptySearch';
import {
  getDeletedProductDetail,
  getDeletedProductsList,
  putProduct,
  setProductActiveStep,
  setProductId,
} from 'store/products/products-slice';

const DeletedProducts = () => {
  const { permissions } = useSelector((state) => state.auth);

  const [searchTerm, setSearchTerm] = useState('');
  const [ordenation, setOrdenation] = useState('');
  const [page, setPage] = useState(1);
  const [totalPerPage, setTotalPerPage] = useState({ value: 10 });
  const perPage = totalPerPage.value;

  const [selectedId, setSelectedId] = useState(null);

  const { items, totalPage } = useSelector((state) => state.products.products);

  const optionsOrdenation = [
    { value: 'name', label: 'Nome' },
    { value: 'platforms', label: 'Plataforma' },
    { value: 'department', label: 'Departamento' },
    { value: 'deletedAt', label: 'Data e hora' },
  ];

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getDeletedProductsList({ page, searchTerm, perPage, ordenation }));
  }, [dispatch, page, searchTerm, perPage, ordenation]);

  useEffect(() => {
    if (getPermission('products', permissions, 'delete') === false) {
      history.push('/');
    }
  }, [history, permissions]);

  const viewDeletedProduct = useCallback(
    (id) => {
      dispatch(getDeletedProductDetail(parseInt(id, 10)));
      setSelectedId(id);
      dispatch(setProductId(id));
      history.push(urls.ROUTES.PRODUCTS_VIEW.path);
      dispatch(setProductActiveStep(0));
    },
    [dispatch, history],
  );

  const restoreProduct = useCallback(
    (id) => {
      const formData = new FormData();
      formData.append('restore', true);
      dispatch(putProduct({ id, info: formData }));
    },
    [dispatch],
  );
  const COLUMNS = useMemo(
    () => [
      { Header: 'ID', accessor: 'id', sticky: 'left' },
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Plataforma',
        accessor: ({ platforms }) => platforms[0],
      },
      {
        Header: 'Departamento',
        accessor: ({ categories }) => categories[0]?.name,
      },
      {
        Header: 'Removido em',
        accessor: ({ deletedAt }) => new Date(deletedAt).toLocaleString(),
      },
      {
        Header: ' ',
        accessor: ({ id }) => (
          <Popover
            content={
              <PopOverContent>
                <PopItem onClick={() => restoreProduct(id)}>Restaurar</PopItem>
                <PopItem onClick={() => viewDeletedProduct(id)}>
                  Ver detalhes
                </PopItem>
              </PopOverContent>
            }
          />
        ),
        sticky: 'right',
      },
    ],
    [],
  );

  const data = useMemo(() => items, [items]);
  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  return (
    <div>
      <PageHeader
        title="Produtos removidos"
        onClickFilter={() => {}}
        search
        searchFunc={(searchData) => setSearchTerm(searchData)}
        placeholder="Buscar por ID"
        optionsOrdenation={optionsOrdenation}
        ordenation={ordenation}
        setOrdenation={setOrdenation}
        trash={false}
      />

      <PageContent>
        <EmptySearch items={data} term={searchTerm}>
          <TableDefault
            columns={COLUMNS}
            data={data}
            pageCount={totalPage}
            defaultCurrentPage={page}
            setPageCurrent={setPage}
            totalPerPage={totalPerPage}
            setTotalPerPage={setTotalPerPage}
          />
        </EmptySearch>
        <Button
          className="backbutton"
          btnType="blue"
          width="20%"
          fontWeight="600"
          onClick={() =>
            history.push(urls.ROUTES.CATALOGUE.items.products.path)
          }
        >
          VOLTAR
        </Button>
      </PageContent>
    </div>
  );
};
export default DeletedProducts;
