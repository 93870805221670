import styled, { css } from 'styled-components';

export const FileContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin: 4px 0px;
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 4px;

  img {
    padding-left: 5px;
    cursor: pointer;
  }
`;
export const OptionalStyle = styled.p`
  ${({ theme }) => css`
    font-weight: 400;
    font-size: ${theme.typography.sizes.sm};
    line-height: 17px;
    align-items: center;
    color: ${theme.colors.black.light};
    padding-left: 5px;
  `}
`;

export const Label = styled.p`
  ${({ theme }) => css`
    font-weight: 400;
    font-size: ${theme.typography.sizes.sm};
    line-height: 17px;
    align-items: center;
    color: ${theme.colors.secondary.default};
    ${({ isError }) =>
      isError && `color: ${theme.colors.contextual.error.default}`}
  `}
`;

export const FileWrapper = styled.label`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: 0.67rem;
`;

export const Text = styled.p`
  ${({ theme, disabled }) => css`
    color: ${() => (disabled ? '#BABABA' : theme.colors.primary.default)};
    font-weight: 600;
    font-size: ${theme.typography.sizes.sm};
    line-height: 17px;
    text-transform: uppercase;
    margin: 0;
  `}
`;

export const Icon = styled.img`
  margin-right: 0;
`;

export const InputTag = styled.input`
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;

  ::-webkit-file-upload-button {
    cursor: pointer;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `};
`;

export const FileNameTitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.secondary.default};
    font-weight: 400;
    font-size: ${theme.typography.sizes.sm};
    line-height: 17px;
    word-break: break-all;

    width: 100%;
  `}
`;

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3f3f3;
  padding: 16px 16px;
  border-radius: 4px;

  ${({ theme }) => css`
    img {
      width: ${theme.typography.sizes.md};
      margin: 0px 8px;
    }
  `}
`;

export const LinkItem = styled.a`
  ${({ theme }) => css`
    text-decoration: none;
    color: ${theme.colors.black.light};
    cursor: pointer;

    width: 10%;

    img {
      width: ${theme.typography.sizes.md};
    }
  `}
`;
