/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { USERS } from 'constants/endpoints';
import { getUserData } from 'lib/contexts/auth';

import api from 'services/api';
import { startLoading, stopLoading } from 'store/loading/loading-slice';
import {
  setModalResetPassword,
  setModalStatus,
  setModalStatusMsg,
  statusModalStatus,
} from 'store/modals/modals-slice';

import { errorSessionToast, errorToast, successToast } from 'utils/toasts';

const INITIAL_STATE = {
  users: {
    totalPage: 1,
    totalItems: 0,
    items: [],
  },
  user: {
    name: '',
    email: '',
    role: '',
  },
  selectedUser: 0,
  openStatusModal: false,
  status: '',
  msg: {
    title: '',
    text: '',
  },
  openModalCreate: false,
  openModalEdit: false,
  openModalDelete: false,
};

const usersSlice = createSlice({
  name: 'users',
  initialState: INITIAL_STATE,
  reducers: {
    getUsersSuccess: (state, { payload }) => {
      state.users = payload;
    },
    getUserSuccess: (state, { payload }) => {
      state.user = payload;
    },
    statusUsersModal: (state, { payload }) => {
      state.openStatusModal = payload;
    },
    setUsersStatus: (state, { payload }) => {
      state.status = payload;
    },
    setMsg: (state, { payload }) => {
      state.msg = payload;
    },
    setSelectUser: (state, { payload }) => {
      state.selectedUser = payload;
    },
    openUserModalCreate: (state, { payload }) => {
      state.openModalCreate = payload;
    },
    openUserModalEdit: (state, { payload }) => {
      state.openModalEdit = payload;
    },
    openUserModalDelete: (state, { payload }) => {
      state.openModalDelete = payload;
    },
    getDeletedUsersSuccess: (state, { payload }) => {
      state.users = payload;
    },
    updateForgotPasswordSuccess: (state, { payload }) => {
      state.status = payload;
    },
  },
});

export const users = usersSlice.reducer;
export const {
  getDeletedUsersSuccess,
  getUserSuccess,
  getUsersSuccess,
  openUserModalCreate,
  openUserModalDelete,
  openUserModalEdit,
  setMsg,
  setSelectUser,
  setUsersStatus,
  statusUsersModal,
  updateForgotPasswordSuccess,
} = usersSlice.actions;

export const getUsersList = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { currentPage, searchTerm, perPage, ordenation } = payload;
  try {
    let url = '';
    if (searchTerm === '') {
      url = `${USERS}?perPage=${
        perPage || '10'
      }&page=${currentPage}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}`;
    } else {
      url = `${USERS}?perPage=${
        perPage || '10'
      }&page=${currentPage}&name=${searchTerm}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}`;
    }
    const response = await api.get(url);

    dispatch(getUsersSuccess(response.data));
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getDeletedUsersList = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { page, searchTerm, perPage, ordenation } = payload;
  try {
    let url = '';
    if (searchTerm === '') {
      url = `${USERS}?perPage=${perPage || '10'}&page=${page}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}&trash=true`;
    } else {
      url = `${USERS}?perPage=${
        perPage || '10'
      }&page=${page}&name=${searchTerm}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}&trash=true`;
    }
    const response = await api.get(url);

    dispatch(getDeletedUsersSuccess(response.data));
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getUserDetail = (payload) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await api.get(`${USERS}/${payload}`);

    dispatch(getUserSuccess(response.data));

    dispatch(stopLoading());

    dispatch(openUserModalEdit(true));
  } catch (error) {
    errorToast(error);
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getDeletedUserDetail = (payload) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await api.get(`${USERS}/${payload}?trash=true`);
    dispatch(getUserSuccess(response.data));

    dispatch(stopLoading());
    dispatch(openUserModalEdit(true));
  } catch (error) {
    errorToast(error);
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const saveUser = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { userData, searchTerm, currentPage } = payload;
  try {
    await api.post(USERS, userData);
    dispatch(stopLoading());

    dispatch(getUsersList({ currentPage, searchTerm }));

    dispatch(openUserModalCreate(false));
    successToast('Usuário salvo com sucesso!');
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Erros de Validação',
            text: 'Verifique os campos e tente novamente!',
          };

          dispatch(setMsg(msg));
          dispatch(setUsersStatus('error'));
          dispatch(statusUsersModal(true));
          break;
        case 409:
          msg = {
            title: 'Usuário já existente',
            text: '',
          };
          dispatch(setMsg(msg));
          dispatch(setUsersStatus('error'));
          dispatch(statusUsersModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const removeUser = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { id, page, searchTerm } = payload;
  try {
    await api.delete(`${USERS}/${id}`);
    const msg = { title: 'Usuário removido com sucesso!', text: '' };
    dispatch(setMsg(msg));

    dispatch(openUserModalDelete(false));
    dispatch(stopLoading());
    dispatch(setUsersStatus('success'));
    dispatch(statusUsersModal(true));

    dispatch(getUsersList({ currentPage: page, searchTerm }));
  } catch (error) {
    dispatch(stopLoading());

    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          dispatch(setUsersStatus('error'));
          dispatch(statusUsersModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const editUser = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { userData, userId, currentPage, searchTerm } = payload;
  try {
    await api.patch(`${USERS}/${userId}`, userData);
    dispatch(stopLoading());
    dispatch(getUsersList({ currentPage, searchTerm }));
    successToast('Usuário alterado com sucesso!');
    dispatch(openUserModalEdit(false));
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Falha no Processamento!',
            text: 'Não foi possível alterar o usuário, tente novamente!',
          };
          dispatch(setMsg(msg));
          dispatch(setUsersStatus('error'));
          dispatch(statusUsersModal(true));
          break;
        case 409:
          msg = {
            title: 'Usuário já existente',
            text: '',
          };
          dispatch(setMsg(msg));
          dispatch(setUsersStatus('error'));
          dispatch(statusUsersModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const resetPasswordUser = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { resetPasswordData } = payload;
  const { email } = getUserData();

  try {
    const response = await api.get(`${USERS}?email=${email}`);
    const { items } = response.data;

    await api.patch(`${USERS}/${items[0].id}`, resetPasswordData);
    const msg = { title: 'Senha alterada com sucesso!', text: '' };

    dispatch(setModalResetPassword({ openModal: false }));

    dispatch(setModalStatusMsg(msg));
    dispatch(setModalStatus('success'));
    dispatch(statusModalStatus(true));
    dispatch(stopLoading());
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Falha no Processamento!',
            text: 'Não foi possível alterar a senha, tente novamente!',
          };
          dispatch(setModalStatusMsg(msg));
          dispatch(setModalStatus('error'));
          dispatch(statusModalStatus(true));
          break;
        case 409:
          msg = {
            title: 'Senha já existente',
            text: '',
          };
          dispatch(setModalStatusMsg(msg));
          dispatch(setModalStatus('error'));
          dispatch(statusModalStatus(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const resetForgottenPassword = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { resetPasswordData, id } = payload;

  try {
    await api.patch(`${USERS}/${id}/password`, resetPasswordData);

    dispatch(updateForgotPasswordSuccess('success'));
    dispatch(stopLoading());
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Falha no Processamento!',
            text: 'Não foi possível alterar a senha, tente novamente!',
          };

          dispatch(updateForgotPasswordSuccess('error'));
          break;
        case 409:
          msg = {
            title: 'Senha já existente',
            text: '',
          };
          dispatch(updateForgotPasswordSuccess('error'));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};
