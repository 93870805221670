import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import InputText from 'components/form/Text/TextInput/InputText';
import Grid from '@material-ui/core/Grid';
import Button from 'components/structure/Button/Button';
import Modal from 'components/structure/Modal/Modal';
import { saveRoleName as createRoleName } from 'store/roles/roles-slice';
import confirmIcon from 'assets/icons/check-gray.svg';
import {
  Container,
  Title,
  ButtonsContainer,
} from './AddRoleNameModalForm.styles';

const AddRoleNameModalForm = ({ cancelActionModal, createActionModal }) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState({
    type: '',
    message: '',
  });

  const intl = useIntl();
  const dispatch = useDispatch();

  function validate() {
    if (!name)
      return setNameError({
        type: 'error',
        message: 'Nome do perfil não preenchido',
      });

    return true;
  }

  const saveRoleName = () => {
    const roleNameData = {
      name,
    };
    createActionModal();
    dispatch(createRoleName({ info: roleNameData }));
  };

  const addRoleModalConfirmationForm = (
    <Container>
      <img src={confirmIcon} alt="" />
      <Title>{intl.messages['textModal.roleNameCreateConfirm']}</Title>
      <ButtonsContainer>
        <Button
          type="button"
          btnType="blue"
          width="100%"
          height="40px"
          borderRadius="2px"
          uppercase
          onClick={() => setShowAddModal(false)}
          fontWeight="600"
        >
          VOLTAR
        </Button>

        <Button
          type="button"
          btnType="primary"
          width="100%"
          height="40px"
          borderRadius="2px"
          uppercase
          fontWeight="600"
          onClick={saveRoleName}
        >
          {intl.messages['buttons.yes']}
        </Button>
      </ButtonsContainer>
    </Container>
  );

  return (
    <>
      <Grid container spacing={8}>
        <Grid item xs={12} md={12}>
          <Grid container>
            <Grid item xs={12}>
              <InputText
                placeholder={intl.messages['labelsInputs.typeYourName']}
                label={intl.messages['labelsInputs.typeYourName']}
                value={name}
                handleChange={setName}
                error={nameError.type === 'error' ? nameError.message : ''}
                isError={nameError.type && name === ''}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ paddingTop: '40px' }}>
        <Grid item md={6} xs={12}>
          <Button
            type="button"
            btnType="secondary"
            width="100%"
            height="40px"
            borderRadius="2px"
            uppercase
            onClick={cancelActionModal}
            fontWeight="600"
          >
            {intl.messages['buttons.cancel']}
          </Button>
        </Grid>
        <Grid item md={6} xs={12}>
          <Button
            type="button"
            btnType="primary"
            width="100%"
            height="40px"
            borderRadius="2px"
            uppercase
            fontWeight="600"
            onClick={() => {
              if (!validate()) return;
              saveRoleName();
            }}
          >
            {intl.messages['buttons.create']}
          </Button>
        </Grid>
      </Grid>

      <Modal
        setIsOpen={setShowAddModal}
        isOpen={showAddModal}
        content={addRoleModalConfirmationForm}
        containerWidth="100%"
        containerHeight="100%"
        width="100%"
        height="100%"
        paddingRolesModal
      />
    </>
  );
};

AddRoleNameModalForm.propTypes = {
  cancelActionModal: PropTypes.func,
  createActionModal: PropTypes.func,
};

AddRoleNameModalForm.defaultProps = {
  cancelActionModal: () => {},
  createActionModal: () => {},
};

export default AddRoleNameModalForm;
