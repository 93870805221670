import React from 'react';
import PropTypes from 'prop-types';
import { Container, Label, ContainerInput } from './Toggle.styles';

const Toggle = ({
  name,
  label,
  checked,
  disabled,
  onChange,
  paddingTop,
  paddingBottom,
  regular,
  large,
  ...rest
}) => (
  <Container paddingTop={paddingTop} paddingBottom={paddingBottom}>
    <Label htmlFor={name}>{label}</Label>
    <ContainerInput regular={regular} large={large}>
      <input
        name={name}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={(e) => {
          onChange(e);
        }}
        {...rest}
      />
      <span className="slider round" />
    </ContainerInput>
  </Container>
);

Toggle.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
  regular: PropTypes.bool,
  large: PropTypes.bool,
};

Toggle.defaultProps = {
  name: '',
  label: '',
  checked: false,
  disabled: false,
  onChange: () => {},
  paddingTop: '',
  paddingBottom: '',
  regular: false,
  large: false,
};

export default Toggle;
