import addIcon from 'assets/icons/add.svg';
import resetIcon from 'assets/icons/close-search.svg';
import filterIcon from 'assets/icons/filter.svg';
import trashIcon from 'assets/icons/outlinedtrash.svg';
import SearchIcon from 'assets/icons/search-icon.svg';
import SelectSearch from 'components/form/Select/ReactSelect';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import MultiActionButton from '../../form/MultiActionButton';
import Button from '../ActionButton/ActionButton';
import {
  AddButton,
  Container,
  ContainerOptions,
  ContainerSearch,
  ContainerTitle,
  Content,
  FilterButton,
  InputContainer,
  ResetButton,
  SearchButton,
  SearchInput,
  Title,
} from './PageHeader.styles';
import { preventRedirectDisabled } from 'store/sidebar/sidebar-slice';

const ContentHeader = ({
  title,
  actions,
  onClickFilter,
  filterBtn,
  search,
  searchFunc,
  placeholder,
  optionsOrdenation,
  ordenation,
  setOrdenation,
  multiActionButton,
  trashRedirect,
  trash,
  secondaryMultiAction,
  customSelect,
}) => {
  const [searchData, setSearchData] = useState('');
  const [enableReset, setEnableReset] = useState(false);

  const handleSearch = () => {
    searchFunc(searchData);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(preventRedirectDisabled());
  }, [dispatch]);

  useEffect(() => {
    if (searchData !== '') {
      setEnableReset(true);
    } else {
      setEnableReset(false);
    }
  }, [searchData]);

  const handleResetSearch = () => {
    setSearchData('');
    searchFunc('');
  };

  return (
    <Container>
      <ContainerTitle>
        <Title>{title}</Title>
        <Content>
          {filterBtn && (
            <FilterButton
              icon={filterIcon}
              btnType="primary"
              borderRadius="8px"
              height="34px"
              onClick={onClickFilter}
            >
              <FormattedMessage id="filter" />
            </FilterButton>
          )}
          {trash && trashRedirect && (
            <Button
              btnType="tertiary"
              icon={trashIcon}
              onClick={() => {
                trashRedirect();
              }}
            />
          )}
          {actions.map((action) => {
            return (
              <AddButton
                icon={action.icon}
                btnType={action.btnType}
                borderRadius="4px"
                width="100%"
                height="34px"
                onClick={action.onClick}
              >
                {action.name}
              </AddButton>
            );
          })}
          {multiActionButton && (
            <MultiActionButton
              primaryLabel={multiActionButton?.primary?.label}
              secondaryLabel={multiActionButton?.secondary?.label}
              primaryfunction={multiActionButton?.primary?.function}
              secondaryFunction={multiActionButton?.secondary?.function}
            />
          )}
        </Content>
      </ContainerTitle>

      {search && (
        <ContainerOptions>
          <ContainerSearch>
            <InputContainer>
              <SearchInput
                placeholder={placeholder}
                value={searchData}
                onChange={(e) => setSearchData(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
              />
              {enableReset && (
                <ResetButton onClick={() => handleResetSearch()}>
                  <img src={resetIcon} alt="" />
                </ResetButton>
              )}
            </InputContainer>
            <SearchButton
              btnType="primary"
              borderRadius="8px"
              onClick={() => handleSearch()}
            >
              <img src={SearchIcon} alt="search-icon" />
            </SearchButton>
            {!!customSelect && (
              <SelectSearch
                options={customSelect.options}
                placeholder={customSelect.placeholder}
                defaultOption={customSelect.defaultOption}
                handleChange={customSelect.handleChange}
                multi={false}
                width="46%"
                padding="0px"
                margin="0 16px 0 0"
                paddingControl
              />
            )}
            <SelectSearch
              options={optionsOrdenation}
              placeholder="Ordenar por"
              defaultOption={ordenation}
              handleChange={setOrdenation}
              multi={false}
              width="46%"
              padding="0px"
              paddingControl
            />
          </ContainerSearch>
          {secondaryMultiAction !== null && (
            <MultiActionButton
              primaryLabel={secondaryMultiAction?.primary?.label}
              secondaryLabel={secondaryMultiAction?.secondary?.label}
              primaryfunction={secondaryMultiAction?.primary?.function}
              secondaryFunction={secondaryMultiAction?.secondary?.function}
              tertiaryLabel={secondaryMultiAction?.tertiary?.label}
              tertiaryFunction={secondaryMultiAction?.tertiary?.function}
            />
          )}
        </ContainerOptions>
      )}
    </Container>
  );
};
export default ContentHeader;

ContentHeader.propTypes = {
  title: PropTypes.node,
  actions: PropTypes.array,
  onClickFilter: PropTypes.func,
  filterBtn: PropTypes.bool,
  search: PropTypes.bool,
  searchFunc: PropTypes.func,
  placeholder: PropTypes.string,
  optionsOrdenation: PropTypes.array,
  ordenation: PropTypes.string,
  setOrdenation: PropTypes.func,
  multiActionButton: PropTypes.object,
  trash: PropTypes.bool,
  trashRedirect: PropTypes.func,
  secondaryMultiAction: PropTypes.object,
  customSelect: PropTypes.shape({
    options: PropTypes.array,
    placeholder: PropTypes.string,
    defaultOption: PropTypes.string,
    handleChange: PropTypes.func,
  }),
};

ContentHeader.defaultProps = {
  title: null,
  actions: [],
  onClickFilter: () => {},
  filterBtn: false,
  search: false,
  searchFunc: () => {},
  placeholder: 'Buscar',
  optionsOrdenation: [],
  ordenation: '',
  setOrdenation: () => {},
  multiActionButton: null,
  trashRedirect: () => {},
  trash: false,
  secondaryMultiAction: null,
  customSelect: null,
};
