import catalogueIcon from 'assets/icons/catalogue.svg';
import basesIcon from 'assets/icons/file-text.svg';
import homeIcon from 'assets/icons/home.svg';
import accessIcon from 'assets/icons/key.svg';
import logoutIcon from 'assets/icons/logout.svg';
import editorialsIcon from 'assets/icons/loudspeaker.svg';
import articlesIcon from 'assets/icons/paint-bucket.svg';
import productsIcon from 'assets/icons/products.svg';
import resetIcon from 'assets/icons/resetPassword.svg';
import tagsIcon from 'assets/icons/tag.svg';
import userIcon from 'assets/icons/user.svg';
import notificationIcon from 'assets/icons/notification.svg';

const urls = {
  ROUTES: {
    APP: {
      path: '/',
      name: 'Home',
      show: true,
      icon: homeIcon,
      submenu: false,
      alwaysView: true,
      key: '',
      items: {},
    },
    AUTH: {
      path: '/login',
      name: 'Login',
      submenu: false,
      items: {},
      alwaysView: false,
      key: '',
    },
    FORGOT_PASSWORD: {
      path: '/forgot-password',
      name: 'Esqueci minha senha',
      submenu: false,
      items: {},
      alwaysView: false,
      key: '',
    },
    RESET_PASSWORD: {
      path: '/reset-password',
      name: 'Reset de senha',
      submenu: false,
      items: {},
      alwaysView: false,
      key: '',
    },
    ACCESS: {
      path: '/admin/access',
      name: 'Acessos',
      show: true,
      icon: accessIcon,
      submenu: true,
      alwaysView: true,
      key: '',
      items: {
        users: {
          path: '/admin/access/users',
          label: 'Usuários',
          name: 'Acessos usuários',
          show: true,
          icon: userIcon,
          alwaysView: false,
          key: 'users',
        },
        roles: {
          path: '/admin/access/roles',
          label: 'Perfis',
          name: 'Acessos perfis',
          show: true,
          alwaysView: false,
          key: 'roles',
        },
        activitiesLogs: {
          path: '/admin/access/activities-logs',
          label: 'Atividades',
          name: 'Acessos atividades',
          show: true,
          alwaysView: false,
          key: 'logs',
        },
        logsByRecord: {
          path: '/admin/access/activities-logs/records',
          name: 'Acesso Logs por registro',
          label: 'Logs por registro',
          show: false,
          alwaysView: false,
        },
      },
    },
    ROLES_CREATE: {
      path: '/admin/access/roles/create',
      name: 'Acessos roles',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    ROLES_EDIT: {
      path: '/admin/access/roles/edit',
      name: 'Acessos roles',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    ROLES_VIEW: {
      path: '/admin/access/roles/view',
      name: 'Acessos roles',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    ROLES_DELETED: {
      path: '/admin/access/roles/deleted',
      name: 'Acessos roles deleted',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    USERS_DELETED: {
      path: '/admin/access/users/deleted',
      name: 'Acessos roles',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    // A opcao no menu referente ao Blog sofreu uma delecao logica podendo ser recuperada neste commit: 71ab283b42d4b965e7a0b37ad10fd33563a93f88
    EDITORIALS: {
      path: '/admin/editorias',
      name: 'Editorias',
      show: false,
      icon: editorialsIcon,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    EDITORIALS_CREATE: {
      path: '/admin/blog/editorials/create',
      name: 'Blog editorias',
      show: false,
      icon: editorialsIcon,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    EDITORIALS_EDIT: {
      path: '/admin/blog/editorials/edit',
      name: 'Blog editorias',
      show: false,
      icon: editorialsIcon,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    EDITORIALS_VIEW: {
      path: '/admin/blog/editorials/view',
      name: 'Blog editorias',
      show: false,
      icon: editorialsIcon,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    EDITORIALS_DELETED: {
      path: '/admin/blog/editorials/deleted',
      name: 'Blog editorias removidas',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    TAGS_DELETED: {
      path: '/admin/blog/tags/deleted',
      name: 'Blog tags removidas',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    ARTICLES: {
      path: '/admin/artigos',
      name: 'Conteúdos de SEO',
      show: false,
      icon: editorialsIcon,
      submenu: false,
      alwaysView: true,
      key: '',
      items: {},
    },
    ARTICLES_CREATE: {
      path: '/admin/blog/articles/create',
      name: 'Blog artigos',
      show: false,
      icon: articlesIcon,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    ARTICLES_VIEW: {
      path: '/admin/blog/articles/view',
      name: 'Blog artigos',
      show: false,
      icon: articlesIcon,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    ARTICLES_EDIT: {
      path: '/admin/blog/articles/edit',
      name: 'Blog artigos',
      show: false,
      icon: articlesIcon,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    ARTICLES_TEST: {
      path: '/admin/teste',
      name: 'Tags',
      show: false,
      icon: tagsIcon,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    ARTICLES_DELETED: {
      path: '/admin/blog/articles/deleted',
      name: 'Tags',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    CATALOGUE: {
      path: '/admin/catalogue',
      name: 'Catálogo',
      show: true,
      icon: catalogueIcon,
      submenu: true,
      alwaysView: true,
      key: '',
      items: {
        products: {
          path: '/admin/catalogue/products',
          label: 'Produtos',
          name: 'Catálogo produtos',
          show: true,
          icon: productsIcon,
          alwaysView: false,
          key: 'products',
        },
        bases: {
          path: '/admin/catalogue/bases',
          label: 'Bases',
          name: 'Catálogo Bases',
          show: true,
          icon: basesIcon,
          alwaysView: false,
          key: 'bases',
        },
        colours: {
          path: '/admin/catalogue/colours',
          label: 'Cores',
          name: 'Catálogo cores',
          show: true,
          icon: tagsIcon,
          alwaysView: false,
          key: 'colors',
        },
        colorGroups: {
          path: '/admin/catalogue/color-groups',
          label: 'Grupos de Cores',
          name: 'Catálogo grupo de cores',
          show: true,
          icon: tagsIcon,
          alwaysView: false,
          key: 'color-groups',
        },
        dryPackages: {
          path: '/admin/catalogue/dry-packages',
          label: 'Embalagens',
          name: 'Catálogo embalagens',
          show: true,
          icon: tagsIcon,
          alwaysView: false,
          key: 'products',
        },
        categories: {
          path: '/admin/catalogue/categories',
          label: 'Categorias',
          name: 'Catálogo categorias',
          show: true,
          icon: tagsIcon,
          alwaysView: false,
          key: 'categories',
        },
        properties: {
          path: '/admin/catalogue/properties',
          label: 'Propriedades',
          name: 'Catálogo propriedades',
          show: true,
          icon: tagsIcon,
          alwaysView: false,
          key: 'properties',
        },
        combinations: {
          path: '/admin/catalogue/color-combinations',
          label: 'Combinações',
          name: 'Combinações de cores',
          show: true,
          icon: tagsIcon,
          alwaysView: false,
          key: 'properties',
        },
        colorsByGroup: {
          path: '/admin/catalogue/color-group/color',
          name: 'Acesso cores por grupo',
          label: 'Acesso cores por grupo',
          show: false,
          alwaysView: false,
        },
        combinations_edit: {
          path: '/admin/catalogue/color-combinations/edit',
          name: 'Editar combinação',
          show: false,
          submenu: false,
          alwaysView: false,
          key: '',
          items: {},
        },
        combinations_deleted: {
          path: '/admin/catalogue/color-combinations/deleted',
          name: 'Combinações Removidas',
          show: false,
          submenu: false,
          alwaysView: false,
          key: '',
          items: {},
        },
      },
    },
    COLORS_CREATE: {
      path: '/admin/catalogue/colours/create',
      name: 'Catálogo cores',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    COLORS_EDIT: {
      path: '/admin/catalogue/colours/edit',
      name: 'Catálogo cores',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    COLORS_VIEW: {
      path: '/admin/catalogue/colours/view',
      name: 'Catálogo cores',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    COLORS_DELETED: {
      path: '/admin/catalogue/colours/deleted',
      name: 'Cores Deletadas',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    COLOR_GROUPS_CREATE: {
      path: '/admin/catalogue/color-groups/create',
      name: 'Catálogo grupo de cores',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    COLOR_GROUPS_EDIT: {
      path: '/admin/catalogue/color-groups/edit',
      name: 'Catálogo grupo de cores',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    COLOR_GROUPS_VIEW: {
      path: '/admin/catalogue/color-groups/view',
      name: 'Catálogo grupo de cores',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    COLOR_GROUPS_DELETED: {
      path: '/admin/catalogue/color-groups/deleted',
      name: 'Grupo de Cores Deletadas',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    DRY_PACKAGE_CREATE: {
      path: '/admin/catalogue/dry-packages/create',
      name: 'Catálogo embalagens',
      show: true,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    DRY_PACKAGE_EDIT: {
      path: '/admin/catalogue/dry-packages/edit',
      name: 'Catálogo embalagens',
      show: true,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    DRY_PACKAGE_VIEW: {
      path: '/admin/catalogue/dry-packages/view',
      name: 'Catálogo embalagens',
      show: true,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    DRY_PACKAGE_DELETED: {
      path: '/admin/catalogue/dry-packages/deleted',
      name: 'Catálogo embalagens',
      show: true,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    COLOR_COMBINATIONS_CREATE: {
      path: '/admin/catalogue/color-combinations/create',
      name: 'Catálogo combinações de cores',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    COLOR_COMBINATIONS_VIEW: {
      path: '/admin/catalogue/color-combinations/view',
      name: 'Catálogo combinações de cores',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    CATEGORIES_CREATE: {
      path: '/admin/catalogue/categories/create',
      name: 'Catálogo categorias',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    CATEGORIES_EDIT: {
      path: '/admin/catalogue/categories/edit',
      name: 'Catálogo categorias',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    CATEGORIES_VIEW: {
      path: '/admin/catalogue/categories/view',
      name: 'Catálogo categorias',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    CATEGORIES_DELETED: {
      path: '/admin/catalogue/categories/deleted',
      name: 'Categorias Deletada  s',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    PRODUCTS_CREATE: {
      path: '/admin/catalogue/products/create',
      name: 'Catálogo produtos',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    PRODUCTS_EDIT: {
      path: '/admin/catalogue/products/edit',
      name: 'Catálogo produtos',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    PRODUCTS_VIEW: {
      path: '/admin/catalogue/products/view',
      name: 'Catálogo produtos',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    SKU_LIST: {
      path: '/admin/catalogue/products/skus',
      name: 'Catálogo produtos',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    PRICES_LIST: {
      path: '/admin/catalogue/products/skus/prices',
      name: 'Catálogo produtos',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    SKU_CREATE: {
      path: '/admin/catalogue/products/skus/create',
      name: 'Catálogo produtos',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    SKU_CREATE_MANY: {
      path: '/admin/catalogue/products/skus/createMany',
      name: 'Catálogo produtos',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    SKU_EDIT: {
      path: '/admin/catalogue/products/skus/edit',
      name: 'Catálogo produtos',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    SKU_VIEW: {
      path: '/admin/catalogue/products/skus/view',
      name: 'Catálogo produtos',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    BASES_DELETED: {
      path: '/admin/catalogue/bases/deleted',
      name: 'Bases Deletadas',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    PRODUCTS_DELETED: {
      path: '/admin/catalogue/products/deleted',
      name: 'Produtos Deletados',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    SIZES_DELETED: {
      path: '/admin/catalogue/sizes/deleted',
      name: 'Tamanhos Deletados',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    PROPERTIES_DELETED: {
      path: '/admin/catalogue/properties/deleted',
      name: 'Propriedades Deletadas',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
    NOTIFICATIONS: {
      path: '/notificacoes',
      name: 'Notificações',
      show: false,
      submenu: false,
      alwaysView: false,
      key: '',
      items: {},
    },
  },
};

export const options = {
  ROUTES: {
    NOTIFICATIONS: {
      path: '/notificacoes',
      name: 'Notificações',
      show: true,
      icon: notificationIcon,
      submenu: false,
      items: {},
      alwaysView: true,
      key: '',
    },
    RESET_PASSWORD: {
      path: '/alterar-senha',
      name: 'Alterar Senha',
      show: true,
      icon: resetIcon,
      submenu: false,
      items: {},
      alwaysView: true,
      key: '',
    },
    LOGOUT: {
      path: '/login',
      name: 'Sair',
      show: true,
      icon: logoutIcon,
      submenu: false,
      alwaysView: true,
      key: '',
      items: {},
    },
  },
};

export default urls;
