/* eslint-disable no-unused-vars */
import Button from 'components/structure/Button/Button';
import PageContent from 'components/structure/PageContent/PageContent';
import PageHeader from 'components/structure/PageHeader/PageHeader';
import Popover from 'components/structure/PopOver/PopOver';
import {
  PopItem,
  PopOverContent,
} from 'components/structure/PopOver/PopOver.styles';
import TableDefault from 'components/structure/Table/Table';
import urls from 'constants/urls';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getPermission } from 'utils/permissions';
import EmptySearch from '../../components/structure/EmptySearch/EmptySearch';
import { types } from './dataOptionsCategories';
import {
  editCategory,
  getDeletedCategoriesList,
  getDeletedCategoryDetail,
  setCategoriesId,
} from 'store/categories/categories-slice';

const DeletedCategories = () => {
  const { permissions } = useSelector((state) => state.auth);
  const [searchTerm, setSearchTerm] = useState('');
  const [ordenation, setOrdenation] = useState('');
  const [page, setPage] = useState(1);
  const [totalPerPage, setTotalPerPage] = useState({ value: 10 });
  const perPage = totalPerPage.value;

  const [selectedId, setSelectedId] = useState(null);

  const { totalPage } = useSelector((state) => state.categories.categories);
  const { items } = useSelector((state) => state.categories.categories);

  const optionsOrdenation = [
    { value: 'name', label: 'Nome' },
    { value: 'type', label: 'Tipo' },
    { value: 'platforms', label: 'Plataforma' },
    { value: 'deletedAt', label: 'Data e hora' },
  ];

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      getDeletedCategoriesList({ page, searchTerm, perPage, ordenation }),
    );
  }, [dispatch, page, searchTerm, perPage, ordenation]);

  useEffect(() => {
    if (getPermission('categories', permissions, 'delete') === false) {
      history.push('/');
    }
  }, [history, permissions]);

  const viewDeletedCategory = (id) => {
    dispatch(getDeletedCategoryDetail(parseInt(id, 10)));
    setSelectedId(id);
    dispatch(setCategoriesId(id));
    history.push(urls.ROUTES.CATEGORIES_VIEW.path);
  };

  const restoreCategory = useCallback(
    (id) => {
      const formData = new FormData();
      formData.append('restore', true);
      dispatch(editCategory({ id, info: formData }));
    },
    [dispatch],
  );

  const COLUMNS = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Plataforma',
        accessor: ({ platforms }) => platforms[0],
      },
      {
        Header: 'Tipo',
        accessor: ({ type }) => {
          const found = types?.find((data) => data?.value === type);
          return found.label;
        },
      },
      {
        Header: 'Removido em',
        accessor: ({ deletedAt }) => new Date(deletedAt).toLocaleString(),
      },
      {
        Header: ' ',
        accessor: ({ id }) => (
          <Popover
            content={
              <PopOverContent>
                <PopItem onClick={() => restoreCategory(id)}>Restaurar</PopItem>
                <PopItem onClick={() => viewDeletedCategory(id)}>
                  Ver detalhes
                </PopItem>
              </PopOverContent>
            }
          />
        ),
        sticky: 'right',
      },
    ],
    [],
  );

  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  const data = useMemo(() => items, [items]);

  return (
    <div>
      <PageHeader
        title="Categorias removidas"
        onClickFilter={() => {}}
        search
        searchFunc={(searchData) => setSearchTerm(searchData)}
        placeholder="Buscar por ID"
        optionsOrdenation={optionsOrdenation}
        ordenation={ordenation}
        setOrdenation={setOrdenation}
        trash={false}
      />

      <PageContent>
        <EmptySearch items={data} term={searchTerm}>
          <TableDefault
            columns={COLUMNS}
            data={data}
            pageCount={totalPage}
            defaultCurrentPage={page}
            setPageCurrent={setPage}
            totalPerPage={totalPerPage}
            setTotalPerPage={setTotalPerPage}
          />
        </EmptySearch>
        <Button
          className="backbutton"
          btnType="blue"
          width="20%"
          fontWeight="600"
          onClick={() =>
            history.push(urls.ROUTES.CATALOGUE.items.categories.path)
          }
        >
          VOLTAR
        </Button>
      </PageContent>
    </div>
  );
};
export default DeletedCategories;
