const ACCESS_TOKEN = 'access-token';
const NAME = 'name';
const FULL_NAME = 'fullname';
const EMAIL = 'email';
const ROLE = 'role';
const PERMISSIONS = 'permissions';

export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN);

export const getUserData = () => {
  return {
    name: localStorage.getItem(NAME),
    email: localStorage.getItem(EMAIL),
    fullname: localStorage.getItem(FULL_NAME),
    role: localStorage.getItem(ROLE),
    permissionsObj: localStorage.getItem(PERMISSIONS),
  };
};

export const setAccessToken = ({
  accessToken,
  name,
  fullname,
  email,
  role,
  permissionsObj,
}) => {
  localStorage.setItem(ACCESS_TOKEN, accessToken);
  localStorage.setItem(NAME, name);
  localStorage.setItem(FULL_NAME, fullname);
  localStorage.setItem(EMAIL, email);
  localStorage.setItem(ROLE, role);
  localStorage.setItem(PERMISSIONS, JSON.stringify(permissionsObj));
};

export const setOnlyAccessToken = (accessToken) => {
  localStorage.setItem(ACCESS_TOKEN, accessToken);
};

export const removeStorage = () => {
  localStorage.clear();
};

export const isAuthenticated = () => getAccessToken() !== null;
