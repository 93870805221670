/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-const */
import Grid from '@material-ui/core/Grid';
import { MorePermissionsButton } from 'components/contexts/permissions/MorePermissionsButton';
import Checkbox from 'components/form/Checkbox/Checkbox';
import InputText from 'components/form/Text/TextInput/InputText';
import BoxAlert from 'components/structure/BoxAlert/BoxAlert';
import Button from 'components/structure/Button/Button';
import FormContainer from 'components/structure/FormContainer/FormContainer';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getPermission } from 'utils/permissions';
import { MorePermissionsModal } from '../../components/contexts/permissions/MorePermissionsModal';
import {
  ButtonPermissionsContent,
  ButtonsContainer,
  Line,
  PermissionsTitle,
  Table,
  TableBody,
  Td,
  Title,
  Tr,
} from './Roles.styles';
import {
  getAttributesListPerRole,
  setArticlesModal,
  setArticlesResourceId,
} from 'store/morePermissions/morePermissions-slice';
import { getResourcesOptions } from 'store/roles/roles-slice';

const RolesView = () => {
  const { role, selectId } = useSelector((state) => state.roles);
  const resourcesItems = useSelector((state) => state.roles.resources);
  const { permissions } = useSelector((state) => state.auth);

  const [changeToMarkOffAll, setChangeToMarkOffAll] = useState(false);
  const [roleName, setRoleName] = useState(role.name);
  const [newResourceList, setNewResourceList] = useState([]);

  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    if (getPermission('roles', permissions, 'access') === false) {
      history.push('/');
    }
  }, [history, permissions]);

  const cancelRoleEdit = () => {
    history.goBack();
  };

  useEffect(() => {
    dispatch(getResourcesOptions());
  }, [dispatch]);

  function getArr() {
    let obj = {};
    return resourcesItems.map((resource) => {
      const found = role.permissions.filter(
        (item) => item.resourceId === resource.resourceId,
      );

      if (found.length > 0) {
        obj = {
          id: found[0]?.id,
          resourceId: found[0]?.resourceId,
          roleId: role.id,
          name: found[0]?.name,
          translatedName: found[0]?.translatedName,
          canList: found[0]?.canList,
          canCreate: found[0]?.canCreate,
          canUpdate: found[0]?.canUpdate,
          canDelete: found[0]?.canDelete,
          canAccess: found[0]?.canAccess,
        };
      } else {
        obj = {
          resourceId: resource.resourceId,
          roleId: role.id,
          name: resource.name,
          translatedName: resource.translatedName,
          canCreate: false,
          canUpdate: false,
          canDelete: false,
          canAccess: false,
        };
      }
      return obj;
    });
  }

  useEffect(() => {
    let arr = getArr();
    setNewResourceList(arr);
  }, [resourcesItems, role.permissions, role.id]);

  useEffect(() => {
    setRoleName(role.name);
  }, [role, dispatch]);

  useEffect(() => {
    let tempResource = [];

    tempResource = newResourceList.filter((resource) => {
      return (
        resource.canAccess === true &&
        resource.canCreate === true &&
        resource.canUpdate === true &&
        resource.canDelete === true
      );
    });

    if (tempResource.length === newResourceList.length) {
      setChangeToMarkOffAll(true);
    } else {
      setChangeToMarkOffAll(false);
    }
  }, [newResourceList]);

  const textBox = (
    <p>
      Alguns resources possuem dependências entre si e precisam ter o mesmo
      nível de permissão. São eles:
      <br />
      <br />
      i. Perfis, Recursos e Permissões
      <br />
      ii. SKUs e Preços
    </p>
  );

  return (
    <>
      <MorePermissionsModal />
      <FormContainer title={intl.messages['textHeader.viewRole']}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item md={12}>
                <InputText
                  placeholder={intl.messages['labelsInputs.typeYourName']}
                  label={intl.messages['labelsInputs.typeYourName']}
                  value={roleName}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12}>
          <PermissionsTitle>Perfis permissões</PermissionsTitle>
        </Grid>

        <Grid item md={9}>
          <BoxAlert title="Atenção" text={textBox} />
        </Grid>

        <Grid item md={9}>
          <ButtonPermissionsContent>
            <Title>Acesso de administrador</Title>

            <Button
              type="checkbox"
              btnType={changeToMarkOffAll ? 'secondary' : 'primary'}
              name="allSelect"
              width="28%"
              height="40px"
              borderRadius="4px"
              fontWeight="600"
              disabled
            >
              {changeToMarkOffAll ? 'Desmarcar tudo' : 'Selecionar tudo'}
            </Button>
          </ButtonPermissionsContent>
          <Line />
        </Grid>
        <Table>
          <TableBody>
            {newResourceList.map((resource) => (
              <Tr key={resource.resourceId}>
                <Td>{resource.name && resource.translatedName}</Td>
                <Td>
                  <Checkbox
                    label="Ver"
                    name={resource.canAccess}
                    checked={resource.canAccess}
                    disabled
                  />
                </Td>
                <Td>
                  <Checkbox
                    label="Criar/Editar"
                    name={resource.canCreate}
                    checked={resource.canCreate}
                    disabled
                  />
                </Td>
                <Td>
                  <Checkbox
                    label="Deletar"
                    name={resource.canDelete}
                    checked={resource?.canDelete}
                    disabled
                  />
                </Td>
                <Td>
                  <MorePermissionsButton
                    visible={resource.name === 'articles'}
                    actionFunction={() => {
                      dispatch(setArticlesModal(true));
                      dispatch(getAttributesListPerRole(selectId));
                      dispatch(setArticlesResourceId(selectId));
                    }}
                  />
                </Td>
              </Tr>
            ))}
          </TableBody>
        </Table>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Grid container>
              <ButtonsContainer>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <Button
                      btnType="secondary"
                      width="100%"
                      borderRadius="4px"
                      fontWeight="600"
                      onClick={() => cancelRoleEdit()}
                    >
                      {intl.messages['buttons.toBack']}
                    </Button>
                  </Grid>
                </Grid>
              </ButtonsContainer>
            </Grid>
          </Grid>
        </Grid>
      </FormContainer>
    </>
  );
};

export default RolesView;
