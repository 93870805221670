import styled, { css } from 'styled-components';
import Button from 'components/structure/ActionButton/ActionButton';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 10% 0;
    background: ${theme.colors.white.default};
    border-top-left-radius: 30px;
    height: 100vh;
  `}
`;

export const FormTitle = styled.h3`
  ${({ theme }) => css`
    color: ${theme.colors.tertiary.default};
    font-family: 'Suvinil Sans';
    font-weight: bold;
    font-size: 24px;
    padding-bottom: 30px;
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const AddButton = styled(Button)`
  ${({ theme }) => css`
    font-family: ${theme.typography.family.primary};
    font-weight: 600;
    font-size: 16px;
    padding: 24px 16px;
    text-transform: uppercase;
    margin-left: 16px;
    max-width: 250px;
  `}
`;
