import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputText from 'components/form/Text/InputText/InputText';
import rgbToHex from 'utils/rgbToHex';
import { errorToast } from 'utils/toasts';
import { Container, Color, InputsWrapper } from './RGBColorInput.styles';

const RGBColorInput = ({
  color1,
  color2,
  color3,
  setColor1,
  setColor2,
  setColor3,
  colorRgbString,
  errors,
  isError,
  disabled,
}) => {
  const [rgbError, setRgbError] = useState('');

  useEffect(() => {
    if (
      color1 < 0 ||
      color1 > 255 ||
      color2 < 0 ||
      color2 > 255 ||
      color3 < 0 ||
      color3 > 255
    ) {
      setRgbError(
        errorToast('Campo RGB não deve ser menor que zero e maior que 255'),
      );
    }
  }, [color1, color2, color3]);

  const convertColor = rgbToHex(colorRgbString);

  return (
    <Container>
      <span>RGB</span>
      <InputsWrapper>
        <InputText
          placeholder="000"
          value={color1}
          onChange={(e) => setColor1(e.target.value, rgbError)}
          error={errors}
          isError={color1 === '' && isError}
          disabled={disabled}
        />
        <InputText
          placeholder="000"
          value={color2}
          onChange={(e) => setColor2(e.target.value, rgbError)}
          error={errors}
          isError={color2 === '' && isError}
          disabled={disabled}
        />
        <InputText
          placeholder="000"
          value={color3}
          onChange={(e) => setColor3(e.target.value, rgbError)}
          error={errors}
          isError={color3 === '' && isError}
          disabled={disabled}
        />
        <Color rgbCode={convertColor || ''} colorRgbString={colorRgbString} />
      </InputsWrapper>
    </Container>
  );
};

RGBColorInput.propTypes = {
  color1: PropTypes.string,
  color2: PropTypes.string,
  color3: PropTypes.string,
  setColor1: PropTypes.func,
  setColor2: PropTypes.func,
  setColor3: PropTypes.func,
  colorRgbString: PropTypes.string,
  errors: PropTypes.any,
  isError: PropTypes.bool,
  disabled: PropTypes.bool,
};

RGBColorInput.defaultProps = {
  color1: '',
  color2: '',
  color3: '',
  setColor1: () => {},
  setColor2: () => {},
  setColor3: () => {},
  colorRgbString: '',
  errors: '',
  isError: false,
  disabled: false,
};

export default RGBColorInput;
