import Grid from '@material-ui/core/Grid';
import { ButtonsContainer } from 'components/contexts/articles/ArticlesFirstStep/ArticlesFirstStep.styles';
import ModalMultiConfirmation from 'components/contexts/modal/ModalMultiConfirmation/ModalMultiConfirmation';
import FileInput from 'components/form/FileInput/FileInput';
import AsyncSelectPaginateEditorial from 'components/form/Select/AsyncSelectPaginate/AsyncSelectPaginateEditorial';
import SelectInputOptions from 'components/form/Select/ReactSelect/SelectInputOptions';
import InputText from 'components/form/Text/TextInput/InputText';
import TextAreaDescription from 'components/form/TextArea/TextAreaDescription/TextAreaDescription';
import BoxAlert from 'components/structure/BoxAlert/BoxAlert';
import Button from 'components/structure/Button/Button';
import urls from 'constants/urls';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getEditorialOptions,
  setActiveStep,
  statusArticlesModal,
} from 'store/articles/articles-slice';

import { createSlug } from 'utils/createSlug';

const ArticlesFirstStepView = ({ location }) => {
  const { openStatusModal, status, msg, article, selectId } = useSelector(
    (state) => state.articles,
  );

  const options = [
    { value: 'institucional', label: 'Suvinil' },
    { value: 'glasu', label: 'Glasu' },
  ];

  const textBox1 = (
    <p>
      O title da imagem deve conter no máximo 125 caracteres, sendo recomendado
      conter até 100 caracteres.
    </p>
  );

  const textBox2 = (
    <p>
      É importante verificar informações de SEO ao editar as informações de um
      artigo
    </p>
  );
  const [platforms, setPlatforms] = useState({
    value: 'institucional',
    label: 'Suvinil',
  });

  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const cancelArticle = () => {
    history.goBack();
  };
  const editArticle = () => {
    history.push(urls.ROUTES.ARTICLES_EDIT.path);
  };

  const advanceStepArticle = () => {
    dispatch(setActiveStep(1));
  };

  useEffect(() => {
    dispatch(getEditorialOptions());
    if (article?.platforms?.[0] === 'institucional') {
      setPlatforms({ value: 'institucional', label: 'Suvinil' });
    } else {
      setPlatforms({ value: 'glasu', label: 'Glasu' });
    }
  }, [dispatch]);

  const fileInput = article?.images;

  return (
    <>
      <ModalMultiConfirmation
        title={msg.title}
        text={msg.text}
        setIsOpen={(payload) => {
          dispatch(statusArticlesModal(payload));
          if (status !== 'error') {
            advanceStepArticle();
          }
        }}
        isOpen={openStatusModal}
        status={status}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid item md={12}>
              <BoxAlert title="Atenção" text={textBox1} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid item md={12}>
              <BoxAlert title="Atenção" text={textBox2} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid item md={12}>
              <InputText
                label={intl.messages['labelsInputs.title']}
                value={article.name}
                maxLength={60}
                isCount
                hasTooltip
                textTooltip={intl.messages['labelsInputs.tooltipArticleTitle']}
                heigth="7vh"
                disabled
              />
            </Grid>
            <Grid item md={12}>
              <InputText
                label={intl.messages['labelsInputs.slug']}
                disabled
                value={createSlug(article.name)}
                hasTooltip
                textTooltip={intl.messages['labelsInputs.tooltipArticleSlug']}
              />
            </Grid>
            <Grid item md={12}>
              <InputText
                label={intl.messages['labelsInputs.siteTitle']}
                value={article.siteTitle}
                disabled
                hasTooltip
                textTooltip={
                  intl.messages['labelsInputs.tooltipArticleSiteTitle']
                }
              />
            </Grid>
            <Grid item md={12}>
              <SelectInputOptions
                label={intl.messages['labelsInputs.platforms']}
                value={platforms}
                disabled
                options={options}
                tooltip
                title={intl.messages['labelsInputs.tooltipArticlePlatforms']}
              />
            </Grid>
            <Grid item md={12}>
              <AsyncSelectPaginateEditorial
                label={intl.messages['labelsInputs.defaultEditorial']}
                platforms={platforms.value}
                value={{
                  id: article?.editorial?.id,
                  name: article?.editorial?.name,
                }}
                disabled
                tooltip
                title={intl.messages['labelsInputs.tooltipArticleEditorial']}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <InputText
                label={intl.messages['labelsInputs.typeAuthor']}
                value={article.author}
                optional
                disabled
                hasTooltip
                textTooltip={intl.messages['labelsInputs.tooltipArticleAuthor']}
              />
            </Grid>
            <Grid item md={12}>
              <TextAreaDescription
                label={intl.messages['labelsInputs.shortDescription']}
                maxLength={140}
                value={article.shortDescription}
                heigth="120px"
                textTooltipSecondary
                disabled
              />
            </Grid>
            {fileInput && (
              <>
                <Grid item md={12}>
                  <FileInput
                    label={intl.messages['labelsInputs.uplodadImageLabel']}
                    text={intl.messages['labelsInputs.toDoUploadImage']}
                    title={
                      platforms === ''
                        ? intl.messages[
                            'labelsInputs.tooltipArticleUploadImage'
                          ]
                        : platforms.value === 'institucional'
                        ? intl.messages[
                            'labelsInputs.tooltipArticleUploadImageSuvinil'
                          ]
                        : intl.messages[
                            'labelsInputs.tooltipArticleUploadImageGlasu'
                          ]
                    }
                    disabled
                    fileName={fileInput[0]?.fullUrl}
                    accept="image/*"
                  />
                </Grid>
                <Grid item md={12}>
                  <TextAreaDescription
                    label={intl.messages['labelsInputs.altImageLabel']}
                    heigth="95px"
                    textTooltipSecondary
                    value={fileInput[0]?.alternativeText}
                    disabled
                    maxLength={100}
                    isCount
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>

      <ButtonsContainer>
        <Grid container spacing={2} style={{ justifyContent: 'end' }}>
          <Grid item md={3} sm={12}>
            <Button
              btnType="secondary"
              width="100%"
              borderRadius="4px"
              fontWeight="600"
              onClick={() => cancelArticle()}
            >
              {intl.messages['buttons.cancel']}
            </Button>
          </Grid>
          <Grid item md={3} sm={12}>
            {!location.state && (
              <Button
                btnType="blue"
                width="100%"
                borderRadius="4px"
                fontWeight="600"
                onClick={editArticle}
              >
                {intl.messages['buttons.edit']}
              </Button>
            )}
          </Grid>
          <Grid item md={3} sm={12}>
            <Button
              btnType="primary"
              width="100%"
              borderRadius="4px"
              fontWeight="600"
              onClick={advanceStepArticle}
            >
              {intl.messages['buttons.advance']}
            </Button>
          </Grid>
        </Grid>
      </ButtonsContainer>
    </>
  );
};

ArticlesFirstStepView.propTypes = { location: PropTypes.object };
ArticlesFirstStepView.defaultProps = { location: { state: true } };
export default ArticlesFirstStepView;
