import React from 'react';
import PropTypes from 'prop-types';
import SpanError from 'components/structure/SpanError/SpanError';
import Tooltip from 'assets/icons/tooltip-icon.svg';
import TooltipComponent from 'components/structure/Tooltip/Tooltip';
import errorIcon from 'assets/icons/ic-x-error.svg';
import {
  Container,
  Content,
  Label,
  TextareaStyle,
  CountInfo,
  LabelContainer,
  OptionalStyle,
} from './TextAreaDescription.styles';

const TextAreaDescription = ({
  id,
  name,
  rows,
  isError,
  error,
  value,
  handleChange,
  label,
  placeholder,
  isCount,
  count,
  maxLength,
  height,
  optional,
  tooltip,
  paddingTop,
  disabled,
  textTooltipSecondary,
  hookForm,
  ...rest
}) => {
  return (
    <Container paddingTop={paddingTop}>
      <Content>
        <LabelContainer>
          <Label htmlFor={name} isError={isError}>
            {label}
          </Label>

          <TooltipComponent
            id="tooltipImage"
            title={textTooltipSecondary || `Máximo de ${maxLength} caracteres`}
            multiline
          >
            {optional && <OptionalStyle>(Opcional)</OptionalStyle>}
            {tooltip && <img src={Tooltip} alt="" />}
          </TooltipComponent>
        </LabelContainer>
      </Content>

      <TextareaStyle
        id={id}
        name={name}
        rows={rows}
        isError={isError}
        onChange={(e) => handleChange(e.target.value)}
        placeholder={placeholder}
        value={value}
        maxLength={maxLength}
        height={height}
        disabled={disabled}
        {...rest}
      />

      {isError && (
        <img src={errorIcon} alt="" className="error-icon-textarea" />
      )}

      {isError && <SpanError>{error.message}</SpanError>}
      {isError && !hookForm && <SpanError>{error}</SpanError>}

      {isCount && (
        <CountInfo>
          {value?.length}/{maxLength}
        </CountInfo>
      )}
    </Container>
  );
};

TextAreaDescription.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  rows: PropTypes.number,
  isError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  error: PropTypes.any,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  isCount: PropTypes.bool,
  count: PropTypes.number,
  maxLength: PropTypes.number,
  height: PropTypes.string,
  optional: PropTypes.bool,
  tooltip: PropTypes.bool,
  paddingTop: PropTypes.bool,
  disabled: PropTypes.bool,
  textTooltipSecondary: PropTypes.bool,
  hookForm: PropTypes.bool,
};

TextAreaDescription.defaultProps = {
  id: '',
  name: '',
  rows: null,
  isError: false,
  error: '',
  value: '',
  handleChange: () => {},
  label: '',
  placeholder: '',
  isCount: true,
  count: 0,
  maxLength: null,
  height: '100%',
  optional: false,
  tooltip: true,
  paddingTop: false,
  disabled: false,
  textTooltipSecondary: false,
  hookForm: false,
};

export default TextAreaDescription;
