import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 20px 0;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerButtons = styled.div`
  display: flex;
  padding: 20px 0 0 8px;

  button {
    :first-child {
      margin-right: 8px;
    }
  }
`;
