import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    p {
      font-size: ${theme.typography.sizes.xs};
      line-height: 18px;
    }
  `}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 40px 0;
`;

export const ContainerInvisible = styled.div`
  display: none;
`;
