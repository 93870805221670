import Button from 'components/structure/Button/Button';
import EmptySearch from 'components/structure/EmptySearch/EmptySearch';
import PageContent from 'components/structure/PageContent/PageContent';
import PageHeader from 'components/structure/PageHeader/PageHeader';
import TableDefault from 'components/structure/Table/Table';
import Toggle from 'components/structure/ToggleTable/TogleTable';
import urls from 'constants/urls';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import tinycolor from 'tinycolor2';
import { useIntl } from 'react-intl';
import { Color } from '../../components/contexts/colors/RGBColorInput/RGBColorInput.styles';
import Popover from '../../components/structure/PopOver/PopOver';
import ModalDeleteConfirmation from '../../components/contexts/modal/ModalDeleteConfirmation/ModalDeleteConfirmation';
import {
  PopItem,
  PopOverContent,
} from '../../components/structure/PopOver/PopOver.styles';

import {
  getDeletedCombinationDetail,
  getDeletedColorCombinationsList,
  editColorCombination,
  setCombinationId,
} from 'store/colorCombinations/colorCombinations-slice';
import rgbToHex from '../../utils/rgbToHex';
import * as S from './ColorCombinations.styles';

const DeletedColorCombinations = () => {
  const { permissions } = useSelector((state) => state.auth);
  const [page, setPage] = useState(1);
  const { items, totalPage } = useSelector(
    (state) => state.colorCombinations.colorCombinations,
  );
  const [selectedId, setSelectedId] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [ordenation, setOrdenation] = useState('');
  const [totalPerPage, setTotalPerPage] = useState({ value: 10 });
  const [restoreModalVisibility, setRestoreModalVisibility] = useState(false);
  const [selectedCombination, setSelectedCombination] = useState({});
  const perPage = totalPerPage.value;

  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  useEffect(() => {
    dispatch(
      getDeletedColorCombinationsList({
        currentPage,
        searchTerm,
        perPage,
        ordenation,
      }),
    );
  }, [dispatch, setCurrentPage, currentPage, searchTerm, perPage, ordenation]);

  const viewDeletedCombination = useCallback(
    (id) => {
      dispatch(setCombinationId(id));
      setSelectedId(id);
      dispatch(getDeletedCombinationDetail(id));
      history.push(urls.ROUTES.COLOR_COMBINATIONS_VIEW.path);
    },
    [dispatch, history],
  );

  const COLUMNS = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Autor',
        accessor: 'author',
      },
      {
        Header: 'Cores',
        accessor: ({ colorToColorCombinations }) => {
          return (
            <S.Colors>
              {colorToColorCombinations.map((color) => {
                const colorRgb = rgbToHex(color.rgb);
                const txtColor = tinycolor(colorRgb);
                return (
                  <Color
                    key={color.id}
                    rgbCode={colorRgb || ''}
                    colorText={txtColor.isLight() ? '#666666' : '#F7F7F7'}
                  />
                );
              })}
            </S.Colors>
          );
        },
      },
      {
        Header: 'Publicado',
        accessor: ({ published, id }) => (
          <Toggle key={id} checked={published} id={id} page={page} readonly />
        ),
      },
      {
        Header: 'Removido em',
        accessor: ({ deletedAt }) => new Date(deletedAt).toLocaleString(),
      },
      {
        Header: ' ',
        accessor: ({ id, name }) => (
          <Popover
            content={
              <PopOverContent>
                <PopItem
                  onClick={() => {
                    setSelectedCombination({ id, name });
                    setRestoreModalVisibility(true);
                  }}
                >
                  Restaurar
                </PopItem>
                <PopItem onClick={() => viewDeletedCombination(id)}>
                  Ver Detalhes
                </PopItem>
              </PopOverContent>
            }
          />
        ),
      },
    ],
    [],
  );

  const data = useMemo(
    () =>
      items?.map((item) => {
        return { ...item, author: 'Suvinil' };
      }),
    [items],
  );

  const optionsOrdenation = [
    { value: 'id', label: 'ID' },
    { value: 'name', label: 'Nome' },
    { value: 'colors', label: 'Cores' },
    { value: 'published', label: 'Publicado' },
  ];

  return (
    <>
      <ModalDeleteConfirmation
        title={intl.messages['textModal.areYouSure']}
        text="Ao pressionar deletar essa combinação será restaurada"
        placeholder="Nome"
        buttonName="Restaurar"
        setIsOpen={setRestoreModalVisibility}
        isOpen={restoreModalVisibility}
        fieldValue={selectedCombination.name}
        fieldLabel={selectedCombination.name}
        confirmOnClick={() => {
          dispatch(
            editColorCombination({
              id: selectedCombination.id,
              info: {
                restore: true,
              },
            }),
          );
          setRestoreModalVisibility(false);
        }}
        textFieldLabel="Digite o nome da combinação para continuar"
      />
      <PageHeader
        title="Combinações removidas"
        onClickFilter={() => {}}
        search
        searchFunc={(searchData) => setSearchTerm(searchData)}
        placeholder="Busque por ID ou nome"
        optionsOrdenation={optionsOrdenation}
        ordenation={ordenation}
        setOrdenation={setOrdenation}
        trash={false}
      />
      <PageContent>
        <EmptySearch items={data} term={searchTerm}>
          <TableDefault
            columns={COLUMNS}
            data={data}
            pageCount={totalPage}
            defaultCurrentPage={currentPage}
            setPageCurrent={setCurrentPage}
            totalPerPage={totalPerPage}
            setTotalPerPage={setTotalPerPage}
          />
        </EmptySearch>
        <Button
          className="backbutton"
          btnType="blue"
          width="20%"
          fontWeight="600"
          onClick={() => history.goBack()}
        >
          VOLTAR
        </Button>
      </PageContent>
    </>
  );
};

export default DeletedColorCombinations;
