import * as Yup from 'yup';

export const useTitleByRule = (rule) =>
  ({
    create: 'Criar embalagem',
    update: 'Editar embalagem',
    view: 'Visualizar embalagem',
  }[rule]);

export const useEndPoint = () => null;

export const DryPackageSchema = Yup.object().shape({
  name: Yup.string().required('O campo Envase é obrigatório'),
  width: Yup.number().required('O campo Largura é obrigatório'),
  length: Yup.number().required('O campo Comprimento é obrigatório'),
  height: Yup.number().required('O campo Altura é obrigatório'),
});

export const isValideErrorObject = (obj) => {
  if (obj === null || obj === undefined) {
    return false;
  }
  return true;
};
