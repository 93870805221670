import styled, { css } from 'styled-components';

export const Menu = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      display: flex;
      align-items: center;
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-weight: 400;
      padding: 0 17px 0 38px;
      color: ${theme.colors.tertiary.default};
      height: 48px;
      transition: border 0.2s ease-out;
      text-decoration: none;
      > img {
        margin-right: 16px;
      }
    }
    cursor: pointer;
    ${({ active }) =>
      active && `border-left: 5px solid ${theme.colors.tertiary.default};`};

    .openner {
      margin-right: 10px;
    }

    &:hover {
      color: ${theme.colors.darkGray};
      //border-left: 4.35px solid ${theme.colors.tertiary.default};
      background: linear-gradient(
        90.04deg,
        rgba(0, 0, 0, 0.1) 22.31%,
        rgba(0, 0, 0, 0) 109.16%
      );
    }
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.black.lighter};
    height: 100vh;
    position: fixed;
    width: 295px;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    padding: 20px 0;

    a {
      text-decoration: none;
    }

    .active {
      border-left: 4.35px solid ${theme.colors.tertiary.default};
      background: linear-gradient(
        90.04deg,
        rgba(0, 0, 0, 0.1) 22.31%,
        rgba(0, 0, 0, 0) 109.16%
      );
      font-weight: 600;
    }
  `}
`;

export const Item = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-left: 15%;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    padding: 0 0 0 10px;
    color: ${theme.colors.tertiary.default};
    height: 48px;
    transition: border 0.2s ease-out;
    text-decoration: none;
    cursor: pointer;
    ${({ active }) =>
      active && `border-left: 5px solid ${theme.colors.tertiary.default};`};

    &:hover {
      color: ${theme.colors.darkGray};
      border-left: 4.35px solid ${theme.colors.tertiary.default};
      background: linear-gradient(
        90.04deg,
        rgba(0, 0, 0, 0.1) 22.31%,
        rgba(0, 0, 0, 0) 109.16%
      );
    }
  `}
`;
