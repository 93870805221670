/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import StepProgress from 'components/contexts/StepProgress/StepProgress';
import FormContainerSecondary from 'components/structure/FormContainerSecondary/FormContainerSecondary';
import ProductsFirstStep from 'pages/products/ProductsCreateSteps/ProductsFirstStep';
import ProductsSecondStep from 'pages/products/ProductsCreateSteps/ProductsSecondStep';
import { getPermission } from 'utils/permissions';
import { useHistory } from 'react-router-dom';
import { Container, StepContainer } from './Products.styles';

const ProductsCreate = () => {
  const { activeStep } = useSelector((state) => state.products);
  const { permissions } = useSelector((state) => state.auth);
  const intl = useIntl();
  const history = useHistory();
  const steps = ['PRODUTO', 'DETALHES'];

  useEffect(() => {
    if (getPermission('products', permissions, 'create') === false) {
      history.push('/');
    }
  }, [history, permissions]);

  return (
    <Container>
      <StepContainer>
        <StepProgress activeStep={activeStep} steps={steps} />
      </StepContainer>
      <FormContainerSecondary title={intl.messages['textHeader.createProduct']}>
        {activeStep === 0 && <ProductsFirstStep />}
        {activeStep === 1 && <ProductsSecondStep />}
      </FormContainerSecondary>
    </Container>
  );
};

export default ProductsCreate;
