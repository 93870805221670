import ModalDeleteConfirmation from 'components/contexts/modal/ModalDeleteConfirmation/ModalDeleteConfirmation';
import PageContent from 'components/structure/PageContent/PageContent';
import PageHeader from 'components/structure/PageHeader/PageHeader';
import PopOver from 'components/structure/PopOver/PopOver';
import TableDefault from 'components/structure/Table/Table';
import Toggle from 'components/structure/ToggleTable/TogleTable';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import addIcon from 'assets/icons/add.svg';

import ModalMultiConfirmation from 'components/contexts/modal/ModalMultiConfirmation/ModalMultiConfirmation';
import EmptySearch from 'components/structure/EmptySearch/EmptySearch';
import {
  Divider,
  PopItem,
  PopOverContent,
} from 'components/structure/PopOver/PopOver.styles';
import urls from 'constants/urls';

import tinycolor from 'tinycolor2';
import { getPermission } from 'utils/permissions';
import rgbToHex from 'utils/rgbToHex';
import { Color } from './Colours.styles';
import ReportModal from "components/structure/ReportModal";
import {
  editColor,
  getColorDetail,
  getColorDetailToDelete,
  getListColours,
  openColorModalDelete,
  removeColor,
  setColourId,
  statusColoursModal,
} from 'store/colours/colours-slice';

const Colours = () => {
  const { items, totalPage } = useSelector((state) => state.colours.colours);
  const { openModalDelete, color, openStatusModal, status, msg } = useSelector(
    (state) => state.colours,
  );

  const [isOpenReportModal, setIsOpenReportModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedId, setSelectedId] = useState(null);

  const { permissions } = useSelector((state) => state.auth);

  const [searchTerm, setSearchTerm] = useState('');
  const [ordenation, setOrdenation] = useState('');
  const [totalPerPage, setTotalPerPage] = useState({ value: 10 });
  const perPage = totalPerPage.value;

  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();

  const addColor = () => {
    history.push(urls.ROUTES.COLORS_CREATE.path);
  };

  const editPage = useCallback(
    (id) => {
      setSelectedId(id);
      dispatch(setColourId(id));
      history.push(urls.ROUTES.COLORS_EDIT.path);
    },
    [dispatch, history],
  );

  const viewPage = useCallback(
    (id) => {
      dispatch(getColorDetail(parseInt(id, 10)));
      setSelectedId(id);
      dispatch(setColourId(id));
      history.push({
        pathname: urls.ROUTES.COLORS_VIEW.path,
        state: 'view',
      });
    },
    [dispatch, history],
  );

  const viewActivities = useCallback(
    (id) => {
      history.push(
        `${urls.ROUTES.ACCESS.items.logsByRecord.path}/${id}/colors`,
      );
    },
    [history],
  );

  const deleteModalColor = useCallback(
    (id) => {
      setSelectedId(id);
      dispatch(getColorDetailToDelete({ id, deleteSearch: true }));
    },
    [dispatch],
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  const COLUMNS = useMemo(
    () => [
      { Header: 'ID', accessor: 'id' },

      {
        Header: 'Nome',
        accessor: ({ id, name, rgb }) => {
          const colorRgb = rgbToHex(rgb);
          const txtColor = tinycolor(colorRgb);

          return (
            <Color
              key={id}
              rgbCode={colorRgb || ''}
              colorText={txtColor.isLight() ? '#666666' : '#F7F7F7'}
            >
              {name}
            </Color>
          );
        },
      },
      { Header: 'Código', accessor: 'code' },
      {
        Header: 'Família',
        accessor: ({ groups }) => (groups.length > 0 ? groups[0].name : '-'),
      },
      {
        Header: 'Ativos',
        accessor: ({ active, id }) => (
          <Toggle
            key={id}
            checked={active}
            id={id}
            setData={() => {
              const formData = new FormData();
              formData.append('active', !active);
              dispatch(
                editColor({
                  info: formData,
                  id,
                }),
              );
              dispatch(
                getListColours({
                  currentPage,
                  searchTerm,
                  perPage,
                  ordenation,
                }),
              );
            }}
            page={currentPage}
            readonly
          />
        ),
      },
      {
        Header: 'NCS',
        accessor: ({ ncs }) => ncs || '-',
      },
      {
        Header: ' ',
        accessor: ({ id }) =>
          getPermission('colors', permissions, 'update') === false ? (
            <></>
          ) : (
            <PopOver
              content={
                <PopOverContent>
                  <PopItem
                    onClick={() => {
                      editPage(id);
                    }}
                  >
                    {intl.messages['buttons.edit']}
                  </PopItem>
                  {getPermission('colors', permissions, 'delete') && (
                    <>
                      <Divider />
                      <PopItem onClick={() => deleteModalColor(id)}>
                        {intl.messages['buttons.exclusion']}
                      </PopItem>
                    </>
                  )}
                  {getPermission('colors', permissions, 'access') && (
                    <>
                      <Divider />
                      <PopItem onClick={() => viewPage(id)}>
                        {intl.messages['buttons.view']}
                      </PopItem>
                    </>
                  )}
                  <Divider />
                  <PopItem onClick={() => viewActivities(id)}>
                    Ver atividades
                  </PopItem>
                </PopOverContent>
              }
            />
          ),
      },
    ],
    [currentPage, deleteModalColor, editPage, intl.messages, permissions],
  );

  const data = useMemo(() => items, [items]);

  useEffect(() => {
    dispatch(getListColours({ currentPage, searchTerm, perPage, ordenation }));
  }, [dispatch, currentPage, searchTerm, perPage, ordenation]);

  const optionsOrdenation = [
    { value: 'id', label: 'ID' },
    { value: 'name', label: 'Nome' },
    { value: 'code', label: 'Código' },
  ];

  return (
    <>
      <ModalDeleteConfirmation
        title={intl.messages['textModal.areYouSure']}
        text={intl.messages['textModal.colorDeleteConfirm']}
        placeholder={intl.messages['labelsInputs.enterColor']}
        buttonName={intl.messages['buttons.delete']}
        setIsOpen={(value) => dispatch(openColorModalDelete(value))}
        isOpen={openModalDelete}
        fieldValue={color.name}
        fieldLabel={color.name}
        confirmOnClick={() =>
          dispatch(removeColor({ id: selectedId, page: currentPage }))
        }
        textFieldLabel={intl.messages['labelsInputs.enterName']}
      />
      <ModalMultiConfirmation
        title={msg.title}
        text={msg.text}
        setIsOpen={(payload) => {
          dispatch(statusColoursModal(payload));
        }}
        isOpen={openStatusModal}
        status={status}
      />
      <ReportModal
        isOpen={isOpenReportModal}
        setIsOpen={setIsOpenReportModal}
        title="Período do relatório de cores"
        entity='colors'
      />
      <PageHeader
        title="Cores"
        actions={!getPermission('colors', permissions, 'create') ? [] : [
          {
            name: 'RELATORIOS',
            onClick: () => {
              setIsOpenReportModal(true)
            },
            btnType: "outlined",
          },
          {
            name: 'NOVA COR',
            onClick: addColor,
            btnType: "primary",
            icon: addIcon,
          },
        ]}
        search
        searchFunc={(searchData) => setSearchTerm(searchData)}
        placeholder="Busque por Nome"
        optionsOrdenation={optionsOrdenation}
        ordenation={ordenation}
        setOrdenation={setOrdenation}
        trashRedirect={() => history.push(urls.ROUTES.COLORS_DELETED.path)}
        trash={getPermission('colors', permissions, 'list')}
      />
      <PageContent>
        <EmptySearch items={data} term={searchTerm}>
          <TableDefault
            columns={COLUMNS}
            data={data}
            pageCount={totalPage}
            setPageCurrent={setCurrentPage}
            defaultCurrentPage={currentPage}
            isColorTable
            totalPerPage={totalPerPage}
            setTotalPerPage={setTotalPerPage}
          />
        </EmptySearch>
      </PageContent>
    </>
  );
};

export default Colours;
