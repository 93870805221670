/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { EDITORIALS } from 'constants/endpoints';

import api from 'services/api';
import multiForm from 'services/multiForm';
import { startLoading, stopLoading } from 'store/loading/loading-slice';

import { errorSessionToast, errorToast, successToast } from 'utils/toasts';

const INITIAL_STATE = {
  editorials: {
    totalPage: 1,
    items: [],
  },
  editorial: {
    id: 0,
    name: '',
    meta: '',
    platform: '',
    description: '',
    images: [],
    articles: [],
  },
  selectId: 0,
  openDeleteModal: false,
  openStatusModal: false,
  status: '',
  msg: {
    title: '',
    text: '',
  },
};

const editorialsSlice = createSlice({
  name: 'editorials',
  initialState: INITIAL_STATE,
  reducers: {
    getEditorialsSuccess: (state, { payload }) => {
      state.editorials = payload;
    },
    showEditorialsModal: (state) => {
      state.openDeleteModal = true;
    },
    dismissEditorialsModal: (state) => {
      state.openDeleteModal = false;
    },
    statusEditorialsModal: (state, { payload }) => {
      state.openStatusModal = payload;
    },
    setEditorialsStatus: (state, { payload }) => {
      state.status = payload;
    },
    setMsg: (state, { payload }) => {
      state.msg = payload;
    },
    setEditorialId: (state, { payload }) => {
      state.selectId = payload;
    },
    getEditorialSuccess: (state, { payload }) => {
      state.editorial = payload;
    },
  },
});
export const editorials = editorialsSlice.reducer;
export const {
  dismissEditorialsModal,
  getEditorialSuccess,
  getEditorialsSuccess,
  setEditorialId,
  setEditorialsStatus,
  setMsg,
  showEditorialsModal,
  statusEditorialsModal,
} = editorialsSlice.actions;

export const getEditorialsList = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { currentPage, searchTerm, perPage, ordenation } = payload;
  try {
    let url = '';
    if (searchTerm === '') {
      url = `${EDITORIALS}?perPage=${
        perPage || '10'
      }&page=${currentPage}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}`;
    } else {
      url = `${EDITORIALS}?perPage=${
        perPage || '10'
      }&page=${currentPage}&name=${searchTerm}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}`;
    }
    const response = await api.get(url);

    dispatch(getEditorialsSuccess(response.data));
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getEditorialDetail = (payload) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await api.get(`${EDITORIALS}/${payload}`);

    const { id, name, description, platforms, shortDescription, images } =
      response.data;

    const editorial = {
      id,
      name,
      description,
      meta: shortDescription,
      platform: platforms[0],
      images,
    };

    dispatch(getEditorialsSuccess(editorial));
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getDeletedEditorialDetail = (payload) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await api.get(`${EDITORIALS}/${payload}?trash=true`);

    const { id, name, description, platforms, shortDescription, images } =
      response.data;

    const editorial = {
      id,
      name,
      description,
      meta: shortDescription,
      platform: platforms[0],
      images,
    };
    dispatch(getEditorialsSuccess(editorial));
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const getDeletedEditorialsList = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { page, searchTerm, perPage, ordenation } = payload;
  try {
    let url = '';
    if (searchTerm === '') {
      url = `${EDITORIALS}?perPage=${perPage || '10'}&page=${page}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}&trash=true`;
    } else {
      url = `${EDITORIALS}?perPage=${
        perPage || '10'
      }&page=${page}&name=${searchTerm}&sort.field=${
        ordenation || 'createdAt'
      }&sort.order=${ordenation ? 'ASC' : 'DESC'}&trash=true`;
    }
    const response = await api.get(url);

    dispatch(getEditorialsSuccess(response.data));
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 401:
          errorSessionToast();
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    }
  }
};

export const saveEditorial = (payload) => async (dispatch) => {
  dispatch(startLoading());
  try {
    await new Promise(
      (resolve) => resolve(multiForm.post(EDITORIALS, payload)),
      successToast('Editorial salvo com sucesso!'),
    );
    dispatch(
      getEditorialsList({
        currentPage: 1,
        searchTerm: '',
      }),
    );

    dispatch(stopLoading());
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Erros de Validação',
            text: 'Verifique os campos e tente novamente!',
          };

          dispatch(setMsg(msg));
          dispatch(setEditorialsStatus('error'));
          dispatch(statusEditorialsModal(true));
          break;
        case 409:
          msg = {
            title: 'Editoria já existente',
            text: '',
          };
          dispatch(setMsg(msg));
          dispatch(setEditorialsStatus('error'));
          dispatch(statusEditorialsModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const updateEditorial = (payload) => async (dispatch) => {
  const { id, info } = payload;
  dispatch(startLoading());
  try {
    await new Promise((resolve) =>
      resolve(multiForm.patch(`${EDITORIALS}/${id}`, info)),
    );
    successToast('Editorial editado com sucesso!');
    dispatch(stopLoading());
  } catch (error) {
    let msg = { title: '', text: '' };
    dispatch(stopLoading());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          msg = {
            title: 'Erros de Validação',
            text: 'Verifique os campos e tente novamente!',
          };
          dispatch(setMsg(msg));
          dispatch(setEditorialsStatus('error'));
          dispatch(statusEditorialsModal(true));
          break;
        case 409:
          msg = {
            title: 'Editoria já existente',
            text: '',
          };
          dispatch(setMsg(msg));
          dispatch(setEditorialsStatus('error'));
          dispatch(statusEditorialsModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};

export const deleteEditorial = (payload) => async (dispatch) => {
  dispatch(startLoading());
  const { id, page } = payload;
  try {
    await api.delete(`${EDITORIALS}/${id}`);

    dispatch(dismissEditorialsModal());
    dispatch(stopLoading());

    dispatch(setEditorialsStatus('success'));
    dispatch(statusEditorialsModal(true));
    dispatch(
      getEditorialsList({
        currentPage: page,
        searchTerm: '',
      }),
    );
  } catch (error) {
    dispatch(stopLoading());
    dispatch(dismissEditorialsModal());
    if (error.response !== undefined) {
      const { status, data } = error.response;
      switch (error.response.status) {
        case 400:
          dispatch(statusEditorialsModal(true));
          dispatch(setEditorialsStatus('error'));
          dispatch(statusEditorialsModal(true));
          break;
        default:
          errorToast(`Erro: ${status}: ${data.error}`);
          break;
      }
    } else {
      errorToast(`Falha ao se comunicar com o servidor, verifique a conexão!`);
    }
  }
};
