/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-const */
import categoryIcon from 'assets/icons/category.svg';
import departmentIcon from 'assets/icons/department.svg';
import subcategoryIcon from 'assets/icons/subcategory.svg';
import viewCategoryDownIcon from 'assets/icons/view-category-down.svg';
import viewCategoryRightIcon from 'assets/icons/view-category-right.svg';
import viewDepartmentDownIcon from 'assets/icons/view-department-down.svg';
import viewDepartmentRightIcon from 'assets/icons/view-department-right-default.svg';
import ModalDeleteConfirmation from 'components/contexts/modal/ModalDeleteConfirmation/ModalDeleteConfirmation';
import ModalMultiConfirmation from 'components/contexts/modal/ModalMultiConfirmation/ModalMultiConfirmation';
import EmptySearch from 'components/structure/EmptySearch/EmptySearch';
import FooterTable from 'components/structure/FooterTable/FooterTable';
import PageContent from 'components/structure/PageContent/PageContent';
import PageHeader from 'components/structure/PageHeader/PageHeader';
import PopOver from 'components/structure/PopOver/PopOver';
import {
  Divider,
  PopItem,
  PopOverContent,
} from 'components/structure/PopOver/PopOver.styles';
import urls from 'constants/urls';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import arrayToString from 'utils/arrayToString';
import { capitalizeAllWordsFirstLetter } from 'utils/capitalizeAllWordsFirstLetter';
import { getPermission } from 'utils/permissions';
import {
  ButtonIcon,
  ContainerTable,
  Table,
  TableBody,
  TableHead,
  Td,
  Th,
  Tr,
} from './Categories.styles';
import { types } from './dataOptionsCategories';
import {
  dismissModalDelete,
  getCategoriesList,
  getCategoryDetail,
  removeCategory,
  setCategoriesId,
  showModalDelete,
  statusCategoriesModal,
} from 'store/categories/categories-slice';

const Categories = ({ footerAttrs }) => {
  const { totalPage } = useSelector((state) => state.categories.categories);
  let { items } = useSelector((state) => state.categories.categories);
  const { openDeleteModal, openStatusModal, msg, status } = useSelector(
    (state) => state.categories,
  );
  const { permissions } = useSelector((state) => state.auth);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPerPage, setTotalPerPage] = useState({ value: 10 });
  const [order, setOrder] = useState(1);
  const [columnOrder, setColumnOrder] = useState('');
  const [expandedRows, setExpandedRows] = useState([]);
  const [expandState, setExpandState] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const [selectedSlug, setSelectedSlug] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [ordenation, setOrdenation] = useState('');
  const perPage = totalPerPage.value;

  const tableData = [];

  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();

  const handleExpandRow = (event, categoryId) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(categoryId);

    const obj = expandState;
    isRowExpanded ? (obj[categoryId] = false) : (obj[categoryId] = true);
    setExpandState(obj);

    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id) => id !== categoryId)
      : currentExpandedRows.concat(categoryId);

    setExpandedRows(newExpandedRows);
  };

  const handleOrder = (fieldName) => {
    if (!order) {
      setOrder(order);
      setColumnOrder(fieldName);
    } else {
      setOrder(-order);
      setColumnOrder(fieldName);
    }
  };

  if (items) {
    items = items.slice().sort((a, b) => {
      return a[columnOrder] > b[columnOrder] ? -order : order;
    });
  }

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  useEffect(() => {
    setTotalPerPage(totalPerPage);
  }, [totalPerPage]);

  useEffect(() => {
    dispatch(
      getCategoriesList({ currentPage, searchTerm, perPage, ordenation }),
    );
  }, [dispatch, currentPage, searchTerm, perPage, ordenation]);

  const addNewCategorie = () => {
    history.push(urls.ROUTES.CATEGORIES_CREATE.path);
  };

  const editCategoryPage = (id) => {
    setSelectedId(id);
    dispatch(setCategoriesId(id));
    history.push(urls.ROUTES.CATEGORIES_EDIT.path);
  };

  const viewCategoryPage = (id) => {
    dispatch(getCategoryDetail(parseInt(id, 10)));
    setSelectedId(id);
    dispatch(setCategoriesId(id));
    history.push({ pathname: urls.ROUTES.CATEGORIES_VIEW.path, state: 'view' });
  };

  const viewActivities = useCallback(
    (id) => {
      history.push(
        `${urls.ROUTES.ACCESS.items.logsByRecord.path}/${id}/categories`,
      );
    },
    [history],
  );

  const deleteCategoryModal = (id, slug) => {
    setSelectedId(id);
    setSelectedSlug(slug);
    dispatch(showModalDelete());
  };

  const findType = (type) => {
    const found = types?.find((data) => data?.value === type);
    return found?.label;
  };

  const optionsOrdenation = [
    { value: 'id', label: 'ID' },
    { value: 'name', label: 'Nome' },
    { value: 'platforms', label: 'Plataformas' },
  ];

  return (
    <>
      <ModalDeleteConfirmation
        title={intl.messages['textModal.areYouSure']}
        text={intl.messages['textModal.categoryDeleteConfirm']}
        placeholder={intl.messages['labelsInputs.typeSlugCategory']}
        label={intl.messages['labelsInputs.typeSlugCategory']}
        buttonName={intl.messages['buttons.delete']}
        setIsOpen={() => dispatch(dismissModalDelete())}
        isOpen={openDeleteModal}
        fieldValue={selectedSlug}
        fieldLabel={selectedSlug}
        confirmOnClick={() =>
          dispatch(removeCategory({ id: selectedId, page: currentPage }))
        }
        textFieldLabel={intl.messages['labelsInputs.enterSlug']}
      />
      <ModalMultiConfirmation
        title={msg.title}
        text={msg.text}
        setIsOpen={(payload) => {
          dispatch(statusCategoriesModal(payload));
        }}
        isOpen={openStatusModal}
        status={status}
      />
      <PageHeader
        title="Categorias"
        addButtonName="Nova Categoria"
        onClickAdd={addNewCategorie}
        onClickFilter={() => {}}
        addButton={getPermission('categories', permissions, 'create')}
        search
        searchFunc={(searchData) => setSearchTerm(searchData)}
        placeholder="Busque por Nome"
        optionsOrdenation={optionsOrdenation}
        ordenation={ordenation}
        setOrdenation={setOrdenation}
        trash={getPermission('categories', permissions, 'list')}
        trashRedirect={() => history.push(urls.ROUTES.CATEGORIES_DELETED.path)}
      />

      <PageContent>
        <EmptySearch items={items} term={searchTerm}>
          <ContainerTable>
            <Table>
              <TableHead>
                <Tr>
                  <Th onClick={() => handleOrder('id')}>ID</Th>
                  <Th onClick={() => handleOrder('name')}>Nome</Th>
                  <Th onClick={() => handleOrder('type')}>Tipo</Th>
                  <Th onClick={() => handleOrder('platforms')}>Plataformas</Th>
                </Tr>
              </TableHead>
              <TableBody>
                {items &&
                  items.map((item) => (
                    <>
                      <Tr key={item.id}>
                        <Td>{item.id}</Td>

                        <Td
                          style={{
                            position: 'relative',
                            paddingLeft:
                              item?.childrens?.length > 0 ? '0px' : '20px',
                          }}
                        >
                          {item?.childrens?.length > 0 && (
                            <ButtonIcon
                              onClick={(event) =>
                                handleExpandRow(event, item?.id)
                              }
                              style={{
                                position: 'relative',
                              }}
                            >
                              {expandState[item?.id] ? (
                                <img
                                  src={viewDepartmentDownIcon}
                                  alt=""
                                  style={{
                                    position: 'relative',
                                  }}
                                />
                              ) : (
                                <img
                                  src={viewDepartmentRightIcon}
                                  alt=""
                                  style={{
                                    position: 'relative',
                                  }}
                                />
                              )}
                            </ButtonIcon>
                          )}
                          <ButtonIcon style={{ marginRight: '10px' }}>
                            <img src={departmentIcon} alt="" />
                          </ButtonIcon>

                          {item.name}
                        </Td>
                        <Td style={{ position: 'relative' }}>
                          {findType(item.type)}
                        </Td>
                        <Td>
                          {arrayToString(item?.platforms) ===
                          'app,ecommerce,institucional'
                            ? arrayToString(item?.platforms).replace(
                                item?.platforms,
                                'App, Ecommerce, Suvinil',
                              )
                            : arrayToString(item?.platforms) === 'institucional'
                            ? arrayToString(item?.platforms).replace(
                                item?.platforms,
                                'Suvinil',
                              )
                            : capitalizeAllWordsFirstLetter(
                                arrayToString(item?.platforms),
                              )}
                        </Td>
                        {getPermission('categories', permissions, 'update') && (
                          <Td>
                            <PopOver
                              content={
                                <PopOverContent>
                                  <PopItem
                                    onClick={() => {
                                      editCategoryPage(item.id);
                                    }}
                                  >
                                    Editar
                                  </PopItem>
                                  {getPermission(
                                    'categories',
                                    permissions,
                                    'delete',
                                  ) && (
                                    <>
                                      <Divider />
                                      <PopItem
                                        onClick={() =>
                                          deleteCategoryModal(
                                            item.id,
                                            item.slug,
                                          )
                                        }
                                      >
                                        Excluir
                                      </PopItem>{' '}
                                    </>
                                  )}
                                  {getPermission(
                                    'categories',
                                    permissions,
                                    'access',
                                  ) && (
                                    <>
                                      <Divider />
                                      <PopItem
                                        onClick={() =>
                                          viewCategoryPage(item.id)
                                        }
                                      >
                                        Ver detalhes
                                      </PopItem>{' '}
                                    </>
                                  )}
                                  <>
                                    <Divider />
                                    <PopItem
                                      onClick={() => viewActivities(item.id)}
                                    >
                                      Ver atividades
                                    </PopItem>
                                  </>
                                </PopOverContent>
                              }
                            />
                          </Td>
                        )}
                      </Tr>

                      {expandedRows.includes(item?.id)
                        ? item?.childrens?.map((child) => (
                            <>
                              <Tr key={child?.id}>
                                <Td>{child?.id}</Td>
                                <Td
                                  style={{
                                    position: 'relative',
                                    paddingLeft: '40px',
                                  }}
                                >
                                  {child?.subcategories?.length > 0 && (
                                    <ButtonIcon
                                      onClick={(event) =>
                                        handleExpandRow(event, child?.id)
                                      }
                                      style={{
                                        position: 'relative',
                                      }}
                                    >
                                      {expandState[child?.id] ? (
                                        <img
                                          src={viewCategoryDownIcon}
                                          alt=""
                                          style={{
                                            position: 'relative',
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={viewCategoryRightIcon}
                                          alt=""
                                          style={{
                                            position: 'relative',
                                          }}
                                        />
                                      )}
                                    </ButtonIcon>
                                  )}
                                  <ButtonIcon style={{ marginRight: '10px' }}>
                                    <img src={categoryIcon} alt="" />
                                  </ButtonIcon>
                                  {child?.name}
                                </Td>
                                <Td
                                  style={{
                                    position: 'relative',
                                  }}
                                >
                                  {findType(child?.type)}
                                </Td>
                                <Td>
                                  {arrayToString(child?.platforms) ===
                                  'app,ecommerce,institucional'
                                    ? arrayToString(child?.platforms).replace(
                                        child?.platforms,
                                        'App, Ecommerce, Suvinil',
                                      )
                                    : arrayToString(child?.platforms) ===
                                      'institucional'
                                    ? arrayToString(child?.platforms).replace(
                                        child?.platforms,
                                        'Suvinil',
                                      )
                                    : capitalizeAllWordsFirstLetter(
                                        arrayToString(child?.platforms),
                                      )}
                                </Td>
                                {getPermission(
                                  'categories',
                                  permissions,
                                  'update',
                                ) && (
                                  <Td>
                                    <PopOver
                                      content={
                                        <PopOverContent>
                                          <PopItem
                                            onClick={() => {
                                              editCategoryPage(child.id);
                                            }}
                                          >
                                            Editar
                                          </PopItem>
                                          {getPermission(
                                            'categories',
                                            permissions,
                                            'delete',
                                          ) && (
                                            <>
                                              <Divider />
                                              <PopItem
                                                onClick={() =>
                                                  deleteCategoryModal(
                                                    child.id,
                                                    child.slug,
                                                  )
                                                }
                                              >
                                                Excluir
                                              </PopItem>
                                            </>
                                          )}
                                          {getPermission(
                                            'categories',
                                            permissions,
                                            'access',
                                          ) && (
                                            <>
                                              <Divider />
                                              <PopItem
                                                onClick={() => {
                                                  viewCategoryPage(child.id);
                                                }}
                                              >
                                                Ver detalhes
                                              </PopItem>
                                            </>
                                          )}
                                        </PopOverContent>
                                      }
                                    />
                                  </Td>
                                )}
                              </Tr>

                              {expandedRows.includes(child?.id)
                                ? child?.subcategories?.map((subcategorie) => (
                                    <Tr key={subcategorie?.id}>
                                      <Td>{subcategorie?.id}</Td>
                                      <Td
                                        style={{
                                          position: 'relative',
                                          paddingLeft: '80px',
                                        }}
                                      >
                                        <ButtonIcon
                                          style={{
                                            marginLeft: '12px',
                                            marginRight: '10px',
                                          }}
                                        >
                                          <img src={subcategoryIcon} alt="" />
                                        </ButtonIcon>
                                        {subcategorie?.name}
                                      </Td>
                                      <Td
                                        style={{
                                          position: 'relative',
                                        }}
                                      >
                                        {findType(subcategorie?.type)}
                                      </Td>
                                      <Td>
                                        {arrayToString(
                                          subcategorie?.platforms,
                                        ) === 'app,ecommerce,institucional'
                                          ? arrayToString(
                                              subcategorie?.platforms,
                                            ).replace(
                                              subcategorie?.platforms,
                                              'App, Ecommerce, Suvinil',
                                            )
                                          : arrayToString(
                                              subcategorie?.platforms,
                                            ) === 'institucional'
                                          ? arrayToString(
                                              subcategorie?.platforms,
                                            ).replace(
                                              subcategorie?.platforms,
                                              'Suvinil',
                                            )
                                          : capitalizeAllWordsFirstLetter(
                                              arrayToString(
                                                subcategorie?.platforms,
                                              ),
                                            )}
                                      </Td>
                                      {getPermission(
                                        'categories',
                                        permissions,
                                        'update',
                                      ) && (
                                        <Td>
                                          <PopOver
                                            content={
                                              <PopOverContent>
                                                <PopItem
                                                  onClick={() => {
                                                    editCategoryPage(
                                                      subcategorie.id,
                                                    );
                                                  }}
                                                >
                                                  Editar
                                                </PopItem>
                                                <Divider />
                                                <PopItem
                                                  onClick={() =>
                                                    deleteCategoryModal(
                                                      subcategorie.id,
                                                      subcategorie.slug,
                                                    )
                                                  }
                                                >
                                                  Excluir
                                                </PopItem>
                                                {getPermission(
                                                  'categories',
                                                  permissions,
                                                  'access',
                                                ) && (
                                                  <>
                                                    <Divider />
                                                    <PopItem
                                                      onClick={() => {
                                                        viewCategoryPage(
                                                          subcategorie.id,
                                                        );
                                                      }}
                                                    >
                                                      Ver detalhes
                                                    </PopItem>{' '}
                                                  </>
                                                )}
                                              </PopOverContent>
                                            }
                                          />
                                        </Td>
                                      )}
                                    </Tr>
                                  ))
                                : null}
                            </>
                          ))
                        : null}
                    </>
                  ))}
              </TableBody>
            </Table>

            <div>
              <FooterTable
                onPageChange={(newPage) => {
                  setCurrentPage(newPage);
                }}
                totalPages={totalPage}
                currentPage={currentPage}
                totalItems={tableData.length}
                onChangePageSize={(option) => {
                  setTotalPerPage(option);
                }}
                style={{ marginTop: '1em' }}
                {...footerAttrs}
              />
            </div>
          </ContainerTable>
        </EmptySearch>
      </PageContent>
    </>
  );
};

Categories.propTypes = {
  footerAttrs: PropTypes.any,
};

Categories.defaultProps = {
  footerAttrs: '',
};
export default Categories;
