import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import FileInput from 'components/form/FileInput/FileInput';
import TextAreaDescription from 'components/form/TextArea/TextAreaDescription/TextAreaDescription';
import ImageModal from 'components/contexts/modal/ImageModal/ImageModel';
import AsyncSelectPaginate from 'components/form/Select/AsyncSelectPaginate/AsyncSelectPaginate';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { UploadFileListProvider } from 'providers/UploadFileList';
import { UploadFileList } from 'components/structure/UploadFileList';

import * as S from './styles';

export const rules = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DETAILS: 'DETAILS',
};

export const ImageRegisters = ({
  showcaseFileName,
  handleFileChange,
  showcaseAltText,
  handleAltText,
  rule,
  sizeList,
  showcase,
}) => {
  const intl = useIntl();
  const [showcaseFileCache, setShowcaseFileCache] = useState(null);
  const [showcaseFileNameCache, setShowcaseFileNameCache] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleChangeFile = (e) => {
    setShowConfirmationModal(true);
    setShowcaseFileCache(e);
    if (typeof window !== 'undefined') {
      setShowcaseFileNameCache(URL.createObjectURL(e.target.files[0]));
    }
  };

  return (
    <S.Container alignCenter={!showcase}>
      <ImageModal
        imgUrl={showcaseFileNameCache}
        isOpen={showConfirmationModal}
        setIsOpen={setShowConfirmationModal}
        actions={{
          changeFile: handleChangeFile,
          saveFile: () => {
            handleFileChange(showcaseFileCache);
            setShowConfirmationModal(false);
          },
        }}
        isPreview
      />
      <>
        {!showcase ? (
          <p>
            <strong>Cadastrar Imagens do envase do SKU</strong>
            {' (Inserir conforme tamanho do envase)'}
          </p>
        ) : (
          'Imagens'
        )}
      </>

      {!showcase && (
        <Grid
          container
          spacing={4}
          style={{
            flexWrap: 'nowrap',
            flexDirection: 'row',
          }}
        >
          <Grid item xs={6} md={12}>
            <AsyncSelectPaginate
              label={'Unidade do envase'}
              placeholder={'Selecione uma opção'}
              url="categories?type=1"
              department
              clearable
              perPage={100}
              value={[]}
              handleChange={(e) => {}}
            />
          </Grid>
          <Grid xs={6} item md={12}>
            <AsyncSelectPaginate
              label={'Envase'}
              placeholder={'Selecione uma opção'}
              url="categories?type=1"
              department
              clearable
              perPage={100}
              value={[]}
              handleChange={(e) => {}}
            />
          </Grid>
        </Grid>
      )}
      {showcase && (
        <>
          <div className="sub-container">
            <h3>Principal</h3>
            <FileInput
              label="Imagem principal (Showcase)"
              text={`${intl.messages['labelsInputs.toDoUploadImage']} (SHOWCASE)`}
              title="Imagem que sera usada como showcase do produto na loja."
              tooltip={false}
              accept="image/*"
              fileName={showcaseFileName ?? ''}
              getFile={handleChangeFile}
              disabled={rule === rules.DETAILS}
            />
            <TextAreaDescription
              label={intl.messages['labelsInputs.altTextImage']}
              placeholder={intl.messages['labelsInputs.altTextImage']}
              heigth="110px"
              tooltip={false}
              isCount={false}
              handleChange={handleAltText}
              value={showcaseAltText ?? ''}
              disabled={rule === rules.DETAILS}
            />
          </div>
        </>
      )}

      {Boolean(sizeList.length) && (
        <div className="sub-container">
          {sizeList.map(({ id, name }) => {
            return (
              <div className="sub-container">
                <h3>SKU envase {name.replace('.', ',').replaceAll(' ', '')}</h3>
                <UploadFileListProvider>
                  <UploadFileList
                    label="Imagem do envase"
                    weightId={id}
                    name={name}
                    isDisable={rule === rules.DETAILS}
                  />
                </UploadFileListProvider>
              </div>
            );
          })}
        </div>
      )}
    </S.Container>
  );
};

ImageRegisters.propTypes = {
  showcaseFileName: PropTypes.string,
  handleFileChange: PropTypes.func.isRequired,
  showcaseAltText: PropTypes.string,
  handleAltText: PropTypes.func,
  rule: PropTypes.oneOf(Object.values(rules)).isRequired,
  sizeList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  showcase: PropTypes.bool.isRequired,
};

ImageRegisters.defaultProps = {
  showcaseFileName: '',
  showcaseAltText: '',
  sizeList: [],
  handleAltText: () => {},
};
