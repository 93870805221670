/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import Grid from '@material-ui/core/Grid';
import ModalMultiConfirmation from 'components/contexts/modal/ModalMultiConfirmation/ModalMultiConfirmation';
import FileInput from 'components/form/FileInput/FileInput';
import MultiActionButton from 'components/form/MultiActionButton';
import AsyncSelectPaginate from 'components/form/Select/AsyncSelectPaginate/AsyncSelectPaginate';
import SelectSearch from 'components/form/Select/ReactSelect';
import InputText from 'components/form/Text/TextInput/InputText';
import TextAreaDescription from 'components/form/TextArea/TextAreaDescription/TextAreaDescription';
import Toggle from 'components/form/Toggle/Toggle';
import Button from 'components/structure/Button/Button';
import urls from 'constants/urls';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { createSlug } from 'utils/createSlug';
import { errorToast } from 'utils/toasts';
import {
  areas,
  coatings,
  dryingByCoatings,
  dryingByFinishes,
  dryingByTouches,
  finishs,
  internalAreas,
  platforms,
  psqs,
  smells,
  surfaces,
} from '../dataProductsOptions';
import { ButtonsContainer } from './ProductsEditSteps.styles';
import {
  getProductDetail,
  getSimilarDepartmentsOptions,
  putProduct,
  setProductActiveStep,
  setProductsStatus,
  statusProductsModal,
} from 'store/products/products-slice';

const ProductsFirstStep = () => {
  const departmentItems = useSelector((state) => state.products.items);
  const { openStatusModal, status, msg, selectId, product, url } = useSelector(
    (state) => state.products,
  );
  const [name, setName] = useState('');
  const [department, setDepartment] = useState('');
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [similarDepartment, setSimilarDepartment] = useState('');
  const [similarCategory, setSimilarCategory] = useState('');
  const [similarSubCategory, setSimilarSubCategory] = useState('');
  const [platform, setPlatform] = useState([]);
  const [siteName, setSiteName] = useState('');
  const [description, setDescription] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [active, setActive] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [brand, setBrand] = useState('Suvinil');
  const [altImage, setAltImage] = useState('');
  const [fileName, setFileName] = useState('');
  const [imageError, setImageError] = useState(null);
  const [dataImage, setDataImage] = useState([{}]);
  const [showModalCancel, setShowModalCancel] = useState(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [categoryItems, setCategoryItems] = useState([]);
  const [subCategoryItems, setSubCategoryItems] = useState([]);
  const [similarCategoryItems, setSimilarCategoryItems] = useState([]);
  const [similarSubCategoryItems, setSimilarSubCategoryItems] = useState([]);
  const [statusButton, setStatusButton] = useState(false);
  const [psq, setPsq] = useState('');
  const [readyToUse, setReadyToUse] = useState(false);
  const [area, setArea] = useState([]);
  const [internalArea, setInternalArea] = useState([]);
  const [finish, setFinish] = useState('');
  const [coating, setCoating] = useState('');
  const [dryingByTouch, setDryingByTouch] = useState('');
  const [dryingByCoating, setDryingByCoating] = useState('');
  const [dryingByFinish, setDryingByFinish] = useState('');
  const [smell, setSmell] = useState('');
  const [surface, setSurface] = useState('');
  const [dilution, setDilution] = useState('');
  const [statusForm, setStatus] = useState({
    type: '',
  });

  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const cancelProductEdit = () => {
    history.push(urls.ROUTES.CATALOGUE.items.products.path);
  };

  useEffect(() => {
    if (selectId === 0) {
      history.push(urls.ROUTES.CATALOGUE.items.products.path);
    } else {
      dispatch(getProductDetail(parseInt(selectId, 10)));
    }
  }, [selectId, dispatch, history]);

  const findCategory = (
    level,
    categoriesArray,
    valueToCompare,
    main,
    secondary,
  ) => {
    let founded = '';
    founded = categoriesArray.find(
      (categorie) =>
        categorie.type === level && categorie.productToCategory.main === main,
    );
    if (level === 2) {
      if (!secondary) {
        founded = categoriesArray.find(
          (categorie) =>
            categorie.type === level && categorie.category === valueToCompare, // compare with department
        );
      } else {
        founded = categoriesArray.find(
          (categorie) =>
            categorie.type === level && categorie.category === valueToCompare, // compare with similar department
        );
      }
    } else if (level === 3) {
      if (!secondary) {
        founded = categoriesArray.find(
          (categorie) =>
            categorie.type === level && categorie.category === valueToCompare, // compare with category
        );
      } else {
        founded = categoriesArray.find(
          (categorie) =>
            categorie.type === level && categorie.category === valueToCompare, // compare with similarCategory
        );
      }
    }
    if (founded && level !== 1) {
      return founded.id;
    }
    if (founded && level === 1) {
      return founded;
    }
    return '';
  };

  const convertPSQ = (psqToConvert) => {
    let psqConverted = psqToConvert;

    if (psq === 'Premium') {
      psqConverted = 'premium';
    } else if (psq === 'Standard') {
      psqConverted = 'standard';
    } else if (psq === 'Econômico') {
      psqConverted = 'economic';
    } else if (psq === 'Super Premium') {
      psqConverted = 'superPremium';
    }

    return psqConverted;
  };

  useEffect(() => {
    setName(product.name);
    const dep = findCategory(1, product.categories, null, true);
    const similarDep = findCategory(
      1,
      product?.categories?.map((value) => value),
      null,
      false,
    );
    setDepartment(dep);
    setSimilarDepartment(similarDep);

    setPlatform(product?.platforms?.map((value) => value));
    setSiteName(product.siteTitle);
    setPsq(convertPSQ(product.psq));
    setReadyToUse(product.alreadyDone);
    setDescription(product.description);
    setShortDescription(product.shortDescription);
    setActive(product.active);
    setIsVisible(product.isVisible);
    setBrand(product.brand);

    setArea(product?.area?.map((value) => value));
    setInternalArea(product?.internalArea?.map((value) => value));
    setFinish(product.finish);
    setCoating(product.coating);
    setDryingByTouch(product.dryingByTouch);
    setDryingByCoating(product.dryingByCoating);
    setDryingByFinish(product.dryingByFinish);
    setSmell(product.smell);
    setSurface(product.surface);
    setDilution(product.dilution);

    if (product?.images?.showcase) {
      setAltImage(product?.images?.showcase[0]?.alternativeText);
      setFileName(product?.images?.showcase[0]?.fullUrl);
    }
  }, [product, dispatch]);

  useEffect(() => {
    let categoryFound = '';
    let categorySimilarFound = '';
    if (department) {
      categoryFound = findCategory(
        2,
        product?.categories?.map((value) => value),
        department.id,
        false,
        false,
      );
      categorySimilarFound = findCategory(
        2,
        product?.categories?.map((value) => value),
        similarDepartment.id,
        false,
        true,
      );
      setCategory(categoryFound);
      setSimilarCategory(categorySimilarFound);
    }

    if (categoryFound) {
      const subCategoryFound = findCategory(
        3,
        product?.categories?.map((value) => value),
        categoryFound,
        false,
        false,
      );
      setSubCategory(subCategoryFound);
    }
    if (categorySimilarFound) {
      const subCategoryFound = findCategory(
        3,
        product?.categories?.map((value) => value),
        categorySimilarFound,
        false,
        true,
      );
      setSimilarSubCategory(subCategoryFound);
    }
  }, [product, department, similarDepartment]);

  useEffect(() => {
    if (department !== '') {
      let data = departmentItems.find(
        (departments) => departments.value === department.id,
      );
      let aux = data?.items?.map((option) => ({
        value: option?.id,
        label: option?.name,
        subcategories: option?.subcategories,
      }));
      setCategoryItems(aux);
      return;
    }
    setCategory('');
  }, [departmentItems, department]);

  useEffect(() => {
    if (category !== '') {
      let data = categoryItems?.find(
        (categories) => categories?.value === category,
      );
      if (data !== undefined) {
        let aux = data?.subcategories?.map((option) => ({
          value: option?.id,
          label: option?.name,
        }));
        setSubCategoryItems(aux);
      } else {
        setSubCategoryItems([]);
      }
      return;
    }
    setSubCategory('');
  }, [category, categoryItems]);

  useEffect(() => {
    dispatch(getSimilarDepartmentsOptions());
  }, [dispatch]);

  useEffect(() => {
    if (similarDepartment !== '') {
      let data = departmentItems.find(
        (departments) => departments.value === similarDepartment.id,
      );
      let aux = data?.items?.map((option) => ({
        value: option?.id,
        label: option?.name,
        subcategories: option?.subcategories,
      }));
      setSimilarCategoryItems(aux);
    }
  }, [departmentItems, similarDepartment]);

  useEffect(() => {
    if (similarCategory !== '') {
      let data = similarCategoryItems?.find(
        (categories) => categories?.value === similarCategory,
      );
      if (data !== undefined) {
        let aux = data?.subcategories?.map((option) => ({
          value: option?.id,
          label: option?.name,
        }));
        setSimilarSubCategoryItems(aux);
      } else {
        setSimilarSubCategoryItems([]);
      }
    }
  }, [similarCategory, similarCategoryItems]);

  const isImage = (extension) => {
    switch (extension.toLowerCase()) {
      case 'jpg':
      case 'gif':
      case 'bmp':
      case 'jpeg':
      case 'webp':
      case 'png':
      case 'svg':
        return true;
      default:
        return false;
    }
  };

  const getImageFile = (event) => {
    if (event.target.files.length !== 0) {
      const file = event.target.files[0];
      const filenames = file.name.split('.');
      const fileType = filenames[filenames.length - 1];
      if (file.size < 1.5 * 1024 * 1024) {
        if (isImage(fileType)) {
          setFileName(file.name);
          setDataImage({ ...dataImage, image: file });
          setImageError(null);
        } else {
          setImageError(
            errorToast(
              'A imagem precisa estar em um desses formatos: .png, .jpg, .gif, etc...',
            ),
          );
        }
      } else {
        setImageError(errorToast('A imagem deve ter no máximo 1.5 MB'));
      }
    }
  };

  function validate() {
    if (!name)
      return setStatus({
        type: 'error',
      });
    if (!category)
      return setStatus({
        type: 'error',
      });
    if (!department)
      return setStatus({
        type: 'error',
      });
    if (!platform)
      return setStatus({
        type: 'error',
      });
    if (!siteName)
      return setStatus({
        type: 'error',
      });
    if (!description)
      return setStatus({
        type: 'error',
      });
    if (!shortDescription)
      return setStatus({
        type: 'error',
      });
    if (!brand)
      return setStatus({
        type: 'error',
      });
    return true;
  }

  function verifyChanges() {
    if (name !== product.name) {
      return false;
    }
    if (
      department !==
      findCategory(
        1,
        product?.categories?.map((value) => value),
        true,
      )
    ) {
      return false;
    }
    if (
      similarDepartment !==
      findCategory(
        1,
        product?.categories?.map((value) => value),
        false,
      )
    ) {
      return false;
    }
    if (platform[0] !== product?.platforms[0]) {
      return false;
    }
    if (siteName !== product.siteTitle) {
      return false;
    }
    if (psq !== product.psq) {
      return false;
    }
    if (readyToUse !== product.readyToUse) {
      return false;
    }
    if (description !== product.description) {
      return false;
    }
    if (shortDescription !== product.shortDescription) {
      return false;
    }
    if (active !== product.active) {
      return false;
    }
    if (isVisible !== product.isVisible) {
      return false;
    }
    if (brand !== product.brand) {
      return false;
    }
    if (fileName !== product.images.showcase[0].fullUrl) {
      return false;
    }
    if (altImage !== product.images.showcase[1].alternativeText) {
      return false;
    }
    return true;
  }

  const saveStepOneEdit = () => {
    let arrayCategories = [
      { id: department.id, main: true },
      { id: similarDepartment.id, main: false },
      { id: category, main: false },
      { id: similarCategory, main: false },
      { id: subCategory, main: false },
      { id: similarSubCategory, main: false },
    ];

    const arrayAltImage = [];
    arrayAltImage.push(altImage);

    if (!validate()) {
      return;
    }

    const formData = new FormData();

    formData.append('name', name);
    formData.append(
      'categories',
      JSON.stringify(
        arrayCategories.filter(
          (categoryData) => categoryData.id !== '' && categoryData.id,
        ),
      ),
    );
    formData.append('platforms', JSON.stringify(platform));
    formData.append('siteTitle', siteName);
    formData.append('description', description);
    formData.append('shortDescription', shortDescription);
    formData.append('active', active);
    formData.append('isVisible', isVisible);
    formData.append('image', dataImage.image);
    formData.append('brand', brand);
    formData.append('alreadyDone', readyToUse);
    formData.append('coating', coating);
    formData.append('dryingByCoating', dryingByCoating);
    formData.append('dryingByTouch', dryingByTouch);
    formData.append('dryingByFinish', dryingByFinish);
    formData.append('dilution', dilution);
    formData.append('surface', surface);
    formData.append('smell', smell);

    if (dataImage.image !== undefined) {
      formData.append('alternativeText', JSON.stringify(arrayAltImage));
    }

    formData.append('psq', convertPSQ(psq));

    dispatch(putProduct({ info: formData, id: selectId, activeStep: 0 }));

    dispatch(statusProductsModal(false));
  };

  return (
    <>
      <ModalMultiConfirmation
        title={intl.messages['textModal.editProductConfirm']}
        isOpen={showModalConfirmation}
        setIsOpen={setShowModalConfirmation}
        buttonNameBack={intl.messages['buttons.not']}
        buttonName={intl.messages['buttons.yes']}
        status="confirm"
        confirmOnClick={saveStepOneEdit}
      />
      <ModalMultiConfirmation
        title={intl.messages['textModal.deleteProductEdit']}
        text={intl.messages['textModal.productWillBeLost']}
        isOpen={showModalCancel}
        setIsOpen={setShowModalCancel}
        buttonNameBack={intl.messages['buttons.not']}
        buttonName={intl.messages['buttons.yes']}
        confirmOnClick={cancelProductEdit}
        status="info"
      />
      <ModalMultiConfirmation
        title={msg.title}
        text={msg.text}
        setIsOpen={(payload) => {
          dispatch(statusProductsModal(payload));
          if (status !== 'error') {
            dispatch(setProductActiveStep(1));
          }
        }}
        isOpen={openStatusModal}
        status={status}
      />
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid item md={12}>
              <InputText
                placeholder={intl.messages['labelsInputs.typeYourName']}
                label={intl.messages['labelsInputs.typeYourName']}
                value={name}
                handleChange={(value) => {
                  setName(value);
                  setStatusButton(true);
                }}
                hasTooltip
                textTooltip="Este é o nome do produto, que aparece na página e também é enviado para VTEX"
                error={
                  statusForm.type === 'error'
                    ? 'Campo Nome deve ser preenchido'
                    : ''
                }
                isError={name === '' && statusForm.type}
              />
            </Grid>
            <Grid item md={12}>
              <InputText
                placeholder={intl.messages['labelsInputs.titleExample']}
                label={intl.messages['labelsInputs.slug']}
                disabled
                value={createSlug(name)}
              />
            </Grid>
            <Grid item md={12}>
              <AsyncSelectPaginate
                zIndex="160"
                label={intl.messages['labelsInputs.department']}
                placeholder={intl.messages['labelsInputs.select']}
                url="categories?type=1"
                department
                clearable
                perPage={100}
                value={department}
                handleChange={(e) => {
                  setDepartment(e);
                  setStatusButton(true);
                }}
                error={
                  statusForm.type === 'error'
                    ? 'Campo Departamento deve ser preenchido'
                    : ''
                }
                isError={department === '' && statusForm.type}
              />
            </Grid>
            <Grid item md={12}>
              <SelectSearch
                zIndex="150"
                label={intl.messages['labelsInputs.category']}
                placeholder={intl.messages['labelsInputs.select']}
                options={categoryItems}
                tooltip
                clearable
                title="A categoria é uma subdivisão de departamento"
                error={
                  statusForm.type === 'error'
                    ? 'Campo Categoria deve ser preenchido'
                    : ''
                }
                defaultOption={category}
                handleChange={(e) => {
                  setCategory(e);
                  setStatusButton(true);
                }}
                isError={category === '' && statusForm.type}
                disabled={department === ''}
              />
            </Grid>
            <Grid item md={12}>
              <SelectSearch
                zIndex="140"
                label={intl.messages['labelsInputs.subCategory']}
                placeholder={intl.messages['labelsInputs.select']}
                options={subCategoryItems}
                tooltip
                clearable
                title="A subcategoria é uma subdivisão de categoria"
                defaultOption={subCategory}
                handleChange={(e) => {
                  setSubCategory(e);
                  setStatusButton(true);
                }}
                disabled={category === ''}
                optional
              />
            </Grid>

            <Grid item md={12}>
              <AsyncSelectPaginate
                zIndex="130"
                label={intl.messages['labelsInputs.similarDepartment']}
                placeholder={intl.messages['labelsInputs.select']}
                url="categories?type=1"
                department
                perPage={100}
                value={similarDepartment}
                handleChange={(e) => {
                  setSimilarDepartment(e);
                  setStatusButton(true);
                }}
                optional
              />
            </Grid>
            <Grid item md={12}>
              <SelectSearch
                zIndex="120"
                label={intl.messages['labelsInputs.similarCategory']}
                placeholder={intl.messages['labelsInputs.select']}
                options={similarCategoryItems}
                tooltip
                title="A categoria é uma subdivisão de departamento"
                defaultOption={similarCategory}
                handleChange={(e) => {
                  setSimilarCategory(e);
                  setStatusButton(true);
                }}
                disabled={similarDepartment === ''}
                optional
              />
            </Grid>
            <Grid item md={12}>
              <SelectSearch
                zIndex="110"
                label={intl.messages['labelsInputs.similarSubCategory']}
                placeholder={intl.messages['labelsInputs.select']}
                options={similarSubCategoryItems}
                tooltip
                title="A subcategoria é uma subdivisão de categoria"
                defaultOption={similarSubCategory}
                handleChange={(e) => {
                  setSimilarSubCategory(e);
                  setStatusButton(true);
                }}
                disabled={similarCategory === ''}
                optional
              />
            </Grid>
            <Grid item md={12}>
              <SelectSearch
                zIndex="100"
                label={intl.messages['labelsInputs.platforms']}
                placeholder={intl.messages['labelsInputs.select']}
                options={platforms}
                defaultMultiOptions={platform}
                handleChange={(e) => {
                  setPlatform(e);
                  setStatusButton(true);
                }}
                multi
                error={
                  statusForm.type === 'error'
                    ? 'Campo Plataformas deve ser preenchido'
                    : ''
                }
                isError={platform.length === 0 && statusForm.type}
              />
            </Grid>

            <Grid item md={12}>
              <InputText
                placeholder={intl.messages['labelsInputs.siteTitle']}
                label={intl.messages['labelsInputs.siteTitle']}
                value={siteName}
                handleChange={(value) => {
                  setSiteName(value);
                  setStatusButton(true);
                }}
                error={
                  statusForm.type === 'error'
                    ? 'Campo Site Title deve ser preenchido'
                    : ''
                }
                isError={siteName === '' && statusForm.type}
              />
            </Grid>
            <Grid item md={12}>
              <SelectSearch
                zIndex="90"
                label={intl.messages['labelsInputs.psq']}
                placeholder={intl.messages['labelsInputs.select']}
                options={psqs}
                defaultOption={psq}
                handleChange={(e) => {
                  setPsq(e);
                  setStatusButton(true);
                }}
              />
            </Grid>

            <Grid item md={12}>
              <SelectSearch
                zIndex="80"
                label={intl.messages['labelsInputs.area']}
                placeholder={intl.messages['labelsInputs.select']}
                options={areas}
                defaultMultiOptions={area}
                handleChange={(e) => {
                  setArea(e);
                  setStatusButton(true);
                }}
                multi
                error={
                  statusForm.type === 'error'
                    ? 'Campo Área deve ser preenchido'
                    : ''
                }
                isError={platform.length === 0 && statusForm.type}
              />
            </Grid>

            <Grid item md={12}>
              <SelectSearch
                zIndex="70"
                label={intl.messages['labelsInputs.internalArea']}
                placeholder={intl.messages['labelsInputs.select']}
                options={internalAreas}
                defaultMultiOptions={internalArea}
                handleChange={(e) => {
                  setInternalArea(e);
                  setStatusButton(true);
                }}
                multi
                error={
                  statusForm.type === 'error'
                    ? 'Campo Área Interna deve ser preenchido'
                    : ''
                }
                isError={platform.length === 0 && statusForm.type}
              />
            </Grid>

            <Grid item md={12}>
              <SelectSearch
                zIndex="60"
                label={intl.messages['labelsInputs.finishes']}
                placeholder={intl.messages['labelsInputs.select']}
                options={finishs}
                defaultOption={finish}
                handleChange={(e) => {
                  setFinish(e);
                  setStatusButton(true);
                }}
                error={
                  statusForm.type === 'error'
                    ? 'Campo Acabamentos deve ser preenchido'
                    : ''
                }
                isError={finish === '' && statusForm.type}
              />
            </Grid>

            <Grid item md={12}>
              <SelectSearch
                zIndex="50"
                label={intl.messages['labelsInputs.coats']}
                placeholder={intl.messages['labelsInputs.select']}
                options={coatings}
                defaultOption={coating}
                handleChange={(e) => {
                  setCoating(e);
                  setStatusButton(true);
                }}
                error={
                  statusForm.type === 'error'
                    ? 'Campo Demãos deve ser preenchido'
                    : ''
                }
                isError={coating === '' && statusForm.type}
              />
            </Grid>

            <Grid item md={12}>
              <SelectSearch
                zIndex="40"
                label={intl.messages['labelsInputs.dryingByTouch']}
                placeholder={intl.messages['labelsInputs.select']}
                options={dryingByTouches}
                defaultOption={dryingByTouch}
                handleChange={(e) => {
                  setDryingByTouch(e);
                  setStatusButton(true);
                }}
                error={
                  statusForm.type === 'error'
                    ? 'Campo Secagem ao toque deve ser preenchido'
                    : ''
                }
                isError={dryingByTouch === '' && statusForm.type}
              />
            </Grid>

            <Grid item md={12}>
              <SelectSearch
                zIndex="30"
                label={intl.messages['labelsInputs.dryingByCoating']}
                placeholder={intl.messages['labelsInputs.select']}
                options={dryingByCoatings}
                defaultOption={dryingByCoating}
                handleChange={(e) => {
                  setDryingByCoating(e);
                  setStatusButton(true);
                }}
                error={
                  statusForm.type === 'error'
                    ? 'Campo Secagem entre demão deve ser preenchido'
                    : ''
                }
                isError={dryingByCoating === '' && statusForm.type}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <SelectSearch
                zIndex="50"
                label={intl.messages['labelsInputs.dryingByFinish']}
                placeholder={intl.messages['labelsInputs.select']}
                options={dryingByFinishes}
                defaultOption={dryingByFinish}
                handleChange={(e) => {
                  setDryingByFinish(e);
                  setStatusButton(true);
                }}
                error={
                  statusForm.type === 'error'
                    ? 'Campo Secagem final deve ser preenchido'
                    : ''
                }
                isError={dryingByFinish === '' && statusForm.type}
              />
            </Grid>
            <Grid item md={12}>
              <SelectSearch
                zIndex="40"
                label={intl.messages['labelsInputs.smell']}
                placeholder={intl.messages['labelsInputs.select']}
                options={smells}
                defaultOption={smell}
                handleChange={(e) => {
                  setSmell(e);
                  setStatusButton(true);
                }}
                error={
                  statusForm.type === 'error'
                    ? 'Campo Cheiro deve ser preenchido'
                    : ''
                }
                isError={smell === '' && statusForm.type}
              />
            </Grid>
            <Grid item md={12}>
              <SelectSearch
                zIndex="30"
                label={intl.messages['labelsInputs.surfaces']}
                placeholder={intl.messages['labelsInputs.select']}
                options={surfaces}
                defaultOption={surface}
                handleChange={(e) => {
                  setSurface(e);
                  setStatusButton(true);
                }}
                error={
                  statusForm.type === 'error'
                    ? 'Campo Superfícies deve ser preenchido'
                    : ''
                }
                isError={surface === '' && statusForm.type}
              />
            </Grid>
            <Grid item md={12}>
              <TextAreaDescription
                label={intl.messages['labelsInputs.dilution']}
                placeholder={intl.messages['labelsInputs.description']}
                value={dilution}
                handleChange={(value) => {
                  setDilution(value);
                  setStatusButton(true);
                }}
                heigth="156px"
                maxLength={1300}
                tooltip={false}
                error={
                  statusForm.type === 'error'
                    ? 'Campo Diluição deve ser preenchido'
                    : ''
                }
                isError={dilution === '' && statusForm.type}
              />
            </Grid>

            <Grid item md={12}>
              <TextAreaDescription
                label={intl.messages['labelsInputs.description']}
                placeholder={intl.messages['labelsInputs.description']}
                value={description}
                handleChange={(value) => {
                  setDescription(value);
                  setStatusButton(true);
                }}
                heigth="156px"
                maxLength={1300}
                tooltip={false}
                error={
                  statusForm.type === 'error'
                    ? 'Campo Descrição deve ser preenchido'
                    : ''
                }
                isError={description === '' && statusForm.type}
              />
            </Grid>
            <Grid item md={12}>
              <TextAreaDescription
                label={intl.messages['labelsInputs.shortDescription']}
                placeholder={intl.messages['labelsInputs.shortDescription']}
                value={shortDescription}
                handleChange={(value) => {
                  setShortDescription(value);
                  setStatusButton(true);
                }}
                heigth="110px"
                maxLength={250}
                tooltip={false}
                error={
                  statusForm.type === 'error'
                    ? 'Campo Descrição Curta deve ser preenchido'
                    : ''
                }
                isError={shortDescription === '' && statusForm.type}
              />
            </Grid>
            <Grid item md={12}>
              <Toggle
                label={intl.messages['labelsInputs.active']}
                checked={active}
                onChange={(e) => {
                  setActive(e.target.checked);
                  setStatusButton(true);
                }}
                regular
              />
            </Grid>
            <Grid item md={12} style={{ paddingTop: '10px' }}>
              <Toggle
                label={intl.messages['labelsInputs.pdpVisible']}
                checked={isVisible}
                onChange={(e) => {
                  setIsVisible(e.target.checked);
                  setStatusButton(true);
                }}
                regular
              />
            </Grid>
            <Grid item md={12}>
              <InputText
                label={intl.messages['labelsInputs.brand']}
                placeholder={intl.messages['labelsInputs.select']}
                value={brand}
                handleChange={(value) => {
                  setBrand(value);
                  setStatusButton(true);
                }}
                error={
                  statusForm.type === 'error'
                    ? 'Campo Marca deve ser preenchido'
                    : ''
                }
                isError={brand === '' && statusForm.type}
              />
            </Grid>
            <Grid item md={12}>
              <Toggle
                label={intl.messages['labelsInputs.readyToUse']}
                checked={readyToUse}
                onChange={(e) => {
                  setStatusButton(true);
                  setReadyToUse(e.target.checked);
                }}
                regular
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <ButtonsContainer>
        <Grid container spacing={2}>
          <Grid item md={6} sm={12} />
          <Grid item md={3} sm={12}>
            <Button
              btnType="secondary"
              width="100%"
              borderRadius="4px"
              fontWeight="600"
              onClick={() => {
                if (!verifyChanges()) {
                  setShowModalCancel(true);
                } else {
                  cancelProductEdit();
                }
              }}
            >
              {intl.messages['buttons.cancel']}
            </Button>
          </Grid>
          {statusButton ? (
            <Grid item md={3} sm={12}>
              <MultiActionButton
                primaryLabel={intl.messages['buttons.toSaveAndAdvance']}
                primaryfunction={() => {
                  if (!validate()) {
                    setShowModalConfirmation(false);
                  } else {
                    setShowModalConfirmation(true);
                  }
                }}
                secondaryLabel={intl.messages['buttons.advanceNotSave']}
                secondaryFunction={() => {
                  if (!validate()) return;
                  dispatch(setProductsStatus('first-step-success'));
                  dispatch(setProductActiveStep(1));
                }}
              />
            </Grid>
          ) : (
            <Grid item md={3} sm={12}>
              <Button
                btnType="primary"
                width="100%"
                borderRadius="4px"
                fontWeight="600"
                onClick={() => {
                  if (validate()) {
                    dispatch(setProductActiveStep(1));
                  }
                }}
              >
                {intl.messages['buttons.advance']}
              </Button>
            </Grid>
          )}
        </Grid>
      </ButtonsContainer>
    </>
  );
};

export default ProductsFirstStep;
