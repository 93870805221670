import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import StepProgress from 'components/contexts/StepProgress/StepProgress';
import FormContainerSecondary from 'components/structure/FormContainerSecondary/FormContainerSecondary';
import { getPermission } from 'utils/permissions';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { StepContainerBox, StepContainer, } from './Skus.styles';
import FirstStep from "./SkuStep/View/SkuView";
import { ImageRegisters, rules } from 'containers/UploadFilesImage';

export default function SkuCreateStep() {
  const { permissions } = useSelector((state) => state.auth);
  const [currentStep, setCurrentStep] = useState(0);
  const [sizeList, setSizeList] = useState([]);
  const intl = useIntl();
  const history = useHistory();
  const steps = ['SKU', 'CONTEÚDO'];

  useEffect(() => {
    if (getPermission('products', permissions, 'access') === false) {
      history.push('/');
    }
  }, [history, permissions]);

  return (
    <StepContainerBox>
      <StepContainer>
        <StepProgress activeStep={currentStep} steps={steps} />
      </StepContainer>
      <FormContainerSecondary title={currentStep === 0 ? intl.messages['textHeader.createSkus'] : ""}>
        {currentStep === 0 && <FirstStep setStep={setCurrentStep} setSizeList={setSizeList} />}
        {currentStep === 1 && <ImageRegisters
          rule={rules.DETAILS}
          sizeList={sizeList}
        />}
      </FormContainerSecondary>
    </StepContainerBox>
  )
}