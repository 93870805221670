/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import Grid from '@material-ui/core/Grid';
import ModalMultiConfirmation from 'components/contexts/modal/ModalMultiConfirmation/ModalMultiConfirmation';
import FileInput from 'components/form/FileInput/FileInput';
import SelectSearch from 'components/form/Select/ReactSelect';
import InputText from 'components/form/Text/TextInput/InputText';
import TextAreaDescription from 'components/form/TextArea/TextAreaDescription/TextAreaDescription';
import Toggle from 'components/form/Toggle/Toggle';
import Button from 'components/structure/Button/Button';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { createSlug } from 'utils/createSlug';
import {
  areas,
  coatings,
  dryingByCoatings,
  dryingByFinishes,
  dryingByTouches,
  finishs,
  internalAreas,
  platforms,
  psqs,
  smells,
  surfaces,
} from '../dataProductsOptions';
import { ButtonsContainer } from '../ProductsEditSteps/ProductsEditSteps.styles';
import {
  getDepartmentsOptions,
  getSimilarDepartmentsOptions,
  setProductActiveStep,
  statusProductsModal,
} from 'store/products/products-slice';

const ProductsViewFirstStep = () => {
  const departmentItems = useSelector((state) => state.products.items);
  const { openStatusModal, status, msg, selectId, product } = useSelector(
    (state) => state.products,
  );
  const [name, setName] = useState('');
  const [department, setDepartment] = useState('');
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [similarDepartment, setSimilarDepartment] = useState('');
  const [similarCategory, setSimilarCategory] = useState('');
  const [similarSubCategory, setSimilarSubCategory] = useState('');
  const [platform, setPlatform] = useState([]);
  const [siteName, setSiteName] = useState('');
  const [description, setDescription] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [active, setActive] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [brand, setBrand] = useState('Suvinil');
  const [altImage, setAltImage] = useState('');
  const [fileName, setFileName] = useState('');
  const [area, setArea] = useState([]);
  const [internalArea, setInternalArea] = useState([]);
  const [finish, setFinish] = useState('');
  const [coating, setCoating] = useState('');
  const [dryingByTouch, setDryingByTouch] = useState('');
  const [dryingByCoating, setDryingByCoating] = useState('');
  const [dryingByFinish, setDryingByFinish] = useState('');
  const [smell, setSmell] = useState('');
  const [surface, setSurface] = useState('');
  const [dilution, setDilution] = useState('');
  const [categoryItems, setCategoryItems] = useState([]);
  const [subCategoryItems, setSubCategoryItems] = useState([]);
  const [similarCategoryItems, setSimilarCategoryItems] = useState([]);
  const [similarSubCategoryItems, setSimilarSubCategoryItems] = useState([]);
  const [psq, setPsq] = useState('');
  const [readyToUse, setReadyToUse] = useState(false);

  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const cancelProductEdit = () => {
    history.goBack();
  };

  const convertPSQ = (psqToConvert) => {
    let psqConverted = psqToConvert;

    if (psq === 'Premium') {
      psqConverted = 'premium';
    } else if (psq === 'Standard') {
      psqConverted = 'standard';
    } else if (psq === 'Econômico') {
      psqConverted = 'economic';
    } else if (psq === 'Super Premium') {
      psqConverted = 'superPremium';
    }

    return psqConverted;
  };

  useEffect(() => {
    dispatch(getDepartmentsOptions());
  }, [dispatch]);

  const findCategory = useCallback(
    (level, categoriesArray, main, secondary) => {
      let founded = '';
      founded = categoriesArray.find(
        (categorie) =>
          categorie.type === level && categorie.productToCategory.main === main,
      );
      if (level === 2) {
        if (!secondary) {
          founded = categoriesArray.find(
            (categorie) =>
              categorie.type === level && categorie.category === department,
          );
        } else {
          founded = categoriesArray.find(
            (categorie) =>
              categorie.type === level &&
              categorie.category === similarDepartment,
          );
        }
      } else if (level === 3) {
        if (!secondary) {
          founded = categoriesArray.find(
            (categorie) =>
              categorie.type === level && categorie.category === category,
          );
        } else {
          founded = categoriesArray.find(
            (categorie) =>
              categorie.type === level &&
              categorie.category === similarCategory,
          );
        }
      }
      if (founded) {
        return founded.id;
      }
      return '';
    },
    [category, department, similarCategory, similarDepartment],
  );

  useEffect(() => {
    setName(product.name);
    setDepartment(
      findCategory(
        1,
        product?.categories?.map((value) => value),
        true,
      ),
    );

    setSimilarDepartment(
      findCategory(
        1,
        product?.categories?.map((value) => value),
        false,
      ),
    );

    setPlatform(product?.platforms?.map((value) => value));
    setSiteName(product.siteTitle);
    setPsq(convertPSQ(product.psq));
    setReadyToUse(product.alreadyDone);
    setDescription(product.description);
    setShortDescription(product.shortDescription);
    setActive(product.active);
    setIsVisible(product.isVisible);
    setBrand(product.brand);

    setArea(product?.area?.map((value) => value));
    setInternalArea(product?.internalArea?.map((value) => value));
    setFinish(product.finish);
    setCoating(product.coating);
    setDryingByTouch(product.dryingByTouch);
    setDryingByCoating(product.dryingByCoating);
    setDryingByFinish(product.dryingByFinish);
    setSmell(product.smell);
    setSurface(product.surface);
    setDilution(product.dilution);

    if (product?.images?.showcase) {
      setAltImage(product?.images?.showcase[1]?.alternativeText);
      setFileName(product?.images?.showcase[0]?.fullUrl);
    }
  }, [product, dispatch]);

  useEffect(() => {
    setCategory(
      findCategory(
        2,
        product?.categories?.map((value) => value),
        false,
        false,
      ),
    );
    setSubCategory(
      findCategory(
        3,
        product?.categories?.map((value) => value),
        false,
        false,
      ),
    );
    setSimilarCategory(
      findCategory(
        2,
        product?.categories?.map((value) => value),
        false,
        true,
      ),
    );
    setSimilarSubCategory(
      findCategory(
        3,
        product?.categories?.map((value) => value),
        false,
        true,
      ),
    );
  }, [
    product,
    department,
    similarDepartment,
    category,
    similarCategory,
    findCategory,
  ]);

  useEffect(() => {
    if (department !== '') {
      let data = departmentItems.find(
        (departments) => departments.value === department,
      );
      let aux = data?.items?.map((option) => ({
        value: option?.id,
        label: option?.name,
        subcategories: option?.subcategories,
      }));
      setCategoryItems(aux);
    }
  }, [departmentItems, department]);

  useEffect(() => {
    if (category !== '') {
      let data = categoryItems?.find(
        (categories) => categories?.value === category,
      );
      if (data !== undefined) {
        let aux = data?.subcategories?.map((option) => ({
          value: option?.id,
          label: option?.name,
        }));
        setSubCategoryItems(aux);
      } else {
        setSubCategoryItems([]);
      }
    }
  }, [category, categoryItems]);

  useEffect(() => {
    dispatch(getSimilarDepartmentsOptions());
  }, [dispatch]);

  useEffect(() => {
    if (similarDepartment !== '') {
      let data = departmentItems.find(
        (departments) => departments.value === similarDepartment,
      );
      let aux = data?.items?.map((option) => ({
        value: option?.id,
        label: option?.name,
        subcategories: option?.subcategories,
      }));
      setSimilarCategoryItems(aux);
    }
  }, [departmentItems, similarDepartment]);

  useEffect(() => {
    if (similarCategory !== '') {
      let data = similarCategoryItems?.find(
        (categories) => categories?.value === similarCategory,
      );
      if (data !== undefined) {
        let aux = data?.subcategories?.map((option) => ({
          value: option?.id,
          label: option?.name,
        }));
        setSimilarSubCategoryItems(aux);
      } else {
        setSimilarSubCategoryItems([]);
      }
    }
  }, [similarCategory, similarCategoryItems]);

  return (
    <>
      <ModalMultiConfirmation
        title={msg.title}
        text={msg.text}
        setIsOpen={(payload) => {
          dispatch(statusProductsModal(payload));
          if (status !== 'error') {
            dispatch(setProductActiveStep(1));
          }
        }}
        isOpen={openStatusModal}
        status={status}
      />
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid item md={12}>
              <InputText
                placeholder={intl.messages['labelsInputs.typeYourName']}
                label={intl.messages['labelsInputs.typeYourName']}
                value={name}
                hasTooltip
                textTooltip="Este é o nome do produto, que aparece na página e também é enviado para VTEX"
                disabled
              />
            </Grid>
            <Grid item md={12}>
              <InputText
                placeholder={intl.messages['labelsInputs.titleExample']}
                label={intl.messages['labelsInputs.slug']}
                disabled
                value={createSlug(name)}
              />
            </Grid>
            <Grid item md={12}>
              <SelectSearch
                label={intl.messages['labelsInputs.department']}
                placeholder={intl.messages['labelsInputs.select']}
                options={departmentItems}
                defaultOption={department}
                disabled
              />
            </Grid>
            <Grid item md={12}>
              <SelectSearch
                label={intl.messages['labelsInputs.category']}
                placeholder={intl.messages['labelsInputs.select']}
                options={categoryItems}
                defaultOption={category}
                tooltip
                title="A categoria é uma subdivisão de departamento"
                disabled
                optional
              />
            </Grid>
            <Grid item md={12}>
              <SelectSearch
                label={intl.messages['labelsInputs.subCategory']}
                placeholder={intl.messages['labelsInputs.select']}
                options={subCategoryItems}
                defaultOption={subCategory}
                tooltip
                title="A subcategoria é uma subdivisão de categoria"
                disabled
                optional
              />
            </Grid>

            <Grid item md={12}>
              <SelectSearch
                label={intl.messages['labelsInputs.similarDepartment']}
                placeholder={intl.messages['labelsInputs.select']}
                options={departmentItems}
                defaultOption={similarDepartment}
                disabled
                optional
              />
            </Grid>
            <Grid item md={12}>
              <SelectSearch
                label={intl.messages['labelsInputs.similarCategory']}
                placeholder={intl.messages['labelsInputs.select']}
                options={similarCategoryItems}
                defaultOption={similarCategory}
                tooltip
                title="A categoria é uma subdivisão de departamento"
                disabled
                optional
              />
            </Grid>
            <Grid item md={12}>
              <SelectSearch
                label={intl.messages['labelsInputs.similarSubCategory']}
                placeholder={intl.messages['labelsInputs.select']}
                options={similarSubCategoryItems}
                defaultOption={similarSubCategory}
                tooltip
                title="A subcategoria é uma subdivisão de categoria"
                disabled
                optional
              />
            </Grid>
            <Grid item md={12}>
              <SelectSearch
                label={intl.messages['labelsInputs.platforms']}
                placeholder={intl.messages['labelsInputs.select']}
                options={platforms}
                defaultMultiOptions={platform}
                multi
                disabled
              />
            </Grid>

            <Grid item md={12}>
              <InputText
                placeholder={intl.messages['labelsInputs.siteTitle']}
                label={intl.messages['labelsInputs.siteTitle']}
                value={siteName}
                disabled
              />
            </Grid>
            <Grid item md={12}>
              <SelectSearch
                label={intl.messages['labelsInputs.psq']}
                placeholder={intl.messages['labelsInputs.select']}
                options={psqs}
                defaultOption={psq}
                disabled
              />
            </Grid>

            <Grid item md={12}>
              <SelectSearch
                label={intl.messages['labelsInputs.area']}
                placeholder={intl.messages['labelsInputs.select']}
                options={areas}
                defaultMultiOptions={area}
                multi
                disabled
              />
            </Grid>

            <Grid item md={12}>
              <SelectSearch
                label={intl.messages['labelsInputs.internalArea']}
                placeholder={intl.messages['labelsInputs.select']}
                options={internalAreas}
                defaultMultiOptions={internalArea}
                multi
                disabled
              />
            </Grid>

            <Grid item md={12}>
              <SelectSearch
                label={intl.messages['labelsInputs.finishes']}
                placeholder={intl.messages['labelsInputs.select']}
                options={finishs}
                defaultOption={finish}
                disabled
              />
            </Grid>

            <Grid item md={12}>
              <SelectSearch
                label={intl.messages['labelsInputs.coats']}
                placeholder={intl.messages['labelsInputs.select']}
                options={coatings}
                defaultOption={coating}
                disabled
              />
            </Grid>

            <Grid item md={12}>
              <SelectSearch
                label={intl.messages['labelsInputs.dryingByTouch']}
                placeholder={intl.messages['labelsInputs.select']}
                options={dryingByTouches}
                defaultOption={dryingByTouch}
                disabled
              />
            </Grid>

            <Grid item md={12}>
              <SelectSearch
                label={intl.messages['labelsInputs.dryingByCoating']}
                placeholder={intl.messages['labelsInputs.select']}
                options={dryingByCoatings}
                defaultOption={dryingByCoating}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <SelectSearch
                label={intl.messages['labelsInputs.dryingByFinish']}
                placeholder={intl.messages['labelsInputs.select']}
                options={dryingByFinishes}
                defaultOption={dryingByFinish}
                disabled
              />
            </Grid>
            <Grid item md={12}>
              <SelectSearch
                label={intl.messages['labelsInputs.smell']}
                placeholder={intl.messages['labelsInputs.select']}
                options={smells}
                defaultOption={smell}
                disabled
              />
            </Grid>
            <Grid item md={12}>
              <SelectSearch
                label={intl.messages['labelsInputs.surfaces']}
                placeholder={intl.messages['labelsInputs.select']}
                options={surfaces}
                defaultOption={surface}
                disabled
              />
            </Grid>
            <Grid item md={12}>
              <TextAreaDescription
                label={intl.messages['labelsInputs.dilution']}
                placeholder={intl.messages['labelsInputs.description']}
                value={dilution}
                heigth="156px"
                maxLength={1300}
                tooltip={false}
                disabled
              />
            </Grid>
            <Grid item md={12}>
              <TextAreaDescription
                label={intl.messages['labelsInputs.description']}
                placeholder={intl.messages['labelsInputs.description']}
                value={description}
                heigth="156px"
                maxLength={1300}
                tooltip={false}
                disabled
              />
            </Grid>
            <Grid item md={12}>
              <TextAreaDescription
                label={intl.messages['labelsInputs.shortDescription']}
                placeholder={intl.messages['labelsInputs.shortDescription']}
                value={shortDescription}
                heigth="110px"
                maxLength={250}
                tooltip={false}
                disabled
              />
            </Grid>
            <Grid item md={12}>
              <Toggle
                label={intl.messages['labelsInputs.active']}
                checked={active}
                disabled
                regular
              />
            </Grid>
            <Grid item md={12} style={{ paddingTop: '10px' }}>
              <Toggle
                label={intl.messages['labelsInputs.pdpVisible']}
                checked={isVisible}
                disabled
                regular
              />
            </Grid>
            <Grid item md={12}>
              <InputText
                label={intl.messages['labelsInputs.brand']}
                placeholder={intl.messages['labelsInputs.select']}
                value={brand}
                disabled
              />
            </Grid>
            <Grid item md={12}>
              <Toggle
                label={intl.messages['labelsInputs.readyToUse']}
                checked={readyToUse}
                regular
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <ButtonsContainer>
        <Grid container spacing={2}>
          <Grid item md={6} sm={12} />
          <Grid item md={3} sm={12}>
            <Button
              btnType="secondary"
              width="100%"
              borderRadius="4px"
              fontWeight="600"
              onClick={() => {
                cancelProductEdit();
              }}
            >
              {intl.messages['buttons.cancel']}
            </Button>
          </Grid>
          <Grid item md={3} sm={12}>
            <Button
              btnType="primary"
              width="100%"
              borderRadius="4px"
              fontWeight="600"
              onClick={() => {
                dispatch(setProductActiveStep(1));
              }}
            >
              {intl.messages['buttons.advance']}
            </Button>
          </Grid>
        </Grid>
      </ButtonsContainer>
    </>
  );
};

export default ProductsViewFirstStep;
