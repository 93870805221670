/* eslint-disable prefer-const */
import Grid from '@material-ui/core/Grid';
import ExternalLinkArticlePreview from 'components/contexts/Links/ExternalLinkArticlePreview/ExternalLinkArticlePreview';
import AsyncSelectPaginate from 'components/form/Select/AsyncSelectPaginate/AsyncSelectPaginate';
import Button from 'components/structure/Button/Button';
import {
  COLLECTIONS,
  COLORS,
  FAMILIES,
  PRODUCTS,
  TAGS,
} from 'constants/endpoints';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ButtonsContainer } from './ArticlesThirdStepView.styles';
import { setActiveStep } from 'store/articles/articles-slice';

const ArticlesThirdStepView = () => {
  const { selectId, article } = useSelector((state) => state.articles);

  const [relatedProducts, setRelatedProducts] = useState([]);
  const [relatedColors, setRelatedColors] = useState([]);

  const [relatedFamilies, setRelatedFamilies] = useState([]);
  const [relatedCollections, setRelatedCollections] = useState([]);
  const [relatedTags, setRelatedTags] = useState([]);

  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const cancelArticle = () => {
    history.goBack();
  };

  useEffect(() => {
    setRelatedProducts(
      article?.products?.map((option) => ({
        id: option.id,
        name: option.name,
      })),
    );
    setRelatedColors(
      article?.colors?.map((option) => ({
        id: option.id,
        name: option.name,
      })),
    );
    setRelatedFamilies(
      article?.colorGroups?.map((option) => ({
        id: option.id,
        name: option.name,
      })),
    );
    setRelatedCollections(
      article?.collections?.map((option) => ({
        id: option.id,
        name: option.name,
      })),
    );
    setRelatedTags(
      article?.tags?.map((option) => ({
        id: option.id,
        name: option.name,
      })),
    );
  }, [article, dispatch]);

  const backPreviousStep = () => {
    dispatch(setActiveStep(1));
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid item md={12}>
              <AsyncSelectPaginate
                label={intl.messages['labelsInputs.relatedProducts']}
                value={relatedProducts}
                url={PRODUCTS}
                perPage={50}
                hasPlatforms
                platforms={article.platforms}
                multi
                disabled
                optional
                tooltip
                title={intl.messages['labelsInputs.tooltipArticleProducts']}
              />
            </Grid>
            <Grid item md={12}>
              <AsyncSelectPaginate
                label={intl.messages['labelsInputs.relatedColors']}
                value={relatedColors}
                url={COLORS}
                perPage={50}
                multi
                optional
                disabled
                tooltip
                title={intl.messages['labelsInputs.tooltipArticleColors']}
              />
            </Grid>
            <Grid item md={12}>
              <AsyncSelectPaginate
                label={intl.messages['labelsInputs.relatedFamilies']}
                value={relatedFamilies}
                url={FAMILIES}
                perPage={50}
                hasPlatforms
                platforms={article.platforms}
                multi
                optional
                tooltip
                disabled
                title={intl.messages['labelsInputs.tooltipArticleFamilies']}
              />
            </Grid>
            <Grid item md={12}>
              <AsyncSelectPaginate
                label={intl.messages['labelsInputs.relatedCollections']}
                value={relatedCollections}
                url={COLLECTIONS}
                perPage={50}
                multi
                optional
                tooltip
                disabled
                title={intl.messages['labelsInputs.tooltipArticleCollections']}
              />
            </Grid>
            <Grid item md={12}>
              <AsyncSelectPaginate
                label={intl.messages['labelsInputs.tags']}
                value={relatedTags}
                url={TAGS}
                perPage={50}
                hasPlatforms
                platforms={article.platforms}
                multi
                disabled
                optional
                tooltip
                title={intl.messages['labelsInputs.tooltipArticleTags']}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <ButtonsContainer>
        <Grid container spacing={2}>
          {article.published === false && (
            <Grid item md={2} xs={12}>
              <div style={{ paddingTop: '12px' }}>
                <ExternalLinkArticlePreview
                  plataform={article.platforms}
                  articleId={article.id}
                  isPageArticle
                />
              </div>
            </Grid>
          )}
          <Grid item md={3} xs={12}>
            <Button
              btnType="blue"
              width="100%"
              fontWeight="600"
              onClick={backPreviousStep}
            >
              {intl.messages['buttons.toBack']}
            </Button>
          </Grid>
          <Grid item md={1} sm={12} />
          <Grid item md={3} xs={12}>
            <Button
              btnType="secondary"
              width="100%"
              borderRadius="4px"
              fontWeight="600"
              onClick={cancelArticle}
            >
              {intl.messages['buttons.cancel']}
            </Button>
          </Grid>
          <Grid item md={3} xs={12}>
            <Button
              btnType="primary"
              width="100%"
              borderRadius="4px"
              fontWeight="600"
              onClick={cancelArticle}
            >
              {intl.messages['buttons.finish']}
            </Button>
          </Grid>
        </Grid>
      </ButtonsContainer>
    </>
  );
};

export default ArticlesThirdStepView;
