import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ paddingTop, paddingBottom }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: ${paddingTop};
    padding-bottom: ${paddingBottom};
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.colors.secondary.dark};
    font-weight: 400;
    font-size: ${theme.typography.sizes.sm};
    line-height: 18px;

    span {
      margin: 0 4px;
      position: relative;
      :first-of-type {
        margin: 0px;
      }
      &.informative {
        color: ${theme.colors.tertiary.default};
      }
    }
  `}
`;

export const ContainerInput = styled.label`
  ${({ theme }) => css`
    position: relative;
    display: inline-block;

    ${({ regular }) =>
      regular &&
      css`
        width: 32px;
        height: 16px;
        border-radius: 8px;
      `};

    ${({ large }) =>
      large &&
      css`
        width: 40px;
        height: 20px;
        border-radius: 19px;
      `};

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    > .slider {
      position: absolute;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 2px;
      background-color: ${theme.colors.background.light.sidebar};
      border: 1px solid ${theme.colors.background.light.sidebar};
      -webkit-transition: 0.4s;
      transition: 0.4s;

      :before {
        content: '';
        background-color: ${theme.colors.white.default};
        -webkit-transition: 0.4s;
        transition: 0.4s;

        ${({ regular }) =>
      regular &&
      css`
            width: 12px;
            height: 12px;
          `};

        ${({ large }) =>
      large &&
      css`
            width: 16px;
            height: 16px;
          `};
      }
    }
    > input:not(:disabled) + .slider {
      cursor: pointer;
    }
    > input:disabled + .slider {
      cursor: not-allowed;
      background-color: ${theme.colors.background.float.solid};
      border: 1px solid ${theme.colors.background.float.solid};
    }
    > input:checked:not(:disabled) + .slider {
      border: 1px solid ${theme.colors.primary.default};
      background-color: ${theme.colors.primary.default};
    }
    > input:checked:not(:disabled) + .slider:before {
      background-color: ${theme.colors.gray.lighter};
    }
    > input:checked + .slider:before {
      -webkit-transform: translateX(calc(100% + 2px));
      -ms-transform: translateX(calc(100% + 2px));
      transform: translateX(calc(100% + 2px));
    }
    .slider.round {
      border-radius: 12px;
      :before {
        border-radius: 50%;
        background-color: ${theme.colors.white.default};
      }
    }
    input:disabled + .slider.round {
      border-radius: 12px;
      :before {
        border-radius: 50%;
        background-color: ${theme.colors.background.light.button};
        border: 1px solid ${theme.colors.background.light.button};
      }
    }
  `}
`;
