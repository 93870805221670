import React from 'react';
import PropTypes from 'prop-types';
import arrayToString from 'utils/arrayToString';
import jwt from 'jsonwebtoken';
import externalIcon from 'assets/icons/open-in-new.svg';
import previewButtonIcon from 'assets/icons/preview-button.svg';
import {
  LinkContainer,
  LinkTitle,
  LinkItem,
} from './ExternalLinkArticlePreview.styles';

const ExternalLinkArticlePreview = ({
  plataform,
  articleSlug,
  isListArticles,
  isPageArticle,
  isListSlugArticles,
  articleSlugTitle,
  articleId,
}) => {
  const token = jwt.sign(
    { id: articleId },
    process.env.REACT_APP_ARTICLE_PREVIEW_KEY,
  );
  function getRedirectLink() {
    const suvinilPath = process.env.REACT_APP_BLOG_BASE_PATH;
    const glasuPath = process.env.REACT_APP_GLASU_BASE_PATH;
    let string = '';

    switch (arrayToString(plataform)) {
      case 'glasu':
        string = `${glasuPath}dicas-e-tutoriais/preview/${token}`;
        break;
      case 'institucional':
        string = `${suvinilPath}blog/preview/${token}`;
        break;
      default:
        break;
    }
    return string;
  }

  return (
    <LinkContainer>
      {articleSlugTitle && <LinkTitle>{articleSlug}</LinkTitle>}
      <LinkItem
        href={getRedirectLink()}
        target="_blank"
        rel="noreferrer"
        isListArticles={isListArticles}
        isPageArticle={isPageArticle}
      >
        {isListArticles || isPageArticle ? 'Ver Prévia' : ''}
        {isListSlugArticles || isPageArticle ? (
          <img
            alt={articleSlug}
            src={isListSlugArticles ? externalIcon : previewButtonIcon}
            title={getRedirectLink()}
          />
        ) : null}
      </LinkItem>
    </LinkContainer>
  );
};

export default ExternalLinkArticlePreview;

ExternalLinkArticlePreview.propTypes = {
  plataform: PropTypes.array.isRequired,
  articleSlug: PropTypes.string,
  isListArticles: PropTypes.bool,
  isPageArticle: PropTypes.bool,
  isListSlugArticles: PropTypes.bool,
  articleSlugTitle: PropTypes.bool,
  articleId: PropTypes.number,
};

ExternalLinkArticlePreview.defaultProps = {
  articleSlug: '',
  isListArticles: false,
  isPageArticle: false,
  isListSlugArticles: false,
  articleSlugTitle: false,
  articleId: 0,
};
