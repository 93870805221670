/* eslint-disable no-useless-escape */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable prefer-const */

export const extractCaractersFromBackend = (text) => {
  let re = /^(<figcaption style=\\\"text-align: center;\\\">)|(<figcaption>)/;
  let temp =
    text &&
    text
      .replace(/\\"?/gi, '')
      .replace(/https:\/\/?/gi, 'https://')
      .replace(re, '<figcaption style="text-align: center;">');

  return temp;
};
