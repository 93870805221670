import styled from 'styled-components';

export const Container = styled.div`
  width: 405px;
  height: 313px;
`;

export const ButtonsContainer = styled.div`
  padding-top: ${({ platformError, nameError }) =>
    platformError && nameError ? '36px' : platformError ? '36px' : '57px'};
`;
