export const dataTypes = [
  { value: 'collections', label: 'Coleção' },
  { value: 'hues', label: 'Matiz' },
  { value: 'families', label: 'Família' },
];

export const dataPlatforms = [
  { value: 'app', label: 'App' },
  { value: 'ecommerce', label: 'Ecommerce' },
  { value: 'institucional', label: 'Suvinil' },
  { value: 'glasu', label: 'Glasu' },
];
