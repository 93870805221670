/* eslint-disable no-unused-vars */
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { Button, Content, StyledMenuItem } from './MultiActionButton.styles';

const MultiActionButton = ({
  primaryLabel,
  primaryfunction,
  secondaryLabel,
  secondaryFunction,
  tertiaryLabel,
  tertiaryFunction,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const buttonLabel = primaryLabel;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = () => {
    if (buttonLabel === primaryLabel) {
      primaryfunction();
    } else {
      secondaryFunction();
    }
  };

  return (
    <Content>
      <Grid container>
        <Grid sm={10}>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            btnType="primary"
            fontWeight="600"
            onClick={() => handleSubmit()}
          >
            {buttonLabel}
          </Button>
        </Grid>
        <Grid sm={2}>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            btnType="primary"
            fontWeight="600"
            onClick={(e) => handleClick(e)}
          >
            <FaChevronDown />
          </Button>
        </Grid>
      </Grid>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClose(buttonLabel)}
      >
        <StyledMenuItem
          onClick={() => {
            secondaryFunction();
            handleClose(secondaryLabel);
          }}
        >
          {secondaryLabel}
        </StyledMenuItem>
        {tertiaryLabel && (
          <StyledMenuItem
            onClick={() => {
              tertiaryFunction();
              handleClose(tertiaryLabel);
            }}
          >
            {tertiaryLabel}
          </StyledMenuItem>
        )}
      </Menu>
    </Content>
  );
};

export default MultiActionButton;

MultiActionButton.propTypes = {
  primaryLabel: PropTypes.string.isRequired,
  primaryfunction: PropTypes.func.isRequired,
  secondaryLabel: PropTypes.string.isRequired,
  secondaryFunction: PropTypes.func.isRequired,
  tertiaryLabel: PropTypes.string,
  tertiaryFunction: PropTypes.func,
};

MultiActionButton.defaultProps = {
  tertiaryLabel: '',
  tertiaryFunction: () => {},
};
