/**
 * Troca a extensão de um nome de arquivo.
 * @param {string} filename - O nome do arquivo original.
 * @param {string} newExtension - A nova extensão (com ou sem ponto).
 * @returns {string} - O nome do arquivo com a nova extensão.
 */
export function changeFileExtension(filename, newExtension) {
  if (newExtension[0] !== '.') {
    // eslint-disable-next-line no-param-reassign
    newExtension = `.${newExtension}`;
  }

  const lastDotPosition = filename.lastIndexOf('.');

  if (lastDotPosition === -1) {
    return filename + newExtension;
  }

  return filename.substring(0, lastDotPosition) + newExtension;
}
