import React from 'react';
import PropTypes from 'prop-types';
import { Container, LabelInput, InputStyle } from './Checkbox.styles';

const Checkbox = ({
  label,
  id,
  name,
  value,
  onChange,
  disabled,
  checked,
  onClick,
}) => {
  return (
    <Container>
      <InputStyle
        type="checkbox"
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        checked={checked}
        onClick={onClick}
      />
      <LabelInput htmlFor={name}>{label}</LabelInput>
    </Container>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
};

Checkbox.defaultProps = {
  id: '',
  name: '',
  label: '',
  value: '',
  onChange: () => {},
  disabled: false,
  checked: false,
  onClick: () => {},
};

export default Checkbox;
