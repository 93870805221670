import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { store } from './store';
import flattenMessages from 'lib/core/flattenMessages';
import locales from 'locales';
import AppRoutes from 'routes';
import { ToastContainer } from 'react-toastify';
import reportWebVitals from './reportWebVitals';
import 'react-toastify/dist/ReactToastify.css';
import GlobalStyles from './styles/global';
import theme from './styles/theme';

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider locale="pt-BR" messages={flattenMessages(locales['pt-BR'])}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <GlobalStyles />
          <ToastContainer />
          <AppRoutes />
        </Provider>
      </ThemeProvider>
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
